import { observable, action, computed } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { getManyHoursSpent } from '../../../../../services/UserService';

class TotalHourStore {
  constructor(organizationStore) {
    this.organizationStore = organizationStore;
  }

  @observable reporteesHours = [];

  @action async getReporteesHours(users) {
    if (!users || users.length === 0) {
      return {};
    }
    const usernames = users.map((user) => user.username);
    const hourData = await getManyHoursSpent(usernames);
    const transformedData = {};
    usernames.forEach((user) => {
      transformedData[user] = {
        username: user,
        count: hourData.hours_spent[user].toFixed(2),
      };
    });
    this.reporteesHours = Object.values(transformedData);
    return Object.values(transformedData);
  }

  // Transform raw data to fit datatable
  @computed get filteredData() {
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;
    const filteredHours = this.reporteesHours.filter((a) =>
      selectedUsers.includes(a.username),
    );

    return filteredHours.map((item) => {
      const userObject = this.organizationStore.getUserByUsername(
        item.username,
      );

      return {
        ...item,
        username: !isEmpty(trim(userObject.preferred_name))
          ? `${item.username} (${userObject.preferred_name})`
          : `${item.username}`,
      };
    });
  }
}

export default TotalHourStore;
