/**
 * @file Provides usage staistics and analysis in detail
 * @author Attila farkas <afarkas@redhat.com>
 */

import React from 'react';

import OfferingsList from '../OfferingsList';
import './styles.scss';

const MainSection = () => {
  return (
    <div className="dashboard-mainsection-wrapper">
      <OfferingsList />
    </div>
  );
};

export default MainSection;
