import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Text,
  TextVariants,
} from '@patternfly/react-core';
import { Link } from 'react-mobx-router5';
import GenericAlert from 'components/Banners/GenericAlert';
import LabControlsStore, { labControlsContext } from 'stores/LabControls';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import useMobxStores from 'hooks/useMobxStores';
import CourseStore from './store';

const LabStartDialog = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'lab-start-dialog' */ 'components/LabControls/LabStartDialog'
    ),
);

const SurveyDialog = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'survey-dialog' */ 'components/Course/SurveyDialog/SurveyDialog'
    ),
);

type Props = {
  courseStore: CourseStore;
  labControlsStore: LabControlsStore;
  courseSlug: string;
};

const CourseHeader = ({ courseSlug, courseStore, labControlsStore }: Props) => {
  const { t } = useTranslation();
  const { routerStore, alertStore, userStore } = useMobxStores();

  const { courseCode, modality, courseVersion } = courseStore;
  const { currentAlerts } = alertStore;
  const courseAlerts = currentAlerts.filter(
    (alert) => alert.enabled && alert.environment.includes(courseSlug),
  );

  const showLabStartDialog =
    labControlsStore.courseSlug &&
    !(labControlsStore.blueprints?.length > 1) &&
    !(labControlsStore.created || labControlsStore.started) &&
    courseStore.canShowAutostart &&
    userStore.hasLabHours;

  return (
    <div>
      <Breadcrumb ouiaId="BasicBreadcrumb">
        <BreadcrumbItem
          className="hud-course-view__fade-in"
          component={Link}
          to="home"
          onClick={() => routerStore.navigate('home')}
        >
          {t('Home')}
        </BreadcrumbItem>
        <BreadcrumbItem
          className="hud-course-view__fade-in hud-course-view__fade-in--delayed"
          isActive
        >
          {courseStore.title || t('Your course')}
        </BreadcrumbItem>
      </Breadcrumb>
      {courseStore.title && (
        <Text component={TextVariants.h1}>{courseStore.title}</Text>
      )}
      {courseAlerts.map((alert) => (
        <GenericAlert key={alert.start_time || alert.title} {...alert} />
      ))}
      {courseStore.canShowSurveyDialog && (
        <Suspense fallback="Loading">
          <SurveyDialog
            onEnrollmentSave={courseStore.saveEnrollmentUserData}
            enrollmentUserData={courseStore.userData}
            courseCode={courseCode}
            courseSlug={courseSlug}
            modality={modality}
            courseVersion={courseVersion}
          />
        </Suspense>
      )}
      {showLabStartDialog && (
        <labControlsContext.Provider value={labControlsStore}>
          <Suspense fallback="Loading">
            <LabStartDialog
              onEnrollmentSave={courseStore.saveEnrollmentUserData}
              enrollmentUserData={courseStore.userData}
            />
          </Suspense>
        </labControlsContext.Provider>
      )}
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseHeader),
  ComponentError,
  handleError,
);
