import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { PageHeader } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';

const Notifications = React.lazy(() =>
  import(
    /* webpackChunkName: 'notifications-edit' */ '../../../components/Notifications/Edit'
  ),
);

@withTranslation()
@inject('routerStore', 'uiStore')
@observer
class NotificationsView extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
    t: PropTypes.func.isRequired,
    uiStore: MobXPropTypes.observableObject.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore } = props;
    const { uuid, title } = routerStore?.route?.params;

    this.uuid = uuid;
    this.props.uiStore.title = title;
  }

  render() {
    const { t, uiStore } = this.props;
    const { title } = uiStore;
    document.title = t(title);

    return (
      <React.Suspense fallback={<ViewLoader />}>
        <div className="container-grid-responsive">
          <PageHeader>{this.title}</PageHeader>
          <Notifications {...(this.uuid && { uuid: this.uuid })} />
        </div>
      </React.Suspense>
    );
  }
}

export default withErrorBoundary(
  NotificationsView,
  GenericViewError,
  handleError,
);
