import React from 'react';
import { observable, action } from 'mobx';
import { getFreeTierVCInfo } from '../../services/CourseService';

class FreeTierVCStore {
  @observable vc = {};

  @action getVCDetails = async (code) => {
    try {
      const details = await getFreeTierVCInfo(code);
      if (details) {
        this.vc = details;
      }
    } catch (err) {
      console.error(err);
    }
    return this.vc;
  };
}

export default FreeTierVCStore;
export const FreeTierVCContext = React.createContext(null);
