import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  SearchInput,
  Bullseye,
  EmptyState,
  EmptyStateVariant,
  EmptyStateIcon,
  Title,
  EmptyStateBody,
  Button,
} from '@patternfly/react-core';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import UsersIcon from '@patternfly/react-icons/dist/esm/icons/users-icon';

import { CenteredLoadingSpinner, Table } from '@ole-ui/ole-ui-components';

import ManagerNameCell from './ManagerNameCell';

const paginationOptions = {
  widgetId: 'management-dashboard-manager-progress-pagination-options-menu',
  perPageOptions: [
    { title: '5', value: 5 },
    { title: '10', value: 10 },
    { title: '25', value: 25 },
  ],
};
function ManagerProgress({ isLoading, data, onFilterChange, filterValue }) {
  const { t } = useTranslation();

  const columnDefinitions = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'manager_name',
        patternflyThProps: { modifier: 'nowrap' },
        Cell: ManagerNameCell,
      },
      {
        Header: t('Courses completed'),
        accessor: 'courses_completed',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Courses in progress'),
        accessor: 'courses_in_progress',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Number of users'),
        accessor: 'number_of_users',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Exams completed'),
        accessor: 'exams_completed',
        patternflyThProps: { modifier: 'fitContent' },
      },
    ],
    [],
  );

  if (!data) return null;

  if (isLoading) return <CenteredLoadingSpinner />;

  const renderEmptyState = () => {
    if (filterValue) {
      return (
        <Bullseye>
          <EmptyState variant={EmptyStateVariant.small}>
            <EmptyStateIcon icon={SearchIcon} />
            <Title headingLevel="h2" size="lg">
              {t('No results found')}
            </Title>
            <EmptyStateBody>
              {t('Clear the filter and try again.')}
            </EmptyStateBody>
            <Button variant="link" onClick={() => onFilterChange('')}>
              {t('Clear filter')}
            </Button>
          </EmptyState>
        </Bullseye>
      );
    }
    return (
      <Bullseye>
        <EmptyState variant={EmptyStateVariant.small}>
          <EmptyStateIcon icon={UsersIcon} />
          <Title headingLevel="h2" size="lg">
            {t('No results found')}
          </Title>
          <EmptyStateBody>
            {t('There are no active subscriptions in your organization.')}
          </EmptyStateBody>
        </EmptyState>
      </Bullseye>
    );
  };

  return (
    <div className="course-progress">
      <div className="course-progress__table-container course-progress__table-container--with-min-height">
        <SearchInput
          className="course-progress__filter"
          placeholder={t('Filter by manager name, username or email')}
          value={filterValue}
          onChange={(value) => onFilterChange(value)}
          onClear={() => onFilterChange('')}
          resultsCount={data.length}
        />
        <Table
          ariaLabel={t(
            'Table containing breakdown of progress achieved by users grouped by managers',
          )}
          className="course-progress__table"
          data={data}
          columns={columnDefinitions}
          paginationOptions={paginationOptions}
          renderEmptyState={renderEmptyState}
          isStriped
        />
      </div>
    </div>
  );
}

const managerProgressRowShape = PropTypes.shape({
  exams_completed: PropTypes.number,
  courses_in_progress: PropTypes.number,
  courses_completed: PropTypes.number,
  number_of_users: PropTypes.number,
  manager_name: PropTypes.string,
  manager_email: PropTypes.string,
  manager_username: PropTypes.string,
});

ManagerProgress.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.PropTypes.arrayOf(managerProgressRowShape),
  filterValue: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

ManagerProgress.defaultProps = {
  data: [],
};

export default ManagerProgress;
