/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-mobx-router5';
import { Button } from '@patternfly/react-core';
import { storesContext } from '../../../../../../stores';
import ViewLoader from '../../../../../Loading/View';
import './styles.scss';

const SessionCollateral = (props) => {
  const { collateral, loading, showChaptersTitle, sessionModality } = props;
  const { userStore, progressStore } = useContext(storesContext);

  const { t } = useTranslation();
  return loading ? (
    <span className="spinner-wrapper">
      <ViewLoader color="#000000" />
    </span>
  ) : (
    <React.Fragment>
      {collateral.chapter_infos?.length ? (
        <React.Fragment>
          {showChaptersTitle && <b>{t('Chapters')}</b>}
          {collateral?.chapter_infos?.map((chapter) => (
            <div className="collateral-link-chapter" key={uuidv4()}>
              {(userStore.isFreeTierSubscriber &&
                progressStore.isChapterEnabled(chapter.tag)) ||
              sessionModality === 'none' ? (
                <Button variant="link" isDisabled>
                  {chapter.tag.startsWith('pr')
                    ? t('Preface')
                    : `${t('Chapter')} ${parseInt(
                        chapter.tag.replace('ch', ''),
                        10,
                      )}`}
                  {': '}
                  {chapter.title}
                </Button>
              ) : (
                <Link
                  key={uuidv4()}
                  href
                  routeName="courses:page"
                  routeParams={{
                    // eslint-disable-next-line camelcase
                    course: collateral?.offering_slug,
                    page: chapter.tag,
                  }}
                >
                  {chapter.tag.startsWith('pr')
                    ? t('Preface')
                    : `${t('Chapter')} ${parseInt(
                        chapter.tag.replace('ch', ''),
                        10,
                      )}`}
                  {': '}
                  {chapter.title}
                </Link>
              )}
              {Array.isArray(chapter.objectives) &&
                chapter.objectives.length > 0 && (
                  <React.Fragment>
                    <p className="objectives-title">
                      <b>{t('Objectives')}</b>
                    </p>
                    <ul className="objectives-list">
                      {chapter.objectives.map((o) => (
                        <li key={uuidv4()}>{o}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                )}
            </div>
          ))}
        </React.Fragment>
      ) : (
        t('Info not available')
      )}
    </React.Fragment>
  );
};

SessionCollateral.propTypes = {
  collateral: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  showChaptersTitle: PropTypes.bool,
  sessionModality: PropTypes.string,
};

SessionCollateral.defaultProps = {
  loading: false,
  showChaptersTitle: true,
  sessionModality: 'course',
};

export default SessionCollateral;
