import { observable, action, computed, autorun } from 'mobx';

class FormUIStore {
  @observable visibleList = [];

  @observable countriesByCurrentLanguage = [];

  constructor(vocabularyStore, userStore, uiStore) {
    this.vocabularyStore = vocabularyStore;
    this.userStore = userStore;
    this.uiStore = uiStore;

    autorun(() => {
      if (this.uiStore?.currentLanguage) {
        this.fetchCountries();
      }
    });
  }

  @action fetchCountries = async () => {
    try {
      this.countriesByCurrentLanguage =
        await this.vocabularyStore.getVocabularyForCountries(
          this.uiStore.currentLanguage,
        );
    } catch {
      this.countriesByCurrentLanguage = [];
    } finally {
      return this.countriesByCurrentLanguage;
    }
  };

  @computed get countries() {
    return this.countriesByCurrentLanguage.length > 0
      ? this.countriesByCurrentLanguage
          .map((country) => {
            return { label: country.display_name, value: country.token };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  }

  @computed get selectedUserCountry() {
    return this.countriesByCurrentLanguage.length > 0 &&
      this.userStore.user &&
      this.userStore.user.default_country
      ? this.countries.find(
          (c) => c.value === this.userStore.user.default_country.trim(),
        )
      : undefined;
  }

  @action arrayRemove(value) {
    this.visibleList = this.visibleList.filter((item) => item !== value);
  }

  @action toggle = (fieldName) => {
    this.visibleList.push(fieldName);
    setTimeout(() => {
      this.arrayRemove(fieldName);
    }, 2000);
  };
}

export default FormUIStore;
