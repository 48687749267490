import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FormControl, FormGroup, Modal, Button } from 'react-bootstrap';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import DataTable from 'components/Datatable2';
import { storesContext } from 'stores';
import './styles.scss';

const AdminUserList = () => {
  const { organizationStore } = useContext(storesContext);
  const { t } = useTranslation();
  const { SearchBar } = Search;

  const promptAssignManager = async (managerId, clickedUserId) => {
    const tempUserSet = new Set(
      organizationStore.selectedUsersInAdminDB.values(),
    );
    tempUserSet.add(clickedUserId);

    const usersToAssign = {
      assign: {
        manager_id: managerId,
        users: Array.from(tempUserSet),
      },
    };

    if (usersToAssign.assign.users.length > 1) {
      const usersLength = usersToAssign.assign.users.length;
      organizationStore.newModal({
        title: t('Assign Manager'),
        text: t(`This will assign all {{usersLength}} users. Do you confirm?`, {
          usersLength,
        }),
        actionText: t('Yes'),
        onAction: () => {
          organizationStore.assignManager(managerId, usersToAssign);
          organizationStore.closeModal();
        },
      });
    } else {
      organizationStore.assignManager(managerId, usersToAssign);
    }
  };

  const managerDropdown = (cell, row, rowIndex, managerList) => {
    let managerVal;
    const managerIds = managerList.map((manager) => {
      return manager.uuid;
    });
    if (row.org_manager_id && managerIds.indexOf(row.org_manager_id) !== -1) {
      managerVal = row.org_manager_id;
    } else {
      managerVal = 'Unassign';
    }
    return (
      <FormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="select"
          bsClass="form-select"
          value={managerVal}
          onChange={(e) => {
            promptAssignManager(e.target.value, row.doc_id);
          }}
        >
          {managerList
            .filter((manager) => manager.state !== 'pending')
            .map((manager) => {
              return (
                <option key={manager.uuid} value={manager.uuid}>
                  {manager.preferred_name || manager.username}
                </option>
              );
            })}
          <option>Unassign</option>
        </FormControl>
      </FormGroup>
    );
  };
  const managerName = (cell, row) => {
    if (row.org_manager_id) {
      const foundManager = organizationStore.managerList.find((manager) => {
        return manager.uuid === row.org_manager_id;
      });
      if (!foundManager) {
        return 'Unassign';
      }
      return foundManager.preferred_name || foundManager.username;
    }
    return 'Unassign';
  };

  const columns = [
    {
      dataField: 'preferred_name',
      text: t('Name'),
      sort: true,
    },
    {
      dataField: 'username',
      text: t('Username'),
      sort: true,
    },
    {
      dataField: 'default_email',
      text: t('Email'),
      sort: true,
    },
    {
      dataField: '@timestamp',
      text: t('Created'),
      sort: true,
      formatter: (ts) => {
        return new Date(ts).toLocaleDateString();
      },
    },
    {
      dataField: 'manager_list',
      isDummyField: true,
      text: t('Manager'),
      sort: true,
      formatter: managerDropdown,
      sortValue: managerName,
      filterValue: managerName,
      formatExtraData: organizationStore.managerList,
    },
    {
      dataField: 'lms_order_number',
      text: t('Order Number'),
      sort: true,
    },
    {
      dataField: 'subscriptionsDaysRemaining',
      text: t('Days Remaining'),
      sort: true,
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
    ],
  });

  const selectRow = {
    mode: 'checkbox',
    onSelect: (row, isSelected) => {
      if (isSelected) {
        organizationStore.addSelectedUserSubscription(row.doc_id);
      } else {
        organizationStore.removeSelectedUserSubscription(row.doc_id);
      }
    },
    onSelectAll: (isSelected, rows) => {
      const ids = rows.map((r) => r.doc_id);
      if (isSelected) {
        organizationStore.addAllSelectedUsersSubscriptions(ids);
      } else {
        organizationStore.removeAllSelectedUsersSubscriptions();
      }
    },
  };

  return (
    <React.Fragment>
      <ToolkitProvider
        keyField="username"
        data={organizationStore.userList}
        columns={columns}
        search
      >
        {(toolkitProps) => (
          <div>
            <SearchBar
              className="user-search-bar"
              {...toolkitProps.searchProps}
            />
            <hr />
            <DataTable
              noDataIndication={t('No Users Available')}
              bordered={false}
              selectRow={selectRow}
              pagination={pagination}
              rowClasses="text-wrap"
              {...toolkitProps.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <Modal
        show={organizationStore.modal.opened}
        onHide={organizationStore.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{organizationStore.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{organizationStore.modal.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={organizationStore.modal.onAction}>
            {organizationStore.modal.actionText}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(AdminUserList),
  GenericViewError,
  handleError,
);
