import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import CheckIndicator from 'img/icons/check_indicator_small.svg';
import ScheduledIndicator from 'img/icons/clock_indicator_small.svg';
import UnavailableIndicator from 'img/icons/cross_indicator_small.svg';
import { PREMIUM_VT_SESSION_STATES } from 'config/constants';
import './styles.scss';

const GroupCirclesIndicator = ({
  sessionContentSessions,
  enrollments,
  scheduledSessions,
  offeringSlug,
}) => {
  const { t } = useTranslation();

  const getScheduledSessionByUUID = (sessionUUID) =>
    scheduledSessions.find((session) => session.doc_id === sessionUUID);

  const getScheduledSessionBySlug = (slug) =>
    scheduledSessions.find(
      (session) =>
        session.premvt_session_slug === slug &&
        session.state === PREMIUM_VT_SESSION_STATES.scheduled,
    );

  const matchWithScheduledSession = (sessionSlug) => {
    // Finding the last enrollment with that sessionSlug
    const foundEnrollment = enrollments
      .slice()
      .reverse()
      .find((enrollment) => {
        const sessionSlugEnrollment = getScheduledSessionByUUID(
          enrollment.premvt_session_uuid,
        ).premvt_session_slug;

        return sessionSlugEnrollment === sessionSlug;
      });

    if (foundEnrollment) {
      return {
        state: foundEnrollment.state,
        failsActiveFilters: false,
      };
    }

    const scheduledSession = getScheduledSessionBySlug(sessionSlug);

    if (scheduledSession) {
      return {
        state:
          scheduledSession.seats_available > 0
            ? PREMIUM_VT_SESSION_STATES.scheduled
            : 'NA',
        failsActiveFilters: scheduledSession.failsActiveFilters,
      };
    }

    return {
      state: 'NA',
      failsActiveFilters: false,
    };
  };

  const sessionStatusUsingEnrollment = () => {
    return sessionContentSessions.map((session) => {
      const slug = `${offeringSlug}-${session.slug}`;
      const scheduledSessionMatch = matchWithScheduledSession(slug);
      return {
        slug,
        ...scheduledSessionMatch,
      };
    });
  };

  const elements = sessionStatusUsingEnrollment();

  return (
    <div className="circle-indicator-container">
      {elements.map((element, index) => {
        if (element.state === 'completed')
          return (
            <SVG
              data-testid="completed-icon"
              key={element.slug}
              src={CheckIndicator}
              title={t('Completed')}
            />
          );
        if (element.state === 'enrolled')
          return (
            <SVG
              data-testid="scheduled-icon"
              key={element.slug}
              src={ScheduledIndicator}
              title={t('Scheduled')}
            />
          );

        // NA happens when the session is not scheduled yet, or the are no remaining seats.
        if (element.state === 'NA' || element.failsActiveFilters)
          return (
            <SVG
              data-testid="unavailable-icon"
              key={element.slug}
              src={UnavailableIndicator}
              title={
                element.failsActiveFilters
                  ? t('Unavailable Session (does not fulfill selected filters)')
                  : t('Unavailable Session')
              }
            />
          );

        return (
          <div
            data-testid="to-be-scheduled-icon"
            key={element.slug}
            className="circle-indicator"
            title={t('To be scheduled')}
          >
            {index + 1}
          </div>
        );
      })}
    </div>
  );
};

GroupCirclesIndicator.propTypes = {
  sessionContentSessions: PropTypes.array,
  enrollments: PropTypes.array,
  scheduledSessions: PropTypes.array,
  offeringSlug: PropTypes.string,
};

GroupCirclesIndicator.defaultProps = {
  sessionContentSessions: [],
  enrollments: [],
  scheduledSessions: [],
  offeringSlug: '',
};

export default GroupCirclesIndicator;
