import React, { useContext } from 'react';
import {
  Nav,
  NavItem,
  Glyphicon,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { REDHAT_COM_URL, RHU_SUPPORT_URL } from 'config/constants';
import { storesContext } from 'stores';
import UserSwitcher from 'components/UserSwitcher';
import './styles.scss';

const Topbar = () => {
  const { t } = useTranslation();
  const { routerStore, userStore, eventStore, configStore } =
    useContext(storesContext);

  const { isConcurrentLogin } = eventStore;
  return (
    <nav className="topbar" aria-label={t('Relevant links')}>
      <menu className="top-nav">
        <Nav>
          <NavItem
            href={REDHAT_COM_URL}
            target="_blank"
            data-analytics-id="rh-link-header-lp"
          >
            Redhat.com
          </NavItem>
          {(!userStore.isExternalSubscriber ||
            (userStore.isExternalSubscriber && userStore.isOpenSubscriber)) && (
            <React.Fragment>
              {userStore.isLoggedIn && !userStore.isRHUSubscriber ? (
                <NavItem
                  data-analytics-id="support-header-lp"
                  onClick={() => {
                    eventStore.toggleDrawer('support_form');
                  }}
                >
                  {t('Support')}
                </NavItem>
              ) : (
                <NavItem
                  data-analytics-id="support-header-lp"
                  href={configStore.config?.OLE_SUPPORT_URL || RHU_SUPPORT_URL}
                  target="_blank"
                >
                  {t('Support')}
                </NavItem>
              )}
              <NavItem
                data-analytics-id="faq-header-lp"
                onClick={() => routerStore.navigate('faq')}
              >
                {t('FAQ')}
              </NavItem>
            </React.Fragment>
          )}
        </Nav>
      </menu>
      <menu className="utility-nav">
        <Nav>
          {userStore.isAdmin && !userStore.isImpersonatingUser && (
            <NavItem data-analytics-id="user-switcher-header-lp">
              <UserSwitcher />
            </NavItem>
          )}
          {isConcurrentLogin && (
            <NavItem
              data-analytics-id="concurrent-warning-header-lp"
              onClick={() => routerStore.navigate('home')}
            >
              <React.Fragment>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip">
                      {t('Concurrent Login Warning')}
                    </Tooltip>
                  }
                >
                  <Glyphicon
                    glyph="warning-sign"
                    style={{ fontSize: '20px', color: '#CC0000' }}
                  />
                </OverlayTrigger>
              </React.Fragment>
            </NavItem>
          )}
        </Nav>
      </menu>
    </nav>
  );
};

export default observer(Topbar);
