/**
 * @file Provides utility for posting events for server side consumption
 * @author Bowe Strickland <bowe@redhat.com>
 */

import { post, get, patch } from 'axios';
import { API_EVENT, API_EVENT_INFO, OPS_JOBS_URL } from '../config/constants';

export const postEvent = async (eventType, eventData) => {
  try {
    const data = { event_type: eventType, ...eventData };
    const response = await post(`${API_EVENT}`, data);
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEventsByType = async (eventType, noOfEventsToReturn = 2) => {
  if (noOfEventsToReturn > 10) {
    throw new Error(
      'Invalid param: noOfEventsToReturn. Maximum 10 events can be returned',
    );
  }
  try {
    const events = await get(
      API_EVENT_INFO.replace('<event-type>', eventType).replace(
        '<last-events>',
        noOfEventsToReturn,
      ),
    );

    return events;
  } catch (error) {
    throw new Error(error);
  }
};

export const postNewJob = async (service, command, params) => {
  try {
    const response = await post(`${OPS_JOBS_URL}`, {
      service,
      command,
      params,
    });
    return response.status === 200;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateJob = async (docId, receipt) => {
  try {
    const response = await patch(`${OPS_JOBS_URL}`, {
      doc_id: docId,
      receipt,
    });
    return response.status === 200;
  } catch (error) {
    throw new Error(error);
  }
};

export const getJobs = async () => {
  try {
    const response = await get(`${OPS_JOBS_URL}`);
    const jobs = response?.data?.jobs;
    return jobs
      ? jobs.sort((a, b) => {
          return Date.parse(a['@timestamp']) < Date.parse(b['@timestamp']);
        })
      : null;
  } catch (error) {
    throw new Error(error);
  }
};
