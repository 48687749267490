/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Badge, Flex } from '@patternfly/react-core';
import { Table } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import { storesContext } from 'stores';
import GenericViewError from '../../../Error/GenericViewError';
import './styles.scss';

const UserList = () => {
  const { t, i18n } = useTranslation();
  const { organizationStore } = useContext(storesContext);

  const columnDefinitions = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'preferred_name',
        id: 'report_title',
      },
      {
        Header: t('Username'),
        accessor: 'username',
        id: 'username',
      },
      {
        Header: t('Email'),
        accessor: 'email',
      },
      {
        Header: t('Last Login'),
        accessor: 'last_login',
        Cell: (props) => {
          if (!props?.cell?.value || props?.cell?.value === 'NA') {
            return t('Never');
          }

          return <div>{new Date(props.cell.value).toLocaleDateString()}</div>;
        },
      },
    ],
    [i18n.language],
  );

  const handleSelectedRowsChange = (items) => {
    organizationStore.setSelectedUserForManager(items.map((i) => i.username));
  };

  useEffect(() => {
    organizationStore.getReportees();
  }, [organizationStore.organization]);

  let badgeLabel = organizationStore.managerReportees.length;

  if (organizationStore.selectedUsersByManagerList?.length > 0) {
    badgeLabel = `${organizationStore.selectedUsersByManagerList.length}/${organizationStore.managerReportees.length}`;
  }

  return (
    <div className="dashboard-manager-user-list">
      <Flex>
        <h3>{t('Users')}</h3>
        <Badge key={1} isRead>
          {badgeLabel}
        </Badge>
      </Flex>
      <Table
        isSelectable
        ariaLabel={t('Table listing all your Scheduled jobs')}
        data={organizationStore.managerReportees}
        columns={columnDefinitions}
        variant="compact"
        onSelectedRowsChange={handleSelectedRowsChange}
      />
    </div>
  );
};

export default withErrorBoundary(
  observer(UserList),
  GenericViewError,
  handleError,
);
