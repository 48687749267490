import { createRouter } from 'router5';
import loggerPlugin from 'router5/plugins/logger';
import browserPlugin from 'router5/plugins/browser';
import { mobxPlugin } from 'mobx-router5';
import { APP_BASE } from './constants';
import Routes from './routes';
import { IntercomAPI } from '../components/Intercom';
import { extendSession } from '../services/SessionService';

const configureRouter = (useLoggerPlugin = false, stores) => {
  const { learningPathStore, progressStore, routerStore } = stores;

  const routes = new Routes(stores);

  const router = createRouter(routes.routes, {
    allowNotFound: true,
  });

  router.usePlugin(mobxPlugin(routerStore));
  router.usePlugin(browserPlugin({ base: APP_BASE, useHash: false }));

  router.subscribe(({ route, previousRoute }) => {
    // FIXME: Remove this after a better implementation is in place
    if (
      (previousRoute?.name === 'courses:view' &&
        previousRoute?.params?.course?.includes('vc')) ||
      previousRoute?.name === 'courses:page'
    ) {
      progressStore.getProgress();
    }

    if (route.name === 'skills-paths') {
      learningPathStore.getLearningPaths(true);
    }

    IntercomAPI('update', {
      last_request_at: parseInt(new Date().getTime() / 1000, 10),
    });

    extendSession({ extend: 'on_url_change' });

    return true;
  });

  if (useLoggerPlugin) {
    router.usePlugin(loggerPlugin);
  }

  return router;
};

export default configureRouter;
