import React from 'react';
import { PropTypes } from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

const TabTitle = (props) => {
  const { icon, title } = props;
  const className = title ? 'visible-xs-inline-block' : '';

  return (
    <React.Fragment>
      <Glyphicon glyph={icon} className={className} />
      {title && <span className="hidden-xs">{title}</span>}
    </React.Fragment>
  );
};

TabTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
};

TabTitle.defaultProps = {
  title: '',
};

export default TabTitle;
