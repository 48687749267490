import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ActivityChart, ChartFrame } from '@ole-ui/ole-ui-components';

const colorScale = ['#4CAF50', '#FFC107', '#EE0000'];

function ActivityChartFrame({ studentActivity }) {
  const { t } = useTranslation();

  let data = [];
  let legendData = [];
  let isLoading = true;

  if (studentActivity) {
    isLoading = false;
    // eslint-disable-next-line no-prototype-builtins
    if (studentActivity.hasOwnProperty('never_logged_in')) {
      data = [
        { x: t('Active'), y: studentActivity.active },
        { x: t('Inactive'), y: studentActivity.inactive },
        { x: t('Never logged in'), y: studentActivity.never_logged_in },
      ];

      legendData = [
        { name: `${t('Active')}: ${studentActivity.active}` },
        { name: `${t('Inactive')}: ${studentActivity.inactive}` },
        { name: `${t('Never logged in')}: ${studentActivity.never_logged_in}` },
      ];
    } else {
      data = [
        { x: t('Active'), y: studentActivity.active },
        { x: t('Inactive'), y: studentActivity.inactive },
      ];

      legendData = [
        { name: `${t('Active')}: ${studentActivity.active}` },
        { name: `${t('Inactive')}: ${studentActivity.inactive}` },
      ];
    }
  }

  const studentPopoverContent = (
    <>
      <p>
        <b>{t('Active:')}</b> {t('users with at least 1 page of progress')}
      </p>
      <p>
        <b>{t('Inactive:')}</b>{' '}
        {t('users with no activity on the learning subscription')}
      </p>
    </>
  );

  const chart = (
    <div className="chart-fade-in">
      <ActivityChart
        subTitle={t('Students')}
        ariaDesc={t('Student activity chart')}
        data={data}
        colorScale={colorScale}
        legendData={legendData}
      />
    </div>
  );

  return (
    <ChartFrame
      subTitle={t('Student Activity')}
      popoverHeader={<div>{t('Student Activity')}</div>}
      popoverContent={studentPopoverContent}
      tabs={[
        {
          key: 'student_activity',
          title: '',
          chart,
          isLoading,
        },
      ]}
    />
  );
}

ActivityChartFrame.propTypes = {
  studentActivity: PropTypes.shape({
    active: PropTypes.number.isRequired,
    inactive: PropTypes.number.isRequired,
    never_logged_in: PropTypes.number,
    total: PropTypes.number.isRequired,
  }),
};

ActivityChartFrame.defaultProps = {
  studentActivity: undefined,
};

export default ActivityChartFrame;
