/* eslint-disable no-unused-vars */
import React from 'react';
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import SVG from 'react-inlinesvg';
import sortAscend from '../../../img/sort_btn_asc.svg';
import sortNeutral from '../../../img/sort_btn_neutral.svg';
import sortDescend from '../../../img/sort_btn_desc.svg';

const TableColumn = (props) => {
  const { t } = useTranslation();
  const {
    isSortAscending,
    sortByField,
    onSort,
    title,
    currentSortByField,
    includeInfoIcon,
  } = props;

  const timezone = moment.tz.guess() || undefined;

  const TimeInfoTooltip = (
    <Tooltip id="time-info-tooltip">
      {`${t('The start time is based on your time zone:')} ${timezone}`}
    </Tooltip>
  );

  const sortClassName = `column-sort-wrapper ${
    sortByField !== '' ? `${sortByField}-sorting` : ''
  }`;

  return (
    <React.Fragment>
      <div className="column-sort">
        <div className={sortClassName}>
          <span>{title}</span>
          <OverlayTrigger placement="top" overlay={TimeInfoTooltip}>
            <span className="with-info-icon display-flex">
              {includeInfoIcon && (
                <Glyphicon
                  glyph="info-sign"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              )}
            </span>
          </OverlayTrigger>
          <span
            className="sort-btn"
            onClick={(e) => {
              e.stopPropagation();
              onSort(sortByField);
            }}
            role="button"
            tabIndex="0"
          >
            {title !== '' && (
              <SVG
                src={
                  sortByField === currentSortByField
                    ? (isSortAscending && sortAscend) || sortDescend
                    : sortNeutral
                }
              >
                {isSortAscending
                  ? t('Click to sort in descending order')
                  : t('Click to sort  in ascending order')}
              </SVG>
            )}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

TableColumn.propTypes = {
  onSort: PropTypes.func,
  title: PropTypes.string,
  isSortAscending: PropTypes.bool,
  sortByField: PropTypes.string,
  currentSortByField: PropTypes.string,
  includeInfoIcon: PropTypes.bool,
};

TableColumn.defaultProps = {
  onSort: () => {},
  isSortAscending: true,
  sortByField: '',
  title: '',
  currentSortByField: '',
  includeInfoIcon: false,
};

export default TableColumn;
