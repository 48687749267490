import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  cancelExam,
  getExamCollateral,
  getExamStatus,
  enrollIntoExam,
  enrollIntoExamV2,
} from '../services/ExamService';

class ExamStore {
  constructor(catalogStore) {
    this.catalogStore = catalogStore;
  }

  @observable canShowSuccessAlert = false;

  @observable code = '';

  @observable collateral = {};

  @observable disableExamOperations = false;

  @observable enrollmentLoading = false;

  @observable isLoadingCollateral = false;

  @observable interval = 10000;

  @observable IESURL = '';

  @observable IESGoLiveDate = '2100-01-01';

  @observable loading = false;

  @observable userExam = {};

  @observable isUserExamLoaded = false;

  @observable version = '';

  @computed get offering() {
    // when null, loading will show
    if (isEmpty(this.catalogStore.groupedCatalogEntries) || !this.code) {
      return null;
    }

    const versions = this.catalogStore.groupedCatalogEntries[this.code];

    // when exam is versionless
    const targetVersion = this.version || '1.0';

    if (versions) {
      return versions[targetVersion];
    }

    // when undefined, empty state will show
    return undefined;
  }

  @computed get exam() {
    return this.userExam;
  }

  @computed get status() {
    return this.exam?.status || 'unenrolled';
  }

  @computed get statusUpperCase() {
    return this.status.toUpperCase();
  }

  @computed get isPreliminary() {
    const examReference = this.offering?.sku || `${this.code.toUpperCase()}`;
    return examReference.toUpperCase().substring(0, 2) === 'PE';
  }

  @computed get isExamBeforeGoLive() {
    if (!this.userExam?.exam_date) {
      return moment().isBefore(this.IESGoLiveDate);
    }

    return (
      this.userExam.retake_index > 0 ||
      moment(this.userExam.exam_date).isBefore(this.IESGoLiveDate)
    );
  }

  @computed get isRetake() {
    return this.userExam.retake_index > 0;
  }

  @action getUserExam = async () => {
    if (!this.offering?.sku) {
      return;
    }

    try {
      const data = await getExamStatus(this.offering.sku);
      this.userExam = data.user_exam;
      this.isUserExamLoaded = true;
    } catch (error) {
      console.error(error);
    }
  };

  @action getExamCollateral = async (code, language) => {
    try {
      this.isLoadingCollateral = true;
      let collateral = await getExamCollateral(code, language);

      if (!collateral) {
        collateral = await getExamCollateral(code);
      }

      this.isLoadingCollateral = false;
      this.collateral = collateral;

      return collateral;
    } catch (error) {
      this.isLoadingCollateral = false;
      throw Error(error);
    }
  };

  @action enroll = async (code) => {
    this.enrollmentLoading = true;
    const url = this.IESURL;
    try {
      await enrollIntoExam(code);
    } catch (err) {
      throw err;
    } finally {
      this.enrollmentLoading = false;
    }
    return url;
  };

  @action enrollV2 = async (sku) => {
    this.enrollmentLoading = true;
    const url = this.IESURL;
    try {
      await enrollIntoExamV2(sku);
    } catch (err) {
      throw err;
    } finally {
      this.enrollmentLoading = false;
    }
    return url;
  };

  @action cancelExam = async (orderNumber) => {
    this.enrollmentLoading = true;
    try {
      await cancelExam(orderNumber);
    } catch (err) {
      throw err;
    } finally {
      this.enrollmentLoading = false;
    }
  };

  startPolling() {
    this.intervalId = setInterval(this.getUserExam.bind(this), this.interval);
  }

  stopPolling() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}

export const ExamStoreContext = createContext(null);
export default ExamStore;
