/**
 * @file Frequently asked questions view allowing finding answers to frequently asked questions
 * @author Attila Farkas <afarkas@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Col, PageHeader, Row, PanelGroup } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
// import SVG from 'react-inlinesvg';
// import { COURSE_HELP } from 'config/constants';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import ViewLoader from 'components/Loading/View';
import { storesContext } from 'stores';
import FAQS from 'config/faqs';
import FAQSPARTNERS from 'config/faqsPartners';
import './styles.scss';
// import downloadPdf from 'img/icons/pdf-download.svg';

const FaqCard = React.lazy(() => import('components/Faq/Card'));

const Faq = () => {
  const { t } = useTranslation();
  const { routerStore, uiStore, userStore } = useContext(storesContext);

  const FaqData = userStore.isOpenSubscriber ? FAQSPARTNERS : FAQS;

  uiStore.title = routerStore?.route?.params?.title;
  const { title } = uiStore;
  document.title = t(title);

  // const blank = '_blank';

  return (
    <div className="container-grid-responsive faq-grid">
      <PageHeader>{t(title)}</PageHeader>
      {/* TODO: uncomment this when new PDF is available */}
      {/* <span className="download-pdf">
        <a href={COURSE_HELP} target={blank}>
          <SVG src={downloadPdf} />
        </a>
      </span> */}
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col sm={12} className="faq-wrapper">
            <PanelGroup id="accordion-panel-group">
              {FaqData.map((faq) => (
                <FaqCard
                  faq={faq}
                  key={faq.id}
                  name={`faq-card-${faq.icon}`}
                  id={`faq-card-${faq.icon}`}
                  className="element"
                />
              ))}
            </PanelGroup>
          </Col>
        </Row>
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(observer(Faq), GenericViewError, handleError);
