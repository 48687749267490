/**
 * @file Course Catalog view allowing filtering and searching for courses
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reaction, when } from 'mobx';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import GenericViewError from 'components/Error/GenericViewError';
import ViewLoader from 'components/Loading/View';
import Filters from 'components/LearningPath/Filters';
import LearningPathList from 'components/LearningPath/List';
import SearchAutosuggest from 'components/Search';
import { handleError } from 'services/ErrorService';
import { storesContext } from 'stores';
import { CenteredLoadingSpinner } from '@ole-ui/ole-ui-components';
import './styles.scss';

const LearningPathCatalog = () => {
  const { i18n, t } = useTranslation();
  const {
    learningPathStore,
    routerStore,
    searchStore,
    uiStore,
    userStore,
    vocabularyStore,
    organizationStore,
  } = useContext(storesContext);

  uiStore.title = routerStore?.route?.params?.title;

  const { title } = uiStore;
  document.title = t(title);

  const presetFilters = () => {
    learningPathStore.clearFilters();
    if (routerStore?.route?.params?.q) {
      searchStore.query = routerStore?.route?.params?.q;
    } else {
      searchStore.query = '';
    }
    learningPathStore.presetFilters(routerStore?.route?.params?.filter);
  };

  useEffect(() => {
    // Populate entry data and filters on initial load
    when(
      () => !learningPathStore.loaded,
      async () => {
        await learningPathStore.init(i18n, vocabularyStore, userStore);

        learningPathStore.loaded = true;
      },
    );

    // Populate entry data and filters on initial load
    when(
      () =>
        userStore.isOrganizationLeader &&
        organizationStore.organizations?.length > 0 &&
        !userStore.subscription.subscription,
      async () => {
        await learningPathStore.init(i18n, vocabularyStore, userStore);

        learningPathStore.loaded = true;
      },
    );

    // Select preset filters on initial load
    when(
      () => learningPathStore.loaded && routerStore?.route?.name,
      () => presetFilters(),
    );

    // Update preset filters when route changes
    reaction(
      () => learningPathStore.loaded && routerStore?.route?.name,
      () => presetFilters(),
    );

    reaction(
      () => learningPathStore.loaded && routerStore?.route?.params?.q,
      () => learningPathStore.filterEntries(),
    );
  }, []);

  if (!learningPathStore.loaded) {
    return (
      <div className="catalog__loading-container">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  return (
    <div className="container-grid-responsive catalog">
      <React.Suspense fallback={<ViewLoader />}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="catalog__search">
            <SearchAutosuggest />
          </div>
        </form>
        <div className="catalog__content">
          <Filters store={learningPathStore} />
          <section className="catalog__list-container">
            <LearningPathList />
          </section>
        </div>
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(LearningPathCatalog),
  GenericViewError,
  handleError,
);
