import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { when } from 'mobx';
import { Form, FormControl, DropdownButton, MenuItem } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

import { storesContext } from 'stores';

import './styles.scss';

const MgmtCustomSkillPathsFilters = (props) => {
  const { t } = useTranslation();
  const { onSelectSkillPath, onSearchUsers, enableFilters } = props;
  const { organizationStore } = useContext(storesContext);
  const [selectedSkillPathTitle, setSelectedSkillPathTitle] =
    React.useState(null);

  const [selectedSkillPathDueDate, setSelectedSkillPathDueDate] =
    React.useState('Not set');

  const handleSkillPathSelect = (skillPathId) => {
    const _found = skillPathId
      ? organizationStore.customSkillPaths?.find((c) => c.code === skillPathId)
      : null;

    const _dueDate =
      _found && _found.end_date
        ? moment(_found.end_date).format('YYYY-MM-DD')
        : t('Not set');
    const _skillPathTitle = _found && _found.title ? _found.title : null;
    setSelectedSkillPathDueDate(_dueDate);
    setSelectedSkillPathTitle(_skillPathTitle);

    onSelectSkillPath(skillPathId);
  };

  useEffect(() => {
    when(
      () => organizationStore.organization,
      async () => {
        if (organizationStore.organization.id) {
          await organizationStore.getCustomSkillPaths(
            organizationStore.organization.id,
            true,
          );
          const defaultSelectCSK =
            organizationStore.customSkillPaths?.length > 0
              ? organizationStore.customSkillPaths[0].code
              : null;

          handleSkillPathSelect(defaultSelectCSK);
        }
      },
    );
  }, [organizationStore.organization]);

  const onSearch = (e) => {
    e.persist();
    const { value } = e.target;

    const query = value && value.toLowerCase();
    onSearchUsers(query);
  };

  const renderFilters = () => {
    return (
      <React.Fragment>
        <div className="mgmt-csk-filters">
          <h4>{t('Progress Monitor')}</h4>
          <Form
            horizontal
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <FormControl
              data-analytics-id="prog-mon-cust-skillp-mngmt-dboard-lp"
              type="text"
              placeholder={t('Search by name, username, email')}
              onKeyUp={onSearch}
              disabled={
                !enableFilters || !organizationStore.customSkillPathsLoaded
              }
            />
            <div className="mgmt-csk-other-filter">
              <div className="filter-row">
                <div className="col">
                  <span className="span-label">{t('Filters')}:</span>
                </div>
                <div className="col">
                  <DropdownButton
                    bsStyle="default"
                    className="mgmt-csk-filter-dropdown"
                    id="mgmt-csk-dropdown"
                    disabled={
                      !enableFilters ||
                      !organizationStore.customSkillPathsLoaded
                    }
                    onSelect={(key) => {
                      handleSkillPathSelect(key);
                    }}
                    title={
                      <span className="dropdown-label">
                        {organizationStore.customSkillPathsLoaded
                          ? selectedSkillPathTitle ||
                            t('Filter by Custom Skill Path')
                          : t('Loading Custom Skill Path')}
                      </span>
                    }
                  >
                    {(organizationStore.customSkillPathsLoaded &&
                      organizationStore.customSkillPaths?.map((csk) => {
                        return (
                          <MenuItem
                            key={csk.title}
                            eventKey={csk.code}
                            value={csk.code}
                          >
                            {csk.title}
                          </MenuItem>
                        );
                      })) || (
                      <MenuItem key="no-csk" eventKey="no-csk" value="no-csk">
                        {t('No Custom Skill Path')}
                      </MenuItem>
                    )}
                  </DropdownButton>
                </div>
              </div>
              <div className="filter-row">
                <div className="col">
                  <span className="span-label">{t('Due Date')}:</span>
                </div>
                <div className="col">{selectedSkillPathDueDate}</div>
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    );
  };

  return <React.Fragment>{renderFilters()}</React.Fragment>;
};

MgmtCustomSkillPathsFilters.propTypes = {
  onSelectSkillPath: PropTypes.func,
  onSearchUsers: PropTypes.func,
  enableFilters: PropTypes.bool,
};
MgmtCustomSkillPathsFilters.defaultProps = {
  onSelectSkillPath: () => {},
  onSearchUsers: () => {},
  enableFilters: false,
};

export default observer(MgmtCustomSkillPathsFilters);
