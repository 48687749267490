import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { Button } from '@patternfly/react-core';
import domToReact from 'html-react-parser/lib/dom-to-react';
import Store from './store';
import './styles.scss';

@inject('routerStore')
@withTranslation()
@observer
class MultiChoice extends Component {
  static propTypes = {
    routerStore: PropTypes.object.isRequired,
    data: PropTypes.string,
    quizAttributeId: PropTypes.string.isRequired,
    chapter: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: '',
  };

  componentDidMount() {
    const {
      routerStore: { route },
      chapter,
    } = this.props;
    const slug = route.params.course;
    Store.interactiveInfoFetch(slug, chapter);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { multiChoiceData } = Store;
    const { quizAttributeId } = this.props;
    const formElem = e.target.elements.answer;
    const doc = document.getElementById(e.target.id);
    const answers = [];
    formElem.forEach((val) => {
      if (val.checked) {
        answers.push(val.value);
      }
    });

    if (answers.length) {
      doc.querySelectorAll('input').forEach((input) => {
        input.disabled = true;
      });
      multiChoiceData[quizAttributeId]?.elements?.forEach((quizAns) => {
        if (e.target.id === quizAns.id) {
          answers.forEach((userAnswer) => {
            const getQuerySelect = doc.querySelectorAll(`.${userAnswer}`);
            if (quizAns.answers.split(',').includes(userAnswer)) {
              getQuerySelect[0].classList.remove('incorrect');
              getQuerySelect[0].classList.add('correct');
            } else {
              getQuerySelect[0].classList.add('incorrect');
            }
          });
        }
      });
    }
  };

  showSolution = (e, elemId) => {
    e.preventDefault();
    const { multiChoiceData } = Store;
    const { quizAttributeId } = this.props;
    multiChoiceData[quizAttributeId].elements.forEach((quizAns) => {
      if (elemId === quizAns.id) {
        const doc = document.getElementById(elemId);
        const quizAnswer = quizAns.answers.split(',');
        doc.querySelectorAll('input').forEach((input) => {
          input.disabled = true;
          input.checked = false;
        });
        doc.querySelectorAll('.answerRow').forEach((row) => {
          row.classList.remove('incorrect');
          row.classList.remove('correct');
        });
        quizAnswer.forEach((answer) => {
          const getQuerySelect = doc.querySelectorAll(`.${answer}`);
          getQuerySelect[0].classList.add('correct');
          getQuerySelect[0].children[1].querySelector('.answer').checked = true;
        });
      }
    });
  };

  resetQuiz = (e, elemId) => {
    e.preventDefault();
    const { multiChoiceData } = Store;
    const { quizAttributeId } = this.props;
    multiChoiceData[quizAttributeId].elements.forEach((quizAns) => {
      if (elemId === quizAns.id) {
        const doc = document.getElementById(elemId);
        doc.querySelectorAll('input').forEach((input) => {
          input.disabled = false;
          input.checked = false;
        });
        doc.querySelectorAll('.answerRow').forEach((row) => {
          row.classList.remove('incorrect');
          row.classList.remove('correct');
        });
      }
    });
  };

  render() {
    const { data, quizAttributeId, t } = this.props;
    const { multiChoiceData } = Store;
    const htmlParser = new DOMParser();
    const parsed = htmlParser.parseFromString(data, 'text/html');
    const html = parsed.body.innerHTML;
    const qLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const iLabels = 'abcdefghijklmnopqrstuvwxyz';
    let incrementQLabels = 0;
    let incrementILabels = 0;
    const input = [];
    const parserOption = {
      // eslint-disable-next-line consistent-return
      replace: ({ attribs, children }) => {
        if (attribs) {
          if (multiChoiceData[quizAttributeId]) {
            multiChoiceData[quizAttributeId]?.elements?.forEach((quizAns) => {
              input.push(quizAns.type);
            });
          }
          if (attribs && attribs.class === 'qandaset') {
            incrementILabels += 1;
            children[1].attribs.style = '';
            return (
              <React.Fragment>
                <form
                  className="multi_question"
                  id={
                    children[0].next.children[1].children[0].children[0]
                      .children[0].attribs.id
                  }
                  onSubmit={(e) => this.handleSubmit(e)}
                  ref={(node) => {
                    this.form = node;
                  }}
                >
                  {domToReact(children, parserOption)}
                  <div className="interactive_buttons">
                    <Button
                      variant="primary"
                      type="submit"
                      data-analytics-id={`check-btn-multiQuiz-ole-lp-${Store.slug}-${Store.chapter}-${children[0].next.children[1].children[0].children[0].children[0].attribs.id}`}
                    >
                      {t('Check')}
                    </Button>
                    <Button
                      data-analytics-id={`reset-btn-multiQuiz-ole-lp-${Store.slug}-${Store.chapter}-${children[0].next.children[1].children[0].children[0].children[0].attribs.id}`}
                      variant="tertiary"
                      onClick={(e) =>
                        this.resetQuiz(
                          e,
                          children[0].next.children[1].children[0].children[0]
                            .children[0].attribs.id,
                        )
                      }
                    >
                      {t('Reset')}
                    </Button>
                    <Button
                      data-analytics-id={`show-btn-multiQuiz-ole-lp-${Store.slug}-${Store.chapter}-${children[0].next.children[1].children[0].children[0].children[0].attribs.id}`}
                      variant="secondary"
                      onClick={(e) =>
                        this.showSolution(
                          e,
                          children[0].next.children[1].children[0].children[0]
                            .children[0].attribs.id,
                        )
                      }
                    >
                      {t('Show Solution')}
                    </Button>
                  </div>
                </form>
              </React.Fragment>
            );
          }
          if (attribs && attribs.class === 'question') {
            return (
              <React.Fragment>
                <tr>
                  <td colSpan="3">
                    <tr>
                      <strong className="ques_label">
                        {incrementILabels}.
                      </strong>{' '}
                      {domToReact(children)}
                    </tr>
                  </td>
                </tr>
              </React.Fragment>
            );
          }
          if (attribs && attribs.class === 'answer') {
            return (
              <React.Fragment>
                <tr
                  className={`answerRow ${iLabels[incrementQLabels]}`}
                  ref={(node) => {
                    this.tr = node;
                  }}
                >
                  <td className="ans_label">
                    {
                      // eslint-disable-next-line no-plusplus
                      qLabels[incrementQLabels++]
                    }
                  </td>
                  <td className="ans_input">
                    {input[incrementILabels - 1] === 'multi-radio' ? (
                      <input
                        type="radio"
                        name="answer"
                        value={iLabels[incrementQLabels - 1]}
                        className="answer"
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="answer"
                        value={iLabels[incrementQLabels - 1]}
                        className="answer"
                      />
                    )}
                  </td>
                  <td className="ans_text">
                    {domToReact(children[1].children)}
                  </td>
                </tr>
              </React.Fragment>
            );
          }
        }
        incrementQLabels = 0;
      },
    };

    return (
      <div className="multi-choice">
        {multiChoiceData && Parser(html, parserOption)}
      </div>
    );
  }
}

export default MultiChoice;
