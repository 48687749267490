import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Alert } from 'react-bootstrap';
import ViewLoader from '../../Loading/View';

const ComponentError = () => {
  const { t } = useTranslation();

  return (
    <React.Suspense fallback={<ViewLoader />}>
      <Alert bsStyle="danger">
        {t("We're having trouble displaying this card.")}
      </Alert>
    </React.Suspense>
  );
};

export default observer(ComponentError);
