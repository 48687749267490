import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import DatetimePicker from 'react-datetime';
import Store from './store';

@withTranslation()
@observer
class DateTimeWidget extends Component {
  static propTypes = {
    formContext: PropTypes.object,
    locale: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    t: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    formContext: {},
    locale: 'en',
    onBlur: () => {},
    onFocus: () => {},
    placeholder: 'Enter date',
    value: null,
  };

  constructor(props) {
    super(props);
    this.store = new Store();
  }

  componentDidMount() {
    this.onChange();
  }

  componentDidUpdate() {
    this.onChange();
  }

  onChange = (date) => {
    const { formContext, onChange, value } = this.props;
    moment.tz.setDefault(formContext.timezone);
    date = moment.tz(date || value, formContext.timezone);

    this.store.dateText = date;

    if (!date.isValid()) {
      return;
    }

    onChange(date.toISOString());
  };

  value() {
    const { formContext, value } = this.props;
    const { timezone } = formContext;
    return timezone ? moment.tz(value, timezone) : moment(value);
  }

  render() {
    const { onBlur, onFocus, locale, placeholder, t } = this.props;

    return (
      <DatetimePicker
        inputProps={{
          placeholder: t(placeholder),
        }}
        onChange={(date) => this.onChange(date)}
        onBlur={onBlur}
        onFocus={onFocus}
        value={this.value()}
        locale={locale}
        timeFormat="HH:mm"
      />
    );
  }
}

export default DateTimeWidget;
