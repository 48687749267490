import React from 'react';

import {
  ValidatedOptions,
  HelperText,
  HelperTextItem,
  FormHelperText,
} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

type Props = {
  hasError: boolean;
  message?: string;
};

export default function ValidationText({ hasError, message }: Props) {
  return (
    <FormHelperText>
      <HelperText>
        <HelperTextItem
          variant={hasError ? ValidatedOptions.error : ValidatedOptions.default}
          {...(hasError && { icon: <ExclamationCircleIcon /> })}
        >
          {message || ''}
        </HelperTextItem>
      </HelperText>
    </FormHelperText>
  );
}
