import { get, patch, post, isCancel } from 'axios';
import {
  PREMVT_SESSION_API,
  PREMVT_SESSION_CONTENT_API,
  PREMVT_SESSION_ID_ENROLLMENT_API,
  PREMVT_SESSION_COMPLETE_API,
  PREMVT_SESSION_DETAIL_API,
  PREMVT_SESSION_COLLATERAL_API,
  PREMVT_SESSION_INSTRUCTORS_DETAILS_API,
  PREMVT_SESSION_SURVEY_API,
} from '../config/constants';

export const getPremiumSessions = async (states = []) => {
  try {
    let url = PREMVT_SESSION_API;
    if (states) {
      let queryParams = '';
      queryParams = `?state=${states?.join(',')}`;
      url = url.concat(queryParams);
    }

    const res = await get(url);
    const { items: docs } = res.data;

    return docs;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPremiumSessionCollateral = async (
  premVTSlug,
  language,
  retry = false,
  retryLanguage,
  cancelToken,
) => {
  try {
    const res = await get(
      `${PREMVT_SESSION_COLLATERAL_API}/${premVTSlug}?language=${language}`,
      { cancelToken },
    );
    return res.data;
  } catch (error) {
    const isCancelReason = isCancel(error);
    if (retry && !isCancelReason)
      return getPremiumSessionCollateral(
        premVTSlug,
        retryLanguage || language,
        false,
        retryLanguage,
        cancelToken,
      );
    // eslint-disable-next-line no-throw-literal
    throw { error, isCancel: isCancelReason };
  }
};

export const getPremiumEnrollmentsBySessionId = async (
  premvtSessionId,
  states = [],
) => {
  try {
    let queryParams = '';

    if (states) {
      queryParams = `?state=${states?.join(',')}`;
    }

    const res = await get(
      PREMVT_SESSION_ID_ENROLLMENT_API.replace(
        '<<session_id>>',
        premvtSessionId,
      ).replace('<<query-params>>', queryParams),
    );
    const { enrollments } = res.data;

    return enrollments;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPremiumSessionContent = async (state) => {
  try {
    const url = state
      ? `${PREMVT_SESSION_CONTENT_API}?state=${state}`
      : PREMVT_SESSION_CONTENT_API;
    const res = await get(url);
    const { items: docs } = res.data;

    return docs;
  } catch (error) {
    throw new Error(error);
  }
};

export const markSessionAsComplete = async (premvtSessionUUID) => {
  if (!premvtSessionUUID) {
    throw new Error('Missing Param: premvtSessionUUID');
  }

  // returning promise to capture error when api returns 400.
  return new Promise((resolve, reject) => {
    patch(
      PREMVT_SESSION_COMPLETE_API.replace('<<session_id>>', premvtSessionUUID),
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPremiumSessionBySessionId = async (premvtSessionId) => {
  try {
    if (premvtSessionId === '') {
      throw new Error('premvtSessionId is required');
    }
    const res = await get(
      PREMVT_SESSION_DETAIL_API.replace('<<session_id>>', premvtSessionId),
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPremiumSessionBlueJeans = async (premvtSessionId) => {
  try {
    if (premvtSessionId === '') {
      throw new Error('premvtSessionId is required');
    }

    const _bjApi = `${PREMVT_SESSION_DETAIL_API.replace(
      '<<session_id>>',
      premvtSessionId,
    )}/bluejeans`;
    const res = await get(_bjApi);

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPremiumSessionBigBlueButton = async (premvtSessionId) => {
  if (!premvtSessionId) {
    throw new Error('premvtSessionId is required');
  }

  const _bbbApi = `${PREMVT_SESSION_DETAIL_API.replace(
    '<<session_id>>',
    premvtSessionId,
  )}/bigbluebutton`;
  const res = await get(_bbbApi);
  return res.data;
};

export const getPremiumInstructorsRHLCDetails = async (
  instructorsUsernamesList,
) => {
  const usernamesString = instructorsUsernamesList.join(',');
  try {
    if (!usernamesString) {
      throw new Error('instructorsUsernamesList is required');
    }

    const res = await post(PREMVT_SESSION_INSTRUCTORS_DETAILS_API, {
      instructors: usernamesString,
    });

    return res.data.instructor_rhlc_info;
  } catch (error) {
    throw new Error(error);
  }
};

export const sendPremiumSessionSurvey = async (
  premvtSessionId,
  score,
  reason,
) => {
  try {
    const res = await post(
      PREMVT_SESSION_SURVEY_API.replace('<<session_id>>', premvtSessionId),
      { score, reason },
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const markPremiumSessionSurveyAsSeen = async (premvtSessionId) => {
  try {
    const res = await post(
      PREMVT_SESSION_SURVEY_API.replace('<<session_id>>', premvtSessionId),
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default getPremiumSessions;
