import { action, observable } from 'mobx';
import { createContext } from 'react';
import { getStuckLabs } from '../../../../services/LabService';

class StuckLabsStore {
  @observable stuckLabs = [];

  @action getStuckLabs = async () => {
    try {
      const labs = await getStuckLabs();
      this.stuckLabs = labs;
    } catch (err) {
      console.error("Couldn't fetch stuck labs");
    }
  };
}

export default StuckLabsStore;
export const stuckLabsStoreContext = createContext(null);
