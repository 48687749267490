import { useEffect } from 'react';

export default function usePageChange(pageIdParam, courseStore, callback) {
  useEffect(() => {
    // this triggers only when user changes the page manually, saving the progress in the process
    if (courseStore.loadedInitialData) {
      courseStore.changePageContent(pageIdParam);
      callback?.();
    }
  }, [pageIdParam]);
}
