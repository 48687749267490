import React, { Component } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Button } from '@patternfly/react-core';
import { withTranslation } from 'react-i18next';
import Store from './store';
import './styles.scss';

@inject('routerStore')
@withTranslation()
@observer
class Matching extends Component {
  static propTypes = {
    routerStore: PropTypes.object.isRequired,
    chapter: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    language: PropTypes.string,
  };

  static defaultProps = {
    language: 'en-US',
  };

  constructor(props) {
    super(props);

    this.store = new Store();
  }

  componentDidMount() {
    const { routerStore, chapter } = this.props;
    const { route } = routerStore;
    this.store.chapter = chapter;
    this.store.slug = route.params.slug;
    this.store.setLabelData(route.params.course, chapter);

    this.updateDraggables();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.store.currentLanguage) {
      this.store.currentLanguage = this.props.language;
      this.store.matchedArray = [];
      this.updateDraggables('show');
    }
  }

  allowDrop = (ev) => {
    if (!ev.target.draggable) {
      ev.preventDefault();
    }

    return false;
  };

  drag = (ev, label) => {
    ev.dataTransfer.setData('label', label);
    this.store.setDragItem.dragged = ev.currentTarget;
  };

  updateDraggable = (visibility, droppedOn, draggable) => {
    draggable.displayClassName = visibility;
    draggable.droppedOn = droppedOn;
  };

  drop = (ev) => {
    ev.preventDefault();
    const label = this.store.setDragItem.dragged.id;
    let elem = this.store.setDragItem.dragged;
    // replace the target area with the new element that is being dropped
    const droppedOption = this.store.draggablesArray.find(
      (d) => d.droppedOn === ev.target.className,
    );

    const hasDroppedElem = ev.target.querySelectorAll?.('*').length > 0;

    if (hasDroppedElem && !droppedOption) {
      // do nothing when the target area (cell) already has a solution bubble (green), but not an option (blue)
      return;
    }

    if (ev.target?.className?.includes('roc_matching_label')) {
      // do nothing when the target area is the solution green bubble instead of the cell. We don't want to put the blue bubble inside the green bubble
      return;
    }

    if (droppedOption) {
      ev.target.querySelectorAll('*').forEach((n) => n.remove());
      this.updateDraggable('show', '', droppedOption);
    }

    // if element is dropped on an empty target area,
    // clone the dragged element before appending
    // also bind the draggable event to make the cloned elment draggable again.
    // Note: CloneNode doesnt clone events of the element.
    if (this.store.setDragItem?.dragged?.parentElement?.tagName !== 'TD') {
      elem = elem.cloneNode(true);
      elem.addEventListener('dragstart', (evt) => this.drag(evt, label));
    }
    ev.target.appendChild(elem);
    this.store.setDragItem.draggedItems.push(ev.target);

    // reset correct/incorrect classes from the dropped element
    elem.classList?.remove('incorrect');
    elem.classList?.remove('correct');

    // find the dragged element and set the visibility to none.
    const draggedItemIndex = this.store.draggablesArray.findIndex(
      (d) => d.label === label,
    );
    if (draggedItemIndex >= 0) {
      this.store.draggablesArray[draggedItemIndex].displayClassName = 'hide';
      this.store.draggablesArray[draggedItemIndex].droppedOn =
        ev.target.className;
    }
  };

  handleReset = () => {
    const { routerStore, chapter } = this.props;
    const { route } = routerStore;
    this.store.showSolution = false;
    this.store.resetQuizData(route.params.course, chapter);
    this.updateDraggables('show');
  };

  handleSolution = () => {
    this.store.removeItem();
    this.store.matchedArray = [];
    this.store.showSolution = true;
  };

  handleCheck = () => {
    this.store.showSolution = false;
    this.store.checkData();
  };

  updateDraggables = (visibility) => {
    const newDraggables = this.store.draggablesArray.map((draggable) => {
      this.updateDraggable(visibility, '', draggable);

      return draggable;
    });
    newDraggables.sort(() => Math.random() - Math.random());
    this.store.setdraggablesArray = newDraggables;
  };

  getTextNode = (node) => {
    if (node.type === 'text' && node.data) {
      return node.data.trim();
    }

    if (node.children?.length) {
      return this.getTextNode(node.children[0]);
    }

    return null;
  };

  render() {
    const { data, t } = this.props;
    const htmlParser = new DOMParser();
    const node = htmlParser.parseFromString(data, 'text/html');
    const html = node.body.innerHTML;

    const parserOption = {
      replace: ({ attribs, children }) => {
        if (attribs && attribs.class === 'solution') {
          const label = this.getTextNode(children?.[0]);
          if (label) {
            this.store.storeMatchedLabel(label, this.store.currentLanguage);
          }
          return (
            <td
              className={label}
              onDrop={(e) => this.drop(e)}
              onDragOver={(e) => this.allowDrop(e)}
            >
              {this.store.showSolution && (
                <div className="roc_matching_label correct">{label}</div>
              )}
            </td>
          );
        }

        return '';
      },
    };

    return (
      <div className="matching">
        <div className="matching-pool clearfix">
          {this.store.draggablesArray.length
            ? this.store.draggablesArray.map((d) => (
                <div
                  className="boxable_component"
                  style={{ display: 'inline-block' }}
                  key={`${d.label}@${d.language}`}
                >
                  <div
                    className={`roc_matching_label ${d.displayClassName}`}
                    draggable="true"
                    onDragStart={(e) => this.drag(e, d.label)}
                    id={d.label}
                  >
                    {d.label}
                  </div>
                </div>
              ))
            : ''}
        </div>
        {Parser(html, parserOption)}

        <div className="interactive_buttons">
          <Button
            variant="primary"
            type="submit"
            onClick={this.handleCheck}
            data-analytics-id={`check-btn-matchingQuiz-ole-lp-${Store.slug}-${Store.chapter}`}
          >
            {t('Check')}
          </Button>
          <Button
            variant="tertiary"
            onClick={this.handleReset}
            data-analytics-id={`reset-btn-matchingQuiz-ole-lp-${Store.slug}-${Store.chapter}`}
          >
            {t('Reset')}
          </Button>
          <Button
            variant="secondary"
            onClick={this.handleSolution}
            data-analytics-id={`show-btn-matchingQuiz-ole-lp-${Store.slug}-${Store.chapter}`}
          >
            {t('Show Solution')}
          </Button>
        </div>
      </div>
    );
  }
}

export default Matching;
