/**
 * @file Provides utility methods to support authentication/authorization from the frontend
 * @author Joshua Jack <jjack@redhat.com>
 */

import querystring from 'querystring';
import { get, post } from 'axios';

import { API_LOGIN, API_LOGOUT, API_IMPERSONATION } from '../config/constants';
import { logger } from '../config/logging';
import { postEvent } from './EventService';

export const login = (username, password) =>
  new Promise((resolve, reject) => {
    post(
      API_LOGIN,
      querystring.stringify({
        username,
        password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      },
    )
      .then((user) => {
        logger.info('post login event');
        postEvent('user_login');
        resolve(user.data);
      })
      .catch((e) => reject(e));
  });

export const logout = async () => {
  try {
    const response = await get(API_LOGOUT);

    const { logout_url: logoutUrl } = response?.data || {};

    if (logoutUrl) {
      window.location.href = logoutUrl;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const impersonate = async (username) =>
  new Promise((resolve, reject) => {
    post(`${API_IMPERSONATION}?username=${username}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
