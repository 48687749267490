import { action, observable } from 'mobx';
import { getExamCollateral } from '../../../../services/ExamService';
import { DEFAULT_LANGUAGE } from '../../../../config/constants';

class ExamCardStore {
  @observable collateral = {};

  @action getExamCollateral = async (code, language = DEFAULT_LANGUAGE) => {
    if (this.collateral.doc_id === `${code}@@${language}`) {
      return this.collateral;
    }

    let collateral;
    try {
      collateral = await getExamCollateral(code, language);
    } catch (error) {
      // if no collateral available in user's preferred language, fetch in English

      collateral = await getExamCollateral(code);
    }

    // TODO: remove this when exam collateral API gets fixed
    // Currently, if there's no collateral found, it returns an empty response instead of 404
    if (!collateral) {
      collateral = await getExamCollateral(code);
    }

    if (collateral) {
      this.collateral = collateral;
    }

    return this.collateral;
  };
}

export default ExamCardStore;
