/* eslint-disable camelcase */
import { observable, computed } from 'mobx';
import moment from 'moment-timezone';

class DashboardStore {
  @observable data = undefined;

  @observable userSubscription = undefined;

  @observable timeDifference = 61;

  @computed get countDown() {
    const now = moment();
    const start = moment(this.offering.start_time);

    this.timeDifference = start.diff(now, 'minutes');

    if (this.timeDifference < 61 && this.timeDifference > 0) {
      this.isLessThanAnHour = true;
    }

    return this.timeDifference;
  }
}

export default DashboardStore;
