import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-mobx-router5';
import SVG from 'react-inlinesvg';
import BaseCard from 'components/Card';
import { getOfferingTitleByLanguage } from 'services/UtilityService';
import ExpertIcon from 'img/icons/expertextra_small.svg';
import LockIcon from 'img/icons/lock.svg';
import { handleError } from 'services/ErrorService';
import SeminarCollateral from './SeminarCollateral';
import ExpertSeminarStore from './store';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const ExpertExtrasCard = ({
  offering,
  canAccess,
  actionButtonsArray,
  disableActionButtons,
  allowOrderingOffering,
  onOfferingOrderChange,
}) => {
  const { code, version, translations } = offering;

  const { i18n, t } = useTranslation();

  const store = useLocalStore(() => new ExpertSeminarStore());

  const lockedTooltip = (
    <Tooltip id="locked-tooltip">
      {t('This content is not available as part of your subscription')}
    </Tooltip>
  );

  const getIcon = () => {
    return canAccess ? (
      <SVG src={ExpertIcon} title={t('Expert Extra')} width={40} height={40} />
    ) : (
      <OverlayTrigger
        placement="bottom"
        trigger={['hover']}
        overlay={lockedTooltip}
      >
        <div className="content-locked">
          <SVG
            src={LockIcon}
            title={t('Content is locked')}
            width={40}
            height={40}
            style={{ pointerEvents: 'auto' }}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const getTitle = () => {
    const title = getOfferingTitleByLanguage(
      offering,
      getTranslation(),
      i18n.language,
    );

    return title;
  };

  const getColorCard = () => {
    return '#5073B0';
  };

  const actionsForExpertSeminar = () => {
    let actionsToReturn = [];
    if (canAccess) {
      if (actionButtonsArray?.length > 0) {
        actionsToReturn = actionButtonsArray;
        return actionsToReturn;
      }

      actionsToReturn.push(
        <Link
          className={`course-view ${
            disableActionButtons && 'course-actions-disabled'
          }`}
          href={`/seminar/${code}-${version}`}
          {...(disableActionButtons && { disabled: true })}
          routeName="expertSeminars:view"
          routeParams={{
            slug: `${code}-${version}`,
          }}
          data-analytics-id="view-btn-expert-card-lp"
        >
          {t('View').toUpperCase()}
        </Link>,
      );
    }

    return actionsToReturn;
  };

  return (
    <BaseCard
      customClassName="expert-extras-card"
      title={`${getTitle()}`}
      cardColor={getColorCard()}
      iconClassName="expert-extras"
      svgIconElement={getIcon()}
      actionButtonsArray={actionsForExpertSeminar()}
      actionToggle={() => store.getSeminarCollateral(code, version)}
      allowOrderingOffering={allowOrderingOffering}
      onOfferingOrderChange={onOfferingOrderChange}
    >
      <div className="seminar-collateral">
        <React.Fragment>
          <SeminarCollateral collateral={store.collateral} />
        </React.Fragment>
      </div>
    </BaseCard>
  );
};

ExpertExtrasCard.propTypes = {
  offering: PropTypes.object,
  canAccess: PropTypes.bool,
  actionButtonsArray: PropTypes.array,
  disableActionButtons: PropTypes.bool,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
};

ExpertExtrasCard.defaultProps = {
  offering: {},
  canAccess: 'false',
  actionButtonsArray: [],
  disableActionButtons: false,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
};

export default withErrorBoundary(
  observer(ExpertExtrasCard),
  ErrorMessage,
  handleError,
);
