import { createContext } from 'react';
import { action, observable, toJS } from 'mobx';
import * as merge from 'deepmerge';
import { sortBy, uniqBy } from 'lodash';
import { updateAlert, createAlert } from '../../../services/AlertService';
import { getCatalogEntries } from '../../../services/CatalogService';
import { getSubscriptions } from '../../../services/SubscriptionService';

class AlertFormStore {
  @observable schema = {};

  @observable UIschema = {};

  @observable formData = {};

  @observable loading = false;

  // TODO: enable when language will be used
  // @action populateLanguageOptions = async (vocabularyStore, language) => {
  //   const languages = await vocabularyStore.getVocabularyByNamespace(
  //     'languages',
  //     language,
  //   );
  //   const languageValues = languages.map(lang => lang.token);
  //   const languageLabels = languages.map(lang => lang.display_name);

  //   const updatedSchema = {
  //     properties: {
  //       language: {
  //         enum: languageValues,
  //         enumNames: languageLabels,
  //       },
  //     },
  //   };

  //   this.schema = merge(toJS(this.schema), updatedSchema);
  //   return this.schema;
  // };

  @action populateCourseOptions = async () => {
    try {
      const courses = await getCatalogEntries();
      const validEnglishCourses = courses.filter(
        (course) => course.language === 'en-US' && course.slug,
      );
      const uniqueCourses = uniqBy(validEnglishCourses, 'slug');
      const sortedCourses = sortBy(uniqueCourses, [(item) => item.slug]);
      const courseValues = sortedCourses.map((entry) => entry.slug);
      const courseLabels = sortedCourses.map(
        (entry) => `${entry.slug?.toUpperCase()}: ${entry.title}`,
      );

      // add home page as an option
      courseValues.unshift('home', 'login');
      courseLabels.unshift('Home page', 'Login page');

      const updatedSchema = {
        properties: {
          environment: {
            items: {
              enum: courseValues,
              enumNames: courseLabels,
            },
          },
        },
      };

      this.schema = merge(toJS(this.schema), updatedSchema);
    } catch (error) {
      console.error(`Couldn't populate the course options:`, error);
    }
    return this.schema;
  };

  @action populateSubscriptionOptions = async () => {
    try {
      const subscriptions = await getSubscriptions();
      subscriptions.sort((s1, s2) => {
        if (s1.slug < s2.slug) return -1;
        if (s1.slug > s2.slug) return 1;
        return 0;
      });
      subscriptions.push(
        ...[
          {
            slug: 'alacarte',
            title: 'A La Carte',
          },
        ],
      );
      const subscriptionValues = subscriptions.map(
        (subscription) => subscription.slug,
      );
      const subscriptionLabels = subscriptions.map(
        (subscription) =>
          `${subscription.slug.toUpperCase()}: ${subscription.title}`,
      );
      const updatedSchema = {
        properties: {
          subscriptions: {
            items: {
              enum: subscriptionValues,
              enumNames: subscriptionLabels,
            },
          },
        },
      };

      this.schema = merge(toJS(this.schema), updatedSchema);
    } catch (error) {
      console.error(`Couldn't populate subscription options:`, error);
    }
  };

  @action async createAlert() {
    this.loading = true;
    try {
      await createAlert(this.formData);
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  @action async updateAlert(uuid) {
    this.loading = true;
    try {
      await updateAlert(uuid, this.formData);
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }
}

export const alertFormContext = createContext(() => new AlertFormStore());
export default AlertFormStore;
