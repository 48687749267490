import { get, put } from 'axios';

import { SESSION_API_BASE } from '../config/constants';

export const getSessionExpiry = async () => {
  try {
    const response = await get(`${SESSION_API_BASE}expiry`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const extendSession = async (data) => {
  try {
    const response = await put(`${SESSION_API_BASE}expiry`, data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
