import { useEffect } from 'react';

// workaround for preventing Intercom from overlapping HUD.
// TODO: Definitve solution is to use a Custom launcher for Intercom instead of using this CSS override
export default function useAutoMoveIntercom(): void {
  useEffect(() => {
    document.body.classList.toggle('body-intercom-for-hud', true);

    return () => {
      document.body.classList.toggle('body-intercom-for-hud', false);
    };
  }, []);
}
