import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import {
  Row,
  PageHeader,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalTitle,
} from 'react-bootstrap';
import { handleError } from '../../../../services/ErrorService';
import GenericViewError from '../../../../components/Error/GenericViewError';
import { storesContext } from '../../../../stores';
import LabEnvironmentStore from '../../../../components/LabEnvironment/store';
import { LAB_DATA_POLLING_SECONDS } from '../../../../config/constants';
import LabResolveUIStore from './store';

const StuckLabResolveView = () => {
  const { t } = useTranslation();
  const { routerStore } = useContext(storesContext);
  const { id } = routerStore.route?.params;
  const labEnvironmentStore = useLocalStore(() => new LabEnvironmentStore());
  const uiStore = useLocalStore(() => new LabResolveUIStore());
  const { modal } = uiStore;

  useEffect(() => {
    labEnvironmentStore.pollIntervalSecs = LAB_DATA_POLLING_SECONDS;
    labEnvironmentStore.bypassSlugCheck = true;
    labEnvironmentStore.courseSlug = '';

    return () => {
      labEnvironmentStore.pollIntervalSecs = 0;
    };
  }, []);

  useEffect(() => {
    labEnvironmentStore.userLab.doc_id = id;
    labEnvironmentStore.fetchUserLab(); // 'true' stands for bypassing slug check
  }, [id]);

  const closeModal = () => {
    uiStore.modal.opened = false;
  };

  const showModal = (title, confirmText, confirmFunc) => {
    modal.opened = true;
    modal.title = title;
    modal.confirmText = confirmText;
    modal.confirmFunc = confirmFunc;
  };

  const startLab = () => {
    labEnvironmentStore.doCommand('app_start');
  };

  const stopLab = () => {
    labEnvironmentStore.doCommand('app_stop');
  };

  const deleteLab = () => {
    closeModal();
    labEnvironmentStore.doCommand('app_delete');
  };

  const confirmAndDeleteLab = () => {
    showModal(
      'Are you sure you want to delete this lab? It cannot be undone',
      'Delete lab',
      deleteLab,
    );
  };

  return (
    <div className="container-grid-responsive">
      <Row>
        <PageHeader>
          {t('Lab resolver')}, {id}
        </PageHeader>
        <Button onClick={startLab}>{t('Start')}</Button>
        <Button onClick={stopLab}>{t('Stop')}</Button>
        <Button onClick={confirmAndDeleteLab}>{t('Delete')}</Button>
        <article>
          <h2>{t('Lab document')}</h2>
          <pre>{JSON.stringify(labEnvironmentStore.userLab, null, 4)}</pre>
        </article>
        <Modal tabIndex="-1" show={modal.opened} onHide={closeModal}>
          <ModalHeader tabIndex="-1" closeButton>
            <ModalTitle>{modal.title}</ModalTitle>
          </ModalHeader>
          <ModalFooter tabIndex="-1">
            <Button bsStyle="primary" onClick={modal.confirmFunc}>
              {modal.confirmText}
            </Button>
            <Button onClick={closeModal}>{t('No')}</Button>
          </ModalFooter>
        </Modal>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(StuckLabResolveView),
  GenericViewError,
  handleError,
);
