import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { Alert, Button, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';

import './styles.scss';

const PendingManagerForm = () => {
  const { organizationStore, userStore } = useContext(storesContext);
  const { t } = useTranslation();
  const handleAccept = async () => {
    await organizationStore.acceptPendingManager(userStore.user.uuid);
    window.location.reload();
  };

  return (
    <Alert
      bsStyle="info"
      className="organization-invitation-alert"
      data-analytics-id="pending-manager-banner-notification-lp"
    >
      <h4>{t('Terms and Conditions')}</h4>
      <p>
        {`${t(
          'You have been invited to manage the reports for users of the Red Hat Learning Subscription by',
        )}
         ${organizationStore.organization.name}.
         ${t(
           'Please read the terms below, and click "Accept" to be able to view data of users that have been assigned to you.',
         )}`}
      </p>
      <br />
      <b>
        {t(
          `Please note that while Red Hat is making the option available for your organization as part of your Red Hat Enterprise Learning Subscription to access a customized management dashboard showing your enterprise users’ subscription-based learning progress and results, your enterprise users may request that their personal data not be shared or be deleted. In such an event, Red Hat may honor such a request. Please ensure to have the appropriate conversations with your employees with regard to the importance of their learning progress’ visibility. Should any of your enterprise users elect not to share their personal information or progress report with regard to their subscription, they will still have access to the Red Hat Enterprise Learning Subscription provided by your organization, however, their learning progress will not be visible to your organization’s designated managers on the account or the portal admin(s).`,
        )}
      </b>
      <Form className="inline">
        <FormGroup>
          <Button bsStyle="default" onClick={handleAccept}>
            {t('Accept')}
          </Button>
        </FormGroup>
      </Form>
    </Alert>
  );
};

export default withErrorBoundary(
  observer(PendingManagerForm),
  GenericViewError,
  handleError,
);
