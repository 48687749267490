import { observable, action } from 'mobx';

class TypeAheadSearchStore {
  @observable selectedSearchItem = [];

  @action setSearchItem(s) {
    this.selectedSearchItem = s;
  }
}

export default TypeAheadSearchStore;
