import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@patternfly/react-core';

function ManagerNameCell({ row, cell }) {
  const { t } = useTranslation();
  return (
    <Tooltip
      animationDuration={150}
      entryDelay={50}
      exitDelay={50}
      isContentLeftAligned
      position="top"
      content={
        <div>
          {row?.original?.manager_email && (
            <p className="course-progress__no-margin">
              {t('Email:')} {row?.original?.manager_email}
            </p>
          )}
          {row?.original?.manager_username && (
            <p className="course-progress__no-margin">
              {t('Username:')} {row?.original?.manager_username}
            </p>
          )}
        </div>
      }
    >
      <span>
        {cell?.value ||
          row?.original?.manager_email ||
          row?.original?.manager_username}
      </span>
    </Tooltip>
  );
}

ManagerNameCell.propTypes = {
  row: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired,
};

export default ManagerNameCell;
