import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import useLocalStorageState from 'hooks/useLocalStorageState';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';

import './styles.scss';

export default function ExpandableSection({
  title,
  children,
  localStorageStateKey,
  initialState,
  color,
}) {
  const { t } = useTranslation();

  const [isExpanded, setExpanded] = useLocalStorageState(
    localStorageStateKey,
    initialState,
  );

  return (
    <div className="expandable-section">
      <div
        className={`expandable-section__clickable-strip expandable-section__clickable-strip--${color}`}
        role="button"
        tabIndex={0}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div>{title}</div>
        <div className="expandable-section__arrow-icon">
          <SVG src={isExpanded ? CollapseIcon : ExpandIcon}>
            {isExpanded ? t('Collapse') : t('Expand')}
          </SVG>
        </div>
      </div>
      {isExpanded ? children : null}
    </div>
  );
}

ExpandableSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  localStorageStateKey: PropTypes.string,
  initialState: PropTypes.bool,
  color: PropTypes.oneOf(['dark', 'light']),
};

ExpandableSection.defaultProps = {
  initialState: true,
  children: null,
  color: 'dark',
  localStorageStateKey: undefined,
};
