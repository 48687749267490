import { Text, TextVariants, Button } from '@patternfly/react-core';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';

import './styles.scss';
import { KalturaWidget } from '@ole-ui/ole-ui-components';
import { KALTURA_CONFIGS } from 'config/constants';

function FreeTrialOffering() {
  const { t } = useTranslation();
  const { routerStore } = useContext(storesContext);

  return (
    <div className="free-trial-offering">
      <Text component={TextVariants.h1} className="free-trial-offering__title">
        {t("Try Red Hat Learning Subscription's new 14-day trial")}
      </Text>
      <div className="free-trial-offering__row">
        <div className="free-trial-offering__card">
          <Text
            component={TextVariants.h3}
            className="free-trial-offering__card-title"
          >
            {t('Start learning with your 14-day free trial')}
          </Text>
          <Text component={TextVariants.p}>
            {t(
              'Red Hat Learning Subscription is an on-demand prescriptive solution for keeping pace with Red Hat technologies. Take advantage of a free trial to get a preview of the portal, access to chapters 1 & 2 of every course in the catalog, and 4 hours of cloud-based lab time.',
            )}
          </Text>
          <Button
            data-analytics-id="start-btn-free-trial-offering-lp"
            onClick={() => {
              routerStore.navigate('free-trial-registration');
            }}
          >
            {t('Start free trial')}
          </Button>
        </div>
        <div className="free-trial-offering__video">
          <KalturaWidget
            className="landing-mat__tutorial-video"
            partnerId={KALTURA_CONFIGS.partnerId}
            playerId={KALTURA_CONFIGS.freeTrialTutorial.uiConfId}
            entryId={KALTURA_CONFIGS.freeTrialTutorial.entryId}
            isPlaylist={false}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(FreeTrialOffering);
