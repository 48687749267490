import React from 'react';
import PropTypes from 'prop-types';

const InstructorName = (props) => {
  const { instructor } = props;

  return instructor.rhlc_profile_url ? (
    <a
      href={instructor.rhlc_profile_url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {instructor.name}
    </a>
  ) : (
    instructor.name
  );
};

InstructorName.propTypes = {
  instructor: PropTypes.object.isRequired,
};

export default InstructorName;
