import { action, observable, computed } from 'mobx';
import { getTechOverview } from 'services/TechOverviewService';
import {
  KALTURA_CONFIGS,
  UPDATE_VIDEO_PROGRESS_POLLING_MS,
} from 'config/constants';

class TechOverviewStore {
  @observable payload = null;

  @observable isLoading = true;

  @observable slug = '';

  @observable versions = [];

  @observable progress = {};

  @observable timestamp = 0;

  @observable currentVideo = '';

  @observable interval = undefined;

  constructor(catalogStore, slug, progressStore) {
    this.catalogStore = catalogStore;
    this.slug = slug;
    this.progressStore = progressStore;
  }

  @action getTechOverview = async (slug) => {
    this.isLoading = true;
    try {
      const payload = await getTechOverview(slug);
      if (payload) {
        this.payload = payload; // this may change
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
    return this.payload;
  };

  @computed get isLatestVersion() {
    return (
      this.slug === this.catalogStore.getLatestVersion(this.slug.split('-')[0])
    );
  }

  @computed get title() {
    return this.payload?.title;
  }

  @computed get videoEntryId() {
    if (
      Array.isArray(this.payload?.kaltura?.videos) &&
      this.payload?.kaltura?.videos.length > 0
    ) {
      return this.payload?.kaltura?.videos[0];
    }
    return null;
  }

  @computed get playerPartnerId() {
    return this.payload?.partner_id || KALTURA_CONFIGS.partnerId;
  }

  @computed get playerUIConfigId() {
    return (
      this.payload?.custom_player_id ||
      KALTURA_CONFIGS.techOverviewPlayer.uiConfId
    );
  }

  @computed get isPlaylist() {
    return Boolean(this.payload?.is_playlist);
  }

  @action setVersions() {
    this.versions = this.catalogStore.getSortedVersions(
      this.slug.split('-')[0],
    );
    return this.versions;
  }

  @action startPolling() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.saveProgress();
    }, UPDATE_VIDEO_PROGRESS_POLLING_MS);
  }

  @action stopPolling() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.saveProgress();
  }

  @action initializeProgress(player) {
    player.playlist?._mediaInfoList?.forEach((video) => {
      this.progress[video.entryId] = 0;
    });
  }

  @action updateProgress(player) {
    const progress = parseFloat(
      (player.normalizedCurrentTime / player.normalizedDuration).toFixed(2),
    );
    if (
      Object.keys(this.progress).length === 0 &&
      !!player.playlist._mediaInfoList
    ) {
      this.initializeProgress(player);
    }
    if (progress && this.progress) {
      if (progress > this.progress[player.sources.id]) {
        this.progress[player.sources.id] = progress;
      }
      this.timestamp = player.normalizedCurrentTime;
      this.currentVideo = player.sources.id;
    }
  }

  @action saveProgress() {
    this.progressStore.updateVideoProgress(
      this.slug,
      this.progress,
      this.currentVideo,
      this.timestamp,
    );
  }
}

export default TechOverviewStore;
