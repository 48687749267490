import React from 'react';
import { ChartFrame, LineTimeSeriesChart } from '@ole-ui/ole-ui-components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

import './styles.scss';

type ConsumptionDataPoint = {
  count: number;
  cumulative_count: number;
  key: string;
};

type Props = {
  completedExams?: {
    retake_available?: {
      series_by_month?: ConsumptionDataPoint[];
    };
    completed?: {
      series_by_month?: ConsumptionDataPoint[];
    };
    retake_complete?: {
      series_by_month?: ConsumptionDataPoint[];
    };
    retake_scheduled?: {
      series_by_month?: ConsumptionDataPoint[];
    };
  };
  isLoading?: boolean;
};

const CompletedExamsChartFrame = ({ completedExams, isLoading }: Props) => {
  const { t } = useTranslation();

  const monthlyChart = (
    <LineTimeSeriesChart
      interval="month"
      isCumulative={false}
      lines={[
        {
          key: 'completed_line',
          title: t('Completed'),
          data: completedExams?.completed?.series_by_month || [],
        },
        {
          key: 'retake_available_line',
          title: t('Retake Available'),
          data: completedExams?.retake_available?.series_by_month || [],
        },
        {
          key: 'retake_scheduled_line',
          title: t('Retake Scheduled'),
          data: completedExams?.retake_scheduled?.series_by_month || [],
        },
        {
          key: 'retake_completed_line',
          title: t('Retake Completed'),
          data: completedExams?.retake_complete?.series_by_month || [],
          lineStyle: { line_color: '#C9190B' },
        },
      ]}
    />
  );

  const popoverContent = (
    <>
      <p>
        <b>{t('Completed exams:')}</b>{' '}
        {t('Amount of exams completed each month')}
      </p>
      <p>
        <i>{t('Scroll to zoom the chart, click and drag to pan the chart.')}</i>
      </p>
    </>
  );

  return (
    <ChartFrame
      subTitle={t('exams')}
      popoverHeader={<div>{t('exams')}</div>}
      popoverContent={popoverContent}
      tabs={[
        {
          key: 'monthly_completed_exams_line_chart',
          title: t('Monthly'),
          chart: monthlyChart,
          isLoading,
        },
      ]}
      className=""
    />
  );
};

export default withErrorBoundary(
  observer(CompletedExamsChartFrame),
  ComponentError,
  handleError,
);
