import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

@withTranslation()
@observer
class OCPSharedLabComponent extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    labComponent: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  doNull() {
    console.warn(this.props.action);
  }

  render() {
    const { t } = this.props;
    const { context } = this.props.labComponent;
    if (!(context && context.idm_username)) {
      return null;
    }

    const apiUrl = context.api_url;
    const consoleUrl = context.console_url;

    return (
      <div className="card-white" style={{ margin: '20pt' }}>
        <div className="card-title">OpenShift Details</div>
        <div className="card-body" style={{ margin: '20pt' }}>
          <table className="table">
            <tbody>
              <tr key="username">
                <th>{t('Username')}</th>
                <th>RHT_OCP4_DEV_USER</th>
                <td>{context.idm_username}</td>
              </tr>
              <tr key="password">
                <th>{t('Password')}</th>
                <th>RHT_OCP4_DEV_PASSWORD</th>
                <td>{context.idm_password}</td>
              </tr>
              <tr key="api">
                <th>{t('API Endpoint')}</th>
                <th>RHT_OCP4_MASTER_API</th>
                <td>
                  <a href={apiUrl} rel="noopener noreferrer" target="_blank">
                    {apiUrl}
                  </a>
                </td>
              </tr>
              <tr key="console">
                <th>{t('Console Web Application')}</th>
                <th />
                <td>
                  <a
                    href={consoleUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {consoleUrl}
                  </a>
                </td>
              </tr>
              <tr key="cluster_id">
                <th>{t('Cluster Id')}</th>
                <th />
                <td>{context.cluster_id}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default OCPSharedLabComponent;
