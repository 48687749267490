import { computed, observable, action } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { extractFromSlug } from 'helpers/utils';

class CourseProgressTableStore {
  constructor(progressStore, organizationStore, catalogStore) {
    this.progressStore = progressStore;
    this.organizationStore = organizationStore;
    this.catalogStore = catalogStore;
  }

  @observable courseSKUTranslation = 'Course SKU: ';

  @observable versionTranslation = ', Version: ';

  @observable userCourseTranslation = 'User/Course ';

  @observable reporteesProgress = [];

  // Transform raw data to fit datatable
  @computed get transformedData() {
    const result = {};
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;

    if (selectedUsers.length === 0) {
      return [];
    }

    this.reporteesProgress
      .filter((a) => selectedUsers.includes(a.username))
      .forEach((item) => {
        if (!result[item.username]) {
          const userObject = this.organizationStore.getUserByUsername(
            item.username,
          );
          result[item.username] = {
            username: !isEmpty(trim(userObject.preferred_name))
              ? `${item.username} (${userObject.preferred_name})`
              : `${item.username}`,
          };
        }

        result[item.username][item.course_slug] = `${Math.round(
          item.total_progress * 100,
        )}%`;
      });

    return Object.values(result).sort((a, b) => {
      // Extracting the full name from the displayed username (Full Name) format
      // in order to sort by Full name even though the username is also appearing
      const fullNameA = a.username.replace(/.*\(|\).*/g, '');
      const fullnameB = b.username.replace(/.*\(|\).*/g, '');
      return fullNameA.localeCompare(fullnameB);
    });
  }

  // Create datatable columns based off selected users
  @computed get tableColumns() {
    const courseProgress = this.reporteesProgress;
    const selectedUsers = this.organizationStore.selectedUsersByManagerList; // Optimize into computed function
    const uniqueCourses = [
      ...new Set(
        courseProgress
          .filter((courseObject) =>
            selectedUsers.includes(courseObject.username),
          )
          .filter(
            (progress, index, self) =>
              self.findIndex(
                (course) => course.course_slug === progress.course_slug,
              ) === index,
          )
          .map((item) => {
            let { code } = extractFromSlug(item.course_slug);

            if (code.slice(-2) === 'vc') {
              code = code.slice(0, -2); // remove vc from course code
            }
            const slug = item.course_slug.split('-')[1];
            let courseTitle = `${this.courseSKUTranslation}${code}${this.versionTranslation}${slug}`;
            const catalogEntries =
              this.catalogStore.groupedAllCatalogEntries[code];

            // If course still exists in cataloge, get its tittle
            if (
              this.organizationStore.hasCatalogData &&
              catalogEntries &&
              catalogEntries[slug]?.translations
            ) {
              this.catalogStore.groupedAllCatalogEntries[code][
                slug
              ].translations.forEach((tr) => {
                if (tr.language === 'en-US') {
                  courseTitle = tr.title;
                }
              });
            }
            const extendedUniqueObject = {
              course_slug: item.course_slug,
              username: item.username,
              title: courseTitle,
            };

            return extendedUniqueObject;
          }),
      ),
    ];

    // populate with data table field properties
    const columns = [
      {
        id: 'username',
        accessor: 'username',
        Header: this.userCourseTranslation,
        sort: true,
        patternflyThProps: {
          className: 'custom-table-header-main',
        },
      },
    ].concat(
      uniqueCourses.map((course) => ({
        id: course.course_slug,
        accessor: (cellValue) => cellValue[course.course_slug],
        Header: () => {
          return this.organizationStore.addHover({
            tooltipText: course.title,
            text: course.course_slug,
          });
        },
        disableSortBy: true,
        patternflyThProps: {
          className: 'custom-table-header',
        },
      })),
    );

    return columns;
  }

  @action getReporteesProgress = async (organizationId, courseType) => {
    this.reporteesProgress = await this.progressStore.getReporteesProgress(
      organizationId,
      courseType,
    );
  };
}

export default CourseProgressTableStore;
