import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

import dangerIcon from '../../../img/icons/danger-icon.svg';
import './styles.scss';

const LabErrorTableRow = ({ children, key }) => {
  return (
    <span key={key} className="table-row">
      {children}
    </span>
  );
};

const LabErrorModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { labStore } = props;

  const { t } = useTranslation();

  const onOpenModal = () => setIsModalOpen(true);

  const onCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Button bsStyle="link" onClick={onOpenModal}>
        <SVG src={dangerIcon} />
      </Button>
      <Modal
        show={isModalOpen}
        bsSize="lg"
        dialogClassName="lab-error-modal container"
      >
        <Modal.Header>
          <span className="lab-error-modal-title">
            {t('Lab Environment Error Tracking')}
          </span>
          <span>{t('An error occurred when you tried to run the lab')}</span>
        </Modal.Header>

        <Modal.Body>
          <div className="lab-error-modal-content">
            {labStore.convertedComponents.map((component) => {
              return (
                component.events && (
                  <>
                    <h4>
                      {t(
                        `Events details coming from ${component.driver} driver`,
                      )}
                    </h4>
                    <div className="lab-error-table-wrapper">
                      <span className="table-header">
                        <strong>Id</strong>
                      </span>
                      <span className="table-header">
                        <strong>{t('Event Time')}</strong>
                      </span>
                      <span className="table-header">
                        <strong>{t('Resource Name')}</strong>
                      </span>
                      <span className="table-header">
                        <strong>{t('Resource Status')}</strong>
                      </span>
                      <span className="table-header">
                        <strong>{t('Resource Status Reason')}</strong>
                      </span>
                      {component.events.map((event) => (
                        <>
                          <LabErrorTableRow key={`${event.id}-id-row`}>
                            {event.id}
                          </LabErrorTableRow>
                          <LabErrorTableRow key={`${event.id}-event-time-row`}>
                            {event.event_time}
                          </LabErrorTableRow>
                          <LabErrorTableRow
                            key={`${event.id}-resource-name-row`}
                          >
                            {event.resource_name}
                          </LabErrorTableRow>
                          <LabErrorTableRow
                            key={`${event.id}-resource-status-row`}
                          >
                            {event.resource_status}
                          </LabErrorTableRow>
                          <LabErrorTableRow
                            key={`${event.id}-resource-status-reason-row`}
                          >
                            {event.resource_status_reason}
                          </LabErrorTableRow>
                        </>
                      ))}
                    </div>
                  </>
                )
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCloseModal}>{t('Close')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

LabErrorTableRow.propTypes = {
  children: PropTypes.element,
  key: PropTypes.string,
};

LabErrorTableRow.defaultProps = {
  children: null,
  key: '',
};

LabErrorModal.propTypes = {
  labStore: PropTypes.object,
};

LabErrorModal.defaultProps = {
  labStore: {},
};

export default LabErrorModal;
