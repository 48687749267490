import { observable, computed } from 'mobx';

class VersionCardStore {
  constructor(catalogStore, progressStore) {
    this.catalogStore = catalogStore;
    this.progressStore = progressStore;
  }

  @observable version = '';

  @observable courseCode = '';

  @computed get offering() {
    return (
      this.catalogStore.groupedCatalogEntries[this.courseCode][this.version] ||
      this.catalogStore.uniqueCatalogEntries[this.courseCode]
    );
  }

  @computed get progress() {
    const rolSlug = `${this.courseCode}-${this.version}`;
    const vcSlug = `${this.courseCode}vc-${this.version}`;
    const rolProgress =
      // eslint-disable-next-line
      this.progressStore.progressDict[rolSlug]?.total_progress || 0;
    const vcProgress =
      // eslint-disable-next-line
      this.progressStore.progressDict[vcSlug]?.total_progress || 0;
    return Math.max(rolProgress, vcProgress);
  }
}

export default VersionCardStore;
