import { get } from 'axios';

import { INTERACTIVE_API } from '../config/constants';

const interactiveData = (slug, chapter) =>
  new Promise((resolve, reject) => {
    get(
      INTERACTIVE_API.replace('<course-slug>', slug).replace(
        '<chapter-name>',
        chapter,
      ),
    )
      .then((response) => {
        const result = response.data.result || {};
        resolve(result);
      })
      .catch((error) => reject(error));
  });

export { interactiveData };
export default interactiveData;
