import React, { useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { SyncLoader } from 'react-spinners';
import { Button } from '@patternfly/react-core';
import Form from 'components/JSONSchemaForm';
import { storesContext } from 'stores';
import SupportStore from './store';
import './styles.scss';

const SupportPane = () => {
  const { t } = useTranslation();
  const rootStore = useContext(storesContext);

  const impactFields = {
    Medium: 3,
    High: 2,
  };

  const schemaForm = {
    type: 'object',
    required: ['title', 'description', 'subcategory', 'impact'],
    properties: {
      title: {
        type: 'string',
        title: t('Issue Title'),
      },
      description: {
        type: 'string',
        title: t('Details'),
      },
      courseCode: {
        type: 'string',
        title: t('Please select the impacted offering'),
        enum: [t('Loading catalogue...')],
        enumNames: ['Loading catalogue...'],
      },
      subcategory: {
        type: 'string',
        title: t('Support Category'),
        enum: [
          'Account or Enrollment Issues',
          'Learning Platform',
          'Lab Environment',
        ],
        enumNames: [
          t('Account or Enrollment Issues'),
          t('Learning Platform'),
          t('Lab Environment'),
        ],
      },
      impact: {
        type: 'string',
        title: t('Impact Level'),
        enum: Object.keys(impactFields),
        enumNames: [t('Medium'), t('High')],
        default: t('Medium'),
      },
      collected: {
        type: 'object',
        title: '',
        properties: {
          username: {
            type: 'string',
            title: t('Username'),
            default: rootStore.userStore.username,
          },
          name: {
            type: 'string',
            title: t('Name'),
            default: rootStore.userStore.name || t('Not set'),
          },
          email: {
            type: 'string',
            title: t('Email'),
            default: rootStore.userStore.email,
          },
          subscription: {
            type: 'string',
            title: t('Subscription'),
            default: rootStore.userStore.getSubscriptionPrettyText,
          },
        },
      },
    },
  };

  const uiSchema = {
    description: {
      'ui:widget': 'textarea',
    },
    courseCode: {
      'ui:placeholder': t('Select from catalog...'),
    },
    subcategory: {
      'ui:placeholder': t('Select category...'),
    },
    collected: {
      username: {
        'ui:readonly': true,
      },
      name: {
        'ui:readonly': true,
      },
      email: {
        'ui:readonly': true,
      },
      country: {
        'ui:readonly': true,
      },
      subscription: {
        'ui:readonly': true,
      },
    },
  };

  const store = useLocalStore(
    () => new SupportStore(rootStore, schemaForm, uiSchema, impactFields),
  );

  React.useEffect(() => {
    store.init();
  }, [store]);

  return (
    <React.Fragment>
      <h4>{!store.submitted ? t('Open a Support Case') : t('Thank You')}</h4>
      <div className="support-component">
        {!store.submitted && !store.submitting && !store.error && (
          <React.Fragment>
            <div className="support-info-wrapper">
              <p>
                {t(
                  'Fill out this form to create a ticket with our support team. Please submit this form before closing it, or you will lose your inputs. After submitting, you will receive an email with a Service Request Number, and assistance will continue through email.',
                )}
              </p>
            </div>
            <Form
              schema={store.schemaForm}
              formData={store.formData}
              uiSchema={store.uiSchema}
              onChange={(form) => {
                store.formData = form.formData;
              }}
              onSubmit={() => {
                store.submit();
              }}
            />
          </React.Fragment>
        )}
        {store.submitted && (
          <div>
            <h4>
              {t(
                'Your support case has been opened and you will receive an email response as soon as possible.',
              )}
            </h4>
            <Button
              variant="link"
              onClick={() => {
                store.formData = {};
                store.submitted = false;
                rootStore.eventStore.toggleDrawer();
                rootStore.eventStore.toggleDrawer('support_form');
              }}
            >
              {t('Open another support case')}
            </Button>
          </div>
        )}
        {store.error && (
          <div>
            <h3>{t('Error submitting support')}</h3>
            <p>{t('Please try again later.')}</p>
          </div>
        )}
        <div className="loading">
          <SyncLoader loading={store.submitting} size={10} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(SupportPane);
