import React, { useMemo } from 'react';
import {
  ChartFrame,
  TrainingHoursTimeSeriesChart,
} from '@ole-ui/ole-ui-components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

import './styles.scss';

type ConsumptionDataPoint = {
  consumption_hours: number;
  cumulative_consumption_hours: number;
  key: string;
};

type Props = {
  labHours?: {
    series_by_month?: ConsumptionDataPoint[];
    series_by_week?: ConsumptionDataPoint[];
  };
  trainingHours?: {
    series_by_month?: ConsumptionDataPoint[];
    series_by_week?: ConsumptionDataPoint[];
  };
  isLoading?: boolean;
};

const TrainingHoursChartFrame = ({
  trainingHours,
  labHours,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const sumTimeSeries = (
    timeSeriesA: ConsumptionDataPoint[],
    timeSeriesB: ConsumptionDataPoint[],
  ) => {
    const result = timeSeriesA.map((pointA, index) => {
      const pointB = timeSeriesB[index];
      return {
        ...pointA,
        consumption_hours: pointA.consumption_hours + pointB.consumption_hours,
        cumulative_consumption_hours:
          pointA.cumulative_consumption_hours +
          pointB.cumulative_consumption_hours,
      };
    });
    return result;
  };

  const monthlyChart = (
    <TrainingHoursTimeSeriesChart
      interval="month"
      isCumulative={false}
      warningText={t('*Total hours includes lab hours')}
      lines={[
        // {
        //   key: 'training_hours_invested',
        //   title: t('Training hours invested'),
        //   data: trainingHours?.series_by_month || [],
        // },
        {
          key: 'lab_hours_invested',
          title: t('Lab hours invested'),
          data: labHours?.series_by_month || [],
        },
        {
          key: 'total_hours_invested',
          title: t('Total hours invested'),
          data: useMemo(
            () =>
              sumTimeSeries(
                trainingHours?.series_by_month || [],
                labHours?.series_by_month || [],
              ),
            [trainingHours, labHours],
          ),
        },
      ]}
    />
  );

  const weeklyChart = (
    <TrainingHoursTimeSeriesChart
      interval="week"
      isCumulative={false}
      warningText={t('*Total hours includes lab hours')}
      lines={[
        // {
        //   key: 'training_hours_invested',
        //   title: t('Training hours invested'),
        //   data: trainingHours?.series_by_week || [],
        // },
        {
          key: 'lab_hours_invested',
          title: t('Lab hours invested'),
          data: labHours?.series_by_week || [],
        },
        {
          key: 'total_hours_invested',
          title: t('Total hours invested'),
          data: useMemo(
            () =>
              sumTimeSeries(
                trainingHours?.series_by_week || [],
                labHours?.series_by_week || [],
              ),
            [trainingHours, labHours],
          ),
        },
      ]}
    />
  );

  const popoverContent = (
    <>
      <p>
        <b>{t('Lab hours invested:')}</b> {t('Time spent by students on labs')}
      </p>
      <p>
        <b>{t('Total hours invested:')}</b>{' '}
        {t(
          'Time spent by students on overall training (also including labs hours)',
        )}
      </p>
      <p>
        <i>{t('Scroll to zoom the chart, click and drag to pan the chart.')}</i>
      </p>
    </>
  );

  return (
    <ChartFrame
      subTitle={t('Training hours')}
      popoverHeader={<div>{t('Training hours')}</div>}
      popoverContent={popoverContent}
      tabs={[
        {
          key: 'monthly_training_hours_line_chart',
          title: t('By month'),
          chart: monthlyChart,
          isLoading,
        },
        {
          key: 'weekly_training_hours_line_chart',
          title: t('By week'),
          chart: weeklyChart,
          isLoading,
        },
      ]}
      className=""
    />
  );
};

export default withErrorBoundary(
  observer(TrainingHoursChartFrame),
  ComponentError,
  handleError,
);
