import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const BookmarkButton = (props) => {
  const { t } = useTranslation();
  const {
    sectionTitle,
    sectionSlug,
    isBooked,
    courseOffering,
    checkBookmark,
    handleBookmark,
  } = props;

  // Check if bookmarked
  useEffect(() => {
    if (!courseOffering) return;
    checkBookmark?.(sectionSlug);
  }, [courseOffering, sectionSlug]);

  const ariaLabelText = isBooked ? 'Page bookmarked' : 'Bookmark this page';

  return (
    <>
      {sectionTitle && sectionSlug && (
        <div
          className="bookmark-button"
          role="button"
          aria-label={t(ariaLabelText)}
          title={t(ariaLabelText)}
          tabIndex={0}
          onClick={() => {
            handleBookmark(sectionSlug, sectionTitle);
          }}
        >
          <span
            className={`favorite-icon glyphicon ${
              isBooked ? 'glyphicon-star' : 'glyphicon-star-empty'
            }`}
          />
          <span>{t(ariaLabelText)}</span>
        </div>
      )}
    </>
  );
};

BookmarkButton.propTypes = {
  sectionTitle: PropTypes.string,
  sectionSlug: PropTypes.string,
  isBooked: PropTypes.bool,
  courseOffering: PropTypes.object,
  checkBookmark: PropTypes.func,
  handleBookmark: PropTypes.func.isRequired,
};

BookmarkButton.defaultProps = {
  sectionTitle: '',
  sectionSlug: '',
  isBooked: false,
  courseOffering: null,
  checkBookmark: null,
};
export default observer(BookmarkButton);
