import { action, observable } from 'mobx';
import { createContext } from 'react';

class AlertListUIStore {
  @observable modal = {
    cancelText: '',
    confirmFunc: () => {},
    confirmText: '',
    title: '',
    visible: false,
    cancelFunc: () => {
      this.modal.visible = false;
    },
  };

  @action showModal(title, confirmText, confirmFunc, cancelText) {
    this.modal.title = title;
    this.modal.confirmText = confirmText;
    this.modal.confirmFunc = confirmFunc;
    this.modal.cancelText = cancelText;
    this.modal.visible = true;
  }

  @action closeModal() {
    this.modal = {
      cancelText: '',
      confirmFunc: () => {},
      confirmText: '',
      title: '',
      visible: false,
      cancelFunc: () => {
        this.modal.visible = false;
      },
    };
  }
}

export default AlertListUIStore;
export const alertListUIStoreContext = createContext(null);
