import { useContext, useEffect, useState } from 'react';
import { when } from 'mobx';
import { getKalturaSession } from 'services/CourseService';

import { storesContext } from '../stores';

type UseKalturaSessionParams = {
  entryId: string;
  onRemoval?: () => void;
};

type UseKalturaSessionResult = {
  kalturaSession: string;
  skipKalturaSession: boolean;
};

/**
 * Reusable React hook to handle Kaltura session
 */
function useKalturaSession({
  entryId,
  onRemoval,
}: UseKalturaSessionParams): UseKalturaSessionResult {
  const { userStore } = useContext(storesContext);
  const [kalturaSession, setkalturaSession] = useState<string>('');
  const [skipKalturaSession, setSkipKalturaSession] = useState<boolean>(false);

  useEffect(() => {
    when(
      () => when && userStore.isLoggedIn,
      async () => {
        try {
          const ks = await getKalturaSession();
          if (ks) {
            setkalturaSession(ks);
            setSkipKalturaSession(false);
          }
        } catch (error) {
          setkalturaSession('');
          setSkipKalturaSession(true);
        }
      },
    );
    // when leaving this video
    return () => {
      onRemoval?.();
    };
  }, [entryId]);

  return { kalturaSession, skipKalturaSession };
}

export default useKalturaSession;
