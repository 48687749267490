import { get } from 'axios';
import { COURSE_INFO_API, COURSE_INFO_META_API } from '../config/constants';

const getCourseInfo = (code) => {
  const slug = code;
  return new Promise((resolve, reject) => {
    get(COURSE_INFO_API.replace('<course-slug>', slug))
      .then((result) => {
        if (result.data?.status !== 0) reject();
        resolve(result.data?.result);
      })
      .catch((e) => reject(e));
  });
};

const getCourseInfoMeta = (code) => {
  const slug = code;
  return new Promise((resolve, reject) => {
    get(COURSE_INFO_META_API.replace('<course-slug>', slug))
      .then((result) => {
        if (result?.status !== 200) reject();
        resolve(result.data);
      })
      .catch((e) => reject(e));
  });
};

export { getCourseInfo, getCourseInfoMeta };
export default getCourseInfo;
