/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';
import { Link } from 'react-mobx-router5';
import { Collateral } from 'types';

interface CourseCollateralProps {
  collateral: Collateral;
}

const CourseCollateral: FC<CourseCollateralProps> = (props) => {
  const { collateral } = props;
  const { i18n, t } = useTranslation();
  const { catalogStore } = useContext(storesContext);
  const locale = i18n.language;

  const hasAssociatedSlugs =
    Array.isArray(collateral.associated_course_slugs) &&
    collateral.associated_course_slugs.length > 0;

  const showContentMatch =
    hasAssociatedSlugs &&
    (catalogStore.subscriptionHasLesson || catalogStore.subscriptionHasLabPlus);

  const renderCourseLink = (slug) => {
    const translatedName = catalogStore.getTranslatedCourseName(slug, locale);

    return (
      <li key={slug}>
        <Link
          routeName="courses:view"
          routeParams={{
            course: catalogStore.getAnySlugFromCode(slug),
          }}
        >
          {slug.toUpperCase()} - {translatedName}
        </Link>
      </li>
    );
  };

  return (
    <React.Fragment>
      {collateral.description && (
        <div
          className="course-collateral-description"
          dangerouslySetInnerHTML={{
            __html: collateral?.description?.body,
          }}
        />
      )}
      {collateral.audience && (
        <div
          className="course-collateral-audience"
          dangerouslySetInnerHTML={{
            __html: `<h3>${t('Audience')}</h3> ${collateral?.audience?.body}`,
          }}
        />
      )}
      <div
        className="course-collateral-prerequisites"
        dangerouslySetInnerHTML={{
          __html: `<h3>${t('Prerequisites')}</h3>
          ${collateral?.prerequisites?.body || t('None')}`,
        }}
      />
      {showContentMatch && (
        <div className="course-collateral-prerequisites">
          <h3>{t('Content match')}</h3>
          <p>
            {t(
              'This lesson is a component of one or multiple offerings. Completing this lesson contributes to the progress in the following offerings:',
            )}
          </p>
          <ul>{collateral?.associated_course_slugs.map(renderCourseLink)}</ul>
        </div>
      )}
      {collateral.lab_plus_courses && catalogStore.subscriptionHasLabPlus && (
        <div
          className="course-collateral-prerequisites"
          dangerouslySetInnerHTML={{
            __html: `<h3>${t('LabPlus Courses')}</h3>
          ${collateral?.lab_plus_courses?.body || t('None')}`,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CourseCollateral;
