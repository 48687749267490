/**
 * @file Provides utility methods to support interacting with the LMS Class API
 * @author Joshua Jack <jjack@redhat.com>
 */

import querystring from 'querystring';
import { get, put } from 'axios';

import {
  ILT_CLASS_DETAILS_VIEW_BASE,
  LMS_CLASSES_API_BASE,
} from '../config/constants';

/**
 * Returns course info (does not require authentication or an enrollment)
 * @param {string} instructors - Offering ID
 * @return {Object} An object containing user enrollment data
 */
export const getLMSClasses = (params) =>
  new Promise((resolve, reject) => {
    const {
      search,
      instructors,
      modalities,
      status,
      startRange,
      endRange,
      ...baseParams
    } = params;

    const query = {
      ...baseParams,
      ...(!search && instructors && { instructors }),
      ...(!search && modalities && { modalities }),
      ...(!search && status && { status }),
      ...(!search && startRange && { startRange }),
      ...(!search && endRange && { endRange }),
      ...(search && { search }),
    };

    get(`${LMS_CLASSES_API_BASE}?${querystring.stringify(query)}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns course info (does not require authentication or an enrollment)
 * @param {string} offeringId - Offering ID
 * @return {Object} An object containing user enrollment data
 */
export const getLMSClassByOfferingId = (offeringId, refresh = false) =>
  new Promise((resolve, reject) => {
    const params = {};

    if (refresh) {
      params.refresh = true;
    }

    get(`${LMS_CLASSES_API_BASE}${offeringId}`, {
      params,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns enrollments for a particular class (requires authentication)
 * @param {string} offeringId - Offering ID
 * @return {Object[]} A collection containing user enrollment data
 */
export const getLMSClassEnrollments = (offeringId) =>
  new Promise((resolve, reject) => {
    get(`${LMS_CLASSES_API_BASE}${offeringId}/enrollments`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns user enrollment data for a particular class (requires authentication)
 * @param {string} offeringId - Offering ID
 * @param {string} username - Username
 * @return {Object} An object containing user enrollment data
 */
export const getClassEnrollmentByUsername = (
  offeringId,
  username,
  refresh = false,
) =>
  new Promise((resolve, reject) => {
    const params = {};

    if (refresh) {
      params.refresh = true;
    }

    get(`${LMS_CLASSES_API_BASE}${offeringId}/enrollments/${username}`, {
      params,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Sets user completion status for a particular class (requires authentication)
 * @param {string} offeringId - Offering ID
 * @param {string} username - Username
 * @return {Object} An object containing user enrollment data
 */
export const setEnrollmentCompletion = (
  offeringId,
  username,
  status,
  comments,
) =>
  new Promise((resolve, reject) => {
    put(`${LMS_CLASSES_API_BASE}${offeringId}/enrollments/${username}`, {
      status,
      comments,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns course info (does not require authentication or an enrollment)
 * @param {string} offeringId - Offering ID
 * @param {string} status - Offering status
 * @return {Object} An object containing user enrollment data
 */
export const setClassCompletion = (offeringId, status = 'completed') =>
  new Promise((resolve, reject) => {
    put(`${LMS_CLASSES_API_BASE}${offeringId}`, {
      status,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Redirects to the ILT Class Dashboard based on the user's enrollment
 * @param {string} offeringId - Offering ID
 */
export const redirectToClass = (offeringId) =>
  window.location.replace(`${ILT_CLASS_DETAILS_VIEW_BASE}${offeringId}`);
