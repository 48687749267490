import React, { useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { Button } from '@patternfly/react-core';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import DefaultModal from 'components/Modal';
import DataTable from 'components/Datatable2';
import { storesContext } from 'stores';

import './styles.scss';
import AdminManagerListStore from './store';

const AdminManagerList = () => {
  const { organizationStore } = useContext(storesContext);
  const managerListStore = useLocalStore(() => new AdminManagerListStore());
  const { t } = useTranslation();
  const { SearchBar } = Search;

  const handleToggle = async (value, row) => {
    const managerId = row.uuid;
    const nextState = value ? 'enabled' : 'disabled';
    await organizationStore.setManagerState(managerId, nextState);
  };

  const handleDelete = async (uuid) => {
    await organizationStore.deleteManager(uuid);
    organizationStore.getUsers();
  };

  const showConfirmModal = (uuid, username) => {
    const params = {
      managerId: uuid,
      username,
    };
    managerListStore.selectedManager = params;
    managerListStore.showDeleteModal = true;
  };

  const closeConfirmModal = () => {
    managerListStore.showDeleteModal = false;
  };

  const columns = [
    {
      dataField: 'preferred_name',
      text: t('Name'),
      sort: true,
    },
    {
      dataField: 'username',
      text: t('Username'),
      sort: true,
    },
    {
      dataField: 'default_email',
      text: t('Email'),
      sort: true,
    },
    {
      dataField: '@timestamp',
      text: t('Created'),
      sort: true,
      formatter: (ts) => {
        if (!ts) {
          return t('Not Yet Created');
        }
        return new Date(ts).toLocaleDateString();
      },
    },
    {
      dataField: 'state',
      text: t('Status'),
      sort: true,
      formatter: (state, row) => {
        return state === 'pending' ? (
          <span>{t('pending')}...</span>
        ) : (
          <Switch
            checked={state === 'enabled'}
            onChange={(value) => {
              handleToggle(value, row);
            }}
            uncheckedIcon={<div className="uncheckedIcon">{t('Disabled')}</div>}
            checkedIcon={<div className="checkedIcon">{t('Enabled')}</div>}
            onColor="#080"
            offColor="#f20000"
            className="switch"
            handleDiameter={20}
            width={72}
            id="manager-status"
            data-analytics-id="status-switch-org-mngmt-dboard-lp" // not supported
          />
        );
      },
    },
    {
      dataField: 'reportees',
      text: t('Users'),
      sort: true,
      align: 'center',
    },
    {
      dataField: 'deleteKey',
      isDummyField: true,
      text: t('Delete'),
      formatter: (cell, row) => {
        return (
          <Button
            variant="link"
            data-analytics-id="delete-manager-org-mngmt-dboard-lp"
            icon={<span className="glyphicon glyphicon-trash delete-key" />}
            onClick={() => {
              showConfirmModal(row.uuid, row.username);
            }}
          />
        );
      },
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
    ],
  });

  return (
    <React.Fragment>
      <ToolkitProvider
        keyField="username"
        data={organizationStore.managerList}
        columns={columns}
        pagination={pagination}
        search
      >
        {(toolkitProps) => (
          <div>
            <SearchBar
              className="manager-search-bar"
              id="download-org-mngmt-dboard-lp2"
              {...toolkitProps.searchProps}
            />
            <hr />
            <DataTable
              noDataIndication={t('No Managers Available')}
              bordered={false}
              {...toolkitProps.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      {managerListStore.showDeleteModal && (
        <DefaultModal
          visible
          title={`${t('Are you sure you want to delete the manager')}, ${
            managerListStore.selectedManager.username
          }?`}
          cancelFunc={() => {
            closeConfirmModal();
          }}
          confirmFunc={() => {
            handleDelete(managerListStore.selectedManager.managerId);
            closeConfirmModal();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(AdminManagerList),
  GenericViewError,
  handleError,
);
