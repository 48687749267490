import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Glyphicon,
  Button,
  OverlayTrigger,
  Tooltip,
  Alert,
  Panel,
} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import { KalturaWidget, FeatureFlag } from '@ole-ui/ole-ui-components';
import { storesContext } from 'stores';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import OrientationIcon from 'img/icons/orientation.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import parse from 'html-react-parser';
import { KALTURA_CONFIGS } from 'config/constants';
import LabInstructionsStore from './store';
import './styles.scss';

const LabInstructions = (props) => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new LabInstructionsStore());
  const { userStore } = useContext(storesContext);

  const disabledSSHDownloadTooltip = (
    <Tooltip id="disabled-ssh-tooltip">
      {t('Create lab first to enable SSH Private Key download')}
    </Tooltip>
  );
  const labParamsTooltip = (
    <Tooltip id="disabled-ssh-tooltip">
      {t(
        'Replace the placeholders on the commands above by their respective parameters',
      )}
    </Tooltip>
  );

  const { lab, user } = props;
  store.labState = _.get(lab, 'labState');

  const isExpanded = (id) => store.expandedPanels?.has(id);
  const {
    labDefinition,
    userCredentialMap,
    publicIp,
    classroomWebapp,
    webApplications,
    hasUserLabParameters,
  } = lab;

  const SSHPortText = hasUserLabParameters ? '<ssh_access_port>' : '53009';

  const isOwnerAccessEnabled = _.has(userCredentialMap, 'owner');

  const classroomWebappUrl = _.get(classroomWebapp, 'url');

  const labOrientationInformation = lab.orientationInformation;

  const labOrientationVideo = lab.orientationVideo;

  const redirectClassroomWebapp = (url) => {
    window.open(url, '_blank');
  };

  const getCredentialsUUID = () => {
    let credentialsUUID = '';

    if (user.isInstructor) {
      credentialsUUID = _.get(userCredentialMap, 'instructor');
    }
    if (!credentialsUUID && user.isAdmin) {
      credentialsUUID =
        _.get(userCredentialMap, 'owner') ||
        _.get(userCredentialMap, 'instructor');
    }

    if (!credentialsUUID) {
      credentialsUUID = _.get(userCredentialMap, 'owner');
    }

    return credentialsUUID;
  };

  const downloadSSHKey = () => {
    const element = document.createElement('a');
    const file = new Blob([store.sshKey], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'rht_classroom.rsa';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const labParametersTable = hasUserLabParameters && (
    <>
      <h3>
        {t('Lab parameters')}
        <OverlayTrigger
          trigger={['hover', 'click']}
          placement="top"
          overlay={labParamsTooltip}
        >
          <Button bsStyle="link">
            <Glyphicon glyph="info-sign" />
          </Button>
        </OverlayTrigger>
      </h3>
      <table className="table">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Description')}</th>
            <th>{t('Value')}</th>
          </tr>
        </thead>

        <tbody>
          {labDefinition.user_lab_parameters.map((paramDefinition) => (
            <tr key={paramDefinition.name}>
              <td>{paramDefinition.name}</td>
              <td>{paramDefinition.title}</td>
              <td>{paramDefinition.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  return (
    <React.Fragment>
      {store.isTourDisplayed && (
        <div className="tour-wrapper">
          <div className="overlay">
            <div className="overlay-content">
              <KalturaWidget
                className="expert-extras-player"
                partnerId={KALTURA_CONFIGS.partnerId}
                playerId={KALTURA_CONFIGS.labsTutorial.uiConfId}
                entryId={KALTURA_CONFIGS.labsTutorial.entryId}
                isPlaylist
              />
              <div className="text-center">
                <Button
                  bsStyle="primary"
                  type="submit"
                  onClick={() => {
                    store.toggleTour();
                  }}
                >
                  {t('Close video')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {store.isOrientationVideoDisplayed && labOrientationVideo && (
        <div className="tour-wrapper">
          <div className="overlay">
            <div className="overlay-content">
              <KalturaWidget
                className="expert-extras-player"
                partnerId={KALTURA_CONFIGS.partnerId}
                playerId={lab.orientationVideoPlayerId}
                entryId={labOrientationVideo.entry_id}
                isPlaylist={false}
              />
              <div className="text-center">
                <Button
                  bsStyle="primary"
                  type="submit"
                  onClick={() => {
                    store.toggleOrientationVideoDisplayed();
                  }}
                >
                  {t('Close video')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {(userStore.isInstructorOrAdmin || isOwnerAccessEnabled) && (
        <Panel
          eventKey="ssh-key-info"
          className="lab-info-panel"
          onToggle={(expanded) => {
            store.togglePanels('ssh-key-info', expanded);
          }}
        >
          <Panel.Toggle componentClass="div" className="filter-panel-toggle">
            <Panel.Heading
              componentClass="header"
              className="filter-panel-heading"
              data-analytics-id="ssh-acc-vt-dash-lp"
            >
              <Panel.Title className="filter-panel-heading-title">
                <h4>
                  <Glyphicon glyph="wrench" />{' '}
                  {t('SSH Private Key & Instructions')}
                </h4>
                <div className="filter-panel-heading-icon">
                  <SVG
                    src={isExpanded('ssh-key-info') ? CollapseIcon : ExpandIcon}
                  >
                    {isExpanded('ssh-key-info') ? t('Collapse') : t('Expand')}
                  </SVG>
                </div>
              </Panel.Title>
            </Panel.Heading>
          </Panel.Toggle>
          <Panel.Body collapsible className="filter-panel-body">
            <div className="margin-bottom">
              {t(
                'Do not do this if you have already set a private key up before for a lab.',
              )}
            </div>
            <ol>
              <li>
                <p>
                  {t('Click')} <b>{t('CREATE')}</b>{' '}
                  {t('to spin up the lab environment')}
                </p>
              </li>
              <li>
                <p>
                  {t('Click')} <b>{t('DOWNLOAD SSH KEY')}</b>{' '}
                  {t(`button when they're enabled (lab is running)`)}
                </p>
              </li>
              <li>
                <p>{t('Open a terminal in your local machine')}</p>
              </li>
              <li>
                <p>
                  {t('On your terminal, go to your ssh folder:')}{' '}
                  <code>cd ~/.ssh</code>
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "If it's not your first time doing this, you can skip this step and go to step 6.",
                  )}
                  <br />
                </p>
                <ul>
                  <li>
                    <p>
                      {t('Move the downloaded file to')} <code>~/.ssh</code>
                      {t(
                        ', either with your files explorer or with the command line:',
                      )}
                      <br />
                      <code>mv ~/Downloads/rht_classroom.rsa ~/.ssh/</code>
                      <br />
                      <code>chmod 0600 ~/.ssh/rht_classroom.rsa</code>
                      <br />
                      <code>ssh-add ~/.ssh/rht_classroom.rsa</code>
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  {t('ssh into lab:')}{' '}
                  <code>
                    {t(
                      `ssh -i ~/.ssh/rht_classroom.rsa -J cloud-user@${
                        publicIp || '<public_ip>'
                      }:22022 student@172.25.252.1 -p ${SSHPortText}`,
                    )}
                  </code>
                  {!publicIp && (
                    <>
                      <br />
                      {t('Replace')} <code>{t('<public_ip>')}</code>{' '}
                      {t(
                        'on the terminal with the ip that will be presented down below when the labs become active, or wait for said ip to appear automatically in the above command.',
                      )}
                    </>
                  )}
                  <br />
                  {t('You will be SSHing into the workstation VM as the')}{' '}
                  <em>student</em>{' '}
                  {t('user. If you are asked for a password, use')}{' '}
                  <code>student</code>
                </p>
              </li>
            </ol>
            <div>{labParametersTable}</div>
          </Panel.Body>
          <Panel.Footer className="filter-panel-footer">
            <div className="footer-btn-wrapper">
              {store.status === 'retrieving' && (
                <div className="lab-ssh-btn-lbl-retrieving">
                  {t('Retrieving SSH key...')}
                </div>
              )}
              {store.status === 'copied' && (
                <div className="lab-ssh-btn-lbl-copied">
                  {t('SSH key copied into your clipboard!')}
                </div>
              )}
              {store.status === 'downloaded' && (
                <div className="lab-ssh-btn-lbl-downloaded">
                  {t('Downloading SSH key file!')}
                </div>
              )}
              {store.status === 'error' && (
                <div className="lab-ssh-btn-lbl-error">
                  {t('Error retrieving SSH key.')}
                </div>
              )}
              {lab.isUserLabReady &&
              userCredentialMap &&
              getCredentialsUUID() ? (
                <React.Fragment>
                  <Button
                    bsStyle="primary"
                    type="submit"
                    on
                    data-analytics-id="dwnload-ssh-vt-dash-lp"
                    onClick={() => {
                      const creds = getCredentialsUUID();
                      if (!store.sshKey) {
                        store
                          .getSSHkey(creds, 'downloaded')
                          .then(downloadSSHKey);
                      } else {
                        downloadSSHKey();
                      }
                    }}
                  >
                    {t('Download ssh key')}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <OverlayTrigger
                    placement="top"
                    trigger={['hover', 'click']}
                    overlay={disabledSSHDownloadTooltip}
                  >
                    <Button
                      className="disabled-ssh-btn"
                      data-analytics-id="dwnload-ssh-vt-dash-lp"
                    >
                      {t('Download ssh key')}
                    </Button>
                  </OverlayTrigger>
                </React.Fragment>
              )}
            </div>
          </Panel.Footer>
          {/* <Panel.Body collapsible className="filter-panel-body" /> */}
        </Panel>
      )}

      {webApplications.length === 0 && classroomWebappUrl && (
        <Panel
          eventKey="classroom-webapp-info"
          className="lab-info-panel"
          onToggle={(expanded) => {
            store.togglePanels('classroom-webapp-info', expanded);
          }}
        >
          <Panel.Toggle componentClass="div" className="filter-panel-toggle">
            <Panel.Heading
              componentClass="header"
              className="filter-panel-heading"
              data-analytics-id="lab-ctrl-acc-vt-dash-lp"
            >
              <Panel.Title className="filter-panel-heading-title">
                <h4>
                  <Glyphicon glyph="link" /> {t('Classroom Webapp')}
                </h4>
                <div className="filter-panel-heading-icon">
                  <SVG
                    src={
                      isExpanded('classroom-webapp-info')
                        ? CollapseIcon
                        : ExpandIcon
                    }
                  >
                    {isExpanded('classroom-webapp-info')
                      ? t('Collapse')
                      : t('Expand')}
                  </SVG>
                </div>
              </Panel.Title>
            </Panel.Heading>
          </Panel.Toggle>
          <Panel.Body collapsible className="filter-panel-body">
            <div className="margin-bottom">
              <p>
                {t(
                  'Classroom web application consoles are more appropriate for a command line experience, including cutting and pasting command text, uploading files into the environments, and related activities.',
                )}
              </p>
              <p>
                {t(
                  'The standard VNC console approach is more appropriate for graphical applications and remote desktop environments.',
                )}
              </p>
            </div>
          </Panel.Body>
          <Panel.Footer className="filter-panel-footer">
            <div className="footer-btn-wrapper">
              <React.Fragment>
                <Button
                  bsStyle="primary"
                  type="submit"
                  on
                  onClick={() => redirectClassroomWebapp(classroomWebappUrl)}
                >
                  {t('Access Classroom Webapp')}
                </Button>
              </React.Fragment>
            </div>
          </Panel.Footer>
        </Panel>
      )}
      <FeatureFlag
        section="lab"
        flagName="lab_control_instructions"
        defaultFlagValue
      >
        <Panel
          eventKey="lab-info"
          className="lab-info-panel"
          onToggle={(expanded) => {
            store.togglePanels('lab-info', expanded);
          }}
        >
          <Panel.Toggle componentClass="div" className="filter-panel-toggle">
            <Panel.Heading
              componentClass="header"
              className="filter-panel-heading"
            >
              <Panel.Title className="filter-panel-heading-title">
                <h4>
                  <Glyphicon glyph="play" /> {t('Lab Controls')}
                </h4>
                <div className="filter-panel-heading-icon">
                  <SVG src={isExpanded('lab-info') ? CollapseIcon : ExpandIcon}>
                    {isExpanded('lab-info') ? t('Collapse') : t('Expand')}
                  </SVG>
                </div>
              </Panel.Title>
            </Panel.Heading>
          </Panel.Toggle>
          <Panel.Body collapsible className="filter-panel-body">
            <p>
              {t('Click')} <b>{t('Create')}</b>{' '}
              {t(
                'to build all of the virtual machines needed for the classroom lab environment. This may take several minutes to complete. Once created the environment can then be stopped and restarted to pause your experience.',
              )}
              <br />
              {t('When a lab is created, click')} <b>{t('Start')}</b>{' '}
              {t('to run all of the virtual machines in the classroom.')}
              <br />
              {t('Click')} <b>{t('Stop')}</b>{' '}
              {t(
                'to stop all the virtual machines from running. This will not delete your lab.',
              )}
              <br />
              {t('If you')} <b>{t('Delete')}</b>{' '}
              {t(
                'your lab, you will remove all of the virtual machines in your classroom and lose all of your progress.',
              )}
              <br />
            </p>

            <p>{t('Virtual machine actions')}</p>
            <p>
              {t('Click')} <b>{t('Start')}</b>{' '}
              {t('to power on the virtual machine.')}
              <br />
              {t('Click')} <b>{t('Shutdown')}</b>{' '}
              {t(
                'to gracefully shut down the virtual machine, preserving disk contents.',
              )}
              <br />
              {t('Click')} <b>{t('Power')}</b>{' '}
              {t(
                'off to forcefully shut down the virtual machine, while still preserving disk contents.',
              )}
              <br />
              {t('Click')} <b>{t('Open console')}</b>{' '}
              {t(
                'to connect to the system console of the virtual machine in a new browser.',
              )}
            </p>

            <p>{t('Auto-stop timer')}</p>
            <p>
              {t(
                'The Red Hat Learning Subscription entitles you to set allotment of lab time.',
              )}
              <br />
              {t(
                'To help conserving your allotted time, the lab environment uses automatic timers to stop or destroy your lab environment when the timer expires.',
              )}
            </p>
            <p>
              {t('Click the')} <b>{t('Auto-stop')}</b>{' '}
              {t(
                'button [+] to extend the time you would like to spend with the labs.',
              )}
              <br />
              {t('Click the')} <b>{t('Auto-destroy')}</b>{' '}
              {t('button [+] to add day(s) to the auto-destroy timer.')}
            </p>

            <p>
              {t(
                'Auto-stop has a maximum of 11 hours, and auto-destroy has a maximum of 14 days.',
              )}
              <br />
              {t(
                "Be careful to keep the timers set while you are working, so that your environment doesn't shut down unexpectedly.",
              )}
              <br />
              {t(
                'We also suggest not to set the auto-timers unnecessarily high, which could waste your lab time allotment',
              )}
            </p>
          </Panel.Body>

          <Panel.Footer className="filter-panel-footer">
            <div className="lab-video-btn-wrapper">
              <Button
                bsStyle="primary"
                type="submit"
                onClick={() => {
                  store.toggleTour();
                }}
                data-analytics-id="watch-tutorial-vt-dash-lp"
              >
                {t('Watch tutorial')}
              </Button>
            </div>
          </Panel.Footer>
          {/* <Panel.Body collapsible className="filter-panel-body" /> */}
        </Panel>
      </FeatureFlag>

      {labOrientationInformation && (
        <FeatureFlag
          section="lab"
          flagName="lab_orientation_instructions"
          defaultFlagValue
        >
          <Panel
            eventKey="lab-ori-info"
            className="lab-info-panel"
            onToggle={(expanded) => {
              store.togglePanels('lab-ori-info', expanded);
            }}
          >
            <Panel.Toggle componentClass="div" className="filter-panel-toggle">
              <Panel.Heading
                componentClass="header"
                className="filter-panel-heading"
              >
                <Panel.Title className="filter-panel-heading-title">
                  <h4>
                    <span className="orientation-icon">
                      <SVG src={OrientationIcon} width={25} height={25} />
                    </span>
                    {t('Orientation to the classroom environment')}
                  </h4>
                  <div className="filter-panel-heading-icon">
                    <SVG
                      src={
                        isExpanded('lab-ori-info') ? CollapseIcon : ExpandIcon
                      }
                    >
                      {isExpanded('lab-ori-info') ? t('Collapse') : t('Expand')}
                    </SVG>
                  </div>
                </Panel.Title>
              </Panel.Heading>
            </Panel.Toggle>
            <Panel.Body collapsible className="filter-panel-body">
              <p>{parse(labOrientationInformation)}</p>
            </Panel.Body>

            <Panel.Footer className="filter-panel-footer">
              <div className="lab-orient-video-btn-wrapper">
                {labOrientationVideo ? (
                  <Button
                    bsStyle="primary"
                    type="submit"
                    onClick={() => {
                      store.toggleOrientationVideoDisplayed();
                    }}
                    data-analytics-id="watch-tutorial-orientation-dash-lp"
                  >
                    {t('Watch orientation')}
                  </Button>
                ) : (
                  <Button
                    className="disabled-orient-btn"
                    ata-analytics-id="watch-tutorial-orientation-dash-lp"
                  >
                    {t('Watch orientation')}
                  </Button>
                )}
              </div>
            </Panel.Footer>
            {/* <Panel.Body collapsible className="filter-panel-body" /> */}
          </Panel>
        </FeatureFlag>
      )}

      {userStore.isInstructorOrAdmin &&
        store.labState === 'running' &&
        !userCredentialMap && (
          <Alert bsStyle="danger" className="no-ssh-banner">
            <p>{t('SSH is not enabled for this lab')}</p>
          </Alert>
        )}
    </React.Fragment>
  );
};

LabInstructions.propTypes = {
  lab: PropTypes.object,
  user: PropTypes.object,
};

LabInstructions.defaultProps = {
  lab: {},
  user: {},
};

export default observer(LabInstructions);
