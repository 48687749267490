import moment from 'moment';

export default [
  {
    type: 'lab_maintenance',
    course_slugs: ['do180-4.2', 'do288-4.2', 'do101-4.2'],
    from_date: moment('2020-02-27').unix(),
    to_date: moment('2020-02-29T12').unix(),
    timeframe: 'the evening hours on Friday, February 28th',
    hours: 6,
  },
];
