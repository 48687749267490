/* eslint-disable no-dupe-class-members */
import { action, computed, observable } from 'mobx';
import Fuse from 'fuse.js';
import * as merge from 'deepmerge';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import intersectionBy from 'lodash/intersectionBy';
import {
  getLearningPaths,
  joinLearningPath,
  unjoinLearningPath,
  deleteCustomSkillPath,
} from '../services/LearningPathService';

import { getLearningPathsForOrgLeader } from '../services/ManagementReportingService';

class LearningPathsStore {
  static renderFilters(values, vocabulary = []) {
    const count = countBy(values);

    const filters = Object.keys(count).reduce((filtered, entry) => {
      const found = vocabulary.find((item) => {
        return entry === item.token;
      });

      if (!found) return filtered;

      filtered.push({
        key: found.token,
        value: found.display_name,
        count: count[found.token],
        checked: false,
        ...(found.expression && { expression: found.expression }),
      });

      return filtered.sort((a, b) => b.count - a.count);
    }, []);

    return filters;
  }

  @observable loaded = false;

  @observable learningPaths = [];

  @observable filters = {
    categories: [],
    products: [],
    roles: [],
    pathTypes: [],
  };

  @observable expandedFilterGroups = new Set();

  @observable _filteredEntries = [];

  @observable searchResults = null;

  @observable.ref courseCollateral = [];

  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable loading = false;

  @observable loaded = false;

  @observable deletePathModal = {
    show: false,
    mode: 'confirm',
    isDeleting: false,
  };

  @observable cskToDelete = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action async init(i18n, vocabularyStore, userStore, force = false) {
    if (
      userStore.isOrganizationLeader &&
      !userStore.subscription.subscription
    ) {
      const entries = await this.getLearningPathsForOrgLeaders(force);

      if (entries) {
        this.getFilterInfo(i18n, vocabularyStore);
      }
    } else if (userStore.subscription.subscription) {
      const entries = await this.getLearningPaths(force);

      if (entries) {
        this.getFilterInfo(i18n, vocabularyStore);
      }
    }
  }

  @action searchEntries = (string) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'title',
        'description.body',
        'prerequisites.body',
        'lp_elements.code',
        'lp_elements.title',
        'code',
        'categories',
      ],
    };

    if (!string.length) {
      this.searchResults = this.filteredEntries;
      return;
    }

    const fuse = new Fuse(this.filteredEntries, options);
    this.searchResults = fuse.search(string);
  };

  @computed get query() {
    return this.rootStore.searchStore.query;
  }

  @computed get totalPages() {
    return (
      Math.ceil(this.filteredEntries.length / this.entriesPerPage, 10) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get filterKeys() {
    return Object.keys(this.filters);
  }

  @computed get entries() {
    // Return a unique array of learning paths entries, preferring the one with
    // the latest user activity
    const uniqueArray = Object.values(
      this.learningPaths.reduce((array, entry) => {
        if (
          !array[entry.last_user_activity] ||
          +entry.last_user_activity > +array[entry.code].last_user_activity
        ) {
          array[entry.code] = entry;
        }

        return array;
      }, {}),
    );

    return uniqueArray.map((entry) =>
      merge(entry, {
        translations: [
          {
            language: 'en-US',
            title: entry.title,
          },
        ],
      }),
    );
  }

  set entries(entries) {
    this.learningPaths = entries;
  }

  @computed get filteredEntries() {
    const checkedFiltersLength = flatMap(
      this.filterKeys.map((key) =>
        this.filters[key].filter((filter) => filter.checked),
      ),
    ).length;

    return checkedFiltersLength || this.query
      ? this._filteredEntries
      : this.entries;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;

    return this.filteredEntries.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  flattenFilters(key) {
    return this.filters[key]
      .filter((item) => item.checked)
      .map((item) => item.key);
  }

  @action filterEntries = () => {
    const filteredCategories = this.entries.filter((entry) => {
      const categories = this.flattenFilters('categories');
      return entry?.categories?.some((item) => categories.includes(item));
    });

    const filteredProducts = this.entries.filter((entry) => {
      const products = this.flattenFilters('products');
      return entry?.products?.some((item) => products.includes(item));
    });

    const filteredRoles = this.entries.filter((entry) => {
      const roles = this.flattenFilters('roles');
      // FIXME: Waiting on the property name for 'Job Roles'
      const { job_roles: jobRoles } = entry;

      return jobRoles?.some((item) => roles.includes(item));
    });

    const filteredPathTypes = this.entries.filter((entry) => {
      const pathTypes = this.flattenFilters('pathTypes');
      const { path_type: pathType } = entry;

      return pathTypes?.some((item) => item === pathType);
    });

    const filters = [];
    if (filteredCategories.length) filters.push(filteredCategories);
    if (filteredProducts.length) filters.push(filteredProducts);
    if (filteredRoles.length) filters.push(filteredRoles);
    if (filteredPathTypes.length) filters.push(filteredPathTypes);

    this._filteredEntries = intersectionBy(...filters, 'code');

    if (!filters.length) {
      this._filteredEntries = this.entries;
    }

    if (this.query) {
      this.searchEntries(this.query);
      this._filteredEntries = this.searchResults;
    }
  };

  @action getLearningPaths = async (force) => {
    if (this.learningPaths.length && !force) {
      return this.learningPaths;
    }
    try {
      this.loading = true;

      const learningPaths = await getLearningPaths();

      if (learningPaths) {
        this.learningPaths = learningPaths;
      }
    } catch {
      this.learningPaths = [];
    } finally {
      this.loading = false;
      return this.learningPaths;
    }
  };

  @action getLearningPathsForOrgLeaders = async (force) => {
    if (this.learningPaths.length && !force) {
      return this.learningPaths;
    }
    try {
      this.loading = true;

      const learningPaths = await getLearningPathsForOrgLeader();

      if (learningPaths) {
        this.learningPaths = learningPaths;
      }
    } catch {
      this.learningPaths = [];
    } finally {
      this.loading = false;
      return this.learningPaths;
    }
  };

  @action getFilterInfo = async (i18n, vocabularyStore) => {
    const { language } = i18n;
    const { getVocabularyByNamespace } = vocabularyStore;

    const categories = await getVocabularyByNamespace(
      'offering_categories',
      language,
    );

    this.filters.categories = LearningPathsStore.renderFilters(
      flatMap(this.entries, 'categories'),
      categories,
    );

    const products = await getVocabularyByNamespace(
      'offering_products',
      language,
    );

    this.filters.products = LearningPathsStore.renderFilters(
      flatMap(this.entries, 'products'),
      products,
    );

    const roles = await getVocabularyByNamespace('job_roles', language);

    this.filters.roles = LearningPathsStore.renderFilters(
      flatMap(this.entries, 'job_roles'),
      roles,
    );

    const pathTypes = await getVocabularyByNamespace(
      'skills_path_types',
      language,
    );

    this.filters.pathTypes = LearningPathsStore.renderFilters(
      flatMap(this.entries, 'path_type'),
      pathTypes,
    );

    return this.filters;
  };

  clearFilters = (exceptions = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
        }
      });
    });

    this.filterEntries();
  };

  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    this.filterEntries();
  };

  @action toggleFilterGroups = (id, expanded) => {
    return expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get joinedLearningPaths() {
    return this.learningPaths?.filter((path) => path.joined);
  }

  @action joinLearningPath(code) {
    joinLearningPath(code).then(() => {
      const index = this.learningPaths.findIndex((p) => p.code === code);
      const pathToUpdate = this.learningPaths[index];
      pathToUpdate.joined = !pathToUpdate.joined;

      this.filterEntries();
    });
  }

  @action unjoinLearningPath(code) {
    unjoinLearningPath(code).then(() => {
      const index = this.learningPaths.findIndex((p) => p.code === code);
      const pathToUpdate = this.learningPaths[index];
      pathToUpdate.joined = !pathToUpdate.joined;

      this.filterEntries();
    });
  }

  @action clearPaths() {
    this.learningPaths = [];
  }

  @action async handleDeleteCSK(
    i18n,
    vocabularyStore,
    userStore,
    pathToDelete,
  ) {
    try {
      await deleteCustomSkillPath(pathToDelete.code);

      await this.init(i18n, vocabularyStore, userStore, true);
    } catch {
      throw new Error('Cannot delete a path');
    }
  }
}

export default LearningPathsStore;
