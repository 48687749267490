import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  inject,
  PropTypes as MobXPropTypes,
  observer,
  Provider,
} from 'mobx-react';
import {
  Alert,
  Button,
  Glyphicon,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import LabControls from './LabControls';
import AutostopTimer from './LabControls/AutostopTimer';
import LabWebApps from './LabControls/LabWebAppPanel';
import DefaultLabComponent from './DefaultLabComponent';
import OpenstackLabComponent from './OpenstackLabComponent';
import OCPSharedLabComponent from './OCPSharedLabComponent';
import TowerLabComponent from './TowerLabComponent';
import PTLLabComponent from './PTLLabComponent';

import {
  LAB_DATA_POLLING_SECONDS,
  LAB_AUTOSHUTDOWN_POLLING_SECONDS,
} from '../../config/constants';

import LabEnvironmentStore from './store';

@inject('userStore', 'uiStore')
@withTranslation()
@observer
class LabEnvironment extends Component {
  componentMap = {
    shared_ocp: OCPSharedLabComponent,
    tower: TowerLabComponent,
    openstack: OpenstackLabComponent,
    ptl: PTLLabComponent,
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    courseSlug: PropTypes.string.isRequired,
    labDefinition: MobXPropTypes.observableObject.isRequired,
    uiStore: MobXPropTypes.observableObject.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    currentEnrollment: PropTypes.object,
    targetEnrollment: PropTypes.object,
  };

  static defaultProps = {
    currentEnrollment: {},
    targetEnrollment: {},
  };

  constructor(props) {
    super(props);
    this.store = new LabEnvironmentStore();
    this.store.currentEnrollment = this.props.currentEnrollment;
    this.store.targetEnrollment = this.props.targetEnrollment;
    this.store.labDefinition = this.props.labDefinition;
    // set courseSlug last, as it triggers the action.
    this.store.courseSlug = this.props.courseSlug;

    // force use :(
    console.warn(props.userStore);
  }

  componentDidMount() {
    this.store.pollIntervalSecs = LAB_DATA_POLLING_SECONDS;
    this.checkLabQuota();
    this.store.setInhibitLabInfo();
    this.store.getLabInformation(this.props.uiStore.currentLanguage);
  }

  componentDidUpdate() {
    if (this.props.courseSlug !== this.store.courseSlug) {
      this.updateStore();
    }

    if (this.props.uiStore.currentLanguage !== this.store.currentLanguage) {
      this.store.currentLanguage = this.props.uiStore.currentLanguage;
      this.store.getLabInformation(this.props.uiStore.currentLanguage);
    }
  }

  componentWillUnmount() {
    this.store.pollIntervalSecs = 0;
    if (this.labHoursWatchdog) clearTimeout(this.labHoursWatchdog);
    if (this.inhibitLabStartWatchdog)
      clearTimeout(this.inhibitLabStartWatchdog);
  }

  getComponents() {
    const ul = this.store.userLab;

    if (!ul.doc_id || !this.props.userStore.hasLabHours) {
      return null;
    }

    const elems = ul.components.map((comp) => {
      const elemComp = this.componentMap[comp.driver] || DefaultLabComponent;
      const props = {
        action: this.store.doCommand,
        labStore: this.store,
        labComponent: comp,
        key: comp.slug,
      };
      return React.createElement(elemComp, props);
    });
    return elems;
  }

  checkLabQuota() {
    const { t } = this.props;

    this.labHoursWatchdog = setTimeout(() => {
      // TODO: Subscriptions are always loaded when the app starts, so why do we need to get this data again just for labs?
      this.props.userStore
        .getActiveSubscription()
        .then(() => {
          if (
            !this.props.userStore.hasLabHours &&
            this.store.userLab?.state === 'running' &&
            // eslint-disable-next-line
            this.store.userLab?.desired_state !== 'stopped'
          ) {
            this.store.doCommand('app_stop');

            this.store.createNewModal(
              {
                title: t(
                  'Your labs have shut down. You have used all of your available lab hours.',
                ),
                dismissText: t('Ok'),
              },
              true,
            );
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.checkLabQuota();
        });
    }, LAB_AUTOSHUTDOWN_POLLING_SECONDS * 1000);
  }

  updateStore() {
    this.store.currentEnrollment = this.props.currentEnrollment;
    this.store.targetEnrollment = this.props.targetEnrollment;
    this.store.labDefinition = this.props.labDefinition;
    this.store.courseSlug = this.props.courseSlug;
    this.store.lastRequestedCommand = null;
    this.store.userLab = {};
    this.store.fetchUserLab();
    this.store.setInhibitLabInfo();
    this.store.getLabInformation(this.props.uiStore.currentLanguage);
  }

  render() {
    const comps = this.getComponents();
    const { t } = this.props;

    return (
      <Provider labStore={this.store}>
        <div>
          {!this.props.userStore.hasLabHours && (
            <Alert bsStyle="warning" id="lab-autoshutdown-banner-dialog">
              <h4>
                <Glyphicon glyph="exclamation-sign" />
                <b> {t('Important!')}</b>
              </h4>
              {t(
                'Your labs have shut down. You have used all of your available lab hours.',
              )}
            </Alert>
          )}
          <LabControls labStore={this.store} />
          <br />
          {comps}
          {this.props.userStore.hasLabHours && (
            <AutostopTimer labStore={this.store} />
          )}
          {this.store?.userLab?.web_applications && (
            <LabWebApps labStore={this.store} />
          )}

          <Modal
            tabIndex="-1"
            show={this.store.modal.opened}
            onHide={() => {
              this.store.hideModal();
            }}
          >
            <ModalHeader tabIndex="-1" closeButton>
              <ModalTitle>{this.store.modal.title}</ModalTitle>
            </ModalHeader>
            <ModalFooter tabIndex="-1">
              {this.store.modal.confirmText ? (
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    this.store.modalDoConfirm();
                  }}
                  disabled={this.store.modal.working}
                >
                  {this.store.modal.confirmText}
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  this.store.hideModal();
                }}
                disabled={this.store.modal.working}
              >
                {this.store.modal.dismissText}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Provider>
    );
  }
}

/*
  debug elements
        <p>Lab Definition</p>
        <pre>{JSON.stringify(this.store.labDefinition, null, 2)}</pre>
        <p>User Lab</p>
        <pre>{JSON.stringify(this.store.userLab, null, 2)}</pre>
*/

export default LabEnvironment;
