import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@patternfly/react-core';
import { storesContext } from '../../../stores';
import CertificateOfAttendanceModal from '../Modal';
import Store from './store';

const CertificateOfAttendanceButton = (props) => {
  const { courseSlug, timestamp, forPremium, buttonText } = props;
  const { t } = useTranslation();

  const { userStore } = useContext(storesContext);

  const store = useLocalStore(() => new Store());

  const setCertificateOfAttendanceModalVisibility = (visible) => {
    store.certificateOfAttendanceModalVisibility = visible;
  };

  return (
    <React.Fragment>
      <Button
        variant="none"
        className="brand-button"
        data-analytics-id={`coa-btn-ole-lp-${courseSlug}`}
        onClick={() => {
          setCertificateOfAttendanceModalVisibility(true);
        }}
      >
        {t(buttonText)}
      </Button>
      {store.certificateOfAttendanceModalVisibility && (
        <CertificateOfAttendanceModal
          user={userStore.user}
          courseSlug={courseSlug}
          timestamp={timestamp}
          setVisibility={setCertificateOfAttendanceModalVisibility}
          forPremium={forPremium}
        />
      )}
    </React.Fragment>
  );
};

CertificateOfAttendanceButton.propTypes = {
  courseSlug: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  forPremium: PropTypes.bool,
  buttonText: PropTypes.string,
};

CertificateOfAttendanceButton.defaultProps = {
  forPremium: false,
  buttonText: 'Certificate of Attendance',
};

export default observer(CertificateOfAttendanceButton);
