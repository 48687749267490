import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.scss';

const UsersInCustomSkillPath = (props) => {
  const { t } = useTranslation();
  const { usersInPath, isUsersLoaded, selectedSkillPath } = props;

  const getUserProgress = (user) => {
    const progress = user?.progress || 0;

    return (
      <ProgressBar
        className="org-progress"
        striped
        now={progress}
        label={`${progress}%`}
      />
    );
  };

  const renderDataColumns = (columns) => {
    return columns?.map((c) => {
      return (
        <div key={c.value} className={c.className || 'column-user'}>
          <div className="flex-sm-row">
            <span className="row-sm-header">{c.headerText}&nbsp;&nbsp;</span>
            {c.value}
          </div>
        </div>
      );
    });
  };

  const renderAUser = (user) => {
    return (
      <React.Fragment key={user.name}>
        <div key={user.name} className="row-user">
          {renderDataColumns([
            {
              headerText: t('Name'),
              value: user?.name,
            },
            {
              headerText: t('Username'),
              value: user?.username,
            },
            {
              headerText: t('Email'),
              value: user?.email,
            },

            { headerText: t('Progress'), value: getUserProgress(user) },
          ])}
        </div>
      </React.Fragment>
    );
  };
  const renderColumns = (columns) => {
    return columns?.map((c) => {
      return (
        <div className={c.className || 'column-user'}>
          <span>{c?.text}</span>
        </div>
      );
    });
  };
  const renderHeader = () => {
    return (
      <div className="row-user-header row-user">
        {renderColumns([
          {
            text: 'Name',
          },
          {
            text: 'Username',
          },
          {
            text: 'Email',
          },
          {
            text: 'Progress',
          },
        ])}
      </div>
    );
  };

  const renderUsersInPath = () => {
    return (
      <React.Suspense>
        <React.Fragment>
          {renderHeader()}
          {!isUsersLoaded && (
            <div className="no-progress-msg">
              <p>{t('Loading users for the selected skill path')}</p>
            </div>
          )}
          {(isUsersLoaded &&
            usersInPath.length > 0 &&
            usersInPath?.map((item) => renderAUser(item))) ||
            (usersInPath.length === 0 && isUsersLoaded && (
              <div className="no-progress-msg">
                <p>{`${t('No users found for the')} ${
                  selectedSkillPath?.title || t('selected')
                } ${t('skill path')}`}</p>
              </div>
            ))}
        </React.Fragment>
      </React.Suspense>
    );
  };

  return (
    <React.Fragment>
      <div className="mgmt-csk-users-list">{renderUsersInPath()}</div>
    </React.Fragment>
  );
};

UsersInCustomSkillPath.propTypes = {
  usersInPath: PropTypes.array,
  selectedSkillPath: PropTypes.object,
  isUsersLoaded: PropTypes.bool,
};
UsersInCustomSkillPath.defaultProps = {
  usersInPath: [],
  isUsersLoaded: false,
  selectedSkillPath: {},
};

export default observer(UsersInCustomSkillPath);
