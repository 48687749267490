import { action, observable } from 'mobx';

class MyProgressStore {
  @observable opened = true;

  @observable visibleItems = 5;

  @action showMoreItems() {
    this.visibleItems += 5;
  }

  @action toggle = () => {
    this.opened = !this.opened;
  };
}

export default MyProgressStore;
