import { observable } from 'mobx';

class ShowSolutionStore {
  @observable expandedSolutions = new Set();

  solutionIsExpanded = (id) => this.expandedSolutions.has(id);

  toggleSolutions = (id, expanded) => {
    return expanded
      ? this.expandedSolutions.add(id)
      : this.expandedSolutions.delete(id);
  };
}

export default ShowSolutionStore;
