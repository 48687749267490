import aIBrain from '../img/RHLSbanner_AI.png';
import aITablet from '../img/RHLSbanner_AITablet.png';
import astronautFlag from '../img/RHLSbanner_AstronautFlag_grey.png';
import astronautPaperboy from '../img/RHLSbanner_AstronautPaperboy.png';
import astronautsSocial from '../img/RHLSbanner_AstronautsSocial_Light.png';
import cloudOpenStack from '../img/RHLSbanner_CloudOpenStack.png';
import digitalBadge from '../img/RHLSbanner_DigitalBadge.png';
import heroBanner from '../img/hero-banner.png';
import bannerExact from '../img/banner-exact.png';
import ITOptimization from '../img/RHLSbanner_ITOptimization.png';
import bannerRemoteExams from '../img/RHLSbanner_RemoteExams.png';
import womanClouds from '../img/RHLSbanner_WomanClouds.png';
import retakesNewsFlash from '../img/RHLSbanner_ExamRetakesNewsflash.png';
import uxTrooper from '../img/ux-trooper-banner-v2.png';
import personHybridCloud from '../img/RHLSbanner_hybridCloud.png';


import dashboardFeedback from '../img/feedback-dark.jpg';
import webinar from '../img/webinar-hero.png';

import newsFlashTV from '../img/RHLSbanner_NewsFlashAstronaut.png';
import newsFlash8 from '../img/newsflash03.png';
import RHLC200k from '../img/RHLSbanner_RHLC200k.png';
import RHCPotY2025 from '../img/RHLSbanner_CPotY2025.png';
import techOverviews from '../img/RHLSbanner_TechOverviews.png';
import whiteboardBanner from '../img/RHLSbanner_whiteboarding.png';
import womanLaptop from '../img/RHLSbanner_WomanLaptop.png';

// DO NOT import animation json files unless they are really being used.
// By importing them the app bundle size is increased significantly and the loading times will also increase
// import earlyAccess from '../img/ea-animation.json';
// import skillsPath from '../img/skills-paths-edited.json';
// import eigthtTopTips from '../img/eightExamTips.json';
// import premium from '../img/premium_hero_animation.json';

import { APP_BASE } from '../config/constants';

const imgs = {
  aIBrain,
  aITablet,
  astronautFlag,
  astronautPaperboy,
  astronautsSocial,
  cloudOpenStack,
  heroBanner,
  bannerExact,
  ITOptimization,
  bannerRemoteExams,
  digitalBadge,
  retakesNewsFlash,
  personHybridCloud,
  dashboardFeedback,
  webinar,
  newsFlash8,
  newsFlashTV,
  uxTrooper,
  womanClouds,
  RHCPotY2025,
  RHLC200k,
  techOverviews,
  whiteboardBanner,
  womanLaptop,
};

export default [
  {
    title: `New and Upgraded Courses Available`,
    titleColor: `white`,
    description: `CL170 - OpenStack Administration: Control Plane Management is a new course added to your subscription catalog. The following courses have been updated: DO316 - Managing Virtual Machines with Red Hat OpenShift Virtualization, and DO370 - Enterprise Kubernetes Storage with Red Hat OpenShift Data Foundation. Availability will vary based on your subscription.`,
    descriptionColor: `white`,
    imageUrl: imgs.cloudOpenStack,
    imageAlt: 'OpenStack Brand Image of cloud and servers',
    actionType: 'openLink',
    actionText: 'Visit the Catalog',
    actionUrl: `${APP_BASE}/catalog`,
    openActionInNewTab: false,
  },
  {
    title: `New Skills Paths Catalog`,
    titleColor: `white`,
    description: `The Skills Paths Catalog was updated to better reflect the goals of Red Hat Training and Certification students. Explore the catalog and enroll in a Skills Path to continue your learning journey.`,
    descriptionColor: `white`,
    imageUrl: imgs.ITOptimization,
    imageAlt: 'Woman at computer optimizing IT',
    actionType: 'openLink',
    actionText: 'Learn more',
    actionUrl: `${APP_BASE}/skills-paths`,
    openActionInNewTab: false,
    displayForSubscriptions: [
      'ls520',
      'ls220',
      'ls120',
      'ls300',
      'ls130c',
      'ls105',
      'ls000',
      'ls101',
      'ls810',
      'ls999',
    ],
  },
  {
    title: `Extra! Extra!`,
    titleColor: `black`,
    description: `Read all about it! Subscribe to the Red Hat Learning Subscription newsletter to receive all of the latest updates available to you. This includes course and exam releases, new features, and important Red Hat Training announcements.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautPaperboy,
    imageAlt: 'Astronauts meeting virtually',
    actionType: 'openLink',
    actionText: 'Subscribe',
    actionUrl: `https://www.redhat.com/en/engage/red-hat-learning-subscription-newsletter-opt-in-20240522`,
    openActionInNewTab: true,
  },
  {
    title: `Welcome to the Red Hat Learning Subscription Standard`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_41isrruz',
    displayForSubscriptions: ['ls220'],
  },
  {
    title: `Welcome to the Red Hat Learning Subscription Basic`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_h07quaro',
    displayForSubscriptions: ['ls120', 'ls130c', 'ls810'],
  },
  {
    title: `Welcome to the Red Hat Learning Subscription Developer`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_h2hmxml5',
    displayForSubscriptions: ['ls300'],
  },
  {
    title: `Take a tour of RHLS Premium`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.astronautFlag,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_7nb59zuy',
    displayForSubscriptions: ['ls520', 'ls105', 'ls000', 'ls101', 'ls999'],
  },
];
