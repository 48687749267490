import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import {
  Title,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
} from '@patternfly/react-core';
import CatalogIcon from '@patternfly/react-icons/dist/esm/icons/catalog-icon';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { storesContext } from '../../../stores';
import LearningPathCard from '../Card';
import './styles.scss';
import DeleteLearningPathModal from '../DeleteLearningPathModal';

const LearningPathList = () => {
  const { learningPathStore } = useContext(storesContext);
  const { t } = useTranslation();
  const [isDeleteModalVisible, setDeleteModalVisibility] =
    React.useState(false);

  const [pathToDelete, setPathToDelete] = React.useState(null);
  const { currentPage, paginatedEntries, setCurrentPage, totalPages } =
    learningPathStore;

  return (
    <React.Fragment>
      {paginatedEntries.length ? (
        <React.Fragment>
          <div className="catalog__list">
            {paginatedEntries.map((entry) => {
              const { user_progress: userProgress } = entry;
              const progress = Math.round(userProgress * 100) || 0;
              return (
                <LearningPathCard
                  key={shortid.generate()}
                  learningPath={entry}
                  progress={progress}
                  onDeleteCSK={(cskToDelete) => {
                    if (cskToDelete && cskToDelete.code) {
                      setPathToDelete(cskToDelete);
                      setDeleteModalVisibility(true);
                    }
                  }}
                />
              );
            })}
          </div>
          <div className="catalog-list-pagination">
            <UltimatePagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={setCurrentPage}
            />
          </div>
          {isDeleteModalVisible && (
            <DeleteLearningPathModal
              showModal={isDeleteModalVisible}
              pathToDelete={pathToDelete}
              onPathDeleted={() => {
                setDeleteModalVisibility(false);
                setPathToDelete(null);
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <EmptyState>
          <EmptyStateIcon icon={CatalogIcon} />
          <Title size="lg" headingLevel="h4">
            {t('No skills paths found')}
          </Title>
          <EmptyStateBody>
            {t('Use the create button to make a custom skill path.')}
          </EmptyStateBody>
        </EmptyState>
      )}
    </React.Fragment>
  );
};

export default observer(LearningPathList);
