import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, ControlLabel, Form, FormGroup } from 'react-bootstrap';
import { SyncLoader } from 'react-spinners';
import DatetimePicker from 'react-datetime';
import moment from 'moment';
import ErrorMessage from '../../Error/ComponentError';
import { handleError } from '../../../services/ErrorService';
import ExpiringSubscriptionsReportStore from './store';
import './styles.scss';

const ExpiringSubscriptionsReport = () => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new ExpiringSubscriptionsReportStore());

  const generateReport = async () => {
    store.fetchReport();
  };
  return (
    <article className="expiring-subscriptions-report-wrapper">
      {store.errorMsgVisible && (
        <Alert bsStyle="danger">
          {t(`Couldn't fetch the expiring subscription report:`)}&nbsp;
          {t(store.errorMessage)}
        </Alert>
      )}
      <Form onSubmit={generateReport}>
        <h4 className="input-bar-header">
          {t('Find subscriptions expiring between:')}
        </h4>
        <FormGroup id="expiring-subscription-inputs">
          <div>
            <ControlLabel>{t('From')}</ControlLabel>
            <DatetimePicker
              timeFormat={false}
              inputProps={{
                placeholder: t('Enter start date'),
              }}
              onChange={(e) => {
                const format = 'YYYY-MM-DD';
                const date = moment.utc(e);
                const formattedDate = date.format(format);

                store.startDateText = e;
                store.startDate = date.isValid() ? formattedDate : null;
              }}
              value={store.startDateText}
              utc
            />
          </div>
          <div>
            <ControlLabel>{t('To')}</ControlLabel>
            <DatetimePicker
              timeFormat={false}
              inputProps={{
                placeholder: t('Enter end date'),
              }}
              onChange={(e) => {
                const format = 'YYYY-MM-DD';
                const date = moment.utc(e);
                const formattedDate = date.format(format);

                store.endDateText = e;
                store.endDate = date.isValid() ? formattedDate : null;
              }}
              value={store.endDateText}
              utc
            />
          </div>
          <div className="submit-btn-container">
            <Button
              bsStyle="primary"
              onClick={generateReport}
              disabled={store.loading}
            >
              {store.loading ? (
                <div className="loading">
                  <SyncLoader loading={store.loading} color="white" size={10} />
                </div>
              ) : (
                t('Generate a report')
              )}
            </Button>
          </div>
        </FormGroup>
      </Form>
      {store.successMsgVisible && (
        <Alert bsStyle="success">
          {t('Report generated. Please check your downloads.')}
        </Alert>
      )}
    </article>
  );
};

export default withErrorBoundary(
  observer(ExpiringSubscriptionsReport),
  ErrorMessage,
  handleError,
);
