/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Table } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import SubscriptionTableStore from './store';
import UserMetricsEmptyState from '../EmptyState';

const SubscriptionTable = () => {
  const { t, i18n } = useTranslation();
  const { progressStore, organizationStore } = useContext(storesContext);
  const store = useLocalStore(
    () => new SubscriptionTableStore(progressStore, organizationStore),
  );

  const DateCell = (props) => (
    <div>
      {props?.cell?.value
        ? new Date(props.cell.value).toLocaleDateString()
        : 'N/A'}
    </div>
  );

  const columnDefinitions = useMemo(
    () => [
      {
        Header: t('Username'),
        accessor: 'username',
        id: 'username',
      },
      {
        Header: t('Type (SKU)'),
        accessor: 'subscription',
        id: 'subscription',
      },
      {
        Header: t('Start date'),
        accessor: 'start_time',
        Cell: DateCell,
      },
      {
        Header: t('End date'),
        accessor: 'end_time',
        Cell: DateCell,
      },
      {
        Header: t('Join date'),
        accessor: 'join_time',
        Cell: DateCell,
      },
      {
        Header: t('Days remaining'),
        accessor: 'subscriptionsDaysRemaining',
      },
      {
        Header: t('Order number'),
        accessor: 'lms_order_number',
        patternflyThProps: { modifier: 'fitContent' },
      },
    ],
    [i18n.language],
  );

  useEffect(() => {
    organizationStore.getUsers();
  }, [organizationStore.organization]);

  const renderEmptyState = () => {
    return (
      <UserMetricsEmptyState
        selectedCount={organizationStore.selectedUsersByManagerList?.length}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="table-horiz-scroll">
        <Table
          ariaLabel={t('Subscription table')}
          data={store.transformedData}
          columns={columnDefinitions}
          variant="compact"
          renderEmptyState={renderEmptyState}
        />
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(SubscriptionTable),
  GenericViewError,
  handleError,
);
