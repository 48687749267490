import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DrawerCard from '../DrawerCard';
import { storesContext } from '../../../stores';
import {
  formatDateTime,
  getTimeZoneInfo,
} from '../../../services/UtilityService';

const UserNotification = ({ userNotification }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    userNotificationsStore: {
      onArchiveUserNotification,
      onDeleteUserNotification,
    },
  } = useContext(storesContext);

  const formatUserNotificationDate = (date) => {
    return `${formatDateTime(
      date,
      language,
      'MM/DD/YYYY h:mm A',
    )} ${getTimeZoneInfo(date)}`;
  };

  return (
    <DrawerCard
      notification={{
        ...userNotification,
        description: userNotification.content,
        doc_id: userNotification.uuid,
        type: userNotification.doc_type,
        formatted_timestamp: formatUserNotificationDate(
          userNotification['@timestamp'],
        ),
        onArchive: onArchiveUserNotification,
        onDelete: onDeleteUserNotification,
      }}
    />
  );
};

UserNotification.propTypes = {
  userNotification: PropTypes.object,
};

UserNotification.defaultProps = {
  userNotification: {},
};

export default UserNotification;
