import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { Glyphicon } from 'react-bootstrap';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';

import './styles.scss';
import Store from './store';

const SearchAutosuggest = ({ autoFocus }) => {
  const { searchStore, routerStore, catalogStore, learningPathStore } =
    useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(() => new Store());

  const recommendedSearchWithin = () => {
    const { route } = routerStore;
    let suggestedRoute = 'all';

    if (route?.name?.includes('catalog')) {
      suggestedRoute = 'catalog';
    }

    if (route?.name?.includes('skills-paths')) {
      suggestedRoute = 'skills-paths';
    }

    return suggestedRoute;
  };

  const isOnSearcheablePage = () => {
    const routeName = routerStore?.route?.name;
    return (
      routeName.includes('catalog') ||
      routeName.includes('skills-paths') ||
      routeName === 'search'
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    store.suggestionSelected = false;
    searchStore.query = value;
  };

  const onChange = (event, { newValue }) => {
    store.suggestionSelected = false;
    searchStore.query = newValue;
  };

  const onSearch = ({ query, searchWithin }) => {
    if (!query && !isOnSearcheablePage()) {
      return;
    }

    if (searchWithin === 'all') {
      routerStore.navigate('search', {
        q: query,
        searchWithin,
      });
      return;
    }

    routerStore.navigate(searchWithin, {
      q: query,
    });
  };

  const renderSuggestion = (option) => {
    return (
      <div
        className="simple-option"
        style={{ display: 'flex', alignItems: 'center' }}
        data-analytics-id={`search-${option.value.searchWithin}-lp`}
      >
        <div>{option.label}</div>
        <div style={{ marginLeft: 'auto' }}>
          {t('search within')}{' '}
          {t(option.value.searchWithin.split('-').join(' ')).toUpperCase()}
        </div>
      </div>
    );
  };

  const onSuggestionsClearRequested = () => {
    console.warn('onSuggestionsClearRequested');
  };

  const getSuggestionValue = (suggestion) => suggestion.label;

  const onSuggestionSelected = (event, { suggestion }) => {
    store.suggestionSelected = true;

    onSearch(suggestion.value);
  };

  const clearSearch = () => {
    searchStore.query = '';
    catalogStore.searchResults = !catalogStore.searchResults;
    learningPathStore.searchResults = !learningPathStore.searchResults;
    if (isOnSearcheablePage()) {
      onSearch({ query: '', searchWithin: recommendedSearchWithin() });
    }
  };

  const onKeyDown = (event) => {
    // handle clicking enter without selecting a suggestion
    if (event.keyCode === 13) {
      if (!store.suggestionSelected) {
        store.suggestionSelected = true;

        // TODO: choose default searchWithin based on location
        onSearch({
          query: searchStore.query,
          searchWithin: recommendedSearchWithin(),
        });
      }
    }
  };

  const inputProps = {
    placeholder: t('Search'),
    value: searchStore.query,
    onChange,
    onKeyDown,
    'data-analytics-id': 'search-input-lp',
    autoFocus,
    'aria-label': t('Search the catalog for offerings'),
  };

  return (
    <span className="search-bar-container">
      <Autosuggest
        suggestions={searchStore.options}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
      />
      {routerStore?.route?.params?.q || searchStore.query ? (
        <span
          tabIndex="0"
          role="button"
          className="clear-button"
          onClick={clearSearch}
          data-analytics-id="search-clear-x-lp"
        >
          <Glyphicon glyph="remove" />
        </span>
      ) : null}
    </span>
  );
};

SearchAutosuggest.propTypes = {
  autoFocus: PropTypes.bool,
};

SearchAutosuggest.defaultProps = {
  autoFocus: false,
};

export default observer(SearchAutosuggest);
