import { action, observable } from 'mobx';
import { getCourseCollateral } from '../../../services/CourseService';
import { getExpertSeminar } from '../../../services/ExpertSeminarService';
import { DEFAULT_LANGUAGE } from '../../../config/constants';

import {
  isBasicSubscriber,
  isFreeTierSubscriber,
  isStandardSubscriber,
  isRHUSubscriber,
  isPremiumSubscriber,
} from '../../../services/SubscriptionService';

class CatalogCardStore {
  @observable opened = false;

  @observable show = false;

  @observable collateral = {};

  @action toggle = () => {
    this.opened = !this.opened;
  };

  @action getCourseCollateral = async (code, version, language) => {
    if (this.collateral.doc_id === `${code}@@${version}@@${language}`) {
      return this.collateral;
    }

    let collateral;
    try {
      collateral = await getCourseCollateral(code, version, language);
    } catch (error) {
      try {
        // if no collateral available in user's preferred language, fetch in English
        collateral = await getCourseCollateral(code, version, DEFAULT_LANGUAGE);
      } catch (retryError) {
        console.error(retryError);
      }
    }

    if (collateral) {
      this.collateral = collateral;
    }

    return this.collateral;
  };

  @action getSeminarCollateral = async (code, version) => {
    const slug = `${code}-${version}`;

    if (this.collateral.slug === slug) {
      return this.collateral;
    }

    const collateral = await getExpertSeminar(slug);
    if (collateral) {
      this.collateral = collateral.seminar;
    }

    return this.collateral;
  };

  @action canAccessExam = (subscription) => {
    return !isBasicSubscriber(subscription);
  };

  @action canAccessExpertSeminar = (subscription) => {
    return (
      isStandardSubscriber(subscription) ||
      isRHUSubscriber(subscription) ||
      isPremiumSubscriber(subscription) ||
      isFreeTierSubscriber(subscription)
    );
  };

  @action isFreeTierSubscriber = (subscription) => {
    return isFreeTierSubscriber(subscription);
  };

  @action isRHUSubscriber = (subscription) => {
    return isRHUSubscriber(subscription);
  };
}

export default CatalogCardStore;
