import React from 'react';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListGroup,
  DescriptionListDescription,
} from '@patternfly/react-core';
import SVG from 'react-inlinesvg';
import DurationIcon from 'img/icons/estimated-duration.svg';
import QuizIcon from 'img/icons/quiz.svg';
import LabIcon from 'img/icons/lab-2.svg';
import GuidedExerciseIcon from 'img/icons/guided-exercise.svg';
import VideoIcon from 'img/icons/video.svg';
import LevelIcon from 'img/icons/level.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  hasEstimatedTime?: boolean;
  hasGECount?: boolean;
  hasQuizCount?: boolean;
  hasLabCount?: boolean;
  hasVideoTime?: boolean;
  hasLevel?: boolean;
};

export default function LessonIndicatorLegends({
  hasEstimatedTime,
  hasGECount,
  hasQuizCount,
  hasLabCount,
  hasVideoTime,
  hasLevel,
}: Props) {
  const { t } = useTranslation();

  return (
    <DescriptionList isHorizontal>
      {hasEstimatedTime && (
        <DescriptionListGroup>
          <DescriptionListTerm
            icon={<SVG src={DurationIcon} title={t('Estimated duration')} />}
          >
            {t('Estimated duration')}
          </DescriptionListTerm>
          <DescriptionListDescription>
            {t(
              'The estimated time it takes to complete a lesson, including readings, quizzes, and interactive activities - videos are calculated separately. This is an average calculation based on typical learner engagement, but your actual time may vary.',
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      )}

      {hasGECount && (
        <DescriptionListGroup>
          <DescriptionListTerm
            icon={
              <SVG src={GuidedExerciseIcon} title={t('Guided exercises')} />
            }
          >
            {t('Guided Exercise')}
          </DescriptionListTerm>
          <DescriptionListDescription>
            {t(
              'A guided exercise is a hands-on practice exercise that follows a presentation section. It walks you through a procedure to perform, step by step.',
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      )}

      {hasLabCount && (
        <DescriptionListGroup>
          <DescriptionListTerm icon={<SVG src={LabIcon} title={t('Labs')} />}>
            {t('Lab')}
          </DescriptionListTerm>
          <DescriptionListDescription>
            {t(
              'An end-of-chapter lab is a gradable hands-on activity to help you to check your learning. You work through a set of high-level steps, based on the guided exercises in that chapter, but the steps do not walk you through every command. A solution is provided with a step-by-step walk-through.',
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      )}

      {hasQuizCount && (
        <DescriptionListGroup>
          <DescriptionListTerm
            icon={<SVG src={QuizIcon} title={t('Quizzes')} />}
          >
            {t('Quiz')}
          </DescriptionListTerm>
          <DescriptionListDescription>
            {t(
              'A quiz is typically used when checking knowledge-based learning, or when a hands-on activity is impractical for some other reason.',
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      )}

      {hasVideoTime && (
        <DescriptionListGroup>
          <DescriptionListTerm
            icon={<SVG src={VideoIcon} title={t('Total video time')} />}
          >
            {t('Video')}
          </DescriptionListTerm>
          <DescriptionListDescription>
            {t(
              'A picture tells a thousand words. A video tells two thousands and a half.',
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      )}

      {hasLevel && (
        <DescriptionListGroup>
          <DescriptionListTerm
            icon={<SVG src={LevelIcon} title={t('Level')} />}
          >
            {t('Difficulty level')}
          </DescriptionListTerm>
          <DescriptionListDescription>
            {t(
              'A score of 1-10 that indicates the difficulty of the lesson. The higher the number, the more difficult the lesson.',
            )}
          </DescriptionListDescription>
        </DescriptionListGroup>
      )}
    </DescriptionList>
  );
}
