import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Table } from '@ole-ui/ole-ui-components';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import ExamStatusTableStore from './store';
import './styles.scss';
import UserMetricsEmptyState from '../EmptyState';

const ExamStatusTable = () => {
  const { t } = useTranslation();
  const { examsStore, organizationStore, catalogStore, uiStore } =
    useContext(storesContext);
  const store = useLocalStore(
    () => new ExamStatusTableStore(examsStore, organizationStore, catalogStore),
  );

  useEffect(() => {
    examsStore.getReporteesExamStatus(organizationStore.managerReportees);
  }, [organizationStore.managerReportees]);

  useEffect(() => {
    store.examSKUTranslation = t('Exam SKU: ');
    store.versionTranslation = t(', Version: ');
    store.userCourseTranslation = t('User/Exam ');
    store.examStatusesTranslation = {
      requested: t('Requested'),
      unscheduled: t('Unscheduled'),
      scheduled: t('Scheduled'),
      results_pending: t('Results pending'),
      completed: t('Completed'),
      retake_available: t('Retake available'),
      retake_scheduled: t('Retake scheduled'),
      retake_results_pending: t('Retake results pending'),
      retake_complete: t('Retake complete'),
    };
  }, [uiStore.currentLanguage]);

  const renderEmptyState = () => {
    return (
      <UserMetricsEmptyState
        selectedCount={organizationStore.selectedUsersByManagerList?.length}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="table-horiz-scroll mgmt-exam-table">
        <Table
          ariaLabel={t('Exam status table')}
          columns={store.tableColumns}
          data={store.transformedData}
          variant="compact"
          renderEmptyState={renderEmptyState}
        />
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(ExamStatusTable),
  GenericViewError,
  handleError,
);
