import { get } from 'axios';

import { SEARCH_API } from '../config/constants';

const search = (query) =>
  new Promise((resolve, reject) => {
    get(SEARCH_API, {
      params: query,
    })
      .then((result) => {
        return resolve(result.data.response);
      })
      .catch((e) => reject(e));
  });

export { search };
export default search;
