import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, observer, useLocalStore } from 'mobx-react';
import SVG from 'react-inlinesvg';
import { Panel } from 'react-bootstrap';
import { Link } from 'react-mobx-router5';

import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';

import ViewLoader from 'components/Loading/View';
import { storesContext } from 'stores';
import { FeatureFlag } from '@ole-ui/ole-ui-components';
import LearningPathCard from '../Card';
import Store from './store';
import DeleteLearningPathModal from '../DeleteLearningPathModal';

import './styles.scss';

const LearningPathWidget = () => {
  const { t } = useTranslation();
  const { learningPathStore } = useContext(storesContext);
  const [isDeleteLearningPathModalVisible, setDeleteLearningPathModalVisible] =
    useState(false);
  const [learningPathToDelete, setLearningPathToDelete] = useState(null);
  const store = useLocalStore(() => new Store());

  return (
    <FeatureFlag
      section="my_widgets"
      flagName="my_skill_paths"
      defaultFlagValue
    >
      <Provider widgetStore={store}>
        <Panel
          className="learning-path-widget"
          expanded={store.opened}
          onToggle={store.toggle}
          data-analytics-id="skills-paths-widget-lp"
        >
          <Panel.Heading>
            <Panel.Title toggle>
              {t('MY SKILLS PATHS')}
              <div className="learning-path-widget-expand">
                <SVG src={store.opened ? CollapseIcon : ExpandIcon}>
                  {store.opened ? t('Collapse') : t('Expand')}
                </SVG>
              </div>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              {learningPathStore.loading && <ViewLoader />}
              {!learningPathStore.loading &&
                (learningPathStore.joinedLearningPaths.length ? (
                  learningPathStore.joinedLearningPaths
                    .slice(0, store.visibleItems)
                    .map((path) => (
                      <LearningPathCard
                        key={path.code}
                        learningPath={path}
                        progress={Math.round(path.user_progress * 100)}
                        onDeleteCSK={(cskToDelete) => {
                          if (cskToDelete && cskToDelete.code) {
                            setDeleteLearningPathModalVisible(true);
                            setLearningPathToDelete(cskToDelete);
                          }
                        }}
                      />
                    ))
                ) : (
                  <div className="no-enrollments-msg">
                    <p>{t('Not currently enrolled in any Skills Paths')}</p>
                    <Link
                      href
                      routeName="skills-paths"
                      data-analytics-id="sp-page-link-skills-paths-widget-lp"
                    >
                      {t('Enroll in a Skills Path')}
                    </Link>
                  </div>
                ))}
            </Panel.Body>
            {learningPathStore.joinedLearningPaths.length >=
            store.visibleItems ? (
              <Panel.Footer onClick={() => store.showMoreItems()}>
                {t('Show more')}
              </Panel.Footer>
            ) : null}
          </Panel.Collapse>
        </Panel>
        {isDeleteLearningPathModalVisible && (
          <DeleteLearningPathModal
            showModal={isDeleteLearningPathModalVisible}
            pathToDelete={learningPathToDelete}
            onPathDeleted={() => {
              setDeleteLearningPathModalVisible(false);
              setLearningPathToDelete(null);
            }}
          />
        )}
      </Provider>
    </FeatureFlag>
  );
};

export default observer(LearningPathWidget);
