/* eslint-disable no-console */
import { computed } from 'mobx';

class SubscriptionStore {
  constructor(progressStore, organizationStore) {
    this.progressStore = progressStore;
    this.organizationStore = organizationStore;
  }

  // Transform raw data to fit datatable
  @computed get transformedData() {
    const result = {};
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;

    if (selectedUsers.length === 0) {
      return [];
    }

    this.organizationStore.managerReportees
      .filter((a) => selectedUsers.includes(a.username))
      .forEach((item) => {
        if (!result[item.username]) {
          result[item.username] = { username: item.username };
        }
        result[item.username] = item;
      });

    return Object.values(result).sort((a, b) =>
      a.username.localeCompare(b.username),
    );
  }
}

export default SubscriptionStore;
