import { get, post } from 'axios';

import {
  COURSE_PROGRESS,
  EXTERNAL_SUBS_COURSE_PROGRESS,
  PROGRESS_API_URL,
  USER_PROGRESS_TOUCH_API,
} from '../config/constants';

// eslint-disable-next-line
export const getProgress = () => {
  return get(PROGRESS_API_URL).then((res) => res.data);
};

/**
 * Requests the summary of the progress of a manager's reportees.
 * @param organizationId - The id of the organization to which the reportees belong.
 * @param courseType - "course" or "module", depending on the kind of courses
 * we want the progress of (optional).
 * @returns The list of the progress of each user and course.
 */
export const getReporteesProgress = async (
  organizationId: string,
  courseType: string = null,
) => {
  try {
    const request = { organization: organizationId, course_type: courseType };
    const response = await post(`${PROGRESS_API_URL}reportees/`, request);
    return response.data.batch_progress;
  } catch (err) {
    throw err;
  }
};

export const setCourseProgress = (slug, pageUUID) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };

    const data = {
      course_slug: slug,
      entry_uuid: pageUUID,
      progress: 1,
      score: 1,
    };

    post(`${COURSE_PROGRESS}`, data, config)
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((error) => reject(error));
  });

export const setCourseProgressExternalSubscription = (
  courseCode,
  progress,
  externalSubscription,
) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    const data = {
      course_code: courseCode,
      progress,
      subscription: externalSubscription,
    };

    post(`${EXTERNAL_SUBS_COURSE_PROGRESS}`, data, config)
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((error) => reject(error));
  });

export const touchProgressDocument = async (courseSlug) => {
  try {
    const request = { slug: courseSlug };
    await post(USER_PROGRESS_TOUCH_API, request);
  } catch (err) {
    throw err;
  }
};

/**
 * Sends a request to update the progress of a course that consists on videos.
 * @param slug - The course's slug.
 * @param progress - An object where the keys are the ids of each of the videos
 * and the values are their progress from 0 to 1.
 * @param currentVideo - The video the user is currently watching when sending this request.
 * @param timestamp - The second of the video where the user is at the moment.
 * @returns The updated user progress document.
 */
export const updateVideoProgress = async (
  slug: String,
  progress: Object,
  currentVideo: String,
  timestamp: number,
) => {
  try {
    const data = {
      progress,
      current_video: currentVideo,
      timestamp,
    };
    const result = await post(
      `${PROGRESS_API_URL}${slug}/update_video_progress/`,
      data,
    );
    return result.data;
  } catch (err) {
    throw err;
  }
};
