import React, { useContext, useMemo } from 'react';
import { TableOfContents } from '@ole-ui/ole-ui-components';
import { observer } from 'mobx-react';
import { Link } from 'react-mobx-router5';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { storesContext } from 'stores';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import {
  CourseProgressStructureItem,
  CourseStructureItem,
  CourseStructureItemStatus,
} from 'types/course';
import keyBy from 'lodash/keyBy';

const { SectionState } = TableOfContents.Item;

type Props = {
  structure?: CourseStructureItem[];
  progressStructure?: CourseProgressStructureItem[];
  ignoreLevel?: boolean;
};

/**
 * Intended to replace the existing TableOfContents component for all offerings in the future?
 */
const TableOfContentsV2 = ({
  structure,
  progressStructure,
  ignoreLevel,
}: Props) => {
  const { t } = useTranslation();
  const { routerStore } = useContext(storesContext);
  const { route } = routerStore;

  const progressMap = useMemo<Record<string, CourseProgressStructureItem>>(
    () => keyBy(progressStructure, 'uuid'),
    [progressStructure],
  );

  const renderItem = (structureItem: CourseStructureItem) => {
    const { id_tag: idTag, uuid } = structureItem;
    const level = ignoreLevel ? 2 : structureItem.depth - 1;
    const isCurrentlyViewedPage =
      (route as any).params.page === structureItem.page_tag;

    if (structureItem.status === CourseStructureItemStatus.Inactive) {
      // is this still supported?
      return (
        <TableOfContents.Item
          key={idTag || uuid}
          state={level === 1 ? SectionState.None : SectionState.Unvisited}
          level={level}
        >
          {t(`${structureItem.title}`)}
        </TableOfContents.Item>
      );
    }

    if (isCurrentlyViewedPage) {
      return (
        <TableOfContents.Item
          key={idTag || uuid}
          state={level === 1 ? SectionState.None : SectionState.Current}
          level={level}
        >
          {t(`${structureItem.title}`)}
        </TableOfContents.Item>
      );
    }

    let state = SectionState.None;
    if (progressMap && level > 1) {
      const p = progressMap[uuid]?.progress;
      if (p === 1) {
        state = SectionState.Visited;
      } else {
        state = SectionState.Unvisited;
      }
    }

    return (
      <TableOfContents.Item key={idTag || uuid} state={state} level={level}>
        <Link
          href
          routeName="courses:page"
          routeParams={{
            course: (route as any).params.course, // eg. 'le101-1.0' or 'rh124-9.0'
            page: structureItem.page_tag, // eg. 'ch02s03' (legacy) or 'accessing-the-command-line-using-the-desktop' (unique universal id for pages)
          }}
        >
          {t(`${structureItem.title}`)}
        </Link>
      </TableOfContents.Item>
    );
  };

  if (!structure?.length) {
    return null;
  }

  return <TableOfContents>{structure?.map(renderItem)}</TableOfContents>;
};

export default withErrorBoundary(
  observer(TableOfContentsV2),
  ComponentError,
  handleError,
);
