import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduledReports as ScheduledReportsUI } from '@ole-ui/ole-ui-components';
import { Text, TextVariants } from '@patternfly/react-core';
import {
  searchManagers,
  searchLMSOrderNumbers,
  searchUsers,
  getManagersByUsernameList,
} from 'services/OrganizationService';
import { storesContext } from 'stores';
import {
  getReports,
  deleteReport,
  getScheduledJobs,
  createScheduledJob,
  editScheduledJob,
  getScheduledJobReports,
  submitActionForScheduleJobs,
} from 'services/ManagementReportingService';
import './styles.scss';

const { ReportType, SettingsOption } = ScheduledReportsUI;

function ScheduledReports() {
  const { t } = useTranslation();
  const { organizationStore } = useContext(storesContext);
  const { organization, isAdminOfCurrentlySelectedOrg } = organizationStore;

  if (!organization?.id) {
    return <div>{t('No organization selected')}</div>;
  }

  const userRole = isAdminOfCurrentlySelectedOrg ? 'admins' : 'managers';
  const visibleSettings = [SettingsOption.notify_owner];

  const enabledReportTypes = isAdminOfCurrentlySelectedOrg
    ? [
        {
          reportType: ReportType.User,
        },
        {
          reportType: ReportType.LMS,
        },
        {
          reportType: ReportType.Manager, // only for admins
        },
      ]
    : [
        {
          reportType: ReportType.User,
        },
        {
          reportType: ReportType.LMS,
        },
      ];

  const searchUsersByUsernameOrEmail = async (
    substring: string,
    _,
    organizationId?: string,
  ) => searchUsers(substring, { organizationId });

  return (
    <div>
      <ScheduledReportsUI
        organizationId={organization?.id}
        userRole={userRole}
        enabledReportTypes={enabledReportTypes}
        // autocompletion
        getUserByUsernameOrEmail={searchUsersByUsernameOrEmail}
        getLMSOrderNumbers={searchLMSOrderNumbers}
        getManagersByName={searchManagers as any}
        getManagersByUsernameList={getManagersByUsernameList}
        // jobs
        getJobs={getScheduledJobs}
        createJob={createScheduledJob}
        editJob={editScheduledJob}
        getJobReports={getScheduledJobReports}
        submitActionForAJob={submitActionForScheduleJobs}
        // reports
        getReports={getReports}
        deleteReport={deleteReport}
        formClassName="scheduled-reports__form"
        omitInternalTerms
      >
        <Text component={TextVariants.h3}>{t('Report content')}</Text>
        <ScheduledReportsUI.Form.FormGuide />
        <ScheduledReportsUI.Form.ReportTypeSpecificsWarning />
        <ScheduledReportsUI.Form.TitleField />
        <ScheduledReportsUI.Form.GenerateByField />
        <ScheduledReportsUI.Form.ReportTypeSpecifics hideFileUploader />
        <ScheduledReportsUI.Form.DateRangeField />
        <ScheduledReportsUI.Form.FrequencyField />
        <ScheduledReportsUI.Form.StartGeneratingField />
        <ScheduledReportsUI.Form.FormatField />
        <ScheduledReportsUI.Form.SettingsField
          visibleSettings={visibleSettings}
        />

        <ScheduledReportsUI.Form.SummaryText />
        <ScheduledReportsUI.Form.FormFooter />
      </ScheduledReportsUI>
    </div>
  );
}

export default ScheduledReports;
