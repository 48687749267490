import React from 'react';
import {
  DataList,
  TextContent,
  Text,
  TextVariants,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { IPTLLabComponent } from '../types';
import './styles.scss';
import ProvisionDataItem from './ProvisionDataItem';

type Props = {
  labComponent?: IPTLLabComponent;
};

function PTLLabComponent({ labComponent }: Props) {
  const { t } = useTranslation();

  const provisionData = labComponent?.context?.stat?.provision_data;

  if (!provisionData) {
    return null;
  }

  const items = [
    {
      key: 'aws-web-console',
      label: t('AWS Web Console'),
      value: provisionData.aws_web_console_url,
      customValue: (
        <Text component={TextVariants.p}>
          <a href={provisionData.aws_web_console_url} target="blank">
            {provisionData.aws_web_console_url}
          </a>
        </Text>
      ),
    },
    {
      key: 'aws-username',
      label: t('AWS Username'),
      value: provisionData.aws_web_console_user_name,
    },
    {
      key: 'aws-password',
      label: t('AWS Password'),
      value: provisionData.aws_web_console_password,
      isSecret: true,
    },
    {
      key: 'aws-access-key-id',
      label: t('AWS Access Key ID'),
      value: provisionData.aws_access_key_id,
    },
    {
      key: 'aws-access-key-secret',
      label: t('AWS Access Key Secret'),
      value: provisionData.aws_secret_access_key,
      isSecret: true,
    },
  ];

  return (
    <section className="ptl-lab-component">
      <TextContent className="ptl-lab-component__title">
        <Text component={TextVariants.h2}>
          {labComponent.context.title || t('Provision data')}
        </Text>
      </TextContent>

      <DataList isCompact aria-label={t('Lab provision data table')}>
        {items.map((item) => (
          <ProvisionDataItem key={item.key} item={item} />
        ))}
      </DataList>
    </section>
  );
}

export default PTLLabComponent;
