import { action, observable } from 'mobx';
import moment from 'moment';
import { getCourseCollateral } from '../../../../services/CourseService';
import { DEFAULT_LANGUAGE } from '../../../../config/constants';
import {
  isFreeTierSubscriber,
  isRHUSubscriber,
  isPremiumSubscriber,
} from '../../../../services/SubscriptionService';

class CourseCardStore {
  @observable collateral = {};

  @observable showModalDownloadCertificate = false;

  constructor(premiumSessionsStore) {
    this.premiumSessionsStore = premiumSessionsStore;
  }

  @action getCourseCollateral = async (code, version, language) => {
    // If a collateral already exists
    if (this.collateral.doc_id) {
      return this.collateral;
    }

    let collateral;
    try {
      collateral = await getCourseCollateral(code, version, language);
    } catch (error) {
      try {
        // if no collateral available in user's preferred language, fetch in English
        collateral = await getCourseCollateral(code, version, DEFAULT_LANGUAGE);
      } catch (retryError) {
        console.error(retryError);
      }
    }

    if (collateral) {
      this.collateral = collateral;
    }

    return this.collateral;
  };

  dateToTimestamp = (date) => {
    const dateObj = date ? moment(date) : moment();
    return dateObj.toISOString();
  };

  @action getPremiumVTClass = (code, version) => {
    // Check to see if the course has Premium Class
    const slug = `${code}-${version}`;
    const premClasses =
      this.premiumSessionsStore.premiumContentWithScheduledSessions;
    return premClasses?.find((premClass) => premClass.offering_slug === slug);
  };

  @action setCertificateOfAttendanceModalVisibility = (visible) => {
    this.showModalDownloadCertificate = visible;
  };

  @action isRHUSubscriber = (subscription) => {
    return isRHUSubscriber(subscription);
  };

  @action isPremiumSubscriber = (subscription) => {
    return isPremiumSubscriber(subscription);
  };

  @action isFreeTierSubscriber = (subscription) => {
    return isFreeTierSubscriber(subscription);
  };
}

export default CourseCardStore;
