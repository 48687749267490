/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-mobx-router5';
import {
  Alert,
  DropdownButton,
  MenuItem,
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { handleError } from 'services/ErrorService';
import { Modality, OfferingStatus, OfferingVisibility } from 'types';
import LockIcon from 'img/icons/lock.svg';
import { storesContext } from 'stores';
import BaseCard from 'components/Card';
import PremiumClassCard from 'components/Classes/PremiumClasses/Card';
import { getOfferingTitleByLanguage } from 'services/UtilityService';
import PageHighlight from 'components/Search/PageHighlight';
import OfferingIcon from 'components/OfferingIcon';
import CertificateOfAttendanceModal from 'components/CertificateOfAttendance/Modal';
import LessonOverview from 'components/Course/LessonOverview';
import CatalogStore from 'stores/Catalog';
import Store from './store';
import CourseCollateral from './CourseCollateral';
import LessonIndicators from '../Lesson/LessonIndicators';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const CourseCard = (props) => {
  const { i18n, t } = useTranslation();
  const {
    userStore,
    premiumSessionsStore,
    classesStore,
    catalogStore,
    progressStore,
    uiStore,
  } = useContext(storesContext);
  const store = useLocalStore(() => new Store(premiumSessionsStore));

  const [childrenPremiumClassCardOpened, setChildrenPremiumClassCardOpened] =
    useState(false);

  const {
    course,
    progress,
    displayVersionDropdown,
    onVersionChange,
    versions,
    isSearchResult,
    lastAccessed,
    courseCardButtons,
    disableActionButtons,
    targetSubscription,
    allowOrderingOffering,
    onOfferingOrderChange,
    alternativeReadButtonText,
  } = props;
  const { code, version, translations, modality, status } = course;
  const { collateral } = store;

  const isRetiredCourse = status === OfferingStatus.Retired;
  const isDisplayedInDasboard = !!lastAccessed;

  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const canShowGetCertificate = () => {
    const { completion_time: progressCompletionTime } =
      progressStore.getProgressForCourse(course.slug) || {};
    return progressStore.shouldShowLegacyCertificateOfAttendance(
      progress,
      progressCompletionTime,
    );
  };

  const title = getOfferingTitleByLanguage(
    course,
    getTranslation(),
    i18n.language,
  );

  const targetSubscriptionToRenderCardFor = targetSubscription;

  const lockedTooltip = (
    <Tooltip id="locked-tooltip">
      {store.isRHUSubscriber(targetSubscriptionToRenderCardFor)
        ? t('This content is not available as part of your subscription')
        : t(
            'This content is available as part of Standard Subscription. Please upgrade to gain access',
          )}
    </Tooltip>
  );

  const isEarlyAccess = CatalogStore.isEarlyAccess(code);
  const isLesson = CatalogStore.isLesson(course?.course_type);
  const isLocked = CatalogStore.isLocked(course?.visibility);

  const getCardColor = () => {
    const courseColor = 'var(--rhls-color-course)';
    const eaColor = 'var(--rhls-color-early-access)';
    return isEarlyAccess ? eaColor : courseColor;
  };

  const isVCModality = modality === Modality.VideoClassRoom;

  const vc = course.videoClassroom
    ? course.videoClassroom
    : isVCModality && course;

  const actionsForCourse = () => {
    const actionsToReturn = [];

    if (courseCardButtons?.length > 0) {
      return courseCardButtons;
    }

    if (!isRetiredCourse && course.visibility !== OfferingVisibility.Upgrade) {
      if (!isVCModality)
        actionsToReturn.push(
          <Link
            className={`course-view ${
              disableActionButtons && 'course-actions-disabled'
            }`}
            href
            {...(disableActionButtons && { disabled: true })}
            routeName="courses:view"
            routeParams={{
              course: `${code}-${version}`,
            }}
            key={uuidv4()}
            data-analytics-id={`launch-btn-course-card-lp-${code}-${version}`}
          >
            {alternativeReadButtonText || t('Launch')}
          </Link>,
        );

      if (vc || isVCModality) {
        if (store.isFreeTierSubscriber(targetSubscriptionToRenderCardFor)) {
          actionsToReturn.push(
            <Link
              className={`course-view ${
                disableActionButtons && 'course-actions-disabled'
              }`}
              routeName="courses:free-tier-vc"
              routeParams={{
                course: `${vc.code}vc`,
              }}
              href
              key={uuidv4()}
              {...(disableActionButtons && { disabled: true })}
              data-analytics-id={`watch-btn-course-card-lp-freetier-${vc.code}vc`}
            >
              {t('Watch')}
            </Link>,
          );
        } else {
          actionsToReturn.push(
            <Link
              className={`course-view ${
                disableActionButtons && 'course-actions-disabled'
              }`}
              routeName="courses:view"
              {...(disableActionButtons && { disabled: true })}
              routeParams={{
                course: `${vc.code}vc-${vc.version}`,
              }}
              href
              key={uuidv4()}
              data-analytics-id={`watch-btn-course-card-lp-${vc.code}vc`}
            >
              {t('Watch')}
            </Link>,
          );
        }
      }
    }

    if (isDisplayedInDasboard && canShowGetCertificate() && !isLesson) {
      actionsToReturn.push(
        <Button
          bsStyle="link"
          onClick={() => {
            store.setCertificateOfAttendanceModalVisibility(true);
          }}
          key={uuidv4()}
          data-analytics-id={`get-cert-btn-course-card-lp-${code}-${version}`}
        >
          {t('Get Certificate')}
        </Button>,
      );
    }

    return actionsToReturn;
  };

  const iconElementForCourse = () => {
    return (
      <React.Fragment>
        <OfferingIcon
          offeringCode={code}
          offeringType={course?.course_type}
          offeringVisibility={course?.visibility}
        />
        {/*
          technically we won't show courses with visibility: hidden in the catalog,
          but in case there's a skills path and we need to show exams there,
          they need to be locked.
        */}
        {isLocked && (
          <OverlayTrigger
            placement="bottom"
            trigger={['hover']}
            overlay={lockedTooltip}
          >
            <div className="content-locked">
              <SVG src={LockIcon} style={{ pointerEvents: 'auto' }}>
                {t('Content is locked')}
              </SVG>
            </div>
          </OverlayTrigger>
        )}
      </React.Fragment>
    );
  };

  const sortedEpochProductVersions = () => {
    const catalogEntries = catalogStore.groupedCatalogEntries[code] || [];

    const epochSortedVersions = Object.keys(catalogEntries)
      .map((v) => {
        return [v, catalogEntries[v]?.epoch_number || ''];
      })
      .filter((versionEpochNumberMap) => {
        return versionEpochNumberMap[1];
      })
      .sort((a, b) => {
        return b[1] - a[1];
      })
      .map((versionEpochNumberMap) => {
        return versionEpochNumberMap[0];
      });

    // filter to exclude epoch versions that are in the product versions list and sort
    const productSortedVersions = Object.keys(catalogEntries)
      .filter((ce) => {
        return !epochSortedVersions.includes(ce);
      })
      .sort((a, b) => {
        return parseFloat(b) - parseFloat(a);
      });

    return epochSortedVersions.concat(productSortedVersions);
  };

  const orderedEpochProductVersions = sortedEpochProductVersions();
  const orderedVersions =
    orderedEpochProductVersions.length !== 0
      ? orderedEpochProductVersions
      : Array.from(versions).sort((a, b) => {
          return parseFloat(b) - parseFloat(a);
        });

  const dropdownVersionElement = () => {
    return (
      displayVersionDropdown && (
        <DropdownButton
          id="progress-version-selector"
          bsStyle="default"
          title={`v.${version}`}
          onClick={(e) => e.stopPropagation()}
          key={uuidv4()}
          data-analytics-id={`version-drpdown-course-card-lp-${code}-${version}`}
        >
          {orderedVersions.map((v) => (
            <MenuItem
              key={uuidv4()}
              value={v}
              active={v === version}
              onClick={(e) => {
                e.stopPropagation();
                onVersionChange(v);
              }}
            >
              {`v.${v}`}
            </MenuItem>
          ))}
        </DropdownButton>
      )
    );
  };

  const premiumClassActionToggle = () => {
    // Used to set the state for children card opening back to default
    // after having clicked on "Schedule"
    setChildrenPremiumClassCardOpened(!childrenPremiumClassCardOpened);
  };

  const getCollateral = () => {
    let courseCode = code || '';
    if (!isLesson) {
      // we can't do this with lessons because their codes are made of human-readable keywords and can be anything
      // remove 'ea' part from course code if present
      courseCode = courseCode.replace(/[eaEA]+$/, '');
      store.getCourseCollateral(courseCode, version, i18n.language);
    }
  };

  // Check if the course has a Premium VT Class to display its card instead of the standard course card
  const premiumVTClass = store.getPremiumVTClass(code, version);

  const renderHighlightsForSearchResults = ([slug, pages]) => {
    return (
      <div className="highlights-for-version" key={uuidv4()}>
        <h4 key={uuidv4()}>{slug}</h4>
        {pages.map((page, index) => {
          return (
            page.highlight && (
              <React.Fragment key={uuidv4()}>
                <PageHighlight
                  highlight={page.highlight}
                  slug={slug}
                  pageTag={page._source?.structure?.page_tag}
                  key={uuidv4()}
                />
                {index !== pages.length - 1 && <hr />}
              </React.Fragment>
            )
          );
        })}
      </div>
    );
  };

  const indicatorElementsArray = [dropdownVersionElement()];

  let expandedCardContent = null;

  if (isLesson) {
    if (course.metadata) {
      const { metadata } = course;

      indicatorElementsArray.push(
        <LessonIndicators
          key="lesson-indicator"
          estimatedTime={metadata.total_duration}
          GECount={metadata.total_ge}
          quizCount={metadata.total_quiz}
          labCount={metadata.total_labs}
          level={metadata.level}
          videoTime={metadata.total_video_duration}
          durationUnit={metadata.duration_unit}
        />,
      );

      expandedCardContent = (
        <LessonOverview
          asCollateral
          currentLanguage={uiStore.currentLanguage}
          metadata={metadata}
          courseCatalogEntry={course}
        />
      );
    } else {
      expandedCardContent = <p>{t('Lesson description unavailable')}</p>;
    }
  } else {
    expandedCardContent = <CourseCollateral collateral={collateral} />;
  }

  const cardTitle = isLesson ? title : `${code.toUpperCase()} - ${title}`;

  let cardToRender = null;

  if (
    store.isPremiumSubscriber(targetSubscriptionToRenderCardFor) &&
    premiumVTClass &&
    !isVCModality &&
    !isSearchResult
  ) {
    // ======= Premium Subscriber card
    cardToRender = (
      <PremiumClassCard
        svgIconElement={iconElementForCourse()}
        premiumClass={premiumVTClass}
        courseDetails={{
          element: expandedCardContent,
          toggle: getCollateral,
        }}
        courseActions={[
          !childrenPremiumClassCardOpened && (
            <Link
              key={uuidv4()}
              className={`${disableActionButtons && 'course-actions-disabled'}`}
              {...(disableActionButtons && { disabled: true })}
              onClick={() => {
                setChildrenPremiumClassCardOpened(true);
              }}
              data-analytics-id="schedule-btn-course-card-lp"
              disabled={userStore.isFreeTierSubscriber}
            >
              {t('Schedule')}
            </Link>
          ),
        ].concat(actionsForCourse())}
        progress={progress}
        indicatorElementsArray={indicatorElementsArray}
        enrollments={
          classesStore.groupedPremiumSessionEnrollmentsByOffering[
            `${code}-${version}`
          ] || []
        }
        externallyExpanded={childrenPremiumClassCardOpened}
        actionToggle={premiumClassActionToggle}
        lastAccessed={lastAccessed}
        allowOrderingOffering={allowOrderingOffering}
        onOfferingOrderChange={onOfferingOrderChange}
      />
    );
  } else if (!userStore.isOpenSubscriberForCourse(`${code}-${version}`)) {
    // ======= standard card
    cardToRender = (
      <BaseCard
        customClassName={`course-card ${isRetiredCourse && 'grayscale-filter'}`}
        title={cardTitle}
        subtitle={
          isRetiredCourse
            ? t('Retired Offering')
            : isEarlyAccess
            ? `${t('Early Access')}!`
            : ''
        }
        iconClassName={modality}
        svgIconElement={iconElementForCourse()}
        cardColor={getCardColor()}
        actionButtonsArray={actionsForCourse()}
        progress={progress}
        indicatorElementsArray={indicatorElementsArray}
        actionToggle={getCollateral}
        leftPanelElement={lastAccessed && <span>{lastAccessed}</span>}
        allowOrderingOffering={allowOrderingOffering}
        onOfferingOrderChange={onOfferingOrderChange}
      >
        {isSearchResult ? (
          <div className="course-results">
            <React.Fragment>
              {course.pages
                ? Object.entries(course.pages).map(
                    renderHighlightsForSearchResults,
                  )
                : null}
            </React.Fragment>
          </div>
        ) : (
          <div className="course-collateral">
            <React.Fragment>{expandedCardContent}</React.Fragment>
          </div>
        )}
      </BaseCard>
    );
  } else {
    // ======= OPEN Subscriber card
    cardToRender = (
      <BaseCard
        customClassName="course-card"
        title={`${title} (${code.toUpperCase()})`}
        subtitle={isEarlyAccess ? `${t('Early Access')}!` : ''}
        iconClassName={modality}
        svgIconElement={iconElementForCourse()}
        cardColor={getCardColor()}
        actionButtonsArray={[
          <Link
            className={`course-view ${
              disableActionButtons && 'course-actions-disabled'
            }`}
            href
            routeName="courses:view"
            {...(disableActionButtons && { disabled: true })}
            routeParams={{
              course: `${code}-${version}`,
            }}
            key={uuidv4()}
            data-analytics-id={`launch-btn-open-course-card-lp-${code}-${version}`}
          >
            {alternativeReadButtonText || t('Launch')}
          </Link>,
        ]}
        progress={progress}
        indicatorElementsArray={indicatorElementsArray}
        allowOrderingOffering={allowOrderingOffering}
        onOfferingOrderChange={onOfferingOrderChange}
      >
        <div className="course-collateral">
          <React.Fragment>{expandedCardContent}</React.Fragment>
        </div>
      </BaseCard>
    );
  }

  return (
    <>
      {cardToRender}
      {isDisplayedInDasboard && store.showModalDownloadCertificate && (
        <CertificateOfAttendanceModal
          user={userStore.user}
          courseSlug={`${code}-${version}`}
          timestamp={store.dateToTimestamp(new Date())}
          setVisibility={store.setCertificateOfAttendanceModalVisibility}
        />
      )}
    </>
  );
};

CourseCard.propTypes = {
  alternativeReadButtonText: PropTypes.string,
  course: PropTypes.object,
  progress: PropTypes.number,
  displayVersionDropdown: PropTypes.bool,
  onVersionChange: PropTypes.func,
  versions: PropTypes.array,
  isSearchResult: PropTypes.bool,
  lastAccessed: PropTypes.object,
  courseCardButtons: PropTypes.array,
  disableActionButtons: PropTypes.bool,
  targetSubscription: PropTypes.string,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
};

CourseCard.defaultProps = {
  course: {},
  progress: 0,
  displayVersionDropdown: false,
  onVersionChange: () => {},
  versions: [],
  isSearchResult: false,
  lastAccessed: undefined,
  courseCardButtons: [],
  disableActionButtons: false,
  targetSubscription: null,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
  alternativeReadButtonText: null,
};

export default withErrorBoundary(
  observer(CourseCard),
  ErrorMessage,
  handleError,
);
