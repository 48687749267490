import React, { useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import banners from 'mocks/banners.mock';
import { storesContext } from 'stores';
import HeroBanner from '../Banners/HeroBanner';

import './styles.scss';

const HeroCarousel = () => {
  const { userStore } = useContext(storesContext);

  const filteredBanners = banners.filter((banner) => {
    if (banner.displayForSubscriptions) {
      return banner.displayForSubscriptions.includes(
        userStore.currentSubscriptionCode,
      );
    }
    return true;
  });

  return (
    <Carousel
      className="hero-slider"
      prevIcon={null}
      nextIcon={null}
      pauseOnHover
      interval={5000}
      slide
      touch="true"
      defaultActiveIndex={0}
      role="slider"
    >
      {filteredBanners.map((banner) => {
        // eslint-disable-next-line no-unused-vars
        const { displayForSubscriptions, ...bannerProps } = banner;
        return <HeroBanner key={shortid.generate()} {...bannerProps} />;
      })}
    </Carousel>
  );
};

export default observer(HeroCarousel);
