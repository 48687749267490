/**
 * @file Dashboard to manage and monitor activity on RHLS
 * @author Attila Farkas <afarkas@redhat.com>
 */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import DashboardComponent from '../../components/Dashboard';
import DashboardNavComponent from '../../components/Dashboard/DashboardNav';

const DashboardView = () => {
  return (
    <div className="container-grid-responsive">
      <Row className="justify-content-center">
        <Col md={12}>
          <DashboardNavComponent />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DashboardComponent />
        </Col>
      </Row>
    </div>
  );
};

export default withErrorBoundary(DashboardView, GenericViewError, handleError);
