import { createContext } from 'react';
import { action, observable } from 'mobx';

class HomeStore {
  @observable isTourDisplayed = false;

  @observable videoToPlay = '';

  @observable premClassesWithEnrollments = [];

  @action toggleTour = (video) => {
    this.videoToPlay = video;
    this.isTourDisplayed = !this.isTourDisplayed;
  };
}

export default HomeStore;
export const homeContext = createContext(null);
