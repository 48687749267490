import { get } from 'axios';
import {
  CATALOG_ENTRIES_API,
  COURSE_CATALOG_API,
  COURSE_CATALOG_LEGACY_API,
} from '../config/constants';

/**
 * Used for RHLS
 * @param {*} subscription subscription code
 * @returns catalog entries specific to that subscription
 */
export const getCatalog = async (subscription) => {
  if (!subscription) {
    throw new Error('Subscription code required');
  }

  try {
    const catalog = await get(`${COURSE_CATALOG_API}${subscription}`);
    const { catalog_entries: catalogEntries } = catalog.data;

    return catalogEntries;
  } catch (error) {
    throw new Error(error);
  }
};

export const getLegacyCatalog = async () => {
  try {
    const catalog = await get(COURSE_CATALOG_LEGACY_API);
    const { result } = catalog.data;

    return result;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Used for a la carte
 * @returns catalog entries
 */
export const getCatalogEntries = async () => {
  try {
    const entries = await get(CATALOG_ENTRIES_API);
    const { items } = entries.data;

    return items;
  } catch (error) {
    throw error;
  }
};

export default getCatalog;
