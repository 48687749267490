import { get, patch, post } from 'axios';
import { PREMVT_INTERESTED_SESSION_API } from '../config/constants';

export const getPremiumSessionInterests = async () => {
  try {
    const res = await get(PREMVT_INTERESTED_SESSION_API);
    const { items: docs } = res.data;

    return docs;
  } catch (error) {
    throw new Error(error);
  }
};

export const cancelPremiumSessionInterest = async (premvtSessionSlug) => {
  if (!premvtSessionSlug) {
    throw new Error('Missing Param: premvtSessionSlug');
  }

  return new Promise((resolve, reject) => {
    const url = `${PREMVT_INTERESTED_SESSION_API}/cancelled`;
    const data = { premvt_session_slug: premvtSessionSlug };
    patch(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createPremiumSessionInterest = async (
  offeringSlug,
  premiumSessionSlug,
  timeRange,
  daysAfter,
  notify,
  timezone,
) => {
  const data = {
    offering_slug: offeringSlug,
    premvt_session_slug: premiumSessionSlug,
    time_range: timeRange,
    days_after: daysAfter,
    notify,
    timezone,
  };
  try {
    const res = await post(PREMVT_INTERESTED_SESSION_API, data);
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};
