import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Glyphicon } from 'react-bootstrap';
import './styles.scss';

const ImpersonatingUserBanner = ({
  username,
  subscriptionType,
  clearAction,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="impersonating-user-banner">
        <span className="impersonating-text">
          <Glyphicon glyph="warning-sign" />
          {t('Warning! You are currently impersonating')} {`${username}, `}
          {t('subscription type:')} {subscriptionType || t('No subscription')}
        </span>
        <Button type="submit" onClick={() => clearAction()}>
          {t('Switch to my account')}
        </Button>
      </div>
    </>
  );
};

ImpersonatingUserBanner.propTypes = {
  username: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string.isRequired,
  clearAction: PropTypes.func.isRequired,
};

export default ImpersonatingUserBanner;
