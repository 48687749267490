/**
 * @file Provides usage staistics and analysis
 * @author Fola Oso <aoso@redhat.com>
 */

import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import {
  Col,
  Row,
  Form,
  ControlLabel,
  FormControl,
  FormGroup,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../Error/GenericViewError';
import { storesContext } from '../../../stores';
import './styles.scss';

const OrganizationForm = () => {
  const { t } = useTranslation();
  const { organizationStore } = useContext(storesContext);

  const handleSubmit = async () => {
    if (organizationStore.editingOrganization.id) {
      organizationStore.updateOrganization(
        organizationStore.editingOrganization.id,
        organizationStore.newOrgPayload,
      );
    } else {
      await organizationStore.addOrganization(organizationStore.newOrgPayload);
      organizationStore.newModal({
        title: t('Create organization'),
        text: t('Organization successfuly created'),
        actionText: t('OK'),
        onAction: () => {
          organizationStore.closeModal();
        },
      });
    }

    organizationStore.resetEditOrganization();
  };

  const closeModal = () => {
    organizationStore.setOrganizationModal(false, '');
  };

  return (
    <React.Fragment>
      <div id="org-form-div">
        <Form onSubmit={handleSubmit} horizontal>
          <FormGroup>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <h1>Create A New Company</h1>
                    <ControlLabel>Company Name</ControlLabel>
                    <FormControl
                      type="text"
                      maxLength="120"
                      autoCapitalize="off"
                      autoCorrect="off"
                      value={organizationStore.editingOrganization.name}
                      onChange={(e) => {
                        organizationStore.editingOrganization.name =
                          e.target.value;
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <ControlLabel>Order Numbers</ControlLabel>
                        <FormControl
                          componentClass="textarea"
                          rows="5"
                          autoCapitalize="off"
                          autoCorrect="off"
                          value={
                            organizationStore.editingOrganization.orderNumbers
                          }
                          onChange={(e) => {
                            organizationStore.editingOrganization.orderNumbers =
                              e.target.value;
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <ControlLabel>Admins</ControlLabel>
                        <FormControl
                          componentClass="textarea"
                          rows="5"
                          autoCapitalize="off"
                          autoCorrect="off"
                          value={
                            organizationStore.editingOrganization.adminUsers
                          }
                          onChange={(e) => {
                            organizationStore.editingOrganization.adminUsers =
                              e.target.value;
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <div className="pull-right">
                  {organizationStore.editingOrganization.id && (
                    <React.Fragment>
                      <Button
                        bsStyle="default"
                        onClick={() => {
                          organizationStore.resetEditOrganization();
                        }}
                      >
                        {t('Cancel')}
                      </Button>
                      {/* <Button
                        className="org-delete-btn"
                        onClick={() => {
                          organizationStore.newModal({
                            title: t('Delete organization'),
                            text: t(
                              'Are you sure you want to delete this organization?',
                            ),
                            actionText: t('Delete'),
                            onAction: () => {
                              organizationStore.deleteOrganization(
                                organizationStore.editingOrganization.id,
                              );
                              organizationStore.closeModal();
                              organizationStore.resetEditOrganization();
                            },
                          });
                        }}
                      >
                        {t('Delete')}
                      </Button> */}
                    </React.Fragment>
                  )}
                  <Button
                    bsStyle="primary"
                    onClick={handleSubmit}
                    disabled={
                      !organizationStore.editingOrganization.name.trim()
                        .length ||
                      !organizationStore.newOrgPayload.adminUsers.length
                    }
                  >
                    {organizationStore.editingOrganization.id
                      ? t('Update')
                      : t('Create')}
                  </Button>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Form>

        <Modal
          show={organizationStore.showOrganizationModal}
          onHide={closeModal}
        >
          <ModalHeader closeButton>
            <ModalTitle>{organizationStore.organizationModalText}</ModalTitle>
          </ModalHeader>
          <ModalFooter>
            <Button bsStyle="primary" onClick={closeModal}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default withErrorBoundary(
  observer(OrganizationForm),
  GenericViewError,
  handleError,
);
