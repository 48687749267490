import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import {
  Header as HeaderBase,
  useViewportSize,
} from '@ole-ui/ole-ui-components';
import NotificationBadge from 'react-notification-badge';
import { Link } from 'react-mobx-router5';
import { Avatar } from 'react-avatar';
import { storesContext } from 'stores';
import { logout } from 'services/AuthService';
import MenuIcon from 'img/icons/menu.svg';
import SearchIcon from 'img/icons/search-h.svg';
import LanguageIcon from 'img/icons/language-h.svg';
import SitesIcon from 'img/icons/sites-h.svg';
import RHLSLogo from 'img/rhls_logo.svg';
import RHTCLogo from 'img/rh-training-certification-logo.svg';
import { RHU_SUPPORT_URL, RHLC_BASE } from 'config/constants';
import SearchAutosuggest from 'components/Search';
import SubscriptionWidget from '../Subscriptions/LineWidget';
import TopBar from './TopBar';
import LanguageMenu from './LanguageMenu';
import AccountMenu from './AccountMenu';
import SitesMenu from './SitesMenu';

import './styles.scss';

const Header = () => {
  const { i18n, t } = useTranslation();
  const isMobile = useViewportSize().isLowerThanMd;
  const {
    routerStore,
    uiStore,
    userStore,
    eventStore,
    configStore,
    userNotificationsStore,
  } = useContext(storesContext);
  const { languages } = uiStore;
  const language = languages.find((lang) => lang.token === i18n.language) || {};
  const { display_name: languageName } = language;
  const { route } = routerStore;
  const { isLoggedIn, avatar, user } = userStore;
  const { preferred_name: preferredName } = user;

  const onLogout = async () => {
    await logout(userStore);
    uiStore.clearLabAutostartSettings();
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  };

  const handleMobileMenuChange = (isOpen) => {
    // eslint-disable-next-line no-unused-expressions
    window?.Intercom?.('update', {
      hide_default_launcher: isOpen,
    });
  };

  // Suggestion: In the future these can come from a new permission manager:
  const canViewLogo = !userStore.isOpenSubscriber;
  const canViewDashboard =
    userStore.isLoggedIn && !userStore.isAlaCarte && !userStore.isVTonly;
  const hasManagerRights =
    (userStore.isLoggedIn && userStore.isOrganizationManager) ||
    userStore.isOrganizationAdmin;
  const canViewCatalog =
    userStore.isLoggedIn &&
    !userStore.isAlaCarte &&
    !userStore.isLS101Subscriber;
  const canViewPremiumSessions =
    userStore.isLoggedIn &&
    ((!userStore.isAlaCarte && userStore.isPremiumSubscriber) ||
      userStore.isFreeTierSubscriber ||
      userStore.isLimitedTrialSubscriber);
  const canViewSkillsPaths =
    userStore.isLoggedIn &&
    !userStore.isAlaCarte &&
    !userStore.isLS101Subscriber &&
    !userStore.isExternalSubscriber &&
    !userStore.isWD200Subscriber;
  const canViewReports = userStore.isLoggedIn && !userStore.isOpenSubscriber;
  const canViewHelp =
    (userStore.isLoggedIn && !userStore.isExternalSubscriber) ||
    (userStore.isExternalSubscriber && userStore.isOpenSubscriber);
  const canViewSubscriptionWidget =
    userStore.isLoggedIn &&
    !userStore.isOpenSubscriber &&
    (userStore.isAlaCarte || userStore.isExternalSubscriber) &&
    route.name.includes('courses');
  const canViewEAbutton =
    userStore.isLoggedIn &&
    !userStore.isAlaCarte &&
    !userStore.isExternalSubscriber &&
    !userStore.isWD200Subscriber;

  const navigationItems = [
    {
      renderIf: userStore.isLoggedIn,
      key: 'home',
      onClick: () => {
        routerStore.navigate('home');
      },
      active: route.name === 'home',
      'data-analytics-id': 'home-nav-link-header-lp',
      text: t('Home'),
    },
    {
      renderIf: canViewDashboard && hasManagerRights,
      key: 'dashboard-manager',
      'data-analytics-id': 'dashboard-nav-link-header-lp',
      text: t('Dashboard'),
      active: route.name.includes('dashboard:'),
      items: [
        {
          key: 'dashboard:user',
          onClick: () => {
            routerStore.navigate('dashboard:user');
          },
          'data-analytics-id': 'my-progress-dashboard-nav-link-header-lp',
          text: t('My Progress'),
        },
        {
          key: 'dashboard:overview',
          onClick: () => {
            routerStore.navigate('dashboard:overview');
          },
          'data-analytics-id': 'mgmnt-overview-dashboard-nav-link-header-lp',
          text: t('Management Overview'),
        },
      ],
    },
    {
      renderIf: canViewDashboard && !hasManagerRights,
      key: 'dashboard',
      onClick: () => {
        routerStore.navigate('dashboard:user');
      },
      active: route.name === 'dashboard:user',
      'data-analytics-id': 'my-progress-dashboard-nav-link-header-lp"',
      text: t('Dashboard'),
    },
    {
      renderIf: canViewCatalog,
      key: 'catalog',
      onClick: () => {
        routerStore.navigate('catalog');
      },
      active: route.name === 'catalog',
      'data-analytics-id': 'catalog-nav-link-header-lp',
      text: t('Catalog'),
    },
    {
      renderIf: canViewPremiumSessions,
      key: 'premium-sessions',
      'data-analytics-id': 'live-sessions-nav-link-header-lp',
      text: t('Live Sessions'),
      active: route.name.includes('premium:sessions:'),
      items: [
        {
          key: 'premium-sessions-catalog',
          onClick: () => {
            routerStore.navigate('premium:sessions:live');
          },
          'data-analytics-id': 'catalog-live-sessions-nav-link-header-lp',
          text: t('Catalog'),
        },
        {
          key: 'premium-sessions-calendar',
          onClick: () => {
            routerStore.navigate('premium:sessions:calendar');
          },
          'data-analytics-id': 'calendar-live-sessions-nav-link-header-lp',
          text: t('Calendar'),
        },
      ],
    },
    {
      renderIf: canViewSkillsPaths,
      key: 'skills-paths',
      onClick: () => {
        routerStore.navigate('skills-paths');
      },
      active: route.name === 'skills-paths',
      'data-analytics-id': 'kills-paths-nav-link-header-lp',
      text: t('Skills Paths'),
    },
    {
      renderIf: canViewReports,
      key: 'reports',
      onClick: () => {
        routerStore.navigate('reports');
      },
      active: route.name === 'reports',
      'data-analytics-id': 'reports-nav-link-header-lp',
      text: t('Reports'),
    },
    {
      renderIf: userStore.isLoggedIn,
      key: 'community',
      href: RHLC_BASE, // if href is present, it's an anchor tag
      'data-analytics-id': '',
      text: t('Community'),
    },
    {
      renderIf: canViewHelp,
      key: 'help-dropdown',
      'data-analytics-id': 'help-nav-link-header-lp',
      text: t('Help'),
      active: route.name.includes('faq'),
      items: [
        userStore.isLoggedIn && !userStore.isRHUSubscriber
          ? {
              key: 'support-form',
              onClick: () => {
                eventStore.toggleDrawer('support_form');
              },
              'data-analytics-id': 'support-header-lp',
              text: t('Support'),
            }
          : {
              key: 'support-external',
              onClick: () => {
                window.open(
                  configStore?.config?.OLE_SUPPORT_URL || RHU_SUPPORT_URL,
                  '_blank',
                );
              },
              'data-analytics-id': 'support-header-lp',
              text: t('Support'),
            },
        {
          key: 'faq',
          onClick: () => {
            routerStore.navigate('faq');
          },
          'data-analytics-id': 'faq-header-lp',
          text: t('FAQ'),
        },
      ],
    },
  ];

  const menuItems = [
    {
      key: 'menu',
      'data-analytics-id': 'mobile-menu-header-lp',
      icon: <SVG src={MenuIcon}>{t('Menu')}</SVG>,
      text: t('Menu'),
      content: 'navigation',
      renderIf: userStore.isLoggedIn,
    },
    {
      renderIf: true,
      key: 'languages',
      'data-analytics-id': 'language-switcher-header-lp',
      icon: <SVG src={LanguageIcon}>{t('Languages')}</SVG>,
      text: languageName || t('Language'),
      positionOnMobile: 'bottom',
      indexOnMobile: 1,
      content: ({ setMenuOpen }) => <LanguageMenu setMenuOpen={setMenuOpen} />,
    },
    {
      renderIf: userStore.isLoggedIn,
      key: 'search',
      'data-analytics-id': 'search-menu-header-lp',
      icon: <SVG src={SearchIcon}>{t('Search')}</SVG>,
      text: t('Search'),
      positionOnMobile: 'top',
      content: (
        <div className="header__menu-item">
          <SearchAutosuggest autoFocus={!isMobile} />
        </div>
      ),
    },
    {
      renderIf: userStore.isLoggedIn,
      key: 'account',
      'data-analytics-id': 'account-menu-header-lp',
      icon: isLoggedIn ? (
        <React.Fragment>
          <Avatar
            className="avatar"
            size="30"
            round
            name={preferredName}
            src={avatar}
          />
          <NotificationBadge
            className="notification-icon-badge"
            style={{ top: '', right: '' }}
            count={
              eventStore.newNotifications.length +
                userNotificationsStore?.newUserNotifications?.length || 0
            }
          />
        </React.Fragment>
      ) : (
        <SVG src={SitesIcon}>{t('Red Hat Sites')}</SVG>
      ),
      text: isLoggedIn ? preferredName || t('Account') : t('Account'),
      positionOnMobile: 'bottom',
      indexOnMobile: 0,
      content: ({ setMenuOpen }) => (
        <div className="header__menu-item">
          <AccountMenu setMenuOpen={setMenuOpen} onLogout={onLogout} />
        </div>
      ),
    },
    {
      renderIf: true,
      'data-analytics-id': 'sites-menu-header-lp',
      key: 'sites',
      text: t('Sites'),
      positionOnMobile: 'bottom',
      icon: <SVG src={SitesIcon}>{t('Red Hat Sites')}</SVG>,
      content: (
        <div className="header__menu-item">
          <SitesMenu />
        </div>
      ),
      indexOnMobile: 2,
    },
  ];

  const renderEALink = ({ setMenuOpen } = {}) => {
    if (canViewEAbutton) {
      return (
        <Link
          href
          routeName="catalog.early-access"
          className="early-access-link"
          data-analytics-id="early-access-nav-link-header-lp"
          onClick={() => {
            if (setMenuOpen) {
              setMenuOpen();
            }
          }}
        >
          {t('Early Access')}
        </Link>
      );
    }
    return null;
  };

  return (
    <div>
      <TopBar />
      <HeaderBase
        className="header"
        menuItems={menuItems}
        navigationItems={navigationItems}
        onMobileMenuChange={handleMobileMenuChange}
      >
        {canViewLogo && !userStore.isWD200Subscriber && (
          <HeaderBase.Logo>
            <Link href routeName="home" data-analytics-id="rhls-logo-header-lp">
              <img
                src={RHLSLogo}
                className="logo"
                alt={t('Red Hat Learning Subscription logo')}
              />
            </Link>
          </HeaderBase.Logo>
        )}
        {canViewLogo && userStore.isWD200Subscriber && (
          <HeaderBase.Logo>
            <Link href routeName="home">
              <img
                src={RHTCLogo}
                className="logo"
                alt={t('Red Hat Training & Certification logo')}
              />
            </Link>
          </HeaderBase.Logo>
        )}
        <HeaderBase.Content>
          <HeaderBase.Navigation />
          <HeaderBase.CentralContent>
            {renderEALink()}
          </HeaderBase.CentralContent>
          <HeaderBase.SideMenu mobileBottomPanel={renderEALink} />
        </HeaderBase.Content>
      </HeaderBase>
      {canViewSubscriptionWidget && <SubscriptionWidget />}
    </div>
  );
};

export default observer(Header);
