import React, { useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormGroup,
  FormControl,
} from 'react-bootstrap';

import { SyncLoader } from 'react-spinners';
import SVG from 'react-inlinesvg';
import { storesContext } from 'stores';
import TellUSImg from 'img/tell_us_img.svg';
import { Modality } from 'types';
import FeedbackStore from './store';
import './styles.scss';

const FeedbackPane = () => {
  const { t } = useTranslation();
  const { userStore, eventStore } = useContext(storesContext);
  const store = useLocalStore(() => new FeedbackStore());
  const { course, courseVersion, pageTitle, uuid, url, slug, modality } =
    eventStore.feedBackProps;

  const isVideoClassroom = `${course}`.toLowerCase().endsWith('vc');
  const isTechOverview = modality === Modality.TechOverview;

  const defaultSubject =
    isVideoClassroom || isTechOverview
      ? 'Course content (video)'
      : 'Course content (text)';

  const onIssueDescriptionChange = (e) => {
    store.issueObj.description = e.target.value;
  };

  const onIssueTypeChange = (e) => {
    store.issueObj.type = e.target.value;
  };

  const onIssueSubjectChange = (e) => {
    store.issueObj.subject = e.target.value;
  };

  const onSubmit = (e) => {
    store.submitting = true;
    e.preventDefault();

    store.issueObj.course = FeedbackStore.sanitizeCourseCode(course);
    store.issueObj.sectionTitle = pageTitle;
    store.issueObj.sectionId = uuid;
    store.issueObj.url = url;
    store.issueObj.RHNUsername = userStore.username;
    store.issueObj.subject = store.issueObj.subject || defaultSubject;
    store.issueObj.slug = slug;
    store.issueObj.modality = modality;

    if (url.includes('pages')) {
      const page = url.substring(url.indexOf('pages/') + 6);
      store.issueObj.page = page;
    }

    if (store.issueObj.isTechOverview) {
      store.issueObj.summary = `Tech overview ${store.issueObj.slug} Feedback`;
      store.issueObj.version = 'Tech overview';
      store.issueObj.deliveryMode = 'tech_overview';
    } else if (course.includes('ea')) {
      store.issueObj.summary = 'Early Access Feedback';
      store.issueObj.version = 'Early Access';
    } else {
      store.issueObj.summary = `Course ${course}-${courseVersion} Feedback`;
      store.issueObj.version = courseVersion;
    }

    store
      .submit()
      .then(() => {
        store.submitted = true;
        store.issueObj.subject = '';
      })
      .catch(() => {
        store.error = true;
        return (error) => console.warn(error);
      })
      .then(() => {
        store.submitting = false;
      });
  };

  return (
    <div className="feedback-component">
      {!store.submitted && !store.error && (
        <Form>
          <div className="feedback-img-wrapper">
            <SVG src={TellUSImg} style={{ pointerEvents: 'none' }}>
              {t('Tell us what you think!')}
            </SVG>
          </div>
          <FormGroup controlId="feedbackTypeSelect">
            <ControlLabel>{t('Feedback type')}</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={(e) => {
                onIssueTypeChange(e);
              }}
            >
              <option value="Bug">{t('Bug')}</option>
              <option value="New Feature">{t('New Feature')}</option>
              <option value="Improvement">{t('Improvement')}</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="feedbacSubjectSelect">
            <ControlLabel>{t('Subject')}</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              defaultValue={defaultSubject}
              onChange={(e) => {
                onIssueSubjectChange(e);
              }}
            >
              <option value="Course content (text)">
                {t('Course content (text)')}
              </option>
              <option value="Course content (video)">
                {t('Course content (video)')}
              </option>
              <option value="Labs">{t('Labs')}</option>
              <option value="Course interface">{t('Course interface')}</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="feedbackTextArea">
            <ControlLabel>{t('Feedback')}</ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder={t('Enter feedback here')}
              onChange={(e) => {
                onIssueDescriptionChange(e);
              }}
            />
          </FormGroup>
          {!store.submitting && (
            <FormGroup>
              <ButtonToolbar style={{ float: 'right' }}>
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    store.submitted = false;
                    eventStore.isDrawerOpen = false;
                  }}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  bsStyle="primary"
                  type="submit"
                  disabled={store.issue.description.trim() === ''}
                  onClick={(e) => {
                    onSubmit(e);
                  }}
                >
                  {t('Submit')}
                </Button>
              </ButtonToolbar>
            </FormGroup>
          )}
        </Form>
      )}
      {store.submitted && (
        <div>
          <h3>{t('Thanks for the feedback!')}</h3>
          <div>
            <Button
              bsStyle="link"
              onClick={(e) => {
                e.preventDefault();
                store.submitted = false;
                eventStore.isDrawerOpen = false;
              }}
            >
              {t('Take me back')}
            </Button>
          </div>
          <div>
            <Button
              bsStyle="link"
              onClick={(e) => {
                e.preventDefault();
                store.submitted = false;
              }}
            >
              {t('Enter another')}
            </Button>
          </div>
          <div className="loading">
            <SyncLoader loading={store.submitting} size={10} />
          </div>
        </div>
      )}
      {store.error && (
        <div>
          <h3>{t('Error submitting feedback')}</h3>
          <p>{t('Please try again later.')}</p>
        </div>
      )}
      <div className="loading">
        <SyncLoader loading={store.submitting} size={10} />
      </div>
    </div>
  );
};

export default observer(FeedbackPane);
