import { observable, action } from 'mobx';

class VersionsDropdownStore {
  @observable showTooltip = false;

  @action hideTooltip = () => {
    this.showTooltip = false;
  };
}

export default VersionsDropdownStore;
