import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { PREMIUM_VT_SESSION_STATES } from 'config/constants';

class PremiumClassStore {
  @observable enrollments = [];

  @observable premiumClass = {};

  @observable ready = false;

  @observable externallyExpanded = false;

  @observable scheduleAllMode = false;

  @observable includedSessionsToSchedule = [];

  @observable displayScheduleAllModal = false;

  @observable isScheduleAllLoading = false;

  constructor(classesStore) {
    this.classesStore = classesStore;
  }

  @computed get sessions() {
    return this.premiumClass?.sessions || [];
  }

  @computed get scheduledSessions() {
    return this.premiumClass.scheduledSessions || [];
  }

  @computed get offeringSlug() {
    return this.premiumClass.offering_slug || '';
  }

  @computed get sessionModality() {
    return this.premiumClass.modality || 'course';
  }

  @computed get scheduledSessionsBySlug() {
    const sessions = {};
    this.scheduledSessions.forEach((session) => {
      const slug = session.premvt_session_slug;
      if (!(slug in sessions)) sessions[slug] = [];
      sessions[slug].push(session);
    });

    return sessions;
  }

  // eslint-disable-next-line class-methods-use-this
  getSessionFromEnrollment(enrollment) {
    const sessionUUID = enrollment.premvt_session_uuid;
    const foundSession = this.scheduledSessions.find(
      (session) => session.doc_id === sessionUUID,
    );

    return foundSession;
  }

  @action enrollmentForSessionSlug(sessionSlug) {
    // Finding the last enrollment with that sessionSlug
    const foundEnrollment = this.enrollments
      .slice()
      .sort((a, b) => {
        return new Date(b['@timestamp']) - new Date(a['@timestamp']);
      })
      .find((enrollment) => {
        const sessionSlugEnrollment =
          this.getSessionFromEnrollment(enrollment).premvt_session_slug;

        return sessionSlugEnrollment === sessionSlug;
      });

    return foundEnrollment;
  }

  @computed get sessionStatusUsingEnrollment() {
    return this.sessions.map((session) => {
      const slug = `${this.offeringSlug}-${session.slug}`;
      return { slug, state: this.enrollmentForSessionSlug(slug)?.state };
    });
  }

  @computed get consecutiveSessionsForScheduleAll() {
    // Used for Schedule All feature
    // returns ordered sessions, the next one
    // ending after the previous one
    // {'do101-4.5-s01': premvt_session, ...}
    const scheduleAllSessions = {};
    let lastClassEndTime;
    this.sessions.forEach((session) => {
      const sessionSlug = `${this.offeringSlug}-${session.slug}`;
      const scheduledSessions = this.scheduledSessionsBySlug[sessionSlug]?.sort(
        (session1, session2) =>
          moment(session1.start_time) - moment(session2.start_time),
      );
      const selectedSession = scheduledSessions?.find(
        (scheduledSession) =>
          // Selecting a session which start time is after previous end time
          // and has available seats and scheduled state
          (!lastClassEndTime ||
            moment(scheduledSession.start_time) > lastClassEndTime) &&
          scheduledSession.seats_available > 0 &&
          scheduledSession.state === PREMIUM_VT_SESSION_STATES.scheduled,
      );

      if (selectedSession) {
        lastClassEndTime = moment(selectedSession.end_time);
      }
      scheduleAllSessions[sessionSlug] = selectedSession;
    });

    return scheduleAllSessions;
  }

  @computed get isEnabledConfirmForScheduleAll() {
    return this.includedSessionsToSchedule.length > 0;
  }

  @computed get canScheduleAllSelectedSessions() {
    return (
      this.classesStore.numberAvailablePremiumSessionEnrollmentsForUser >=
      this.includedSessionsToSchedule.length
    );
  }

  @computed get includedSessionsIds() {
    return this.includedSessionsToSchedule.map((s) => s.doc_id);
  }

  @action getScheduledSessionForEnrollment(enrollment, slug) {
    const scheduledSession =
      enrollment &&
      this.scheduledSessions?.find(
        (s) => s.doc_id === enrollment.premvt_session_uuid,
      );

    if (scheduledSession) return scheduledSession;

    return this.getScheduledSessionBySlug(slug) || {};
  }

  @action getScheduledSessionBySlug(slug) {
    return this.scheduledSessions.find((session) => {
      return (
        session.premvt_session_slug === slug &&
        session.state === PREMIUM_VT_SESSION_STATES.scheduled
      );
    });
  }

  @action toggleScheduleAllMode() {
    if (!this.isScheduleAllLoading) {
      // Avoiding to cancel schedule all mode if a request is being made
      this.externallyExpanded = !this.scheduleAllMode;
      this.scheduleAllMode = !this.scheduleAllMode;
      this.includedSessionsToSchedule = [];
      if (this.scheduleAllMode) {
        // Adding by default all the possible sessions to include
        this.sessions.forEach((session) => {
          const sessionSlug = `${this.offeringSlug}-${session.slug}`;
          const scheduleAllSession =
            this.getScheduleAllSessionForSessionSlug(sessionSlug);
          if (scheduleAllSession)
            this.addIncludedSessionToSchedule(true, scheduleAllSession);
        });
      }
    }
  }

  @action addIncludedSessionToSchedule(add, session) {
    const sessionId = session.doc_id;
    if (add) {
      const isSessionAlreadyIncluded = this.includedSessionsToSchedule.find(
        (s) => s.doc_id === sessionId,
      );
      if (!isSessionAlreadyIncluded)
        this.includedSessionsToSchedule.push(session);
    } else {
      this.includedSessionsToSchedule = this.includedSessionsToSchedule.filter(
        (s) => s.doc_id !== sessionId,
      );
    }
  }

  @action getScheduleAllSessionForSessionSlug(sessionSlug) {
    const scheduleAllSession =
      this.consecutiveSessionsForScheduleAll[sessionSlug];

    return scheduleAllSession;
  }

  @action isSessionIncluded(session) {
    const included = this.includedSessionsToSchedule.find(
      (s) => s.doc_id === session.doc_id,
    );

    return !!included;
  }

  @action
  enrollUserIncludedPremiumSessions = async () => {
    const response = {};
    this.isScheduleAllLoading = true;
    const premiumVTSessionIdsArray = this.includedSessionsIds;
    const successfulEnrollments = [];
    const errorEnrollments = [];
    let index = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const sessionId of premiumVTSessionIdsArray) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const enrollmentResponse = await this.classesStore.enrollUser(
          sessionId,
          undefined,
          index + 1 === premiumVTSessionIdsArray.length, // Refreshing enrollments in the last id
        );
        successfulEnrollments.push(enrollmentResponse.data);
      } catch (e) {
        errorEnrollments.push(e);
      }

      index += 1;
    }

    response.success = successfulEnrollments;
    response.errors = errorEnrollments;
    this.isScheduleAllLoading = false;
    return response;
  };
}

export default PremiumClassStore;
