import React from 'react';

import { Skeleton } from '@patternfly/react-core';

export default function PageSkeletonLoading() {
  return (
    <React.Fragment>
      <Skeleton width="75%" fontSize="4xl" />
      <br />
      <Skeleton width="25%" />
      <br />
      <Skeleton width="33%" />
      <br />
      <Skeleton width="50%" />
      <br />
      <Skeleton width="50%" />
      <br />
      <Skeleton width="66%" />
      <br />
      <Skeleton width="100%" />
      <br />
      <Skeleton width="100%" />
      <br />
      <div style={{ height: '140px' }}>
        <Skeleton height="100%" width="100%" />
      </div>
      <br />
      <Skeleton width="100%" />
      <br />
      <Skeleton width="75%" />
      <br />
      <Skeleton width="66%" />
      <br />
      <Skeleton width="66%" />
      <br />
    </React.Fragment>
  );
}
