import { observable } from 'mobx';

class ContentAreaStore {
  @observable userSettings = {
    user_data: {
      bookmark: '',
      favorites: [],
    },
  };

  @observable favoriteClass = 'glyphicon-star-empty';
}

export default ContentAreaStore;
