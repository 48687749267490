import { action, computed, observable, autorun } from 'mobx';

class PendoStore {
  // THIS CAN BE PART OF OLE-CONFIG
  COURSES_TO_TRACK = [
    'rh124-8.2',
    'rh124vc-8.0',
    'rh134-8.2',
    'rh134vc-8.0',
    'do180-4.6',
    'do180vc-4.6',
    'rh294-8.0',
    'rh294-8.4',
    'do280-4.6',
    'do288-4.6',
    'do250-1.0',
    'do101-4.6',
  ];

  constructor(rootStore) {
    this.rootStore = rootStore;
    autorun(() => {
      if (this.rootStore.userStore.subscription && this.isPendoInitialized) {
        this.sendSubscription();
      }
    });
  }

  @observable isPendoInitialized = false;

  @observable pendoProgressWatcher = null;

  @observable currentUserSubscription = null;

  @computed get usersToTrack() {
    return this.rootStore?.configStore?.pendoUsersToTrack;
  }

  @computed get userSubscription() {
    const { subscription } = this.rootStore.userStore;
    if (this.rootStore.userStore.isLoggedIn && subscription.subscription) {
      const _usersubscription = {
        ...(subscription.subscription && {
          subscription: subscription.subscription,
        }),
        ...(subscription.region && {
          region: subscription.region,
        }),
        ...(subscription.start_time && {
          subscription_start_time: subscription.start_time,
        }),
        ...(subscription.end_time && {
          subscription_end_time: subscription.end_time,
        }),
      };
      return _usersubscription;
    }
    return { subscription: null };
  }

  @computed get entitlement() {
    if (this.rootStore.userStore.isOpenSubscriber)
      return { entitlement: 'open' };

    if (this.rootStore.userStore.hasVT) return { entitlement: 'vt' };

    if (this.rootStore.userStore.hasAlaCarteEnrollments)
      return { entitlement: 'alacarte' };

    return { entitlement: null };
  }

  @computed get visitor() {
    const { user, name } = this.rootStore.userStore;
    let _visitor = null;
    if (
      this.rootStore.userStore.isLoggedIn &&
      this.isUserAllowedToBeTracked(user.username)
    ) {
      _visitor = {
        ...(user.uuid && { id: user.uuid }),
        ...(user.sessionid && { sessionid: user.sessionid }),
        ...(user.default_country && { country: user.default_country }),
        ...(this.rootStore.configStore.rhtDeployment && {
          platform: this.rootStore.configStore.rhtDeployment,
        }),
        ...this.userSubscription,
        ...this.entitlement,
        hasPersonalData: name?.split(/\s+/).length >= 2,
      };
    }

    return _visitor;
  }

  @computed get account() {
    const { subscription } = this.rootStore.userStore;

    const _accountName = subscription?.account_name;

    if (
      this.rootStore.userStore.isLoggedIn &&
      subscription.subscription &&
      _accountName
    ) {
      const _account = {
        id: _accountName,
        ...(subscription.global_customer_name && {
          globalCustomerName: subscription.global_customer_name,
        }),
      };

      return _account;
    }
    return null;
  }

  @action initPendo() {
    const _visitor = this.visitor;
    const _account = this.account;

    if (_visitor && !this.isPendoInitialized) {
      this.isPendoInitialized = true;

      window.pendo.initialize({
        visitor: _visitor,
        ...(_account && {
          account: _account,
        }),
      });
    }
  }

  @computed get courseProgress() {
    const _progressStore = this.rootStore.progressStore;

    const _progressToSend = this.COURSES_TO_TRACK?.map((courseSlug) => {
      const _courseProgress = _progressStore.progressDict[courseSlug];
      return _courseProgress && _courseProgress.total_progress
        ? {
            [courseSlug?.replace('-', '_')?.replace('.', '_')]:
              _courseProgress.total_progress,
          }
        : null;
    })?.filter((p) => p !== null);

    return _progressToSend?.length > 0
      ? Object.assign(
          {},
          ..._progressToSend,
          _progressStore.lastAccessProgress && {
            last_access_date: _progressStore.lastAccessProgress['@timestamp'],
          },
        )
      : null;
  }

  @action sendSubscription() {
    if (
      this.isPendoInitialized &&
      this.isUserAllowedToBeTracked(this.rootStore.userStore.username) &&
      this.rootStore.userStore.subscription.subscription &&
      this.currentUserSubscription !==
        this.rootStore.userStore.subscription.subscription
    ) {
      this.currentUserSubscription =
        this.rootStore.userStore.subscription.subscription;

      const _visitor = this.visitor;
      const _account = this.account;

      window.pendo.updateOptions({
        visitor: _visitor,
        ...(_account && {
          account: _account,
        }),
      });
    }
  }

  @action isUserAllowedToBeTracked(username) {
    const _isTrackAllUsersEnabled =
      this.rootStore?.configStore.pendoTrackALLUsers || false;

    if (_isTrackAllUsersEnabled) return true;

    const _usersToTrack = this.rootStore.configStore.pendoUsersToTrack || [];

    return _usersToTrack.length > 0
      ? _usersToTrack.filter(
          (u) => u.trim().toLowerCase() === username.trim().toLowerCase(),
        ).length > 0
      : false;
  }

  /**
   * https://support.pendo.io/hc/en-us/articles/360032294291-Configure-Track-Events
   */
  @action sendTrackEvent(eventName, params) {
    if (
      this.isPendoInitialized &&
      this.isUserAllowedToBeTracked(this.rootStore.userStore.username)
    ) {
      // window.pendo.enableDebugging();
      window.pendo?.track?.(eventName, params);
    }
  }

  @action showGuideById(guideId) {
    // https://agent.pendo.io/public/Guides/
    if (
      guideId &&
      this.isPendoInitialized &&
      this.isUserAllowedToBeTracked(this.rootStore.userStore.username)
    ) {
      return window.pendo?.showGuideById?.(guideId); // returns true if guide was showed and false if not. False can be returned for example when user is not in the segment
    }
    return false;
  }

  @action showGuideByName(guideName) {
    if (
      guideName &&
      this.isPendoInitialized &&
      this.isUserAllowedToBeTracked(this.rootStore.userStore.username)
    ) {
      return window.pendo?.showGuideByName?.(guideName); // returns true if guide was showed and false if not. False can be returned for example when user is not in the segment
    }
    return false;
  }
}

export default PendoStore;
