import {
  Button,
  Text,
  TextVariants,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Checkbox,
  FormAlert,
  Alert,
  TextInput,
  ValidatedOptions,
  HelperText,
  HelperTextItem,
  Switch,
} from '@patternfly/react-core';
import {
  CenteredLoadingSpinner,
  AlertGroupProvider,
  KalturaWidget,
  useFeature,
} from '@ole-ui/ole-ui-components';
import { withErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { storesContext } from 'stores';

import './styles.scss';
import {
  KALTURA_CONFIGS,
  PRIVACY_POLICY_URL,
  PREFERENCE_UNSUBSCRIBE,
} from 'config/constants';
import AWSWAFCaptcha from 'components/AWSWAFCaptcha';
import {
  FormFields,
  countriesThatRequireDataSharingConsent,
  getSchemaWithTranslations,
} from './schema';
import ValidationText from './ValidationText';

function FreeTrialRegistration() {
  const { t } = useTranslation();
  const isCaptchaEnabled = useFeature({
    section: 'free_trial',
    flagName: 'captcha_active',
    defaultFlagValue: true,
  });

  const [isPreloadingData, setIsPreloadingData] = useState(true);
  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const { addAlert } = AlertGroupProvider.useAlertGroupContext();
  const { configStore, routerStore, userStore, vocabularyStore } =
    useContext(storesContext);
  const { getFreeTrialRegistrationInitialValues } = userStore;
  const {
    isFreeTrialRegistrationEnabled,
    isFreeTrialRestartEnabled,
    captchaAPIKEY,
  } = configStore;
  const {
    fetchCountriesForCurrentLanguage,
    countriesByCurrentLanguage,
    // fetchJobRolesForCurrentLanguage,
    // jobRolesByCurrentLanguage,
    // fetchDepartmentsForCurrentLanguage,
    // departmentsByCurrentLanguage,
  } = vocabularyStore;

  const instanceCountryCode = configStore.isChina
    ? 'CN'
    : userStore.defaultCountry || 'US';

  const defaultCountry = countriesByCurrentLanguage?.find(
    (c) => c.token === instanceCountryCode,
  );

  const defaultValues: FormFields = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    country: defaultCountry?.token || instanceCountryCode,
    phone: '',
    company: '',
    // department: '',
    // job_title: '',
    notify_me: false,
    captcha: '',
    consent_external_data_sharing: false,
  };

  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormFields>({
    resolver: yupResolver(
      getSchemaWithTranslations(t, isCaptchaEnabled),
    ) as never,
    defaultValues,
  });

  const [currentlySelectedCountry] = watch(['country']);

  const includeExternalDataSharingConsent =
    countriesThatRequireDataSharingConsent.includes(
      currentlySelectedCountry?.toUpperCase?.(),
    );

  useEffect(() => {
    const preLoadForm = async () => {
      try {
        setIsPreloadingData(true);
        const fieldList = Object.keys(defaultValues);
        const initialValues = await getFreeTrialRegistrationInitialValues(
          fieldList,
        );
        reset({ ...defaultValues, ...initialValues });
        await fetchCountriesForCurrentLanguage();
        // await fetchJobRolesForCurrentLanguage();
        // await fetchDepartmentsForCurrentLanguage();
      } catch (e) {
        console.error('Could not preload form data', e);
      } finally {
        setIsPreloadingData(false);
      }
    };

    preLoadForm();
  }, []);

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      await userStore.submitFreeTrialRegistration(data);
      addAlert(
        t('Free trial registration sent successfully.'),
        'success',
        `freetrialform-success-${Date.now()}`,
        {
          timeout: 10 * 1000,
        },
      );
      routerStore.navigate('home');
    } catch (e) {
      const errorMessage = t(
        `Could not submit free trial registration. Please try again.`,
      );
      addAlert(errorMessage, 'danger', `freetrialform-error-${Date.now()}`, {
        timeout: 5000,
      });
      setError('root.serverError', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  if (!isFreeTrialRegistrationEnabled) {
    // page not enabled
    routerStore.navigate('home');
    return null;
  }

  if (userStore.hasUserAnyPastSubscription && !isFreeTrialRestartEnabled) {
    // user can't restart free trial
    routerStore.navigate('home');
    return null;
  }

  if (userStore.isUserAuthorized) {
    // user can't restart free trial
    routerStore.navigate('home');
    return null;
  }

  if (isPreloadingData) {
    return (
      <div className="free-trial-registration__loading-state">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  return (
    <div className="free-trial-registration container-grid-responsive">
      <div className="free-trial-registration__content">
        <Text component={TextVariants.h1}>
          {t('Start learning with your 14-day free trial')}
        </Text>
        <Text component={TextVariants.p}>
          {t(
            'Red Hat Learning Subscription is an on-demand prescriptive solution for keeping pace with Red Hat technologies. Take advantage of a free trial to explore the first two chapters of courses, experience Red Hat products with four hours of lab time and outline a custom learning path.',
          )}
        </Text>

        <Form
          className="free-trial-registration__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup
            isRequired
            label={t('Red Hat username')}
            fieldId="username"
          >
            <Controller
              name="username"
              key="username"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  readOnly
                  isDisabled={isSubmitting}
                  type="text"
                  id="username"
                  placeholder={t('Username')}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />
            <ValidationText
              hasError={Boolean(errors.username)}
              message={errors?.username?.message}
            />
          </FormGroup>
          <FormGroup isRequired label={t('First name')} fieldId="first_name">
            <Controller
              name="first_name"
              key="first_name"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  isDisabled={isSubmitting}
                  type="text"
                  id="first_name"
                  placeholder={t('First name')}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />
            <ValidationText
              hasError={Boolean(errors.first_name)}
              message={errors?.first_name?.message}
            />
          </FormGroup>
          <FormGroup isRequired label={t('Last name')} fieldId="last_name">
            <Controller
              name="last_name"
              key="last_name"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  isDisabled={isSubmitting}
                  type="text"
                  id="last_name"
                  placeholder={t('Last name')}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />
            <ValidationText
              hasError={Boolean(errors.last_name)}
              message={errors?.last_name?.message}
            />
          </FormGroup>
          <FormGroup isRequired label={t('Email address')} fieldId="email">
            <Controller
              name="email"
              key="email"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  isDisabled={isSubmitting}
                  type="text"
                  id="email"
                  placeholder={t('Email address')}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />
            <ValidationText
              hasError={Boolean(errors.email)}
              message={errors?.email?.message}
            />
          </FormGroup>
          <FormGroup label={t('Country')} isRequired fieldId="country">
            <Controller
              name="country"
              key="country"
              control={control}
              render={({ field }) => (
                <FormSelect
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  isDisabled={isSubmitting}
                  id="country"
                  aria-label={t('Country')}
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                >
                  {countriesByCurrentLanguage.map((option) => (
                    <FormSelectOption
                      key={option.token}
                      value={option.token}
                      label={option.display_name}
                    />
                  ))}
                </FormSelect>
              )}
            />
            <ValidationText
              hasError={Boolean(errors.country)}
              message={errors?.country?.message}
            />
          </FormGroup>
          <FormGroup isRequired label={t('Phone')} fieldId="phone">
            <Controller
              name="phone"
              key="phone"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  isDisabled={isSubmitting}
                  type="tel"
                  id="phone"
                  placeholder={t('Phone')}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />
            <ValidationText
              hasError={Boolean(errors.phone)}
              message={errors?.phone?.message}
            />
          </FormGroup>
          <FormGroup isRequired label={t('Company')} fieldId="company">
            <Controller
              name="company"
              key="company"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  isDisabled={isSubmitting}
                  type="text"
                  id="company"
                  placeholder={t('Company')}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />
            <ValidationText
              hasError={Boolean(errors.company)}
              message={errors?.company?.message}
            />
          </FormGroup>
          {/* <FormGroup
            isRequired={false}
            label={t('Department')}
            fieldId="department"
            validated={
              errors.department
                ? ValidatedOptions.error
                : ValidatedOptions.default
            }
            helperTextInvalid={errors.department?.message}
          >
            <Controller
              name="department"
              key="department"
              control={control}
              render={({ field }) => (
                <FormSelect
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  isDisabled={isSubmitting}
                  id="department"
                  aria-label={t('Department')}
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                >
                  <FormSelectOption
                    key="none"
                    value=""
                    label={t('Select department')}
                  />
                  {departmentsByCurrentLanguage.map((option) => (
                    <FormSelectOption
                      key={option.token}
                      value={option.token}
                      label={option.display_name}
                    />
                  ))}
                </FormSelect>
              )}
            />
          </FormGroup>
          <FormGroup
            label={t('Job title')}
            isRequired={false}
            fieldId="job_title"
            validated={
              errors.job_title
                ? ValidatedOptions.error
                : ValidatedOptions.default
            }
            helperTextInvalid={errors.job_title?.message}
          >
            <Controller
              name="job_title"
              key="job_title"
              control={control}
              render={({ field }) => (
                <FormSelect
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  isDisabled={isSubmitting}
                  id="job_title"
                  aria-label={t('Job title')}
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                  validated={
                    errors[field.name]
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                >
                  <FormSelectOption
                    key="none"
                    value=""
                    label={t('Select job title')}
                  />
                  {jobRolesByCurrentLanguage.map((option) => (
                    <FormSelectOption
                      key={option.token}
                      value={option.token}
                      label={option.display_name}
                    />
                  ))}
                </FormSelect>
              )}
            />
          </FormGroup> */}
          <div className="free-trial-registration__form-marketing">
            <Text component={TextVariants.h4}>{t('Marketing opt-in')}</Text>
            <Text component={TextVariants.p}>
              {t(
                'Red Hat may use your personal data to inform you about its products, services, and events.',
              )}
            </Text>
            <Controller
              name="notify_me"
              key="notify_me"
              control={control}
              render={({ field }) => (
                <Checkbox
                  isDisabled={isSubmitting}
                  isChecked={field.value}
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                  label={t('Notify me about products, services and events.')}
                  id="notify_me"
                  aria-label={t('Marketing opt-in checkbox')}
                  className="free-trial-registration__mkt-checkbox"
                  data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                />
              )}
            />

            <Text component={TextVariants.p}>
              <Trans>
                You can stop receiving marketing emails by clicking the
                unsubscribe link in each email or withdraw your consent at any
                time in the{' '}
                <a
                  href={PREFERENCE_UNSUBSCRIBE}
                  target="_blank"
                  rel="noreferrer"
                >
                  preference center
                </a>
                . See{' '}
                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                  privacy statement
                </a>{' '}
                for details.
              </Trans>
            </Text>
          </div>

          {includeExternalDataSharingConsent && (
            <Controller
              name="consent_external_data_sharing"
              key="consent_external_data_sharing"
              control={control}
              render={({ field }) => (
                <div className="free-trial-registration__form-consent">
                  <Checkbox
                    isDisabled={isSubmitting}
                    isChecked={field.value}
                    onChange={(event) => {
                      field.onChange(event);
                    }}
                    label={t(
                      'I agree that my personal information may be transferred outside the country for the purpose of providing me relevant products and services.',
                    )}
                    id={field.name}
                    aria-label={t('Data consent checkbox')}
                    className="free-trial-registration__mkt-checkbox"
                    data-analytics-id={`${field.name}-input-free-trial-registration-lp`}
                  />
                  {errors[field.name] && (
                    <HelperText className="free-trial-registration__form-error">
                      <HelperTextItem variant="error" hasIcon>
                        {errors[field.name].message}
                      </HelperTextItem>
                    </HelperText>
                  )}
                </div>
              )}
            />
          )}

          {isCaptchaEnabled && (
            <div className="free-trial-registration__form-captcha">
              <Controller
                name="captcha"
                key="captcha"
                control={control}
                render={({ field }) => (
                  <>
                    {captchaAPIKEY ? (
                      <AWSWAFCaptcha
                        apiKey={captchaAPIKEY}
                        isOpen={isCaptchaModalOpen}
                        onClose={() => setIsCaptchaModalOpen(false)}
                        onSuccess={(wafToken) => {
                          setIsCaptchaModalOpen(false);
                          field.onChange(wafToken);
                        }}
                        onError={(e) => {
                          console.error('Captcha verification error:', e);
                          addAlert(
                            t('Could not verify captcha. Please try again.'),
                            'danger',
                            `freetrialform-error-${Date.now()}`,
                            {
                              timeout: 5000,
                            },
                          );
                        }}
                      />
                    ) : (
                      <Text component={TextVariants.h1}>
                        {t(
                          'Captcha verification could not load: Key not present in environment config',
                        )}
                      </Text>
                    )}
                    <Switch
                      label={t('I am not a robot')}
                      labelOff={t('I am not a robot')}
                      isChecked={Boolean(field.value)}
                      hasCheckIcon
                      onChange={() => {
                        if (!field.value) {
                          setIsCaptchaModalOpen(true);
                        }
                      }}
                    />
                    {errors[field.name] && (
                      <HelperText className="free-trial-registration__form-error">
                        <HelperTextItem variant="error" hasIcon>
                          {errors[field.name].message}
                        </HelperTextItem>
                      </HelperText>
                    )}
                  </>
                )}
              />
            </div>
          )}

          {errors.root?.serverError?.message && (
            <FormAlert>
              <Alert
                variant="danger"
                title={errors.root?.serverError?.message}
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}

          <div>
            <Button
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              data-analytics-id="submit-btn-free-trial-registration-lp"
            >
              {t('Start free trial')}
            </Button>
          </div>
        </Form>
      </div>
      <div className="free-trial-registration__video">
        <KalturaWidget
          className="landing-mat__tutorial-video"
          partnerId={KALTURA_CONFIGS.partnerId}
          playerId={KALTURA_CONFIGS.freeTrialTutorial.uiConfId}
          entryId={KALTURA_CONFIGS.freeTrialTutorial.entryId}
          isPlaylist={false}
        />
      </div>
    </div>
  );
}

export default withErrorBoundary(observer(FreeTrialRegistration));
