const labLimits = {
  ls000: 1,
  ls120: 400,
  ls220: 400,
  ls300: 100,
  ls810: 400,
  ls999: 40,
  wdp200sp: 160,
};

export default labLimits;
