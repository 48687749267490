const FAQS = [
  {
    id: 40,
    center: true,
    key: 51,
    title: 'ACCESSING RED HAT LEARNING SUBSCRIPTION',
  },
  {
    id: 1,
    icon: 'Q1',
    class: 'faq-icon',
    key: 62,
    center: false,
    question: 'How do I log in to my Red Hat Learning Subscription?',
    answer: [
      {
        key: 1,
        class: 'list-item pre-link',
        text: 'Subscribers can log in from anywhere there is an internet connection at the top of the Red Hat Learning Subscription page: ',
      },
      {
        key: 2,
        class: 'list-item',
        link: 'https://www.redhat.com/en/services/training/learning-subscription',
      },
      {
        key: 123,
        class: 'list-item',
        text: 'Red Hat Learning Subscriptions in the People’s Republic of China are accessible via Red Hat’s distribution and marketing Partner NIIT Shanghai Ltd.',
      },
    ],
  },
  {
    id: 2,
    icon: 'Q2',
    class: 'faq-icon',
    center: false,
    key: 63,
    question:
      'Where are the basic, standard, developer, and premium tier subscriptions available globally?',
    answer: [
      {
        key: 3,
        class: 'list-item',
        text: 'The basic, standard, developer, and premium Red Hat Learning Subscriptions are available globally, except for China. Red Hat Learning Subscription tiers and offerings may differ in the People’s Republic of China and all Red Hat Learning Subscriptions in China can be accessed via our distribution and marketing partner, NIIT Shanghai Ltd. Because Red Hat Learning Subscription is an online service with online content, customers should verify that their connectivity is sufficient to use the service. You can verify your network connectivity by using our tool at',
      },
      {
        key: 4,
        class: 'list-item',
        link: 'https://www.redhat.com/rhtapps/compatibility',
      },
    ],
  },
  {
    id: 3,
    icon: 'Q3',
    class: 'faq-icon',
    key: 64,
    center: false,
    question:
      'How and when do I get access to my Red Hat Learning Subscription after I purchase it?',
    answer: [
      {
        key: 5,
        class: 'list-item',
        text: 'You can access your subscription within one business day of the order being booked. Instructions for accessing the content will be sent after the order is processed in the system.',
      },
      // { key: 9, class: 'list-item', imageUrl: Img1 },
    ],
  },
  {
    id: 41,
    center: true,
    key: 52,
    title: 'FEATURES OF RED HAT LEARNING SUBSCRIPTION',
  },
  {
    id: 4,
    icon: 'Q4',
    class: 'faq-icon',
    center: false,
    key: 65,
    question: 'What is Red Hat Learning Subscription?',
    answer: [
      {
        key: 6,
        class: 'list-item',
        text: 'Red Hat Learning Subscription is a learning solution that provides users with one year of access to self-paced training courses. There are four subscriptions available for purchase that include additional features: premium , standard, basic, and developer. For each of these tiers, there is also an enterprise subscription package.',
      },
    ],
  },
  {
    id: 5,
    icon: 'Q5',
    class: 'faq-icon',
    center: false,
    key: 66,
    question: 'What is included in my basic tier subscription?',
    answer: [
      {
        key: 7,
        class: 'list-item',
        text: 'The basic tier of Red Hat Learning Subscription includes access to our entire catalog of self-paced learning content, skills paths, and early access content. The subscription also includes up to 400 hours of lab access, up to 10 e-book downloads, and chat or email support for technical questions. *The availability of certain Red Hat Learning Subscription tiers, features and content may differ in the People’s Republic of China',
      },
    ],
  },
  {
    id: 6,
    icon: 'Q6',
    class: 'faq-icon',
    center: false,
    key: 67,
    question: 'What is included in my standard tier subscription?',
    answer: [
      {
        key: 8,
        class: 'list-item pre-link',
        text: 'The standard tier includes access to our entire catalog of self-paced learning content, early access content, skills paths, and up to 400 hours of hands-on lab access. In addition to what you get in the basic tier subscription, you also receive 5 certification exams, expert extras, and expert chat. *The availability of certain Red Hat Learning Subscription tiers, features and content may differ in the People’s Republic of China',
      },
    ],
  },
  {
    id: 7,
    icon: 'Q7',
    class: 'faq-icon',
    center: false,
    key: 68,
    question: 'What is included in my developer tier subscription?',
    answer: [
      {
        key: 10,
        class: 'list-item',
        text: 'The developer tier includes access to our entire catalog of developer self-paced learning content, and up to 100 hours of hands-on lab time. In addition, you have access to 3 developer certification exams. *The availability of certain Red Hat Learning Subscription tiers, features and content may differ in the People’s Republic of China',
      },
    ],
  },
  {
    id: 61,
    icon: 'Q8',
    class: 'faq-icon',
    center: false,
    key: 79,
    question: 'What is included in my premium tier subscription?',
    answer: [
      {
        key: 80,
        class: 'list-item',
        text: 'The premium tier includes access to our entire catalog of self-paced learning content, as well as live instructor-led premium sessions. Premium tier includes up to 400 hours of hands-on lab time, 5 certification exams, expert extras, and expert chat. *The availability of certain Red Hat Learning Subscription tiers, features and content may differ in the People’s Republic of China',
      },
    ],
  },
  {
    id: 8,
    icon: 'Q9',
    class: 'faq-icon',
    center: false,
    key: 69,
    question: 'What are expert extras?',
    answer: [
      {
        key: 11,
        class: 'list-item',
        text: 'Expert extras are on-demand, high-definition videos offered as part of the standard and premium tiers of Red Hat Learning Subscription to complement course content. These expert extras are not scheduled and are available on demand at any time within the subscription term. These 8 to 15 minute videos are presented by Red Hat and expert practitioners as demos of innovative technologies and practices, based on real-world use cases and scenarios. These seminars have a broad appeal to anyone involved with IT.',
      },
    ],
  },
  {
    id: 9,
    icon: 'Q10',
    class: 'faq-icon',
    center: false,
    key: 70,
    question: 'What is early access content?',
    answer: [
      {
        key: 12,
        class: 'list-item',
        text: 'The early access content feature gives subscribers exclusive access to course chapters and lab environments in development before they are released for public purchase. All early access content is available in all subscriptions and allows users to give feedback on the material. Once the courses and labs are completed, they will be added to the full catalog in the subscription.',
      },
    ],
  },
  {
    id: 60,
    icon: 'Q11',
    class: 'faq-icon',
    key: 121,
    center: false,
    question: 'What is the Dashboard?',
    answer: [
      {
        key: 122,
        class: 'list-item pre-link',
        text: 'The dashboard delivers a convenient summary of your training progress and content consumption. The metrics help you track your growth and education throughout your learning journey. In addition to course progress, lab usage, and exam status, the dashboard also provides data about the amount of training content you have reviewed and the total time spent within the Red Hat Learning Subscription. You can download a report of these metrics to share with your manager.',
      },
    ],
  },
  {
    id: 10,
    icon: 'Q12',
    class: 'faq-icon',
    center: false,
    key: 71,
    question: 'Which courses are available in each subscription tier?',
    answer: [
      {
        key: 13,
        class: 'list-item',
        text: 'All current Red Hat online training courses and video classroom courses as well as any new courses that Red Hat Training makes available during the subscription term are included in the basic, standard, and premium tiers. This includes all the same content and labs that students access in instructor-led classes. All developer-related courses and labs are available in the developer tier. The developer tier does not include all courses across the entire Red Hat Training portfolio. Contact your regional training team for a list of specific courses available.',
      },
    ],
  },
  {
    id: 11,
    icon: 'Q13',
    class: 'faq-icon',
    center: false,
    key: 72,
    question:
      'Will new training courses or updated versions of existing training courses be available to customers during the term of their subscription?',
    answer: [
      {
        key: 14,
        class: 'list-item',
        text: 'Yes. As Red Hat releases new self-paced courses, the content will be made available to Red Hat Learning Subscription customers. In addition, as the content is being developed, chapters will be released into the catalog under the early access filter with EA added to the beginning of the course title. It will then move to the full catalog when the course is final.',
      },
    ],
  },
  {
    id: 12,
    icon: 'Q14',
    class: 'faq-icon',
    center: false,
    key: 73,
    question:
      'What level of support will customers have in the basic subscription?',
    answer: [
      {
        key: 15,
        class: 'list-item',
        text: 'Customers of the basic tier of Red Hat Learning Subscription will have email support provided through a support form in the platform, available from the "Support" link at the top of the page. This support will cover technical issues, including access and connectivity. There is a service-level agreement (SLA) of one business day on all support tickets. Support is provided in English only. There is also a chat option that will allow basic users to communicate in real time with our technical support team. Please refer to the related FAQ questions regarding the chat. RHLS content and support may differ in the People’s Republic of China.',
      },
    ],
  },
  {
    id: 13,
    icon: 'Q15',
    class: 'faq-icon',
    center: false,
    key: 74,
    question:
      'What level of support will customers have in the standard subscription?',
    answer: [
      {
        key: 16,
        class: 'list-item',
        text: 'In addition to receiving the same level of support provided in the basic subscription, standard subscribers may use chat to get additional assistance with course content from a subject matter expert. Chat support is provided in English. *Note that the availability of subject matter experts for chat may be limited based on schedule or assistance of other customers.',
      },
    ],
  },
  {
    id: 14,
    icon: 'Q16',
    class: 'faq-icon',
    center: false,
    key: 75,
    question:
      'What level of support will customers have in the developer subscription?',
    answer: [
      {
        key: 17,
        class: 'list-item',
        text: 'Customers of the developer tier of Red Hat Learning Subscription will have email support provided through a support form in the platform, available from the "Support" link at the top of the page. This support will cover technical issues, including access and connectivity. There is a service-level agreement (SLA) of one business day on all support tickets. Support is provided in English only. There is also a chat option that will allow developer users to communicate in real time with our technical support team. Please refer to the related FAQ questions regarding the chat.',
      },
    ],
  },
  {
    id: 62,
    icon: 'Q17',
    class: 'faq-icon',
    center: false,
    key: 81,
    question:
      'What level of support will customers have in the premium subscription?',
    answer: [
      {
        key: 82,
        class: 'list-item',
        text: 'In addition to receiving the same level of support provided in the standard subscription, premium subscribers may use chat to get expedited assistance with access to scheduled live premium sessions. Chat support is provided in English. *Note that the availability of subject matter experts for chat may be limited based on schedule or assistance of other customers.',
      },
    ],
  },
  {
    id: 15,
    icon: 'Q18',
    class: 'faq-icon',
    center: false,
    key: 76,
    question: 'Is any course content available offline?',
    answer: [
      {
        key: 18,
        class: 'list-item',
        text: 'Yes, you can generate a PDF formatted e-book for up to 10 courses (limited to 1 course per day).',
      },
    ],
  },
  {
    id: 16,
    icon: 'Q19',
    class: 'faq-icon',
    center: false,
    key: 77,
    question: 'How is lab usage calculated?',
    answer: [
      {
        key: 19,
        class: 'list-item',
        text: ' Users are limited to 400 hours of lab usage per subscription period for basic, standard, and premium subscriptions and 100 hours of lab usage per developer subscription. This limited lab time is consumed when one or more of the lab machines in a lab environment are running. When all machines are stopped, the lab environment is no longer consuming lab time. Lab consumption is measured in minutes, but displayed in hours, rounded up to the nearest whole hour. Note that aggregate lab sessions will continue to be tracked by the minute, and whole hours will be displayed in the dashboard.',
      },
    ],
  },
  {
    id: 17,
    icon: 'Q20',
    class: 'faq-icon',
    center: false,
    key: 78,
    question: 'What is expert chat used for? When is it available?',
    answer: [
      {
        key: 20,
        class: 'list-item',
        text: ' Expert chat provides standard and premium subscribers with the ability to have a one-on-one conversation with a certified expert regarding any topics covered in the course portfolio. Chat sessions can only be started in your region, beginning Monday at 8:00 am, through Friday at 5:00 pm and are limited to 30 minutes. If instructors are unavailable during the time the chat session starts or if more time is needed, an instructor office hours session can be scheduled during a mutually agreed upon date and time.',
      },
    ],
  },
  {
    id: 64,
    icon: 'Q21',
    class: 'faq-icon',
    center: false,
    key: 124,
    question:
      'What determines if a self-paced training course is marked as "complete"?',
    answer: [
      {
        key: 125,
        class: 'list-item',
        text: 'Courses are marked as complete when the user visits 75% of the total pages. When this occurs, the Dashboard will mark the course as complete, and the user will be able to download a Certificate of Attendance. In addition, the progress report will indicate "Date of Completion" as the date on which 75% completion was achieved.',
      },
    ],
  },
  {
    id: 65,
    icon: 'Q22',
    class: 'faq-icon',
    center: false,
    key: 126,
    question: 'Why do self-paced courses mark as complete when they reach 75%?',
    answer: [
      {
        key: 127,
        class: 'list-item',
        text: 'Visiting 75% of the self-paced course is determined to be a sufficient amount of progress to mark a student as "complete". Students have different goals when it comes to courseware, including certification, job role practice, and product training. Due to this, hitting every single page in the course is not required. The 75% progress milestone is deemed as being an adequate amount of progress on a course to account for different student scenarios.',
      },
    ],
  },
  {
    id: 20,
    icon: 'Q23',
    class: 'faq-icon',
    center: false,
    key: 22,
    question:
      'How can course completion certificates be generated from the subscription?',
    answer: [
      {
        key: 24,
        class: 'list-item',
        text: 'Once users complete 75% of a course, they are eligible to receive a certificate of attendance for that particular course. Within the subscription, a "Certificate of Attendance" button will appear at the top of a coompleted self-paced course. Selecting this button will direct the user to the Red Hat Learning Community to download the certificate.',
      },
      {
        key: 25,
        class: 'list-item',
        link: 'https://learn.redhat.com/',
      },
      {
        key: 26,
        class: 'list-item',
        text: 'It is within the Community where the certificate can be viewed, downloaded, and printed.',
      },
    ],
  },
  {
    id: 42,
    center: true,
    key: 53,
    title: 'CERTIFICATION EXAM INFORMATION',
  },
  {
    id: 21,
    icon: 'Q24',
    class: 'faq-icon',
    center: false,
    key: 23,
    question:
      'How many certification exams are available in the standard and premium subscriptions?',
    answer: [
      {
        key: 27,
        class: 'list-item',
        text: 'Subscribers of the standard and premium tier can take up to five unique exams. Each exam is eligible for 1 retake, in the event the student did not pass the first attempt. Certification exams are not included in the basic tier of Red Hat Learning Subscription. ',
      },
    ],
  },
  {
    id: 22,
    icon: 'Q25',
    class: 'faq-icon',
    center: false,
    key: 83,
    question:
      'How many developer exams are available in the developer subscription?',
    answer: [
      {
        key: 28,
        class: 'list-item',
        text: 'Developer subscribers get three unique developer exams. Each exam is eligible for 1 retake, in the event the student did not pass the first attempt. The full list of exams available to developer subscribers is available in the RHCEMD & RHCCD section on the bottom of the exams tab at:',
      },
      {
        key: 29,
        class: 'list-item',
        link: 'https://www.redhat.com/en/services/certification/rhca',
      },
    ],
  },
  {
    id: 23,
    icon: 'Q26',
    class: 'faq-icon',
    center: false,
    key: 84,
    question: 'How do I take certification exams?',
    answer: [
      {
        key: 30,
        class: 'list-item',
        text: 'You will have access to enroll into your certification exam through the Catalog. Simply filter by "Exam" in the "Delivery Formats" to view all of the available exams. Additionally, you can view exams that appear within Skills Paths. Once enrolled in an exam, you can then click "Schedule" which will direct you to the Individual Exam Scheduler where you will select a date and time for your Individual Exam. Many exams are also now available to be taken remotely. Look for the "Remote Available" inidcator in the Catalog. Certification exams and all retake attempts must be booked and taken within the 12-month subscription period.',
      },
    ],
  },
  {
    id: 24,
    icon: 'Q27',
    class: 'faq-icon',
    center: false,
    key: 85,
    question:
      'Are there any course completion requirements before an exam attempt?',
    answer: [
      {
        key: 31,
        class: 'list-item',
        text: 'No, you are not required to complete any amount of any specific courses to be eligible to take a certification exam.',
      },
    ],
  },
  {
    id: 25,
    icon: 'Q288',
    class: 'faq-icon',
    center: false,
    key: 86,
    question: 'What are the terms of unique exams and retakes?',
    answer: [
      {
        key: 32,
        class: 'list-item',
        text: "A standard and premium subscription provide five first attempts, and developer tier offers 3 first attempt exams. All exams are eligible for a retake in the event the student does not pass the first attempt. All available retakes can be scheduled in the Individual Exam Scheduler, accessible from the exam details page. The status of all exams can be found on the dashboard. Renewing your subscription does not extend your first year's exams, but instead it gives you a new set of first attempt exams.",
      },
    ],
  },
  {
    id: 43,
    center: true,
    key: 54,
    title: 'TRANSLATIONS AND ACCESSIBILITY',
  },
  {
    id: 26,
    icon: 'Q29',
    class: 'faq-icon',
    center: false,
    key: 87,
    question:
      'Which languages are Red Hat Learning Subscription courses offered in?',
    answer: [
      {
        key: 33,
        class: 'list-item',
        text: ' All subscription content is available in English. Red Hat’s most popular courses are available in as many as eight languages: International Spanish, Brazilian Portuguese, French, Italian, German, Simplified Chinese, Korean, and Japanese. Red Hat cannot guarantee that a class will be published in another language.',
      },
    ],
  },
  {
    id: 27,
    icon: 'Q30',
    class: 'faq-icon',
    center: false,
    key: 88,
    question: 'What languages is expert chat available in?',
    answer: [
      {
        key: 34,
        class: 'list-item',
        text: 'Language translations of the Expert Chat are currently unavailable. Chat conversations are currently only offered in English.',
      },
    ],
  },
  {
    id: 28,
    icon: 'Q31',
    class: 'faq-icon',
    center: false,
    key: 89,
    question: 'Do the courses within the subscriptions have subtitles?',
    answer: [
      {
        key: 35,
        class: 'list-item',
        text: 'All video classroom videos offer machine translated subtitles and transcripts in International Spanish, Brazilian Portuguese, French, Italian, German, Simplified Chinese, Korean, and Japanese.',
      },
    ],
  },
  {
    id: 63,
    icon: 'Q32',
    class: 'faq-icon',
    center: false,
    key: 42,
    question:
      'I am a visually impaired user. Can I still access Red Hat Training lab environments?',
    answer: [
      {
        key: 43,
        class: 'list-item',
        text: 'Yes, some of the Red Hat Training labs can be configured to work with screen readers. To check availability please submit a support request letting the team know what course(s) you require this access. If it is available, support will then assist with providing your username access to this environment.',
      },
    ],
  },
  {
    id: 44,
    center: true,
    key: 55,
    title: 'PURCHASING, PAYMENT, AND TERMS',
  },
  {
    id: 29,
    icon: 'Q33',
    class: 'faq-icon',
    center: false,
    key: 90,
    question: 'When does a subscription start to count toward days used?',
    answer: [
      {
        key: 36,
        class: 'list-item',
        text: 'At purchase time, customers can explicitly choose a date on which to start the subscription (within a 90-day window of purchase). This delayed start is noted in the order itself. If no other date is specified, the date on the order is used as the start date.',
      },
    ],
  },
  {
    id: 30,
    icon: 'Q34',
    class: 'faq-icon',
    center: false,
    key: 91,
    question:
      'When creating my subscription access, what should I avoid when creating my Red Hat account ID for login?',
    answer: [
      {
        key: 37,
        class: 'list-item',
        text: 'Non-named user accounts are not permitted for subscription use. If you choose to use corporate or company email addresses when setting up your login, make sure that it is your individual email address and not a team or general company email address, like support@company.com. Your Red Hat account login must be associated with an individual’s valid first and last name. Generic first and last names such as “Company Training1” should be avoided. If customers supply non-named user accounts, some features, like e-book generation for courses, will be degraded or disabled.',
      },
    ],
  },
  {
    id: 31,
    icon: 'Q35',
    class: 'faq-icon',
    center: false,
    key: 92,
    question: 'Is there a discount if I buy subscriptions for multiple users?',
    answer: [
      {
        key: 38,
        class: 'list-item',
        text: 'There are tiered discounts available for purchasing multiple subscriptions. For more information, visit',
      },
      {
        key: 39,
        class: 'list-item',
        link: 'https://www.redhat.com/en/services/training/specials',
      },
    ],
  },
  {
    id: 32,
    icon: 'Q36',
    class: 'faq-icon',
    center: false,
    key: 93,
    question: 'Is there an enterprise-level subscription service?',
    answer: [
      {
        key: 40,
        class: 'list-item',
        text: 'Yes, Red Hat Learning Subscription Enterprise offers a flexible, economical way to deliver the critical skills your team needs. Tailored to organizations seeking to train many users on Red Hat technologies, Red Hat Learning Subscription Enterprise offers up to 100 standard subscriptions, 100 basic subscriptions, 100 premium subscriptions, or 500 developer subscriptions for a single flat price. For more information, visit',
      },
      {
        key: 41,
        class: 'list-item',
        link: 'https://www.redhat.com/en/services/training/learning-subscription',
      },
    ],
  },
  {
    id: 33,
    icon: 'Q37',
    class: 'faq-icon',
    center: false,
    key: 94,
    question:
      'Can a Red Hat Learning Subscription be shared by multiple users?',
    answer: [
      {
        key: 45,
        class: 'list-item',
        text: 'No. A subscription is for one named user. For a user to access Red Hat Learning Subscription content, the subscription will be assigned to that user’s Red Hat Network account only.',
      },
    ],
  },

  {
    id: 34,
    icon: 'Q38',
    class: 'faq-icon',
    center: false,
    key: 95,
    question:
      'Can I reassign my named user license to someone else if an employee leaves the company?',
    answer: [
      {
        key: 46,
        class: 'list-item',
        text: 'No, the terms and conditions of Red Hat Learning Subscription are clear that subscriptions are not transferable.',
      },
    ],
  },
  {
    id: 35,
    icon: 'Q39',
    class: 'faq-icon',
    center: false,
    key: 96,
    question:
      'Can a Red Hat Certified Architect (RHCA) purchase Red Hat Learning Subscription at a discount? Are there discounts for other levels of Red Hat Certified Professionals?',
    answer: [
      {
        key: 47,
        class: 'list-item',
        text: 'Yes, a 50% discount is offered for current RHCAs. At this time, this discount is exclusive to RHCAs. We do not offer any discounts for other levels of Red Hat Certified Professionals.',
      },
    ],
  },
  {
    id: 36,
    icon: 'Q40',
    class: 'faq-icon',
    center: false,
    key: 97,
    question: 'Can I get access for more than one year?',
    answer: [
      {
        key: 48,
        class: 'list-item',
        text: 'Red Hat Learning Subscription is sold on a 12-month/1-year subscription basis. You have the option to buy consecutive subscriptions or renew your subscription to fulfill multiple-year needs.',
      },
    ],
  },
  {
    id: 37,
    icon: 'Q41',
    class: 'faq-icon',
    center: false,
    key: 98,
    question:
      'When I renew my subscription, does the next year start when the previous year ends?',
    answer: [
      {
        key: 49,
        class: 'list-item',
        text: 'By default, subscriptions begin on the date they were ordered or purchased, including renewed subscriptions. However, while ordering a subscription, users can define an explicit start date (within 90 days) on which they would like the subscription or renewal to begin. For customers renewing their subscription prior to their existing subscription expiring, we strongly suggest using this feature during their renewal purchase.',
      },
    ],
  },
  {
    id: 38,
    icon: 'Q42',
    class: 'faq-icon',
    center: false,
    key: 99,
    question: 'Does course progress get carried through on renewal?',
    answer: [
      {
        key: 50,
        class: 'list-item',
        text: 'Yes. The course progress is attached to your login ID, so if you maintain your account and renew your subscription, the progress will carry through to the next year. Any unused resources from a subscription, such as exams, lab hours, and e-book downloads, do not carry over into renewals. Instead, those consumption limits are reset so that customers can use up to the product limit included with their new subscription purchase. Progress cannot be guaranteed to be saved for expired subscriptions.',
      },
    ],
  },
  {
    id: 46,
    center: true,
    key: 56,
    title: 'CHAT AVAILABILITY',
  },
  {
    id: 47,
    icon: 'Q43',
    class: 'faq-icon',
    center: false,
    key: 100,
    question: 'When are experts available to chat?',
    answer: [
      {
        key: 57,
        class: 'list-item',
        text: 'Chat experts are available from Sunday at 10:00 pm GMT, through Saturday at 12:30 am GMT. Chat expert availability may differ in the People’s Republic of China.',
      },
    ],
  },
  {
    id: 48,
    icon: 'Q44',
    class: 'faq-icon',
    center: false,
    key: 101,
    question: 'Will a chat expert answer my request immediately?',
    answer: [
      {
        key: 58,
        class: 'list-item',
        text: 'Chat expert availability may vary depending on a high volume of other students using the chat feature. You will be updated on the approximate wait times.',
      },
    ],
  },
  {
    id: 49,
    icon: 'Q45',
    class: 'faq-icon',
    center: false,
    key: 102,
    question: 'Why don’t I see an Expert Chat feature in my subscription?',
    answer: [
      {
        key: 59,
        class: 'list-item',
        text: 'The expert chat feature is only available to Standard and Premium subscribers. Basic and Developer subscribers will only have access to the technical chat feature.',
      },
    ],
  },
  {
    id: 50,
    center: true,
    key: 60,
    title: 'CHAT SESSIONS',
  },
  {
    id: 51,
    icon: 'Q46',
    class: 'faq-icon',
    key: 61,
    center: false,
    question: 'What will experts chat with me about?',
    answer: [
      {
        key: 103,
        class: 'list-item pre-link',
        text: 'Our chat experts are available to assist you with questions related to our course content. Our experts will not be able to assist you with your questions about exam content. For technical support questions about the product, choose "Technical support" from your chat options. You may also submit a ticket through the Support Form, available by clicking "Support" at the top of the screen.',
      },
    ],
  },
  {
    id: 52,
    icon: 'Q47',
    class: 'faq-icon',
    key: 105,
    center: false,
    question: 'Can I chat with a technical support specialist?',
    answer: [
      {
        key: 106,
        class: 'list-item pre-link',
        text: 'Yes, by selecting "technical support" from your chat options, you can discuss your technical issues with one of our support team members. Depending on the severity of the issue, the support team may open a support ticket, and use it to contact you by email in the future as they resolve the issue.',
      },
    ],
  },
  {
    id: 57,
    icon: 'Q48',
    class: 'faq-icon',
    key: 115,
    center: false,
    question: 'How long can I chat with an expert?',
    answer: [
      {
        key: 116,
        class: 'list-item pre-link',
        text: 'Each chat session will be limited to no more than 30 minutes. During a session, if the time reaches 25 minutes, the chat expert will give a warning to the student that their time is ending shortly. The session will then be completed at 30 minutes. If additional time is needed, you will need to chat again the next day, or schedule an Instructor Office Hours session.',
      },
    ],
  },
  {
    id: 58,
    icon: 'Q49',
    class: 'faq-icon',
    key: 117,
    center: false,
    question: 'What are the chat experts qualifications?',
    answer: [
      {
        key: 118,
        class: 'list-item pre-link',
        text: 'Our chat experts have a variety of Red Hat certifications including RHCE, RHCSA, and RHCA. Our experts will be paired with you based on the course you are taking and the individual questions you have.',
      },
    ],
  },
  {
    id: 59,
    icon: 'Q50',
    class: 'faq-icon',
    key: 119,
    center: false,
    question: 'What happens if I don’t get my questions answered?',
    answer: [
      {
        key: 120,
        class: 'list-item pre-link',
        text: 'If your content questions cannot be answered by the chat expert, the expert has access to other experts who may be able to assist you. If so, you may be transferred to the second expert with a brief wait time. If none of the available experts can assist you with your questions, then an Instructor Office Hours session can be scheduled with the appropriate Red Hat expert.',
      },
    ],
  },
  {
    id: 56,
    icon: 'Q51',
    class: 'faq-icon',
    key: 115,
    center: false,
    question: 'How do I schedule an Instructor Office Hours session?',
    answer: [
      {
        key: 116,
        class: 'list-item pre-link',
        text: 'If chat experts are unavailable, you may schedule an Instructor Office Hours session. Sessions are scheduled by adding the details of your request to our chat. Please start a new conversation and add the following information: 1) Indicate you would like to schedule an IOH session. 2) Include the Course number and chapter you would like to discuss with our experts. 3) Identify at least 3 different dates and times you would like to try to schedule with our experts. 4) Include your timezone. Our experts will review your request and contact you by your registered email within 1 business day. Through email communication, they will work out an appropriate date and time to meet to discuss your questions about the course content.',
      },
    ],
  },
  {
    id: 53,
    center: true,
    key: 108,
    title: 'CHAT PRIVACY',
  },
  {
    id: 54,
    icon: 'Q52',
    class: 'faq-icon',
    key: 109,
    center: false,
    question: 'What data does this chat collect about me?',
    answer: [
      {
        key: 110,
        class: 'list-item pre-link',
        text: 'There is no additional personal data collected outside of what is already associated with your Red Hat Learning Subscription account. You may request to have your data sent to you, or deleted from our systems by completing the Red Hat personal data request form as provided below:',
      },
      {
        key: 111,
        class: 'list-item',
        link: 'https://www.redhat.com/en/about/personal-data-request?source=searchresultlisting',
      },
    ],
  },
  {
    id: 55,
    icon: 'Q53',
    class: 'faq-icon',
    key: 112,
    center: false,
    question: 'Are my chat logs saved?',
    answer: [
      {
        key: 113,
        class: 'list-item pre-link',
        text: 'Logs of your conversations are saved in order to assist you in future conversations related to course content. These logs can be downloaded directly from the chat window. You may request to have your logs removed from our systems by completing the Red Hat personal data request form as provided below:',
      },
      {
        key: 114,
        class: 'list-item',
        link: 'https://www.redhat.com/en/about/personal-data-request?source=searchresultlisting',
      },
    ],
  },
];

export default FAQS;
