import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-mobx-router5';
import { Alert, OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from 'services/UtilityService';
import { storesContext } from 'stores';
import BaseCard from 'components/Card';
import VTClassIcon from 'img/icons/virtual_training_small.svg';
import ILTClassIcon from 'img/icons/ilt_small.svg';
import './styles.scss';

const handleError = () => {
  // TODO: send error to backend analytics service
};

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const ClassCard = (props) => {
  const { i18n, t } = useTranslation();
  const { modality, offering } = props;
  const locale = i18n.language;
  const { catalogStore } = useContext(storesContext);

  const { vtclass, vtclass_uuid: uuid } = offering;
  const timezone = moment.tz.guess() || undefined;

  const {
    course,
    start_time: startTime,
    end_time: endTime,
    phase,
    extension_end_time: extensionEndTime,
  } = vtclass || {};
  const { code, name } = course || {};

  const translatedName =
    (code &&
      catalogStore.uniqueCatalogEntries[code]?.translations?.find(
        (element) => element.language === locale,
      )?.title) ||
    name;

  const getIcon = () => {
    return (
      <SVG
        src={modality === 'ilt' ? ILTClassIcon : VTClassIcon}
        title={t('Class')}
        width={40}
        height={40}
      />
    );
  };

  const isExtended = () => {
    return phase === 'extended' && extensionEndTime;
  };

  const TimeInfoTooltip = (
    <Tooltip id="time-info-tooltip">
      {`${t('The time is based on your time zone:')} ${timezone}`}
    </Tooltip>
  );

  const getFirstLineElement = () => {
    return (
      <React.Fragment>
        {startTime && (
          <p>
            <b>{t('Class Starts:')}</b> {`${formatDateTime(startTime, locale)}`}
            <OverlayTrigger placement="top" overlay={TimeInfoTooltip}>
              <span className="with-info-icon">
                <Glyphicon
                  glyph="info-sign"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </span>
            </OverlayTrigger>
          </p>
        )}
        {endTime && (
          <p>
            <b>{t('Class Ends:')}</b> {`${formatDateTime(endTime, locale)}`}
            <OverlayTrigger placement="top" overlay={TimeInfoTooltip}>
              <span className="with-info-icon">
                <Glyphicon
                  glyph="info-sign"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </span>
            </OverlayTrigger>
          </p>
        )}
      </React.Fragment>
    );
  };

  const getSecondLineElement = () => {
    return (
      <React.Fragment>
        {isExtended() && (
          <p>
            <b>{t('Lab Extension Ends:')}</b>{' '}
            {`${formatDateTime(extensionEndTime, locale)}`}
            <OverlayTrigger placement="top" overlay={TimeInfoTooltip}>
              <span className="with-info-icon">
                <Glyphicon
                  glyph="info-sign"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </span>
            </OverlayTrigger>
          </p>
        )}
      </React.Fragment>
    );
  };

  return (
    <BaseCard
      data-analytics-id="join-btn-vt-card-lp"
      customClassName={`${modality}-class-card`}
      cardColor="#007A87"
      svgIconElement={getIcon()}
      title={`${code.toUpperCase()} - ${translatedName}`}
      firstLineElement={getFirstLineElement()}
      secondLineElement={getSecondLineElement()}
      actionButtonsArray={[
        <Link
          key={uuidv4()}
          href
          className="class-view"
          routeName="classes:view"
          routeParams={{
            uuid,
          }}
        >
          {t('Join')}
        </Link>,
      ]}
    />
  );
};

ClassCard.propTypes = {
  offering: PropTypes.object,
  modality: PropTypes.string,
};

ClassCard.defaultProps = {
  offering: {},
  modality: 'ilt',
};

export default withErrorBoundary(
  observer(ClassCard),
  ErrorMessage,
  handleError,
);
