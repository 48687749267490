import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { FreeTierVCContext } from '../../../views/FreeTierVideoClassroom/store';
import './styles.scss';

const FreeTierVideoClassroom = () => {
  const vcStore = useContext(FreeTierVCContext);
  const { vc } = vcStore;
  return (
    <React.Fragment>
      <article className="role_lms_container rht_rhls_seminar">
        <h2>
          {vc.header?.code?.toUpperCase()}: {vc.header?.title}
        </h2>
        <section className="video-container-wrapper">
          <iframe
            id="kaltura_player_1548366954"
            src={`https://cdnapisec.kaltura.com/p/2032581/sp/203258100/embedIframeJs/uiconf_id/43595931/partner_id/2032581?iframeembed=true&playerId=kaltura_player_1548366954&flashvars[playlistAPI.kpl0Id]=${vc.playlist_id}`}
            width="640"
            height="630"
            style={{ height: '630' }}
            allowFullScreen="1"
            webkitallowfullscreen="1"
            mozAllowFullScreen="1"
            allow="autoplay *; fullscreen *; encrypted-media *"
            frameBorder="0"
            title="Course video player"
          />
        </section>
      </article>
    </React.Fragment>
  );
};

export default observer(FreeTierVideoClassroom);
