import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import VideoWidget from 'components/Video/Widget';
import ContentArea from 'components/Course/ContentArea';
import { KALTURA_CONFIGS } from 'config/constants';
import {
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
} from '@patternfly/react-core';
import BookIcon from '@patternfly/react-icons/dist/esm/icons/book-open-icon';
import PageSkeletonLoading from '../../skeletons/PageSkeletonLoading';
import CourseStore from '../store';

const ContentVersionHint = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'content-version-hint' */ 'components/ContentVersionHint'
    ),
);

const SubscriptionComparisonTable = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'subscriptions-comparison-table-component' */ 'components/Subscriptions/ComparisonTable'
    ),
);

type Props = {
  courseStore: CourseStore;
  isVideoPlayerEnabled: boolean;
};

const CourseTab = ({ courseStore, isVideoPlayerEnabled }: Props) => {
  const { t } = useTranslation();
  const {
    courseSlug,
    euuid,
    page,
    content,
    isLoadingPage,
    isCurrentPageBlockedForFreeTier,
    buildEnv,
    pageWithVideo,
    canShowVideoPlayer,
    customVideoPlayerId,
  } = courseStore;

  const { page_tag: currentPageTag } = page ?? {};

  let contentElement = null;
  if (!isCurrentPageBlockedForFreeTier) {
    if (isLoadingPage) {
      contentElement = (
        <div className="course__skeleton">
          <PageSkeletonLoading />
        </div>
      );
    } else if (!page || !content) {
      contentElement = (
        <EmptyState>
          <EmptyStateHeader
            titleText={t('Could not load page content')}
            headingLevel="h4"
            icon={<EmptyStateIcon icon={BookIcon} />}
          />
        </EmptyState>
      );
    } else {
      contentElement = (
        <div className="course__content-wrapper ">
          <ContentArea
            key={currentPageTag}
            euuid={euuid}
            page={page}
            content={content}
            language={courseStore.currentCourse?.language}
            pageInProgress={isLoadingPage}
          />
        </div>
      );
    }
  }

  return (
    <div id="tab-course-content" className="hud-course-view__tab-content">
      {isCurrentPageBlockedForFreeTier && (
        <div className="free-tier-message-wrapper">
          <p>
            This content is unavailable during your free trial. Click a section
            from Chapter 1 or Chapter 2 in the progress bar above, to return to
            your free trial course content.
          </p>
          <p>
            Please subscribe to the Red Hat Learning Subscription to receive
            access to all of the course content.
          </p>
          <SubscriptionComparisonTable />
        </div>
      )}
      {canShowVideoPlayer && isVideoPlayerEnabled && (
        <div
          id="hud-course-view__video-widget"
          className="hud-course-view__video-widget"
        >
          <VideoWidget
            entryId={pageWithVideo.entry_id}
            partnerId={KALTURA_CONFIGS.partnerId}
            playerId={customVideoPlayerId}
            isPlaylist={Boolean(pageWithVideo?.is_playlist)}
          />
        </div>
      )}
      {contentElement}
      <Suspense fallback="Loading">
        <ContentVersionHint
          courseSlug={courseSlug}
          language={courseStore.currentCourse?.language}
          buildEnv={buildEnv}
        />
      </Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseTab),
  ComponentError,
  handleError,
);
