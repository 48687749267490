import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-mobx-router5';
import { observer, useLocalStore } from 'mobx-react';
import { DropdownButton, Overlay, Tooltip } from 'react-bootstrap';
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from '@patternfly/react-core/deprecated';
import { Tooltip as PatternflyTooltip } from '@patternfly/react-core';
import shortid from 'shortid';
import { Modality } from 'types';
import VersionsDropdownStore from './store';

import './styles.scss';

interface VersionsDropdownProps {
  code: string;
  currentVersion: string;
  versions: string[];
  isLatestVersion: boolean;
  modality?: string;
  usePatternfly?: boolean;
  onChangeRoute?: (any) => void;
}

const VersionsDropdown: React.FC<VersionsDropdownProps> = (props) => {
  const { t } = useTranslation();

  const target = useRef(null);
  const container = useRef(null);
  const {
    code,
    currentVersion,
    versions,
    isLatestVersion,
    modality,
    usePatternfly = false,
    onChangeRoute, // patternfly only
  } = props;
  const store = useLocalStore(() => new VersionsDropdownStore());

  const [isOpen, setIsOpen] = React.useState(false); // patternfly only

  useEffect(() => {
    store.showTooltip = isLatestVersion === false;
  }, []);

  if (usePatternfly) {
    const onToggle = (_event: any, newIsOpen: boolean) => {
      setIsOpen(newIsOpen);

      if (newIsOpen) {
        store.hideTooltip();
      }
    };

    const onFocus = () => {
      const element = document.getElementById('course-version-toggle');
      element?.focus();
    };

    const onSelect = () => {
      setIsOpen(false);
      onFocus();
    };

    const onOptionSelect = (version: string) => {
      setIsOpen(false);
      onFocus();
      if (modality === Modality.TechOverview) {
        onChangeRoute({
          routeName: 'tech-overview:view',
          routeParams: {
            slug: `${code}-${version}`,
          },
        });
      } else {
        onChangeRoute({
          routeName: 'courses:view',
          routeParams: {
            course: `${code}-${version}`,
          },
        });
      }
    };

    const dropdownItems = versions.map((version) => (
      <DropdownItem
        key={version}
        onClick={() => onOptionSelect(version)}
        data-analytics-id={`version-option-${code}-${version}`}
        aria-label={t('Visit version {{version}}', { version })}
      >
        {version}
      </DropdownItem>
    ));

    return (
      <PatternflyTooltip
        content={<div>{t('A later version of this course is available')}</div>}
        isVisible={store.showTooltip}
        trigger="manual"
        zIndex={10}
      >
        <Dropdown
          onSelect={onSelect}
          toggle={
            <DropdownToggle
              id="course-version-toggle"
              toggleVariant="secondary"
              onToggle={onToggle}
              style={{ width: '100%' }}
              aria-label={t('Click to  open course version selector')}
            >
              {t('Version {{version}}', { version: currentVersion })}
            </DropdownToggle>
          }
          isOpen={isOpen}
          dropdownItems={dropdownItems}
        />
      </PatternflyTooltip>
    );
  }

  return (
    <div className="course-versions-btn">
      <DropdownButton
        bsStyle="info"
        onClick={store.hideTooltip}
        title={t('Version {{version}}', { version: currentVersion })}
        id="course-versions-dropdown"
        data-analytics-id="version-selector-ole-lp"
      >
        {Array.from(versions)
          .reverse()
          .map((version) => (
            <li role="presentation" key={version}>
              {modality === Modality.TechOverview ? (
                <Link
                  href
                  routeName="tech-overview:view"
                  routeParams={{
                    slug: `${code}-${version}`,
                  }}
                  data-analytics-id={`version-option-${code}-${version}`}
                >
                  {version}
                </Link>
              ) : (
                <Link
                  href
                  routeName="courses:view"
                  routeParams={{
                    course: `${code}-${version}`,
                  }}
                  data-analytics-id={`version-option-${code}-${version}`}
                >
                  {version}
                </Link>
              )}
            </li>
          ))}
      </DropdownButton>
      <Overlay
        target={target.current}
        show={store.showTooltip}
        placement="bottom"
        container={container.current}
      >
        <Tooltip
          className="in tooltip-bottom"
          placement="bottom"
          id={shortid.generate()}
        >
          {t('A later version of this course is available')}
        </Tooltip>
      </Overlay>
      <div ref={container} className="tooltip-container">
        <div ref={target} className="tooltip-target" />
      </div>
    </div>
  );
};

export default observer(VersionsDropdown);
