import { post, delete as del } from 'axios';
import {
  ARCHIVE_USER_NOTIFICATION_API,
  GET_USER_NOTIFICATION_API,
  DELETE_USER_NOTIFICATION_API,
} from '../config/constants';
import queryHelpers from '../helpers/query';

export const getUserNotification = async (userId) => {
  if (!userId) throw new Error('User id is required');

  try {
    const res = await post(`${GET_USER_NOTIFICATION_API}`, {
      query: { raw_terms: { user_uuid: userId }, terms: { deleted: false } },
    });

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const archiveUserNotification = async (notificationId) => {
  if (!notificationId) throw new Error('Notification Id is required');

  try {
    const res = await post(
      queryHelpers.parseQueryUrl(ARCHIVE_USER_NOTIFICATION_API, {
        key: '<notification_uuid>',
        value: notificationId,
      }),
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUserNotification = async (notificationId) => {
  if (!notificationId) throw new Error('Notification Id is required');

  try {
    const res = await del(
      queryHelpers.parseQueryUrl(DELETE_USER_NOTIFICATION_API, {
        key: '<notification_uuid>',
        value: notificationId,
      }),
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};
