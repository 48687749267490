import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { when, reaction } from 'mobx';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Checkbox,
} from 'react-bootstrap';
import CSVIcon from 'img/icons/csv-download.svg';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import { CenteredLoadingSpinner } from '@ole-ui/ole-ui-components';
import UserList from './UserList';
import UserMetrics from './UserMetrics';

const ManagerDashboard = ({ hideConsolidatedCSV }) => {
  const { organizationStore, eventStore, catalogStore } =
    useContext(storesContext);
  const { t } = useTranslation();
  const [isDownloadModalOpened, setIsDownloadModalOpened] = useState(false);
  const [reportFormat, setReportFormat] = useState(false);

  const csvTooltip = (
    <Tooltip id="locked-tooltip">{t('Download consolidated CSV')}</Tooltip>
  );

  const download = (format) => {
    organizationStore.generateManagerReport(format);
    eventStore.toggleDrawer('reports');
    setTimeout(() => {
      eventStore.getNotifications();
    }, 1000);
  };

  useEffect(() => {
    organizationStore.getReportees();
  }, []);

  useEffect(() => {
    // Populate catalog entry data
    when(
      () => !organizationStore.entriesFetched,
      async () => {
        await catalogStore.getAllCatalogEntries();
        organizationStore.entriesFetched = true;
      },
    );

    // Check if there are catalog entries
    reaction(
      () => organizationStore.entriesFetched,
      () => {
        // eslint-disable-next-line no-console
        console.log('Entries are fetched');

        if (catalogStore.allCatalogEntries.length) {
          organizationStore.hasCatalogData = true;
        }
      },
    );
  }, []);

  const DownloadAlert = (props) => {
    const [isChecked, setIsChecked] = React.useState(false);
    return (
      <Modal
        {...props}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {t('Attention')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              "By downloading or obtaining reports related to your organization's use of the Red Hat Enterprise Learning Subscription, you agree that you are authorized to take such action by your organization. Such content may include personal information related to your organization’s personnel and you agree that you are authorized to access such information and your organization’s access and use of such data is in compliance with all applicable laws, including but not limited to, applicable privacy and employment laws.",
            )}
          </p>
          <Checkbox
            onChange={() => {
              setIsChecked(!isChecked);
            }}
            inline
          >
            {t('I acknowledge')}
          </Checkbox>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsDownloadModalOpened(false);
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={!isChecked}
            onClick={() => {
              setIsDownloadModalOpened(false);
              download(reportFormat);
            }}
          >
            {t('Download')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <div className="manager-dashboard-wrapper">
        {organizationStore.entriesFetched ? (
          <React.Fragment>
            {!hideConsolidatedCSV && (
              <div className="download-options-wrapper">
                <OverlayTrigger
                  placement="top"
                  trigger={['hover']}
                  overlay={csvTooltip}
                >
                  <div className="svg-holder pull-right">
                    <span
                      onClick={() => {
                        setReportFormat('csv');
                        setIsDownloadModalOpened(true);
                      }}
                      role="button"
                      tabIndex="0"
                      data-analytics-id="download-team-mngmt-dboard-lp"
                    >
                      <SVG src={CSVIcon} />
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
            )}
            <UserList />
            <UserMetrics />
          </React.Fragment>
        ) : (
          <div className="admin-dashboard-wrapper__loading-state">
            <CenteredLoadingSpinner />
          </div>
        )}
      </div>
      <DownloadAlert show={isDownloadModalOpened} />
    </React.Fragment>
  );
};

ManagerDashboard.defaultProps = {
  hideConsolidatedCSV: false,
};

ManagerDashboard.propTypes = {
  hideConsolidatedCSV: PropTypes.bool,
};

export default withErrorBoundary(
  observer(ManagerDashboard),
  GenericViewError,
  handleError,
);
