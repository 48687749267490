import React, { useContext } from 'react';
import { Button, OverlayTrigger, Popover, Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';

import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

import './styles.scss';
import { labEnvironmentContext } from '../store';

const NovelloLabInfo = () => {
  const { t } = useTranslation();
  const store = useContext(labEnvironmentContext);

  /* TODO: uncomment/update this when floating IP will be exposed through API
  const classroomFQDN = store.classroomVM.externalFqdn;

  // Expose the External FQDN if the property exists and there's SSH running on the 'classroom' vm
  const suppliedServices = store.classroomVM.suppliedServices || [];

  const hasSSH = Boolean(
    suppliedServices.find(service => service.name === 'ssh'),
  );

  const exposeClassroomFQDN = classroomFQDN && hasSSH;
  */

  const { userLab } = store;

  const {
    components,
    state,
    billing_region: region,
    lab_definition_id: labDefinitionId,
    shared_offering_slugs_list: sharedOfferingSlugs,
  } = userLab;

  const fc =
    components && components.length
      ? components.find((c) => c.driver === 'openstack')
      : {};

  // eslint-disable-next-line camelcase
  const projectName = fc?.context?.project_name
    ? fc.context.project_name
    : null;
  // eslint-disable-next-line camelcase
  const projectId = fc?.context?.project_id ? fc.context.project_id : null;

  // eslint-disable-next-line camelcase
  const publicIp = fc?.context?.public_ip ? fc.context.public_ip : null;

  const regionDetails = () => {
    return components && components.length
      ? components.map((component) => {
          return (
            <p>
              <strong>
                {component.slug} {t('region')}:
              </strong>{' '}
              {component.region}
            </p>
          );
        })
      : null;
  };

  const publicIpInfo = () => {
    return publicIp ? (
      <p>
        <strong>{t('Public Ip')}:</strong> {publicIp}
      </p>
    ) : null;
  };

  const labInfo = (
    <Popover
      id="labinfo"
      title={t('Lab information')}
      className="lab-controls-info-panel"
    >
      <p>
        <strong>{t('Project id')}:</strong> {projectId || t('Not available')}
      </p>
      <p>
        <strong>{t('Project name')}:</strong>{' '}
        {projectName || t('Not available')}
      </p>
      <p>
        <strong>{t('Project state')}:</strong> {state}
      </p>
      <p>
        <strong>{t('Lab definition id')}:</strong> {labDefinitionId}
      </p>
      {/*
      TODO: uncomment this when floating IP work is available
      {exposeClassroomFQDN ? (
        <p>
          <strong>{t('External FQDN')}:</strong> {classroomFQDN}
        </p>
      ) : null}
      */}
      {sharedOfferingSlugs ? (
        <p>
          <strong>{t('Shared offering slugs')}:</strong>{' '}
          {sharedOfferingSlugs.join(', ')}
        </p>
      ) : null}
      <p>
        <strong>{t('Published region')}:</strong> {region}
      </p>
      {regionDetails()}
    </Popover>
  );

  return (
    <div className="lab-controls-info">
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={labInfo}
        rootClose
      >
        <Button
          bsStyle="link"
          className="lab-controls-info-btn"
          data-analytics-id="info-icon-vt-dash-lp"
        >
          <Glyphicon glyph="info-sign" />
        </Button>
      </OverlayTrigger>
      {publicIpInfo()}
    </div>
  );
};

export default withErrorBoundary(
  observer(NovelloLabInfo),
  ComponentError,
  handleError,
);
