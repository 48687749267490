import { observable, action, computed } from 'mobx';
import ContentAreaStore from '../../store';
import { getCoursePage } from '../../../../../services/CourseService';

class MatchingStore {
  @observable matchedArray = [];

  @observable draggablesArray = [];

  @observable reset = false;

  @observable currentLanguage = 'en-US';

  @observable showSolution = false;

  @observable slug = '';

  @observable chapter = '';

  @observable setDragItem = {
    dragged: '',
    draggedItems: [],
  };

  @action storeMatchedLabel(label) {
    if (!this.matchedArray.includes(label)) {
      this.matchedArray.push(label);

      const _exists = this.draggablesArray.find((d) => {
        return label === d.label;
      });

      if (!_exists) {
        this.draggablesArray.push({
          label,
          displayClassName: 'show',
          droppedOn: '',
        });
      }
    }
  }

  @computed get setdraggablesArray() {
    return this.draggablesArray || [];
  }

  set setdraggablesArray(item) {
    this.draggablesArray = item;
  }

  @action clearDraggables() {
    this.draggablesArray = [];
  }

  @action resetQuizData(slug, chapter) {
    this.setDragItem.dragged = '';
    this.setLabelData(slug, chapter);

    if (this.setDragItem.draggedItems.length) {
      this.removeItem();
    }
  }

  @action setLabelData(slug, chapter) {
    getCoursePage(slug, chapter, this.currentLanguage).then((result) => {
      this.matchedArray = [];
      ContentAreaStore.courseContent = result;
    });
  }

  @action checkData() {
    this.setDragItem.dragged = '';
    const array = this.setDragItem.draggedItems;
    if (array.length) {
      array.forEach((element) => {
        const childNodes = element.children;

        Object.values(childNodes).forEach((child) => {
          if (child.textContent !== element.className) {
            child.classList.add('incorrect');
          } else {
            child.classList.add('correct');
          }
        });
      });
    }
  }

  @action removeItem() {
    const array = this.setDragItem.draggedItems;
    array.forEach((element) => {
      element.querySelectorAll('*').forEach((n) => n.remove());
    });
    this.setDragItem.draggedItems = [];
  }
}

export default MatchingStore;
