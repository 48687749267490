import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const SeminarCollateral = (props) => {
  const { collateral } = props;
  const { t } = useTranslation();

  /* eslint-disable camelcase */
  return (
    <React.Fragment>
      {collateral.description && (
        <div className="course-collateral-description">
          {collateral?.description}
        </div>
      )}
      {collateral.who_benefits && (
        <div className="course-collateral-audience">
          <h3>{t('Who benefits')}</h3> {collateral?.who_benefits}
        </div>
      )}
      {!collateral.presenter_info && collateral.presenter && (
        <div className="course-collateral-prerequisites">
          <h3>{t('Presenter')}</h3>
          {collateral?.presenter}
        </div>
      )}
      {collateral.presenter_info && (
        <div className="course-collateral-prerequisites">
          <h3>{t('Presenter')}</h3>
          {`${collateral?.presenter_info?.name}, ${collateral?.presenter_info?.description}`}
        </div>
      )}
    </React.Fragment>
  );
};
/* eslint-enable camelcase */

SeminarCollateral.propTypes = {
  collateral: PropTypes.object.isRequired,
};

export default SeminarCollateral;
