import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { CenteredLoadingSpinner } from '@ole-ui/ole-ui-components';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../Error/GenericViewError';

import AddManager from './AddManager';
import './styles.scss';
import AdminManagerList from '../AdminManagerList';
import AdminUserList from '../AdminUserList';
import CSVIcon from '../../../img/icons/csv-download.svg';
import { storesContext } from '../../../stores';

const AdminDashboard = () => {
  const { organizationStore, eventStore } = useContext(storesContext);
  const { t } = useTranslation();

  const csvTooltip = (
    <Tooltip id="locked-tooltip">{t('Download consolidated CSV')}</Tooltip>
  );

  const download = (format) => {
    organizationStore.generateAdminReport(format);
    eventStore.toggleDrawer('reports');
    setTimeout(() => {
      eventStore.getNotifications();
    }, 1000);
  };

  useEffect(() => {
    organizationStore.getManagers();
    organizationStore.getUsers();
  }, [organizationStore.organization]);

  if (organizationStore.isLoadingManagers || organizationStore.isLoadingUsers) {
    return (
      <div className="admin-dashboard-wrapper__loading-state">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="download-options-wrapper">
        <OverlayTrigger
          placement="top"
          trigger={['hover']}
          overlay={csvTooltip}
        >
          <div className="svg-holder pull-right">
            <span
              onClick={() => download('csv')}
              role="button"
              tabIndex="0"
              data-analytics-id="download-org-mngmt-dboard-lp"
            >
              <SVG src={CSVIcon} />
            </span>
          </div>
        </OverlayTrigger>
      </div>
      <div className="admin-dashboard-wrapper">
        <p className="dashboard-list-title">{t('Managers')}</p>
        <AdminManagerList />
        <p className="add-manager-label">{t('Add Manager')}</p>
        <AddManager />
        <p className="dashboard-list-title">{t('Users')}</p>
        <AdminUserList />
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(AdminDashboard),
  GenericViewError,
  handleError,
);
