import React from 'react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import SVG from 'react-inlinesvg';

import CourseIcon from 'img/icons/course_small.svg';
import LockIcon from 'img/icons/lock.svg';
import EarlyAccessIcon from 'img/icons/eaicon.svg';
import LabPlusIcon from 'img/icons/lpicon.svg';
import LessonIcon from 'img/icons/lessonicon.svg';
import CatalogStore from 'stores/Catalog';

import './styles.scss';
import { CourseType, OfferingVisibility } from 'types';

type Props = {
  offeringVisibility?: OfferingVisibility;
  offeringCode?: string;
  offeringType?: CourseType;
  size?: number;
};

const OfferingIcon = ({
  offeringCode,
  offeringType,
  offeringVisibility,
  size = 40,
}: Props) => {
  const { t } = useTranslation();

  const getCourseIcon = () => {
    if (CatalogStore.isLocked(offeringVisibility)) {
      return { icon: LockIcon, text: t('Content is Locked') };
    }

    if (CatalogStore.isEarlyAccess(offeringCode)) {
      return { icon: EarlyAccessIcon, text: t('Early Access') };
    }

    if (CatalogStore.isLesson(offeringType)) {
      return { icon: LessonIcon, text: t('Lesson') };
    }

    if (CatalogStore.isLabPlus(offeringType)) {
      return { icon: LabPlusIcon, text: t('Lab Plus') };
    }

    return { icon: CourseIcon, text: t('Course') };
  };
  const iconImage = getCourseIcon();

  return (
    <SVG
      src={iconImage.icon}
      title={iconImage.text}
      width={size}
      height={size}
    />
  );
};

export default withErrorBoundary(
  React.memo(OfferingIcon),
  ComponentError,
  handleError,
);
