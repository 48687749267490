import { get } from 'axios';
import {
  SERVER_CONFIG_API,
  FEATURE_SERVER_CONFIG_API,
} from '../config/constants';

/**
 * Returns user avatar as image source
 * @param {string} uuid - User UUID
 * @return {string} A string containing image data
 */
export const getServerConfig = async () => {
  try {
    const config = await get(SERVER_CONFIG_API);
    return config?.data?.config;
  } catch (error) {
    throw new Error(error);
  }
};

export const getFeaturesBySection = async (section) => {
  const { data } = await get(`${FEATURE_SERVER_CONFIG_API}${section}/section`);
  return data;
};

export const getAllFeatures = async () => {
  const { data } = await get(`${FEATURE_SERVER_CONFIG_API}`);
  return data;
};

export default getServerConfig;
