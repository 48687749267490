import { get, post } from 'axios';

import { LAB_USED_COURSE } from '../config/constants';

export const getLabUsedHoursGroupByCourse = () =>
  new Promise((resolve, reject) => {
    get(`${LAB_USED_COURSE}`)
      .then((response) => {
        resolve(response && response.data);
      })
      .catch((error) => reject(error));
  });

export const getReporteesLabHours = async (users) => {
  try {
    const request = { users };
    const response = await post(`${LAB_USED_COURSE}me/users/`, request);
    return response.data.items;
  } catch (err) {
    throw err;
  }
};
