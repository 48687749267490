import React, { useContext, useEffect, useState, Suspense } from 'react';
import { Tabs, Tab, TabTitleText } from '@patternfly/react-core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { CenteredLoadingSpinner } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import ViewLoaderV2 from 'components/Loading/ViewLoaderV2';
import { storesContext } from 'stores';

import './styles.scss';

const loadReportsComponent = () =>
  import(/* webpackChunkName: 'user-reports' */ 'components/Reports');

const ReportsComponent = React.lazy(loadReportsComponent);

const AdminReportsComponent = React.lazy(() =>
  import(/* webpackChunkName: 'admin-reports' */ 'components/AdminReports'),
);

const Reports = () => {
  const { t } = useTranslation();
  const { uiStore, routerStore, userStore } = useContext(storesContext);
  const [isReportsLoaded, setReportsLoaded] = useState(false);

  const { isReportingUser, didFetchGroups } = userStore;

  useEffect(() => {
    const { title } = routerStore?.route?.params;
    uiStore.title = title;
  }, []);

  if (!didFetchGroups) {
    return (
      <div className="reports__loading">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  if (isReportingUser) {
    return (
      <Tabs
        aria-label={t('Reporting tools')}
        role="region"
        isBox
        className="reports__tabs"
        defaultActiveKey={0}
      >
        <Tab
          eventKey={0}
          title={<TabTitleText>{t('Report scheduling tool')}</TabTitleText>}
          aria-label="Default content - users"
        >
          <Suspense fallback={<ViewLoaderV2 />}>
            <AdminReportsComponent />
          </Suspense>
        </Tab>
        <Tab
          eventKey={1}
          title={<TabTitleText>{t('Legacy reporting tool')}</TabTitleText>}
          onMouseEnter={() => {
            setReportsLoaded(true);
          }}
        >
          <div className="container-grid-responsive reports__old-reports">
            <Row>
              <Col md={12}>
                <Suspense fallback={<ViewLoaderV2 />}>
                  {isReportsLoaded && <ReportsComponent />}
                </Suspense>
              </Col>
            </Row>
          </div>
        </Tab>
      </Tabs>
    );
  }

  return (
    <div className="container-grid-responsive">
      <PageHeader>{t(uiStore.title)}</PageHeader>
      <Row>
        <Col md={12}>
          <Suspense fallback={<ViewLoaderV2 />}>
            <ReportsComponent />
          </Suspense>
        </Col>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(Reports),
  GenericViewError,
  handleError,
);
