import { observable, action } from 'mobx';
import { getExpertSeminar } from '../../services/ExpertSeminarService';

class ExpertSeminarStore {
  @observable seminar = {};

  @observable seminarFetched = false;

  @action getExpertSeminar = async (slug) => {
    try {
      const seminar = await getExpertSeminar(slug);
      this.seminar = seminar;
    } finally {
      this.seminarFetched = true;
    }
  };
}

export default ExpertSeminarStore;
