import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { PageHeader } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';
import Store from './store';

const NotificationList = React.lazy(() =>
  import(
    /* webpackChunkName: 'notifications-list' */ '../../../components/Notifications/List'
  ),
);

@withTranslation()
@inject('routerStore', 'uiStore')
@observer
class NotificationListView extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
    t: PropTypes.func.isRequired,
    uiStore: MobXPropTypes.observableObject.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore } = this.props;
    const { title } = routerStore?.route?.params;

    this.store = new Store();
    this.title = title;
    document.title = title;
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.store.getNotifications();
  };

  render() {
    const { t, uiStore } = this.props;
    const { notifications } = this.store;

    const { title } = uiStore;
    document.title = t(title);

    return (
      <React.Suspense fallback={<ViewLoader />}>
        <div className="container-grid-responsive">
          <PageHeader>{this.title}</PageHeader>
          <NotificationList notifications={notifications} />
        </div>
      </React.Suspense>
    );
  }
}

export default withErrorBoundary(
  NotificationListView,
  GenericViewError,
  handleError,
);
