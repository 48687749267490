import { action, observable } from 'mobx';
import { getNotifications } from '../../../services/NotificationService';

class NotificationsStore {
  @observable notifications = [];

  @action async getNotifications() {
    this.notifications = await getNotifications();
    return this.notifications;
  }
}

export default NotificationsStore;
