import { action, computed, observable } from 'mobx';
import _ from 'lodash';
import { getServerConfig } from '../services/ConfigService';
import { BASE, SSO_LOGIN_API, HOSTNAME } from '../config/constants';

class ConfigStore {
  @observable config = {};

  @observable didFetchConfig = false;

  @observable loginPage = 'login';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getLoginPage() {
    const loginMatch = this.config?.LOGIN_METHOD?.find?.(
      (o) => o.hostname === HOSTNAME && o.method === 'redirect',
    );
    this.loginPage = loginMatch?.params?.page || 'login';
  }

  @action getServerConfig = async (force) => {
    if (Object.keys(this.config).length && !force) {
      return this.config;
    }

    try {
      const config = await getServerConfig();

      if (config) {
        this.config = config;
        this.didFetchConfig = true;
        this.getLoginPage();
      }
    } catch (error) {
      console.error(error);
    }

    return this.config;
  };

  @computed get currentDeployment() {
    // return a value from configuration, or assume it's prod
    return this.config.OLE_APP_DEPLOYMENT || 'rht';
  }

  @computed get rezodePlatform() {
    // return a value from configuration, or assume rht platfrom
    return this.config.REZODE_PLATFORM || 'rht';
  }

  @computed get rhtDeployment() {
    // return a value from configuration, or assume rht platfrom
    return this.config.RHT_DEPLOYMENT || 'devel';
  }

  @computed get isChina() {
    return this.rezodePlatform === 'china';
  }

  @computed get shouldSkipSentry() {
    return this.rezodePlatform === 'china';
  }

  @computed get skipRhlcForCoa() {
    return this.isChina;
  }

  @computed get UISettings() {
    const settings = this.config.UI_SETTINGS || {};
    const hostNameSettings =
      settings.by_hostname?.find((o) => o?.hostname === HOSTNAME) || {};

    return { ...settings, ...hostNameSettings };
  }

  @computed get showNIITFooter() {
    return this.isChina && this.UISettings.niit_footer_enabled;
  }

  @computed get ssoLoginEndpoint() {
    // return a value from configuration, or returns the default auth idp endpoint.
    const ssoLoginEndPoint =
      this.config[HOSTNAME] || this.config.OLE_SSO_LOGIN_ENDPOINT;
    return ssoLoginEndPoint ? `${BASE}${ssoLoginEndPoint}` : SSO_LOGIN_API;
  }

  // unique appid provided by Pendo to initialize Pendo SDK
  @computed get pendoAppId() {
    // return a value from configuration, or returns the default appid.
    return this.config.PENDO_APP_ID || '';
  }

  // flag to indicate whether pendo should start tracking all users or not.
  @computed get pendoTrackALLUsers() {
    return this.config.PENDO_TRACK_ALL_USERS;
  }

  // flag to indicate whether pendo should start tracking all users or not.
  @computed get pendoUsersToTrack() {
    const _pendoUsersToTrack = this.config.PENDO_USERS || [];

    return _pendoUsersToTrack;
  }

  // URL to redirect unauthorized user
  @computed get unauthorizedRedirectUrl() {
    return this.config.UNAUTHORIZED_REDIRECT_URL || '';
  }

  // Ebooks platform's URL.
  @computed get ebooksPlatformURL() {
    return this.config.EBOOKS_PLATFORM_URL;
  }

  @computed get contactOperationURL() {
    return this.config.CONTACT_OPERATION_URL || '';
  }

  @computed get learningSubscriptionURL() {
    return this.config.LEARNING_SUBSCRIPTION_URL || '';
  }

  @computed get isFreeTrialRegistrationEnabled() {
    const isEnabledLandingMat = this.loginPage === 'landing-mat';

    return Boolean(this.config.FREE_TRIAL_REGISTRATION && isEnabledLandingMat);
  }

  @computed get isFreeTrialRestartEnabled() {
    const isEnabledLandingMat = this.loginPage === 'landing-mat';
    return Boolean(this.config.FREE_TRIAL_RESTART && isEnabledLandingMat);
  }

  @computed get captchaAPIKEY() {
    return this.config.CAPTCHA_API_KEY;
  }

  @computed get pendoGuides() {
    return this.config.PENDO_GUIDES || {};
  }

  @computed get staticBaseURL() {
    return _.get(
      this.config,
      'STATIC_BASE_URL',
      'https://static.ole.redhat.com/rhls/',
    );
  }
}

export default ConfigStore;
