import { get, post } from 'axios';
import queryString from 'query-string';
import { asyncParseCSVFileSingleLine } from './UtilityService';

import {
  COURSE_COMPLETION_REPORTING_API_URL,
  COURSE_ACTIVITY_REPORTING_API_URL,
  LAB_ACTIVITY_REPORTING_API_URL,
  EXAM_ACTIVITY_REPORTING_API_URL,
  ADMIN_REPORTING_API_BASE,
  REPORT_MANAGEMENT_API_BASE,
  USER_SUBSCRIPTION_API_BASE,
} from '../config/constants';

const request = (url, params) => {
  const requestUrl = `${url}?q=${queryString.stringify(params)}`;

  return get(requestUrl).then(({ data }) => {
    const result = {
      data: data?.new_data, // eslint-disable-line
      pages: data?.total_pages, // eslint-disable-line
    };
    return result;
  });
};

export const getCourseCompletionReport = (searchId, page, count) => {
  const params = {
    searchId,
    count,
    page,
  };

  return request(COURSE_COMPLETION_REPORTING_API_URL, params);
};

export const getExamActivityReport = (searchId, page, count) => {
  const params = {
    searchId,
    count,
    page,
  };

  return request(EXAM_ACTIVITY_REPORTING_API_URL, params);
};

export const getCourseActivityReport = (searchId, page, count) => {
  const params = {
    searchId,
    count,
    page,
  };

  return request(COURSE_ACTIVITY_REPORTING_API_URL, params);
};

export const getLabActivityReport = (searchId, page, count) => {
  const params = {
    searchId,
    count,
    page,
  };

  return request(LAB_ACTIVITY_REPORTING_API_URL, params);
};

export const getAdminReport = (type, data) => {
  return post(`${ADMIN_REPORTING_API_BASE}${type}/`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const generateCSV = (report, filename) => {
  // a trick to download a named file is to create an invisible <a> element
  const reportBlob = new Blob([report], { type: 'text/csv' });
  const url = window.URL.createObjectURL(reportBlob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const parseCSVFiles = async (files) => {
  let objects = [];
  const parsedObjects = await Promise.all(
    files.map(asyncParseCSVFileSingleLine),
  );

  // Promise.all returns values of all promises in an array,
  // so to get the names we need to go through each item.
  parsedObjects.forEach((newObjects) => {
    objects = [...objects, ...newObjects];
  });
  return objects;
};

export const getSavedFilters = async () => {
  try {
    const response = await get(`${REPORT_MANAGEMENT_API_BASE}manage`).then(
      (json) => {
        return json.data.map((filter) => {
          return {
            id: filter.id,
            users: filter.user_list,
            regions: filter.regions,
            startDate: filter.startDate,
            endDate: filter.endDate,
            ...(filter.subscription_list && {
              subscriptions: filter.subscription_list,
            }),
            ...(filter.lms_order_number_list && {
              lms_order_number_list: filter.lms_order_number_list,
            }),
            ...(filter.report_type && {
              reportType: filter.report_type,
            }),
          };
        });
      },
    );

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const removeSavedFilter = async (id) => {
  try {
    const response = await post(`${REPORT_MANAGEMENT_API_BASE}manage`, {
      action: 'delete',
      target: id,
    });

    return response.status === 200;
  } catch (error) {
    throw new Error(error);
  }
};

export const createReportSearch = async (body) => {
  try {
    const response = await post(`${REPORT_MANAGEMENT_API_BASE}`, body);
    return response.data.searchId;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAccountName = (search) => {
  return get(`${USER_SUBSCRIPTION_API_BASE}${search}/account_names/`)
    .then((json) => json?.data?.items || [])
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAccountNameUsers = (orgId) => {
  return get(`${USER_SUBSCRIPTION_API_BASE}${orgId}/account_users/`)
    .then((json) => json?.data || [])
    .catch((err) => {
      throw new Error(err);
    });
};

export const getLMSOrderNumbers = async (search, args) => {
  // LMS order number is a 6 digit number
  // for now ignore everything shorter than that
  if (search.length < 6) {
    return [];
  }
  const params = {
    lms_order_number: search,
    organizationId: args?.organizationId,
  };

  return get(`${USER_SUBSCRIPTION_API_BASE}get_lms_numbers`, {
    params,
  })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
};
