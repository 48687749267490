import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Form from 'react-jsonschema-form';
import './styles.scss';

function JSONSchemaForm(props) {
  const { t } = useTranslation();
  return (
    <Form {...props}>
      <Button bsStyle="info" bsSize="large" type="submit">
        {t('Submit')}
      </Button>
    </Form>
  );
}

JSONSchemaForm.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default JSONSchemaForm;
