import { orderBy, values as lodashValues } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getTopItems = (items, limit = 5, propertyName = 'value') => {
  if (!items) return items;
  return orderBy(lodashValues(items), propertyName, 'desc').slice(0, limit);
};

export const pieChartcolorScale = [
  'rgb(255, 0, 4)',
  'rgb(255, 199, 0)',
  'rgb(35, 95, 255)',
  'rgb(255, 120, 0)',
  'rgb(0, 196, 76)',
];
