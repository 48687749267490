import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';

import { Table } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import CourseProgressTableStore from './store';
import './styles.scss';
import UserMetricsEmptyState from '../EmptyState';

const CourseProgressTable = (props) => {
  const { courseType } = props;
  const { t } = useTranslation();
  const { progressStore, organizationStore, catalogStore, uiStore } =
    useContext(storesContext);
  const store = useLocalStore(
    () =>
      new CourseProgressTableStore(
        progressStore,
        organizationStore,
        catalogStore,
      ),
  );

  useEffect(() => {
    store.getReporteesProgress(organizationStore.organization.id, courseType);
  }, [organizationStore.organization.id]);

  useEffect(() => {
    store.courseSKUTranslation = t('Course SKU: ');
    store.versionTranslation = t(', Version: ');
    store.userCourseTranslation = t('User/Course ');
  }, [uiStore.currentLanguage]);

  const renderEmptyState = () => {
    return (
      <UserMetricsEmptyState
        selectedCount={organizationStore.selectedUsersByManagerList?.length}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="table-horiz-scroll mgmt-course-progress-table">
        <Table
          ariaLabel={t('Course Progress table')}
          data={store.transformedData}
          columns={store.tableColumns}
          variant="compact"
          renderEmptyState={renderEmptyState}
        />
      </div>
    </React.Fragment>
  );
};

CourseProgressTable.propTypes = {
  courseType: PropTypes.string,
};

CourseProgressTable.defaultProps = {
  courseType: null,
};

export default withErrorBoundary(
  observer(CourseProgressTable),
  GenericViewError,
  handleError,
);
