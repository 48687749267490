import { observable } from 'mobx';
import { post } from 'axios';

import { RHZ_API_BASE } from '../../config/constants';

class LabPollingStore {
  @observable application = {};

  @observable userLab = {};

  @observable api = '';

  @observable intervalId = undefined;

  @observable interval = 20000;

  @observable euuid = undefined;

  @observable subscriptionPollingInterval = 300000; // 5 minutes

  @observable labUsageIntervalId = undefined;

  determineAPI(mode) {
    switch (mode) {
      case 'rol':
        this.api = `${RHZ_API_BASE}ravello_lab`;
        break;
      case 'vt':
        this.api = `${RHZ_API_BASE}es/vt/${this.euuid}/api`;
        break;
      default:
        this.api = `${RHZ_API_BASE}ravello_lab`;
        break;
    }
  }

  post(data) {
    return new Promise((resolve, reject) => {
      post(this.api, JSON.stringify(data))
        .then((response) => {
          resolve(response && response.data && response.data.result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default LabPollingStore;
