import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  DropdownButton,
  MenuItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-mobx-router5';
import SVG from 'react-inlinesvg';
import BaseCard from 'components/Card';
import { getOfferingTitleByLanguage } from 'services/UtilityService';
import ExpertIcon from 'img/icons/techoverview_small.svg';
import LockIcon from 'img/icons/lock.svg';
import { handleError } from 'services/ErrorService';
import TechOverviewCollateral from './TechOverviewCollateral';
import TechOverviewStore from './store';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const TechOverviewCard = ({
  offering,
  canAccess,
  actionButtonsArray,
  disableActionButtons,
  allowOrderingOffering,
  onOfferingOrderChange,
  progress,
  displayVersionDropdown,
  versions,
  onVersionChange,
}) => {
  const { code, version, slug, translations } = offering;

  const { i18n, t } = useTranslation();

  const store = useLocalStore(() => new TechOverviewStore());

  const lockedTooltip = (
    <Tooltip id="locked-tooltip">
      {t('This content is not available as part of your subscription')}
    </Tooltip>
  );

  const getIcon = () => {
    return canAccess ? (
      <SVG src={ExpertIcon} title={t('Tech Overview')} width={40} height={40} />
    ) : (
      <OverlayTrigger
        placement="bottom"
        trigger={['hover']}
        overlay={lockedTooltip}
      >
        <div className="content-locked">
          <SVG
            src={LockIcon}
            title={t('Content is locked')}
            width={40}
            height={40}
            style={{ pointerEvents: 'auto' }}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const getTitle = () => {
    const title = getOfferingTitleByLanguage(
      offering,
      getTranslation(),
      i18n.language,
    );

    return title;
  };

  const getColorCard = () => {
    return '#73804E';
  };

  const getActions = () => {
    let actionsToReturn = [];
    if (canAccess) {
      if (actionButtonsArray?.length > 0) {
        actionsToReturn = actionButtonsArray;
        return actionsToReturn;
      }

      actionsToReturn.push(
        <Link
          key={uuidv4()}
          className={`course-view ${
            disableActionButtons && 'course-actions-disabled'
          }`}
          href={`/technical-overview/${slug}`}
          {...(disableActionButtons && { disabled: true })}
          routeName="tech-overview:view"
          routeParams={{
            slug,
          }}
          data-analytics-id="view-btn-expert-card-lp"
        >
          {t('View').toUpperCase()}
        </Link>,
      );
    }

    return actionsToReturn;
  };

  const orderedVersions = Array.from(versions).sort((a, b) => {
    return parseFloat(b) - parseFloat(a);
  });

  const dropdownVersionElement = () => {
    return (
      displayVersionDropdown && (
        <DropdownButton
          id="progress-version-selector"
          bsStyle="default"
          title={`v.${version}`}
          onClick={(e) => e.stopPropagation()}
          key={uuidv4()}
          data-analytics-id={`version-drpdown-tech-overview-card-lp-${code}-${version}`}
        >
          {orderedVersions.map((v) => (
            <MenuItem
              key={uuidv4()}
              value={v}
              active={v === version}
              onClick={(e) => {
                e.stopPropagation();
                onVersionChange(v);
              }}
            >
              {`v.${v}`}
            </MenuItem>
          ))}
        </DropdownButton>
      )
    );
  };

  return (
    <BaseCard
      customClassName="tech-overview-card"
      title={`${code.toUpperCase()} - ${getTitle()}`}
      cardColor={getColorCard()}
      iconClassName="tech-overview"
      svgIconElement={getIcon()}
      actionButtonsArray={getActions()}
      actionToggle={() => store.getTechOverviewCollateral(slug, i18n.language)}
      allowOrderingOffering={allowOrderingOffering}
      onOfferingOrderChange={onOfferingOrderChange}
      progress={progress}
      indicatorElementsArray={[dropdownVersionElement()]}
    >
      <div className="tech-overview">
        <React.Fragment>
          <TechOverviewCollateral collateral={store.collateral} />
        </React.Fragment>
      </div>
    </BaseCard>
  );
};

TechOverviewCard.propTypes = {
  offering: PropTypes.object,
  canAccess: PropTypes.bool,
  actionButtonsArray: PropTypes.array,
  disableActionButtons: PropTypes.bool,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
  progress: PropTypes.number,
  displayVersionDropdown: PropTypes.bool,
  versions: PropTypes.array,
  onVersionChange: PropTypes.func,
};

TechOverviewCard.defaultProps = {
  offering: {},
  canAccess: 'false',
  actionButtonsArray: [],
  disableActionButtons: false,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
  progress: 0,
  displayVersionDropdown: false,
  versions: [],
  onVersionChange: () => {},
};

export default withErrorBoundary(
  observer(TechOverviewCard),
  ErrorMessage,
  handleError,
);
