import React from 'react';

const ChineseFooter = () => {
  return (
    <div className="chinese-footer">
      <a
        href="http://www.beian.miit.gov.cn"
        target="_blank"
        rel="noopener noreferrer"
      >
        沪ICP备2021022465号-1
      </a>
      <a
        href="https://training-china-v2-1303390700.cos.ap-shanghai.myqcloud.com/vats/vats.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        增值电信业务经营许可证 沪B2-20211412
      </a>
      <a
        href="https://training-china-v2-1303390700.cos.ap-shanghai.myqcloud.com/vats/yyzz.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        营业执照
      </a>
    </div>
  );
};

export default ChineseFooter;
