import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import shortid from 'shortid';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Col,
  Glyphicon,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Tooltip,
  Row,
  Table,
} from 'react-bootstrap';
import { storesContext } from '../../../stores';
import { FREE_TRIAL_UPGRADE_PAGE } from '../../../config/constants';
import './styles.scss';

// eslint-disable-next-line react/prop-types
const ComparisonTable = ({ tableOnly = false }) => {
  const { t } = useTranslation();
  const { userStore, configStore } = useContext(storesContext);

  const { partnerId, partnerUrl, isFreeTierSubscriber } = userStore;
  const { isChina } = configStore;

  const colSm = isChina ? 6 : 4;

  const PartnerLink = () =>
    isFreeTierSubscriber ? (
      <h3>
        {t(`Want to continue learning? Upgrade to one of these tiers or`)}
        <a target="_blank" rel="noopener noreferrer" href={partnerUrl}>
          {' '}
          {t(`contact us`)}.
        </a>
      </h3>
    ) : null;

  const listGroupItem = (text = '', tooltip) => (
    <ListGroupItem className="list-group-item">
      <span
        className="list-group-item-text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <span className="list-group-item-icon">
        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={shortid.generate()}>{tooltip}</Tooltip>}
          >
            <Glyphicon glyph="question-sign" />
          </OverlayTrigger>
        )}
      </span>
    </ListGroupItem>
  );

  const BasicList = () => (
    <ListGroup>
      {listGroupItem(
        t('Hands-on labs'),
        t('Availability of 400 hours of cloud-based labs'),
      )}
      {listGroupItem(
        t('Instructor videos'),
        t(
          'Access to more than 1,000 instructor videos and high-definition training',
        ),
      )}
      {listGroupItem(
        t('Early access'),
        t(
          'Advance screening of in-development course chapters and lab environments',
        ),
      )}
      {listGroupItem(
        t('Course e-books'),
        t('Download functionality for offline use of up to 10 course e-books'),
      )}
    </ListGroup>
  );

  const StandardList = () => (
    <ListGroup>
      {listGroupItem(t('Everything in <strong>Basic</strong>, plus:'))}
      {listGroupItem(
        t('Certification exams'),
        t('Up to five attempts and two retakes on certifications'),
      )}
      {listGroupItem(
        t('Expert chat'),
        t('Live, online coaching from Red Hat instructors on all courses'),
      )}
      {listGroupItem(
        t('Expert extras'),
        t('On-demand video seminars from Red Hat instructors'),
      )}
    </ListGroup>
  );

  const PremiumList = () => (
    <React.Fragment>
      <ListGroup>
        {listGroupItem(t('Everything in <strong>Standard</strong>, plus:'))}
        {listGroupItem(
          t('Live, virtual sessions'),
          t(
            'Robust schedule of interactive, instructor-led sessions for the self-paced courses',
          ),
        )}
        <div className="list-group-item-placeholder">
          <span>&nbsp;</span>
        </div>
        <div className="list-group-item-placeholder">
          <span>&nbsp;</span>
        </div>
      </ListGroup>
      <div className="filler" />
    </React.Fragment>
  );

  const LearnMoreBtn = () => {
    if (partnerId) {
      return null;
    }

    return (
      <div className="learn-more">
        <Button
          bsStyle="primary"
          className="learn-more-btn"
          href={FREE_TRIAL_UPGRADE_PAGE}
          target="_blank"
          data-analytics-id="learn-more-free-trial-btn-lp"
        >
          {t('Learn More')}
        </Button>
      </div>
    );
  };

  return (
    <Row className="subscription-comparison-table">
      {!tableOnly && (
        <Col xs={12} className="subscription-comparison-table-heading">
          <h1>{t('Welcome to your free trial')}</h1>
          <p>
            {t(
              `Get started learning with access to the first 2 chapters of each course and 4-hours of cloud-based lab time. Check out the Early Access tab to preview courses currently in development or select the Catalog tab to explore the full catalog.`,
            )}
          </p>
          <br />
          <PartnerLink />
        </Col>
      )}
      <Col sm={colSm} xs={12} className="tier1">
        <Table>
          <thead>
            <tr>
              <th>{t('Basic')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <BasicList />
                <LearnMoreBtn />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col sm={colSm} xs={12} className="tier2">
        <Table>
          <thead>
            <tr>
              <th>{t('Standard')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StandardList />
                <LearnMoreBtn />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      {!isChina && (
        <Col sm={4} xs={12} className="tier3">
          <Table>
            <thead>
              <tr>
                <th>{t('Premium')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <PremiumList />
                  <LearnMoreBtn />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      )}
    </Row>
  );
};

export default observer(ComparisonTable);
