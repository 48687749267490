import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { Row, PageHeader, Button, Table } from 'react-bootstrap';
import { Link } from 'react-mobx-router5';
import moment from 'moment';
import Moment from 'react-moment';
import { handleError } from '../../../../services/ErrorService';
import GenericViewError from '../../../../components/Error/GenericViewError';
import StuckLabsStore from './store';

const StuckLabsListView = () => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new StuckLabsStore());
  const { stuckLabs } = store;
  useEffect(() => {
    store.getStuckLabs();
  }, []);

  return (
    <div className="container-grid-responsive">
      <Row>
        <PageHeader>{t('Stuck labs')}</PageHeader>
        <Button onClick={store.getStuckLabs}>{t('Refresh')}</Button>
        <Table responsive>
          <thead>
            <tr>
              <th className="lab-id-col">{t('Lab ID')}</th>
              <th className="lab-modality-col">{t('Modality')}</th>
              <th className="lab-username-col">{t('Username')}</th>
              <th className="lab-stuck-time-col">{t('Stuck time')}</th>
              <th>{t('Stuck state')}</th>
              <th className="lab-actions-col">{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {stuckLabs.map((lab) => {
              const stuckFrom = moment(lab.last_state_time);
              return (
                <tr key={lab.doc_id}>
                  <td>{lab.doc_id}</td>
                  <td>{lab.modality}</td>
                  <td>{lab.username}</td>
                  <td>
                    <Moment fromNow ago>
                      {stuckFrom}
                    </Moment>{' '}
                    ({t('stuck from')} <Moment>{stuckFrom}</Moment>)
                  </td>
                  <td>{lab.lab_stuck_type}</td>
                  <td>
                    <Link
                      href
                      routeName="admin:labs:resolve"
                      routeParams={{ id: lab.doc_id }}
                    >
                      <Button bsStyle="primary">{t('Resolve')}</Button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(StuckLabsListView),
  GenericViewError,
  handleError,
);
