import { observable, action } from 'mobx';
import { get, post } from 'axios';

import { EBOOK_API_BASE_V2 } from '../../config/constants';
import { postEvent } from '../../services/EventService';

class EBookStore {
  @observable user = {
    firstName: '',
    lastName: '',
    email: '',
  };

  @observable documents = [];

  @observable currentUUID = '';

  @observable modal = {
    opened: false,
  };

  @observable isTermsAndConditionsEnabled = false;

  @observable isTermsAndConditionsAccepted = false;

  @observable loading = false;

  @observable isDropdownOpen = false;

  // Filter out documents by provided keys
  static filterDocs(docs) {
    return Object.keys(this).every((key) => docs[key] === this[key]);
  }

  static poll() {
    return new Promise((resolve) => {
      setTimeout(resolve.bind(null), 5000);
    });
  }

  fetchPDF(uuid, euuid, modality) {
    return new Promise((resolve, reject) => {
      if (!uuid) {
        // TODO: Add reject message
        return reject();
      }

      return get(`${EBOOK_API_BASE_V2}${uuid}`, {
        params: {
          ...(euuid ? { enrollment_uuid: euuid } : {}),
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          email: this.user.email,
          modality,
        },
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf, */*',
        },
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  checkPDF(uuid, modality) {
    return new Promise((resolve, reject) => {
      if (!uuid) {
        // TODO: Add reject message
        return reject();
      }

      this.loading = true;

      return post(`${EBOOK_API_BASE_V2}${uuid}`, {
        params: {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          email: this.user.email,
          modality,
        },
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  // List all documents for a particular course version
  @action list(code, version, modality) {
    return new Promise((resolve, reject) => {
      get(EBOOK_API_BASE_V2, {
        params: {
          course_code: code,
          course_version: version,
          modality,
        },
      })
        .then((response) => {
          const docs =
            (response && response.data && response.data.documents) || [];

          const query = {
            product: 'sg',
            modality: 'rhls',
          };
          const filteredDocs = docs
            .filter(EBookStore.filterDocs, query)
            .sort((a, b) => a.language > b.language);

          this.documents = filteredDocs;
          resolve(filteredDocs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action onUserInfoNotAvailable = async (userInfo, userID) => {
    try {
      return await postEvent('no_user_info_ebook', {
        data: {
          user_info: userInfo,
          user_uuid: userID,
        },
      });
    } catch (e) {
      console.error('onUserInfoNotAvailable: PDF Error : ', e);
      return {};
    }
  };
}

export default EBookStore;
