import React from 'react';
import { PropTypes } from 'prop-types';

const ExamCollateral = (props) => {
  const { collateral } = props;

  /* eslint-disable camelcase */
  return (
    <React.Fragment>
      {collateral.description && (
        <div
          className="course-collateral-description"
          dangerouslySetInnerHTML={{
            __html: collateral?.description?.body,
          }}
        />
      )}
    </React.Fragment>
  );
};
/* eslint-enable camelcase */

ExamCollateral.propTypes = {
  collateral: PropTypes.object.isRequired,
};

export default ExamCollateral;
