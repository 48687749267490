import React, { useContext } from 'react';
import { Button, Flex, FlexItem } from '@patternfly/react-core';
import Bookshelf from 'img/sites_logos/red-hat-training-bookshelf.svg';
import CustomerPortal from 'img/sites_logos/red-hat-customer-portal.svg';
import SkillsAssessment from 'img/sites_logos/red-hat-skills-assessment.svg';
import RedHat from 'img/sites_logos/red-hat-logo.svg';
import RedHatCommunity from 'img/sites_logos/red-hat-learning-community-long.svg';
import { RHLC_BASE } from 'config/constants';
import { storesContext } from 'stores';

export default function SitesMenu() {
  const { configStore } = useContext(storesContext);
  return (
    <Flex
      justifyContent={{ default: 'justifyContentCenter' }}
      alignItems={{ default: 'alignItemsBaseline' }}
    >
      <FlexItem className="external-site">
        <Button
          variant="link"
          component="a"
          target="_blank"
          href="https://www.redhat.com/"
        >
          <img src={RedHat} alt="Red Hat" />
        </Button>
      </FlexItem>
      <FlexItem className="external-site">
        <Button
          variant="link"
          component="a"
          target="_blank"
          href="https://access.redhat.com/front"
        >
          <img src={CustomerPortal} alt="Red Hat Customer Portal" />
        </Button>
      </FlexItem>
      <FlexItem className="external-site">
        <Button variant="link" component="a" target="_blank" href={RHLC_BASE}>
          <img src={RedHatCommunity} alt="Red Hat Community" />
        </Button>
      </FlexItem>
      <FlexItem className="external-site">
        <Button
          variant="link"
          component="a"
          target="_blank"
          href="https://skills.ole.redhat.com/en"
        >
          <img src={SkillsAssessment} alt="Red Hat Skills Assessment" />
        </Button>
      </FlexItem>
      {configStore.ebooksPlatformURL && (
        <FlexItem className="external-site">
          <Button
            variant="link"
            component="a"
            target="_blank"
            href={configStore.ebooksPlatformURL}
            data-analytics-id="bookshelf-btn-header-lp"
          >
            <img src={Bookshelf} alt="Red Hat Training Bookshelf" />
          </Button>
        </FlexItem>
      )}
    </Flex>
  );
}
