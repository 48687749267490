/**
 * @file Provides utility methods to support interacting with the Class API
 * @author Joshua Jack <jjack@redhat.com>
 */

import querystring from 'querystring';
import { get, delete as del, post, put } from 'axios';

import {
  CLASSES_API_BASE,
  CLASSES_VIEW_BASE,
  CLASS_ENROLLMENT_JOIN_API,
  VTC_BASE,
  CLASSES_CONFERENCE_API,
} from '../config/constants';

/**
 * Returns class info (does not require authentication or an enrollment)
 * @param {array} offeringIds - Array of Offering IDs
 * @return {Object} An object containing user enrollment data
 */
const getVTClassesByOfferingIds = (offeringIds) =>
  new Promise((resolve, reject) => {
    const query = querystring.stringify({
      offeringIds,
    });

    get(`${CLASSES_API_BASE}?${query}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Creates a class
 * @param {Object} params - VTClass parameters
 * @return {Object} A VTClass object
 */
const createVTClass = (params) =>
  new Promise((resolve, reject) => {
    post(CLASSES_API_BASE, {
      ...params,
    })
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Updates an existing class
 * @param {string} uuid - Class UUID
 * @param {Object} params - VTClass parameters
 * @return {Object} A VTClass object
 */
const updateVTClass = (uuid, params) =>
  new Promise((resolve, reject) => {
    put(`${CLASSES_API_BASE}${uuid}`, {
      ...params,
    })
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Deletes a class
 * @param {string} uuid - Class UUID
 * @return {Object} A VTClass object
 */
const deleteVTClass = (uuid) =>
  new Promise((resolve, reject) => {
    del(`${CLASSES_API_BASE}${uuid}`)
      .then(() => resolve())
      .catch((error) => reject(error));
  });

/**
 * Returns class info
 * @param {string} uuid - Class UUID
 * @return {Object} An object containing user enrollment data
 */
const getVTClassByUUID = (uuid) =>
  new Promise((resolve, reject) => {
    get(`${CLASSES_API_BASE}${uuid}`)
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Adds an enrollment based on Class UUID and Enrollment UUID
 * @param {Object} params - Params
 * @return {Object[]} A collection containing user enrollment data
 */
const addVTClassEnrollment = (uuid, params) =>
  new Promise((resolve, reject) => {
    post(`${CLASSES_API_BASE}${uuid}/enrollments`, {
      ...params,
    })
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Deletes an enrollment based on Class UUID and Enrollment UUID
 * @param {string} uuid - Class UUID
 * @param {string} euuid - Enrollment UUID
 */
const deleteVTClassEnrollment = (uuid, euuid) =>
  new Promise((resolve, reject) => {
    del(`${CLASSES_API_BASE}${uuid}/enrollments/${euuid}`)
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns enrollments for a particular class (requires authentication)
 * @param {string} uuid - Course uuid
 * @return {Object[]} A collection containing user enrollment data
 */
const getVTClassEnrollments = (uuid) =>
  new Promise((resolve, reject) => {
    get(`${CLASSES_API_BASE}${uuid}/enrollments`)
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Returns conference url for a particular class (requires authentication)
 * @param {string} uuid - Course uuid
 * @return {Object} An object containing user conference url
 */

const getVTClassConferenceUrl = async (uuid) => {
  try {
    const response = await get(CLASSES_CONFERENCE_API.replace('<uuid>', uuid));
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Returns user enrollment data for a particular class (requires authentication)
 * @param {string} offeringId - Course uuid
 * @param {string} username - Username
 * @return {Object} An object containing user enrollment data
 */

const getVTClassEnrollmentByUsername = (uuid, username) =>
  new Promise((resolve, reject) => {
    const query = querystring.stringify({
      username,
    });

    get(`${CLASSES_API_BASE}${uuid}/enrollments?${query}`)
      .then((response) =>
        resolve(response && response.data && response.data[0]),
      )
      .catch((error) => reject(error));
  });

/**
 * Redirects to the VT Class Dashboard based on the user's enrollment
 * @param {string} uuid - Course uuid
 * @param {string} euuid - User enrollment uuuid
 * @return {Nothing}
 */

const joinVTClass = async (uuid, euuid) =>
  post(
    CLASS_ENROLLMENT_JOIN_API.replace('<uuid>', uuid).replace('<euuid>', euuid),
  );

/**
 * Redirects to the VT Class Dashboard based on the user's enrollment
 * @param {string} uuid - Course uuid
 * @param {string} euuid - User enrollment uuuid
 * @return {Promise} A blank promise (redirects you to a new URL)
 */

const jumpToVTClass = (uuid, euuid) =>
  post(
    `${VTC_BASE}enrollment_jump`,
    querystring.stringify({
      enrollment: euuid,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  ).then(() => window.location.replace(`${CLASSES_VIEW_BASE}${uuid}`));

export {
  getVTClassesByOfferingIds,
  createVTClass,
  updateVTClass,
  deleteVTClass,
  getVTClassByUUID,
  addVTClassEnrollment,
  deleteVTClassEnrollment,
  getVTClassEnrollments,
  getVTClassEnrollmentByUsername,
  joinVTClass,
  jumpToVTClass,
  getVTClassConferenceUrl,
};
