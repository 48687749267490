import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Row } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import FreeTierVCStore, { FreeTierVCContext } from './store';
import { storesContext } from '../../stores';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import FreeTierVideoClassroomComponent from '../../components/VideoClassroom/FreeTier';

const FreeTierVideoClassroom = () => {
  const { routerStore } = useContext(storesContext);
  const vcStore = useLocalStore(() => new FreeTierVCStore()); // seminarStore should provide context
  const { route } = routerStore;
  const courseCode = route.params.course.slice(0, -2);

  useEffect(() => {
    vcStore.getVCDetails(courseCode);
    if (vcStore.vc.header) {
      window.document.title = vcStore.vc.header?.title;
    }
  }, []);

  useEffect(() => {
    vcStore.getVCDetails(courseCode);
    if (vcStore.vc.header) {
      window.document.title = vcStore.vc.header?.title;
    }
  }, [route.params.course]);

  return (
    <div className="container-grid-responsive">
      <Row>
        <FreeTierVCContext.Provider value={vcStore}>
          <FreeTierVideoClassroomComponent />
        </FreeTierVCContext.Provider>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(FreeTierVideoClassroom),
  GenericViewError,
  handleError,
);
