import { post } from 'axios';
import { ZDOC_POST_QUERY_API } from '../config/constants';
import queryHelpers from '../helpers/query';

export const postQuery = async (zdocName, data = {}, size = 100, from = 0) => {
  try {
    if (zdocName === '') {
      throw new Error('ZDOC name required');
    }
    const zdocApi = ZDOC_POST_QUERY_API.replace('<size>', size).replace(
      '<from>',
      from,
    );

    const res = await post(
      queryHelpers.parseQueryUrl(zdocApi, {
        key: '<zdoc-name>',
        value: zdocName,
      }),
      data,
    );

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default postQuery;
