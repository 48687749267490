import { observable, action } from 'mobx';
import moment from 'moment';
import {
  getAdminReport,
  generateCSV,
} from '../../../services/ReportingService';

class ConsumptionReportStore {
  @observable number = 1;

  @observable timeMeasurement = 'M';

  @observable options = [
    {
      label: 'Months',
      value: 'M',
    },
    {
      label: 'Weeks',
      value: 'W',
    },
  ];

  @observable successMsgVisible = false;

  @observable errorMsgVisible = false;

  @observable errorMessage = '';

  @observable loading = false;

  @action fetchReport = async () => {
    this.hideErrorMessage();

    try {
      this.loading = true;
      const report = await getAdminReport('consumption_report', {
        when: `${this.number}${this.timeMeasurement}`,
      });
      if (report) {
        const format = 'YYYY-MM-DD';
        const date = moment().format(format);
        generateCSV(
          report,
          `consumption_${date}_${this.number}${this.timeMeasurement}.csv`,
        );
        this.showSuccessMessage();
      }
    } catch (error) {
      this.showErrorMessage(error.message);
    } finally {
      this.loading = false;
    }
  };

  @action showErrorMessage = (error) => {
    this.errorMessage = error;
    this.errorMsgVisible = true;
  };

  @action hideErrorMessage = () => {
    this.errorMessage = '';
    this.errorMsgVisible = false;
  };

  @action showSuccessMessage = () => {
    this.successMsgVisible = true;
    setTimeout(() => {
      this.hideSuccessMessage();
    }, 10000);
  };

  @action hideSuccessMessage = () => {
    this.successMsgVisible = false;
  };
}

export default ConsumptionReportStore;
