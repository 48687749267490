import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import * as merge from 'deepmerge';
import { reportError } from 'config/sentry';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';

import Store from './store';
import { TRAINING_CONTACTS } from '../../../config/constants';

@inject('userStore', 'eventStore', 'configStore')
@withTranslation()
@observer
class CertificateOfAttendanceModal extends Component {
  static propTypes = {
    courseSlug: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
    user: PropTypes.object,
    setVisibility: PropTypes.func,
    t: PropTypes.func.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    eventStore: MobXPropTypes.observableObject.isRequired,
    configStore: MobXPropTypes.observableObject.isRequired,
    forPremium: PropTypes.bool,
  };

  static defaultProps = {
    user: {
      preferred_name: '',
    },
    setVisibility: () => false,
    timestamp: null,
    forPremium: false,
  };

  constructor(props) {
    super(props);

    this.store = new Store();
    this.trainingContacts = TRAINING_CONTACTS;
  }

  componentDidMount() {
    this.store.user = this.props.user;
    this.verifyUser();
  }

  onConfirmModalSubmit = (e) => {
    e.preventDefault();

    this.getCertificate();
  };

  onRedirectModalSubmit = (e) => {
    e.preventDefault();
    this.getCertificate();
  };

  onChange = (e) => {
    this.store.user = merge(toJS(this.store.user), {
      [e.target.name]: e.target.value,
    });
  };

  async getCertificate() {
    const { courseSlug, timestamp, user, userStore, forPremium } = this.props;
    try {
      const { skipRhlcForCoa } = this.props.configStore;
      this.store.hasErrorOnGenerateCertificate = false;
      this.store.generateCertificateInProgress = true;
      await this.store.updateUserProfile(user, userStore);

      await this.store.generateCertificate(
        courseSlug,
        timestamp,
        forPremium,
        skipRhlcForCoa,
      );

      this.closeConfirmModal();
      this.closeRedirectModal();
    } catch (e) {
      console.error(e);
      reportError(
        e,
        {
          courseSlug,
          timestamp,
          forPremium,
          user,
        },
        'CertificateOfAttendanceModal.getCertificate',
      );
      this.store.hasErrorOnGenerateCertificate = true;
    } finally {
      this.store.generateCertificateInProgress = false;
    }
  }

  verifyUser = () => {
    const { user } = this.props;
    // Verify required user infomation is available
    if (!this.store.user?.preferred_name) {
      if (user.first_name && user.last_name) {
        this.store.user.preferred_name =
          `${user.first_name} ${user.last_name}`.trim();
      }
      this.showConfirmModal();
      return;
    }

    this.showRedirectModal();
  };

  showConfirmModal = () => {
    this.store.confirmModal.opened = true;
  };

  closeConfirmModal = () => {
    this.store.confirmModal.opened = false;
    const { setVisibility } = this.props;
    setVisibility(false);
  };

  showRedirectModal = () => {
    this.store.redirectModal.opened = true;
  };

  closeRedirectModal = () => {
    this.store.redirectModal.opened = false;
    const { setVisibility } = this.props;
    setVisibility(false);
  };

  openSupport = () => {
    this.props.eventStore.toggleDrawer('support_form');
    this.closeConfirmModal();
    this.closeRedirectModal();
    return false;
  };

  render() {
    const { t } = this.props;
    const {
      confirmModal,
      redirectModal,
      user,
      hasErrorOnGenerateCertificate,
      generateCertificateInProgress,
    } = this.store;

    const downloadCertText = t('Download Certificate of Attendance');
    const redirectionText =
      t(`<strong>Please note:</strong> you'll be redirected to the Red Hat Learning Community
    (RHLC) to download your certificate. You may be prompted to register if you are not already an RHLC member or
    log in using your Red Hat Login (RHN ID) and password.`);

    const errorCertificateGeneration =
      t(`<i> Problem generating Certificate, please try again. If the problem persists, please contact <a href="javascript:"  >
    Red Hat Training Support</a> for assistance.</i>`);

    const proceedText = t('Proceed');

    return (
      <React.Fragment>
        <Modal
          show={confirmModal.opened}
          onHide={this.closeConfirmModal}
          bsSize="lg"
        >
          <Form onSubmit={this.onConfirmModalSubmit}>
            <ModalHeader closeButton>
              <ModalTitle>{downloadCertText}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                {t(
                  `In order to download the Certificate of Attendance for this course, you'll need to provide your name as you'd like it to appear on your certificate (your preferred name).`,
                )}
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: redirectionText,
                }}
              />
              {hasErrorOnGenerateCertificate && (
                <div
                  aria-hidden="true"
                  onClick={this.openSupport}
                  dangerouslySetInnerHTML={{
                    __html: errorCertificateGeneration,
                  }}
                />
              )}
              <FormGroup
                validationState={
                  user.preferred_name && user.preferred_name.length > 0
                    ? null
                    : 'error'
                }
              >
                <FormControl
                  id="CertificateOfAttendancePreferredName"
                  name="preferred_name"
                  type="text"
                  autoFocus
                  placeholder={t('Preferred name')}
                  defaultValue={user.preferred_name}
                  onChange={this.onChange}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                block
                bsStyle="primary"
                disabled={!user.preferred_name || generateCertificateInProgress}
                type="submit"
              >
                {proceedText}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <Modal
          show={redirectModal.opened}
          onHide={this.closeRedirectModal}
          bsSize="lg"
        >
          <Form onSubmit={this.onRedirectModalSubmit}>
            <ModalHeader closeButton>
              <ModalTitle>{downloadCertText}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p
                dangerouslySetInnerHTML={{
                  __html: redirectionText,
                }}
              />
              {hasErrorOnGenerateCertificate && (
                <div
                  aria-hidden="true"
                  onClick={this.openSupport}
                  dangerouslySetInnerHTML={{
                    __html: errorCertificateGeneration,
                  }}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                block
                bsStyle="primary"
                type="submit"
                disabled={generateCertificateInProgress}
              >
                {proceedText}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CertificateOfAttendanceModal;
