/**
 * @file Course Catalog view allowing filtering and searching for courses
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import ViewLoader from '../../../components/Loading/View';
import { storesContext } from '../../../stores';

const NotFound = () => {
  const { t } = useTranslation();
  const { routerStore, uiStore } = useContext(storesContext);

  uiStore.title = routerStore?.route?.params?.title;

  const { title } = uiStore;
  document.title = t(title);

  return (
    <div className="container-grid-responsive catalog">
      <PageHeader>{t(title)}</PageHeader>
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col sm={12}>{t('Not Found')}</Col>
        </Row>
      </React.Suspense>
    </div>
  );
};

export default observer(NotFound);
