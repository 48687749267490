import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { storesContext } from 'stores';
import TrashIcon from 'img/icons/trash.svg';
import ArchiveIcon from 'img/icons/archive.svg';
import DangerIcon from 'img/icons/danger-icon.svg';
import './styles.scss';

interface DescriptionTextProps {
  text: string;
}

const DescriptionText: React.FC<DescriptionTextProps> = (props) => {
  const { t } = useTranslation();

  const { text } = props;
  const newText = text.split('\n').map((str) => <p>{t(str)}</p>);

  return newText;
};

interface Notification {
  doc_id?: string;
  title?: string;
  description?: string;
  formatted_timestamp?: string;
  archived?: boolean;
  type?: string;
  onArchive?: (id: string) => void;
  onDelete?: (id: string) => void;
  view_button?: boolean;
  onAction?: () => void;
  button_text?: string;
  route_name?: string;
}

interface DrawerCardProps {
  notification: Notification;
}

const DrawerCard: React.FC<DrawerCardProps> = (props) => {
  const { t } = useTranslation();
  const { notification = {} } = props;
  const { userNotificationsStore, eventStore, routerStore } =
    useContext(storesContext);

  const deleteCard = (id: string) => {
    notification?.onDelete?.(id);
  };

  const archiveCard = (id: string) => {
    notification?.onArchive?.(id);
  };

  const deleteTooltip = (
    <Tooltip id="tooltip">
      <strong>{t('Delete')}</strong>
    </Tooltip>
  );

  const archiveTooltip = (
    <Tooltip id="tooltip">
      <strong>{t('Archive')}</strong>
    </Tooltip>
  );

  const hasError =
    notification.type === 'user_notification' &&
    userNotificationsStore.errorNotifications.includes(notification.doc_id);

  const renderActionButton = () => {
    if (!notification.view_button) {
      return null;
    }

    if (notification.route_name) {
      return (
        <div className="action-btn-wrap">
          <Button
            variant="primary"
            className="drawer-card-link-btn"
            data-analytics-id="view-link-notification-card-lp"
            role="link"
            onClick={() => {
              routerStore.navigate(notification.route_name);
              eventStore.isDrawerOpen = false;
            }}
          >
            {t(`${notification.button_text}`)}
          </Button>
        </div>
      );
    }

    return (
      <div className="action-btn-wrap">
        <Button
          className="drawer-card-action-btn"
          onClick={notification.onAction}
          data-analytics-id="view-btn-notification-card-lp"
        >
          {t(`${notification.button_text}`)}
        </Button>
      </div>
    );
  };

  return (
    <div
      className="drawer-card-wrapper"
      data-analytics-id="report-notification-card-lp"
    >
      <div
        className={`${
          notification.archived ? 'drawer-card isArchived' : 'drawer-card'
        } ${hasError ? 'hasError' : ''}`}
      >
        <div className="top-part">
          <p>{t(`${notification.title}`)}</p>
        </div>
        <div className="middle-part">
          <span>
            <DescriptionText text={notification.description} />
          </span>
        </div>
        <div className="bottom-part">{notification.formatted_timestamp}</div>
        <div className="buttons-part">
          {!notification.archived && notification.onArchive && (
            <OverlayTrigger
              placement="top"
              overlay={archiveTooltip}
              delayShow={300}
            >
              <div
                data-analytics-id="arch-btn-notification-card-lp"
                className="archive-btn-wrap"
                onClick={() => archiveCard(notification.doc_id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    archiveCard(notification.doc_id);
                  }
                }}
                role="button"
                tabIndex={0}
                aria-label={t('Archive notification')}
              >
                <SVG src={ArchiveIcon}>{t('Archive')}</SVG>
              </div>
            </OverlayTrigger>
          )}
          {notification.onDelete && (
            <OverlayTrigger
              placement="top"
              overlay={deleteTooltip}
              delayShow={300}
            >
              <div
                data-analytics-id="del-btn-notification-card-lp"
                className="delete-btn-wrap"
                onClick={() => deleteCard(notification.doc_id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    deleteCard(notification.doc_id);
                  }
                }}
                role="button"
                tabIndex={0}
                aria-label={t('Delete notification')}
              >
                <SVG src={TrashIcon}>{t('Delete')}</SVG>
              </div>
            </OverlayTrigger>
          )}

          {hasError && (
            <div className="error-message-container">
              <SVG src={DangerIcon}>Error</SVG>
              <span className="error-message">
                {t('An error occurred performing this action.')}
              </span>
            </div>
          )}
          {renderActionButton()}
        </div>
      </div>
    </div>
  );
};

export default observer(DrawerCard);
