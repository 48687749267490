import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import './styles.scss';

const OverQuotaBanner = () => {
  const { t } = useTranslation();
  return (
    <Alert bsStyle="danger" className="overquota-banner">
      <p>
        {t(
          'Labs for this subscription are unavailable. You have used all of your available lab hours.',
        )}
      </p>
    </Alert>
  );
};

export default OverQuotaBanner;
