import { action, observable } from 'mobx';
import { getTechOverviewCollateral } from 'services/TechOverviewService';

class TechOverviewCardStore {
  @observable collateral = {};

  @action getTechOverviewCollateral = async (slug, language) => {
    const id = `${slug.replace('-', '@@')}@@${language}`;
    if (this.collateral.doc_id === id) {
      return this.collateral;
    }

    const collateral = await getTechOverviewCollateral(id);
    if (collateral) {
      this.collateral = collateral;
    }

    return this.collateral;
  };
}

export default TechOverviewCardStore;
