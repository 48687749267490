import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { storesContext } from '../../../stores';

import Modal from '../../Modal';

const DeleteLearningPathModal = (props) => {
  const { learningPathStore, userStore, vocabularyStore } =
    useContext(storesContext);
  const { i18n, t } = useTranslation();

  const [isModalVisible, setModalVisible] = React.useState(
    props.showModal || false,
  );

  const [mode, setMode] = React.useState('confirm');

  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleDeleteCSK = async () => {
    try {
      setIsDeleting(true);
      await learningPathStore.handleDeleteCSK(
        i18n,
        vocabularyStore,
        userStore,
        props.pathToDelete,
      );
      setMode('success');
    } catch {
      setMode('error');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      cancelFunc={() => {
        setModalVisible(false);
        setMode('confirm');
        setIsDeleting(false);
        props.onPathDeleted();
      }}
      cancelText={mode === 'confirm' ? t('No') : t('Close')}
      confirmDisabled={isDeleting}
      confirmFunc={async () => {
        if (mode === 'confirm') {
          await handleDeleteCSK();
        } else {
          setModalVisible(false);
          setMode('confirm');
          setIsDeleting(false);
          props.onPathDeleted();
        }
      }}
      confirmText={mode === 'confirm' ? t('Yes') : t('Ok')}
      visible={isModalVisible}
      title={
        mode === 'confirm'
          ? t(
              'You are about to delete your custom skills path. This will remove it from your catalog, as well as all users that have been assigned to it. Would you like to continue?',
            )
          : (mode === 'success' &&
              t('Custom Skill Path deleted succesfully.')) ||
            t(
              'Custom Skill Path was not deleted succesfully. Please try again. If the problem persists, please contact support.',
            )
      }
      submitting={isDeleting}
    />
  );
};

DeleteLearningPathModal.propTypes = {
  pathToDelete: PropTypes.object,
  showModal: PropTypes.bool,
  onPathDeleted: PropTypes.func,
};

DeleteLearningPathModal.defaultProps = {
  pathToDelete: null,
  showModal: false,
  onPathDeleted: () => {},
};

export default observer(DeleteLearningPathModal);
