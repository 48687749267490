import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Text,
  TextVariants,
  Flex,
} from '@patternfly/react-core';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { storesContext } from 'stores';
import ExternalLinkSquareAltIcon from '@patternfly/react-icons/dist/esm/icons/arrow-right-icon';
import { ACCESS_REDHAT_COM_URL, KALTURA_CONFIGS } from 'config/constants';

import './styles.scss';
import { KalturaWidget } from '@ole-ui/ole-ui-components';

function LandingMat() {
  const { t } = useTranslation();
  const { configStore, routerStore } = useContext(storesContext);
  const {
    isFreeTrialRegistrationEnabled,
    contactOperationURL,
    learningSubscriptionURL,
  } = configStore;

  if (!isFreeTrialRegistrationEnabled) {
    routerStore.navigate('home');
    return null;
  }

  return (
    <div className="landing-mat">
      <div className="landing-mat__grid">
        <Card className="landing-mat__card landing-mat__card--registration">
          <CardHeader>
            <Text component={TextVariants.h2}>
              {t('Start your free trial subscription')}
            </Text>
          </CardHeader>
          <CardBody>
            <div className="landing-mat__registration-body">
              <p>
                <Trans>
                  {
                    "Red Hat Learning Subscription provides the critical skills training you need through a flexible and easy-to-manage platform. <strong>Try Red Hat Learning Subscription's new 14-day trial</strong> to explore the first two chapters of courses, experience Red Hat products with four hours of lab time and outline a custom learning path."
                  }
                </Trans>
              </p>
              <div className="landing-mat__video-container">
                <KalturaWidget
                  className="landing-mat__tutorial-video"
                  partnerId={KALTURA_CONFIGS.partnerId}
                  playerId={KALTURA_CONFIGS.freeTrialTutorial.uiConfId}
                  entryId={KALTURA_CONFIGS.freeTrialTutorial.entryId}
                  isPlaylist={false}
                />
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button
              size="lg"
              onClick={() => {
                routerStore.navigate('free-trial-registration');
              }}
              data-analytics-id="register-btn-landing-mat-lp"
            >
              {t('Register for a free trial')}
            </Button>
          </CardFooter>
        </Card>

        <Card className="landing-mat__login-card">
          <CardBody>
            <Flex
              alignItems={{ default: 'alignItemsFlexStart' }}
              justifyContent={{ default: 'justifyContentCenter' }}
            >
              <Text component={TextVariants.h3}>
                {t('Already have a Red Hat Learning Subscription account?')}
              </Text>
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => {
                  routerStore.navigate('login');
                }}
                data-analytics-id="login-btn-landing-mat-lp"
              >
                {t('Log in')}
              </Button>
            </Flex>
          </CardBody>
        </Card>

        <Card className="landing-mat__card landing-mat__card--second-row">
          <CardHeader>
            <Text component={TextVariants.h4}>{t('Problems logging in?')}</Text>
          </CardHeader>
          <CardBody>
            <p>
              <Trans>
                If you are unable to login, please{' '}
                <a href="https://sso.redhat.com" target="__blank">
                  verify your user credentials here
                </a>
                . If you are able to authenticate there, please contact our
                training operations team for further assistance.
              </Trans>
            </p>
          </CardBody>
          <CardFooter>
            <Button
              variant="link"
              icon={<ExternalLinkSquareAltIcon />}
              iconPosition="right"
              component="a"
              target="_blank"
              href={contactOperationURL}
              data-analytics-id="training-ops-link-landing-mat-lp"
            >
              {t('Contact Training Operations')}
            </Button>
          </CardFooter>
        </Card>

        <Card className="landing-mat__card landing-mat__card--second-row">
          <CardHeader>
            <Text component={TextVariants.h4}>
              {t('Not a Learning Subscriber?')}
            </Text>
          </CardHeader>
          <CardBody>
            <p>
              {t(
                `Red Hat Learning Subscription provides access to the entire catalog of online training for an entire year! To learn more, visit:`,
              )}
            </p>
          </CardBody>
          <CardFooter>
            <Button
              variant="link"
              icon={<ExternalLinkSquareAltIcon />}
              iconPosition="right"
              component="a"
              target="_blank"
              href={learningSubscriptionURL}
              data-analytics-id="about-subs-link-landing-mat-lp"
            >
              {t('About Red Hat Learning Subscriptions')}
            </Button>
          </CardFooter>
        </Card>

        <Card className="landing-mat__card landing-mat__card--second-row">
          <CardHeader>
            <Text component={TextVariants.h4}>
              {t('Looking for more stuff to explore?')}
            </Text>
          </CardHeader>
          <CardBody>
            <p>
              {t(
                `Every Red Hat Online Training course comes with access to the Red Hat Customer Portal, including its Knowledge Base, Discussion Communities, and Tools.`,
              )}
            </p>
          </CardBody>
          <CardFooter>
            <Button
              variant="link"
              icon={<ExternalLinkSquareAltIcon />}
              iconPosition="right"
              component="a"
              target="_blank"
              href={ACCESS_REDHAT_COM_URL}
              data-analytics-id="customer-portal-link-landing-mat-lp"
            >
              {t('Red Hat Customer Portal')}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}

export default observer(LandingMat);
