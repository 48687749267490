import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

import {
  Hint,
  HintBody,
  Button,
  Modal,
  ModalVariant,
  Text,
  TextVariants,
  CodeBlock,
  CodeBlockCode,
  ClipboardCopyButton,
  Label,
  LabelGroup,
} from '@patternfly/react-core';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import LanguageIcon from '@patternfly/react-icons/dist/esm/icons/globe-icon';

import { storesContext } from 'stores';
import './styles.scss';

function ContentVersionHint({ buildEnv, courseSlug, language }) {
  const { t } = useTranslation();
  const { userStore } = useContext(storesContext);
  const { isAdmin, isInstructorOrAdmin } = userStore;
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    build_timestamp: buildTimestamp,
    scm_revision: scmRevision,
    build_branch: buildBranch,
    build_url: buildURL,
  } = buildEnv;

  const commit = `${courseSlug}-${scmRevision}`;

  const code = useMemo(
    () => JSON.stringify(buildEnv, Object.keys(buildEnv).sort(), 4),
    [buildEnv],
  );

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (!(buildTimestamp || scmRevision || buildBranch || buildURL)) {
    return null;
  }

  if (!isInstructorOrAdmin) {
    if (!scmRevision) {
      return null;
    }
    return (
      <Text component={TextVariants.small}>
        <strong>{t('Revision:')}</strong> {commit}
      </Text>
    );
  }

  const codeActions = (
    <ClipboardCopyButton
      aria-label={t('Copy to clipboard')}
      onClick={() => {
        navigator.clipboard.writeText(code);
        setCopied(true);
      }}
      exitDelay={copied ? 1500 : 600}
      maxWidth="110px"
      variant="plain"
      onTooltipHidden={() => setCopied(false)}
    >
      {copied ? t('Successfully copied to clipboard!') : t('Copy to clipboard')}
    </ClipboardCopyButton>
  );

  const actions = isAdmin
    ? [
        <Button
          variant="link"
          isInline
          onClick={handleModalToggle}
          key="more-info-btn"
        >
          {t('More info')}
        </Button>,
      ]
    : null;

  return (
    <>
      <Hint className="content-version-hint" actions={actions}>
        <HintBody className="content-version-hint__body">
          <Text component={TextVariants.small}>
            <strong>{t('Revision:')}</strong> {commit}
          </Text>
          {isInstructorOrAdmin && (
            <>
              {buildTimestamp && (
                <Text component={TextVariants.small}>
                  <strong>{t('Build timestamp:')}</strong> {buildTimestamp}
                </Text>
              )}
              {scmRevision && (
                <Text component={TextVariants.small}>
                  <strong>{t('SCM revision:')}</strong> {scmRevision}
                </Text>
              )}
              {buildBranch && (
                <Text component={TextVariants.small}>
                  <strong>{t('Build branch:')}</strong> {buildBranch}
                </Text>
              )}
            </>
          )}
        </HintBody>
      </Hint>
      <Modal
        variant={ModalVariant.medium}
        title={t('Build info')}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={
          buildURL
            ? [
                <Button
                  key="go_to_build_button"
                  component="a"
                  target="_blank"
                  variant="link"
                  href={buildURL}
                >
                  {t('Go to build')}
                </Button>,
              ]
            : []
        }
      >
        <LabelGroup className="content-version-hint__label-group">
          <Label icon={<InfoCircleIcon />}>{courseSlug}</Label>
          <Label icon={<LanguageIcon />} color="blue">
            {language}
          </Label>
        </LabelGroup>
        <CodeBlock actions={codeActions} className="content-version-hint__code">
          <CodeBlockCode>{code}</CodeBlockCode>
        </CodeBlock>
      </Modal>
    </>
  );
}

ContentVersionHint.propTypes = {
  courseSlug: PropTypes.string,
  language: PropTypes.string,
  buildEnv: PropTypes.shape({
    build_branch: PropTypes.string,
    build_timestamp: PropTypes.string,
    scm_revision: PropTypes.string,
    build_url: PropTypes.string,
  }),
};

ContentVersionHint.defaultProps = {
  buildEnv: {},
  courseSlug: '',
  language: '',
};

export default withErrorBoundary(
  ContentVersionHint,
  ComponentError,
  handleError,
);
