import React, { useContext, useEffect } from 'react';
import { Row, PageHeader, Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { Link } from 'react-mobx-router5';
import GenericViewError from '../../../components/Error/GenericViewError';
import { handleError } from '../../../services/ErrorService';
import { storesContext } from '../../../stores';
import AlertListItem from '../../../components/Alert/List/ListItem';
import Modal from '../../../components/Modal';
import './styles.scss';
import AlertListUIStore, { alertListUIStoreContext } from './store';

const AlertsList = () => {
  const { t } = useTranslation();
  const { alertStore } = useContext(storesContext);
  const { chronologicalAlerts } = alertStore;
  const store = useLocalStore(() => new AlertListUIStore());

  useEffect(() => {
    alertStore.getAlerts();
  }, []);

  const refresh = () => {
    alertStore.getAlerts();
  };

  return (
    <div className="container-grid-responsive">
      <Row>
        <PageHeader>{t('Alerts')}</PageHeader>
        <Link href routeName="admin:alerts:new">
          <Button bsStyle="primary">{t('New alert')}</Button>
        </Link>
        <Button onClick={refresh} disabled={alertStore.loading}>
          {t('Refresh data')}
        </Button>
      </Row>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th className="alert-preview-col">{t('Alert preview')}</th>
              <th className="alert-location-col">{t('Location')}</th>
              <th className="alert-subscriptions-col">{t('Subscriptions')}</th>
              <th>{t('Status')}</th>
              <th className="alert-actions-col">{t('Actions')}</th>
            </tr>
          </thead>
          <alertListUIStoreContext.Provider value={store}>
            <tbody>
              {chronologicalAlerts.map((alert) => (
                <AlertListItem alert={alert} key={alert.doc_id} />
              ))}
            </tbody>
          </alertListUIStoreContext.Provider>
        </Table>
      </Row>
      <Modal {...store.modal} />
    </div>
  );
};

export default withErrorBoundary(
  observer(AlertsList),
  GenericViewError,
  handleError,
);
