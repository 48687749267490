import { computed, observable, extendObservable } from 'mobx';
import { createContext } from 'react';

class LabResolveUIStore {
  @observable _modal = {
    opened: false,
    title: '',
    confirmText: '',
    confirmFunc: () => {},
  };

  @computed get modal() {
    return this._modal;
  }

  set modal(obj) {
    extendObservable(this.modal, obj);
  }
}

export default LabResolveUIStore;
export const labResolveUIContext = createContext(null);
