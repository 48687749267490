import { get, post, patch } from 'axios';
import {
  PREMIUM_VT_SESSION_STATES,
  PREMVT_SESSION_ENROLLMENT_API,
  PREMVT_SESSION_ENROLLMENT_PARAMS_API,
  PREMVT_SESSION_ENROLLMENT_PATCH_API,
} from '../config/constants';

export const postEnrollment = async (
  premvtSessionUUID,
  premvtSessionState,
  rescheduleEnrolledId = null,
) => {
  if (!premvtSessionUUID) {
    throw new Error('Missing Param: premvtSessionUUID');
  }
  if (!Object.values(PREMIUM_VT_SESSION_STATES).includes(premvtSessionState)) {
    throw new Error('Invalid State: Please provide a valid state');
  }

  const data = {
    premvt_session_uuid: premvtSessionUUID,
    state: premvtSessionState,
  };

  if (rescheduleEnrolledId) {
    data.reschedule_enrolled_id = rescheduleEnrolledId;
  }

  try {
    const response = await post(PREMVT_SESSION_ENROLLMENT_API, data);
    return response;
  } catch (e) {
    throw e;
  }
};

export const getAllUserEnrollments = async (states = []) => {
  let queryParams = '';

  if (states?.length > 0) {
    queryParams = `?states=${states?.join(',')}`;
  }

  return new Promise((resolve, reject) => {
    get(
      PREMVT_SESSION_ENROLLMENT_PARAMS_API.replace(
        '<<query-params>>',
        queryParams,
      ),
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchEnrollment = async (
  premvtSessionUUID,
  premvtSessionState,
) => {
  if (!premvtSessionUUID) {
    throw new Error('Missing Param: premvtSessionUUID');
  }
  if (!Object.values(PREMIUM_VT_SESSION_STATES).includes(premvtSessionState)) {
    throw new Error(
      'Invalid State: Please provide a valid state : enrolled | cancelled | completed',
    );
  }

  if (
    !(
      premvtSessionState === PREMIUM_VT_SESSION_STATES.enrolled ||
      premvtSessionState === PREMIUM_VT_SESSION_STATES.cancelled ||
      premvtSessionState === PREMIUM_VT_SESSION_STATES.completed
    )
  ) {
    throw new Error('Invalid State: Please provide a valid state');
  }

  const data = {
    state: premvtSessionState,
  };

  // returning promise to capture error when api returns 400.
  return new Promise((resolve, reject) => {
    patch(
      PREMVT_SESSION_ENROLLMENT_PATCH_API.replace(
        '<<premvt_session_uuid>>',
        premvtSessionUUID,
      ),
      data,
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
