/**
 * @file Provides usage staistics and analysis
 * @author Attila farkas <afarkas@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import SVG from 'react-inlinesvg';
import { withErrorBoundary } from 'react-error-boundary';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import CSVIcon from 'img/icons/csv-download.svg';
import { storesContext } from 'stores';
import TopSummary from './TopSummary';
import MainSection from './MainSection';
import Store from './store';
import './styles.scss';

const Dashboard = () => {
  const { userStore } = useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(() => new Store(userStore));
  const csvTooltip = (
    <Tooltip id="locked-tooltip">{t('Download consolidated CSV')}</Tooltip>
  );

  const downloadCSV = () => {
    store.fetchConsolidatedReportCSV();
  };

  return (
    <React.Fragment>
      <div className="dashboard-wrapper">
        <div className="download-options-wrapper">
          <OverlayTrigger
            placement="top"
            trigger={['hover']}
            overlay={csvTooltip}
          >
            <div className="svg-holder pull-right">
              <span
                onClick={() => downloadCSV()}
                role="button"
                tabIndex="0"
                data-analytics-id="download-dboard-lp"
                aria-label={t('Download consolidated CSV')}
              >
                <SVG src={CSVIcon} />
              </span>
            </div>
          </OverlayTrigger>
        </div>
        <TopSummary />
        <MainSection />
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(Dashboard),
  GenericViewError,
  handleError,
);
