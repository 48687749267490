import { observable, action, computed } from 'mobx';
import { interactiveData } from '../../../../../services/Interactions';

class ResequencingStore {
  @observable matchedArray = [];

  @observable reSequencingData = [];

  @observable items = [];

  @observable reset = false;

  @observable originalArray = [];

  @observable originalItems = [];

  @observable slug = '';

  @observable chapter = '';

  // pushed label to array
  @action storeMatchedLabel(label, index) {
    if (!this.matchedArray.includes(label)) {
      this.matchedArray.push(label);
      this.originalArray.push(index);
      this.items.push({
        id: index,
        content: label,
      });
      this.originalItems.push({
        id: index,
        content: label,
      });
    }
  }

  // set item in items array
  @computed get setItem() {
    return this.items || [];
  }

  set setItem(item) {
    this.items = item;
  }

  // get answer data
  @action interactiveInfoFetch(slug, chapter) {
    interactiveData(slug, chapter).then((result) => {
      this.reSequencingData = result;
    });
  }
}

export default new ResequencingStore();
