import useMobxStores from 'hooks/useMobxStores';
import { useEffect } from 'react';

export default function useLoadCourse(courseSlug, courseStore) {
  const { catalogStore } = useMobxStores();

  useEffect(() => {
    if (catalogStore.hasCatalogLoaded) {
      courseStore.getInitialData(courseSlug);
    }
  }, [courseSlug, catalogStore.hasCatalogLoaded]);

  useEffect(() => {
    // loads any data that depends on the language, like first page, structure, page content, etc.
    courseStore.getComplementaryDataByLanguage();
  }, [courseSlug, courseStore.language, courseStore.loadedInitialData]);
}
