import { get } from 'axios';
import { SUBSCRIPTION_API } from '../config/constants';

export const getSubscriptions = async () => {
  try {
    const response = await get(SUBSCRIPTION_API);
    return response.data?.items || [];
  } catch (error) {
    throw error;
  }
};

export const isFreeTierSubscriber = (subscription) => {
  return subscription === 'ls000';
};

export const isLimitedTrialSubscriber = (subscription) => {
  return subscription === 'ls999';
};

export const isBasicSubscriber = (subscription) => {
  return subscription === 'ls120';
};

export const isStandardSubscriber = (subscription) => {
  return subscription === 'ls220';
};

export const isPremiumSubscriber = (subscription) => {
  return subscription === 'ls520' || isLimitedTrialSubscriber(subscription);
};

export const isRHUSubscriber = (subscription) => {
  return subscription === 'ls810';
};

export const isLS300Subscriber = (subscription) => {
  return subscription === 'ls300';
};

export const isLS101Subscriber = (subscription) => {
  return subscription === 'ls101';
};

export const isWD200Subscriber = (subscription) => {
  return subscription === 'wdp200sp';
};

export const isXS001Subscriber = (subscription) => {
  return subscription === 'xs001';
};

export default getSubscriptions;
