/**
 * @file Provides methods to interact with course data
 * @author Joshua Jack <jjack@redhat.com>
 */

import { post } from 'axios';

import {
  CERTIFICATE_OF_ATTENDANCE_BASE_API,
  CERTIFICATE_OF_ATTENDANCE_UUID_API,
  PREMVT_SESSION_API_COA,
} from '../config/constants';

/**
 * Emails certificates of attendance to students
 * @param {Array} students - Array of student objects
 */
export const emailCertificates = (students, theme, course) =>
  new Promise((resolve, reject) => {
    post(CERTIFICATE_OF_ATTENDANCE_BASE_API, {
      students,
      theme,
      course,
    })
      .then((response) => resolve(response && response.data))
      .catch((error) => reject(error));
  });

/**
 * Generates a course certificate of attendance on the server
 * @param {Object} data
 * @return {String} UUID of the generated certificate
 */
export const generateCertificate = async (data) => {
  try {
    const response = await post(CERTIFICATE_OF_ATTENDANCE_UUID_API, data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Generates a course certificate of attendance on the server
 * @param {Object} data
 * @return {String} UUID of the generated certificate
 */
export const generateCertificateForPremium = async (data) => {
  try {
    const response = await post(PREMVT_SESSION_API_COA, data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
