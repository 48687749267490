import React from 'react';
import {
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateFooter,
  EmptyStateActions,
  EmptyStateIcon,
} from '@patternfly/react-core';
import ErrorIcon from '@patternfly/react-icons/dist/esm/icons/error-circle-o-icon';
import { useTranslation } from 'react-i18next';

type Props = {
  onRetry: () => void;
  initialErrorCount?: number;
  onSupportClick: () => void;
};

const ErrorState = ({ onRetry, onSupportClick, initialErrorCount }: Props) => {
  const { t } = useTranslation();

  const hasTooManyRetries = initialErrorCount && initialErrorCount > 1;

  return (
    <div className="container-grid-responsive hud-course-view">
      <EmptyState>
        <EmptyStateHeader
          titleText={t('Could not load the course')}
          headingLevel="h4"
          icon={
            <EmptyStateIcon
              icon={ErrorIcon}
              color="var(--pf-v5-global--danger-color--100)"
            />
          }
        />
        <EmptyStateBody>
          {hasTooManyRetries
            ? t(
                'Our team received the error report. Meanwhile you can try again by reloading the page or contact support.',
              )
            : t(
                'Our team received the error report. Meanwhile you can try again by reloading the page.',
              )}
        </EmptyStateBody>
        <EmptyStateFooter>
          <EmptyStateActions>
            <Button
              variant="link"
              onClick={() => {
                onRetry();
              }}
            >
              {t('Retry')}
            </Button>
            {hasTooManyRetries && (
              <Button variant="link" onClick={onSupportClick}>
                {t('Contact support')}
              </Button>
            )}
          </EmptyStateActions>
        </EmptyStateFooter>
      </EmptyState>
    </div>
  );
};

export default ErrorState;
