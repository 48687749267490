import { action, observable, computed } from 'mobx';
import flatMap from 'lodash/flatMap';
import moment from 'moment';
import {
  PREMIUM_SCHEDULED_SESSION_SORT_FILEDS,
  PREMIUM_VT_SESSION_STATES,
  PREMIUM_VT_SESSION_ERROR_STATES,
} from '../../../config/constants';

import ClassesStore from '../../../stores/Classes';

class ScheduleSessionStore {
  @observable ready = false;

  @observable submitting = false;

  @observable submitProgress = {};

  @observable isShowASubmitProgress = false;

  @observable premSessionSlug = '';

  @observable _currentPage = 1;

  @observable isSortAscending = true;

  @observable sortByField = PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.date;

  @observable entriesPerPage = 10;

  @observable sessionsBySlug = [];

  @observable selectedSessionToEnroll = '';

  @observable languages = {};

  @observable _isRequestingSession = false;

  @observable showSubscriptionExpiredModal = false;

  constructor(
    catalogStore,
    premiumSessionFilterStore,
    classesStore,
    routerStore,
  ) {
    this.catalogStore = catalogStore;
    this.premiumSessionFilterStore = premiumSessionFilterStore;
    this.classesStore = classesStore;
    this.routerStore = routerStore;
    this.initSelectedSessionToEnroll();
  }

  extendPremiumSession = (sessions, selectedSessionId) => {
    return sessions?.map((session) => {
      return {
        ...session,
        // eslint-disable-next-line camelcase
        time: moment().set({
          hour: moment(session.start_time).get('hour'),
          minute: moment(session.start_time).get('minute'),
          second: moment(session.start_time).get('second'),
        }),
        selected: session.doc_id === selectedSessionId,
        instructorNames:
          session?.instructors?.length === 0
            ? ''
            : flatMap(session?.instructors, 'name')?.sort()?.join(', '),
      };
    });
  };

  @action initSelectedSessionToEnroll() {
    if (this.isRescheduling) {
      this.selectedSessionToEnroll = this.reschedulingSessionId;
    } else {
      this.selectedSessionToEnroll = '';
    }
  }

  @action filterToApply = (s) => {
    return (
      // eslint-disable-next-line camelcase
      s?.premvt_session_slug === this.premSessionSlug &&
      // eslint-disable-next-line camelcase
      s?.state === PREMIUM_VT_SESSION_STATES.scheduled &&
      moment(s.start_time).isSameOrAfter(moment())
    );
  };

  // get schedled sessions whose startdate is > now.
  @computed get scheduledSessionsBySlug() {
    const filteredSessions =
      this.premSessionSlug !== ''
        ? this.premiumSessionFilterStore?.filteredEntries?.filter((s) => {
            return this.filterToApply(s);
          })
        : [];
    const premSessions = this.extendPremiumSession(
      filteredSessions,
      this.selectedSessionToEnroll,
    );

    const sortedSessions = this.sortPremiumSessions(
      premSessions,
      this.isSortAscending,
      this.sortByField,
    );

    return sortedSessions;
  }

  @computed get isAllowToSchedule() {
    if (this.isRescheduling) {
      return (
        this.selectedSessionToEnroll !== this.reschedulingSessionId &&
        !this.submitting
      );
    }

    return this.selectedSessionToEnroll !== '' && !this.submitting;
  }

  @action onSortBy = (sortbyField) => {
    this.isSortAscending = !this.isSortAscending;
    this.sortByField = sortbyField;
  };

  @action sortSessions = (a, b, sortByField) => {
    if (
      typeof a[sortByField] === 'number' &&
      typeof b[sortByField] === 'number'
    )
      return a[sortByField] - b[sortByField];

    return b[sortByField].localeCompare(a[sortByField]);
  };

  @action sortPremiumSessions = (sessions, isSortAscending, sortByField) => {
    if (!isSortAscending) {
      return sessions
        .slice()
        .sort((a, b) =>
          sortByField === PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.date ||
          sortByField === PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.time
            ? new Date(b[sortByField]) - new Date(a[sortByField])
            : this.sortSessions(b, a, sortByField),
        );
    }

    return sessions
      .slice()
      .sort((a, b) =>
        sortByField === PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.date ||
        sortByField === PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.time
          ? new Date(a[sortByField]) - new Date(b[sortByField])
          : this.sortSessions(a, b, sortByField),
      );
  };

  @action onSelectSessionToSchedule = (docId) => {
    this.selectedSessionToEnroll = docId;
  };

  @action enrollUser = async (t) => {
    try {
      this.submitting = true;
      this.submitProgress = {};
      const response = await this.classesStore.enrollUser(
        this.selectedSessionToEnroll,
        this.reschedulingSessionId,
      );

      const successMessage = this.isRescheduling
        ? t('Your session has been rescheduled successfully.')
        : t('Your session has been scheduled successfully.');

      this.submitProgress = {
        hasError: !!response.data.conflicting_enrollments,
        message: response.data.conflicting_enrollments
          ? ClassesStore.getUseEnrollErrorMessage(
              PREMIUM_VT_SESSION_ERROR_STATES.conflicting_enrollments,
              t,
            )
          : successMessage,
      };
    } catch (e) {
      const errorMessage = e.response.data?.detail || '';

      this.submitProgress = {
        hasError: true,
        message: ClassesStore.getUseEnrollErrorMessage(errorMessage, t),
      };
    } finally {
      this.isShowASubmitProgress = true;
      this.submitting = false;
      return this.submitProgress;
    }
  };

  @action toggleSubmitProgressModal = () => {
    this.isShowASubmitProgress = !this.isShowASubmitProgress;
  };

  @action toggleSubscriptionExpiredModal = () => {
    this.showSubscriptionExpiredModal = !this.showSubscriptionExpiredModal;
  };

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.scheduledSessionsBySlug.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  @computed get totalPages() {
    return (
      Math.ceil(
        this.scheduledSessionsBySlug.length / this.entriesPerPage,
        10,
      ) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  set isRequestingSession(bool) {
    this._isRequestingSession = bool;
  }

  @computed get isRequestingSession() {
    return this._isRequestingSession;
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get isRescheduling() {
    return this.routerStore.route?.name === 'premium:sessions:reschedule';
  }

  @computed get reschedulingSessionId() {
    if (this.isRescheduling)
      return this.routerStore.route?.params.scheduledsessionid;

    return undefined;
  }
}

export default ScheduleSessionStore;
