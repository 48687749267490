import { action, observable, computed } from 'mobx';
import Axios from 'axios';
import moment from 'moment-timezone';
import { orderBy, chain, keyBy } from 'lodash';
import { getPremiumSessionCollateral } from 'services/PremiumSessionService';
import { PREMIUM_VT_SESSION_ERROR_STATES } from 'config/constants';
import ClassesStore from 'stores/Classes';
import { CONFIRMATION_DIALOGS } from './constants';

export const groupByDateFormat = 'YYYY-MM-DD HH:mm';

class LiveSessionsCalendarStore {
  @observable ready = false;

  @observable showTodaysPastSessions = false; // if true, shows today's past sessions

  @observable viewMode = 'day';

  // filters
  @observable selectedDate = new Date(); // controls which sessions to show

  @observable startTime = '';

  @observable endTime = '';

  @observable term = '';

  @observable isLoadingCollaterals = false;

  @observable sessionCollateralMap = null; // used to map collaterals to their respective sessions in computed values

  requestTokenSources = []; // used to abort ongoing http requests if they are no longer necessary

  constructor(
    catalogStore,
    premiumSessionsStore,
    premiumSessionFilterStore,
    classesStore,
  ) {
    this.catalogStore = catalogStore;
    this.premiumSessionsStore = premiumSessionsStore;
    this.premiumSessionFilterStore = premiumSessionFilterStore;
    this.classesStore = classesStore;
  }

  @action setViewMode = (viewMode) => {
    this.viewMode = viewMode;
  };

  @action setSelectedDate = (selectedDate) => {
    if (
      this.requestTokenSources.length > 0 &&
      selectedDate !== this.selectedDate
    ) {
      this.requestTokenSources.forEach((rs) => rs?.cancel?.());
    }

    this.selectedDate = selectedDate;
  };

  @action onNextSelectedDate = () => {
    this.setSelectedDate(moment(this.selectedDate).add(1, 'days').toDate());
  };

  @computed get canGoToPreviousDate() {
    const pastDate = moment(this.selectedDate).subtract(1, 'days');
    return pastDate.isSameOrAfter(moment(), 'd');
  }

  @action onPrevSelectedDate = () => {
    if (this.canGoToPreviousDate) {
      this.setSelectedDate(
        moment(this.selectedDate).subtract(1, 'days').toDate(),
      );
    }
  };

  @action setStartTime = (startTime) => {
    this.startTime = startTime;
  };

  @action setEndTime = (endTime) => {
    this.endTime = endTime;
  };

  @action setTerm = (term) => {
    this.term = term;
  };

  @action resetFilters = () => {
    this.startTime = '';
    this.endTime = '';
    this.term = '';
  };

  /**
   * load all session collaterals for the given session objects
   * @param {string} UILanguage current UI language which will be used to get the equivalent collateral document
   */
  @action loadSessionCollaterals = async (sessions, UILanguage) => {
    if (Array.isArray(sessions)) {
      const requestTokenSources = [];

      const requests = sessions.map((session) => {
        const requestSource = Axios.CancelToken?.source();
        requestTokenSources.push(requestSource);
        return getPremiumSessionCollateral(
          session.premvt_session_slug,
          UILanguage,
          true,
          'en-US', // fallback to english if fails
          requestSource?.token,
        );
      });

      this.requestTokenSources = requestTokenSources;

      let cancelled = false;

      try {
        this.isLoadingCollaterals = true;
        this.sessionCollateralMap = null;
        const responses = await Promise.allSettled(requests);
        const successful = responses
          .filter((r) => {
            if (r?.reason?.isCancel) {
              cancelled = true;
            }

            return r.status === 'fulfilled';
          })
          .map((r) => r.value);

        this.sessionCollateralMap = keyBy(successful, 'premvt_session_slug');
      } catch (e) {
        console.error(e);
        this.sessionCollateralMap = null;
      } finally {
        if (!cancelled) {
          this.isLoadingCollaterals = false;
        }
      }
    }
  };

  @action enrollUser = async (
    premiumVTSessionId,
    t,
    rescheduleSessionId = null,
  ) => {
    let result = {};
    try {
      let response;
      if (rescheduleSessionId) {
        response = await this.classesStore.enrollUser(
          premiumVTSessionId,
          rescheduleSessionId,
          true,
          true,
        );
      } else {
        response = await this.classesStore.enrollUser(premiumVTSessionId);
      }

      result = {
        hasError: !!response.data.conflicting_enrollments,
        message: response.data.conflicting_enrollments
          ? ClassesStore.getUseEnrollErrorMessage(
              PREMIUM_VT_SESSION_ERROR_STATES.conflicting_enrollments,
              t,
            )
          : 'successMessage',
      };
    } catch (e) {
      const errorMessage = e.response.data?.detail || '';

      result = {
        hasError: true,
        message: ClassesStore.getUseEnrollErrorMessage(errorMessage, t),
      };
    } finally {
      if (result.hasError) {
        throw new Error(result.message);
      }
      return result;
    }
  };

  @action cancelSession = async (enrollmentId) => {
    await this.classesStore.cancelPremVTUserEnrollment(enrollmentId);
  };

  @computed get isLoadingList() {
    return (
      !this.premiumSessionsStore.scheduledSessionsloaded ||
      !this.catalogStore.loaded
    );
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get timeZoneOffset() {
    const zoneName = moment.tz.guess();
    const timezone = moment.tz(zoneName).zoneAbbr();
    return `GMT ${timezone} | ${zoneName}`;
  }

  isSessionInSelectedDate = (session) => {
    if (!session) {
      return false;
    }

    const sessionStartTime = moment(session.start_time);

    const isSessionToday = sessionStartTime.isSame(
      moment(this.selectedDate),
      'day',
    ); // true if dates are in the same day.

    if (this.showTodaysPastSessions) {
      return isSessionToday;
    }

    return isSessionToday && sessionStartTime.isAfter(); // session is today AND begins in the future;
  };

  /**
   * returns true if session is between this.startTime and this.endTime
   * @param {object} session
   * @returns {boolean}
   */
  isSessionInTimeRange = (session) => {
    if (!session) {
      return false;
    }

    const formattedSelectedDate = moment(this.selectedDate).format(
      'YYYY-MM-DD',
    );

    if (
      this.startTime &&
      moment(session.start_time).isBefore(
        moment(
          `${formattedSelectedDate} ${this.startTime}`,
          'YYYY-MM-DD HH:mm a',
        ),
      )
    ) {
      return false;
    }

    if (
      this.endTime &&
      moment(session.end_time).isAfter(
        moment(
          `${formattedSelectedDate} ${this.endTime}`,
          'YYYY-MM-DD HH:mm a',
        ),
      )
    ) {
      return false;
    }

    return true;
  };

  /**
   * returns true if session title contains the string in this.term (ignoring case)
   * @param {object} sessionWithEntry
   * @returns {boolean}
   */
  isSessionMatchingTerm = (sessionWithEntry) => {
    if (!this.term) {
      return true;
    }

    try {
      const title =
        sessionWithEntry.collateral?.offering_title ||
        sessionWithEntry.relatedEntry?.title ||
        '';

      return (
        sessionWithEntry.offering_slug
          .toUpperCase()
          .includes(this.term.toUpperCase()) ||
        title?.toUpperCase().includes(this.term.toUpperCase())
      );
    } catch (e) {
      return false;
    }
  };

  /**
   * @typedef {Object} EnrollmentInfo
   * @property {string} availableAction - 'schedule', 'reschedule' or 'cancel'
   * @property {object} exactEnrollment - enrollment for same session EXACTLY in the same date/time and SAME premvt_session_uuid
   * @property {object} sideEnrollment - enrollment for same session, but not necessarily on the same date/time
   */

  /**
   * Given a session, returns info that provides enrollment context to the session. Only includes enrollments with state in ['enrolled', 'scheduled']
   * @param {object} session
   * @returns {EnrollmentInfo}
   */
  getEnrollmentInfo = (session) => {
    let availableAction = CONFIRMATION_DIALOGS.schedule;
    let sideEnrollment = null;
    let exactEnrollment = null;

    const offeringEnrollments =
      this.classesStore?.groupedPremiumSessionEnrollmentsByOffering?.[
        session.offering_slug
      ];

    if (Array.isArray(offeringEnrollments)) {
      sideEnrollment = offeringEnrollments.find((enrollment) => {
        // const enrollmentRelatedSession = this.sessionMapBydocId[
        //   enrollment.premvt_session_uuid
        // ];

        return (
          (enrollment.state === 'enrolled' ||
            enrollment.state === 'rescheduled') &&
          enrollment.premvt_session_slug === session.premvt_session_slug
          // && moment(enrollmentRelatedSession?.end_time).isAfter() // ignore enrollments that are bound to ended sessions
        );
      });
      exactEnrollment = offeringEnrollments.find(
        (enrollment) =>
          (enrollment.state === 'enrolled' ||
            enrollment.state === 'rescheduled') &&
          enrollment.premvt_session_uuid === session.doc_id,
      );

      if (sideEnrollment) {
        if (exactEnrollment) {
          // 'is this exact session (matching date/time)'
          availableAction = CONFIRMATION_DIALOGS.cancel;
        } else {
          availableAction = CONFIRMATION_DIALOGS.reschedule;
        }
      }
    }

    return { availableAction, exactEnrollment, sideEnrollment };
  };

  /**
   * Computed session list sorted by ascending start_time
   */
  @computed get orderedList() {
    const { scheduledPremiumSessions } = this.premiumSessionsStore;
    if (!scheduledPremiumSessions || !Array.isArray(scheduledPremiumSessions)) {
      return [];
    }

    const orderedList = orderBy(
      scheduledPremiumSessions,
      ['start_time', 'offering_slug', 'premvt_session_slug'],
      ['asc', 'asc', 'asc'],
    );

    return orderedList;
  }

  @computed get sessionMapBydocId() {
    return keyBy(this.orderedList, 'doc_id'); // fast access to
  }

  // not affected by side filters. Needed to get collateral info for sessions under selected date
  @computed get todaysSessions() {
    return this.orderedList.filter((session) => {
      return this.isSessionInSelectedDate(session);
    });
  }

  // not affected by side filters. Needed to get collateral info for sessions under selected date
  @computed get todaysSessionsIds() {
    return this.todaysSessions
      .map((s) => s.doc_id)
      .sort()
      .join('-');
  }

  /**
   * Computed session list with extra properties containing catalog_entry and session_collateral.
   */
  @computed get filteredSessionsWithCollateral() {
    const filteredList = this.todaysSessions.filter((session) => {
      return this.isSessionInTimeRange(session);
    });

    // apply related catalog entries to a key "relatedEntry"
    const sessionsWithEntries = filteredList.map((session) => {
      const relatedEntry = this.catalogStore.allCatalogEntries.find((entry) => {
        return entry.slug === session.offering_slug;
      });

      if (relatedEntry) {
        return { ...session, relatedEntry };
      }
      return session;
    });

    // apply related collaterals to a key "collateral"
    let sessionsWithCollateral = sessionsWithEntries;

    if (this.sessionCollateralMap) {
      sessionsWithCollateral = sessionsWithEntries.map((session) => {
        const enrollmentInfo = this.getEnrollmentInfo(session);
        return {
          ...session,
          collateral: this.sessionCollateralMap[session.premvt_session_slug],
          enrollmentInfo,
        };
      });
    }

    return sessionsWithCollateral.filter(
      (session) => this.isSessionMatchingTerm(session), // last step because it depends on catalog entry to filter by course name
    );
  }

  /**
   * Computed object where every key is a start_time and the value is an object containing a title and an array of sessions
   */
  @computed get sessionsGroupedByStartTime() {
    return chain(this.filteredSessionsWithCollateral)
      .groupBy((session) =>
        moment(session.start_time).format(groupByDateFormat),
      )
      .map((value, key) => ({
        key,
        title: moment(key, groupByDateFormat).format('LT'),
        items: value,
      }))
      .value();
  }
}

export default LiveSessionsCalendarStore;
