import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import { SyncLoader } from 'react-spinners';
import ErrorMessage from '../../Error/ComponentError';
import { handleError } from '../../../services/ErrorService';
import ConsumptionReportStore from './store';
import './styles.scss';

// FIXME: this report needs to obtain info about *all* consumption
// for a gived period. The fields in this report would stay the same,
// but the name of the report
const ConsumptionReport = () => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new ConsumptionReportStore());

  const generateReport = async () => {
    store.fetchReport();
  };

  const onNumberChange = (e) => {
    store.number = e.target.value;
  };

  const onOptionChange = (e) => {
    store.timeMeasurement = e.target.value;
  };

  return (
    <article className="consumption-report-wrapper">
      {store.errorMsgVisible && (
        <Alert bsStyle="danger">
          {t(`Couldn't fetch the page hits report:`)}&nbsp;
          {t(store.errorMessage)}
        </Alert>
      )}
      <Form onSubmit={generateReport}>
        <h4 className="input-bar-header">
          {t('Get the report for consumption over the period')}
        </h4>
        <FormGroup id="consumption-inputs">
          <div>
            <FormControl
              type="number"
              placeholder="3"
              onChange={onNumberChange}
              value={store.number}
            />
          </div>
          <div>
            <FormControl
              componentClass="select"
              onChange={onOptionChange}
              value={store.timeMeasurement}
            >
              {store.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FormControl>
          </div>
          <div className="submit-btn-container">
            <Button bsStyle="primary" onClick={generateReport}>
              {store.loading ? (
                <div className="loading">
                  <SyncLoader loading={store.loading} color="white" size={10} />
                </div>
              ) : (
                t('Generate a report')
              )}
            </Button>
          </div>
        </FormGroup>
      </Form>
      {store.successMsgVisible && (
        <Alert bsStyle="success">
          {t('Report generated. Please check your downloads.')}
        </Alert>
      )}
    </article>
  );
};

export default withErrorBoundary(
  observer(ConsumptionReport),
  ErrorMessage,
  handleError,
);
