import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Skeleton, Title } from '@patternfly/react-core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { storesContext } from 'stores';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import { UserAchievement } from 'types';
import skeletonImage from 'img/credly-badge-placeholder.png';

import './styles.scss';

const UserAchievementList = () => {
  const { t } = useTranslation();
  const { userStore } = useContext(storesContext);

  useEffect(() => {
    userStore.getUserAchievements();
  }, [userStore?.user?.uuid]);

  const badges = userStore.achievements as UserAchievement[];

  // get achievements from user store

  if (userStore.isLoadingAchievements) {
    return (
      <div className="achievement-list">
        <div className="achievement-list__item">
          <div className="achievement-list__card">
            <div className="achievement-list__card-skeleton">
              <img
                className="achievement-list__img"
                src={skeletonImage}
                alt={t('Badge loading')}
              />
              <span className="achievement-list__card-skeleton-msg">
                {t('Loading badge...')}
              </span>
            </div>
          </div>
          <div className="achievement-list__info">
            <div style={{ width: '100%' }}>
              <Skeleton
                width="100%"
                height="28px"
                style={{ marginBottom: 4 }}
              />
              <Skeleton width="70%" height="28px" />
            </div>

            <Skeleton width="50%" height="1.2rem" />
          </div>
        </div>
      </div>
    );
  }

  return !badges?.length ? (
    <Title size="lg" headingLevel="h4">
      {t('You have no achievements yet')}
    </Title>
  ) : (
    <div className="achievement-list">
      {badges.map((ua) => (
        <div key={ua.issued_at} className="achievement-list__item">
          <div className="achievement-list__card">
            <a
              className="achievement-list__link"
              href={ua.badge_url}
              target="_blank"
              rel="noreferrer"
              title={t('View achievement on Credly')}
              data-analytics-id="achievement-link-user-achievement-lp"
            >
              <img
                className="achievement-list__img"
                src={ua.badge_image_url}
                alt={t('Your achievement badge')}
              />
            </a>
          </div>
          <div className="achievement-list__info">
            <h4 className="achievement-list__title">{ua.title}</h4>
            <p className="achievement-list__date">
              {ua.issued_at ? moment(ua.issued_at).format('L') : ''}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default withErrorBoundary(
  observer(UserAchievementList),
  ComponentError,
  handleError,
);
