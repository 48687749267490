import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LabMaintenanceBanner = (props) => {
  const { timeframe, hours } = props;
  const { t } = useTranslation();
  return (
    <Alert bsStyle="warning" className="lab-maintenance-banner">
      <h4>
        <strong>{t('Scheduled Maintenance')}</strong>
      </h4>
      <p>
        {t(
          `We will have scheduled downtime on the labs in this course during {{timeframe}} for approximately {{hours}} hours. You may experience issues with labs during this time.`,
          { timeframe, hours },
        )}
      </p>
    </Alert>
  );
};

LabMaintenanceBanner.propTypes = {
  timeframe: PropTypes.string.isRequired,
  hours: PropTypes.number.isRequired,
};

export default LabMaintenanceBanner;
