/* eslint-disable no-dupe-class-members */
import { observable } from 'mobx';

class AdminManagerListStore {
  @observable selectedManager = {
    managerId: '',
    username: '',
  };

  @observable showDeleteModal = false;

  set selectedManager(params) {
    this.deleteManagerModal = params;
  }

  set showDeleteModal(value) {
    this.showDeleteModal = value;
  }
}

export default AdminManagerListStore;
