import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import ViewLoader from 'components/Loading/View';
import { storesContext } from '../../../stores';
import CatalogCard from '../../Catalog/Card';

const SearchResultsList = () => {
  const { searchStore } = useContext(storesContext);
  const { t } = useTranslation();

  const {
    currentPage,
    paginatedEntries,
    setCurrentPage,
    totalPages,
    finishedSearch,
  } = searchStore;

  return finishedSearch ? (
    <React.Fragment>
      {paginatedEntries.length ? (
        <React.Fragment>
          <div className="catalog__list">
            {paginatedEntries.map((entry) => {
              // const { doc_id: docId } = entry;
              return (
                <CatalogCard
                  course={entry}
                  key={shortid.generate()}
                  isSearchResult
                />
              );
            })}
          </div>
          <div className="catalog-list-pagination">
            <UltimatePagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={setCurrentPage}
            />
          </div>
        </React.Fragment>
      ) : (
        <p>{t('Please refine your search query or go to catalog')}</p>
      )}
    </React.Fragment>
  ) : (
    <ViewLoader color="#000000" />
  );
};

export default observer(SearchResultsList);
