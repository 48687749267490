import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import {
  InputGroup,
  FormControl,
  DropdownButton,
  MenuItem,
  Button,
} from 'react-bootstrap';
import { DotLoader } from 'react-spinners';
import './styles.scss';

@inject('userStore')
@withTranslation()
@observer
class VMRow extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    vmSummary: PropTypes.object.isRequired,
    vmAnnotations: PropTypes.object.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.menuOpen = false;
  }

  // Necessary so i18n extract can detect that the following terms need translation
  getVmStateLabel(state) {
    const { t } = this.props;
    switch (state) {
      case 'stopped':
        return t('Stopped');
      case 'active':
        return t('Active');

      default:
        return state;
    }
  }

  handleClick(command) {
    const vm = this.props.vmSummary;
    this.props.action(command, { vm_id: vm.id });
  }

  isInhibit(annotateName, vmName) {
    const { vmAnnotations } = this.props;
    const annotate = vmAnnotations.filter(
      (a) =>
        a.name === annotateName && a.vm_name === vmName && a.value === true,
    );

    return annotate.length > 0;
  }

  render() {
    const { t } = this.props;
    const vm = this.props.vmSummary;
    const { isInstructorOrAdmin } = this.props.userStore;
    const isInhibitConsole =
      this.isInhibit('inhibit_vnc_console', vm.name) && !isInstructorOrAdmin;
    const isInhibitReset =
      !this.isInhibit('allow_reset', vm.name) && !isInstructorOrAdmin;

    const isLoading = vm.vm_state !== 'active' && vm.vm_state !== 'stopped';
    return (
      <tr key={vm.id} id={`vm-${vm.id}`}>
        <td className="lab-controls-vm-panel-name">{vm.name}</td>
        <td className="lab-controls-vm-panel-actions">
          <InputGroup>
            <FormControl
              type="text"
              className="autostop-display"
              value={this.getVmStateLabel(vm.vm_state)}
              readOnly
            />
            <InputGroup.Addon>
              <DotLoader loading={isLoading} color="#999" size={15} />
            </InputGroup.Addon>
            <DropdownButton
              componentClass={InputGroup.Button}
              id="vm-action-dropdown"
              title={t('Action')}
              className="btn-secondary"
            >
              <MenuItem
                key="vm-action-start"
                disabled={vm.vm_state !== 'stopped'}
                onClick={() => this.handleClick('vm_start')}
              >
                {t('Start')}
              </MenuItem>
              <MenuItem divider />
              <MenuItem
                key="vm-action-shutdown"
                disabled={vm.vm_state !== 'active'}
                onClick={() => this.handleClick('vm_stop')}
              >
                {t('Shutdown')}
              </MenuItem>
              <MenuItem
                key="vm-action-poweroff"
                disabled={vm.vm_state === 'stopped'}
                onClick={() => this.handleClick('vm_poweroff')}
              >
                {t('Power Off')}
              </MenuItem>
              {!isInhibitReset && (
                <React.Fragment>
                  <MenuItem divider />
                  <MenuItem
                    key="vm-action-reset"
                    disabled={vm.vm_state === 'stopped'}
                    onClick={(e) => this.handleClick('vm_reset', e)}
                  >
                    {t('Reset')}
                  </MenuItem>
                </React.Fragment>
              )}
            </DropdownButton>
          </InputGroup>
        </td>
        {!isInhibitConsole && (
          <React.Fragment>
            <td className="lab-controls-vm-panel-console">
              <Button
                bsStyle="success"
                disabled={vm.vm_state !== 'active'}
                onClick={() => this.handleClick('get_vnc_url')}
              >
                {t('Open Console')}
              </Button>
            </td>
          </React.Fragment>
        )}
      </tr>
    );
  }
}

export default VMRow;
