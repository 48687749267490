import React, { useContext, useEffect, useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';

import { Table } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import TotalHourStore from './store';
import UserMetricsEmptyState from '../EmptyState';

const TotalHoursTable = () => {
  const { t, i18n } = useTranslation();
  const { organizationStore } = useContext(storesContext);
  const store = useLocalStore(() => new TotalHourStore(organizationStore));

  const columnDefinitions = useMemo(
    () => [
      {
        Header: t('Username'),
        accessor: 'username',
        id: 'username',
      },
      {
        Header: t('Total Training Hours'),
        accessor: 'count',
      },
    ],
    [i18n.language],
  );

  useEffect(() => {
    store.getReporteesHours(organizationStore.managerReportees);
  }, [organizationStore.managerReportees]);

  const renderEmptyState = () => {
    return (
      <UserMetricsEmptyState
        selectedCount={organizationStore.selectedUsersByManagerList?.length}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="table-horiz-scroll">
        <Table
          ariaLabel={t('Time spent studying table')}
          data={store.filteredData}
          columns={columnDefinitions}
          variant="compact"
          renderEmptyState={renderEmptyState}
        />
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(TotalHoursTable),
  GenericViewError,
  handleError,
);
