/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withErrorBoundary } from 'react-error-boundary';
import DataTable from '../../Datatable2';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../Error/GenericViewError';
import { storesContext } from '../../../stores';
import './styles.scss';

const OrganizationList = () => {
  const { t } = useTranslation();
  const { organizationStore } = useContext(storesContext);

  useEffect(() => {
    organizationStore.getAllOrganizations();
  }, []);

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: !organizationStore.editingOrganization.id,
    clickToSelect: true,
    onSelect: (org) => {
      organizationStore.editOrganization(org.doc_id);
    },
  };

  const columns = [
    {
      dataField: 'name',
      text: t('Company Name'),
      sort: true,
    },
    {
      dataField: 'admin_set',
      text: t('Admininistrators'),
      formatter: (admin_set) =>
        admin_set.map((u) => {
          if (u.preferred_name) {
            return (
              <small>
                {`${u.username} (${u.preferred_name})`}
                <br />
              </small>
            );
          }

          if (u.last_name) {
            return (
              <small>
                {`${u.username} (${u.first_name} ${u.last_name})`}
                <br />
              </small>
            );
          }

          return (
            <small>
              {`${u.username}`}
              <br />
            </small>
          );
        }),
    },
    {
      dataField: 'order_number_set',
      text: t('Order #s'),
      formatter: (order_number_set) =>
        order_number_set.map((o) => (
          <small>
            {o}
            <br />
          </small>
        )),
    },
  ];

  const pagination = paginationFactory({
    sizePerPage: 25,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
    ],
  });

  const rowEvents = {
    onClick: (e, org) => {
      organizationStore.editOrganization(org.doc_id);
    },
  };

  return (
    <React.Fragment>
      <h3>Users</h3>
      <DataTable
        classes="mgmt-admin"
        keyField="doc_id"
        data={organizationStore.organizations}
        columns={columns}
        pagination={pagination}
        rowEvents={rowEvents}
        selectRow={selectRow}
      />
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(OrganizationList),
  GenericViewError,
  handleError,
);
