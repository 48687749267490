import React, { useContext } from 'react';
import { Alert } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';

import './styles.scss';

function LimitedTrialLimitReachAlert() {
  const { t } = useTranslation();
  const { userStore } = useContext(storesContext);

  const used = userStore.numberTotalUsedPremiumSessions;
  const limit = userStore.currentSubscriptionPremiumVTLimit;

  return (
    <Alert
      className="enterprise-trial-limit-reached-alert"
      isInline
      variant="info"
      title={t('Upgrade to Premium for more sessions!')}
    >
      <p>
        {t(
          'You have reached the session limit ({{used}}/{{limit}}) for the Limited Trial subscription. Upgrade now and unlock the full potential of our Premium subscription!',
          {
            used,
            limit,
          },
        )}
      </p>
    </Alert>
  );
}

export default LimitedTrialLimitReachAlert;
