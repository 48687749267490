import { observable, action, computed } from 'mobx';
import {
  archiveUserNotification,
  deleteUserNotification,
  getUserNotification,
} from '../../../services/UserNotificationsService';

class UserNotificationsStore {
  @observable userNotifications = [];

  @observable errorNotifications = [];

  constructor(allPaneStore = {}) {
    this.allPaneStore = allPaneStore;
  }

  @computed get newUserNotifications() {
    return this.userNotifications.filter(
      (notification) => !notification.archived,
    );
  }

  @computed get userNotificationsToDisplay() {
    const { isArchivesChecked } = this.allPaneStore;

    return isArchivesChecked
      ? this.userNotifications
      : this.newUserNotifications;
  }

  @action onDeleteUserNotification = async (notificationId) => {
    const notification = this.userNotifications.find(
      (_notification) => _notification.uuid === notificationId,
    );
    try {
      await deleteUserNotification(notificationId);
    } catch (error) {
      this.errorNotifications.push(notification.uuid);
      console.error(error);
      return;
    }
    this.userNotifications = this.userNotifications.filter(
      (_notification) => _notification.uuid !== notificationId,
    );
  };

  @action onArchiveUserNotification = async (notificationId) => {
    const notification = this.userNotifications.find(
      (_notification) => _notification.uuid === notificationId,
    );
    try {
      await archiveUserNotification(notificationId);
    } catch (error) {
      this.errorNotifications.push(notification.uuid);
      console.error(error);
      return;
    }
    notification.archived = true;
  };

  @action getUserNotifications = async (userId) => {
    try {
      const userNotifications = await getUserNotification(userId);

      this.userNotifications = userNotifications.data.items;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
}

export default UserNotificationsStore;
