import { get } from 'axios';
import {
  TECH_OVERVIEW_API_URL,
  TECH_OVERVIEW_COLLATERAL_API_URL,
} from '../config/constants';

/*
const mockedTechOverview = {
  sku: 'ad123',
  doc_type: 'tech_overview',
  product: ['red_hat_ceph_storage'],
  uuid: 'd39f396a-6da6-4120-b5e6-b348c6718fcc',
  language: 'en-US',
  title: 'Sample Tech overview',
  track: ['unknown'],
  '@timestamp': '2020-03-20T15:18:00.416573Z',
  tech_overview_faq:
    'https://www.redhat.com/en/services/training/docs/expert-seminar-faq',
  slug: 'ad123-1.0',
  modality: 'tech_overview',
  kaltura: {
    videos: ['1_39cd8zad'],
  },
  version: '1',
  survey_url: 'http://app.keysurvey.com/f/1132472/4979/',
  player_id: '52219432',
  category: ['data_storage'],
  partner_id: '2032581',
  related_course: ['ceph125'],
  tech_overview: {
    presenter: 'Jean-Charles Lopez, Red Hat Storage Expert, Red Hat, Inc.',
    who_benefits:
      'Storage administrators, cloud operators, and cloud developers.',
    description:
      'The goal of this Expert Seminar is to configure two Ceph clusters to enable RBD Mirroring for disaster recovery scenarios. We will use two simple test clusters and demonstrate how to configure the one-way and two-way mirroring configurations as well as the two mirroring modes known as pool mode (mirror all RBD Images within a pool as they get created) and image mode (specifically enable mirroring for a particular RBD Image within a pool).',
    blurb:
      'In this Expert Seminar we will configure two Ceph clusters to enable RBD Mirroring for disaster recovery scenarios.',
    presenter_info: {
      username: ['jean-charles_lopez'],
      name: ['Jean-Charles Lopez'],
      description: ['Red Hat Storage Expert, Red Hat, Inc.'],
    },
  },
};


function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

*/

// eslint-disable-next-line
export const getTechOverview = (slug) => {
  return get(`${TECH_OVERVIEW_API_URL}${slug}/`).then((res) => res.data);
  // return Promise.resolve(mockedTechOverview);
  // return sleep(3000).then(() => {
  //   return mockedTechOverview;
  // });
};

// eslint-disable-next-line
export const getTechOverviewCollateral = (id) => {
  return get(`${TECH_OVERVIEW_COLLATERAL_API_URL}${id}/`).then(
    (res) => res.data,
  );
  // return Promise.resolve(mockedTechOverview);
  // return sleep(3000).then(() => {
  //   return mockedTechOverview;
  // });
};
