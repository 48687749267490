import { action, computed, observable, toJS } from 'mobx';

import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import intersectionBy from 'lodash/intersectionBy';
import moment from 'moment';
import * as merge from 'deepmerge';
import { PREMIUM_VT_SESSION_STATES } from '../config/constants';

const hasAnyFilterActive = (filters, filterKeys) => {
  return Boolean(
    flatMap(
      filterKeys.map((key) => filters[key].filter((filter) => filter.checked)),
    ).length,
  );
};

class PremiumSessionFilterStore {
  static renderFilters(
    values,
    vocabulary = [],
    filterKey = '',
    selectedValues = [],
  ) {
    const count = countBy(values);

    const filters = Object.keys(count).reduce((filtered, entry) => {
      const found = vocabulary?.find?.((item) => {
        return entry === item.token;
      });

      if (!found) return filtered;
      if (filterKey !== '') {
        filtered.push({
          key: found.token,
          value: found.display_name,
          count: count[found.token],
          checked: selectedValues.includes(found.token),
          ...(found.expression && { expression: found.expression }),
        });
      } else {
        filtered.push({
          key: found.token,
          value: found.display_name,
          count: count[found.token],
          checked: false,
          ...(found.expression && { expression: found.expression }),
        });
      }

      return filtered.sort((a, b) => b.count - a.count);
    }, []);

    return filters;
  }

  @observable loaded = false;

  @observable filters = {
    products: [],
    categories: [],
    instructors: [],
    languages: [],
    daterange: [
      {
        key: 'daterange1',
        value: {
          from: undefined,
          to: undefined,
        },
        count: 0,
        checked: false,
      },
    ],
    timerange: [
      {
        key: 'timerange1',
        value: {
          from: undefined,
          to: undefined,
        },
        count: 0,
        checked: false,
      },
    ],
    fullsessions: [
      {
        key: 'fullsessions',
        count: 0,
        checked: false,
      },
    ],
  };

  @observable expandedFilterGroups = new Set();

  @observable _filteredScheduledSessionEntries = [];

  @observable _filteredSessionContent = [];

  @observable filterQueryFunc = () => {};

  constructor(premiumSessionsStore, catalogStore) {
    this.premiumSessionsStore = premiumSessionsStore;
    this.catalogStore = catalogStore;
  }

  @computed get filterKeys() {
    return Object.keys(this.filters);
  }

  @computed get filteredEntries() {
    return hasAnyFilterActive(this.filters, this.filterKeys)
      ? this._filteredScheduledSessionEntries
      : this.premiumSessionsStore.premiumSessions;
  }

  @computed get filteredSessionContentEntries() {
    return hasAnyFilterActive(this.filters, this.filterKeys)
      ? this._filteredSessionContent
      : this.premiumSessionsStore.premiumContentWithScheduledSessions;
  }

  @action flattenFilters = (key) => {
    return this.filters[key]
      .filter((item) => item.checked)
      .map((item) => item.key);
  };

  @action filterByDateTimeRanges = (_filteredEntries) => {
    const range = this.filters.daterange[0]?.value;

    let startDateRange =
      range && range.from ? this.getStartDate(range.from) : moment();
    startDateRange = this.getStartDate(startDateRange);

    let endDateRange = range && range.to ? moment(range.to) : moment();
    endDateRange = this.getEndDate(endDateRange);

    const timerange = this.filters.timerange[0]?.value;
    const startTimeRange =
      timerange && timerange.from
        ? moment().set({
            hour: moment(timerange.from).get('hour'),
            minute: moment(timerange.from).get('minute'),
            second: moment(timerange.from).get('second'),
          })
        : this.getStartDate(moment());

    const endTimeRange =
      timerange && timerange.to
        ? moment().set({
            hour: moment(timerange.to).get('hour'),
            minute: moment(timerange.to).get('minute'),
            second: moment(timerange.to).get('second'),
          })
        : this.getEndDate(moment());

    const filteredByRanges =
      this.filters.daterange[0]?.checked || this.filters.timerange[0]?.checked
        ? _filteredEntries.filter((entry) => {
            const isBetweenDateRange = this.filters.daterange[0].checked
              ? this.isBetweenDateTimeRange(
                  entry.start_time,
                  startDateRange,
                  endDateRange,
                  'day',
                )
              : false;

            if (this.filters.timerange[0]?.checked) {
              const sessionStartTime = moment().set({
                hour: moment(entry.start_time).get('hour'),
                minute: moment(entry.start_time).get('minute'),
                second: moment(entry.start_time).get('second'),
              });

              const isBetweenTimeRange = this.isBetweenDateTimeRange(
                sessionStartTime,
                startTimeRange,
                endTimeRange,
                'hours',
              );

              return this.filters.daterange[0]?.checked
                ? isBetweenDateRange && isBetweenTimeRange
                : isBetweenTimeRange;
            }
            return isBetweenDateRange;
          })
        : _filteredEntries;
    return filteredByRanges;
  };

  getStartDate = (startDate) => {
    return moment(startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
  };

  getEndDate = (endDate) => {
    return moment(endDate).set({
      hour: 23,
      minute: 59,
      second: 59,
    });
  };

  isBetweenDateTimeRange = (dateToCompare, start, end, unit) => {
    return !(
      moment(dateToCompare).isBefore(start, unit) ||
      moment(dateToCompare).isAfter(end, unit)
    );
  };

  @action filterEntries = () => {
    const _filtered = this.premiumSessionsStore.premiumSessions.filter((s) => {
      return this.filterQueryFunc(s);
    });
    // filter instructors
    const sessionsFilteredByInstructors = _filtered.filter((entry) => {
      const instructors = this.flattenFilters('instructors');
      const filtered = entry.instructors?.filter((item) =>
        instructors.includes(item.username),
      );
      return entry?.instructors?.some((item) => filtered.includes(item));
    });

    // filter insturctor's languages.
    const filteredLanguages = _filtered.filter((entry) => {
      const languages = this.flattenFilters('languages');

      // eslint-disable-next-line camelcase
      return languages.includes(entry?.instruction_language);
    });

    const filters = [];
    if (sessionsFilteredByInstructors.length)
      filters.push(sessionsFilteredByInstructors);
    if (filteredLanguages.length) filters.push(filteredLanguages);

    let _filteredEntries = intersectionBy(...filters, 'doc_id');

    if (!filters.length) {
      _filteredEntries = this.premiumSessionsStore.premiumSessions;
    }

    _filteredEntries = this.hideFullSessions
      ? _filtered.filter((entry) => {
          // return sessions with available seats.
          return toJS(entry.seats_available) > 0;
        })
      : _filteredEntries;

    // Filter by date and time range
    this._filteredScheduledSessionEntries =
      this.filterByDateTimeRanges(_filteredEntries);

    // filter categories
    const categories = this.flattenFilters('categories');
    const filteredPremiumCategories =
      categories?.length > 0
        ? this.catalogPremiumEntries.filter((entry) => {
            const filtered = entry.categories?.filter((item) =>
              categories.includes(item),
            );
            return entry?.categories?.some((item) => filtered.includes(item));
          })
        : [];

    // _filteredSessionContent may contain multiple sessions
    let _filteredSessionContent =
      categories?.length > 0
        ? this.premiumSessionsStore.premiumContentWithScheduledSessions?.filter(
            (content) => {
              const splitOffering = content.offering_slug.split('-');

              return filteredPremiumCategories.some(
                (category) =>
                  category?.code === splitOffering[0] &&
                  category.version === splitOffering[1],
              );
            },
          ) || []
        : this.premiumSessionsStore.premiumContentWithScheduledSessions;

    // filter products
    const products = this.flattenFilters('products');
    const filteredPremiumProducts =
      products?.length > 0
        ? this.catalogPremiumEntries.filter((entry) => {
            const filtered = entry.products?.filter((item) =>
              products.includes(item),
            );
            return entry?.products?.some((item) => filtered.includes(item));
          })
        : [];
    _filteredSessionContent =
      products?.length > 0
        ? _filteredSessionContent?.filter((content) => {
            const splitOffering = content.offering_slug.split('-');

            return filteredPremiumProducts.some(
              (category) =>
                category?.code === splitOffering[0] &&
                category.version === splitOffering[1],
            );
          }) || []
        : _filteredSessionContent;

    // Only select session content i.e. premium clases that has some filtered scheduled sessions.
    // flatScheduledSessionDocIds contains only the ids of the sessions that fulfill the filters calculated above:
    const flatScheduledSessionDocIds = flatMap(
      this._filteredScheduledSessionEntries,
      'doc_id',
    );

    const hasFilter =
      this.filters.daterange[0]?.value?.from !== undefined ||
      this.filters.timerange[0]?.value?.from !== undefined ||
      sessionsFilteredByInstructors.length > 0 ||
      filteredLanguages.length > 0;

    this._filteredSessionContent = hasFilter
      ? _filteredSessionContent.filter((content) => {
          let hasAnySessionFulfillingFilters = false;

          if (content.scheduledSessions) {
            content.scheduledSessions = content.scheduledSessions.map(
              (session) => {
                const sessionScheduledState = [
                  PREMIUM_VT_SESSION_STATES.scheduled,
                ];
                const fulfillsActiveFilters =
                  sessionScheduledState.includes(session.state) &&
                  flatScheduledSessionDocIds.includes(session.doc_id);

                hasAnySessionFulfillingFilters =
                  hasAnySessionFulfillingFilters || fulfillsActiveFilters;

                // failsActiveFilters is true if a session does not pass the current filters
                return {
                  ...session,
                  failsActiveFilters: !fulfillsActiveFilters,
                };
              },
            );
          }

          return hasAnySessionFulfillingFilters;
        })
      : _filteredSessionContent;
  };

  // only catalogs that has premium classes
  @computed get catalogPremiumEntries() {
    return this.catalogStore.entries?.filter((course) => {
      const courseSlug = `${course.code}-${course.version}`;
      return this.premiumSessionsStore.premiumSessionsContent?.some(
        (p) => p.offering_slug === courseSlug,
      );
    });
  }

  @action getFilterInfo = async (
    i18n,
    t,
    vocabularyStore,
    filterQueryFunc = (x) => x,
  ) => {
    const { language } = i18n;
    const { getVocabularyByNamespace } = vocabularyStore;
    this.filterQueryFunc = filterQueryFunc;
    const searchResults = filterQueryFunc
      ? this.premiumSessionsStore.premiumSessions.filter((s) => {
          return filterQueryFunc(s);
        })
      : this.premiumSessionsStore.premiumSessions;

    // categories
    const categories = await getVocabularyByNamespace(
      'offering_categories',
      language,
    );

    this.filters.categories = PremiumSessionFilterStore.renderFilters(
      flatMap(this.catalogPremiumEntries, 'categories'),
      categories,
    );
    // products
    const products = await getVocabularyByNamespace(
      'offering_products',
      language,
    );

    this.filters.products = PremiumSessionFilterStore.renderFilters(
      flatMap(this.catalogPremiumEntries, 'products'),
      products,
    );

    const flatInstructors = flatMap(searchResults, 'instructors');
    const uniqueInstructors = flatInstructors?.map((i) => {
      return i.username;
    });

    const instructors = flatInstructors?.map((i) => {
      return { token: i.username, display_name: i.name };
    });
    this.filters.instructors = PremiumSessionFilterStore.renderFilters(
      uniqueInstructors,
      instructors,
      'instructors',
      this.flattenFilters('instructors'),
    );

    // Languages
    const flatLanguages = flatMap(searchResults, 'instruction_language');

    const languages = await getVocabularyByNamespace('languages', language);
    this.filters.languages = PremiumSessionFilterStore.renderFilters(
      flatLanguages,
      languages,
      'lanugages',
      this.flattenFilters('languages'),
    );

    const selectedDateRange =
      this.filters.daterange?.length > 0 ? this.filters.daterange[0] : [];

    this.filters.daterange = [
      {
        key: 'daterange1',
        value: {
          from: selectedDateRange?.value?.from || undefined,
          to: selectedDateRange?.value?.to || undefined,
        },
        count: 0,
        checked: selectedDateRange?.checked || false,
      },
    ];

    const selectedTimeRange =
      this.filters.timerange?.length > 0 ? this.filters.timerange[0] : [];

    this.filters.timerange = [
      {
        key: 'timerange1',
        value: {
          from: selectedTimeRange?.value?.from || undefined,
          to: selectedTimeRange?.value?.to || undefined,
        },
        count: 0,
        checked: selectedTimeRange?.checked || false,
      },
    ];

    return this.filters;
  };

  @computed get dateRange() {
    return this.filters.daterange[0]?.value;
  }

  @computed get timeRange() {
    return this.filters.timerange[0]?.value;
  }

  @action setDateRange = (range) => {
    this.filters.daterange[0].value = merge(
      toJS(this.filters.daterange[0].value),
      range || {},
    );
  };

  @action setTimeRange = (range) => {
    this.filters.timerange[0].value = merge(
      toJS(this.filters.timerange[0].value),
      range || {},
    );
  };

  @action resetCalendar = () => {
    this.filters.daterange[0].value.from = undefined;
    this.filters.daterange[0].value.to = undefined;
  };

  @action resetCalendarTime = () => {
    this.filters.timerange[0].value.from = undefined;
    this.filters.timerange[0].value.to = undefined;
  };

  clearFilters = (exceptions = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
          if (['daterange', 'timerange'].includes(key)) {
            item.value = { from: undefined, to: undefined };
          }
        }
      });
    });

    this.filterEntries();
  };

  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    // this.showFullSessions = false;

    this.filterEntries();
  };

  toggleFilterGroups = (id, expanded) => {
    return expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);
  };

  @computed get hideFullSessions() {
    return this.filters.fullsessions[0].checked;
  }
}

export default PremiumSessionFilterStore;
