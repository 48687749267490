import { action, observable } from 'mobx';
import { getPremiumSessionCollateral } from '../../../../../services/PremiumSessionService';
import { DEFAULT_LANGUAGE } from '../../../../../config/constants';

class SessionCardStore {
  @observable collateral = {};

  @observable loadingCollateral = false;

  @action getSessionCollateral = async (premVTSessionSlug, language) => {
    // If a collateral already exists
    if (this.collateral.doc_id) {
      return this.collateral;
    }

    let collateral;
    try {
      this.loadingCollateral = true;
      collateral = await getPremiumSessionCollateral(
        premVTSessionSlug,
        language,
        true,
      );
    } catch (error) {
      // if no collateral available in user's preferred language, fetch in English
      collateral = await getPremiumSessionCollateral(
        premVTSessionSlug,
        DEFAULT_LANGUAGE.replace('_', '-'),
        true,
      );
    } finally {
      if (collateral) {
        this.collateral = collateral;
      }
      this.loadingCollateral = false;
      return this.collateral;
    }
  };
}

export default SessionCardStore;
