/**
 * @file Provides summary of usage
 * @author Attila farkas <afarkas@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import {
  // Button,
  Grid,
  GridItem,
  Progress,
  ProgressMeasureLocation,
  // Tooltip,
} from '@patternfly/react-core';
// import VideoIcon from '@patternfly/react-icons/dist/esm/icons/video-icon';
import PageIcon from '@patternfly/react-icons/dist/esm/icons/book-open-icon';
import ClockIcon from '@patternfly/react-icons/dist/esm/icons/outlined-clock-icon';
import QuitIcon from '@patternfly/react-icons/dist/esm/icons/arrow-right-icon';
// import { InfoCircleIcon } from '@patternfly/react-icons';
import CountUp from 'react-countup';
import { useFeature } from '@ole-ui/ole-ui-components';
import { storesContext } from '../../../stores';
import { handleError } from '../../../services/ErrorService';
import ComponentError from '../../Error/ComponentError';
import './styles.scss';

const TopSummary = () => {
  const { t } = useTranslation();
  const { userStore, examsStore } = useContext(storesContext);
  const { subscription, subscriptionDaysLeft } = userStore;
  const {
    lab_hours: labHours,
    pdf_downloads: pdfDownloads,
    claims_consumed: claimsConsumed,
  } = subscription.enablements || {};

  const isBookshelfEnabled = useFeature({
    section: 'course',
    flagName: 'bookshelf_button_active',
    defaultFlagValue: false,
  });

  const capHours = () => {
    return Math.min(labHours?.allowed, labHours?.used);
  };

  const pageViews = () => {
    return userStore.usageSummary.pageViews
      ? userStore.usageSummary.pageViews
      : 0;
  };

  const allowedHours = () => {
    return userStore.totalAllowedLabHours;
  };

  return (
    <div className="dashboard-top-summary">
      <Grid hasGutter>
        {/* <GridItem span={12} lg={3} className="mini-card">
          <div className="card-data">
            <Tooltip
              aria-label="Basic tooltip"
              content={t(
                'Total number of videos you have started watching in the Learning Subscription',
              )}
              entryDelay={0}
              exitDelay={0}
            >
              <Button
                className="info-button"
                variant="plain"
                icon={
                  <InfoCircleIcon color="var(--pf-v5-global--info-color--100)" />
                }
              />
            </Tooltip>
            <div className="card-icon icon-1">
              <VideoIcon size="md" />
            </div>
            <div className="card-info">
              {!userStore.usageSummary ? (
                <p>{t('Loading content...')}</p>
              ) : (
                <React.Fragment>
                  <span className="bold-data">
                    <CountUp
                      start={0}
                      end={
                        userStore.usageSummary.videosWatched
                          ? userStore.usageSummary.videosWatched +
                            userStore.kalturaVideosWatched
                          : userStore.kalturaVideosWatched
                      }
                      duration={1}
                    />
                  </span>
                  <span>{t('Videos watched').toUpperCase()}</span>
                </React.Fragment>
              )}
            </div>
          </div>
        </GridItem> */}
        <GridItem span={12} lg={4} className="mini-card">
          <div className="flip-mini-card">
            <div className="flip-mini-card-inner">
              <div className="flip-mini-card-front">
                <div className="card-data">
                  <div className="card-icon icon-2">
                    <PageIcon size="md" />
                  </div>
                  <div className="card-info">
                    {userStore.usageSummary.pageViews ? (
                      <React.Fragment>
                        <span className="bold-data">
                          <CountUp start={0} end={pageViews()} duration={1} />
                        </span>
                        <span>{t('Pages read').toUpperCase()}</span>
                      </React.Fragment>
                    ) : (
                      t('Loading content...')
                    )}
                  </div>
                </div>
              </div>
              <div className="flip-mini-card-back">
                <p>{t('Pages opened within online training courses.')}</p>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem span={12} lg={4} className="mini-card">
          <div className="flip-mini-card">
            <div className="flip-mini-card-inner">
              <div className="flip-mini-card-front">
                <div className="card-data">
                  <div className="card-icon icon-3">
                    <ClockIcon size="md" />
                  </div>
                  <div className="card-info">
                    {!userStore.usageSummary ? (
                      <p>{t('Loading content...')}</p>
                    ) : (
                      <React.Fragment>
                        <span className="bold-data">
                          <CountUp
                            start={0}
                            end={
                              userStore.usageSummary.hoursSpent
                                ? userStore.usageSummary.hoursSpent
                                : 0
                            }
                            duration={1}
                          />
                        </span>
                        <span>{t('Training hours').toUpperCase()}</span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="flip-mini-card-back">
                <p className="scaled-text">
                  {t(
                    'Time spent interacting with learning materials, excluding lab time.',
                  )}
                </p>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem span={12} lg={4} className="mini-card">
          <div className="flip-mini-card">
            <div className="flip-mini-card-inner">
              <div className="flip-mini-card-front">
                <div className="card-data">
                  <div className="card-icon icon-4">
                    <QuitIcon size="md" />
                  </div>
                  <div className="card-info">
                    {userStore.usageSummary.lastLogin ? (
                      <React.Fragment>
                        <span className="bold-data">
                          {userStore.usageSummary.lastLogin}
                        </span>
                        <span>{t('Last login').toUpperCase()}</span>
                      </React.Fragment>
                    ) : (
                      t('Loading content...')
                    )}
                  </div>
                </div>
              </div>
              <div className="flip-mini-card-back">
                <p>
                  {t('The last time you accessed the Learning Subscription.')}
                </p>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem span={12} lg={6}>
          <div className="medium-card">
            <div className="flip-card-exam">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="medium-card-data">
                    <div className="medium-card-info-exam">
                      <div className="medium-card-display-exam">
                        {!examsStore?.exams ? (
                          t('Loading content...')
                        ) : (
                          <>
                            <div className="exam-data-wrapper">
                              <div className="exam-data-holder">
                                <span>{t('SCHEDULED').toUpperCase()}</span>
                                <span className="exam-data bold-data">
                                  {examsStore.examStatus.scheduled.length}
                                </span>
                              </div>
                              <div className="exam-data-holder">
                                <span>{t('UNSCHEDULED').toUpperCase()}</span>
                                <span className="exam-data bold-data">
                                  {examsStore.examStatus.unscheduled.length}
                                </span>
                              </div>
                              <div className="exam-data-holder">
                                <span>{t('CANCELLED').toUpperCase()}</span>
                                <span className="exam-data bold-data">
                                  {examsStore.examStatus.cancelled.length}
                                </span>
                              </div>
                              <div className="exam-data-holder">
                                <span>{t('COMPLETED').toUpperCase()}</span>
                                <span className="exam-data bold-data">
                                  {examsStore.examStatus.completed.length}
                                </span>
                              </div>
                            </div>
                            <div className="exam-data-wrapper">
                              <div className="exam-data-holder">
                                <span>
                                  {t('RETAKE AVAILABLE').toUpperCase()}
                                </span>
                                <span className="exam-data bold-data">
                                  {
                                    examsStore.examStatus.retake_available
                                      .length
                                  }
                                </span>
                              </div>
                              <div className="exam-data-holder">
                                <span>
                                  {t('RETAKE SCHEDULED').toUpperCase()}
                                </span>
                                <span className="exam-data bold-data">
                                  {
                                    examsStore.examStatus.retake_scheduled
                                      .length
                                  }
                                </span>
                              </div>
                              <div className="exam-data-holder">
                                <span>
                                  {t('RETAKE COMPLETE').toUpperCase()}
                                </span>
                                <span className="exam-data bold-data">
                                  {examsStore.examStatus.retake_complete.length}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="medium-card-bottom">
                        {t('Exams').toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flip-card-back">
                  <p>
                    {t(
                      'This is the status of all exams that may be included with your current subscription.',
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem span={12} lg={3}>
          <div className="medium-card">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="medium-card-data">
                    <div className="medium-card-info">
                      <div className="medium-card-split-half-display">
                        {!(pdfDownloads || isBookshelfEnabled) ? (
                          t('Loading content...')
                        ) : (
                          <div className="pdf-downloads-wrapper">
                            <div className="pdf-downloads-icon-wrapper">
                              <span className="medium-nr">
                                {isBookshelfEnabled
                                  ? claimsConsumed || 0
                                  : pdfDownloads?.used || 0}
                              </span>
                            </div>
                            <div className="course-book-downloads-text-wrapper">
                              {t('E-BOOKS CLAIMED')}
                            </div>
                          </div>
                        )}
                        <div className="lab-hours-summary-wrapper">
                          {!labHours?.used && !allowedHours() ? (
                            t('Loading content...')
                          ) : (
                            <>
                              <span className="inline-count-up bold-data">
                                {labHours?.used
                                  ? t(
                                      labHours?.used > allowedHours()
                                        ? `${allowedHours()}`
                                        : `${Math.round(labHours?.used)}`,
                                    )
                                  : t('N/A')}
                                /{t(`${allowedHours()}`)}
                              </span>
                              <Progress
                                value={capHours()}
                                max={allowedHours()}
                                measureLocation={ProgressMeasureLocation.none}
                              />
                              {t('Lab hours').toUpperCase()}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flip-card-back">
                  <p>
                    {t(
                      'The total amount of lab hours and course book downloads that were used in this subscription period.',
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
        <GridItem span={12} lg={3}>
          <div className="medium-card">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="medium-card-data">
                    <div className="medium-card-info">
                      <div className="medium-card-display">
                        {!userStore.isAlaCarte &&
                          (!subscriptionDaysLeft ? (
                            t('Loading content...')
                          ) : (
                            <p className="giant-nr">
                              {t(`${subscriptionDaysLeft}`)}
                            </p>
                          ))}
                        {userStore.isAlaCarte && (
                          <p>
                            <span>{t('See individual courses')}</span>
                          </p>
                        )}
                      </div>
                      <div className="medium-card-bottom">
                        {t('Subscription days left').toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flip-card-back">
                  <p>
                    {t(
                      'The total number of days remaining on this subscription.',
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};
export default withErrorBoundary(
  observer(TopSummary),
  ComponentError,
  handleError,
);
