import { computed, observable } from 'mobx';

class CertificateOfAttendanceButtonStore {
  @observable _certificateOfAttendanceModalVisibility = false;

  @computed get certificateOfAttendanceModalVisibility() {
    return this._certificateOfAttendanceModalVisibility;
  }

  set certificateOfAttendanceModalVisibility(visible) {
    this._certificateOfAttendanceModalVisibility = visible;
  }
}

export default CertificateOfAttendanceButtonStore;
