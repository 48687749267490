/* eslint-disable no-dupe-class-members */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { mapValues, toUpper } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { action, computed, observable, toJS, runInAction } from 'mobx';

import { getKalturaVideosWatched } from 'services/UserService';
import {
  getManagers,
  getUsers,
  assignManager,
  addManager,
  deleteManager,
  acceptPendingManager,
  rejectPendingManager,
  getOrganizations,
  getAllOrganizations,
  getManagerReportees,
  setManagerState,
  addOrganization,
  deleteOrganization,
  updateOrganization,
  getOrganizationSummary,
  getUserSubscriptionsByOrganizationIds,
  getCustomSkillPaths,
  getConsumptionTimeSeries,
  getCourseCompletionTimeSeries,
  getExamCompletionTimeSeries,
} from 'services/ManagementReportingService';

import { createReportSearch } from 'services/ReportingService';
import { postNewJob } from 'services/EventService';

const initialSummaryState = {
  total_courses: 0,
  completed_courses: 0,
  vc_courses: 0,
  rol_courses: 0,
  attended_sessions: 0,
  exams: {
    scheduled: 0,
    unscheduled: 0,
    canceled: 0,
    completed: 0,
    retake_available: 0,
    retake_scheduled: 0,
    retake_results_pending: 0,
    retake_complete: 0,
  },
  training_hours: 0,
  lab_hours: 0,
  average_completion_hours: 0,
};

class OrganizationStore {
  @observable progressPerCourseFilters = {};

  @observable progressPerCourseTextFilter = '';

  @observable managers = [];

  @observable isLoadingManagers = false;

  @observable users = [];

  @observable isLoadingUsers = false;

  @observable managerReportees = [];

  @observable selectedUsersInManagerDB = new Set(); // For user list on manager dashboard

  @observable selectedUsersInAdminDB = new Set(); // For user list on admin dashboard

  @observable organization = { name: '', id: '', isPending: false };

  @observable isLoadingOrganizations = true;

  @observable organizations = [];

  @observable didFailToFetchOrganizations = false;

  @observable customSkillPaths = [];

  @observable customSkillPathsLoaded = false;

  @observable overallCourseProgress = [];

  @observable kalturaVideosWatched = 0;

  @observable managerProgressFilter = '';

  @observable editingOrganization = {
    id: null,
    name: '',
    orderNumbers: '',
    adminUsers: '',
  };

  @observable _modal = {
    opened: false,
    title: '',
    text: '',
    actionText: 'OK',
    onAction: () => {
      this._modal.opened = false;
    },
  };

  @observable deleteManagerModal = {
    opened: false,
    managerId: '',
    username: '',
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.catalogStore = rootStore.catalogStore;
    this.uiStore = rootStore.uiStore;
  }

  @action setManagerProgressFilter(typed) {
    this.managerProgressFilter = typed;
  }

  @observable entriesFetched = false;

  @observable hasCatalogData = false;

  @observable addHover = (offering) => {
    return (
      <OverlayTrigger
        placement="top"
        trigger={['hover']}
        overlay={<Tooltip id="locked-tooltip">{offering.tooltipText}</Tooltip>}
      >
        <span>{offering.text}</span>
      </OverlayTrigger>
    );
  };

  @observable summaryType = 'all';

  @observable isUsageSummaryLoading = true;

  @observable allSubscriptionsUsageSummary = {
    ...initialSummaryState,
  };

  @observable currentSubscriptionsUsageSummary = {
    ...initialSummaryState,
  };

  @observable customDateRangeSubscriptionsUsageSummary = {
    ...initialSummaryState,
  };

  @observable customDateFromDate = null;

  @observable customDateToDate = null;

  @observable totalHoursTimeSeries = null;

  @observable isTotalHoursTSLoading = true;

  @observable courseCompletionTimeSeries = null;

  @observable isCourseCompletionTSLoading = true;

  @observable isExamCompletionTSLoading = true;

  @observable examCompletionTimeSeries = null;

  @computed get usageSummary() {
    return this[`${this.summaryType}SubscriptionsUsageSummary`];
  }

  @action newModal = (modal) => {
    this._modal.opened = true;
    this._modal.title = modal.title;
    this._modal.text = modal.text;
    this._modal.actionText = modal.actionText;
    this._modal.onAction = modal.onAction;
  };

  @computed get modal() {
    return this._modal;
  }

  @action closeModal = () => {
    this._modal.opened = false;
  };

  @action setProgressPerCourseFilters = (filters) => {
    this.progressPerCourseFilters = filters;
  };

  @action setProgressPerCourseTextFilter = (text) => {
    this.progressPerCourseTextFilter = text;
  };

  @action setManagerState = async (managerId, toggleState) => {
    let updatedManager;
    let oldState;
    try {
      this.managers.forEach((manager) => {
        if (manager.uuid === managerId) {
          // Track these in case we need to revert on error
          updatedManager = manager;
          oldState = manager.state;
          runInAction(() => {
            manager.state = toggleState;
          });
        }
      });
      await setManagerState(this.organization.id, managerId, toggleState);
    } catch (err) {
      if (updatedManager) {
        runInAction(() => {
          updatedManager.state = oldState;
        });
      }
      console.error(err);
    }
  };

  @computed get managerList() {
    if (!this.managers || this.managers.length === 0) {
      return [];
    }
    return toJS(this.managers);
  }

  @computed get userList() {
    if (!this.users || this.users.length === 0) {
      return [];
    }
    return toJS(this.users);
  }

  @computed get selectedUsersByManagerList() {
    if (
      !this.selectedUsersInManagerDB ||
      this.selectedUsersInManagerDB.length === 0
    ) {
      return [];
    }

    return [...this.selectedUsersInManagerDB];
  }

  findOrganizationById = (id) => {
    return this.organizations.find((org) => org.id === id);
  };

  isAdminOfOrg(organization) {
    const currentUser = this.rootStore.userStore.user;

    return Boolean(
      organization?.admin_set?.some((o) => o.uuid === currentUser?.uuid),
    );
  }

  @computed get isAdminOfCurrentlySelectedOrg() {
    return this.isAdminOfOrg(this.organization);
  }

  isManagerOfOrg(organization) {
    const currentUser = this.rootStore.userStore.user;

    return Boolean(
      organization?.manager_set?.some((o) => o.uuid === currentUser?.uuid),
    );
  }

  @computed get isManagerOfCurrentlySelectedOrg() {
    return this.isManagerOfOrg(this.organization);
  }

  @computed get isLeaderOfCurrentlySelectedOrg() {
    return (
      this.isAdminOfCurrentlySelectedOrg || this.isManagerOfCurrentlySelectedOrg
    );
  }

  @action getManagers = async () => {
    try {
      this.isLoadingManagers = true;
      if (this.organization.id) {
        const managerSet = await getManagers(this.organization.id);
        this.setManagers(managerSet.map(this.normalizeManager));
      } else {
        this.setManagers([]);
      }
    } catch (err) {
      console.error('Could not get managers: ', err);
      // TODO Would be nice to show an alert here
    } finally {
      this.isLoadingManagers = false;
    }
  };

  @action getUserByUsername = (username) => {
    return this.managerReportees.find(
      (element) => element.username.toLowerCase() === username.toLowerCase(),
    );
  };

  // This should be used to cleanup properies on each manager
  @action normalizeManager = (manager) => {
    if (!manager.preferred_name) {
      manager.preferred_name = `${manager.first_name || ''} ${
        manager.last_name || ''
      }`.trim();
    }
    return manager;
  };

  @action.bound setManagers = (managerList) => {
    this.managers = managerList;
  };

  @action.bound setUsers = (userList) => {
    this.users = userList;
  };

  @action retryLoadingOrganizations = async () => {
    const orgs = await this.getOrganizations();
    this.setOrganization();
    return orgs;
  };

  @action init = async () => {
    try {
      this.isLoadingOrganizations = true;
      const orgs = await this.getOrganizations();
      this.setOrganization();
      return orgs;
    } catch (error) {
      if (error) {
        console.error(error);
      }
      this.organizations = [];
      // what else could we do when this fails?
      return this.organizations;
    } finally {
      this.isLoadingOrganizations = false;
    }
  };

  @action setOrganization = (docId) => {
    this.summaryType = 'all';
    if (!docId && this.organizations?.length > 0) {
      this.setOrganization(this.organizations[0].doc_id);
    } else {
      this.organizations.forEach((org) => {
        if (org.doc_id === docId) {
          this.organization = org;
          this.organization.id = org.doc_id;
          this.organization.isPending = org.is_pending || false;
        }
      });
    }
  };

  @action addManager = async (username, email) => {
    try {
      await addManager(username, email, this.organization.id);
    } catch (err) {
      console.error('Could not add manager: ', err);
    }
  };

  @action deleteManager = async (uuid) => {
    try {
      await deleteManager(uuid, this.organization.id);
      const remainingManagers = this.managers.filter((manager) => {
        return manager.uuid !== uuid;
      });
      runInAction(() => {
        this.setManagers(remainingManagers);
      });
    } catch (err) {
      console.error('Could not delete manager: ', err);
      // TODO Would be nice to show an alert here
    }
  };

  @action addSelectedUserSubscription = (uuid) => {
    this.selectedUsersInAdminDB.add(uuid);
  };

  @action removeSelectedUserSubscription = (uuid) => {
    this.selectedUsersInAdminDB.delete(uuid);
  };

  @action addAllSelectedUsersSubscriptions = (users) => {
    users.forEach((user) => this.selectedUsersInAdminDB.add(user));
  };

  @action removeAllSelectedUsersSubscriptions = () => {
    this.selectedUsersInAdminDB.clear();
  };

  @action getUsers = async () => {
    try {
      this.isLoadingUsers = true;
      if (this.organization.id) {
        const userSet = await getUsers(this.organization.id);
        this.setUsers(userSet);
      } else {
        this.setUsers([]);
      }
    } catch (err) {
      console.error('Could not get users: ', err);
      // TODO Would be nice to show an alert here
    } finally {
      this.isLoadingUsers = false;
    }
  };

  @action setSelectedUserForManager = (items) => {
    this.selectedUsersInManagerDB = new Set(items);
  };

  @action addSelectedUserForManager = (uuid) => {
    this.selectedUsersInManagerDB.add(uuid);
  };

  @action removeSelectedUserForManager = (uuid) => {
    this.selectedUsersInManagerDB.delete(uuid);
  };

  @action addAllSelectedUsersForManager = (users) => {
    users.forEach((user) => this.selectedUsersInManagerDB.add(user));
  };

  @action removeAllSelectedUsersForManager = () => {
    this.selectedUsersInManagerDB.clear();
  };

  @action assignManager = async (managerId, usersToAssign) => {
    await assignManager(this.organization.id, usersToAssign);
    runInAction(() => {
      this.users.forEach((user) => {
        if (usersToAssign.assign.users.includes(user.doc_id)) {
          const oldManager = this.managers.find((manager) => {
            return user.org_manager_id === manager.uuid;
          });

          if (oldManager !== undefined) {
            oldManager.reportees -= 1;
          }
          user.org_manager_id = managerId;
        }
      });
      // Update managers to reflect new users
      const newManager = this.managers.find((manager) => {
        return managerId === manager.uuid;
      });
      if (newManager !== undefined) {
        newManager.reportees += usersToAssign.assign.users.length;
      }
    });
  };

  @action getReportees = async () => {
    const reportees = await getManagerReportees();
    this.managerReportees = reportees
      .filter((subscription) =>
        [...this.organization.order_number_set].includes(
          subscription.lms_order_number,
        ),
      )
      .sort((a, b) => a.username.localeCompare(b.username));
  };

  @action addManager = async (username, email) => {
    try {
      await addManager(username, email, this.organization.id);
    } catch (err) {
      console.error('Could not accept manager invitation: ', err);
      // TODO Would be nice to show an alert here
    }
  };

  @action deleteManager = async (uuid) => {
    try {
      await deleteManager(uuid, this.organization.id);
      const remainingManagers = this.managers.filter((manager) => {
        return manager.uuid !== uuid;
      });
      runInAction(() => {
        this.setManagers(remainingManagers);
      });
    } catch (err) {
      throw err;
    }
  };

  @action acceptPendingManager = async (uuid) => {
    try {
      await acceptPendingManager(uuid, this.organization.id);
      runInAction(() => {
        this.organization.isPending = false;
      });
    } catch (err) {
      throw err;
    }
  };

  @action rejectPendingManager = async (uuid) => {
    try {
      await rejectPendingManager(uuid, this.organization.id);
    } catch (err) {
      console.error('Could not reject manager invitation: ', err);
      // TODO Would be nice to show an alert here
    }
  };

  @action getOrganizations = async () => {
    try {
      this.isLoadingOrganizations = true;
      const { uuid } = this.rootStore.userStore.user;
      if (uuid) {
        const organizations = await getOrganizations(uuid);
        this.organizations = organizations.managing;
        this.rootStore.userStore.myOrganizations = organizations.reporting;
      }
      this.didFailToFetchOrganizations = false;
    } catch (err) {
      this.organizations = [];
      this.didFailToFetchOrganizations = true;
      throw err;
    } finally {
      this.isLoadingOrganizations = false;
    }
  };

  @action getAllOrganizations = async () => {
    try {
      const organizations = await getAllOrganizations();
      this.organizations = organizations.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
    } catch (err) {
      throw err;
    }
  };

  @computed get organizationsList() {
    return this.organizations;
  }

  @action addOrganization = async (organizationData) => {
    try {
      await addOrganization(organizationData);
      await this.getAllOrganizations();
    } catch (err) {
      throw err;
    }
  };

  @action updateOrganization = async (uuid, data) => {
    try {
      await updateOrganization(uuid, data);
      setTimeout(() => {
        this.getAllOrganizations();
      }, 5000);
    } catch (err) {
      throw err;
    }
  };

  @action deleteOrganization = async (uuid) => {
    try {
      await deleteOrganization(uuid);
      setTimeout(() => {
        this.getAllOrganizations();
      }, 5000);
    } catch (err) {
      throw err;
    }
  };

  @action editOrganization = (uuid) => {
    this.organizations.forEach((org) => {
      if (org.doc_id === uuid) {
        this.editingOrganization.id = uuid;
        this.editingOrganization.name = org.name;
        this.editingOrganization.orderNumbers = org.order_number_set.join('\n');
        this.editingOrganization.adminUsers = org.admin_set
          .map((a) => a.username)
          .join('\n');
      }
    });
  };

  @action resetEditOrganization = () => {
    this.editingOrganization.id = null;
    this.editingOrganization.name = '';
    this.editingOrganization.orderNumbers = '';
    this.editingOrganization.adminUsers = '';
  };

  @computed get newOrgPayload() {
    const name = this.editingOrganization.name.trim();
    const orderNumbers = this.editingOrganization.orderNumbers
      .split(/[\n,]+/)
      .map((a) => a.trim().toLowerCase().replaceAll('\u200b', ''))
      .filter((a) => a.length);
    const adminUsers = this.editingOrganization.adminUsers
      .split(/[\n,]+/)
      .map((a) => a.trim().toLowerCase())
      .filter((a) => a.length);

    return {
      name,
      orderNumbers,
      adminUsers,
    };
  }

  @computed get hasPendingOrganizations() {
    return (
      this.organizations?.filter((org) => {
        return org.is_pending;
      }).length >= 1
    );
  }

  /**
   * Attaches the respective catalog_entry to each courseProgress object.
   * @returns {Array} An array of courseProgress objects with the catalog_entry attached.
   */
  @computed get userProgressPerCourses() {
    const referenceCatalog = this.catalogStore?.allCatalogEntries;

    const data =
      this.usageSummary.user_progress_per_course?.map((courseProgress) => {
        const catalogEntry = referenceCatalog?.find(
          (entry) =>
            entry.slug === courseProgress.id ||
            entry.code === courseProgress.id,
        );

        const courseTitle = catalogEntry?.title
          ? `${catalogEntry?.title}`
          : toUpper(courseProgress.id);

        return {
          ...courseProgress,
          course_title: courseTitle,
          catalog_entry: catalogEntry,
        };
      }) || [];
    return data;
  }

  @computed get progressPerCoursesWithNames() {
    const flattenFilters = (filters) => {
      if (!filters) return [];
      return Object.keys(filters).reduce(
        (acc, key) => (filters[key] ? [...acc, key] : acc),
        [],
      );
    };

    const filtersByType = {
      categories: flattenFilters(this.progressPerCourseFilters.categories),
      products: flattenFilters(this.progressPerCourseFilters.products),
      modalities: flattenFilters(this.progressPerCourseFilters.modalities),
    };

    let filteredList = this.catalogStore.__getFilteredEntries(
      this.userProgressPerCourses,
      filtersByType,
      (item) => item.catalog_entry,
      'id',
    );

    if (
      this.progressPerCourseTextFilter &&
      this.progressPerCourseTextFilter.length > 0
    ) {
      const lowerCaseFilter = this.progressPerCourseTextFilter.toLowerCase();
      filteredList = filteredList.filter((row) => {
        return (
          row.course_title?.toLowerCase().includes(lowerCaseFilter) ||
          row.id?.toLowerCase().includes(lowerCaseFilter)
        );
      });
    }

    // TODO: Include a way to filter "Include courses with lesson activity"

    return filteredList;
  }

  @computed get filteredManagerProgress() {
    const lowerCaseFilter =
      this.managerProgressFilter?.toLocaleLowerCase() || '';

    if (lowerCaseFilter === '')
      return this.usageSummary.progress_per_manager || [];

    return (
      this.usageSummary.progress_per_manager?.filter((row) => {
        return (
          row.manager_name?.toLowerCase().includes(lowerCaseFilter) ||
          row.manager_email?.toLowerCase().includes(lowerCaseFilter) ||
          row.manager_username?.toLowerCase().includes(lowerCaseFilter)
        );
      }) || []
    );
  }

  @action generateManagerReport = (format) => {
    format = format || 'csv';

    createReportSearch({
      organizationId: this.organization.id,
      role: 'managers',
      pull_all: true,
      report_type: 'by_user',
    }).then((searchId) => {
      postNewJob('reporting', `course-reporting-${format}`, {
        searchId,
      });
    });
  };

  @action generateAdminReport = (format) => {
    format = format || 'csv';

    createReportSearch({
      organizationId: this.organization.id,
      role: 'admins',
      report_type: 'by_user',
      pull_all: true,
    }).then((searchId) => {
      postNewJob('reporting', `course-reporting-${format}`, {
        searchId,
      });
    });
  };

  @action async getSummary(
    i18n,
    vocabularyStore,
    fieldName,
    summaryOf,
    dateRange,
  ) {
    const { getVocabularyMapTokensByNamespace } = vocabularyStore;
    const { language } = i18n;
    const data = await getOrganizationSummary(
      this.organization.id,
      this.summaryType,
      summaryOf,
      dateRange,
    );

    const getProductName = await getVocabularyMapTokensByNamespace(
      language,
      'offering_products',
    );
    const getCategoryDisplayName = await getVocabularyMapTokensByNamespace(
      language,
      'offering_categories',
    );

    if (data && data.categories) {
      data.categories = mapValues(data.categories, (value, key) => ({
        value,
        label: getCategoryDisplayName(key),
      }));
    }

    if (data && data.products) {
      data.products = mapValues(data.products, (value, key) => ({
        value,
        label: getProductName(key),
      }));
    }

    this[fieldName] = { ...this[fieldName], ...data };
  }

  @action getUsageSummary = async (
    i18n,
    vocabularyStore,
    dateRange,
    fetchTrainingHours,
    fetchCompletedCourses,
    fetchCompletedExams,
  ) => {
    const fieldName = `${this.summaryType}SubscriptionsUsageSummary`;

    this[fieldName] = {
      ...initialSummaryState,
    };
    this.isUsageSummaryLoading = true;

    this.getSummary(i18n, vocabularyStore, fieldName, 'course', dateRange);
    const summariesList = [
      'exams',
      'lab_hours',
      'attended_sessions',
      'training_hours',
      'student_activity',
    ];
    summariesList.forEach((summaryOf) => {
      // for current dashboard summary, getting exams and course summary details
      // from course summary request only as course and exams summary are dependent
      if (this.summaryType === 'current' && summaryOf === 'exams') {
        return;
      }
      this.getSummary(i18n, vocabularyStore, fieldName, summaryOf, dateRange);
    });

    if (fetchTrainingHours) {
      this.getTrainingHoursTimeSeries(this.organization.id, dateRange);
    }
    if (fetchCompletedCourses) {
      this.getCourseCompletionTimeSeries(this.organization.id, dateRange);
    }

    if (fetchCompletedExams) {
      this.getExamCompletionTimeSeries(this.organization.id, dateRange);
    }

    this.getKalturaVideosWatched(this.organization.id, dateRange);

    this.isUsageSummaryLoading = false;
  };

  @action switchUsageSummary = async (
    i18n,
    vocabularyStore,
    summaryType,
    dateRange, // assumes dateRange is only defined for customDateRange
    fetchTrainingHours,
    fetchCompletedCourses,
    fetchCompletedExams,
  ) => {
    this.summaryType = summaryType;
    if (summaryType === 'customDateRange') {
      // set custom date range params
      this.customDateFromDate = dateRange.from;
      this.customDateToDate = dateRange.to;
    } else {
      // reset custom date range params
      this.customDateFromDate = null;
      this.customDateToDate = null;
    }

    try {
      await this.getUsageSummary(
        i18n,
        vocabularyStore,
        dateRange,
        fetchTrainingHours,
        fetchCompletedCourses,
        fetchCompletedExams,
      );
    } catch (e) {
      console.error(e);
    }
  };

  @action filteredOrganizations = (userRole) => {
    return this.organizations.filter((org) => {
      return org[userRole === 'admin' ? 'admin_set' : 'manager_set']
        .map((user) => {
          return user.username;
        })
        .includes(this.rootStore.userStore.user?.username);
    });
  };

  @action getOrganizationName(organizationId) {
    if (!this.organizations.length) return '';
    const _found = this.organizations?.find((o) => o.doc_id === organizationId);
    return _found ? _found.name : '';
  }

  @action getUsersByOrgIdsAndSubscription = async (
    organizationIds = [],
    subscriptionCode = '',
  ) => {
    try {
      if (organizationIds?.length > 0) {
        const dataToSend = {
          org_ids: organizationIds,
          ...(subscriptionCode !== '' && { subscription: subscriptionCode }),
        };

        const userList = await getUserSubscriptionsByOrganizationIds(
          dataToSend,
        );

        return userList;
      }
      return [];
    } catch (err) {
      console.error(
        '[getUsersByOrgIdsAndSubscription] Could not get users for orgids: ',
        err,
      );
      return [];
    }
  };

  @action getCustomSkillPaths = async (organizationId, force = false) => {
    try {
      if ((force || !this.customSkillPathsLoaded) && organizationId) {
        this.customSkillPathsLoaded = false;
        const customSkillPaths = await getCustomSkillPaths(organizationId);
        this.customSkillPaths = customSkillPaths;
      }
    } catch (err) {
      this.customSkillPaths = [];
      console.error(
        '[getCustomSkillPaths] Could not get customskillpats for a orgid: ',
        err,
      );
    } finally {
      this.customSkillPathsLoaded = true;
      return this.customSkillPaths;
    }
  };

  @action getKalturaVideosWatched = async (organizationId, dateRange) => {
    try {
      this.kalturaVideosWatched = await getKalturaVideosWatched(
        organizationId,
        this.summaryType,
        dateRange,
      );
    } catch {
      this.kalturaVideosWatched = 0;
    }
    return this.kalturaVideosWatched;
  };

  @action getTrainingHoursTimeSeries = async (organizationId, dateRange) => {
    try {
      this.isTotalHoursTSLoading = true;
      const chartData = await getConsumptionTimeSeries(
        organizationId,
        this.summaryType,
        dateRange,
      );

      this.totalHoursTimeSeries = chartData;
    } catch (e) {
      console.error(e);
    } finally {
      this.isTotalHoursTSLoading = false;
    }
  };

  @action getCourseCompletionTimeSeries = async (organizationId, dateRange) => {
    try {
      this.isCourseCompletionTSLoading = true;
      const chartData = await getCourseCompletionTimeSeries(
        organizationId,
        this.summaryType,
        dateRange,
      );

      this.courseCompletionTimeSeries = chartData;
    } catch (e) {
      console.error(e);
    } finally {
      this.isCourseCompletionTSLoading = false;
    }
  };

  @action getExamCompletionTimeSeries = async (organizationId, dateRange) => {
    try {
      this.isExamCompletionTSLoading = true;
      const chartData = await getExamCompletionTimeSeries(
        organizationId,
        this.summaryType,
        dateRange,
      );

      this.examCompletionTimeSeries = chartData;
    } catch (e) {
      console.error(e);
    } finally {
      this.isExamCompletionTSLoading = false;
    }
  };
}

export default OrganizationStore;
