import { observable, action, computed } from 'mobx';

import { getUserProgressForACustomSkillPath } from '../../../services/ManagementReportingService';

class MgmtCustomSkillPathsStore {
  @observable usersInPath = [];

  @observable _filteredUsersInPath = [];

  @observable usersInPathLoaded = false;

  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable userSearchQuery = '';

  @observable sortUserByField = 'username';

  @observable isSortUserAscending = true;

  @observable selectedSkillPathId = null;

  constructor(organizationStore) {
    this.organizationStore = organizationStore;
  }

  @computed get selectedSkillPath() {
    return this.selectedSkillPathId
      ? this.organizationStore.customSkillPaths?.filter(
          (c) => c.code === this.selectedSkillPathId,
        )
      : {};
  }

  roundProgress = (data) => {
    return data !== null ? Math.round(data * 100) : 0;
  };

  buildUserInPath = (userWithPath) => {
    return userWithPath
      ? {
          ...userWithPath,
          username: userWithPath.user.username || '',
          name: userWithPath.user.first_name
            ? // eslint-disable-next-line camelcase
              `${userWithPath?.user?.first_name} ${
                // eslint-disable-next-line camelcase
                userWithPath?.user?.last_name
              }`
            : userWithPath.user.preferred_name || '',
          // eslint-disable-next-line camelcase
          email: userWithPath?.user.default_email || '',

          // eslint-disable-next-line camelcase
          progress: this.roundProgress(userWithPath?.user_progress),
        }
      : null;
  };

  @action getUserProgressForACustomSkillPath = async (
    orgId,
    customSkillPathId,
  ) => {
    try {
      if (customSkillPathId && orgId) {
        this.usersInPathLoaded = false;
        let usersInPath = await getUserProgressForACustomSkillPath(
          orgId,
          customSkillPathId,
        );

        usersInPath = usersInPath?.map((userWithPath) =>
          this.buildUserInPath(userWithPath),
        );
        this.usersInPath = usersInPath;
        this.selectedSkillPathId = customSkillPathId;
      }
    } catch (err) {
      this.usersInPath = [];
      console.error(
        '[getUserProgressForACustomSkillPath] Could not get customskillpaths  user progress for a customskillpathid: ',
        err,
      );
    } finally {
      this.filterUsers();
      this.usersInPathLoaded = true;

      return this.usersInPath;
    }
  };

  @action searchUsers = (searchFor) => {
    let _searchResults = this.usersInPath;

    if (!searchFor.length) {
      return _searchResults;
    }

    _searchResults = _searchResults.filter((user) => {
      return (
        user.username.toLowerCase().includes(searchFor.toLowerCase()) ||
        user.name.toLowerCase().includes(searchFor.toLowerCase()) ||
        user.email.toLowerCase().includes(searchFor.toLowerCase())
      );
    });

    return _searchResults;
  };

  @action sortUsers = (users) => {
    if (!this.isSortUserAscending) {
      return users
        .slice()
        .sort((a, b) =>
          b[this.sortUserByField].localeCompare(a[this.sortUserByField]),
        );
    }

    return users
      .slice()
      .sort((a, b) =>
        a[this.sortUserByField].localeCompare(b[this.sortUserByField]),
      );
  };

  @action filterUsers = () => {
    const _filteredUsers = this.searchUsers(this.userSearchQuery);

    this._filteredUsersInPath = this.sortUsers(_filteredUsers);

    return this._filteredUsersInPath;
  };

  @action onSearchUsers = (searchFor) => {
    this.userSearchQuery = searchFor.trim();
    this.filterUsers();
  };

  @computed get filteredUsers() {
    // return this.userSearchQuery ? this._filteredUsersInPath : [];
    return this._filteredUsersInPath;
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return Math.ceil(this.filteredUsers.length / this.entriesPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedUserEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.filteredUsers.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }
}

export default MgmtCustomSkillPathsStore;
