/* eslint-disable camelcase */

import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { storesContext } from 'stores';
import { TRUSTARC_SCRIPT_URL, TRUSTARC_LIBS_DEF } from 'config/constants';

import './styles.scss';

const Trustarc = () => {
  const { configStore, userStore } = useContext(storesContext);

  const event = new Event('DOMContentLoadedReact');

  const syncConsents = () => {
    // we don't know when TrustArc will be initialized so we need to poll
    setTimeout(() => {
      if (window.PrivacyManagerAPI) {
        ['functional', 'advertising'].forEach((t) => {
          const result = window.PrivacyManagerAPI.callApi(
            'getConsent',
            'rol.redhat.com',
            null,
            null,
            t,
          );

          userStore.privacyConsents[t] = result.consent === 'approved';
        });
      } else {
        syncConsents();
      }
    }, 1000);
  };

  // when we're notified by the MutationObserver that the DOM changed, each node
  // is passed to this function which will check if there was any iframe element
  // loaded (those are TrustArc's targets). This method is recursive, meaning
  // that if the node has children, it will call itself on the children as well
  const checkNodeForIframes = (node) => {
    if (node && node.tagName && node.tagName.toLowerCase() === 'iframe') {
      // if we have a match, dispatch the event that will trigger trustarc
      dispatchEvent(event);
    } else if (node.childNodes.length) {
      node.childNodes.forEach((subnode) => {
        checkNodeForIframes(subnode);
      });
    }
  };

  const mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.addedNodes.length) {
        mutation.addedNodes.forEach((node) => {
          checkNodeForIframes(node);
        });
      }
    });
  });

  mutationObserver.observe(document.documentElement, {
    subtree: true,
    childList: true,
  });

  useEffect(() => {
    if (
      configStore.config.OLE_APP_DEPLOYMENT &&
      TRUSTARC_SCRIPT_URL[configStore.config.OLE_APP_DEPLOYMENT]
    ) {
      const script = document.createElement('script');
      script.src = TRUSTARC_SCRIPT_URL[configStore.config.OLE_APP_DEPLOYMENT];
      script.id = 'trustarc';
      document.head.appendChild(script);

      TRUSTARC_LIBS_DEF.forEach((def) => {
        const obj = document.createElement(def.type);
        Object.keys(def.attr).forEach((attr) => {
          if (attr === 'data-domain' && configStore.isChina) {
            return;
          }
          obj.setAttribute(attr, def.attr[attr]);
        });
        document.head.appendChild(obj);
      });

      syncConsents();
    }
  }, [configStore.config.OLE_APP_DEPLOYMENT]);

  return (
    <React.Fragment>
      <span
        data-analytics-id="rh-cookie-preferences-footer-lp"
        id="teconsent"
      />
      <div id="consent_blackbar" />
    </React.Fragment>
  );
};

export default observer(Trustarc);
