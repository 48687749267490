/* eslint-disable react/no-this-in-sfc */
import React, { useContext } from 'react';
import { SyncLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  ButtonToolbar,
  Button,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import shortid from 'shortid';
import { storesContext } from 'stores';
import { useFeature } from '@ole-ui/ole-ui-components';
import LabInstructions from './LabInstructions';
import LabAlert from './LabAlert';
import LabInfo from '../LabInfo';
import { labEnvironmentContext } from '../store';
import LabErrorModal from '../LabErrorModal';
import './styles.scss';

const LabControls = (props) => {
  const { t } = useTranslation();
  const { userStore } = useContext(storesContext);

  const canCreateOnStates = ['ready', 'deleted'];

  const renderButtons = () => {
    const LabButton = (
      command,
      activeState,
      workingState,
      title,
      modalConfirmation,
      analyticsId,
    ) => {
      let state = null;
      if (props.labStore) {
        if (activeState.when.includes(props.labStore.labState)) {
          state = Object.assign({ status: 'active' }, activeState);
        } else if (workingState.when.includes(props.labStore.labState)) {
          state = Object.assign({ status: 'working' }, workingState);
        }
      }

      let isFeatureEnabled = useFeature({
        section: 'lab',
        flagName: command,
        defaultFlagValue: true,
      });

      if (state !== null) {
        const isLabControlDisabled =
          !userStore.hasLabHours ||
          state.status !== 'active' ||
          (canCreateOnStates.includes(props.labStore.labState) &&
            !props.labStore.canLabStart);

        isFeatureEnabled =
          command === 'launch' && !isLabControlDisabled
            ? true
            : isFeatureEnabled;
      }
      return state !== null && isFeatureEnabled ? (
        <Button
          key={command}
          data-analytics-id={analyticsId}
          className={[
            state.btnClass,
            'has-spinner',
            state.status === 'working' ? 'spinner-active' : '',
          ].join(' ')}
          title={title}
          disabled={
            !userStore.hasLabHours ||
            state.status !== 'active' ||
            (canCreateOnStates.includes(props.labStore.labState) &&
              !props.labStore.canLabStart)
          }
          onClick={() =>
            modalConfirmation
              ? props.labStore.createNewModal(
                  {
                    ...modalConfirmation,
                    confirmFunc: () => {
                      props.labStore.doCommand(command);
                      props.labStore.hideModal();
                    },
                  },
                  true,
                )
              : props.labStore.doCommand(command)
          }
        >
          <div className="spinner-wrapper">
            <SyncLoader size={8} color="#6a6e73" />
          </div>
          {state.label}
        </Button>
      ) : null;
    };

    const LabTemplateSelector = () => {
      return props.labStore.availableTemplates.length &&
        props.labStore.labState === 'ready' ? (
        <DropdownButton
          bsStyle="success"
          title={props.labStore.currentTemplate.title}
          key={shortid.generate()}
          id={shortid.generate()}
        >
          {props.labStore.availableTemplates.map((template, i) => (
            <MenuItem
              key={template.child_offering_slug || template.id}
              onClick={() => {
                props.labStore.setCurrentTemplate(i);
              }}
            >
              {template.title}
            </MenuItem>
          ))}
        </DropdownButton>
      ) : null;
    };

    return [
      LabTemplateSelector(),
      LabButton(
        'launch',
        {
          when: ['ready', 'deleted'],
          label: t('Create'),
          btnClass: 'btn-secondary',
        },
        {
          when: ['requested', 'creating', 'installing', 'transitioning'],
          label: t('Creating'),
          btnClass: 'btn-greyed',
        },
        t('Create the lab'),
        undefined,
        'create-vt-dash-lp',
      ),
      LabButton(
        'app_delete',
        {
          when: ['running', 'stopped', 'failed', 'starting', 'stopping'],
          label: t('Delete'),
          btnClass: 'btn-danger',
        },
        { when: ['deleting'], label: t('Deleting'), btnClass: 'btn-greyed' },
        t('Delete the lab'),
        {
          title: t('Are you sure you want to delete your lab?'),
          dismissText: t('Cancel'),
          confirmText: t('Delete'),
        },
        'delete-vt-dash-lp',
      ),
      LabButton(
        'app_start',
        { when: ['stopped'], label: t('Start'), btnClass: 'btn-secondary' },
        {
          when: [
            'requested',
            'creating',
            'installing',
            'transitioning',
            'starting',
          ],
          label: t('Starting'),
          btnClass: 'btn-greyed',
        },
        t('Start the lab'),
        undefined,
        'start-vt-dash-lp',
      ),
      LabButton(
        'app_stop',
        { when: ['running'], label: t('Stop'), btnClass: 'btn-secondary' },
        { when: ['stopping'], label: t('Stopping'), btnClass: 'btn-greyed' },
        t('Stop the lab'),
        {
          title: t('Are you sure you want to stop your lab?'),
          dismissText: t('Cancel'),
          confirmText: t('Stop'),
        },
        'stop-vt-dash-lp',
      ),
      props.labStore.labState !== 'ready' ? (
        <labEnvironmentContext.Provider value={props.labStore}>
          {props.labStore.labState ? (
            <div className="lab-info-icons-container">
              <LabInfo />
              {props.labStore.labState === 'failed' &&
                (userStore.isAdmin || userStore.isInstructor) && (
                  <LabErrorModal labStore={props.labStore} />
                )}
            </div>
          ) : null}
        </labEnvironmentContext.Provider>
      ) : null,
    ];
  };

  return (
    <React.Fragment>
      <div data-ole-role="lab-controls">
        <labEnvironmentContext.Provider value={props.labStore}>
          <LabInstructions lab={props.labStore} user={userStore} />
        </labEnvironmentContext.Provider>
        {!props.labStore.canLabStart ? (
          <LabAlert alertType={props.labStore.reasonLabCantStart} />
        ) : null}
        <ButtonToolbar>{renderButtons()}</ButtonToolbar>
      </div>
    </React.Fragment>
  );
};

LabControls.propTypes = {
  labStore: PropTypes.object,
};

LabControls.defaultProps = {
  labStore: {},
};

export default observer(LabControls);
