import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';
import { SyncLoader } from 'react-spinners';
import Dropzone from 'react-dropzone';
import ErrorMessage from '../../Error/ComponentError';
import { handleError } from '../../../services/ErrorService';
import UserListReportStore from './store';
import arrowDown from '../../../img/icons/arrow-down.svg';
import './styles.scss';

const UserListReport = () => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new UserListReportStore());

  const generateReport = async () => {
    await store.fetchReport();
  };

  return (
    <article className="user-list-report-wrapper">
      {store.errorMsgVisible && (
        <Alert bsStyle="danger">
          {t(`Couldn't fetch the user list report:`)}&nbsp;
          {t(store.errorMessage)}
        </Alert>
      )}
      {store.CSVInputVisible && (
        <Dropzone accept="text/csv" onDrop={store.parseCSVFile}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="csv-input">
              <input {...getInputProps()} />
              <img src={arrowDown} className="drop-icon" alt="arrow down" />
              <p>{t('Drop your CSV file here')}</p>
              <p>
                {t(
                  'The username field is expected to be in the first column of every row. No header columns, please.',
                )}
              </p>
            </div>
          )}
        </Dropzone>
      )}
      {store.usernames.length ? (
        <section>
          <strong>{t('Generate a report for the following users:')}</strong>
          <p>{store.usernames.join(', ')}</p>
          <Button bsStyle="primary" onClick={generateReport}>
            {store.loading ? (
              <div className="loading">
                <SyncLoader loading={store.loading} color="white" size={10} />
              </div>
            ) : (
              t('Generate a report')
            )}
          </Button>
          <Button onClick={store.resetInput}>{t('Reset')}</Button>
        </section>
      ) : null}
      {store.successMsgVisible && (
        <Alert bsStyle="success">
          {t('Report generated. Please check your downloads.')}
        </Alert>
      )}
    </article>
  );
};

export default withErrorBoundary(
  observer(UserListReport),
  ErrorMessage,
  handleError,
);
