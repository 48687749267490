import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../services/ErrorService';
import Form from '../../JSONSchemaForm';
import DateTimeWidget from '../../JSONSchemaForm/DateTimeWidget';
import ComponentError from '../../Error/ComponentError';
import { alertFormContext } from '../../../views/Alert/Edit/store';
// TODO: enable when language will be used
import './styles.scss';

const AlertForm = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const store = useContext(alertFormContext);
  // TODO: enable when language will be used
  // const { vocabularyStore } = useContext(storesContext);

  useEffect(() => {
    const createForm = async () => {
      store.schema = {
        type: 'object',
        required: [
          'title',
          'description',
          'priority',
          'environment',
          'start_time',
          'end_time',
          // TODO: enable when language will be used
          // 'language',
        ],
        properties: {
          enabled: {
            title: t('Enabled?'),
            type: 'boolean',
          },
          '@timestamp': {
            title: t('Timestamp'),
            type: 'string',
            format: 'date-time',
          },
          title: {
            title: t('Title'),
            type: 'string',
            maxLength: 255,
          },
          description: {
            title: t('Description'),
            type: 'string',
          },
          // TODO: enable deployment options when env recognition is implemented
          // deployment: {
          //   title: t('Deployment'),
          //   type: 'string',
          //   enum: ['prod', 'rhu'],
          //   enumNames: ['ROL-Prod', 'RHU'],
          // },
          environment: {
            title: t('Environment'),
            type: 'array',
            minItems: 1,
            items: {
              type: 'string',
              enum: [],
              enumNames: [],
            },
            uniqueItems: true,
          },
          subscriptions: {
            title: t('Subscriptions (leave blank to show for all)'),
            type: 'array',
            items: {
              type: 'string',
              enum: [],
              enumNames: [],
            },
            uniqueItems: true,
          },
          // TODO: enable when language will be used
          // language: {
          //   title: t('Notification language'),
          //   type: 'string',
          //   enum: [],
          //   enumNames: [],
          // },
          priority: {
            title: t('Alert type'),
            type: 'string',
            enum: ['info', 'warning', 'success', 'danger'],
            enumNames: [
              'Info (blue)',
              'Warning (yellow)',
              'Success (green)',
              'Danger (red)',
            ],
          },
          start_time: {
            title: t('Start Date/Time'),
            type: 'string',
            format: 'datetime',
          },
          end_time: {
            title: t('End Date/Time'),
            type: 'string',
            format: 'datetime',
          },
          uuid: {
            title: t('UUID'),
            type: 'string',
          },
        },
      };

      store.uiSchema = {
        uuid: {
          'ui:widget': 'hidden',
        },
        '@timestamp': {
          'ui:widget': 'hidden',
        },
        environment: {
          'ui:widget': 'checkboxes',
        },
        description: {
          'ui:widget': 'textarea',
        },
        subscriptions: {
          'ui:widget': 'checkboxes',
        },
        start_time: {
          'ui:widget': DateTimeWidget,
        },
        end_time: {
          'ui:widget': DateTimeWidget,
        },
      };

      // TODO: enable when language will be used
      // await store.populateLanguageOptions(vocabularyStore, language);
      await store.populateCourseOptions();
      await store.populateSubscriptionOptions();
    };

    createForm();
  }, []);

  return (
    <React.Fragment>
      <Form
        disabled={store.loading}
        noHtml5Validate
        showErrorList={false}
        schema={toJS(store.schema)}
        uiSchema={toJS(store.uiSchema)}
        formData={store.formData}
        onSubmit={onSubmit}
        onChange={(form) => {
          store.formData = form.formData;
        }}
      >
        <div>
          <Button bsStyle="primary" type="submit">
            {t('Submit')}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

AlertForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withErrorBoundary(
  observer(AlertForm),
  ComponentError,
  handleError,
);
