import { observable } from 'mobx';
import {
  CERTIFICATE_OF_ATTENDANCE_RHLC_URL,
  CERTIFICATE_OF_ATTENDANCE_PREMIUM_RHLC_URL,
  CERTIFICATE_OF_ATTENDANCE_DOWNLOAD_API,
} from '../../../config/constants';
import {
  generateCertificate,
  generateCertificateForPremium,
} from '../../../services/CertificateService';

class CertificateofAttendanceModalStore {
  @observable user = {
    first_name: '',
    last_name: '',
    preferred_name: '',
  };

  @observable confirmModal = {
    opened: false,
  };

  @observable redirectModal = {
    opened: false,
  };

  @observable loading = false;

  @observable generateCertificateInProgress = false;

  @observable hasErrorOnGenerateCertificate = false;

  async generateCertificate(
    courseSlug,
    timestamp,
    forPremium,
    skipRhlcForCoa = false,
  ) {
    const params = {
      name: this.user.preferred_name,
      course: courseSlug,
      username: this.user?.username,
      ...(timestamp && { timestamp }),
    };

    try {
      const uuid = forPremium
        ? await generateCertificateForPremium(params)
        : await generateCertificate(params);

      const RHLCCertUrl = forPremium
        ? CERTIFICATE_OF_ATTENDANCE_PREMIUM_RHLC_URL
        : CERTIFICATE_OF_ATTENDANCE_RHLC_URL;

      if (skipRhlcForCoa) {
        return window.open(
          CERTIFICATE_OF_ATTENDANCE_DOWNLOAD_API.replace('<uuid>', uuid),
        );
      }

      return window.open(`${RHLCCertUrl}?course=${courseSlug}&uuid=${uuid}`);
    } catch (error) {
      throw new Error(error);
    }
  }

  updateUserProfile(user = this.user, userStore) {
    return new Promise((resolve, reject) => {
      const data = {
        preferred_name: user.preferred_name,
      };

      userStore
        .partiallyUpdateUserRecord(data)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }
}

export default CertificateofAttendanceModalStore;
