/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/**
 * @file Provides utility methods to support interacting with user APIs
 * @author Joshua Jack <jjack@redhat.com>
 */

import { get, patch, post } from 'axios';
// import moment from 'moment-timezone';
import {
  SAPI_BASE,
  CURRENT_USER_RECORD_API,
  USERS_API,
  USER_GROUPS_API,
  USER_RECORD_API,
  USER_SUBSCRIPTION_API,
  USERINFO_API_URL,
} from '../config/constants';

/**
 * Get a user by their username or email
 * @param {string} search - Group name
 * @return {Object} A user object
 */
// TODO: USERS_API is being deprecated - this needs to be added to the existing
// /sapi/rest/users list API
export const getUserByUsernameOrEmail = (
  search,
  ignoreEmail,
  organizationId,
) => {
  const params = {
    q: search,
    ignore_email: ignoreEmail,
    organizationId,
  };

  return get(`${USERS_API}`, { params })
    .then((response) => response.data.data.map((record) => record.attributes))
    .catch((error) => {
      throw new Error(error);
    });
};

/**
 * Returns a collection of users in a group
 * @param {string} group - Offering ID
 * @return {Object} A collection containing user data
 */
export const getUsersInGroup = (group) =>
  new Promise((resolve, reject) => {
    get(USER_GROUPS_API.replace('<group>', group))
      .then((response) => resolve(response.data?.items))
      .catch((error) => reject(error));
  });

/**
 * Gets user groups for a particular user based on UUID
 * @return {Array} An array of strings
 */
export const getUserGroups = async (uuid) => {
  if (!uuid) {
    throw new Error('User UUID is required');
  }

  try {
    const groups = await get(`${USER_RECORD_API}${uuid}/groups`);
    return groups.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getActiveSubscription = (uuid) => {
  return get(USER_SUBSCRIPTION_API.replace('<uuid>', uuid))
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * Returns user avatar as image source
 * @param {string} uuid - User UUID
 * @return {string} A string containing image data
 */
export const getUserAvatar = async (uuid) => {
  if (!uuid) {
    throw new Error('User UUID is required');
  }

  try {
    const avatar = await get(`${USER_RECORD_API}${uuid}/avatar`, {
      responseType: 'arraybuffer',
    });
    const image = Buffer.from(avatar.data, 'binary').toString('base64');
    return image ? `data:image/jpeg;base64, ${image}` : null;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Sets user avatar
 * @param {string} uuid - User UUID
 * @param {string} data - Image data to upload, null to reset
 * @return {boolean} - True if updated successfully, false otherwise
 */
export const postUserAvatar = async (uuid, image) => {
  if (!uuid) {
    throw new Error('User UUID is required');
  }

  try {
    let data = {};

    if (image) {
      data = new FormData();
      data.append('avatar', image);
    }

    const response = await post(`${USER_RECORD_API}${uuid}/avatar`, data);
    return response.status === 200;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Returns user record for the currently logged-in user
 * @return {Object} An object containing user data
 */
export const getCurrentUserRecord = async () => {
  try {
    const user = await get(CURRENT_USER_RECORD_API);
    return user.data;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Returns user record by username
 * @param {string} uuid - User UUID
 * @return {Object} A collection containing user data
 */
export const getUserRecordByUsername = async (username) => {
  if (!username) {
    throw new Error('Username is required');
  }

  try {
    const user = await get(`${USER_RECORD_API}?username=${username}`);
    return user.data?.items?.[0];
  } catch (error) {
    throw new Error(error);
  }
};

export const patchUserRecord = (uuid, data) => {
  return patch(`${USER_RECORD_API}${uuid}`, data)
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getUserInfo = (username) => {
  return get(`${USERINFO_API_URL}${username}/`)
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const patchUserInfo = (username, data) => {
  return patch(`${USERINFO_API_URL}${username}`, data)
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPageView = async (username) => {
  try {
    const response = await get(`${SAPI_BASE}dashboard/${username}/page_view`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getVideoView = async (username) => {
  try {
    const response = await get(`${SAPI_BASE}dashboard/${username}/video_view`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getLastLogin = async (username) => {
  try {
    const response = await get(`${SAPI_BASE}metric/${username}/last_login`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getHoursSpent = async (username) => {
  try {
    const response = await get(`${SAPI_BASE}metric/${username}/hours_spent`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getManyHoursSpent = async (usernames) => {
  try {
    const data = {
      users: usernames,
    };
    const response = await post(`${SAPI_BASE}metric/me/hours_spent`, data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getKalturaVideosWatched = async () =>
  // organizationId,
  // period,
  // dateRange,
  {
    return 0;
    // TODO: Uncomment once Kaltura block is
    // try {
    //   let path = `${SAPI_BASE}kaltura_videos_watched/`;
    //   if (organizationId && period) {
    //     path += `?organization=${organizationId}&period=${period}`;

    //     const { from, to } = { ...dateRange };
    //     if (from && to) {
    //       path += `&from=${moment(from).utc().format()}&to=${moment(to)
    //         .utc()
    //         .format()}`;
    //     }
    //   }

    //   const response = await get(path);
    //   return response.data;
    // } catch (error) {
    //   throw new Error(error);
    // }
  };

export const getCredlyBadges = async () => {
  try {
    const response = await get(`${SAPI_BASE}credly_user_badge`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Issue Credly Badge for 1 student
 * @param {string} uuid - UUID for the VT Class Enrollment
 * @param {string} username - Student username. Required.
 * @param {string} offering_id - LMS Class unique ID. Required.
 * @return {boolean} - True if issued successfully, false otherwise
 */
export const postIssueCredlyBadge = async (uuid, username, offering_id) => {
  if (!username) {
    throw new Error('Username is required');
  }

  if (!offering_id) {
    throw new Error('Offering ID is required');
  }

  try {
    const data = {
      username,
      offering_id,
    };

    const response = await post(
      `${SAPI_BASE}credly_user_badge/${uuid}/issue_badge`,
      data,
    );
    return response.status === 200;
  } catch (error) {
    throw new Error(error);
  }
};

export const registerFreeTrial = async (data) => {
  try {
    const response = await post(`${SAPI_BASE}eloqua_submission`, data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const restartFreeTrial = async (username, data) => {
  try {
    const response = await post(
      `${SAPI_BASE}eloqua_submission/${username}/restart`,
      data,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
