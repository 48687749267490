import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Panel, Table } from 'react-bootstrap';
import VMRow from './VMRow';

@withTranslation()
@observer
class OpenstackLabComponent extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired,
    labComponent: PropTypes.object.isRequired,
    labStore: PropTypes.object.isRequired,
  };

  getRows() {
    const { context } = this.props.labComponent;
    if (!(context && context.stat && context.stat.vm_list)) {
      return null;
    }
    const vmAnnotations = context.vm_annotations || [];
    const action = this.doCommand;
    const elems = context.stat.vm_list.map((vm) => {
      return (
        <VMRow
          key={vm.id}
          action={action}
          vmSummary={vm}
          vmAnnotations={vmAnnotations}
        />
      );
    });
    return elems;
  }

  doCommand = async (command, params) => {
    const { t } = this.props;

    let result = null;
    if (command !== 'vm_reset') {
      result = await this.props.action(command, params);
    } else {
      this.props.labStore.createNewModal(
        {
          title: t('Are you sure you want to reset this VM?'),
          confirmText: t('Yes, Reset it'),
          dismissText: t('No'),
          confirmFunc: async () => {
            this.props.labStore.setModalWorking(true);
            try {
              result = await this.props.action(command, params);
            } finally {
              this.props.labStore.setModalWorking(false);
              this.props.labStore.hideModal();
            }
          },
        },
        true,
      );
    }

    if (command === 'get_vnc_url') {
      if (result.data && result.data.results) {
        result.data.results.forEach((res) => {
          if (!res.component) {
            return;
          }
          if (!res.component.driver === this.context.driver) {
            return;
          }
          const vncUrl = res?.result?.url;
          if (vncUrl) {
            window.open(vncUrl);
          }
        });
      }
    }

    return result;
  };

  render() {
    const title = this.props.labComponent.slug;
    return (
      <Panel header={title} className="lab-controls-vm-panel">
        <Table striped condensed>
          <tbody>{this.getRows()}</tbody>
        </Table>
      </Panel>
    );
  }
}

export default OpenstackLabComponent;
