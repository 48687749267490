// TODO: Store this in synced session storage once the bugs are worked out
// import { Storage } from 'session-storage-sync';

// const storage = new Storage();

const getSessionData = (key) => {
  // return storage.session.get(key);
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (err) {
    return {};
  }
};

const setSessionData = (key, data) => {
  return window.localStorage.setItem(key, JSON.stringify(data));
  // return storage.session.set(key, data);
};

const removeSessionData = (key) => {
  return window.localStorage.removeItem(key);
  // return storage.session.remove(key);
};

const clearSessionData = () => {
  return window.localStorage.clear();
};

export { getSessionData, setSessionData, removeSessionData, clearSessionData };
export default getSessionData;
