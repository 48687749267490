import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from '@patternfly/react-core/deprecated';
import { storesContext } from 'stores';
import './styles.scss';
import { Icon, Flex, Text, TextVariants } from '@patternfly/react-core';

const LanguageDropdown = (props) => {
  const { t } = useTranslation();
  const { uiStore } = useContext(storesContext);

  const { usePatternfly, language, translations, callback } = props;

  const [isOpen, setIsOpen] = React.useState(false); // patternfly only

  const onFocus = () => {
    const element = document.getElementById('course-language-toggle');
    element?.focus();
  };

  const onSelect = () => {
    setIsOpen(false);
    onFocus();
  };

  const onToggle = (_event, newIsOpen) => {
    setIsOpen(newIsOpen);
  };

  if (usePatternfly) {
    const dropdownItems = translations.map((translation) => {
      const found = uiStore.languages.find(
        (lang) => lang.token === translation,
      );

      if (!found) return false;

      const { display_name: name } = found;
      const isActive = translation === language;

      return (
        <DropdownItem
          key={translation}
          style={{ minWidth: '260px' }}
          onClick={() => {
            callback(translation);
          }}
          data-analytics-id={`${translation}-translate-ole-lp`}
          isPlainText={isActive}
          isDisabled={isActive}
        >
          <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}>
            <div>
              <span>{name}</span>
              {isActive && (
                <Icon size="sm" style={{ marginLeft: '6px' }}>
                  <CheckIcon />
                </Icon>
              )}
            </div>
            <Text component={TextVariants.small}>{translation}</Text>
          </Flex>
        </DropdownItem>
      );
    });

    return (
      <Dropdown
        position="right"
        onSelect={onSelect}
        toggle={
          <DropdownToggle
            id="course-language-toggle"
            toggleVariant="secondary"
            onToggle={onToggle}
            style={{ width: '100%' }}
            aria-label={t('Click to open/close language dropdown')}
          >
            {t('Translations')}
          </DropdownToggle>
        }
        isOpen={isOpen}
        dropdownItems={dropdownItems}
      />
    );
  }

  return (
    <div className="course-language-btn">
      <DropdownButton
        title={t('Translations')}
        id="course-language-dropdown"
        bsStyle="info"
        data-analytics-id="translation-selector-ole-lp"
      >
        {translations.map((translation) => {
          const found = uiStore.languages.find(
            (lang) => lang.token === translation,
          );

          if (!found) return false;

          const { display_name: name } = found;

          const text = `${name} [${translation}]`;
          const formattedText =
            translation === language ? <strong>{text}</strong> : text;

          return (
            <MenuItem
              key={translation}
              eventKey={translation}
              style={{ display: 'block' }}
              onClick={() => {
                callback(translation);
              }}
              data-analytics-id={`${translation}-translate-ole-lp`}
            >
              {formattedText}
            </MenuItem>
          );
        })}
      </DropdownButton>
    </div>
  );
};

LanguageDropdown.propTypes = {
  callback: PropTypes.func,
  language: PropTypes.string,
  translations: PropTypes.array.isRequired,
  usePatternfly: PropTypes.bool,
};

LanguageDropdown.defaultProps = {
  callback: () => {},
  language: '',
  usePatternfly: false,
};

export default observer(LanguageDropdown);
