import React from 'react';
import VideoWidget from 'components/Video/Widget';
import { withErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import { KALTURA_CONFIGS } from 'config/constants';

type Props = {
  playListId: string;
};

const ExpertExtrasTab = (props: Props) => {
  const { playListId } = props;
  return (
    <div>
      <VideoWidget
        partnerId={KALTURA_CONFIGS.partnerId}
        playerId={KALTURA_CONFIGS.expertExtrasPlayer.uiConfId}
        entryId={playListId}
        isPlaylist
      />
    </div>
  );
};

export default withErrorBoundary(
  observer(ExpertExtrasTab),
  ComponentError,
  handleError,
);
