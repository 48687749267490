import React, { useContext, useEffect } from 'react';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import ReactPlaceholder from 'react-placeholder';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { withErrorBoundary } from 'react-error-boundary';

import {
  Alert,
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';

import { ACCESS_REDHAT_COM_URL } from '../../config/constants';
import { storesContext } from '../../stores';
import LoginStore from './store';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import GenericAlert from '../../components/Banners/GenericAlert';

import './styles.scss';

const LoginView = () => {
  const { t } = useTranslation();

  const { routerStore, uiStore, userStore, alertStore, configStore } =
    useContext(storesContext);
  const { params } = routerStore.route;
  const { authorized, offering: offeringId, redirect, scope } = params;

  const baseHref = (document.querySelector('base') || {}).href;

  const store = useContext(LoginStore);

  const loginAlerts = alertStore.currentAlerts?.filter(
    (alert) => alert.enabled && alert.environment.includes('login'),
  );

  const { course, courseCode, loading, message, modality } = store;
  const { contactOperationURL, learningSubscriptionURL } = configStore;

  uiStore.title = params?.title;

  useEffect(() => {
    const normalizePath = (path) => (path || '').replace(/^\//, '');

    // After a user logs in via SSO
    when(
      () => authorized && offeringId,
      async () => {
        store.offeringId = offeringId;
        let uuid;

        try {
          uuid = await store.getVTClassUUID();
          routerStore.navigate('classes:view', { uuid });
        } catch (err) {
          routerStore.navigate('home');
        }
      },
    );

    when(
      () => !offeringId,
      async () => {
        if (scope === 'sso') {
          await configStore.getServerConfig();

          const next = redirect
            ? `${baseHref}${normalizePath(
                redirect?.path,
              )}?${querystring.stringify(redirect.params)}`
            : baseHref;

          // Redirect to the intended location after authenticating via SSO
          window.location.replace(
            `${configStore.ssoLoginEndpoint}?next_url=${encodeURIComponent(
              next,
            )}`,
          );
        }
      },
    );

    when(
      () => !authorized && !redirect && offeringId,
      async () => {
        if (scope === 'sso') {
          await configStore.getServerConfig();
          const loginPath = routerStore.router.buildPath('login');

          const next = `${baseHref}${normalizePath(
            loginPath,
          )}?offering=${offeringId}&authorized=true`;

          // Redirect to the login screen with `authorized` parameter
          // after authenticating via SSO
          window.location.replace(
            `${configStore.ssoLoginEndpoint}?next_url=${encodeURIComponent(
              next,
            )}`,
          );
          return;
        }

        store.offeringId = offeringId;
        await store.getCourse();
      },
    );
  }, []);

  const onChange = (e) => {
    store[e.target.name] = e.target.value;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    store.post(routerStore, userStore, t);
  };

  const LocalLogin = () => (
    <React.Fragment>
      {offeringId && courseCode && (
        <div className="course-banner">
          <div className="container-grid-responsive">
            <div
              className={`course-modality rh-icon icon-modality-${modality}`}
            />
            <div className="course-title">
              <ReactPlaceholder
                type="textRow"
                style={{ height: 30 }}
                ready={Boolean(course.code)}
                showLoadingAnimation
              >
                <h1 className="welcome">
                  {t('Welcome!')}
                  <br />
                  <strong>{course.code}:</strong>
                  {course.title}
                </h1>
              </ReactPlaceholder>
            </div>
          </div>
        </div>
      )}
      <div className="container-grid-responsive">
        <Row>
          <Col md={12}>
            <div className="login-page-alerts">
              {loginAlerts.map((alert) => (
                <GenericAlert {...alert} />
              ))}
            </div>
            <h1>{t('Log in to your Red Hat account')}</h1>
            <form
              id="login_form"
              name="login_form"
              className="login-form"
              onSubmit={onSubmit}
            >
              {message && message.text && (
                <Alert bsStyle={message.type}>{message.text}</Alert>
              )}
              <div id="errors" className="error" />
              <FormGroup>
                <ControlLabel className="sr-only">
                  {t('Red Hat login')}
                </ControlLabel>
                <FormControl
                  autoFocus
                  id="username"
                  name="username"
                  type="text"
                  placeholder={t('Red Hat login')}
                  maxLength="120"
                  autoCapitalize="off"
                  autoCorrect="off"
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel className="sr-only">{t('Password')}</ControlLabel>
                <FormControl
                  id="password"
                  name="password"
                  type="password"
                  placeholder={t('Password')}
                  onChange={onChange}
                />
              </FormGroup>
              <Button
                id="login_button"
                type="submit"
                bsStyle="primary"
                disabled={loading}
              >
                {t('Log In')}
              </Button>
              <a
                id="lost_password_a"
                className="lostPasswordLink external_link"
                href="https://www.redhat.com/wapps/sso/lostPassword.html"
              >
                {t('Forgot your login or password?')}
              </a>
            </form>
          </Col>
        </Row>
      </div>
      <div className="band band-alt login-bottom">
        <div className="container-grid-responsive">
          <div className="row equal">
            <div className="col-md-4">
              <div className="card card-white">
                <h2 className="card-heading">{t('Problems logging in?')}</h2>
                <p>
                  If you are unable to login, please{' '}
                  <a href="https://sso.redhat.com">
                    verify your user credentials here
                  </a>
                  . If you are able to authenticate there, please contact our
                  training operations team for further assistance.
                </p>
                <a
                  href={contactOperationURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Contact Training Operations')}
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-white">
                <h2 className="card-heading">
                  {t('Not a Learning Subscriber?')}
                </h2>
                <p>
                  {t(
                    `Red Hat Learning Subscription provides access to the entire catalog of online training for an entire year! To learn more, visit:`,
                  )}
                </p>
                <a
                  href={learningSubscriptionURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('About Red Hat Learning Subscriptions')}
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-white">
                <h2 className="card-heading">
                  {t('Looking for more stuff to explore?')}
                </h2>
                <p>
                  {t(
                    `Every Red Hat Online Training course comes with access to the Red Hat Customer Portal, including its Knowledge Base, Discussion Communities, and Tools.`,
                  )}
                </p>
                <a
                  href={ACCESS_REDHAT_COM_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Red Hat Customer Portal')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return <div className="login">{scope !== 'sso' ? <LocalLogin /> : null}</div>;
};

export default withErrorBoundary(
  observer(LoginView),
  GenericViewError,
  handleError,
);
