import React, { useContext } from 'react';

import { DateUtils } from 'react-day-picker';
import SVG from 'react-inlinesvg';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import { parseDate, formatDate } from 'react-day-picker/moment';
import {
  Button,
  ControlLabel,
  Form,
  FormGroup,
  FormControl,
  Col,
  HelpBlock,
  Row,
  Panel,
  Modal,
} from 'react-bootstrap';
import { customSkillPathContext } from 'views/CustomSkillPath/store';

import UsersIcon from 'img/icons/users.svg';
import { CUSTOM_SKILL_PATH_MODE } from 'config/constants';
import ViewLoader from 'components/Loading/View';

import LearningPathIcon from 'img/icons/skills_path_small.svg';
import CatalogCard from 'components/Catalog/Card';

import { storesContext } from 'stores';
import './styles.scss';

// Todo: may be this should be outisde learning path?
import TypeAheadOffering from './TypeAheadSearch';
import DatePickerInput from './DatePickerInput';
import 'react-day-picker/lib/style.css';

const CustomSkillPathForm = () => {
  const { i18n, t } = useTranslation();

  const { catalogStore, learningPathStore, vocabularyStore } =
    useContext(storesContext);
  const store = useContext(customSkillPathContext);

  const getOfferingInPathButtonsArray = (offeringCode) => {
    return [
      <Button
        key={uuidv4()}
        disabled={store.apiUpdate.inProgress}
        onClick={() => {
          store.removeOfferingsFromPath(offeringCode);
        }}
      >
        {t('Remove')}
      </Button>,
    ];
  };
  const renderOfferingInPathCard = (pathCode, offeringIndex) => {
    const offering = catalogStore.uniqueCatalogEntries[pathCode];
    if (offering) {
      return (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          key={`custom-offering-${pathCode}`}
        >
          <FormGroup controlId="clp-offerings-input">
            <Col>
              <CatalogCard
                course={offering}
                targetSubscription={store.targetSubscription}
                catlogCardButtons={getOfferingInPathButtonsArray(pathCode)}
                key={`custom-offering-card-${pathCode}`}
                canAccess={
                  offering.visibility !== 'hidden' &&
                  offering.visibility !== 'upgrade'
                }
                onOfferingOrderChange={() =>
                  store.onOfferingOrderChange(offering, offeringIndex)
                }
                allowOrderingOffering
              />
            </Col>
          </FormGroup>
        </VerticalTimelineElement>
      );
    }
    return null;
  };

  const renderAddOfferingCard = () => {
    const isCompleted = { background: 'rgb(249, 249, 249)', color: '#fff' };
    return (
      <React.Fragment>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={isCompleted}
          key={uuidv4()}
        >
          <FormGroup className="padding-right" controlId="clp-offering-select">
            <TypeAheadOffering
              dataToSearchFrom={store.offerings}
              onAcceptSearch={(o) => {
                if (!store.apiUpdate.inProgress) {
                  const _selectedItem = o[0] || {};
                  store.addOfferingsToPath(_selectedItem.value.code);
                }
              }}
              labelKey="label"
              placeHolderText={t('Select an offering...')}
            />
          </FormGroup>
        </VerticalTimelineElement>
      </React.Fragment>
    );
  };

  const renderOfferingsInPath = () => {
    return (
      <div className="learning-path-elements">
        {store.customSkillPath.offerings.map((code, index) => {
          return renderOfferingInPathCard(code, index);
        })}
      </div>
    );
  };

  const renderCustomSkillPathForm = () => {
    return (
      <React.Fragment>
        <Row className="learning-path-input">
          <Col sm={1} md={1}>
            <div className="icon">
              <SVG src={LearningPathIcon} title={t('Skills Path')} />
            </div>
          </Col>
          <Col sm={11} md={11}>
            <VerticalTimeline layout="1-column" animate={false}>
              <Form horizontal className="cskp-form" id={uuidv4()}>
                <FormGroup controlId="clp-title-input">
                  <Col
                    componentClass={ControlLabel}
                    className="col-md-2 col-sm display-flex clp-title-input "
                  >
                    <div className="input-group clp-title-input-group">
                      <span className="input-group-prepend">{t('Title:')}</span>
                      <span className="custom-text">{t('Custom-')}</span>
                    </div>
                  </Col>
                  <Col className="col-md-10 col-sm">
                    <FormControl
                      type="text"
                      as="input"
                      autoFocus
                      value={store.customSkillPath.title}
                      placeholder={t(
                        'Give a title for the custom skill path here...',
                      )}
                      onChange={(e) => {
                        e.preventDefault();
                        store.handleInputChange(e, 'title');
                      }}
                    />

                    <HelpBlock className="validation-message">
                      <span className={!store.isTitleValid ? 'error' : ''}>
                        {`${t('* Title is mandatory with maximum')} ${
                          store.customSkillPathValidation.title.length
                        } ${t('characters')}`}
                      </span>
                    </HelpBlock>
                  </Col>
                </FormGroup>

                <FormGroup controlId="clp-desc-input">
                  <Col
                    componentClass={ControlLabel}
                    className="col-md-2 col-sm"
                  >
                    {t('Description:')}
                  </Col>
                  <Col className="col-md-10 col-sm">
                    <FormControl
                      componentClass="textarea"
                      value={store.customSkillPath.description}
                      placeholder={t('Add the skill path description here...')}
                      onChange={(e) => {
                        store.handleInputChange(e, 'description');
                      }}
                    />
                    <HelpBlock className="validation-message align-right">
                      <span
                        className={!store.isDescriptionValid ? 'error' : ''}
                      >
                        {`${store.customSkillPath.description.length}/${store.customSkillPathValidation.description.length}`}
                      </span>
                    </HelpBlock>
                  </Col>
                </FormGroup>

                {renderOfferingsInPath()}

                {renderAddOfferingCard()}
              </Form>
            </VerticalTimeline>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const getButtonsForCustomSkillPathForm = () => {
    const isEditing = store.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.edit;
    return [
      <Button
        data-analytics-id="clear-btn-cform-cskp-lp"
        key={uuidv4()}
        disabled={store.apiUpdate.inProgress}
        onClick={() => {
          store.cancelCustomSkillPath();
        }}
      >
        {t('Clear')}
      </Button>,
      <Button
        data-analytics-id={`${isEditing ? 'save' : 'create'}-btn-cform-cskp-lp`}
        key={uuidv4()}
        bsStyle="primary"
        disabled={!store.isAllValid || store.apiUpdate.inProgress}
        onClick={async () => {
          await store.saveCustomSkillPath();
          await learningPathStore.getLearningPaths(true);
          learningPathStore.getFilterInfo(i18n, vocabularyStore);
        }}
      >
        {store.apiUpdate.inProgress ? (
          <span className="spinner-wrapper">
            <ViewLoader color="#ffffff" />
          </span>
        ) : (
          (isEditing && t('Save')) || t('Create')
        )}
      </Button>,
    ];
  };

  const validateDate = (selectedDate) => {
    if (selectedDate !== undefined) {
      return DateUtils.isFutureDay(selectedDate);
    }
    return true;
  };

  const handleDayClick = (selectedDay) => {
    const selectedEndDate = parseDate(selectedDay);

    store.customSkillPath.end_date = selectedEndDate;
  };

  const renderAddUsersHandler = () => {
    const usersLabelClassnames = `input-group-addon add-users-text ${
      store.totalTargetUsers < 1 ? `add-users-text--invalid` : ''
    }`;
    return (
      <React.Fragment>
        <div className="input-group add-users-input-group">
          <div className={usersLabelClassnames}>{t('* Users:')}</div>

          <input
            data-analytics-id="users-cform-cskp-lp"
            className="form-control"
            readOnly
            value={store.totalTargetUsers}
            onClick={() => {
              store.toggleAddUserToPathModal();
            }}
          />

          <div className="input-group-btn add-users-btn-group">
            <Button
              data-analytics-id="users-btn-cform-cskp-lp"
              className="input-group-addon add-users-btn"
              onClick={() => {
                store.toggleAddUserToPathModal();
              }}
            >
              <SVG src={UsersIcon} />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderForm = () => {
    return (
      <React.Fragment>
        <div className="custom-skill-path-form-container">
          <Panel className="panel-custom-skill-path-form">
            {store.isUserOrganisationLeader && (
              <Panel.Heading>
                <div className="csk-panel-header">
                  <div className="header-group">
                    <span className="header">{t('Subscription')}</span>
                    <span>{store.targetSubscriptionText}</span>
                  </div>
                </div>
              </Panel.Heading>
            )}
            <Panel.Body>{renderCustomSkillPathForm()}</Panel.Body>
            <Panel.Footer
              className="csk-panel-footer"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div className="footer-elements-container">
                <div className="date-picker-container">
                  <DatePickerInput
                    data-analytics-id="due-date-calendar-cform-cskp-lp"
                    onDateSelected={handleDayClick}
                    dateCaption={t('Due Date')}
                    selectedDate={
                      store.customSkillPath.end_date &&
                      formatDate(store.customSkillPath.end_date, 'YYYY-MM-DD')
                    }
                    onValidateDate={validateDate}
                  />
                </div>
                {store.allowToAddUsersToPath && (
                  <div className="add-users-container">
                    {renderAddUsersHandler()}
                  </div>
                )}
                <div className="actions-container">
                  {getButtonsForCustomSkillPathForm()}
                </div>
              </div>
            </Panel.Footer>
          </Panel>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderForm()}
      <Modal show={store.apiUpdate.showProgress}>
        {store.apiUpdate.isError && (
          <React.Fragment>
            <Modal.Header>
              {t('Not able to save a customized Skills Path')}
            </Modal.Header>
            <Modal.Body>
              {t(
                "The custom skills path couldn't be created. Please try again. If the problem persists, please contact support.",
              )}
            </Modal.Body>
          </React.Fragment>
        )}
        {!store.apiUpdate.isError && (
          <React.Fragment>
            <Modal.Header>
              {store.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.create &&
                t('You have created a customized Skills Path!')}
              {store.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.edit &&
                t('You have updated a customized Skills Path!')}
            </Modal.Header>
            <Modal.Body>
              {store.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.create &&
                `${t('Congratulations! You have successfully created')} ${
                  store.customSkillPath.title
                } ${t('skill path!')}`}

              {store.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.edit &&
                `${t('Congratulations! You have successfully updated')} ${
                  store.customSkillPath.title
                } ${t('skill path!')}`}
            </Modal.Body>
          </React.Fragment>
        )}
        <Modal.Footer>
          <Button
            bsStyle="primary"
            disabled={store.apiUpdate.inProgress}
            onClick={() => {
              if (!store.apiUpdate.isError) {
                store.setCustomSkillPath({});
                store.resetCustomPathFlow();
              }
              store.toggleAPIProgress();
            }}
          >
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default observer(CustomSkillPathForm);
