export enum CourseStructureItemStatus {
  Inactive = 'inactive',
  Active = 'active',
}

export interface CourseStructureItem {
  status: CourseStructureItemStatus;
  toc_tag: string;
  id_tag: string;
  prev_id: string;
  path: string;
  document_index: number;
  children: any[];
  interactives: any[];
  page_tag: string;
  uuid: string;
  title: string;
  media: any[];
  prev_tag: string;
  next_tag: string;
  parent_id: string;
  depth: number;
  next_id: string;
  type: string;
  order: number;
}

export interface CourseProgressStructureItem {
  uuid: string;
  weight: number;
  title: string;
  type: string;
  comments: any[];
  children: any[];
  parent_id: string;
  depth: number;
  score: number;
  progress: number;
  path: string;
  document_index: number;
  order: number;
}
