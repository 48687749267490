import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { routeNode, RouteView } from 'react-mobx-router5';

import Routes from '../../config/routes';

const routeNodeName = ''; // '' because root node

@inject('userStore', 'routerStore')
class Main extends React.Component {
  static propTypes = {
    userStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const routes = new Routes({
      userStore: props.userStore,
      routerStore: props.routerStore,
    });

    this.routes = routes.routes;
  }

  render() {
    return (
      <main className="app-content">
        {this.props.route && (
          <RouteView
            route={this.props.route}
            routes={this.routes}
            routeNodeName={routeNodeName}
          />
        )}
      </main>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object, // observable
  routerStore: PropTypes.object.isRequired,
};

Main.defaultProps = {
  route: {},
};

export default routeNode(routeNodeName)(Main);
