import { action, computed, observable, reaction } from 'mobx';
import { getUserExams, getReporteesExamStatus } from '../services/ExamService';

class ExamsStore {
  @observable exams = [];

  @observable loading = false;

  @observable enrollmentLoading = false;

  @observable canShowSuccessAlert = false;

  @observable intervalId = undefined;

  @observable disableExamOperations = false;

  @observable interval = 10000;

  @observable reporteesExamStatus = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.enrollmentLoading,
      () => {
        if (!this.enrollmentLoading) {
          // after enrollment request is done, refresh quotas
          try {
            this.rootStore.userStore.getActiveSubscription();
          } catch (e) {
            console.error(e);
          }
        }
      },
    );
  }

  @computed get exam() {
    return (
      this.exams.find(
        (exam) => exam.exam_code === `${this.code}-${this.version}`,
      ) || {}
    );
  }

  @action getUserExams = async () => {
    this.loading = true;
    const examsInfo = await getUserExams();
    if (examsInfo) {
      this.exams = examsInfo;
    }
    this.disableExamOperations = false;
    this.loading = false;
  };

  @action getReporteesExamStatus = async (users) => {
    try {
      const examStatus = await getReporteesExamStatus(users);
      this.reporteesExamStatus = examStatus;
    } catch (e) {
      console.error('getReporteesExamStatus error: ', e);
    }
    return this.reporteesExamStatus;
  };

  @computed get examStatus() {
    const examStatuses = {
      scheduled: [],
      unscheduled: [],
      results_pending: [],
      completed: [],
      cancelled: [],
      retake_available: [],
      retake_scheduled: [],
      retake_results_pending: [],
      retake_complete: [],
      noStatus: [],
    };

    this.exams.map((exam) => {
      const examStatus = examStatuses[exam.status];
      return examStatus
        ? examStatus.push(exam)
        : examStatuses.noStatus.push(exam);
    });

    return examStatuses;
  }
}

export default ExamsStore;
