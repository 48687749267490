/**
 * @file Provides methods to interact with course data
 * @author Anna Kurylo <akurylo@redhat.com>
 */

import { get, post, put } from 'axios';
import { LEARNING_PATH_API_URL } from '../config/constants';

export const getLearningPaths = async () => {
  try {
    return await get(LEARNING_PATH_API_URL).then((res) => res.data?.items);
  } catch (error) {
    throw new Error(error);
  }
};

export const getJoinedLearningPaths = () => {
  return get(`${LEARNING_PATH_API_URL}?joined=true`)
    .then((res) => {
      const paths = res.data.items;
      return paths;
    })
    .catch(() => {
      return [];
    });
};

export const joinLearningPath = (slug) => {
  return post(`${LEARNING_PATH_API_URL}${slug}/join/`).then((res) => res.data);
};

export const unjoinLearningPath = (slug) => {
  return post(`${LEARNING_PATH_API_URL}${slug}/unjoin/`).then(
    (res) => res.data,
  );
};

export const getLearningPath = (slug) => {
  return get(`${LEARNING_PATH_API_URL}${slug}/`).then((res) => res.data);
};

export const createCustomSkillPath = async (skillPathData) => {
  try {
    const res = await post(
      `${LEARNING_PATH_API_URL}create_custom`,
      skillPathData,
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCustomSkillPath = async (skillPathData, id) => {
  if (!id) {
    throw new Error('Custom Skill Path Id required');
  }
  try {
    const res = await put(
      `${LEARNING_PATH_API_URL}${id}/update_custom`,
      skillPathData,
    );

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCustomSkillPath = async (id) => {
  if (!id) {
    throw new Error('Custom Skill Path Id required');
  }
  try {
    const res = await get(`${LEARNING_PATH_API_URL}${id}/get_custom`);

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCustomSkillPath = async (id) => {
  if (!id) {
    throw new Error('Custom Skill Path Id required');
  }
  try {
    const res = await put(`${LEARNING_PATH_API_URL}${id}/delete_custom`);

    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};
