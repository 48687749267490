import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';

const LabAlert = inject('labStore')(
  observer(({ alertType, labStore }) => {
    const { t } = useTranslation();
    const alerts = {
      generic: {
        style: 'warning',
        message: t(
          'We are not able to start your lab at this time.  Please try back again later.',
        ),
        displayInState: ['ready', 'deleted'],
      },
      capacity_exceeded: {
        style: 'warning',
        message: t(
          'We are not able to start your lab at this time, our lab capacity in your region has reached the maximum number of users.  Please try back again later.',
        ),
        displayInState: ['ready', 'deleted'],
      },
      not_allowed_launch: {
        style: 'warning',
        message: t('Labs for this course are not available for you.'),
        displayInState: ['ready', 'deleted'],
      },
      throttled: {
        style: 'warning',
        message: t(
          'We are not able to start your lab at this time, our lab capacity in your region has reached the maximum number of users.  Please try back again later.',
        ),
        displayInState: ['ready', 'deleted', 'stopped'],
      },
      user_throttled: {
        style: 'warning',
        message: t(
          'We are not able to start your lab as you reached the maximum number of running labs per user. Please stop or delete a running lab to start this lab.',
        ),
        displayInState: ['ready', 'deleted', 'stopped'],
      },
    };

    const selectedAlert =
      alertType in alerts ? alerts[alertType] : alerts.generic;
    return (
      selectedAlert.displayInState.includes(labStore.labState) && (
        <Alert bsStyle={selectedAlert.style}>{selectedAlert.message}</Alert>
      )
    );
  }),
);

LabAlert.defaultProps = {
  alertType: 'generic',
};

LabAlert.propTypes = {
  labStore: PropTypes.object.isRequired,
  alertType: PropTypes.string,
};

export default LabAlert;
