import React from 'react';
import { CenteredLoadingSpinner } from '@ole-ui/ole-ui-components';

import './styles.scss';

const ViewLoaderV2 = () => (
  <div className="view-loader-v2">
    <CenteredLoadingSpinner />
  </div>
);

export default ViewLoaderV2;
