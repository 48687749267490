import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-mobx-router5';
import './styles.scss';

const PageHighlight = (props) => {
  const { highlight, pageTag, slug } = props;

  if (!highlight) {
    return '';
  }
  return (
    <Link
      href
      title="Course page"
      routeName="courses:page"
      routeParams={{
        course: slug,
        page: pageTag,
      }}
      className="course-page-result"
    >
      {highlight?.title && (
        <div
          className="course-page-highlighted-title"
          dangerouslySetInnerHTML={{
            __html: highlight?.title?.join(' '),
          }}
        />
      )}
      {highlight?.text && (
        <div
          className="course-page-highlighted-text"
          dangerouslySetInnerHTML={{
            __html: highlight?.text?.join(' '),
          }}
        />
      )}
    </Link>
  );
};

PageHighlight.propTypes = {
  highlight: PropTypes.object.isRequired,
  pageTag: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

PageHighlight.defaultProps = {
  pageTag: 'ch01',
};

export default PageHighlight;
