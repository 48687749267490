import PropTypes from 'prop-types';
import React from 'react';
import { SyncLoader } from 'react-spinners';
import './styles.scss';

const ViewLoader = ({ color }) => {
  return (
    <React.Fragment>
      <div className="spinner-wrapper">
        <SyncLoader width={100} widthUnit="%" color={color} />
      </div>
    </React.Fragment>
  );
};

ViewLoader.propTypes = {
  color: PropTypes.string,
};

ViewLoader.defaultProps = {
  color: '#2a2a2a',
};

export default ViewLoader;
