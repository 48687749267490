import React, { useEffect, useContext } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, PropTypes as MobXPropTypes } from 'mobx-react';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {
  GroupedList,
  PremiumCalendarFilters,
  PremiumCalendarCard,
  KalturaWidget,
} from '@ole-ui/ole-ui-components';
import {
  Title,
  EmptyState,
  EmptyStateBody,
  EmptyStateActions,
  EmptyStateIcon,
  Button,
} from '@patternfly/react-core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import { KALTURA_CONFIGS } from 'config/constants';
import LimitedTrialLimitReachAlert from 'components/Subscriptions/LimitedTrial/LimitReachedAlert';
import { groupByDateFormat } from '../store';
import Card from './Card';

const groupedListTitleDateFormat = 'LT';

function DayView({ liveSessionsStore }) {
  const { t, i18n } = useTranslation();
  const { userStore } = useContext(storesContext);

  const {
    isLoadingList,
    todaysSessions,
    todaysSessionsIds,
    isLoadingCollaterals,
    loadSessionCollaterals,
    filteredSessionsWithCollateral,
    selectedDate,
    setSelectedDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    term,
    setTerm,
    resetFilters,
    sessionsGroupedByStartTime,
  } = liveSessionsStore;

  const { hourCycle } = Intl.DateTimeFormat(i18n.language, {
    hour: 'numeric',
  })?.resolvedOptions();
  const is24hourFormat = !['h11', 'h12'].includes(hourCycle);

  const isDateSelectable = (d) => {
    return moment(d).isAfter(moment().subtract(1, 'days'), 'day');
  };

  const handleStartTimeChange = (e, time) => {
    setStartTime(time);
  };

  const handleEndTimeChange = (e, time) => {
    setEndTime(time);
  };

  const handleTermChange = (e, value) => {
    setTerm(value);
  };

  const renderCard = (session) => (
    <Card
      key={session.doc_id}
      session={session}
      isLoadingCollaterals={isLoadingCollaterals}
      onSchedule={liveSessionsStore.enrollUser}
      onCancel={liveSessionsStore.cancelSession}
      onReschedule={liveSessionsStore.enrollUser}
      currentSubscriptionEndTime={userStore.subscriptionEndTime}
    />
  );

  const renderCardSkeleton = () => <PremiumCalendarCard.Skeleton />;

  const renderResults = () => {
    if (filteredSessionsWithCollateral.length < 1 && !isLoadingList) {
      return (
        <div className="live-session-calendar__loading-container">
          <EmptyState>
            <EmptyStateIcon icon={SearchIcon} />
            <Title size="lg" headingLevel="h4">
              {t('No session on the selected date')}
            </Title>
            <EmptyStateBody>
              {t(
                'No session match the filter criteria. Reset the filters or select another date.',
              )}
            </EmptyStateBody>
            <EmptyStateActions>
              <Button variant="link" type="button" onClick={resetFilters}>
                {t('Clear filters')}
              </Button>
            </EmptyStateActions>
          </EmptyState>
        </div>
      );
    }

    return (
      <GroupedList
        isLoading={isLoadingList || isLoadingCollaterals}
        groups={sessionsGroupedByStartTime.map((g) => ({
          ...g,
          title: moment(g.key, groupByDateFormat).format(
            groupedListTitleDateFormat,
          ), // title is formatted here to ensure it will re-render whenever language changes
        }))}
        renderItem={renderCard}
        renderItemSkeleton={renderCardSkeleton}
      />
    );
  };

  useEffect(() => {
    loadSessionCollaterals(todaysSessions, i18n.language);
  }, [todaysSessionsIds, i18n.language]);

  return (
    <>
      <div className="live-session-calendar__results-wrapper">
        {userStore.hasReachedCurrentSubscriptionPremiumVTLimit &&
          userStore.isLimitedTrialSubscriber && <LimitedTrialLimitReachAlert />}
        <section className="live-session-calendar__results">
          {renderResults()}
        </section>
      </div>
      <aside
        className="live-session-calendar__filters"
        aria-label={t('Premium Calendar filters')}
      >
        <PremiumCalendarFilters
          title={t('Filter by')}
          locale={i18n.language}
          selectedDate={selectedDate}
          onDateChange={(e, d) => setSelectedDate(d)}
          calendarProps={{
            'data-analytics-id': 'calendar-filter-premium-calendar-lp',
          }}
          is24Hour={is24hourFormat}
          isDateSelectable={isDateSelectable}
          startTimeLabel={t('Start time')}
          startTime={startTime}
          startTimeInputProps={{
            'data-analytics-id': 'star-time-filter-premium-calendar-lp',
          }}
          onStartTimeChange={handleStartTimeChange}
          endTimeLabel={t('End time')}
          endTime={endTime}
          endTimeInputProps={{
            'data-analytics-id': 'end-time-filter-premium-calendar-lp',
          }}
          onEndTimeChange={handleEndTimeChange}
          courseTermLabel={t('Course search')}
          courseTermInputProps={{
            placeholder: t('Type a course name or SKU for ex: DO180'),
            'aria-label': t(
              'Type a course name or SKU (for ex: DO180) to filter the listed sessions',
            ),
            'data-analytics-id': 'course-term-filter-premium-calendar-lp',
          }}
          courseTerm={term}
          onCourseTermChange={handleTermChange}
        />
        {userStore.isFreeTierSubscriber && (
          <div
            className="premVidFreeTierConatiner"
            data-analytics-id="prem-vid-free-tier-premium-calendar-lp"
          >
            <KalturaWidget
              className="expert-extras-player"
              partnerId={KALTURA_CONFIGS.partnerId}
              playerId={KALTURA_CONFIGS.premiumInFreeTrial.uiConfId}
              entryId={KALTURA_CONFIGS.premiumInFreeTrial.entryId}
              isPlaylist={false}
            />
          </div>
        )}
      </aside>
    </>
  );
}

DayView.propTypes = {
  liveSessionsStore: MobXPropTypes.observableObject.isRequired,
};

export default withErrorBoundary(
  observer(DayView),
  GenericViewError,
  handleError,
);
