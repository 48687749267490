import { observable, action } from 'mobx';
import moment from 'moment-timezone';
import {
  cancelPremiumSessionInterest,
  createPremiumSessionInterest,
} from '../../../services/PremiumSessionInterestedService';

class RequestSessionFormStore {
  @observable dataForm = {
    dateRange: '30',
    timeRange: 'morning',
    timezone: '',
    notify: false,
  };

  @observable premVtSessionSlug = '';

  @observable offeringSlug = '';

  @observable friendlyDateRangeNames = {
    30: 'Next month',
    90: 'Next 3 months',
    180: 'Next 6 months',
  };

  @observable loadingRequest = false;

  @observable interest = {};

  constructor(
    userStore,
    classesStore,
    offeringSlug,
    premVtSessionSlug,
    interest,
  ) {
    const timezone = userStore.user.default_timezone || moment.tz.guess();
    this.dataForm = {
      ...this.dataForm,
      timezone,
    };

    this.classesStore = classesStore;
    this.offeringSlug = offeringSlug;
    this.premVtSessionSlug = premVtSessionSlug;
    this.interest = interest;
  }

  @action async createInterest() {
    const data = {
      offeringSlug: this.offeringSlug,
      premiumSessionSlug: this.premVtSessionSlug,
      timeRange: this.dataForm.timeRange,
      daysAfter: Number(this.dataForm.dateRange),
      notify: this.dataForm.notify,
      timezone: this.dataForm.timezone,
    };

    try {
      this.loadingRequest = true;
      await createPremiumSessionInterest(
        data.offeringSlug,
        data.premiumSessionSlug,
        data.timeRange,
        data.daysAfter,
        data.notify,
        data.timezone,
      );
      await this.classesStore.getPremiumSessionInterestsForCurrentUser();
      this.interest = this.classesStore.getInterestInSession(
        this.premVtSessionSlug,
      );
      this.loadingRequest = false;
    } catch (error) {
      console.error(error);
      this.loadingRequest = false;
    }
  }

  @action async cancelInterest() {
    try {
      this.loadingRequest = true;
      await cancelPremiumSessionInterest(this.premVtSessionSlug);
      await this.classesStore.getPremiumSessionInterestsForCurrentUser();
      this.interest = this.classesStore.getInterestInSession(
        this.premVtSessionSlug,
      );
      this.loadingRequest = false;
    } catch (error) {
      console.error(error);
      this.loadingRequest = false;
    }
  }
}
export default RequestSessionFormStore;
