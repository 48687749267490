import React, { useRef } from 'react';

import SVG from 'react-inlinesvg';

import { observer, useLocalStore } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import { LocaleUtils } from 'react-day-picker';

import 'react-day-picker/lib/style.css';

import CalendarIcon from 'img/icons/calendar.svg';

import DatePickerInputStore from './store';

import './styles.scss';

const DatePickerInput = (props) => {
  const { t, i18n } = useTranslation();
  const dayPickerInput = useRef(null);
  const dayPicker = useRef(null);
  const store = useLocalStore(() => new DatePickerInputStore());
  const {
    onDateSelected,
    dateCaption,
    selectedDate,
    onValidateDate,
    enableReadOnlyInput,
    'data-analytics-id': analyticsId,
  } = props;

  const RenderDateInputWithIcon = (dayProps) => {
    return (
      <React.Fragment>
        <div className="input-group date-input-group">
          {dateCaption !== '' && (
            <div className="input-group-addon due-date-text">{dateCaption}</div>
          )}
          <input
            data-analytics-id={analyticsId}
            className={`form-control ${
              !store.isDateValid ? 'invalid-date-control' : ''
            }`}
            {...dayProps}
            ref={dayPickerInput}
          />
          <div className="input-group-btn due-date-btn-group">
            <Button
              data-analytics-id={`btn-${analyticsId}`}
              disabled={enableReadOnlyInput}
              className="input-group-addon date-btn"
              aria-label={t('Open calendar')}
              onClick={() => {
                if (!store.isCalendarOpen) {
                  dayPicker.current.showDayPicker();
                } else {
                  dayPicker.current.hideDayPicker();
                }
              }}
            >
              <SVG src={CalendarIcon} />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="date-picker-input-wrapper">
        <DayPickerInput
          formatDate={LocaleUtils.formatDate}
          value={selectedDate}
          keepFocus
          className="Selectable"
          parseDate={LocaleUtils.parseDate}
          placeholder="YYYY-MM-DD"
          ref={dayPicker}
          inputProps={{ ref: dayPickerInput, disabled: enableReadOnlyInput }}
          onDayChange={async (day) => {
            store.isDateValid = onValidateDate(day);
            await onDateSelected(day);
            if (dayPickerInput.current.focus) {
              dayPickerInput.current.focus();
            }
          }}
          component={React.forwardRef((dayProps, ref) => (
            <RenderDateInputWithIcon {...dayProps} innerref={ref} />
          ))}
          dayPickerProps={{
            locale: i18n.language,
            localeUtils: LocaleUtils,
            disabledDays: { before: new Date() },
          }}
          format="YYYY-MM-DD"
          onDayPickerShow={() => {
            if (dayPickerInput.current.focus) {
              dayPickerInput.current.focus();
            }
            store.toggleDayPickerInput(true);
          }}
          onDayPickerHide={() => {
            store.toggleDayPickerInput(false);
          }}
        />
      </div>
    </React.Fragment>
  );
};

DatePickerInput.propTypes = {
  onDateSelected: PropTypes.func,
  dateCaption: PropTypes.string.isRequired,
  selectedDate: PropTypes.string,
  onValidateDate: PropTypes.func,
  enableReadOnlyInput: PropTypes.bool,
  'data-analytics-id': PropTypes.string,
};
DatePickerInput.defaultProps = {
  onDateSelected: () => {},
  selectedDate: undefined,
  onValidateDate: () => {
    return true;
  },
  enableReadOnlyInput: false,
  'data-analytics-id': undefined,
};

export default observer(DatePickerInput);
