import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { NavLink } from 'react-mobx-router5';
import { withTranslation } from 'react-i18next';
import { Nav, NavItem, PageHeader } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';

import {
  OPS_ACCOUNTS_VIEW,
  OPS_CLASSES_VIEW,
  OPS_CONTENT_MANAGER_VIEW,
  OPS_COURSES_VIEW,
  OPS_DJANGO_ADMIN_VIEW,
  OPS_ENROLLMENT_SEARCH_VIEW,
  OPS_RHLC_USERS_VIEW,
  OPS_SCHEMA_VIEW,
} from '../../config/constants';

@inject('routerStore')
@withTranslation()
@observer
class AdminView extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore, t } = this.props;
    const { title } = routerStore?.route?.params;

    this.title = t(title);
    document.title = t(title);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="container-grid-responsive">
        <PageHeader>{this.title}</PageHeader>
        <Nav bsStyle="pills">
          <NavItem href={OPS_ENROLLMENT_SEARCH_VIEW}>
            {t('Enrollment Search')}
          </NavItem>
          <NavItem href={OPS_ACCOUNTS_VIEW}>{t('Accounts')}</NavItem>
          <NavItem href={OPS_COURSES_VIEW}>{t('Courses')}</NavItem>
          <NavItem href={OPS_CLASSES_VIEW}>{t('Classes')}</NavItem>
          <NavItem href={OPS_RHLC_USERS_VIEW}>{t('RHLC Users')}</NavItem>
          <NavLink routeName="admin:notifications:list">
            {t('Notifications')}
          </NavLink>
          <NavItem href={OPS_CONTENT_MANAGER_VIEW}>
            {t('Content Manager')}
          </NavItem>
          <NavItem href={OPS_SCHEMA_VIEW}>{t('Schema')}</NavItem>
          <NavItem href={OPS_DJANGO_ADMIN_VIEW}>{t('Django Admin')}</NavItem>
          <NavLink routeName="admin:reports">{t('Reports')}</NavLink>
          <NavLink routeName="admin:alerts:list">{t('Alerts')}</NavLink>
          <NavLink routeName="admin:management-reporting">
            {t('Management Reporting')}
          </NavLink>
          <NavLink routeName="admin:labs:list">{t('Stuck labs')}</NavLink>
        </Nav>
      </div>
    );
  }
}

export default withErrorBoundary(AdminView, GenericViewError, handleError);
