import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { observer, useLocalStore } from 'mobx-react';

import { Button, Modal, ModalVariant } from '@patternfly/react-core';

import ViewLoader from '../../../Loading/View';
import { formatDateTime } from '../../../../services/UtilityService';
import { storesContext } from '../../../../stores';
import './styles.scss';

import CancelSessionEnrollmentModalStore from './store';

const CancelSessionEnrollmentModal = (props) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const {
    sessionEnrollmentId,

    show,
    onClose,
    scheduledSessionDetail,
    sessionTitle,
    onCancelSessionSuccess,
  } = props;
  const cancelMessage = scheduledSessionDetail.doc_id
    ? t(
        `You are about to cancel your scheduled class session for ${sessionTitle} on ${formatDateTime(
          scheduledSessionDetail.start_time,
          locale,
        )} .`,
      )
    : '';

  const cancelSureMessage = t(
    `Are you sure you want to continue with the cancellation? If there are no seats remaining in this class, you will not be able to reschedule.`,
  );

  const { classesStore } = useContext(storesContext);

  const store = useLocalStore(
    () => new CancelSessionEnrollmentModalStore(classesStore),
  );
  const onCloseModal = (e) => {
    if (e) e.stopPropagation();
    store.toggleModal();
    onClose();
  };
  store.isShowModal = show;

  const renderCancelSessionModal = () => {
    return (
      <Modal
        variant={ModalVariant.small}
        title={t('Class cancellation')}
        isOpen={store.isShowModal}
        onClose={(e) => {
          onCloseModal(e);
        }}
        actions={[
          <Button
            key="confirm"
            isLoading={store.submitting}
            isDisabled={store.submitting}
            variant="danger"
            onClick={async (e) => {
              e.stopPropagation();
              await store.cancelEnrollment(t, sessionEnrollmentId);
              if (!store.submitProgress.hasError) {
                onCancelSessionSuccess();
              }
            }}
          >
            {store.submitting
              ? t('Canceling')
              : t('Confirm class cancellation')}
          </Button>,
          <Button
            key="abort"
            isDanger
            isDisabled={!store.isAllowToClose}
            variant="link"
            onClick={async (e) => {
              onCloseModal(e);
            }}
          >
            {t('Abort')}
          </Button>,
        ]}
      >
        <div className="progress-message">{cancelMessage}</div>
        <div className="progress-message">{cancelSureMessage}</div>

        <div
          className={
            !store.submitProgress?.hasError
              ? 'progress-message success-message'
              : 'progress-message error-message'
          }
        >
          {store.submitProgress.message}
        </div>
      </Modal>
    );
  };
  return (
    <div className="container-grid-responsive">
      <React.Suspense fallback={<ViewLoader />}>
        {renderCancelSessionModal()}
      </React.Suspense>
    </div>
  );
};
CancelSessionEnrollmentModal.propTypes = {
  sessionEnrollmentId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  scheduledSessionDetail: PropTypes.object,
  sessionTitle: PropTypes.string,
  onCancelSessionSuccess: PropTypes.func,
};

CancelSessionEnrollmentModal.defaultProps = {
  sessionEnrollmentId: '',
  show: false,
  onClose: () => {},
  scheduledSessionDetail: {},
  sessionTitle: '',
  onCancelSessionSuccess: () => {},
};
export default observer(CancelSessionEnrollmentModal);
