import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const TechOverviewCollateral = (props) => {
  const { collateral } = props;
  const { t } = useTranslation();

  /* eslint-disable camelcase */
  return (
    <React.Fragment>
      {collateral.description && (
        <div
          className="course-collateral-description"
          dangerouslySetInnerHTML={{
            __html: collateral?.description?.body,
          }}
        />
      )}
      {collateral.audience && (
        <div
          className="course-collateral-audience"
          dangerouslySetInnerHTML={{
            __html: `<h3>${t('Audience')}</h3> ${collateral?.audience?.body}`,
          }}
        />
      )}
      <div
        className="course-collateral-prerequisites"
        dangerouslySetInnerHTML={{
          __html: `<h3>${t('Prerequisites')}</h3>
          ${collateral?.prerequisites?.body || t('None')}`,
        }}
      />
    </React.Fragment>
  );
};
/* eslint-enable camelcase */

TechOverviewCollateral.propTypes = {
  collateral: PropTypes.object.isRequired,
};

export default TechOverviewCollateral;
