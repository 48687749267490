import React from 'react';

import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { observer, useLocalStore } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Panel } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import PlusCircleIcon from '../../../../img/icons/plus-circle.svg';

import TypeAheadSearchStore from './store';

import './styles.scss';

const TypeAheadSearch = (props) => {
  const { t } = useTranslation();
  const { placeHolderText, dataToSearchFrom, labelKey } = props;
  const store = useLocalStore(() => new TypeAheadSearchStore());
  const getFirstLineElement = () => {
    return (
      <React.Fragment>
        <Typeahead
          clearButton
          id="typeaheadsearch"
          labelKey={labelKey}
          onChange={(e) => {
            store.setSearchItem(e);
          }}
          options={dataToSearchFrom}
          selected={store.selectedSearchItem}
          placeholder={placeHolderText}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Panel id="typeahead-panel-card" className="typeahead-panel-card">
        <Panel.Body>
          <Row>
            <Col className="col-md-1 col-sm display-flex">
              <div className="search-icon">
                <SVG src={PlusCircleIcon} title={t('Skills Path')} />
              </div>
            </Col>
            <Col className="col-md-11 col-sm ">{getFirstLineElement()}</Col>
          </Row>
        </Panel.Body>
        <Panel.Footer
          className="panel-footer"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="footer-elements-container">
            <div className="actions-container">
              <Button
                data-analytics-id="accept-btn-cform-cskp-lp"
                key={uuidv4()}
                disabled={store.selectedSearchItem?.length <= 0}
                onClick={(e) => {
                  e.preventDefault();
                  if (store.selectedSearchItem?.length > 0) {
                    props.onAcceptSearch(store.selectedSearchItem);
                  }
                }}
              >
                {t('Accept')}
              </Button>
            </div>
          </div>
        </Panel.Footer>
      </Panel>
    </React.Fragment>
  );
};

TypeAheadSearch.propTypes = {
  dataToSearchFrom: PropTypes.array.isRequired,
  labelKey: PropTypes.string.isRequired,
  onAcceptSearch: PropTypes.func.isRequired,
  placeHolderText: PropTypes.string,
};
TypeAheadSearch.defaultProps = {
  placeHolderText: '',
};

export default observer(TypeAheadSearch);
