import React from 'react';
import OfferingIcon from 'components/OfferingIcon';
import { toUpper } from 'lodash';
import { CatalogEntry } from 'types';

type CourseNameCellProps = {
  row: {
    original: {
      catalog_entry: CatalogEntry;
      id: string;
    };
    index: number;
  };
  cell: {
    value: string;
  };
};

const CourseNameCell: React.FC<CourseNameCellProps> = ({ row, cell }) => {
  const catalogEntry = row?.original?.catalog_entry;

  return (
    <div className="course-progress__table-row">
      <span className="course-progress__table-row-number">{`${
        row?.index + 1
      }.`}</span>
      <div>
        <OfferingIcon
          offeringCode={catalogEntry?.code}
          offeringType={catalogEntry?.course_type}
          offeringVisibility={catalogEntry?.visibility}
          size={40}
        />
      </div>
      <div>{`${toUpper(row?.original?.id)} - ${cell?.value}`}</div>
    </div>
  );
};

export default CourseNameCell;
