import { observable } from 'mobx';

class RedirectStore {
  @observable hasRelatedData = false;

  @observable hasCatalogData = false;

  @observable urlPattern = 'redirect/';

  @observable loaded = false;
}
export default RedirectStore;
