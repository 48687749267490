import { observable, action } from 'mobx';
import moment from 'moment';
import { validateIncomingFiles } from '../../../services/UtilityService';
import {
  getAdminReport,
  generateCSV,
  parseCSVFiles,
} from '../../../services/ReportingService';

class UserListReportStore {
  @observable usernames = [];

  @observable successMsgVisible = false;

  @observable errorMsgVisible = false;

  @observable errorMessage = '';

  @observable loading = false;

  @observable CSVInputVisible = true;

  @action parseCSVFile = async (acceptedFiles, rejectedFiles) => {
    const validFiles = validateIncomingFiles(
      acceptedFiles,
      rejectedFiles,
      'csv',
    );
    const usernames = await parseCSVFiles(validFiles);

    this.CSVInputVisible = false;
    this.usernames = [...usernames];
  };

  @action resetInput = () => {
    this.usernames = [];
    this.CSVInputVisible = true;
  };

  @action fetchReport = async () => {
    this.hideErrorMessage();

    try {
      this.loading = true;
      const report = await getAdminReport('ibm', {
        usernames: this.usernames,
      });
      const format = 'YYYY-MM-DD';
      const date = moment().format(format);
      if (report) {
        generateCSV(report, `user_activity_${date}.csv`);
        this.showSuccessMessage();
      }
    } catch (error) {
      this.showErrorMessage(error.message);
    } finally {
      this.loading = false;
    }
  };

  @action showErrorMessage = (error) => {
    this.errorMessage = error;
    this.errorMsgVisible = true;
  };

  @action hideErrorMessage = () => {
    this.errorMessage = '';
    this.errorMsgVisible = false;
  };

  @action showSuccessMessage = () => {
    this.successMsgVisible = true;
    setTimeout(() => {
      this.hideSuccessMessage();
    }, 10000);
  };

  @action hideSuccessMessage = () => {
    this.successMsgVisible = false;
  };
}

export default UserListReportStore;
