import { action, observable, computed } from 'mobx';
import { keyBy, get as lodashGet } from 'lodash';
import { VocabularyNamespaces } from 'types';
import {
  getVocabularyList,
  getVocabularyByNamespace,
} from '../services/VocabularyService';

class VocabularyStore {
  @observable vocabulary = [];

  @observable countriesByLanguage = [];

  @observable countriesByCurrentLanguage = [];

  @observable jobRolesByCurrentLanguage = [];

  @observable departmentsByCurrentLanguage = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get vocabularyMap() {
    try {
      const vocabulariesWithTokenMap = this.vocabulary.map((v) => {
        return { ...v, vocabulary: keyBy(v.vocabulary, 'token') };
      });

      const finalMap = keyBy(vocabulariesWithTokenMap, (obj) => {
        return `${obj.slug}@@${obj.language}`;
      });

      return finalMap;
    } catch {
      return {};
    }
  }

  @action getVocabularyMapTokensByNamespace = async (language, slug) => {
    let found = this.vocabularyMap[`${slug}@@${language}`];

    if (!found) {
      const namespace = await getVocabularyByNamespace(slug);
      if (namespace) {
        this.vocabulary.push(namespace);
        found = {
          ...namespace,
          vocabulary: keyBy(namespace.vocabulary, 'token'),
        };
      } else {
        found = {};
      }
    }

    const tokensVocabulary = found.vocabulary || {};
    return (tokenKey) =>
      lodashGet(tokensVocabulary, `${tokenKey}.display_name`, tokenKey);
  };

  @action getVocabularyByNamespace = async (slug, language = 'en-US') => {
    const found = this.vocabulary.find(
      (namespace) => namespace.slug === slug && namespace.language === language,
    );

    if (found) return found.vocabulary;

    const namespace = await getVocabularyByNamespace(slug);

    if (namespace) this.vocabulary.push(namespace);

    return namespace || {};
  };

  @action getVocabularyForCountries = async (language = 'en-US') => {
    const slug = VocabularyNamespaces.Countries;
    const found = this.countriesByLanguage.find((c) => c.language === language);

    if (found) return found;

    let namespace = await getVocabularyByNamespace(slug, language);
    if (!namespace) {
      namespace = await getVocabularyByNamespace(
        VocabularyNamespaces.Countries,
        'en-US',
      );
    }
    if (namespace) this.countriesByLanguage.push(namespace);

    return namespace || {};
  };

  /**
   * Get current UI language and return array of countries
   * @returns {Array} countriesByCurrentLanguage - Example: [{ display_name: "Netherlands", token: "NL" }]
   */
  @action fetchCountriesForCurrentLanguage = async () => {
    try {
      this.countriesByCurrentLanguage = await this.getVocabularyForCountries(
        this.rootStore.uiStore.currentLanguage,
      );
    } catch {
      this.countriesByCurrentLanguage = [];
    } finally {
      return this.countriesByCurrentLanguage;
    }
  };

  /**
   * Get current UI language and return array of job roles
   * @returns {Array} jobRolesOptions - Example: [{ display_name: "DevOps Engineer", token: "devops_engineer" }]
   */
  @action fetchJobRolesForCurrentLanguage = async () => {
    try {
      this.jobRolesByCurrentLanguage = await this.getVocabularyByNamespace(
        VocabularyNamespaces.JobRoles,
        this.rootStore.uiStore.currentLanguage,
      );
    } catch {
      this.jobRolesByCurrentLanguage = [];
    } finally {
      return this.jobRolesByCurrentLanguage;
    }
  };

  /**
   * Get current UI language and return array of job roles
   * @returns {Array} departmentOptions - Example: [{ display_name: "IT - Telecom", token: "it_telecom" }]
   */
  @action fetchDepartmentsForCurrentLanguage = async () => {
    try {
      this.departmentsByCurrentLanguage = await this.getVocabularyByNamespace(
        VocabularyNamespaces.Departments,
        this.rootStore.uiStore.currentLanguage,
      );
    } catch {
      this.departmentsByCurrentLanguage = [];
    } finally {
      return this.departmentsByCurrentLanguage;
    }
  };

  @action getVocabularyList = async (language = 'en-US') => {
    const vocabulary = await getVocabularyList(language);

    if (vocabulary) {
      this.vocabulary = vocabulary;
    }

    return this.vocabulary;
  };
}

export default VocabularyStore;
