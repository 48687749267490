import { action, observable } from 'mobx';

class PremiumFilterStore {
  @observable isCalendarOpen = false;

  @observable isCalendarTimeOpen = false;

  @observable numberOfMonths = 2;

  @action toggleCalendar = () => {
    this.isCalendarOpen = !this.isCalendarOpen;
  };

  @action toggleCalendarTime = () => {
    this.isCalendarTimeOpen = !this.isCalendarTimeOpen;
  };
}
export default PremiumFilterStore;
