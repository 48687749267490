import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'react-bootstrap';
import { storesContext } from 'stores';
import { DRAWER_SECTIONS } from 'config/constants';
import DrawerCard from '../DrawerCard';
import './styles.scss';
import UserNotification from '../UserNotification';

const AllPane = () => {
  const { t } = useTranslation();
  const { eventStore, userNotificationsStore, allPaneStore, userStore } =
    useContext(storesContext);

  useEffect(() => {
    userNotificationsStore.getUserNotifications(userStore.user.uuid);
  }, [eventStore.isDrawerOpen]);

  const sectionHeaderClick = (label) => {
    eventStore.isDrawerActive = label;
  };

  const filterNotifications = () => {
    const notificationList = !allPaneStore.isArchivesChecked
      ? eventStore.newNotifications
      : eventStore.existingNotifications;

    const notifications = notificationList.filter((notification) => {
      return notification.type === eventStore.isDrawerActive;
    });

    const otherNotifications = notificationList.filter((notification) => {
      return !notification.type || notification.type === 'other';
    });

    const allNotifications = notificationList.map((notification) => {
      return (
        <DrawerCard key={notification.doc_id} notification={notification} />
      );
    });

    switch (eventStore.isDrawerActive) {
      case 'all':
        return allNotifications;
      case 'other':
        return otherNotifications.map((notification) => {
          return (
            <DrawerCard key={notification.doc_id} notification={notification} />
          );
        });
      default:
        return notifications.map((notification) => {
          return (
            <DrawerCard key={notification.doc_id} notification={notification} />
          );
        });
    }
  };

  const onSelecting = () => {
    allPaneStore.isArchivesChecked = !allPaneStore.isArchivesChecked;
  };

  const renderAllNotifications = () => {
    // retrieve all notifications
    const { userNotificationsToDisplay } = userNotificationsStore;
    const notificationList = !allPaneStore.isArchivesChecked
      ? eventStore.newNotifications
      : eventStore.existingNotifications;

    /*
      merge both arrays into one and sort them by date
      we are mixing two different objects here
      `userNotificationToDisplay` contains `user_notification` documents
      `notificationList` contains artifically created notification objects
      (see Event.js -> triggerLocalNotification() | allNotifications())
    */
    const mergedNotificationList =
      userNotificationsToDisplay.concat(notificationList);
    mergedNotificationList.sort((a, b) => {
      return Date.parse(b['@timestamp']) - Date.parse(a['@timestamp']);
    });

    // create DrawerCard based on the type of object
    // if the item comes from `userNotificationToDisplay` (doc_type === 'user_notification')
    // then create a UserNotification card, otherwise create a DrawerCard
    return mergedNotificationList.map((notification) => {
      // eslint-disable-next-line no-prototype-builtins
      if (notification.hasOwnProperty('doc_type'))
        return <UserNotification userNotification={notification} />;
      return (
        <DrawerCard key={notification.doc_id} notification={notification} />
      );
    });
  };

  return (
    <React.Fragment>
      <ul className="section-wrapper">
        <li className="section-header">
          <div
            className={`${
              allPaneStore.isArchivesChecked ? 'checked' : ''
            } section-header-btn archives`}
          >
            <Checkbox
              data-analytics-id="archives-notification-lp"
              checked={allPaneStore.isArchivesChecked}
              className="custom-control-input"
              onChange={() => {
                onSelecting();
              }}
              name="check"
            >
              {t('Archives')}
            </Checkbox>
          </div>
        </li>
        {DRAWER_SECTIONS.map((section) => {
          const label = section.label.toLowerCase();
          return (
            <li className="section-header" key={section.key}>
              <div
                className={`${
                  eventStore.isDrawerActive === label ? 'active' : ''
                } section-header-btn ${label}`}
                onClick={() => sectionHeaderClick(label)}
                role="button"
                tabIndex={section.key}
                data-analytics-id={`${label}-option-notification-lp`}
              >
                {t(`${section.label}`)}
              </div>
            </li>
          );
        })}
      </ul>
      {eventStore.isDrawerActive === 'support' && (
        <div
          className="open-support-from-pane-btn"
          role="button"
          tabIndex="0"
          onClick={() => {
            eventStore.isDrawerActive = 'support_form';
          }}
        >
          {t('Open a support case')}
        </div>
      )}
      <div className="drawer-section">
        {eventStore.isDrawerActive === 'reports' && (
          <div className="reports-msg">
            {t(
              "You can follow the progress of your reports being processed here. You will be notified when they're ready to be downloaded.",
            )}
          </div>
        )}
        {(eventStore.isDrawerActive === 'all' && renderAllNotifications()) ||
          filterNotifications()}
      </div>
    </React.Fragment>
  );
};

export default observer(AllPane);
