import React, { Suspense } from 'react';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { PageHeader } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../../services/ErrorService';
import GenericViewError from '../../../../components/Error/GenericViewError';
import ViewLoader from '../../../../components/Loading/View';

const GenerationForm = React.lazy(() =>
  import(
    /* webpackChunkName: 'coa-generation-form' */ '../../../../components/CertificateOfAttendance/GenerationForm'
  ),
);

const GenerationFormView = (props) => {
  const { routerStore } = props;
  const { title } = routerStore?.route?.params;

  document.title = title;

  return (
    <div className="container-grid-responsive">
      <PageHeader>{title}</PageHeader>
      <Suspense fallback={<ViewLoader />}>
        <GenerationForm />
      </Suspense>
    </div>
  );
};

GenerationFormView.propTypes = {
  routerStore: MobXPropTypes.observableObject.isRequired,
};

export default withErrorBoundary(
  inject('routerStore')(observer(GenerationFormView)),
  GenericViewError,
  handleError,
);
