import { action, observable, computed } from 'mobx';
import ClassesStore from '../../../../stores/Classes';

class CancelSessionEnrollmentModalStore {
  @observable submitting = false;

  @observable isShowModal = false;

  @observable submitProgress = {};

  constructor(classesStore) {
    this.classesStore = classesStore;
  }

  @action toggleModal = () => {
    this.isShowModal = !this.isShowModal;
  };

  @computed get isAllowToCancel() {
    return (!this.submitProgress?.hasError || false) && !this.submitting;
  }

  @computed get isAllowToClose() {
    return !this.submitting;
  }

  @action cancelEnrollment = async (t, selectedSessionToCancel) => {
    try {
      this.submitting = true;
      this.submitProgress = {};
      await this.classesStore.cancelPremVTUserEnrollment(
        selectedSessionToCancel,
      );

      this.submitProgress = {
        hasError: false,
        message: t('Your session has been cancelled successfully.'),
      };
    } catch (e) {
      const errorMessage = e.response.data?.detail || '';

      this.submitProgress = {
        hasError: true,
        message: ClassesStore.getUseUnEnrollErrorMessage(errorMessage, t),
      };
    } finally {
      this.submitting = false;
      return this.submitProgress;
    }
  };
}

export default CancelSessionEnrollmentModalStore;
