/**
 * @file Course Catalog filters
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  Checkbox,
  Form,
  FormGroup,
  Panel,
  PanelGroup,
  Button,
} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { storesContext } from 'stores';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import './styles.scss';

const LearningPathsFilters = () => {
  const { t } = useTranslation();
  const { learningPathStore, userStore, routerStore } =
    useContext(storesContext);

  const onChange = (key, value, e) => {
    const { checked } = e.target;

    const found = learningPathStore.filters[key]?.find(
      (filter) => filter.key === value,
    );

    if (!found) return;

    found.checked = checked;
    learningPathStore.filterEntries();
  };

  const isChecked = (filterKey, key) =>
    learningPathStore.filters[filterKey].find((filter) => filter.key === key)
      ?.checked;

  const isExpanded = (id) => learningPathStore.expandedFilterGroups?.has(id);

  const renderFilterGroup = (id, key, title) => (
    <Panel
      eventKey={id}
      onToggle={(expanded) => {
        learningPathStore.toggleFilterGroups(id, expanded);
      }}
      data-analytics-id={`${key}-filt-skilp-lp`}
    >
      <Panel.Toggle componentClass="div" className="filter-panel-toggle">
        <Panel.Heading componentClass="header" className="filter-panel-heading">
          <Panel.Title className="filter-panel-heading-title">
            {title}
          </Panel.Title>
          <div className="filter-panel-heading-icon">
            <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
              {isExpanded(id) ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </Panel.Heading>
      </Panel.Toggle>
      <Panel.Body collapsible className="filter-panel-body">
        <Form>
          <FormGroup>
            {learningPathStore.filters[key].map((item) => (
              <div
                key={item?.key}
                className="filter-control-wrapper"
                data-analytics-id={`container-${item?.key}-${key}-filt-skilp-lp`}
              >
                <Checkbox
                  checked={isChecked(key, item?.key)}
                  name={item?.key}
                  className="filter-control"
                  onChange={(e) => onChange(key, item?.key, e)}
                  data-analytics-id={`${item?.key}-${key}-filt-skilp-lp`}
                >
                  {item?.value}
                </Checkbox>
                <small className="filter-control-badge">{item?.count}</small>
              </div>
            ))}
          </FormGroup>
        </Form>
      </Panel.Body>
    </Panel>
  );

  const renderCustomSkillPathButton = () => {
    return (
      <Button
        data-analytics-id="create-custom-btn-skillp-lp"
        key="lp-csk-button"
        className="btn-primary btn-block"
        onClick={() => {
          routerStore.navigate('custom-skill-path');
        }}
      >
        {t('Create Custom Skill Path')}
      </Button>
    );
  };

  return (
    <section className="filters filters-lp">
      <header className="filter-group-heading">{t('Filter by')}</header>
      <header
        data-analytics-id="filt-cancel-btn-skillp-lp"
        className="filter-clear"
        role="button"
        tabIndex="0"
        onClick={() => learningPathStore.clearFilters()}
      >
        {t('Clear all')}
      </header>
      <PanelGroup id="filter-panel">
        {learningPathStore.filters.roles.length
          ? renderFilterGroup(1, 'roles', t('Job Roles'))
          : null}
        {learningPathStore.filters.products.length
          ? renderFilterGroup(2, 'products', t('Products'))
          : null}
        {learningPathStore.filters.categories.length
          ? renderFilterGroup(3, 'categories', t('Categories'))
          : null}
        {learningPathStore.filters.pathTypes.length
          ? renderFilterGroup(4, 'pathTypes', t('Path types'))
          : null}
        {(userStore.currentSubscriptionCode ||
          userStore.isOrganizationAdmin ||
          userStore.isOrganizationManager) && (
          <div className="custom-skill-path">
            {renderCustomSkillPathButton()}
          </div>
        )}
      </PanelGroup>
    </section>
  );
};

export default observer(LearningPathsFilters);
