import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@patternfly/react-core';
import { Footer as FooterBase } from '@ole-ui/ole-ui-components';
import { get as lodashGet } from 'lodash';
import { RELEASE_NOTES_URL } from 'config/constants';
import TrainingLogo from 'img/rh-training-certification-logo.svg';
import { FooterUrls } from 'services/FooterLanguageLinkService';
import { storesContext } from 'stores';
import Trustarc from '../Trustarc';
import ChineseFooter from './ChineseFooter';
import './styles.scss';

const Footer = () => {
  const { i18n, t } = useTranslation();
  const { configStore, userStore } = useContext(storesContext);
  const { showNIITFooter } = configStore;
  const { isRHUSubscriber } = userStore;

  return (
    <>
      <FooterBase
        navAttributes={{
          'aria-label': t('Red Hat Learning Subscription important links'),
        }}
        logo={
          <img
            src={TrainingLogo}
            width={128}
            alt={t('Red Hat Training + Certification logo')}
            data-analytics-id="rh-training-logo-footer-lp"
          />
        }
        rightContent={
          isRHUSubscriber ? (
            <Button
              variant="secondary"
              type="button"
              className="footer-main__light-blue-link"
              data-analytics-id="share-your-ideas-btn-footer-lp"
            >
              {t('Share your ideas')}
            </Button>
          ) : null
        }
        links={[
          {
            'data-analytics-id': 'rh-privacy-policy-footer-lp',
            text: t('Privacy Policy'),
            url: lodashGet(
              FooterUrls,
              i18n.language,
              lodashGet(FooterUrls, 'en-US'),
            ).privacy_policy_url,
          },
          {
            'data-analytics-id': 'rh-rh-training-policies-footer-lp',
            text: t('Red Hat Training Policies'),
            url: lodashGet(
              FooterUrls,
              i18n.language,
              lodashGet(FooterUrls, 'en-US'),
            ).training_policies_url,
          },
          {
            'data-analytics-id': 'rh-terms-of-use-footer-lp',
            text: t('Terms of Use'),
            url: lodashGet(
              FooterUrls,
              i18n.language,
              lodashGet(FooterUrls, 'en-US'),
            ).terms_of_use_url,
          },
          {
            'data-analytics-id': 'rh-all-policies-and-guidelines-footer-lp',
            text: t('All policies and guidelines'),
            url: lodashGet(
              FooterUrls,
              i18n.language,
              lodashGet(FooterUrls, 'en-US'),
            ).all_policies_url,
          },
          {
            'data-analytics-id': 'rh-release-notes-footer-lp',
            text: t('Release Notes'),
            url: RELEASE_NOTES_URL,
          },
          {
            text: t('Cookies management'),
            node: <Trustarc />,
          },
        ]}
      />
      {showNIITFooter && <ChineseFooter />}
    </>
  );
};

export default Footer;
