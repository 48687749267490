import React, { useContext } from 'react';

import { observer, useLocalStore } from 'mobx-react';

import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';

import { storesContext } from 'stores';

import MgmtCustomSkillsPathStore from './store';
import MgmtCustomSkillPathsFilters from './Filters';
import UsersInCustomSkillPath from './UsersInPath';

import './styles.scss';

const MgmtCustomSkillPaths = () => {
  const { organizationStore } = useContext(storesContext);
  const store = useLocalStore(
    () => new MgmtCustomSkillsPathStore(organizationStore),
  );

  const renderFilters = () => {
    return (
      <React.Fragment>
        <MgmtCustomSkillPathsFilters
          enableFilters={store.usersInPathLoaded}
          onSearchUsers={(query) => {
            store.onSearchUsers(query);
          }}
          onSelectSkillPath={async (skillPathId) => {
            if (!skillPathId) {
              organizationStore.customSkillPaths = [];
              store.usersInPath = [];
            }
            await store.getUserProgressForACustomSkillPath(
              organizationStore.organization?.id,
              skillPathId,
            );
          }}
        />
      </React.Fragment>
    );
  };

  const renderUsersInSelectedSkillPath = () => {
    return (
      <React.Fragment>
        <UsersInCustomSkillPath
          usersInPath={store.paginatedUserEntries}
          isUsersLoaded={store.usersInPathLoaded}
          selectedSkillPath={store.selectedSkillPath}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="mgmt-csk-container">
        {renderFilters()}
        {renderUsersInSelectedSkillPath()}
        <div className="user-list-pagination">
          <UltimatePagination
            currentPage={store.currentPage}
            totalPages={store.totalPages}
            onChange={store.setCurrentPage}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(MgmtCustomSkillPaths);
