import React from 'react';

import { Flex, Skeleton } from '@patternfly/react-core';

export default function CourseHeaderSkeletonLoading() {
  return (
    <div className="">
      <Flex
        gap={{ default: 'gapMd' }}
        style={{ marginTop: '6px', marginBottom: '30px' }}
      >
        <Skeleton width="50px" height="20px" />
        <Skeleton width="220px" height="20px" />
      </Flex>
      <Skeleton width="600px" height="54px" />
    </div>
  );
}
