/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import SVG from 'react-inlinesvg';
import { v4 as uuidv4 } from 'uuid';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-mobx-router5';
import { Button as PFButton } from '@patternfly/react-core';
import {
  Alert,
  Button,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Checkbox,
} from 'react-bootstrap';
import { formatDateTime } from 'services/UtilityService';
import VTClassIcon from 'img/icons/virtual_training_small.svg';
import CheckIndicator from 'img/icons/check_indicator_small.svg';
import ScheduledIndicator from 'img/icons/clock_indicator_small.svg';
import UnavailableIndicator from 'img/icons/cross_indicator_small.svg';
import PremiumSessionsStore from 'stores/PremiumSessions';
import { storesContext } from 'stores';
import {
  PREMIUM_VT_SESSION_START_DUE_MINS,
  PREMIUM_VT_SESSION_STATES,
} from 'config/constants';
import BaseCard from 'components/Card';
import InstructorName from 'components/SessionDetails/InstructorName';
import SessionCollateral from './SessionCollateral';
import SessionCardStore from './store';
import CancelSessionEnrollmentModal from '../../CancelSessionModal';
import './styles.scss';

const handleError = () => {
  // TODO: send error to backend analytics service
};

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const SessionCard = (props) => {
  const { t } = useTranslation();
  const {
    course,
    premvt_session_slug,
    sessionContent,
    scheduledSession,
    enrollment,
    showInstructorView,
    onCancelSessionSuccess,
    lastAccessed,
    scheduleAllMode,
    sessionToSchedule,
    isIncluded,
    onIncludeSession,
    sessionModality,
  } = props;

  const { premiumSessionsStore, userStore, premiumSessionFilterStore } =
    useContext(storesContext);

  const [isToCancelSession, setIsToCancelSession] = React.useState(false);
  const [sessionEnrollmentIdToCancel, setSessionEnrollmentIdToCancel] =
    React.useState('');

  const [scheduledSessionToCancel, setScheduledSessionToCancel] =
    React.useState({});

  const { i18n } = useTranslation();
  const locale = i18n.language;

  const { code, name } = course || {};

  const store = useLocalStore(() => new SessionCardStore());

  const timezone = moment.tz.guess() || undefined;

  const TimeInfoTooltip = (
    <Tooltip id="time-info-tooltip">
      {`${t('The start time is based on your time zone')} ${timezone}`}
    </Tooltip>
  );

  const AttendedInfoTooltip = (
    <Tooltip id="time-info-tooltip">
      {`${t('The time of attendance is based on your time zone:')} ${timezone}`}
    </Tooltip>
  );

  const NotAvailableTooltip = (
    <Tooltip id="not-available-tooltip">
      {t('This feature is currently under development.')}
    </Tooltip>
  );

  const renderViewSessionButton = (sessionID) => {
    return (
      <Link
        key={uuidv4()}
        href
        className="class-view"
        routeName="sessions:view"
        routeParams={{
          uuid: sessionID,
          sessionid: sessionID,
          sessionslug: premvt_session_slug,
        }}
        data-analytics-id="view-btn-session-card-lp"
      >
        {t('View').toUpperCase()}
      </Link>
    );
  };

  const renderCancelSessionButton = (sessionEnrollmentId) => {
    return (
      <Button
        key={uuidv4()}
        className="class-view"
        onClick={() => {
          setSessionEnrollmentIdToCancel(sessionEnrollmentId);
          setScheduledSessionToCancel(scheduledSession);
          setIsToCancelSession(!isToCancelSession);
        }}
        data-analytics-id="cancel-btn-session-card-lp"
      >
        {t('Cancel').toUpperCase()}
      </Button>
    );
  };

  const renderRequestSessionButton = (premvtSessionSlug) => {
    return userStore.isFreeTierSubscriber ? (
      <PFButton
        variant="link"
        isDisabled
        data-analytics-id="req-btn-session-card-freeTier-lp"
        className="disabled-patternfly-btn"
      >
        {t('Request').toUpperCase()}
      </PFButton>
    ) : (
      <Link
        href
        key={uuidv4()}
        routeName="premium:sessions:schedule"
        routeParams={{
          sessionslug: premvtSessionSlug,
        }}
        data-analytics-id="req-btn-session-card-lp"
      >
        {t('Request').toUpperCase()}
      </Link>
    );
  };

  const renderScheduleSessionButton = (premvtSessionSlug) => {
    return (
      <Link
        href
        key={uuidv4()}
        routeName="premium:sessions:schedule"
        routeParams={{
          sessionslug: premvtSessionSlug,
        }}
        data-analytics-id="schedule-btn-session-card-lp"
      >
        {t('Schedule').toUpperCase()}
      </Link>
    );
  };

  const renderRescheduleSessionButton = (
    scheduledSessionId,
    premvtSessionSlug,
  ) => {
    return (
      <Link
        href
        key={uuidv4()}
        routeName="premium:sessions:reschedule"
        routeParams={{
          sessionslug: premvtSessionSlug,
          scheduledsessionid: scheduledSessionId,
        }}
      >
        {t('Reschedule').toUpperCase()}
      </Link>
    );
  };

  const renderScheduledSession = () => {
    if (
      scheduleAllMode &&
      sessionToSchedule &&
      enrollment?.state === 'enrolled'
    ) {
      // sessionToSchedule selected in ScheduleAll is not included if
      // user is already enrolled in that session
      onIncludeSession(false, sessionToSchedule);
    }
    const scheduledSessionToUse = sessionToSchedule || scheduledSession;

    const instructorNamesComponentsList = scheduledSessionToUse.instructors.map(
      (instructor) => (
        <InstructorName
          instructor={premiumSessionsStore.getDetailsForInstructor(instructor)}
        />
      ),
    );

    const instructorNames = instructorNamesComponentsList.length
      ? instructorNamesComponentsList.reduce((prev, curr) => [prev, ', ', curr])
      : '';

    const firstLineElement = (
      <React.Fragment>
        <span>
          <b>{`${t('Starts')}: `}</b>
          {!showInstructorView &&
            `${formatDateTime(scheduledSessionToUse.start_time, locale)}`}
          {showInstructorView &&
            `${formatDateTime(scheduledSessionToUse.start_time, locale, 'LT')}`}
          <OverlayTrigger placement="top" overlay={TimeInfoTooltip}>
            <span className="with-info-icon">
              <Glyphicon
                glyph="info-sign"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </span>
          </OverlayTrigger>
        </span>
        <span>
          <b>{`${t('Duration')}: `}</b>
          {`${scheduledSessionToUse.duration_secs / 3600} ${t('hours')}`}
        </span>
      </React.Fragment>
    );

    const secondLineElement = (
      <React.Fragment>
        {!showInstructorView && (
          <span>
            <b>{`${t('Seats')}: `}</b>
            {!userStore.isInstructorOrAdmin
              ? t(
                  premiumSessionsStore.convertAvailableSeatsToRange(
                    scheduledSessionToUse.seats_available,
                  ),
                )
              : t(`${scheduledSessionToUse.seats_available}`)}
          </span>
        )}

        {showInstructorView && (
          <span>
            <b>{`${t('Enrollments')}:`}</b>{' '}
            {scheduledSessionToUse.enrolled_count}
          </span>
        )}
        <span>
          <b>{`${t('Instructor')}:`}</b> {instructorNames}
        </span>
      </React.Fragment>
    );

    const actionButtons = !showInstructorView
      ? [
          renderCancelSessionButton(enrollment?.doc_id, scheduledSession),

          renderRescheduleSessionButton(
            scheduledSession.doc_id,
            scheduledSession.premvt_session_slug,
          ),
          renderViewSessionButton(scheduledSession.doc_id),
        ]
      : [renderViewSessionButton(scheduledSession.doc_id)];

    const svgIconElement = (
      <SVG
        width={40}
        height={40}
        src={ScheduledIndicator}
        title={t('Scheduled')}
      >
        {t('Scheduled Session')}
      </SVG>
    );

    return {
      firstLineElement,
      secondLineElement,
      actionButtons,
      svgIconElement,
    };
  };

  const renderToScheduleSessionForScheduleAll = (svgIconElement) => {
    const { firstLineElement, secondLineElement } = renderScheduledSession();

    const actionButtons = [
      <OverlayTrigger
        key={uuidv4()}
        placement="top"
        overlay={NotAvailableTooltip}
      >
        <Link href key={uuidv4()} className="not-available-btn">
          {t('Reschedule')}
        </Link>
      </OverlayTrigger>,
    ];

    const indicators = [
      <div key={uuidv4()}>
        <Checkbox
          inline
          defaultChecked={isIncluded}
          value={isIncluded}
          onChange={(event) => {
            const { checked } = event.target;
            onIncludeSession(checked, sessionToSchedule);
          }}
        >
          {t('Include')}
        </Checkbox>
      </div>,
    ];

    return {
      svgIconElement,
      firstLineElement,
      secondLineElement,
      actionButtons,
      indicators,
    };
  };

  const renderUnavailableSession = (isLeftOutBySelectedFilters = false) => {
    const secondLineElement = (
      <span>
        {t(
          isLeftOutBySelectedFilters
            ? 'Session not available to schedule for selected filters'
            : 'Session not available to schedule',
        )}
      </span>
    );
    const actionButtons = [renderRequestSessionButton(premvt_session_slug)];

    const svgIconElement = (
      <SVG
        src={UnavailableIndicator}
        width={40}
        height={40}
        title={t(
          isLeftOutBySelectedFilters
            ? 'Unavailable Session (does not fulfill selected filters)'
            : 'Unavailable Session',
        )}
      />
    );

    return { secondLineElement, actionButtons, svgIconElement };
  };

  const renderToBeScheduledSession = () => {
    const secondLineElement = <React.Fragment />;

    const actionButtons = [renderScheduleSessionButton(premvt_session_slug)];

    const svgIconElement = (
      <SVG src={VTClassIcon} title={t('Premium Session')} />
    );

    const availableSession = premiumSessionFilterStore.filteredEntries.find(
      (element) =>
        scheduledSession.premvt_session_slug === element.premvt_session_slug,
    );

    if (!availableSession) {
      return renderUnavailableSession(true);
    }

    if (scheduleAllMode) {
      if (sessionToSchedule)
        return renderToScheduleSessionForScheduleAll(svgIconElement);

      return renderUnavailableSession();
    }

    if (
      !premiumSessionsStore.sessionSlugHasScheduledSessions(premvt_session_slug)
    ) {
      return renderUnavailableSession();
    }

    return { secondLineElement, actionButtons, svgIconElement };
  };

  const renderAttendedSession = () => {
    const attendedSession = scheduledSession;
    const secondLineElement = (
      <React.Fragment>
        <span>
          <b>{`${t('Attended')}:`}</b>{' '}
          {`${formatDateTime(attendedSession.start_time, locale)}`}
          <OverlayTrigger placement="top" overlay={AttendedInfoTooltip}>
            <span className="with-info-icon">
              <Glyphicon
                glyph="info-sign"
                className="timezone-info"
                title={timezone}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </span>
          </OverlayTrigger>
        </span>
      </React.Fragment>
    );

    const actionButtons = [
      renderScheduleSessionButton(premvt_session_slug),
      renderViewSessionButton(scheduledSession.doc_id),
    ];

    const svgIconElement = (
      <SVG width={40} height={40} src={CheckIndicator} title={t('Completed')}>
        {t('Completed Session')}
      </SVG>
    );

    if (sessionToSchedule)
      return renderToScheduleSessionForScheduleAll(svgIconElement);

    return { secondLineElement, actionButtons, svgIconElement };
  };

  const renderCompletedSession = () => {
    const secondLineElement = (
      <React.Fragment>
        <span>
          <b>{`${t('Delivered')}:`}</b>{' '}
          {`${formatDateTime(scheduledSession.start_time, locale)}`}
          <OverlayTrigger placement="top" overlay={AttendedInfoTooltip}>
            <span className="with-info-icon">
              <Glyphicon
                glyph="info-sign"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </span>
          </OverlayTrigger>
        </span>
      </React.Fragment>
    );

    const actionButtons = [renderViewSessionButton(scheduledSession.doc_id)];

    const svgIconElement = (
      <SVG width={40} height={40} src={CheckIndicator} title={t('Delivered')}>
        {t('Delivered Session')}
      </SVG>
    );

    return { secondLineElement, actionButtons, svgIconElement };
  };

  const getRenderSessionElements = () => {
    if (showInstructorView) {
      switch (scheduledSession?.state) {
        case 'completed':
          return renderCompletedSession();
        case 'scheduled':
          return renderScheduledSession();
        default:
          return renderScheduledSession();
      }
    } else {
      switch (enrollment?.state) {
        case 'completed':
          return renderAttendedSession();
        case 'enrolled':
          return renderScheduledSession();
        default:
          return renderToBeScheduledSession(sessionToSchedule);
      }
    }
  };

  const getSessionStateLabel = () => {
    switch (scheduledSession?.state) {
      case 'completed':
        return (
          <React.Fragment>
            <span> {t('Delivered')}</span>
          </React.Fragment>
        );
      case 'scheduled':
        return (
          <React.Fragment>
            <span> {t('Scheduled')}</span>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <span> {t('Confirmed')}</span>
          </React.Fragment>
        );
    }
  };

  const sessionElements = getRenderSessionElements();
  const sessionTitle = `${code} - ${sessionContent.title}`;

  const getSessionCollateral = () => {
    store.getSessionCollateral(premvt_session_slug, locale);
  };

  const isStartingSoon = () => {
    let color = '#f8f8f8';

    if (
      scheduledSession &&
      scheduledSession.start_time &&
      scheduledSession.state !== PREMIUM_VT_SESSION_STATES.completed
    ) {
      const minutesBeforeSessionStarts =
        PremiumSessionsStore.getMinutesBeforeSessionStarts(
          scheduledSession.start_time,
        );
      const dueMins = PREMIUM_VT_SESSION_START_DUE_MINS;

      if (
        minutesBeforeSessionStarts <= dueMins &&
        minutesBeforeSessionStarts > 0
      ) {
        color = '#780000';
      }
    }

    return color;
  };

  return (
    <React.Fragment>
      <BaseCard
        customClassName="premvt-session-card"
        title={sessionContent.title ? `${sessionContent.title}` : `${code}`}
        svgIconElement={sessionElements.svgIconElement}
        firstLineElement={
          sessionElements.firstLineElement || (
            <React.Fragment>
              <span>
                <b>{`${t('Course')}:`}</b> {name}
              </span>
            </React.Fragment>
          )
        }
        secondLineElement={sessionElements.secondLineElement}
        indicatorElementsArray={
          showInstructorView
            ? [getSessionStateLabel()]
            : sessionElements.indicators || []
        }
        actionButtonsArray={sessionElements.actionButtons}
        actionToggle={getSessionCollateral}
        cardColor={isStartingSoon()}
        coloredBottom
        leftPanelElement={lastAccessed && <span>{lastAccessed}</span>}
      >
        <SessionCollateral
          collateral={store.collateral}
          loading={store.loadingCollateral}
          sessionModality={sessionModality}
        />
      </BaseCard>

      {isToCancelSession && (
        <CancelSessionEnrollmentModal
          key="CancelSessionEnrollmentModal"
          show={isToCancelSession}
          sessionEnrollmentId={sessionEnrollmentIdToCancel}
          scheduledSessionDetail={scheduledSessionToCancel}
          sessionTitle={sessionTitle}
          onClose={() => {
            setIsToCancelSession(!isToCancelSession);
          }}
          onCancelSessionSuccess={() => {
            onCancelSessionSuccess();
          }}
        />
      )}
    </React.Fragment>
  );
};

SessionCard.propTypes = {
  course: PropTypes.object.isRequired,
  premvt_session_slug: PropTypes.string.isRequired,
  sessionContent: PropTypes.object.isRequired,
  scheduledSession: PropTypes.object,
  enrollment: PropTypes.object,
  showInstructorView: PropTypes.bool,
  onCancelSessionSuccess: PropTypes.func,
  lastAccessed: PropTypes.object,
  scheduleAllMode: PropTypes.bool,
  sessionToSchedule: PropTypes.object,
  onIncludeSession: PropTypes.func,
  isIncluded: PropTypes.bool,
  sessionModality: PropTypes.string,
};

SessionCard.defaultProps = {
  scheduledSession: {},
  enrollment: undefined,
  showInstructorView: false,
  onCancelSessionSuccess: () => {},
  lastAccessed: undefined,
  scheduleAllMode: false,
  sessionToSchedule: undefined,
  onIncludeSession: () => {},
  isIncluded: false,
  sessionModality: 'course',
};

export default withErrorBoundary(
  observer(SessionCard),
  ErrorMessage,
  handleError,
);
