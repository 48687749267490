import { useEffect } from 'react';

export default function useAutoScrollToPageVideo(
  isVideoPlayerEnabled: boolean,
  id: string = 'hud-course-view__video-widget',
): void {
  useEffect(() => {
    if (isVideoPlayerEnabled) {
      document.getElementById(id)?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isVideoPlayerEnabled]);
}
