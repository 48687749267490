import { observable, action } from 'mobx';
import {
  getAdminReport,
  generateCSV,
} from '../../../services/ReportingService';

class ExpiringSubscriptionsReportStore {
  @observable startDate;

  @observable startDateText;

  @observable endDate;

  @observable endDateText;

  @observable successMsgVisible = false;

  @observable errorMsgVisible = false;

  @observable errorMessage = '';

  @observable loading = false;

  @action fetchReport = async () => {
    if (!this.startDate || !this.endDate) {
      this.errorMessage = 'start date and end date are required';
      this.errorMsgVisible = true;
      return null;
    }

    this.hideErrorMessage();

    try {
      this.loading = true;
      const report = await getAdminReport('expiring_subscriptions', {
        start_date: this.startDate,
        end_date: this.endDate,
      });
      if (report) {
        generateCSV(
          report,
          `expiring_subscriptions_${this.startDate}_${this.endDate}.csv`,
        );
        this.showSuccessMessage();
      }
    } catch (error) {
      this.showErrorMessage(error.message);
    } finally {
      this.loading = false;
    }
    return null;
  };

  @action showErrorMessage = (error) => {
    this.errorMessage = error;
    this.errorMsgVisible = true;
  };

  @action hideErrorMessage = () => {
    this.errorMessage = '';
    this.errorMsgVisible = false;
  };

  @action showSuccessMessage = () => {
    this.successMsgVisible = true;
    setTimeout(() => {
      this.hideSuccessMessage();
    }, 10000);
  };

  @action hideSuccessMessage = () => {
    this.successMsgVisible = false;
  };
}

export default ExpiringSubscriptionsReportStore;
