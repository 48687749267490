import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon, Button, Panel } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';

import LabWebApplicationsStore from './store';
import './styles.scss';

const LabWebApps = (props) => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new LabWebApplicationsStore());
  const isExpanded = (id) => store.expandedPanels?.has(id);
  const { userLab } = props.labStore;

  const LabWebApplications = userLab?.web_applications;

  const redirectClassroomWebapp = (url) => {
    window.open(url, '_blank');
  };

  const applicationCards = (
    <React.Fragment>
      {LabWebApplications?.map((webApplication, index) => {
        return (
          <Panel
            eventKey={`${`classroom-webapp-info ${index}`}`}
            className="lab-webapp-panel"
            onToggle={(expanded) => {
              store.togglePanels(index, expanded);
            }}
          >
            <Panel.Toggle componentClass="div" className="filter-panel-toggle">
              <Panel.Heading
                componentClass="header"
                className="filter-panel-heading"
                data-analytics-id="lab-ctrl-acc-vt-dash-lp"
              >
                <Panel.Title className="filter-panel-heading-title">
                  <div className="filter-panel-heading-icon">
                    <SVG src={isExpanded(index) ? CollapseIcon : ExpandIcon}>
                      {isExpanded(index) ? t('Collapse') : t('Expand')}
                    </SVG>
                    <h4>{webApplication.title || t('Classroom Webapp')}</h4>
                  </div>
                  <div className="lab-ssh-btn-wrapper">
                    <React.Fragment>
                      <Button
                        bsStyle="primary"
                        type="submit"
                        on
                        onClick={() =>
                          redirectClassroomWebapp(webApplication.url)
                        }
                      >
                        <Glyphicon glyph="new-window" /> {t('Access')}
                      </Button>
                    </React.Fragment>
                  </div>
                </Panel.Title>
              </Panel.Heading>
            </Panel.Toggle>
            <Panel.Body collapsible className="filter-panel-body">
              <div className="margin-bottom">
                <p>{webApplication.description}</p>
              </div>
            </Panel.Body>
          </Panel>
        );
      })}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {LabWebApplications && (
        <div className="pf-l-flex.">
          <div className="pf-l-flex__item">
            <h3>{t('Web Applications')}</h3>
          </div>
          <div className="lab-webpanel-row">{applicationCards}</div>
        </div>
      )}
    </React.Fragment>
  );
};

LabWebApps.propTypes = {
  labStore: PropTypes.object,
};

LabWebApps.defaultProps = {
  labStore: {},
};

export default observer(LabWebApps);
