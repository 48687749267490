/* eslint-disable import/prefer-default-export */
import { post } from 'axios';
import { MANAGEMENT_REPORTING_API_BASE } from '../config/constants';

/**
 * For admins only. Given a search substring, returns managers under this admin account.
 * @param {string} search - substring containing parts of full name
 * @return {Array} list of managers found
 */
export const searchManagers = (search, { organizationId }) => {
  const params = {
    by_text: search,
    for: 'managers',
    include_validations: true,
  };

  return post(
    `${MANAGEMENT_REPORTING_API_BASE}/${organizationId}/search`,
    params,
  )
    .then((response) => {
      const list = response.data?.results || [];
      return list.map((u) => ({
        username: u.username,
        email: u.default_email,
        preferredName: u.preferred_name,
        fullName: u.full_name,
        hasAssignees: u.has_assignees,
      }));
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getManagersByUsernameList = (usernameList, organizationId) => {
  const params = {
    by_list: usernameList,
    for: 'managers',
  };

  return post(
    `${MANAGEMENT_REPORTING_API_BASE}/${organizationId}/search`,
    params,
  )
    .then((response) => {
      const list = response.data?.results || [];
      return list.map((u) => ({
        username: u.username,
        email: u.default_email,
        preferredName: u.preferred_name,
        fullName: u.full_name,
        hasAssignees: u.has_assignees,
      }));
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const searchUsers = (search, { organizationId }) => {
  const params = {
    by_text: search,
    for: 'users',
    include_validations: true,
  };

  return post(
    `${MANAGEMENT_REPORTING_API_BASE}/${organizationId}/search`,
    params,
  )
    .then((response) => {
      const list = response.data?.results || [];
      return list.map((u) => ({
        username: u.username,
        email: u.email,
        fullName: u.preferred_name,
      }));
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const searchLMSOrderNumbers = (search, { organizationId }) => {
  const params = {
    by_text: search,
    for: 'lms_order_numbers',
    include_validations: true,
  };

  return post(
    `${MANAGEMENT_REPORTING_API_BASE}/${organizationId}/search`,
    params,
  )
    .then((response) => response.data?.results || [])
    .catch((error) => {
      throw new Error(error);
    });
};
