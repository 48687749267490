import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';

import ViewLoader from '../Loading/View';

const DefaultModal = (props) => {
  const { t } = useTranslation();
  const {
    cancelFunc,
    cancelText,
    confirmFunc,
    confirmText,
    title,
    visible,
    confirmDisabled,
    submitting,
  } = props;

  return (
    <Modal tabIndex="-1" show={visible} onHide={cancelFunc}>
      {title && (
        <ModalHeader tabIndex="-1" closeButton>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      <ModalFooter tabIndex="-1">
        <Button
          bsStyle="primary"
          onClick={confirmFunc}
          disabled={confirmDisabled}
        >
          {!submitting ? (
            t(confirmText)
          ) : (
            <span className="spinner-wrapper">
              <ViewLoader color="#ffffff" />
            </span>
          )}
        </Button>
        <Button onClick={cancelFunc}>{t(cancelText)}</Button>
      </ModalFooter>
    </Modal>
  );
};

DefaultModal.propTypes = {
  cancelFunc: PropTypes.func,
  cancelText: PropTypes.string,
  confirmFunc: PropTypes.func,
  confirmText: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
};

DefaultModal.defaultProps = {
  cancelFunc: () => {},
  cancelText: 'No',
  confirmFunc: () => {},
  confirmText: 'Yes',
  visible: false,
  title: null,
  confirmDisabled: false,
  submitting: false,
};

export default DefaultModal;
