const TIMEZONE_LOCALES = {
  '(+00:00) Africa/Abidjan': 'Africa/Abidjan',
  '(+00:00) Africa/Accra': 'Africa/Accra',
  '(+03:00) Africa/Addis_Ababa': 'Africa/Addis_Ababa',
  '(+01:00) Africa/Algiers': 'Africa/Algiers',
  '(+03:00) Africa/Asmara': 'Africa/Asmara',
  '(+00:00) Africa/Bamako': 'Africa/Bamako',
  '(+01:00) Africa/Bangui': 'Africa/Bangui',
  '(+00:00) Africa/Banjul': 'Africa/Banjul',
  '(+00:00) Africa/Bissau': 'Africa/Bissau',
  '(+02:00) Africa/Blantyre': 'Africa/Blantyre',
  '(+01:00) Africa/Brazzaville': 'Africa/Brazzaville',
  '(+02:00) Africa/Bujumbura': 'Africa/Bujumbura',
  '(+02:00) Africa/Cairo': 'Africa/Cairo',
  '(+01:00) Africa/Casablanca': 'Africa/Casablanca',
  '(+01:00) Africa/Ceuta': 'Africa/Ceuta',
  '(+00:00) Africa/Conakry': 'Africa/Conakry',
  '(+00:00) Africa/Dakar': 'Africa/Dakar',
  '(+03:00) Africa/Dar_es_Salaam': 'Africa/Dar_es_Salaam',
  '(+03:00) Africa/Djibouti': 'Africa/Djibouti',
  '(+01:00) Africa/Douala': 'Africa/Douala',
  '(+01:00) Africa/El_Aaiun': 'Africa/El_Aaiun',
  '(+00:00) Africa/Freetown': 'Africa/Freetown',
  '(+02:00) Africa/Gaborone': 'Africa/Gaborone',
  '(+02:00) Africa/Harare': 'Africa/Harare',
  '(+02:00) Africa/Johannesburg': 'Africa/Johannesburg',
  '(+02:00) Africa/Juba': 'Africa/Juba',
  '(+03:00) Africa/Kampala': 'Africa/Kampala',
  '(+02:00) Africa/Khartoum': 'Africa/Khartoum',
  '(+02:00) Africa/Kigali': 'Africa/Kigali',
  '(+01:00) Africa/Kinshasa': 'Africa/Kinshasa',
  '(+01:00) Africa/Lagos': 'Africa/Lagos',
  '(+01:00) Africa/Libreville': 'Africa/Libreville',
  '(+00:00) Africa/Lome': 'Africa/Lome',
  '(+01:00) Africa/Luanda': 'Africa/Luanda',
  '(+02:00) Africa/Lubumbashi': 'Africa/Lubumbashi',
  '(+02:00) Africa/Lusaka': 'Africa/Lusaka',
  '(+01:00) Africa/Malabo': 'Africa/Malabo',
  '(+02:00) Africa/Maputo': 'Africa/Maputo',
  '(+02:00) Africa/Maseru': 'Africa/Maseru',
  '(+02:00) Africa/Mbabane': 'Africa/Mbabane',
  '(+03:00) Africa/Mogadishu': 'Africa/Mogadishu',
  '(+00:00) Africa/Monrovia': 'Africa/Monrovia',
  '(+03:00) Africa/Nairobi': 'Africa/Nairobi',
  '(+01:00) Africa/Ndjamena': 'Africa/Ndjamena',
  '(+01:00) Africa/Niamey': 'Africa/Niamey',
  '(+00:00) Africa/Nouakchott': 'Africa/Nouakchott',
  '(+00:00) Africa/Ouagadougou': 'Africa/Ouagadougou',
  '(+01:00) Africa/Porto-Novo': 'Africa/Porto-Novo',
  '(+00:00) Africa/Sao_Tome': 'Africa/Sao_Tome',
  '(+02:00) Africa/Tripoli': 'Africa/Tripoli',
  '(+01:00) Africa/Tunis': 'Africa/Tunis',
  '(+02:00) Africa/Windhoek': 'Africa/Windhoek',
  '(−10:00) America/Adak': 'America/Adak',
  '(−09:00) America/Anchorage': 'America/Anchorage',
  '(−04:00) America/Anguilla': 'America/Anguilla',
  '(−04:00) America/Antigua': 'America/Antigua',
  '(−03:00) America/Araguaina': 'America/Araguaina',
  '(−03:00) America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  '(−03:00) America/Argentina/Catamarca': 'America/Argentina/Catamarca',
  '(−03:00) America/Argentina/Cordoba': 'America/Argentina/Cordoba',
  '(−03:00) America/Argentina/Jujuy': 'America/Argentina/Jujuy',
  '(−03:00) America/Argentina/La_Rioja': 'America/Argentina/La_Rioja',
  '(−03:00) America/Argentina/Mendoza': 'America/Argentina/Mendoza',
  '(−03:00) America/Argentina/Rio_Gallegos': 'America/Argentina/Rio_Gallegos',
  '(−03:00) America/Argentina/Salta': 'America/Argentina/Salta',
  '(−03:00) America/Argentina/San_Juan': 'America/Argentina/San_Juan',
  '(−03:00) America/Argentina/San_Luis': 'America/Argentina/San_Luis',
  '(−03:00) America/Argentina/Tucuman': 'America/Argentina/Tucuman',
  '(−03:00) America/Argentina/Ushuaia': 'America/Argentina/Ushuaia',
  '(−04:00) America/Aruba': 'America/Aruba',
  '(−04:00) America/Asuncion': 'America/Asuncion',
  '(−05:00) America/Atikokan': 'America/Atikokan',
  '(−03:00) America/Bahia': 'America/Bahia',
  '(−06:00) America/Bahia_Banderas': 'America/Bahia_Banderas',
  '(−04:00) America/Barbados': 'America/Barbados',
  '(−03:00) America/Belem': 'America/Belem',
  '(−06:00) America/Belize': 'America/Belize',
  '(−04:00) America/Blanc-Sablon': 'America/Blanc-Sablon',
  '(−04:00) America/Boa_Vista': 'America/Boa_Vista',
  '(−05:00) America/Bogota': 'America/Bogota',
  '(−07:00) America/Boise': 'America/Boise',
  '(−07:00) America/Cambridge_Bay': 'America/Cambridge_Bay',
  '(−04:00) America/Campo_Grande': 'America/Campo_Grande',
  '(−05:00) America/Cancun': 'America/Cancun',
  '(−04:00) America/Caracas': 'America/Caracas',
  '(−03:00) America/Cayenne': 'America/Cayenne',
  '(−05:00) America/Cayman': 'America/Cayman',
  '(−06:00) America/Chicago': 'America/Chicago',
  '(−07:00) America/Chihuahua': 'America/Chihuahua',
  '(−06:00) America/Costa_Rica': 'America/Costa_Rica',
  '(−07:00) America/Creston': 'America/Creston',
  '(−04:00) America/Cuiaba': 'America/Cuiaba',
  '(−04:00) America/Curacao': 'America/Curacao',
  '(+00:00) America/Danmarkshavn': 'America/Danmarkshavn',
  '(−07:00) America/Dawson': 'America/Dawson',
  '(−07:00) America/Dawson_Creek': 'America/Dawson_Creek',
  '(−07:00) America/Denver': 'America/Denver',
  '(−05:00) America/Detroit': 'America/Detroit',
  '(−04:00) America/Dominica': 'America/Dominica',
  '(−07:00) America/Edmonton': 'America/Edmonton',
  '(−05:00) America/Eirunepe': 'America/Eirunepe',
  '(−06:00) America/El_Salvador': 'America/El_Salvador',
  '(−07:00) America/Fort_Nelson': 'America/Fort_Nelson',
  '(−03:00) America/Fortaleza': 'America/Fortaleza',
  '(−04:00) America/Glace_Bay': 'America/Glace_Bay',
  '(−04:00) America/Goose_Bay': 'America/Goose_Bay',
  '(−05:00) America/Grand_Turk': 'America/Grand_Turk',
  '(−04:00) America/Grenada': 'America/Grenada',
  '(−04:00) America/Guadeloupe': 'America/Guadeloupe',
  '(−06:00) America/Guatemala': 'America/Guatemala',
  '(−05:00) America/Guayaquil': 'America/Guayaquil',
  '(−04:00) America/Guyana': 'America/Guyana',
  '(−04:00) America/Halifax': 'America/Halifax',
  '(−05:00) America/Havana': 'America/Havana',
  '(−07:00) America/Hermosillo': 'America/Hermosillo',
  '(−05:00) America/Indiana/Indianapolis': 'America/Indiana/Indianapolis',
  '(−06:00) America/Indiana/Knox': 'America/Indiana/Knox',
  '(−05:00) America/Indiana/Marengo': 'America/Indiana/Marengo',
  '(−05:00) America/Indiana/Petersburg': 'America/Indiana/Petersburg',
  '(−06:00) America/Indiana/Tell_City': 'America/Indiana/Tell_City',
  '(−05:00) America/Indiana/Vevay': 'America/Indiana/Vevay',
  '(−05:00) America/Indiana/Vincennes': 'America/Indiana/Vincennes',
  '(−05:00) America/Indiana/Winamac': 'America/Indiana/Winamac',
  '(−07:00) America/Inuvik': 'America/Inuvik',
  '(−05:00) America/Iqaluit': 'America/Iqaluit',
  '(−05:00) America/Jamaica': 'America/Jamaica',
  '(−09:00) America/Juneau': 'America/Juneau',
  '(−05:00) America/Kentucky/Louisville': 'America/Kentucky/Louisville',
  '(−05:00) America/Kentucky/Monticello': 'America/Kentucky/Monticello',
  '(−04:00) America/Kralendijk': 'America/Kralendijk',
  '(−04:00) America/La_Paz': 'America/La_Paz',
  '(−05:00) America/Lima': 'America/Lima',
  '(−08:00) America/Los_Angeles': 'America/Los_Angeles',
  '(−04:00) America/Lower_Princes': 'America/Lower_Princes',
  '(−03:00) America/Maceio': 'America/Maceio',
  '(−06:00) America/Managua': 'America/Managua',
  '(−04:00) America/Manaus': 'America/Manaus',
  '(−04:00) America/Marigot': 'America/Marigot',
  '(−04:00) America/Martinique': 'America/Martinique',
  '(−06:00) America/Matamoros': 'America/Matamoros',
  '(−07:00) America/Mazatlan': 'America/Mazatlan',
  '(−06:00) America/Menominee': 'America/Menominee',
  '(−06:00) America/Merida': 'America/Merida',
  '(−09:00) America/Metlakatla': 'America/Metlakatla',
  '(−06:00) America/Mexico_City': 'America/Mexico_City',
  '(−03:00) America/Miquelon': 'America/Miquelon',
  '(−04:00) America/Moncton': 'America/Moncton',
  '(−06:00) America/Monterrey': 'America/Monterrey',
  '(−03:00) America/Montevideo': 'America/Montevideo',
  '(−04:00) America/Montserrat': 'America/Montserrat',
  '(−05:00) America/Nassau': 'America/Nassau',
  '(−05:00) America/New_York': 'America/New_York',
  '(−05:00) America/Nipigon': 'America/Nipigon',
  '(−09:00) America/Nome': 'America/Nome',
  '(−02:00) America/Noronha': 'America/Noronha',
  '(−06:00) America/North_Dakota/Beulah': 'America/North_Dakota/Beulah',
  '(−06:00) America/North_Dakota/Center': 'America/North_Dakota/Center',
  '(−06:00) America/North_Dakota/New_Salem': 'America/North_Dakota/New_Salem',
  '(−03:00) America/Nuuk': 'America/Nuuk',
  '(−07:00) America/Ojinaga': 'America/Ojinaga',
  '(−05:00) America/Panama': 'America/Panama',
  '(−05:00) America/Pangnirtung': 'America/Pangnirtung',
  '(−03:00) America/Paramaribo': 'America/Paramaribo',
  '(−07:00) America/Phoenix': 'America/Phoenix',
  '(−05:00) America/Port-au-Prince': 'America/Port-au-Prince',
  '(−04:00) America/Port_of_Spain': 'America/Port_of_Spain',
  '(−04:00) America/Porto_Velho': 'America/Porto_Velho',
  '(−04:00) America/Puerto_Rico': 'America/Puerto_Rico',
  '(−03:00) America/Punta_Arenas': 'America/Punta_Arenas',
  '(−06:00) America/Rainy_River': 'America/Rainy_River',
  '(−06:00) America/Rankin_Inlet': 'America/Rankin_Inlet',
  '(−03:00) America/Recife': 'America/Recife',
  '(−06:00) America/Regina': 'America/Regina',
  '(−06:00) America/Resolute': 'America/Resolute',
  '(−05:00) America/Rio_Branco': 'America/Rio_Branco',
  '(−03:00) America/Santarem': 'America/Santarem',
  '(−04:00) America/Santiago': 'America/Santiago',
  '(−04:00) America/Santo_Domingo': 'America/Santo_Domingo',
  '(−03:00) America/Sao_Paulo': 'America/Sao_Paulo',
  '(−01:00) America/Scoresbysund': 'America/Scoresbysund',
  '(−09:00) America/Sitka': 'America/Sitka',
  '(−04:00) America/St_Barthelemy': 'America/St_Barthelemy',
  '(−03:30) America/St_Johns': 'America/St_Johns',
  '(−04:00) America/St_Kitts': 'America/St_Kitts',
  '(−04:00) America/St_Lucia': 'America/St_Lucia',
  '(−04:00) America/St_Thomas': 'America/St_Thomas',
  '(−04:00) America/St_Vincent': 'America/St_Vincent',
  '(−06:00) America/Swift_Current': 'America/Swift_Current',
  '(−06:00) America/Tegucigalpa': 'America/Tegucigalpa',
  '(−04:00) America/Thule': 'America/Thule',
  '(−05:00) America/Thunder_Bay': 'America/Thunder_Bay',
  '(−08:00) America/Tijuana': 'America/Tijuana',
  '(−05:00) America/Toronto': 'America/Toronto',
  '(−04:00) America/Tortola': 'America/Tortola',
  '(−08:00) America/Vancouver': 'America/Vancouver',
  '(−07:00) America/Whitehorse': 'America/Whitehorse',
  '(−06:00) America/Winnipeg': 'America/Winnipeg',
  '(−09:00) America/Yakutat': 'America/Yakutat',
  '(−07:00) America/Yellowknife': 'America/Yellowknife',
  '(+11:00) Antarctica/Casey': 'Antarctica/Casey',
  '(+07:00) Antarctica/Davis': 'Antarctica/Davis',
  '(+10:00) Antarctica/DumontDUrville': 'Antarctica/DumontDUrville',
  '(+10:00) Antarctica/Macquarie': 'Antarctica/Macquarie',
  '(+05:00) Antarctica/Mawson': 'Antarctica/Mawson',
  '(+12:00) Antarctica/McMurdo': 'Antarctica/McMurdo',
  '(−03:00) Antarctica/Palmer': 'Antarctica/Palmer',
  '(−03:00) Antarctica/Rothera': 'Antarctica/Rothera',
  '(+03:00) Antarctica/Syowa': 'Antarctica/Syowa',
  '(+00:00) Antarctica/Troll': 'Antarctica/Troll',
  '(+06:00) Antarctica/Vostok': 'Antarctica/Vostok',
  '(+01:00) Arctic/Longyearbyen': 'Arctic/Longyearbyen',
  '(+03:00) Asia/Aden': 'Asia/Aden',
  '(+06:00) Asia/Almaty': 'Asia/Almaty',
  '(+02:00) Asia/Amman': 'Asia/Amman',
  '(+12:00) Asia/Anadyr': 'Asia/Anadyr',
  '(+05:00) Asia/Aqtau': 'Asia/Aqtau',
  '(+05:00) Asia/Aqtobe': 'Asia/Aqtobe',
  '(+05:00) Asia/Ashgabat': 'Asia/Ashgabat',
  '(+05:00) Asia/Atyrau': 'Asia/Atyrau',
  '(+03:00) Asia/Baghdad': 'Asia/Baghdad',
  '(+03:00) Asia/Bahrain': 'Asia/Bahrain',
  '(+04:00) Asia/Baku': 'Asia/Baku',
  '(+07:00) Asia/Bangkok': 'Asia/Bangkok',
  '(+07:00) Asia/Barnaul': 'Asia/Barnaul',
  '(+02:00) Asia/Beirut': 'Asia/Beirut',
  '(+06:00) Asia/Bishkek': 'Asia/Bishkek',
  '(+08:00) Asia/Brunei': 'Asia/Brunei',
  '(+09:00) Asia/Chita': 'Asia/Chita',
  '(+08:00) Asia/Choibalsan': 'Asia/Choibalsan',
  '(+05:30) Asia/Colombo': 'Asia/Colombo',
  '(+02:00) Asia/Damascus': 'Asia/Damascus',
  '(+06:00) Asia/Dhaka': 'Asia/Dhaka',
  '(+09:00) Asia/Dili': 'Asia/Dili',
  '(+04:00) Asia/Dubai': 'Asia/Dubai',
  '(+05:00) Asia/Dushanbe': 'Asia/Dushanbe',
  '(+02:00) Asia/Famagusta': 'Asia/Famagusta',
  '(+02:00) Asia/Gaza': 'Asia/Gaza',
  '(+02:00) Asia/Hebron': 'Asia/Hebron',
  '(+07:00) Asia/Ho_Chi_Minh': 'Asia/Ho_Chi_Minh',
  '(+08:00) Asia/Hong_Kong': 'Asia/Hong_Kong',
  '(+07:00) Asia/Hovd': 'Asia/Hovd',
  '(+08:00) Asia/Irkutsk': 'Asia/Irkutsk',
  '(+03:00) Asia/Istanbul': 'Asia/Istanbul',
  '(+07:00) Asia/Jakarta': 'Asia/Jakarta',
  '(+09:00) Asia/Jayapura': 'Asia/Jayapura',
  '(+02:00) Asia/Jerusalem': 'Asia/Jerusalem',
  '(+04:30) Asia/Kabul': 'Asia/Kabul',
  '(+12:00) Asia/Kamchatka': 'Asia/Kamchatka',
  '(+05:00) Asia/Karachi': 'Asia/Karachi',
  '(+05:45) Asia/Kathmandu': 'Asia/Kathmandu',
  '(+09:00) Asia/Khandyga': 'Asia/Khandyga',
  '(+05:30) Asia/Kolkata': 'Asia/Kolkata',
  '(+07:00) Asia/Krasnoyarsk': 'Asia/Krasnoyarsk',
  '(+08:00) Asia/Kuala_Lumpur': 'Asia/Kuala_Lumpur',
  '(+08:00) Asia/Kuching': 'Asia/Kuching',
  '(+03:00) Asia/Kuwait': 'Asia/Kuwait',
  '(+08:00) Asia/Macau': 'Asia/Macau',
  '(+11:00) Asia/Magadan': 'Asia/Magadan',
  '(+08:00) Asia/Makassar': 'Asia/Makassar',
  '(+08:00) Asia/Manila': 'Asia/Manila',
  '(+04:00) Asia/Muscat': 'Asia/Muscat',
  '(+02:00) Asia/Nicosia': 'Asia/Nicosia',
  '(+07:00) Asia/Novokuznetsk': 'Asia/Novokuznetsk',
  '(+07:00) Asia/Novosibirsk': 'Asia/Novosibirsk',
  '(+06:00) Asia/Omsk': 'Asia/Omsk',
  '(+05:00) Asia/Oral': 'Asia/Oral',
  '(+07:00) Asia/Phnom_Penh': 'Asia/Phnom_Penh',
  '(+07:00) Asia/Pontianak': 'Asia/Pontianak',
  '(+09:00) Asia/Pyongyang': 'Asia/Pyongyang',
  '(+03:00) Asia/Qatar': 'Asia/Qatar',
  '(+06:00) Asia/Qostanay': 'Asia/Qostanay',
  '(+05:00) Asia/Qyzylorda': 'Asia/Qyzylorda',
  '(+03:00) Asia/Riyadh': 'Asia/Riyadh',
  '(+11:00) Asia/Sakhalin': 'Asia/Sakhalin',
  '(+05:00) Asia/Samarkand': 'Asia/Samarkand',
  '(+09:00) Asia/Seoul': 'Asia/Seoul',
  '(+08:00) Asia/Shanghai': 'Asia/Shanghai',
  '(+08:00) Asia/Singapore': 'Asia/Singapore',
  '(+11:00) Asia/Srednekolymsk': 'Asia/Srednekolymsk',
  '(+08:00) Asia/Taipei': 'Asia/Taipei',
  '(+05:00) Asia/Tashkent': 'Asia/Tashkent',
  '(+04:00) Asia/Tbilisi': 'Asia/Tbilisi',
  '(+03:30) Asia/Tehran': 'Asia/Tehran',
  '(+06:00) Asia/Thimphu': 'Asia/Thimphu',
  '(+09:00) Asia/Tokyo': 'Asia/Tokyo',
  '(+07:00) Asia/Tomsk': 'Asia/Tomsk',
  '(+08:00) Asia/Ulaanbaatar': 'Asia/Ulaanbaatar',
  '(+06:00) Asia/Urumqi': 'Asia/Urumqi',
  '(+10:00) Asia/Ust-Nera': 'Asia/Ust-Nera',
  '(+07:00) Asia/Vientiane': 'Asia/Vientiane',
  '(+10:00) Asia/Vladivostok': 'Asia/Vladivostok',
  '(+09:00) Asia/Yakutsk': 'Asia/Yakutsk',
  '(+06:30) Asia/Yangon': 'Asia/Yangon',
  '(+05:00) Asia/Yekaterinburg': 'Asia/Yekaterinburg',
  '(+04:00) Asia/Yerevan': 'Asia/Yerevan',
  '(−01:00) Atlantic/Azores': 'Atlantic/Azores',
  '(−04:00) Atlantic/Bermuda': 'Atlantic/Bermuda',
  '(+00:00) Atlantic/Canary': 'Atlantic/Canary',
  '(−01:00) Atlantic/Cape_Verde': 'Atlantic/Cape_Verde',
  '(+00:00) Atlantic/Faroe': 'Atlantic/Faroe',
  '(+00:00) Atlantic/Madeira': 'Atlantic/Madeira',
  '(+00:00) Atlantic/Reykjavik': 'Atlantic/Reykjavik',
  '(−02:00) Atlantic/South_Georgia': 'Atlantic/South_Georgia',
  '(+00:00) Atlantic/St_Helena': 'Atlantic/St_Helena',
  '(−03:00) Atlantic/Stanley': 'Atlantic/Stanley',
  '(+09:30) Australia/Adelaide': 'Australia/Adelaide',
  '(+10:00) Australia/Brisbane': 'Australia/Brisbane',
  '(+09:30) Australia/Broken_Hill': 'Australia/Broken_Hill',
  '(+09:30) Australia/Darwin': 'Australia/Darwin',
  '(+08:45) Australia/Eucla': 'Australia/Eucla',
  '(+10:00) Australia/Hobart': 'Australia/Hobart',
  '(+10:00) Australia/Lindeman': 'Australia/Lindeman',
  '(+10:30) Australia/Lord_Howe': 'Australia/Lord_Howe',
  '(+10:00) Australia/Melbourne': 'Australia/Melbourne',
  '(+08:00) Australia/Perth': 'Australia/Perth',
  '(+10:00) Australia/Sydney': 'Australia/Sydney',
  '(+01:00) Europe/Amsterdam': 'Europe/Amsterdam',
  '(+01:00) Europe/Andorra': 'Europe/Andorra',
  '(+04:00) Europe/Astrakhan': 'Europe/Astrakhan',
  '(+02:00) Europe/Athens': 'Europe/Athens',
  '(+01:00) Europe/Belgrade': 'Europe/Belgrade',
  '(+01:00) Europe/Berlin': 'Europe/Berlin',
  '(+01:00) Europe/Bratislava': 'Europe/Bratislava',
  '(+01:00) Europe/Brussels': 'Europe/Brussels',
  '(+02:00) Europe/Bucharest': 'Europe/Bucharest',
  '(+01:00) Europe/Budapest': 'Europe/Budapest',
  '(+01:00) Europe/Busingen': 'Europe/Busingen',
  '(+02:00) Europe/Chisinau': 'Europe/Chisinau',
  '(+01:00) Europe/Copenhagen': 'Europe/Copenhagen',
  '(+01:00) Europe/Dublin': 'Europe/Dublin',
  '(+01:00) Europe/Gibraltar': 'Europe/Gibraltar',
  '(+00:00) Europe/Guernsey': 'Europe/Guernsey',
  '(+02:00) Europe/Helsinki': 'Europe/Helsinki',
  '(+00:00) Europe/Isle_of_Man': 'Europe/Isle_of_Man',
  '(+03:00) Europe/Istanbul': 'Europe/Istanbul',
  '(+00:00) Europe/Jersey': 'Europe/Jersey',
  '(+02:00) Europe/Kaliningrad': 'Europe/Kaliningrad',
  '(+02:00) Europe/Kiev': 'Europe/Kiev',
  '(+03:00) Europe/Kirov': 'Europe/Kirov',
  '(+00:00) Europe/Lisbon': 'Europe/Lisbon',
  '(+01:00) Europe/Ljubljana': 'Europe/Ljubljana',
  '(+00:00) Europe/London': 'Europe/London',
  '(+01:00) Europe/Luxembourg': 'Europe/Luxembourg',
  '(+01:00) Europe/Madrid': 'Europe/Madrid',
  '(+01:00) Europe/Malta': 'Europe/Malta',
  '(+02:00) Europe/Mariehamn': 'Europe/Mariehamn',
  '(+03:00) Europe/Minsk': 'Europe/Minsk',
  '(+01:00) Europe/Monaco': 'Europe/Monaco',
  '(+03:00) Europe/Moscow': 'Europe/Moscow',
  '(+02:00) Europe/Nicosia': 'Europe/Nicosia',
  '(+01:00) Europe/Oslo': 'Europe/Oslo',
  '(+01:00) Europe/Paris': 'Europe/Paris',
  '(+01:00) Europe/Podgorica': 'Europe/Podgorica',
  '(+01:00) Europe/Prague': 'Europe/Prague',
  '(+02:00) Europe/Riga': 'Europe/Riga',
  '(+01:00) Europe/Rome': 'Europe/Rome',
  '(+04:00) Europe/Samara': 'Europe/Samara',
  '(+01:00) Europe/San_Marino': 'Europe/San_Marino',
  '(+01:00) Europe/Sarajevo': 'Europe/Sarajevo',
  '(+04:00) Europe/Saratov': 'Europe/Saratov',
  '(+03:00) Europe/Simferopol': 'Europe/Simferopol',
  '(+01:00) Europe/Skopje': 'Europe/Skopje',
  '(+02:00) Europe/Sofia': 'Europe/Sofia',
  '(+01:00) Europe/Stockholm': 'Europe/Stockholm',
  '(+02:00) Europe/Tallinn': 'Europe/Tallinn',
  '(+01:00) Europe/Tirane': 'Europe/Tirane',
  '(+04:00) Europe/Ulyanovsk': 'Europe/Ulyanovsk',
  '(+02:00) Europe/Uzhgorod': 'Europe/Uzhgorod',
  '(+01:00) Europe/Vaduz': 'Europe/Vaduz',
  '(+01:00) Europe/Vatican': 'Europe/Vatican',
  '(+01:00) Europe/Vienna': 'Europe/Vienna',
  '(+02:00) Europe/Vilnius': 'Europe/Vilnius',
  '(+03:00) Europe/Volgograd': 'Europe/Volgograd',
  '(+01:00) Europe/Warsaw': 'Europe/Warsaw',
  '(+01:00) Europe/Zagreb': 'Europe/Zagreb',
  '(+02:00) Europe/Zaporozhye': 'Europe/Zaporozhye',
  '(+01:00) Europe/Zurich': 'Europe/Zurich',
  '(+03:00) Indian/Antananarivo': 'Indian/Antananarivo',
  '(+06:00) Indian/Chagos': 'Indian/Chagos',
  '(+07:00) Indian/Christmas': 'Indian/Christmas',
  '(+06:30) Indian/Cocos': 'Indian/Cocos',
  '(+03:00) Indian/Comoro': 'Indian/Comoro',
  '(+05:00) Indian/Kerguelen': 'Indian/Kerguelen',
  '(+04:00) Indian/Mahe': 'Indian/Mahe',
  '(+05:00) Indian/Maldives': 'Indian/Maldives',
  '(+04:00) Indian/Mauritius': 'Indian/Mauritius',
  '(+03:00) Indian/Mayotte': 'Indian/Mayotte',
  '(+04:00) Indian/Reunion': 'Indian/Reunion',
  '(+13:00) Pacific/Apia': 'Pacific/Apia',
  '(+12:00) Pacific/Auckland': 'Pacific/Auckland',
  '(+11:00) Pacific/Bougainville': 'Pacific/Bougainville',
  '(+12:45) Pacific/Chatham': 'Pacific/Chatham',
  '(+10:00) Pacific/Chuuk': 'Pacific/Chuuk',
  '(−06:00) Pacific/Easter': 'Pacific/Easter',
  '(+11:00) Pacific/Efate': 'Pacific/Efate',
  '(+13:00) Pacific/Enderbury': 'Pacific/Enderbury',
  '(+13:00) Pacific/Fakaofo': 'Pacific/Fakaofo',
  '(+12:00) Pacific/Fiji': 'Pacific/Fiji',
  '(+12:00) Pacific/Funafuti': 'Pacific/Funafuti',
  '(−06:00) Pacific/Galapagos': 'Pacific/Galapagos',
  '(−09:00) Pacific/Gambier': 'Pacific/Gambier',
  '(+11:00) Pacific/Guadalcanal': 'Pacific/Guadalcanal',
  '(+10:00) Pacific/Guam': 'Pacific/Guam',
  '(−10:00) Pacific/Honolulu': 'Pacific/Honolulu',
  '(+14:00) Pacific/Kiritimati': 'Pacific/Kiritimati',
  '(+11:00) Pacific/Kosrae': 'Pacific/Kosrae',
  '(+12:00) Pacific/Kwajalein': 'Pacific/Kwajalein',
  '(+12:00) Pacific/Majuro': 'Pacific/Majuro',
  '(−09:30) Pacific/Marquesas': 'Pacific/Marquesas',
  '(−11:00) Pacific/Midway': 'Pacific/Midway',
  '(+12:00) Pacific/Nauru': 'Pacific/Nauru',
  '(−11:00) Pacific/Niue': 'Pacific/Niue',
  '(+11:00) Pacific/Norfolk': 'Pacific/Norfolk',
  '(+11:00) Pacific/Noumea': 'Pacific/Noumea',
  '(−11:00) Pacific/Pago_Pago': 'Pacific/Pago_Pago',
  '(+09:00) Pacific/Palau': 'Pacific/Palau',
  '(−08:00) Pacific/Pitcairn': 'Pacific/Pitcairn',
  '(+11:00) Pacific/Pohnpei': 'Pacific/Pohnpei',
  '(+10:00) Pacific/Port_Moresby': 'Pacific/Port_Moresby',
  '(−10:00) Pacific/Rarotonga': 'Pacific/Rarotonga',
  '(+10:00) Pacific/Saipan': 'Pacific/Saipan',
  '(−10:00) Pacific/Tahiti': 'Pacific/Tahiti',
  '(+12:00) Pacific/Tarawa': 'Pacific/Tarawa',
  '(+13:00) Pacific/Tongatapu': 'Pacific/Tongatapu',
  '(+12:00) Pacific/Wake': 'Pacific/Wake',
  '(+12:00) Pacific/Wallis': 'Pacific/Wallis',
};

export default TIMEZONE_LOCALES;
