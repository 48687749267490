import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import SwitchUserIcon from '../../img/icons/switch-user.svg';

const Switcher = ({ userStore }) => {
  const [state, setState] = React.useState({ showModal: false });
  const { t } = useTranslation();

  const onUpdate = (updatedState) => {
    return setState(updatedState);
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        id="user-switcher"
        onClick={() => setState({ showModal: true })}
      >
        <SVG src={SwitchUserIcon} />
        <span>{t('Switch user')}</span>
      </div>
      <UserSwitcherModal
        show={state.showModal}
        onUpdate={onUpdate}
        impersonateUser={(username) => {
          userStore.impersonateUser(username);
        }}
      />
    </>
  );
};

Switcher.propTypes = {
  userStore: MobXPropTypes.observableObject.isRequired,
};

const UserSwitcher = inject('userStore')(observer(Switcher));

const UserSwitcherModal = ({ onUpdate, show, impersonateUser }) => {
  let usernameRef;
  const onHide = () => {
    onUpdate({ showModal: false });
  };
  const { t } = useTranslation();

  const onSubmit = () => {
    const username = usernameRef.value;
    if (username.length > 0) {
      impersonateUser(usernameRef.value);
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">
          {t('Switch User')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          inline
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
        >
          <FormGroup controlId="formInlineUsername">
            <ControlLabel>{t('Username')}</ControlLabel>{' '}
            <FormControl
              inputRef={(el) => {
                usernameRef = el;
              }}
              type="text"
            />
          </FormGroup>{' '}
          <Button
            bsStyle="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            {t('Switch User')}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>{t('Close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

UserSwitcherModal.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  impersonateUser: PropTypes.func.isRequired,
};

export default UserSwitcher;
