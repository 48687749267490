import React, { useContext } from 'react';
import { Button, Carousel, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { homeContext } from '../../../views/Home/store';

const HeroBanner = (props) => {
  const {
    title,
    description,
    imageUrl,
    imageAlt,
    actionText,
    actionType,
    actionUrl,
    actionStyle,
    openActionInNewTab,
    titleColor,
    descriptionColor,
    isAnimation,
    animationUrl,
    ...rest
  } = props;
  const homeStore = useContext(homeContext);
  const { t } = useTranslation();

  const showVideo = (video) => {
    homeStore.toggleTour(video);
  };

  const animationOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    animationData: animationUrl,
  };

  return (
    <Carousel.Item {...rest}>
      {isAnimation ? (
        <Lottie options={animationOptions} className="hero-image" />
      ) : (
        <img className="hero-image" src={imageUrl} alt={imageAlt} />
      )}

      <Carousel.Caption>
        <div className="container-grid-responsive">
          <Col xs={12}>
            <h2
              style={{
                color: titleColor,
              }}
            >
              {t(title)}
            </h2>
            <div className="text-wrapper">
              <p
                style={{
                  color: descriptionColor || '',
                }}
              >
                {t(description)}
              </p>
            </div>
            <div className="btn-wrapper">
              <Button
                data-analytics-id="action-btn-hero-lp"
                bsStyle={actionStyle || 'primary'}
                type="submit"
                {...(actionType === 'showVideo' && {
                  onClick: () => {
                    showVideo(actionUrl);
                  },
                })}
                {...(actionType === 'openLink' && {
                  href: actionUrl,
                })}
                {...(openActionInNewTab && {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                })}
              >
                {t(actionText)}
              </Button>
            </div>
          </Col>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  );
};

HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf(['showVideo', 'openLink']).isRequired,
  actionUrl: PropTypes.string.isRequired,
  openActionInNewTab: PropTypes.bool,
  titleColor: PropTypes.string,
  descriptionColor: PropTypes.string,
  actionStyle: PropTypes.string,
  isAnimation: PropTypes.bool,
  animationUrl: PropTypes.object,
};

HeroBanner.defaultProps = {
  actionStyle: 'primary',
  description: '',
  titleColor: 'white',
  descriptionColor: 'white',
  openActionInNewTab: false,
  isAnimation: false,
  animationUrl: {},
  imageUrl: '../img/hero-banner.png',
};

export default HeroBanner;
