import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

import './styles.scss';

class Datatable extends Component {
  static propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
  };

  static defaultProps = {
    columns: [],
    data: [
      {
        dataField: 'name',
        text: '',
      },
    ],
  };

  render() {
    const { columns, data } = this.props;

    return (
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        striped
        hover
        wrapperClasses="table-responsive"
        rowClasses="text-nowrap"
        {...this.props}
      />
    );
  }
}

export default Datatable;
