import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reaction, when } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';

import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { withErrorBoundary } from 'react-error-boundary';

import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import ViewLoader from '../../components/Loading/View';
import Filters from '../../components/Catalog/Filters';
import { storesContext } from '../../stores';
import './styles.scss';

import { CUSTOM_SKILL_PATH_MODE } from '../../config/constants';
import CustomSkillPathOfferingsList from '../../components/LearningPath/CustomSkillForm/OfferingList';
import CustomSkillPathForm from '../../components/LearningPath/CustomSkillForm';
import CustomSkillPathStore, { customSkillPathContext } from './store';
import ManageUsersInPath from '../../components/LearningPath/CustomSkillForm/ManageUsersInPath';
import OrgLeaderCustomPathSelection from '../../components/LearningPath/CustomSkillForm/OrgLeaderCustomPathSelection';

const CustomSkillPath = () => {
  const { i18n, t } = useTranslation();
  const {
    catalogStore,
    learningPathStore,
    routerStore,
    searchStore,
    uiStore,
    userStore,
    vocabularyStore,
    organizationStore,
  } = useContext(storesContext);

  const store = useLocalStore(
    () =>
      new CustomSkillPathStore(
        catalogStore,
        learningPathStore,
        userStore,
        organizationStore,
        routerStore,
        vocabularyStore,
      ),
  );

  uiStore.title = routerStore?.route?.params?.title;

  const { currentPage, paginatedEntries, setCurrentPage, totalPages } =
    catalogStore;

  const { title } = uiStore;
  document.title = t(title);

  const updateCatalog = async () => {
    const entries = await catalogStore.getCatalogEntries(true);

    if (entries) {
      catalogStore.getFilterInfo(i18n, t, vocabularyStore);
    }
  };

  const presetFilters = () => {
    catalogStore.clearFilters();
    if (routerStore?.route?.params?.q) {
      searchStore.query = routerStore?.route?.params?.q;
    } else {
      searchStore.query = '';
    }
    catalogStore.presetFilters(routerStore?.route?.params?.filter);
  };

  useEffect(() => {
    // Populate entry data and filters on initial load
    when(
      () =>
        !catalogStore.loaded &&
        (userStore.subscription.subscription || userStore.isOrganizationLeader),
      async () => {
        const entries = await catalogStore.getCatalogEntries();

        if (entries) {
          catalogStore.getFilterInfo(i18n, t, vocabularyStore);
        }

        catalogStore.loaded = true;
      },
    );

    // Select preset filters on initial load
    when(
      () => catalogStore.loaded && routerStore?.route?.name,
      async () => {
        await store.init(i18n, t);
        presetFilters();
      },
    );

    // Update preset filters when route changes
    reaction(
      () => catalogStore.loaded && routerStore?.route?.name,
      async () => {
        presetFilters();
      },
    );

    reaction(
      () => catalogStore.loaded && routerStore?.route?.params?.q,
      () => catalogStore.filterEntries(),
    );
    // when leaving this route - refresh catalog based on curret user subscription
    return async () => {
      await updateCatalog();
    };
  }, []);

  const renderAddUsersModal = () => {
    return (
      <ManageUsersInPath
        subscription={store.targetSubscription}
        usersToAdd={store.userListToAdd}
        usersInPath={store.targetUsersInPath}
        title={store.customSkillPathTitle}
        showModal={store.showAddUserModal}
        onCancelUsersToPath={() => {
          store.toggleAddUserToPathModal();
        }}
        onAddUsersToPath={(selectedUsers) => {
          store.setUsersInPath(selectedUsers);
          store.toggleAddUserToPathModal();
        }}
      />
    );
  };

  return (
    <div className="container-grid-responsive custom-skillpath-container">
      <React.Suspense fallback={<ViewLoader />}>
        {store.allowToSelectCustomPathType && (
          <OrgLeaderCustomPathSelection
            subscriptions={store.uniqueSubscriptionInUserListToAdd}
            isSubscriptionsLoading={store.loadingUserListToAdd}
            onSelectMyself={() => {
              store.allowToAddUsersToPath = false;
              store.allowFilters = true;
              store.setTargetSubscription(userStore.currentSubscriptionCode);
              store.setCustomSkillPathMode(CUSTOM_SKILL_PATH_MODE.create);
            }}
            onSelectMyTeam={async (subscriptionCode) => {
              store.allowFilters = true;
              store.allowToAddUsersToPath = true;
              store.setTargetSubscription(subscriptionCode);
              store.setCustomSkillPathMode(CUSTOM_SKILL_PATH_MODE.create);
            }}
          />
        )}
        {store.allowFilters && (
          <div className="flex-row">
            <Filters analytics-suffix="cskp-lp" />

            <div className="flex-full-col">
              <div className="custom-skill-path-form">
                <customSkillPathContext.Provider value={store}>
                  <CustomSkillPathForm />
                </customSkillPathContext.Provider>
              </div>

              <React.Fragment>
                <div className="offering-list-container">
                  <h3 className="available-offering-title">
                    {t('Available Offerings')}
                  </h3>
                </div>
                <div className="offering-list">
                  <CustomSkillPathOfferingsList
                    targetSubscription={store.targetSubscription}
                    offerings={paginatedEntries}
                    offeringsInPath={store.customSkillPath.offerings}
                    onAddToPath={(offeringCode) => {
                      store.addOfferingsToPath(offeringCode);
                    }}
                  />
                </div>
                <div className="catalog-list-pagination">
                  <UltimatePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChange={setCurrentPage}
                  />
                </div>
                {renderAddUsersModal()}
              </React.Fragment>
            </div>
          </div>
        )}
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(CustomSkillPath),
  GenericViewError,
  handleError,
);
