import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, PropTypes as MobXPropTypes, observer } from 'mobx-react';

import Store from './store';
import LabControls from '../LabEnvironment/LabControls';

@inject('userStore')
@observer
class LabPolling extends Component {
  static propTypes = {
    courseSlug: PropTypes.string,
    euuid: PropTypes.string,
    mode: PropTypes.string,
    userStore: MobXPropTypes.observableObject.isRequired,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    courseSlug: '',
    euuid: undefined,
    mode: 'rol',
    uuid: undefined,
  };

  constructor(props) {
    super(props);

    this.store = new Store();
    this.store.determineAPI(this.props.mode);
    this.store.euuid = this.props.euuid;
    this.store.uuid = this.props.uuid;

    this.poll = this.poll.bind(this);
    this.post = this.post.bind(this);
    this.startPolling = this.startPolling.bind(this);
    this.stopPolling = this.stopPolling.bind(this);

    this.poll();
  }

  componentDidMount() {
    this.startPolling();
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  post(command) {
    const data = {
      command,
      course_slug: this.props.courseSlug,
    };

    this.store
      .post(data)
      .then((response) => {
        this.store.application = (response && response.application) || {};
        this.store.userLab = (response && response.user_lab) || {};
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  poll() {
    this.post('poll');
  }

  startPolling() {
    this.poll();
    this.store.intervalId = setInterval(this.poll, this.store.interval);
    if (this.props.userStore.isFreeTierSubscriber) {
      this.store.labUsageIntervalId = setInterval(
        this.props.userStore.getActiveSubscription,
        this.store.subscriptionPollingInterval,
      );
    }
  }

  stopPolling() {
    clearInterval(this.store.intervalId);
    if (this.props.userStore.isFreeTierSubscriber) {
      clearInterval(this.store.labUsageIntervalId);
    }
  }

  render() {
    return <LabControls />;
  }
}

export default LabPolling;
