import { action, computed, observable } from 'mobx';

import _ from 'lodash';
import { postQuery } from '../services/QueryService';

class ZDocManagerStore {
  @observable zdocName = '';

  @observable documents = [];

  @observable rawTerms = {};

  @observable metadata = {};

  @observable matches = {};

  @observable sort = '';

  @observable defaultDocumentsPerPage = 100;

  constructor(zdocname) {
    this.zdocName = zdocname;
    this.resetZDocAttr();
  }

  @action resetZDocAttr() {
    this.rawTerms = {};
    this.ranges = {};
    this.terms = {};
    this.metadata = {};
    this.sort = ''; // should this be a array?
  }

  @action fetch(pageNumber = 0) {
    return new Promise((resolve, reject) => {
      const query = {
        ...(this.rawTerms && {
          raw_terms: this.rawTerms,
        }),
        ...(this.ranges && {
          ranges: this.ranges,
        }),
        ...(this.terms && {
          terms: this.terms,
        }),
        ...(this.sort && {
          kwargs: { sort: this.sort },
        }),
      };

      postQuery(
        this.zdocName,
        query,
        this.documentsPerPage,
        pageNumber,
        this.sort,
      )
        .then((response) => {
          if (!response) {
            resolve({});
          }
          this.documents = response.data.items || [];

          this.metadata.from = response.data.from;
          this.metadata.size = response.data.size;
          this.metadata.total_n_docs = response.data.total_n_docs;

          resolve(this.documents);
        })
        .catch((error) => reject(error))
        .then(() => {});
    });
  }

  @computed get from() {
    return this.metadata?.from || 0;
  }

  @computed get totalDocuments() {
    // eslint-disable-next-line camelcase
    return this.metadata?.total_n_docs || 0;
  }

  @computed get nextPageStartIndex() {
    return this.currentPage + this.documentsPerPage;
  }

  @computed get currentPageStartIndex() {
    return this.from;
  }

  @computed get previousPageStartIndex() {
    return _.max([this.from - this.documentsPerPage, 0]);
  }

  @computed get currentPage() {
    return Math.floor(this.from / this.documentsPerPage);
  }

  @computed get documentsPerPage() {
    return this.metadata.size || this.defaultDocumentsPerPage;
  }

  @computed get totalPages() {
    return (
      Math.ceil(
        this.totalDocuments / this.documentsPerPage,
        this.defaultDocumentsPerPage,
      ) || 1
    );
  }

  @action async gotoPrevPage() {
    return this.fetch(this.previousPageStartIndex);
  }

  @action async gotoNextPage() {
    return this.fetch(this.nextPageStartIndex);
  }

  @action gotoPage(pageNumber) {
    return this.fetch(pageNumber * this.documentsPerPage);
  }
}

export default ZDocManagerStore;
