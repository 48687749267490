import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Col, PageHeader, Row, Tabs, Tab } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import { storesContext } from '../../../stores';
import UserListReport from '../../../components/Reports/UserList';
import ExpiringSubscriptionsReport from '../../../components/Reports/ExpiringSubscriptionsReport';
import ConsumptionReport from '../../../components/Reports/Consumption';

const Reports = () => {
  const { uiStore, routerStore } = useContext(storesContext);
  const { t } = useTranslation();
  const { title } = routerStore?.route?.params;
  document.title = t(title);

  useEffect(() => {
    uiStore.title = title;
  }, []);

  return (
    <div className="container-grid-responsive">
      <PageHeader>{t(title)}</PageHeader>
      <Row>
        <Col xs={12}>
          <Tabs id="reporting-tabs">
            <Tab eventKey="ibm" title={t('User List')}>
              <UserListReport />
            </Tab>
            <Tab
              eventKey="expiring_subscriptions"
              title={t('Expiring subscriptions')}
            >
              <ExpiringSubscriptionsReport />
            </Tab>
            <Tab eventKey="consumption_report" title={t('Page Hits')}>
              <ConsumptionReport />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(Reports),
  GenericViewError,
  handleError,
);
