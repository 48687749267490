import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { PageHeader, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OrganizationList from '../../components/ManagementReporting/OrganizationList';
import OrganizationForm from '../../components/ManagementReporting/OrganizationForm';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import { storesContext } from '../../stores';

// TODO: - Fix string concatentation issue
const OrganizationAdmin = () => {
  const { uiStore, routerStore, organizationStore } = useContext(storesContext);
  const { t } = useTranslation();
  const { title } = routerStore?.route?.params;
  document.title = t(title);

  useEffect(() => {
    uiStore.title = title;
  }, []);

  return (
    <div className="container-grid-responsive">
      <PageHeader>{t(title)}</PageHeader>
      <OrganizationForm />
      <OrganizationList />
      <Modal
        show={organizationStore.modal.opened}
        onHide={organizationStore.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{organizationStore.modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{organizationStore.modal.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={organizationStore.modal.onAction}>
            {organizationStore.modal.actionText}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withErrorBoundary(
  observer(OrganizationAdmin),
  GenericViewError,
  handleError,
);
