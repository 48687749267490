import { delete as del, get, post, put } from 'axios';
import Noty from 'noty';
import uuidv4 from 'uuid/v4';
import { NOTIFICATION_API } from '../config/constants';

const alert = (type, text, timeout, layout) => {
  new Noty({
    type: type || 'default',
    text,
    timeout: typeof timeout !== 'undefined' ? timeout : 15000,
    layout: layout || 'topRight',
    theme: 'metroui',
  }).show();
};

const getNotifications = async () => {
  const notifications = await get(NOTIFICATION_API);

  return notifications?.data?.items;
};

const getNotification = async (uuid) => {
  if (!uuid) {
    throw new Error('UUID required');
  }

  try {
    const notifications = (await get(`${NOTIFICATION_API}${uuid}`)) || [];
    return notifications?.data;
  } catch (error) {
    throw new Error(error);
  }
};

const createOrUpdateNotification = async (data, uuid = uuidv4()) => {
  const endpoint = uuid ? `${NOTIFICATION_API}${uuid}` : NOTIFICATION_API;
  const method = uuid ? put : post;
  const request = uuid ? data : undefined;

  try {
    const notifications = (await method(endpoint, request)) || [];
    return notifications?.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteNotification = async (uuid, t) => {
  if (!uuid) {
    throw new Error(t('UUID required'));
  }

  const notifications = (await del(`${NOTIFICATION_API}${uuid}`)) || [];
  if (!notifications) throw new Error(t('Unable to delete notification'));

  return notifications?.data;
};

export {
  alert,
  getNotifications,
  getNotification,
  createOrUpdateNotification,
  deleteNotification,
};
export default alert;
