import TUWYTimage from '../img/tell_us_img.svg';

const imgs = {
  TUWYTimage,
};

const earlyAccess = {
  button: false,
  description: `Early Access content is under development and your feedback is critical within each course as we finalize and improve the content.`,
  descriptionColor: `white`,
  imageUrl: imgs.TUWYTimage,
  imageAlt: 'Tell us what you think!',
};

export default { earlyAccess };
