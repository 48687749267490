import React, { useContext, useEffect } from 'react';
import { PremiumCalendarNavigationBar } from '@ole-ui/ole-ui-components';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment-timezone';
import { withErrorBoundary } from 'react-error-boundary';
import { BackToTop } from '@patternfly/react-core';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import DayView from './DayView';

import './styles.scss';

import LiveSessionsCalendarStore from './store';

function LiveSessionsCalendar() {
  const {
    catalogStore,
    classesStore,
    premiumSessionsStore,
    premiumSessionFilterStore,
  } = useContext(storesContext);
  const { t } = useTranslation();

  const liveSessionsStore = useLocalStore(
    () =>
      new LiveSessionsCalendarStore(
        catalogStore,
        premiumSessionsStore,
        premiumSessionFilterStore,
        classesStore,
      ),
  );

  const {
    selectedDate,
    timeZoneOffset,
    setViewMode,
    viewMode,
    onNextSelectedDate,
    onPrevSelectedDate,
    canGoToPreviousDate,
  } = liveSessionsStore;

  useEffect(() => {
    const loadData = async () => {
      premiumSessionsStore.getPremiumSessions();
      classesStore.getPremiumSessionEnrollmentsForCurrentUser();
      catalogStore.getAllCatalogEntries();
    };
    loadData();
  }, []);

  return (
    <main className="live-session-calendar">
      <BackToTop className="live-session-calendar__back-to-top" title="" />
      <PremiumCalendarNavigationBar
        navProps={{
          'aria-label': t('Premium Calendar controls'),
        }}
        previousButtonProps={{
          'aria-label': t('Previous day'),
          title: t('Previous day'),
          'data-analytics-id': 'previous-day-btn-premium-calendar-lp',
        }}
        nextButtonProps={{
          'aria-label': t('Next day'),
          title: t('Next day'),
          'data-analytics-id': 'next-day-btn-premium-calendar-lp',
        }}
        title={moment(selectedDate).format('LL')}
        subtitle={timeZoneOffset}
        onPrev={onPrevSelectedDate}
        onNext={onNextSelectedDate}
        viewMode={viewMode}
        onViewModeChange={(mode) => setViewMode(mode)}
        isPrevButtonDisabled={!canGoToPreviousDate}
        options={
          [
            // {
            //   label: t('Day'),
            //   value: 'day',
            // },
            // {
            //   label: t('Week'),
            //   value: 'week',
            // },
            // {
            //   label: t('Month'),
            //   value: 'month',
            // },
          ]
        }
      />
      <div className="live-session-calendar__content">
        {viewMode === 'day' && (
          <DayView liveSessionsStore={liveSessionsStore} />
        )}
      </div>
    </main>
  );
}

export default withErrorBoundary(
  observer(LiveSessionsCalendar),
  GenericViewError,
  handleError,
);
