import { action, observable, computed } from 'mobx';
import { interactiveData } from '../../../../../services/Interactions';

class MultichoiceStore {
  @observable multiChoiceData = [];

  @observable quizId = [];

  @observable checkedValue = [];

  @observable slug = '';

  @observable chapter = '';

  @action interactiveInfoFetch(slug, chapter) {
    this.slug = slug;
    this.chapter = chapter;
    interactiveData(slug, chapter).then((result) => {
      this.multiChoiceData = result;
    });
  }

  @computed get pushedCheckboxData() {
    return this.checkedValue;
  }

  set pushedCheckboxData(value) {
    this.checkedValue.push(value);
  }
}

export default new MultichoiceStore();
