/**
 * @file Provides usage staistics and analysis
 * @author Fola Oso <aoso@redhat.com>
 */

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import {
  Col,
  Form,
  ControlLabel,
  FormControl,
  FormGroup,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import './styles.scss';

const AddManager = () => {
  const { t } = useTranslation();
  const { organizationStore } = useContext(storesContext);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await organizationStore.addManager(username, email);
      organizationStore.getManagers();
      setUsername('');
      setEmail('');
    } catch (err) {
      // eslint-disable-next-line
      console.log('failed to add manager: ' + err);
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit} horizontal>
        <FormGroup>
          <Col md={6}>
            <ControlLabel className="add-manager-form-label">
              {t('Username')}
            </ControlLabel>
            <FormControl
              id="add-manager-username"
              data-analytics-id="add-man-uname-org-mngmt-dboard-lp"
              name="add-manager-username"
              type="text"
              placeholder="Red Hat Username"
              maxLength="120"
              autoCapitalize="off"
              autoCorrect="off"
              value={username}
              onChange={handleUsernameChange}
            />
          </Col>

          <Col md={6}>
            <ControlLabel className="add-manager-form-label">
              {t('Email')}
            </ControlLabel>
            <FormControl
              id="add-manager-email"
              data-analytics-id="add-man-email-org-mngmt-dboard-lp"
              name="add-manager-email"
              type="text"
              placeholder="Email"
              autoCapitalize="off"
              autoCorrect="off"
              value={email}
              onChange={handleEmailChange}
            />
            <div id="add-manager-button">
              <Button
                bsStyle="primary"
                type="submit"
                data-analytics-id="add-man-btn-org-mngmt-dboard-lp"
              >
                {t('Add')}
              </Button>
            </div>
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};
export default withErrorBoundary(
  observer(AddManager),
  GenericViewError,
  handleError,
);
