import React, { Component } from 'react';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { Tabs, Tab } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';
import './styles.scss';

const ClassList = React.lazy(() =>
  import(
    /* webpackChunkName: 'class-list' */ '../../../components/Classes/List'
  ),
);

const PremiumSessionsList = React.lazy(() =>
  import(
    /* webpackChunkName: 'class-list' */ '../../../components/Classes/PremiumClasses/List'
  ),
);

@inject('routerStore')
@withTranslation()
@observer
class ClassListView extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore } = this.props;
    const { title } = routerStore?.route?.params;

    this.title = title;
    document.title = title;
    this.state = {
      activeTab: 'classlist',
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="container-grid-responsive instructor-dashboard-wrapper">
        <React.Suspense fallback={<ViewLoader />}>
          <Tabs
            activeKey={this.state.activeTab}
            defaultActiveKey={this.state.activeTab}
            id="instructor-class-tabs"
            mountOnEnter
            animation={false}
            onSelect={(tab) => {
              this.setState({
                activeTab: tab,
              });
            }}
          >
            {/* Display the Overview tab containing class information */}
            <Tab eventKey="classlist" title={t('Class List')}>
              <ClassList />
            </Tab>

            <Tab eventKey="premiumsessions" title={t('Premium sessions')}>
              <PremiumSessionsList />
            </Tab>
          </Tabs>
        </React.Suspense>
      </div>
    );
  }
}

export default withErrorBoundary(ClassListView, GenericViewError, handleError);
