import React, { useState, useEffect, useRef } from 'react';
import {
  DataListItem,
  DataListItemRow,
  DataListItemCells,
  DataListCell,
  DataListAction,
  Tooltip,
  Button,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import OpenEyeIcon from '@patternfly/react-icons/dist/esm/icons/eye-icon';
import BlockedEyeIcon from '@patternfly/react-icons/dist/esm/icons/eye-slash-icon';
import CopyIcon from '@patternfly/react-icons/dist/esm/icons/copy-icon';

import './styles.scss';

type ProvisionItem = {
  key: string;
  label: string;
  value: string;
  customValue?: React.ReactNode;
  isSecret?: boolean;
};

type Props = {
  item: ProvisionItem;
};

function ProvisionDataItem({ item }: Props) {
  const { t } = useTranslation();
  const [isVisible, setVisibility] = useState<boolean>(!item.isSecret);
  const [showSuccessCopy, setShowSuccessCopy] = useState<boolean>(false);
  const timer = useRef(null);

  const labelId = `${item.key}-label`;

  const copyText = t('Copy to clipboard');
  const doneCopyText = t('Successfully copied to clipboard!');

  useEffect(
    () => () => {
      clearTimeout(timer.current); // clear timer so we don't setState after unmounted
    },
    [],
  );

  const handleVisibilityChange = () => {
    setVisibility((v) => !v);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(item.value);
    setShowSuccessCopy(true);

    timer.current = setTimeout(() => {
      setShowSuccessCopy(false);
    }, 8000);
  };

  return (
    <DataListItem aria-labelledby={labelId}>
      <DataListItemRow>
        <DataListItemCells
          dataListCells={[
            <DataListCell isFilled id={labelId} key="item-label">
              {item.label}
            </DataListCell>,
            <DataListCell isFilled={false} key="item-value">
              {isVisible
                ? item.customValue || item.value
                : '*'.repeat(item.value.length || 20)}
            </DataListCell>,
          ]}
        />
        <DataListAction
          id="check-action-action1"
          aria-labelledby={`check-action-action1 ${labelId}`}
          aria-label={t('Actions')}
          isPlainButtonAction
          className="ptl-lab-component-item__action"
        >
          {item.isSecret ? (
            <Button
              variant="plain"
              onClick={handleVisibilityChange}
              icon={isVisible ? <BlockedEyeIcon /> : <OpenEyeIcon />}
              title={isVisible ? t('Hide value') : t('Show value')}
            />
          ) : null}
        </DataListAction>
        <DataListAction
          id="check-action-action1"
          aria-labelledby={`check-action-action1 ${labelId}`}
          aria-label={t('Actions')}
          isPlainButtonAction
          className="ptl-lab-component-item__action ptl-lab-component-item__action--no-margin"
        >
          <Tooltip
            aria="none"
            aria-live="polite"
            content={showSuccessCopy ? doneCopyText : copyText}
            position="right"
          >
            <Button
              variant="plain"
              onClick={handleCopyToClipboard}
              icon={<CopyIcon />}
              aria-label={t('Copy to clipboard')}
            />
          </Tooltip>
        </DataListAction>
      </DataListItemRow>
    </DataListItem>
  );
}

export default ProvisionDataItem;
