import React from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-mobx-router5';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../Error/GenericViewError';
import './styles.scss';

const DashboardNav = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="dashboard-nav">
            {/* TODO: Does this need to be a link anymore? */}
            <Link href="dashboard:user" routeName="dashboard:user">
              <span className="nav-link">{t('My Progress')}</span>
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(DashboardNav),
  GenericViewError,
  handleError,
);
