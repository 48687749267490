/**
 * @file Provides utility methods to support interacting with user Org APIs
 * @author Anna Kurylo <akurylo@redhat.com>
 */

import { get } from 'axios';
import queryString from 'query-string';

import { CUSTOMER_ORG_API_BASE } from '../config/constants';

export const getCustomerOrgInfo = (groupId) => {
  return get(`${CUSTOMER_ORG_API_BASE}${groupId}/`)
    .then((json) => json.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCustomerOrgsByName = (search) => {
  const params = {
    q: search,
  };

  return get(`${CUSTOMER_ORG_API_BASE}?${queryString.stringify(params)}`)
    .then((json) => json.data.items)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCustomerOrgMembers = (orgId) => {
  return get(`${CUSTOMER_ORG_API_BASE}${orgId}/subscribed_users/`)
    .then((json) => json.data)
    .catch((err) => {
      throw new Error(err);
    });
};
