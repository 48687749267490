import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-mobx-router5';
import { PropTypes as MobXPropTypes, observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Alert } from '@patternfly/react-core';
import { storesContext } from 'stores';
import { alertListUIStoreContext } from 'views/Alert/List/store';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

const AlertListItem = (props) => {
  const { alert } = props;
  const { alertStore } = useContext(storesContext);
  const alertListUIStore = useContext(alertListUIStoreContext);
  const { t } = useTranslation();

  const enableAlert = async () => {
    alertListUIStore.closeModal();
    await alertStore.partiallyUpdateAlert(alert.doc_id, { enabled: true });
    setTimeout(alertStore.getAlerts, 1000);
  };

  const disableAlert = async () => {
    alertListUIStore.closeModal();
    await alertStore.partiallyUpdateAlert(alert.doc_id, { enabled: false });
    setTimeout(alertStore.getAlerts, 1000);
  };

  const confirmAndEnableAlert = () => {
    alertListUIStore.showModal(
      t(
        `Are you sure you want to enable this alert? It will appear in the following locations: {{locations}}`,
        { locations: alert.environment.join(', ') },
      ),
      t('Enable'),
      enableAlert,
      t('Cancel'),
    );
  };

  const confirmAndDisableAlert = () => {
    alertListUIStore.showModal(
      t(
        `Are you sure you want to disable this alert? It will disappear from the following locations: {{locations}}`,
        { locations: alert.environment.join(', ') },
      ),
      t('Disable'),
      disableAlert,
      t('Cancel'),
    );
  };

  return (
    <tr>
      <td>
        <Alert isInline variant={alert.priority} title={alert.title}>
          <p>{alert.description}</p>
        </Alert>
      </td>
      <td>{alert.environment?.join(', ').toUpperCase()}</td>
      <td>{alert.subscriptions?.join(', ').toUpperCase() || 'All'}</td>
      <td>{alert.enabled ? t('Enabled') : t('Disabled')}</td>
      <td>
        <Link
          href
          routeName="admin:alerts:edit"
          routeParams={{ uuid: alert.doc_id }}
        >
          <Button bsStyle="primary">{t('Edit')}</Button>
        </Link>
        {alert.enabled ? (
          <Button onClick={confirmAndDisableAlert}>{t('Disable')}</Button>
        ) : (
          <Button onClick={confirmAndEnableAlert}>{t('Enable')}</Button>
        )}
      </td>
    </tr>
  );
};

AlertListItem.propTypes = {
  alert: MobXPropTypes.observableObject.isRequired,
};

export default withErrorBoundary(
  observer(AlertListItem),
  ComponentError,
  handleError,
);
