import { observable, action, computed } from 'mobx';
import { submitFeedback } from '../../../services/FeedbackService';

class FeedbackStore {
  @observable feedbackSubmitting = false;

  @observable feedbackSubmitted = false;

  @observable feedbackError = false;

  @observable issueObj = {
    course: '',
    page: '',
    type: 'Bug',
    subject: '',
    summary: '',
    description: '',
    version: '',
    component: 'SG',
    deliveryMode: 'ROLE',
    sectionTitle: '',
    sectionId: '',
    url: '',
    RHNUsername: '',
    slug: '',
    modality: '',
  };

  static sanitizeCourseCode(code) {
    return code?.replace(/ea$/, '');
  }

  @computed get submitting() {
    return this.feedbackSubmitting;
  }

  set submitting(boolean) {
    this.feedbackSubmitting = boolean;
  }

  @computed get submitted() {
    return this.feedbackSubmitted;
  }

  set error(boolean) {
    this.feedbackError = boolean;
  }

  @computed get error() {
    return this.feedbackError;
  }

  @computed get issue() {
    return this.issueObj;
  }

  set submitted(boolean) {
    this.feedbackSubmitted = boolean;
  }

  @action submit() {
    return submitFeedback(this.issue);
  }
}

export default FeedbackStore;
