import { action, observable } from 'mobx';
import { getExpertSeminar } from '../../../../services/ExpertSeminarService';

class ExpertSeminarCardStore {
  @observable collateral = {};

  @action getSeminarCollateral = async (code, version) => {
    const slug = `${code}-${version}`;

    if (this.collateral.slug === slug) {
      return this.collateral;
    }

    const collateral = await getExpertSeminar(slug);
    if (collateral) {
      this.collateral = collateral.seminar;
    }

    return this.collateral;
  };
}

export default ExpertSeminarCardStore;
