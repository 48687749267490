import React from 'react';
import {
  Bullseye,
  EmptyState,
  EmptyStateVariant,
  Title,
  EmptyStateBody,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedCount: number;
};

export default function UserMetricsEmptyState({ selectedCount }: Props) {
  const { t } = useTranslation();
  if (selectedCount > 0) {
    return (
      <Bullseye>
        <EmptyState variant={EmptyStateVariant.small}>
          <Title headingLevel="h2" size="lg">
            {t('No data available for the selected users')}
          </Title>
        </EmptyState>
      </Bullseye>
    );
  }
  return (
    <Bullseye>
      <EmptyState variant={EmptyStateVariant.small}>
        <Title headingLevel="h2" size="lg">
          {t('No users selected yet')}
        </Title>
        <EmptyStateBody>
          {t('Select users from the list above to see their metrics.')}
        </EmptyStateBody>
      </EmptyState>
    </Bullseye>
  );
}
