import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { storesContext } from '../../../stores';
import './styles.scss';

const CatalogCard = React.lazy(() =>
  import(/* webpackChunkName: 'catalog' */ '../Card'),
);

const CatalogCardList = () => {
  const { catalogStore, progressStore } = useContext(storesContext);
  const { t } = useTranslation();

  const { currentPage, paginatedEntries, setCurrentPage, totalPages } =
    catalogStore;

  return (
    <React.Fragment>
      {paginatedEntries.length ? (
        <React.Fragment>
          <div className="catalog__list">
            {paginatedEntries.map((entry) => {
              const { doc_id: docId } = entry;
              const found = progressStore.progressByCourse.find((course) => {
                const { course_code: courseCode } = course || {};
                return courseCode === entry.code;
              });

              const { total_progress: totalProgress } = found || {};

              const progress = found ? Math.round(totalProgress * 100) : 0;

              return (
                <CatalogCard course={entry} progress={progress} key={docId} />
              );
            })}
          </div>
          <div className="catalog-list-pagination">
            <UltimatePagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={setCurrentPage}
            />
          </div>
        </React.Fragment>
      ) : (
        <p>{t('No catalog entries found')}</p>
      )}
    </React.Fragment>
  );
};

export default observer(CatalogCardList);
