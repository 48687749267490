import { observable, action } from 'mobx';

class LabWebApplicationsStore {
  @observable expandedPanels = new Set();

  @action togglePanels = (id, expanded) => {
    return expanded
      ? this.expandedPanels.add(id)
      : this.expandedPanels.delete(id);
  };
}

export default LabWebApplicationsStore;
