import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-mobx-router5';
import {
  Alert,
  DropdownButton,
  MenuItem,
  Panel,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { getOfferingTitleByLanguage } from 'services/UtilityService';
import OrderingButtons from 'components/Card/OrderingButtons';
import CourseIcon from 'img/icons/course_small.svg';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import LockIcon from 'img/icons/lock.svg';
import { handleError } from 'services/ErrorService';
import { storesContext } from 'stores';
import { Modality, CourseFormat, OfferingVisibility } from 'types';
import Store from './store';
import CourseCollateral from './Course/CourseCollateral';
import CourseCard from './Course';
import ExamCard from './Exam';
import ExpertSeminarCard from './ExpertSeminar';
import TechOverviewCard from './TechOverview';
import './styles.scss';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const CatalogCard = (props) => {
  const { i18n, t } = useTranslation();
  const { userStore } = useContext(storesContext);
  const store = useLocalStore(() => new Store());

  const {
    status,
    progress,
    displayVersionDropdown,
    onVersionChange,
    versions,
    isSearchResult,
    lastAccessed,
    catlogCardButtons,
    disableActionButtons,
    targetSubscription,
    allowOrderingOffering,
    onOfferingOrderChange,
  } = props;

  let { course } = props;

  course = course.catalog_entries?.length ? course.catalog_entries[0] : course; // lp_elements with multiple catalog entries, choosing one to display in card

  const { code, duration, version, translations, modality, format } = course;
  const { collateral } = store;

  const targetSubscriptionToRenderCardFor =
    targetSubscription || userStore.currentSubscriptionCode;

  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const title = getOfferingTitleByLanguage(
    course,
    getTranslation(),
    i18n.language,
  );

  const lockedTooltip = (
    <Tooltip id="locked-tooltip">
      {store.isRHUSubscriber(targetSubscriptionToRenderCardFor)
        ? t('This content is not available as part of your subscription')
        : t(
            'This content is available as part of Standard Subscription. Please upgrade to gain access',
          )}
    </Tooltip>
  );

  const isEarlyAccess = () => code?.endsWith('ea');

  const handleCardOpening = () => {
    // remove 'ea' part from course code if present
    const courseCode = (code || '').replace(/[eaEA]+$/, '');

    switch (modality) {
      default:
        return store.getCourseCollateral(courseCode, version, i18n.language);
    }
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    store.toggle();
  };

  const icon = () => {
    switch (modality) {
      default:
        return CourseIcon;
    }
  };

  const vc = course.videoClassroom
    ? course.videoClassroom
    : modality === Modality.VideoClassroom && course;

  const orderedVersions = Array.from(versions).sort((a, b) => {
    return parseFloat(b) - parseFloat(a);
  });

  switch (modality) {
    case Modality.Course:
    case Modality.VideoClassroom: {
      return (
        <CourseCard
          course={course}
          progress={progress}
          displayVersionDropdown={displayVersionDropdown}
          onVersionChange={onVersionChange}
          versions={versions}
          isSearchResult={isSearchResult}
          lastAccessed={lastAccessed}
          courseCardButtons={catlogCardButtons}
          disableActionButtons={disableActionButtons}
          targetSubscription={targetSubscriptionToRenderCardFor}
          allowOrderingOffering={allowOrderingOffering}
          onOfferingOrderChange={onOfferingOrderChange}
          alternativeReadButtonText={
            format === CourseFormat.Bundle ? t('Access') : null
          }
        />
      );
    }

    case Modality.Exam:
      return (
        <ExamCard
          exam={course}
          status={status}
          onVersionChange={onVersionChange}
          version={versions}
          canAccess={store.canAccessExam(targetSubscriptionToRenderCardFor)}
          lastAccessed={lastAccessed}
          actionButtonsArray={catlogCardButtons}
          disableActionButtons={disableActionButtons}
          allowOrderingOffering={allowOrderingOffering}
          onOfferingOrderChange={onOfferingOrderChange}
        />
      );

    case Modality.ExpertSeminar:
      return (
        <ExpertSeminarCard
          actionButtonsArray={catlogCardButtons}
          offering={course}
          canAccess={
            store.canAccessExpertSeminar(targetSubscriptionToRenderCardFor) &&
            !vc
          }
          allowOrderingOffering={allowOrderingOffering}
          onOfferingOrderChange={onOfferingOrderChange}
        />
      );

    case Modality.TechOverview:
      return (
        <TechOverviewCard
          actionButtonsArray={catlogCardButtons}
          offering={course}
          versions={versions}
          onVersionChange={onVersionChange}
          canAccess
          allowOrderingOffering={allowOrderingOffering}
          onOfferingOrderChange={onOfferingOrderChange}
          progress={progress}
          displayVersionDropdown={displayVersionDropdown}
        />
      );

    default:
      return (
        <Panel className={`course-card ${modality} panel-with-ordering`}>
          <div className="card-with-ordering">
            {allowOrderingOffering && (
              <OrderingButtons onOrderChange={onOfferingOrderChange} />
            )}
            <div className="card-body-with-ordering">
              <Panel
                expanded={store.opened}
                onClick={handleToggle}
                onToggle={handleToggle}
              >
                <Panel.Body>
                  <div className={`course-icon ${modality}`}>
                    <SVG
                      src={icon()}
                      title={t('Course')}
                      width={40}
                      height={40}
                    />
                    {/*
                technically we won't show courses with visibility: hidden in the catalog,
                but in case there's a skills path and we need to show exams there,
                they need to be locked.
              */}
                    {(course.visibility === OfferingVisibility.Upgrade ||
                      course.visibility === OfferingVisibility.Hidden) && (
                      <OverlayTrigger
                        placement="bottom"
                        trigger={['hover']}
                        overlay={lockedTooltip}
                      >
                        <div className="content-locked">
                          <SVG src={LockIcon} style={{ pointerEvents: 'auto' }}>
                            {t('Content is locked')}
                          </SVG>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                  <div className="course-content-wrapper">
                    <div className="caret-wrapper">
                      <div className="course-expand">
                        <SVG src={store.opened ? CollapseIcon : ExpandIcon}>
                          {store.opened ? t('Collapse') : t('Expand')}
                        </SVG>
                      </div>
                    </div>
                    <h4 className="course-title">{title}</h4>
                    <div className="course-content">
                      <div className="course-meta">
                        <span className="course-code">
                          {(code || '').toUpperCase()}
                        </span>
                        {duration && (
                          <span className="course-duration">
                            {t('{{duration}} hours', { duration })}
                          </span>
                        )}
                        {isEarlyAccess() && (
                          <div className="badge-icon-wrapper">
                            <span className="showText">
                              <p className="slide ea-slide">
                                {t('Early Access')}
                              </p>
                            </span>
                          </div>
                        )}
                        <span className="course-actions">
                          {course.visibility !== 'upgrade' && (
                            <React.Fragment>
                              {vc &&
                                !store.isFreeTierSubscriber(
                                  targetSubscriptionToRenderCardFor,
                                ) && (
                                  <Link
                                    className="course-view"
                                    routeName="courses:view"
                                    routeParams={{
                                      course: `${vc.code}vc-${vc.version}`,
                                    }}
                                    href
                                  >
                                    {t('Video Classroom').toUpperCase()}
                                  </Link>
                                )}
                              {vc &&
                                store.isFreeTierSubscriber(
                                  targetSubscriptionToRenderCardFor,
                                ) && (
                                  <Link
                                    className="course-view"
                                    routeName="courses:free-tier-vc"
                                    routeParams={{
                                      course: `${vc.code}vc`,
                                    }}
                                    href
                                  >
                                    {t('Video Classroom').toUpperCase()}
                                  </Link>
                                )}
                              {modality === Modality.Course && (
                                <Link
                                  className="course-view"
                                  href
                                  routeName="courses:view"
                                  routeParams={{
                                    course: `${code}-${version}`,
                                  }}
                                >
                                  {t('Online Training').toUpperCase()}
                                </Link>
                              )}
                            </React.Fragment>
                          )}
                        </span>
                      </div>
                      <Panel.Collapse onEnter={handleCardOpening}>
                        <div className="course-collateral">
                          <React.Fragment>
                            {modality === Modality.Course && (
                              <CourseCollateral collateral={collateral} />
                            )}
                          </React.Fragment>
                        </div>
                      </Panel.Collapse>
                    </div>
                  </div>
                </Panel.Body>
                <div className="progress-version-container">
                  {displayVersionDropdown && (
                    <DropdownButton
                      id="progress-version-selector"
                      bsStyle="default"
                      title={`v.${version}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {orderedVersions.map((v) => (
                        <MenuItem
                          key={v}
                          value={v}
                          active={v === version}
                          onClick={(e) => {
                            e.stopPropagation();
                            onVersionChange(v);
                          }}
                        >
                          {`v.${v}`}
                        </MenuItem>
                      ))}
                    </DropdownButton>
                  )}
                  <div className="progress-container">
                    {modality && modality !== Modality.ExpertSeminar ? (
                      <ProgressBar
                        striped
                        now={progress}
                        label={`${progress}%`}
                      />
                    ) : (
                      <div className="no-progress" />
                    )}
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </Panel>
      );
  }
};

CatalogCard.propTypes = {
  course: PropTypes.object,
  progress: PropTypes.number,
  displayVersionDropdown: PropTypes.bool,
  onVersionChange: PropTypes.func,
  versions: PropTypes.array,
  status: PropTypes.string,
  isSearchResult: PropTypes.bool,
  lastAccessed: PropTypes.object,
  catlogCardButtons: PropTypes.array,
  disableActionButtons: PropTypes.bool,
  targetSubscription: PropTypes.string,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
};

CatalogCard.defaultProps = {
  course: {},
  progress: 0,
  displayVersionDropdown: false,
  onVersionChange: () => {},
  versions: [],
  status: 'unenrolled',
  isSearchResult: false,
  lastAccessed: undefined,
  catlogCardButtons: [],
  disableActionButtons: false,
  targetSubscription: null,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
};

export default withErrorBoundary(
  observer(CatalogCard),
  ErrorMessage,
  handleError,
);
