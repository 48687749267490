import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import { storesContext } from '../../../../stores';
import { PREMIUM_VT_SCHEDULE_SESSION_LIMIT } from '../../../../config/constants';

const ScheduleAllModal = (props) => {
  const { t } = useTranslation();
  const { show, onCloseModal } = props;

  const { classesStore } = useContext(storesContext);

  const message =
    classesStore.numberAvailablePremiumSessionEnrollmentsForUser === 0
      ? t(
          `You are unable to schedule at this time, as you have reached your maximum number of sessions that can be scheduled at once (${PREMIUM_VT_SCHEDULE_SESSION_LIMIT}). Please attend or cancel your scheduled sessions before attempting to schedule another session.`,
        )
      : t(
          `You are trying to schedule a number of sessions that will exceed the total number of sessions that can be scheduled at one time (${PREMIUM_VT_SCHEDULE_SESSION_LIMIT}). You will only be able to schedule ${classesStore.numberAvailablePremiumSessionEnrollmentsForUser} sessions for this action. Please select the ${classesStore.numberAvailablePremiumSessionEnrollmentsForUser} sessions you would like included, and then click Confirm.`,
        );

  return (
    <Modal show={show} backdrop={false}>
      <ModalHeader>
        <ModalTitle bsClass="text-danger">{t('Schedule Warning')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="modal-wrapper">{message}</div>
      </ModalBody>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={onCloseModal}>
          {t('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ScheduleAllModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default observer(ScheduleAllModal);
