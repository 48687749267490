import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { when } from 'mobx';
import '@babel/polyfill';

import '@patternfly/react-core/dist/styles/base.css';

import rootStore from './stores';
import configureI18n from './config/i18n';
import configureSentry from './config/sentry';
import configureRouter from './config/router5';
import Layout from './components/Layout';

import '@ole-ui/ole-ui-components/dist/styles/index.css';
import './styles/base.scss';

import { setAxiosRequestHeaders } from './services/NetworkingService';

when(
  () =>
    rootStore.configStore.didFetchConfig === true &&
    rootStore.configStore.rezodePlatform,
  () => {
    configureSentry(rootStore.configStore.shouldSkipSentry);
  },
);

const isDevelopmentEnv = process.env.NODE_ENV === 'development';
const isAccessibilityCheckEnabled = false; // turning this on will cause some renders to be significantly slower

configureI18n(rootStore.uiStore);

const router = configureRouter(isDevelopmentEnv, rootStore);

const app = document.getElementById('app');

setAxiosRequestHeaders();

function renderReactDOM() {
  ReactDOM.render(
    <Provider {...rootStore}>
      <Suspense fallback="">
        <Layout />
      </Suspense>
    </Provider>,
    app,
  );
}

router.start(() => {
  if (isDevelopmentEnv && isAccessibilityCheckEnabled) {
    import('react-axe').then((axe) => {
      axe.default(React, ReactDOM, 1000);
      renderReactDOM();
    });
  } else {
    renderReactDOM();
  }
});
