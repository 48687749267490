import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  ControlLabel,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import { storesContext } from 'stores';
import UsersIcon from 'img/icons/users.svg';
import UserIcon from 'img/icons/single-user.svg';

import { USER_SUBSCRIPTION_CODE } from 'config/constants';
import ViewLoader from 'components/Loading/View';
import { Modality } from 'types';
import './styles.scss';

const OrgLeaderCustomPathSelection = (props) => {
  const { i18n, t } = useTranslation();

  const { routerStore, userStore, catalogStore, vocabularyStore, searchStore } =
    useContext(storesContext);
  const {
    onSelectMyTeam,
    onSelectMyself,
    subscriptions,
    isSubscriptionsLoading,
  } = props;
  const [selectedSubscription, setSelectedSubScription] = React.useState(
    props.subscriptionCode || null,
  );

  const [catalogUpdateInProgress, setCatalogUpdateInProgress] =
    React.useState(false);

  useEffect(() => {
    when(
      () => props.subscriptionCode,
      () => {
        setSelectedSubScription(props.subscriptionCode);
      },
    );
  }, [props.subscriptionCode]);

  const presetFilters = () => {
    catalogStore.clearFilters();
    if (routerStore?.route?.params?.q) {
      searchStore.query = routerStore?.route?.params?.q;
    } else {
      searchStore.query = '';
    }
    catalogStore.presetFilters(routerStore?.route?.params?.filter);
  };

  const renderCreateForMyself = () => {
    return (
      <React.Fragment>
        <div className="panel panel-default select-organization-team">
          <div className="panel-heading">
            <SVG src={UserIcon} />
          </div>
          <div className="panel-body">
            <Row>
              <Col className="col-sm form-sub-title">
                {t('I want to create a custom skill path for myself.')}
              </Col>
            </Row>
          </div>
          <div className="panel-footer">
            <Button
              key={uuidv4()}
              disabled={
                !userStore.currentSubscriptionCode && !catalogStore.loaded
              }
              onClick={async () => {
                onSelectMyself();
              }}
              className="blue-btn"
            >
              {t('Create for myself')}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const renderSelectTargetSubscription = () => {
    return (
      <React.Fragment>
        <div className="panel panel-default select-organization-team">
          <div className="panel-heading">
            <SVG src={UsersIcon} />
          </div>
          <div className="panel-body">
            <Form
              horizontal
              className="cskp-form-select-org-team"
              id={uuidv4()}
            >
              <FormGroup>
                <Col className="col-sm form-sub-title">
                  {t('I want to create a custom skill path for a team.')}
                </Col>
              </FormGroup>

              <FormGroup>
                <Col componentClass={ControlLabel} className="col-sm">
                  {t('Subscription Type:')}
                </Col>
                <Col className="col-sm">
                  <DropdownButton
                    bsStyle="default"
                    className="org-subscription-dropdown"
                    id="org-subscription-dropdown"
                    onSelect={(key) => {
                      key = key !== 'no-sub-in-team' ? key : null;

                      setSelectedSubScription(key);
                    }}
                    title={
                      selectedSubscription
                        ? USER_SUBSCRIPTION_CODE[selectedSubscription]
                        : t('Select a Subscription Type')
                    }
                  >
                    {(subscriptions?.length > 0 &&
                      subscriptions?.map((sub) => {
                        return (
                          <MenuItem key={sub} eventKey={sub} value={sub}>
                            {USER_SUBSCRIPTION_CODE[sub]}
                          </MenuItem>
                        );
                      })) || (
                      <MenuItem
                        key="no-sub-in-team"
                        eventKey="no-sub-in-team"
                        value="no-sub-in-team"
                      >
                        {isSubscriptionsLoading
                          ? t('Loading subscriptions under your team')
                          : t('No subscriptions exists under your team')}
                      </MenuItem>
                    )}
                  </DropdownButton>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <div className="panel-footer">
            <Button
              key={uuidv4()}
              disabled={
                selectedSubscription === null || catalogUpdateInProgress
              }
              onClick={async () => {
                if (!catalogUpdateInProgress) {
                  setCatalogUpdateInProgress(true);
                  const entries =
                    await catalogStore.getCatalogEntriesBySubscrition(
                      selectedSubscription,
                      selectedSubscription !==
                        userStore.currentSubscriptionCode,
                    );
                  if (entries) {
                    catalogStore.removeModalityFromCatalog([
                      Modality.ExpertSeminar,
                    ]);
                    catalogStore.getFilterInfo(i18n, t, vocabularyStore);
                    presetFilters();
                    catalogStore.loaded = true;
                    setCatalogUpdateInProgress(false);
                    onSelectMyTeam(selectedSubscription);
                  }
                }
              }}
              className="blue-btn"
            >
              {catalogUpdateInProgress ? (
                <span className="spinner-wrapper">
                  <ViewLoader color="#ffffff" />
                </span>
              ) : (
                t('Create for my team')
              )}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="flex-row">
        <div className="flex-no-filter-col">
          <Button
            key="lp-csk-button"
            bsStyle="primary"
            className="btn-block"
            onClick={() => {
              routerStore.navigate('skills-paths');
            }}
          >
            {t('Cancel')}
          </Button>
        </div>
        <div className="flex-select-path-type-col">
          {renderSelectTargetSubscription()}
        </div>
        {userStore.currentSubscriptionCode && (
          <div className="flex-select-path-type-col">
            {renderCreateForMyself()}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

OrgLeaderCustomPathSelection.propTypes = {
  onSelectMyTeam: PropTypes.func,
  onSelectMyself: PropTypes.func,
  subscriptionCode: PropTypes.string,
  subscriptions: PropTypes.array,
  isSubscriptionsLoading: PropTypes.bool,
};
OrgLeaderCustomPathSelection.defaultProps = {
  onSelectMyself: () => {},
  onSelectMyTeam: () => {},
  subscriptionCode: null,
  subscriptions: [],
  isSubscriptionsLoading: true,
};

export default observer(OrgLeaderCustomPathSelection);
