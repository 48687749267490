import React from 'react';
import { Text } from '@patternfly/react-core';
import {
  CourseMetadata,
  CourseRelatedContent,
  CourseRelationType,
} from 'types';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-mobx-router5';
import SVG from 'react-inlinesvg';
import useMobxStores from 'hooks/useMobxStores';

import CourseIcon from 'img/icons/course_small.svg';

type Props = {
  relatedContent: CourseMetadata['related_contents'];
  heading: React.ReactNode;
};

type CourseRelatedContentWithEntry = CourseRelatedContent & {
  catalogEntry: any;
};

const RelatedContent = ({ relatedContent, heading }: Props) => {
  const { t } = useTranslation();
  const { catalogStore } = useMobxStores();

  const childOfItems: CourseRelatedContent[] = filter<CourseRelatedContent>(
    relatedContent,
    (entry: CourseRelatedContent) =>
      entry?.relation_type === CourseRelationType.ChildOf,
  );

  const itemsWithCatalogEntries: CourseRelatedContentWithEntry[] =
    childOfItems.map((item) => {
      const catalogEntry =
        catalogStore.groupedCatalogEntries?.[item?.code?.toLowerCase?.()]?.[
          item?.version
        ];

      return { ...item, catalogEntry };
    });

  const isEmpty = itemsWithCatalogEntries.length === 0;

  if (isEmpty) {
    return null;
  }

  return (
    <>
      {heading}
      <ul className="course-overview__list">
        {itemsWithCatalogEntries.map((item) => {
          let title = '';
          if (!item?.catalogEntry) {
            title = `${item.code}-${item.version}`.toUpperCase();
          } else {
            title = `${item.catalogEntry?.slug?.toUpperCase?.()} - ${
              item.catalogEntry.title || ''
            }`;
          }
          const courseParam = `${item.code}-${item.version}`.toLowerCase();

          return (
            <li key={`${item.code}-${item.version}`}>
              <div className="course-overview__related-course">
                <SVG src={CourseIcon} title={title} width={30} height={30} />
                <Link
                  href
                  routeName="courses:view"
                  routeParams={{
                    course: courseParam,
                  }}
                >
                  {title}
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
      <Text>
        {t(
          'Completing this lesson contributes to the progress of the parent course.',
        )}
      </Text>
    </>
  );
};

export default RelatedContent;
