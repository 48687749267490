import { Modal, ModalVariant } from '@patternfly/react-core';
import { PostScribe } from 'react-postscribe';
import React, { useRef } from 'react';
import { AWS_WAF_CAPTCHA } from 'config/constants';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export interface CaptchaError extends Error {
  kind: 'internal_error' | 'network_error' | 'token_error' | 'client_error';
  statusCode?: number;
}

type Props = {
  apiKey: string;
  onSuccess: (wafToken: string) => void;
  onError?: (error: CaptchaError) => void;
  onClose: () => void;
  isOpen: boolean;
};

function AWSWAFCaptcha({ apiKey, isOpen, onClose, onSuccess, onError }: Props) {
  const { t } = useTranslation();
  const captchaContainer = useRef(null);

  const handleCaptchaScriptLoaded = () => {
    // DOCS: https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-captcha-api-specification.html
    window.AwsWafCaptcha?.renderCaptcha?.(captchaContainer.current, {
      apiKey,
      onSuccess: (token: string) => {
        onSuccess(token);
      },
      onError: (e) => {
        onError?.(e);
      },
      onLoad: () => {
        captchaContainer.current?.focus?.();
      },
      dynamicWidth: true,
    });
  };

  return (
    <>
      <Modal
        aria-label={t('Captcha verification - Are you a human?')}
        className="aws-waf-captcha"
        variant={ModalVariant.small}
        isOpen={isOpen}
        onClose={onClose}
        actions={[]}
        tabIndex={0}
      >
        <PostScribe
          // ref={player}
          html={`<script type="text/javascript" src="${AWS_WAF_CAPTCHA.SCRIPT_SRC_URL}" defer></script>`}
          done={handleCaptchaScriptLoaded}
        />
        <div
          id="captcha-container"
          className="aws-waf-captcha__container"
          ref={captchaContainer}
        />
      </Modal>
    </>
  );
}

export default AWSWAFCaptcha;
