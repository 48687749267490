import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import flippingBanners from '../../../mocks/flippingBanners.mock';
import './styles.scss';

const FlippingBanner = (props) => {
  const { banner } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <React.Fragment>
        <div className="col-sm-12">
          <div className="flipping-banner-container">
            <div className="flipping-description-banner">
              <img
                src={flippingBanners[banner].imageUrl}
                alt={t(flippingBanners[banner].imageAlt)}
                className="flipping-banner-img"
              />
              <p>{t(flippingBanners[banner].description)}</p>
            </div>
            {flippingBanners[banner].button && (
              <div className="premiumGeneralFeedbackBtn-wrapper">
                <Button
                  block
                  alt={flippingBanners[banner].actionUrl}
                  title={t('Give Feedback')}
                  href={flippingBanners[banner].actionUrl}
                  target="_blank"
                  className="premiumGeneralFeedbackBtn"
                >
                  {t('Give Feedback')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </Row>
  );
};

FlippingBanner.propTypes = {
  banner: PropTypes.string,
};

FlippingBanner.defaultProps = {
  banner: '',
};

export default FlippingBanner;
