import pino from 'pino';

// see https://github.com/pinojs/pino

const logger = pino({
  name: 'ole-spa',
  prettyPrint: true,
});

export { logger };
export default logger;
