import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import {
  Form,
  FormGroup,
  Checkbox,
  FormControl,
  Button,
} from 'react-bootstrap';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import { observer, useLocalStore } from 'mobx-react';
import TIMEZONE_LOCALES from 'config/timezones';
import { formatDateTime } from 'services/UtilityService';
import ViewLoader from 'components/Loading/View';
import { storesContext } from 'stores';
import RequestSessionFormStore from './store';
import './styles.scss';

const RequestSessionForm = ({ interest, offeringSlug, premVtSessionSlug }) => {
  const { userStore, classesStore } = useContext(storesContext);

  const timezone = userStore.user.default_timezone || moment.tz.guess();

  const store = useLocalStore(
    () =>
      new RequestSessionFormStore(
        userStore,
        classesStore,
        offeringSlug,
        premVtSessionSlug,
        interest,
      ),
  );

  const { t } = useTranslation();

  const renderDateRangeOptionForValue = (value) => {
    const stringValue = String(value);
    return (
      <option value={stringValue}>
        {t(store.friendlyDateRangeNames[stringValue])}
      </option>
    );
  };

  const renderTimeRangeOptionForValue = (value) => {
    const friendlyNames = {
      morning: t('Morning'),
      afternoon: t('Afternoon'),
      evening: t('Evening'),
    };

    const selected = friendlyNames[value];

    return selected ? <option value={value}>{selected}</option> : null;
  };

  return (
    <React.Fragment>
      <tr>
        <td>{t('Status')}</td>
        <td>
          <p>
            {store.interest
              ? t(
                  'You have requested this session. If you have chosen to be notified, you will receive an email when this session is available to be scheduled.',
                )
              : t(
                  'This session is not currently available. You may request this session to be added to our schedule.',
                )}
          </p>
        </td>
      </tr>
      <tr>
        <td>{t('Request Form')}</td>
        <td>
          <div className="request-session-form">
            <p>
              {store.interest
                ? `${t('You requested this session on')} ${formatDateTime(
                    store.interest.create_time,
                  )}:`
                : t(
                    'Please indicate your preferred date and time range for this session:',
                  )}
            </p>
            <Form inline>
              <FormGroup controlId="formInlineName" id="date-range-form-group">
                <p className="label-form">{t('Select date range')}</p>
                <FormControl
                  onChange={(e) => {
                    store.dataForm = {
                      ...store.dataForm,
                      dateRange: e.target.value,
                    };
                  }}
                  componentClass="select"
                  placeholder="select"
                  disabled={!!store.interest}
                  data-analytics-id="date-range-sessReq-view-lp"
                >
                  {store.interest ? (
                    renderDateRangeOptionForValue(store.interest.days_after)
                  ) : (
                    <React.Fragment>
                      {renderDateRangeOptionForValue(30)}
                      {renderDateRangeOptionForValue(90)}
                      {renderDateRangeOptionForValue(180)}
                    </React.Fragment>
                  )}
                </FormControl>
              </FormGroup>{' '}
              <FormGroup
                controlId="formInlineName"
                id="preferred-time-form-group"
              >
                <p className="label-form">{t('Select preferred time')}</p>
                <FormControl
                  className="form-preferred-time"
                  componentClass="select"
                  placeholder="select"
                  onChange={(e) => {
                    store.dataForm = {
                      ...store.dataForm,
                      timeRange: e.target.value,
                    };
                  }}
                  disabled={!!store.interest}
                  data-analytics-id="pref-time-sessReq-view-lp"
                >
                  {store.interest ? (
                    renderTimeRangeOptionForValue(store.interest.time_range)
                  ) : (
                    <React.Fragment>
                      {renderTimeRangeOptionForValue('morning')}
                      {renderTimeRangeOptionForValue('afternoon')}
                      {renderTimeRangeOptionForValue('evening')}
                    </React.Fragment>
                  )}
                </FormControl>
              </FormGroup>{' '}
              <FormGroup controlId="formInlineName" id="timezone-form-group">
                <p className="label-form">{t('Select timezone')}</p>
                <TimezonePicker
                  defaultValue={
                    store.interest
                      ? store.interest.timezone || timezone
                      : timezone
                  }
                  placeholder={t('Select timezone')}
                  disabled={!!store.interest}
                  onChange={(selected) => {
                    store.dataForm = { ...store.dataForm, timezone: selected };
                  }}
                  timezones={Object.fromEntries(
                    Object.entries(TIMEZONE_LOCALES).map(([, v]) => [v, v]),
                  )}
                />
              </FormGroup>
              <Checkbox
                onChange={(e) => {
                  const notify = e.target.checked;
                  store.dataForm = { ...store.dataForm, notify };
                }}
                checked={store.interest ? store.interest.notify : null}
                disabled={!!store.interest}
                data-analytics-id="notify-chkbox-sessReq-view-lp"
              >
                {t(
                  'Notify me via email when sessions are available to schedule',
                )}
              </Checkbox>
              <div className="bottom-element">
                <div className="note-request">
                  {t('Note: Preferred dates and times are not guaranteed.')}
                </div>
                <div className="request-button-container">
                  {store.interest ? (
                    <Button
                      disabled={store.loadingRequest}
                      onClick={() => store.cancelInterest()}
                      className="black-cancel-button"
                      data-analytics-id="cancel-btn-sessReq-view-lp"
                    >
                      {store.loadingRequest ? (
                        <ViewLoader color="#ffffff" />
                      ) : (
                        t('Cancel')
                      )}
                    </Button>
                  ) : (
                    <Button
                      disabled={store.loadingRequest}
                      onClick={() => store.createInterest()}
                      bsStyle="danger"
                      data-analytics-id="req-btn-sessReq-view-lp"
                    >
                      {store.loadingRequest ? (
                        <ViewLoader color="#ffffff" />
                      ) : (
                        t('Request')
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

RequestSessionForm.propTypes = {
  interest: PropTypes.object,
  offeringSlug: PropTypes.string,
  premVtSessionSlug: PropTypes.string,
};

RequestSessionForm.defaultProps = {
  interest: undefined,
  offeringSlug: '',
  premVtSessionSlug: '',
};

export default observer(RequestSessionForm);
