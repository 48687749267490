import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Text,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
  EmptyStateActions,
} from '@patternfly/react-core';
import { storesContext } from 'stores';

import SyncIcon from '@patternfly/react-icons/dist/esm/icons/sync-alt-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

function GenericViewErrorV2() {
  const { t } = useTranslation();
  const { eventStore } = useContext(storesContext);

  return (
    <EmptyState>
      <EmptyStateIcon icon={ExclamationCircleIcon} />
      <Title size="lg" headingLevel="h4">
        {t("We're having trouble displaying this page.")}
      </Title>
      <EmptyStateBody>
        <Text>{t('Our team has been alerted to the issue.')}</Text>

        <Text>
          {t(
            'Meanwhile, you may also refresh the page or try again later. If the issue continues for too long, please contact support.',
          )}
        </Text>
        <Button
          variant="link"
          onClick={() => {
            eventStore.toggleDrawer('support_form');
          }}
        >
          {t('Open support')}
        </Button>
      </EmptyStateBody>
      <EmptyStateActions>
        <Button
          variant="secondary"
          icon={<SyncIcon />}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('Refresh page')}
        </Button>
      </EmptyStateActions>
    </EmptyState>
  );
}

export default GenericViewErrorV2;
