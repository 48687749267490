import queryString from 'query-string';
import { observable, action, computed, toJS, autorun } from 'mobx';
import moment from 'moment';
import { OPS_API_BASE } from '../../config/constants';

import {
  getSavedFilters,
  createReportSearch,
} from '../../services/ReportingService';

class DashboardStore {
  @observable querying = false;

  @observable queryData = {
    users: [],
    regions: [],
    startDateText: undefined,
    startDate: undefined,
    endDate: moment.utc().format('YYYY-MM-DD'),
  };

  @observable _searchId = '';

  @observable savedQuerys = [];

  constructor(userStore) {
    this.userStore = userStore;

    autorun(() => {
      if (this.querying) {
        this.fetchReport(this.querys);
      }
      this.saveQuery();
    });
  }

  @computed get searchId() {
    return this._searchId;
  }

  set searchId(id) {
    this._searchId = id;
  }

  @computed get querys() {
    return this.queryData;
  }

  @action async saveQuery() {
    await this.createSearch(false);
    setTimeout(() => {
      this.loadSavedQuerys();
    }, 1000);
  }

  @action loadSavedQuerys() {
    this.loadingData = true;
    getSavedFilters()
      .then((querys) => {
        this.savedQuerys = querys;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.loadingData = false;
      });
  }

  async createSearch(persist) {
    const { querys } = this;
    const body = {
      users: querys.users,
      regions: querys.regions,
      startDate: querys.startDate || undefined,
      endDate: querys.endDate || undefined,
      persist: persist === true,
    };

    this.loadingData = true;

    return createReportSearch(body)
      .then((searchId) => {
        this.searchId = searchId;
        return searchId;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.loadingData = false;
      });
  }

  async runQueries() {
    const searchId = await this.createSearch();
    this.searchId = searchId;
    this.querying = true;
    this.querying = false;
  }

  applyQuery(id) {
    const querys = id
      ? toJS(this.savedQuerys).find((item) => item && item.id === id)
      : {};

    const format = 'MM/DD/YYYY';
    const startDateText = moment(querys.startDate);
    const endDateText = moment(querys.endDate);

    this.queryData = {
      ...this.queryData,
      ...querys,
      startDateText:
        querys.startDate && startDateText.isValid()
          ? startDateText.format(format)
          : null,
      endDateText:
        querys.endDate && endDateText.isValid()
          ? endDateText.format(format)
          : null,
    };

    this.runQueries();

    return this.queryData;
  }

  fetchConsolidatedReportCSV() {
    const { searchId } = this;
    const params = {
      searchId,
    };

    const api = params
      ? `${OPS_API_BASE}reports/csv/?${queryString.stringify(params)}`
      : `${OPS_API_BASE}reports/csv/`;

    window.open(api);
  }
}

export default DashboardStore;
