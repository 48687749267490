import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class DefaultLabComponent extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    labComponent: PropTypes.object.isRequired,
  };

  doNull() {
    console.warn(`action ${this.props.action}`);
  }

  render() {
    return (
      <div>
        Default Component
        <pre>{JSON.stringify(this.props.labComponent, null, 2)}</pre>
      </div>
    );
  }
}

export default DefaultLabComponent;
