import React from 'react';
import {
  ChartFrame,
  CompletedCoursesTimeSeriesChart,
} from '@ole-ui/ole-ui-components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

import './styles.scss';

type ConsumptionDataPoint = {
  count: number;
  cumulative_count: number;
  key: string;
};

type Props = {
  completedCourses?: {
    series_by_month?: ConsumptionDataPoint[];
    series_by_week?: ConsumptionDataPoint[];
  };
  isLoading?: boolean;
};

const CompletedCoursesChartFrame = ({ completedCourses, isLoading }: Props) => {
  const { t } = useTranslation();

  const monthlyChart = (
    <CompletedCoursesTimeSeriesChart
      interval="month"
      isCumulative={false}
      lines={[
        {
          key: 'courses_completed',
          title: t('Courses completed'),
          data: completedCourses?.series_by_month || [],
        },
      ]}
    />
  );

  const popoverContent = (
    <>
      <p>
        <b>{t('Completed courses:')}</b>{' '}
        {t('Amount of courses completed each month')}
      </p>
      <p>
        <i>{t('Scroll to zoom the chart, click and drag to pan the chart.')}</i>
      </p>
    </>
  );

  return (
    <ChartFrame
      subTitle={t('Completed courses')}
      popoverHeader={<div>{t('Completed courses')}</div>}
      popoverContent={popoverContent}
      tabs={[
        {
          key: 'monthly_completed_courses_line_chart',
          title: t('Monthly'),
          chart: monthlyChart,
          isLoading,
        },
      ]}
      className=""
    />
  );
};

export default withErrorBoundary(
  observer(CompletedCoursesChartFrame),
  ComponentError,
  handleError,
);
