import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { getEventsByType, getJobs, updateJob } from '../services/EventService';
import { EVENT_TYPES } from '../config/constants';
import { getTimeZoneInfo } from '../services/UtilityService';

export default class EventStore {
  @observable isConcurrentLogin = false;

  @observable jobs = [];

  @observable localNotifications = [];

  @observable isDrawerOpen = false;

  @observable isDrawerActive = 'all';

  @observable feedBackProps = {};

  @action toggleDrawer = (type) => {
    this.isDrawerActive = type;
    this.isDrawerOpen = !this.isDrawerOpen;
  };

  @action toggleFeedbackForm = (data) => {
    this.feedBackProps = data;
    this.toggleDrawer('feedback');
  };

  @action resetConcurrentLogin = () => {
    this.isConcurrentLogin = false;
  };

  @action getConcurrentLogin = async () => {
    try {
      const { data } = await getEventsByType(EVENT_TYPES.CONCURRENT_LOGIN, 2);
      this.isConcurrentLogin = data.concurrent_login;
    } catch (err) {
      this.isConcurrentLogin = false;
      console.error(`couldn't fetch events : ${err}`, err);
    }
  };

  @action getNotifications = async () => {
    try {
      const result = await getJobs();
      this.jobs = result;
    } catch (e) {
      if (e.response || e.request) {
        console.error(e);
      }
    }

    // TODO: make a call to get new notifications
    // try {
    //   const { data } = await getEventsByType(EVENT_TYPES.NEW_NOTIFICATIONS, 2);
    //   this.newNotification = data.new_notifications;
    // } catch (err) {
    //   this.newNotification = [];
    //   console.error(`couldn't fetch new notifications : ${err}`, err);
    // }
  };

  @action startNotificationsPolling() {
    if (this.notificationsPoller) {
      clearTimeout(this.notificationsPoller);
    }

    this.notificationsPoller = setInterval(() => {
      this.getNotifications();
    }, 60 * 1000);
  }

  @action stopNotificationsPolling() {
    if (this.notificationsPoller) {
      clearTimeout(this.notificationsPoller);
    }
  }

  @computed get allNotifications() {
    const jobs = this.jobs
      .filter((job) => {
        return job && job.state !== 'archived';
      })
      .map((job) => {
        /* eslint-disable camelcase */
        const success = job?.status.startsWith('S');
        const done = job?.percent_complete === 100;
        const artifact_filename = job.artifact_url.split('/').slice(-1)[0];
        const short_filename = artifact_filename.split('-').slice(-1)[0];
        const date = moment(job['@timestamp']);

        let variant = 'progress';
        let description = 'Report is being generated';
        const viewButton = done && success;
        let buttonText = '';
        let onAction = () => null;
        let link = null;

        if (done) {
          if (success) {
            variant = 'download';
            description = 'Report is ready for download';
          } else {
            variant = 'error';
            description = 'Report generation failed';
          }
        }

        if (viewButton) {
          if (job.link_to_profile) {
            buttonText = 'Go to profile';
            link = 'account.profile';
          } else {
            buttonText = 'Download';
            onAction = () => {
              window.open(job.artifact_url);
            };
          }
        }

        return {
          type: 'reports',
          variant,
          doc_id: job.doc_id,
          date: date.format('YYYY/MM/DD HH:mm'),
          '@timestamp': job['@timestamp'], // for sorting purposes
          // vvv  display timestamp on the DrawerCard
          formatted_timestamp: `${date.format(
            'MM/DD/YYYY h:mm A',
          )} ${getTimeZoneInfo(date)}`,
          title: short_filename,
          description,
          isArchived: job.receipt === 'archived',
          view_button: viewButton,
          button_text: buttonText,
          link,
          onAction,
          onArchive: (id) => {
            updateJob(id, 'archived');
            setTimeout(() => {
              this.getNotifications();
            }, 1000);
          },
          onDelete: (id) => {
            updateJob(id, 'deleted');
            setTimeout(() => {
              this.getNotifications();
            }, 1000);
          },
        };
      });

    return [...jobs, ...this.localNotifications];
  }

  @computed get existingNotifications() {
    return this.allNotifications.filter((notification) => {
      return notification.isArchived;
    });
  }

  @computed get newNotifications() {
    return this.allNotifications.filter((notification) => {
      return !notification.isArchived;
    });
  }

  @action doesNotificationExist(notificationId) {
    const existingNotification = this.allNotifications.find(
      (notif) => notif.doc_id === notificationId,
    );

    return !!existingNotification;
  }

  @action triggerLocalNotification(
    title,
    id,
    description = '',
    buttonText,
    onAction,
    onDelete,
    onArchive,
    isArchived = false,
    date = moment().format('YYYY/MM/DD HH:mm'),
  ) {
    if (id && !this.doesNotificationExist(id)) {
      const notification = {
        type: 'other',
        doc_id: id,
        variant: 'local-notification',
        date,
        '@timestamp': moment(date).toISOString(), // for sorting purposes
        // vvv  display timestamp on the DrawerCard
        formatted_timestamp: `${moment(date).format(
          'MM/DD/YYYY h:mm A',
        )} ${getTimeZoneInfo(moment(date))}`,
        title,
        description,
        isArchived,
        button_text: buttonText,
        view_button: true,
        onAction: () => {
          onAction();
          this.isDrawerOpen = false;
        },
        onArchive:
          onArchive &&
          (() => {
            this.archiveLocalNotification(id);
            onArchive();
          }),
        onDelete:
          onDelete &&
          (() => {
            this.deleteLocalNotification(id);
            onDelete();
          }),
      };

      this.localNotifications.push(notification);
    }
  }

  @action deleteLocalNotification(notificationId) {
    if (notificationId)
      this.localNotifications = this.localNotifications.filter(
        (notif) => notif.doc_id !== notificationId,
      );
  }

  @action archiveLocalNotification(notificationId) {
    if (notificationId)
      this.localNotifications.forEach((notif) => {
        if (notif.doc_id === notificationId) notif.isArchived = true;
      });
  }
}
