import * as yup from 'yup';
import { TFunction } from 'i18next';

export type FormFields = {
  username?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  country?: string;
  phone?: string;
  company?: string;
  // department?: string;
  // job_title?: string;
  notify_me: boolean;
  captcha?: string;
  consent_external_data_sharing?: boolean;
};

export const countriesThatRequireDataSharingConsent = ['KR', 'CN'];

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const getSchemaWithTranslations = (
  t: TFunction,
  isCaptchaEnabled: boolean,
): yup.ObjectSchema<Partial<FormFields>> =>
  yup
    .object({
      username: yup.string().required(t('Username is required')),
      first_name: yup.string().required(t('First name is required')),
      last_name: yup.string().required(t('Last name is required')),
      email: yup
        .string()
        .email(t('Invalid email'))
        .required(t('Email is required')),
      country: yup.string().required(t('Country is required')),
      phone: yup.string().matches(phoneRegExp, t('Phone number is not valid')),
      company: yup.string().required(t('Company is required')),
      // department: yup.string(),
      // job_title: yup.string(),
      notify_me: yup.boolean(),
      captcha: isCaptchaEnabled
        ? yup.string().required(t('Captcha human verification is required'))
        : yup.string(),
      consent_external_data_sharing: yup.boolean().when(['country'], {
        is: (country: string) =>
          countriesThatRequireDataSharingConsent.includes(
            country?.toUpperCase?.(),
          ),
        then: (consentField) =>
          consentField
            .required(t('Please check the box in order to proceed.'))
            .oneOf([true], t('Please check the box in order to proceed.')),
      }),
    })
    .required() as yup.ObjectSchema<Partial<FormFields>>;
