import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'react-bootstrap';
import BullHornIcon from '@patternfly/react-icons/dist/esm/icons/bullhorn-icon';

import { Button, EmptyState, EmptyStateBody } from '@patternfly/react-core';
import { BUNDLED_COURSE_BASE } from 'config/constants';

import './styles.scss';

function BundledCourse(props) {
  const { onComplete, bundleReference, prepareFeedback, showToolbar, title } =
    props;
  const { t } = useTranslation();

  if (!bundleReference) {
    return <div>{t('No reference found for the course')}</div>;
  }

  const handleComplete = () => {
    onComplete();
  };

  const handleStartCourse = () => {
    const win = window.open(
      `${BUNDLED_COURSE_BASE}/${bundleReference}/content/index.html`,
      '_blank',
    );
    win.focus();
    handleComplete();
  };

  return (
    <div className="container-grid-responsive course">
      <PageHeader>{title || t('Launch course')}</PageHeader>
      <section className="course-container">
        <EmptyState>
          <EmptyStateBody>
            {t(
              'Click Launch to begin your course. Please note that this course will open in a separate tab.',
            )}
          </EmptyStateBody>
          <Button
            className="launch-button-bundle-course"
            variant="primary"
            onClick={handleStartCourse}
          >
            {t('Launch')}
          </Button>
          {showToolbar && (
            <Button
              variant="danger"
              onClick={prepareFeedback}
              icon={<BullHornIcon />}
            >
              {t('Feedback')}
            </Button>
          )}
        </EmptyState>
      </section>
    </div>
  );
}

BundledCourse.propTypes = {
  onComplete: PropTypes.func.isRequired,
  bundleReference: PropTypes.string,
  showToolbar: PropTypes.bool,
  prepareFeedback: PropTypes.func.isRequired,
  title: PropTypes.string,
};

BundledCourse.defaultProps = {
  bundleReference: null,
  showToolbar: false,
  title: null,
};

export default BundledCourse;
