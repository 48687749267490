const FooterUrls = {
  'cs-CZ': {
    // TODO: Translate
    privacy_policy_url:
      'http://s.bl-1.com/h/cZrgWbQn?url=https://www.redhat.com/en/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/en/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/en/about/terms-use',
    training_policies_url:
      'http://s.bl-1.com/h/cZrb2DXG?url=https://www.redhat.com/en/about/red-hat-training-policies',
  },
  'de-DE': {
    privacy_policy_url:
      'https://www.redhat.com/de/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/de/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/de/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/de/about/red-hat-training-policies',
  },
  'en-US': {
    privacy_policy_url:
      'http://s.bl-1.com/h/cZrgWbQn?url=https://www.redhat.com/en/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/en/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/en/about/terms-use',
    training_policies_url:
      'http://s.bl-1.com/h/cZrb2DXG?url=https://www.redhat.com/en/about/red-hat-training-policies',
  },
  'es-ES': {
    privacy_policy_url:
      'https://www.redhat.com/es/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/es/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/es/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/es/about/red-hat-training-policies',
  },
  'fr-FR': {
    privacy_policy_url:
      'https://www.redhat.com/fr/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/fr/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/fr/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/fr/about/red-hat-training-policies',
  },
  'it-IT': {
    privacy_policy_url:
      'https://www.redhat.com/it/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/it/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/it/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/it/about/red-hat-training-policies',
  },
  'ja-JP': {
    privacy_policy_url:
      'https://www.redhat.com/ja/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/ja/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/ja/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/ja/about/red-hat-training-policies',
  },
  'ko-KR': {
    privacy_policy_url:
      'https://www.redhat.com/ko/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/ko/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/ko/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/ko/about/red-hat-training-policies',
  },
  'pt-BR': {
    privacy_policy_url:
      'https://www.redhat.com/pt-br/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url:
      'https://www.redhat.com/pt-br/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/pt-br/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/pt-br/about/red-hat-training-policies',
  },
  'zh-CN': {
    privacy_policy_url:
      'https://www.redhat.com/zh/about/privacy-policy?extIdCarryOver=true&sc_cid=701f2000001D8QoAAK',
    all_policies_url: 'https://www.redhat.com/zh/about/all-policies-guidelines',
    terms_of_use_url: 'https://www.redhat.com/zh/about/terms-use',
    training_policies_url:
      'https://www.redhat.com/zh/about/red-hat-training-policies',
  },
};

export { FooterUrls };
export default FooterUrls;
