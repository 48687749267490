import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';

function LanguagesMenu({ setMenuOpen }) {
  const { i18n, t } = useTranslation();
  const { uiStore } = useContext(storesContext);
  const { languages, currentLanguage } = uiStore;

  return (
    <Flex justifyContent={{ default: 'justifyContentCenter' }}>
      <div className="language-menu">
        {languages.map((lang) => {
          const { display_name: name } = lang;
          const classNames = `button-link ${
            currentLanguage === lang.token ? 'button-link--selected' : ''
          }`;
          return lang.token ? (
            <Button
              variant="link"
              className={classNames}
              key={lang.token}
              event={lang.token}
              style={{ display: 'block' }}
              onClick={() => {
                i18n.changeLanguage(lang.token);
                setMenuOpen();
              }}
              data-analytics-id={`${lang.token}-language-header-lp`}
            >
              {t(name)}
            </Button>
          ) : null;
        })}
      </div>
    </Flex>
  );
}

LanguagesMenu.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
};

export default LanguagesMenu;
