/**
 * Holds data for all the scheduled premium sessions and session content.
 */

import { action, computed, observable } from 'mobx';

import moment from 'moment';

import {
  getPremiumSessions,
  getPremiumSessionContent,
  getPremiumSessionBySessionId,
  markSessionAsComplete,
  getPremiumInstructorsRHLCDetails,
} from '../services/PremiumSessionService';

import {
  PREMIUM_VT_SESSION_SEATS_RANGE,
  PREMIUM_VT_SESSION_STATES,
} from '../config/constants';

class PremiumSessionsStore {
  @observable loaded = false;

  @observable scheduledSessionsloaded = false;

  @observable premiumSessions = [];

  @observable premiumSessionsContent = [];

  @observable instructorsDetails = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  static getMinutesBeforeSessionStarts = (startTime) => {
    if (startTime) {
      const now = moment();
      const start = moment(startTime);
      const timeDifference = start.diff(now, 'minutes');

      return timeDifference;
    }

    return 0;
  };

  static getCourseMetaFromSessionSlug(sessionSlug) {
    const splitSessionSlug = sessionSlug.split('-');
    return splitSessionSlug?.length >= 2
      ? {
          code: splitSessionSlug[0],
          version: splitSessionSlug[1],
          modality: 'vt',
          sessionSlug: splitSessionSlug[2],
        }
      : {};
  }

  @action getPremiumSessions = async (force = true) => {
    if (!force) {
      return this.premiumSessions;
    }

    try {
      const premiumSessions = await getPremiumSessions();
      if (premiumSessions) {
        await this.getInstructorsDetailsForSessions(premiumSessions);
        this.premiumSessions = premiumSessions;
      }
    } catch (e) {
      console.error('Error while getting premium sessions', e);
    } finally {
      this.scheduledSessionsloaded = true;
    }

    return this.premiumSessions;
  };

  // eslint-disable-next-line class-methods-use-this
  convertAvailableSeatsToRange(availableSeats) {
    if (availableSeats === 0) return PREMIUM_VT_SESSION_SEATS_RANGE.full;

    if (availableSeats <= 5) return PREMIUM_VT_SESSION_SEATS_RANGE.limited;

    if (availableSeats > 5) return PREMIUM_VT_SESSION_SEATS_RANGE.available;

    return availableSeats;
  }

  @computed get scheduledPremiumSessions() {
    return this.premiumSessions?.filter(
      ({ state }) => state === PREMIUM_VT_SESSION_STATES.scheduled,
    );
  }

  // key value pair where key is offering slug and value is array of all session content.
  // how to use : this.groupedPremiumSessionContentByOffering["jb183-7.0"]
  @computed get groupedPremiumSessionContentByOffering() {
    const dict = this.premiumSessionsContent?.reduce((r, a) => {
      r[a.offering_slug] = r[a.offering_slug] || [];
      r[a.offering_slug] = a;
      return r;
    }, Object.create(null));
    return dict;
  }

  // key value pair where key is offering slug and value is array of all sessions.
  // how to use : this.groupedPremiumSessionsByOffering["jb183-7.0"]
  @computed get groupedScheduledSessionsByOffering() {
    const dict = this.premiumSessions?.reduce((r, a) => {
      r[a.offering_slug] = r[a.offering_slug] || [];
      r[a.offering_slug].push(a);
      return r;
    }, Object.create(null));
    return dict;
  }

  @action getScheduledSessionsBySessionSlug = (premiumVTSessionSlug) => {
    let offeringSlug = premiumVTSessionSlug.split('-');
    offeringSlug = `${offeringSlug[0]}-${offeringSlug[1]}`;
    return this.groupedScheduledSessionsByOffering[offeringSlug]?.filter(
      (session) => session.premvt_session_slug === premiumVTSessionSlug,
    );
  };

  @action getScheduledSessionBySessionId = (premiumVTSessionId) => {
    return (
      this.premiumSessions?.find(
        (session) => session.doc_id === premiumVTSessionId,
      ) || null
    );
  };

  @action getSessionSecsDurationBySessionSlug = (premiumVTSessionSlug) => {
    const sessionExample =
      this.getScheduledSessionsBySessionSlug(premiumVTSessionSlug);

    return sessionExample?.length > 0
      ? // eslint-disable-next-line camelcase
        sessionExample[0]?.duration_secs
      : undefined;
  };

  @computed get premiumContentWithScheduledSessions() {
    return (
      this.premiumSessionsContent?.map((content) => {
        return {
          ...content,
          scheduledSessions:
            this.groupedScheduledSessionsByOffering[content.offering_slug] ||
            [],
        };
      }) || []
    );
  }

  @action getPremiumSessionContent = async (force = true) => {
    if (!force) {
      return this.premiumSessionsContent;
    }
    try {
      this.loading = true;
      const sessionContentState = 'active';
      const premiumSessionsContent = await getPremiumSessionContent(
        sessionContentState,
      );

      if (premiumSessionsContent) {
        this.premiumSessionsContent = premiumSessionsContent;
      }
      if (!this.scheduledSessionsloaded) {
        await this.getPremiumSessions();
      }
    } catch (e) {
      console.error('Error while getting session content', e);
    } finally {
      this.loading = false;
      this.loaded = true;
      return this.premiumSessionsContent;
    }
  };

  @action fetchPremiumSessionBySessionId = async (premiumSessionId) => {
    try {
      const premiumSession = await getPremiumSessionBySessionId(
        premiumSessionId,
      );
      return premiumSession;
    } catch (e) {
      console.error('Error while getting premium session by id', e);
      return {};
    }
  };

  @action markSessionAsComplete = async (
    premiumVTSessionId,
    isPremiumSubscriber,
  ) => {
    let response = {};

    try {
      response = await markSessionAsComplete(premiumVTSessionId);

      if (isPremiumSubscriber) {
        await this.getPremiumSessions();
      }
      return response;
    } catch (e) {
      throw e;
    }
  };

  @action async getInstructorsDetailsForSessions(sessions) {
    let listPremiumInstructorsUsernames = [];
    sessions.forEach((session) => {
      const sessionInstructorsUsernames = session.instructors?.map(
        (instructor) => instructor.username,
      );
      listPremiumInstructorsUsernames = [
        ...listPremiumInstructorsUsernames,
        ...sessionInstructorsUsernames,
      ];
    });

    const uniqueListInstructorsUsernames = [
      ...new Set(listPremiumInstructorsUsernames),
    ];

    try {
      const response = await getPremiumInstructorsRHLCDetails(
        uniqueListInstructorsUsernames,
      );

      this.instructorsDetails = response;
    } catch (error) {
      console.error('Error retrieving instructor details', error);
    }
  }

  @action getDetailsForInstructor(instructor) {
    const details = this.instructorsDetails[instructor?.username];

    return details ? { ...instructor, ...details } : instructor;
  }

  @action sessionSlugHasScheduledSessions(sessionSlug) {
    const scheduledSessions = this.getScheduledSessionsBySessionSlug(
      sessionSlug,
    )?.filter(
      (session) =>
        session.state === PREMIUM_VT_SESSION_STATES.scheduled &&
        session.seats_available > 0,
    );

    return scheduledSessions?.length > 0;
  }
}

export default PremiumSessionsStore;
