import { post } from 'axios';
import { FEEDBACK_URL } from '../config/constants';

// eslint-disable-next-line import/prefer-default-export
export const submitFeedback = (payload) => {
  return post(FEEDBACK_URL, payload).then(
    (response) => response.data,
    (err) => {
      throw new Error(err);
    },
  );
};
