import { action, observable, computed } from 'mobx';
import moment from 'moment';
import { PREMIUM_VT_SESSION_STATES } from 'config/constants';

class LiveSessionsStore {
  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable ready = false;

  constructor(catalogStore, premiumSessionFilterStore) {
    this.catalogStore = catalogStore;
    this.premiumSessionFilterStore = premiumSessionFilterStore;
  }

  @action filterForScheduledSessionFilters = (s) => {
    return (
      moment(s.start_time).isSameOrAfter(moment()) &&
      s?.state === PREMIUM_VT_SESSION_STATES.scheduled
    );
  };

  @computed get livePremiumSessions() {
    return this.premiumSessionFilterStore?.filteredSessionContentEntries || [];
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.livePremiumSessions.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  @computed get totalPages() {
    return (
      Math.ceil(this.livePremiumSessions.length / this.entriesPerPage, 10) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };
}

export default LiveSessionsStore;
