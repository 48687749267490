import React, { useEffect, useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  Alert,
  Button,
  Col,
  PageHeader,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { SyncLoader } from 'react-spinners';
import LabControlsStore, { labControlsContext } from 'stores/LabControls';
import { storesContext } from 'stores';
import LabStore from 'stores/Lab';
import LabPolling from 'components/LabPolling';
import LabEnvironment from 'components/LabEnvironment';
import TabTitle from 'components/TabTitle';
import { RHU_SUPPORT_URL } from 'config/constants';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';

import NewCourseViewStore from 'views/Course/CourseViewV2/store';

import './styles.scss';

const LabView = () => {
  const { configStore, uiStore, routerStore } = useContext(storesContext);
  const { course: courseSlug } = routerStore.route.params;
  const store = useLocalStore(() => new LabStore());
  const [currentCourse, setCurrentCourse] = React.useState({});

  const labControlsStore = useLocalStore(() => new LabControlsStore());
  const { t } = useTranslation();

  const refreshData = () => {
    store.courseSlug = courseSlug;
    store.getLabDefinition();
    store.getLabBlueprints().then(() => {
      setCurrentCourse(
        NewCourseViewStore.getCurrentCourse(
          store.courseInfo,
          uiStore.currentLanguage,
        ),
      );
    });
  };

  useEffect(() => {
    refreshData();
  }, [courseSlug]);

  const {
    labBlueprints,
    labBlueprintsFetched,
    labDefinition,
    labDefinitionFetched,
  } = store;

  // TODO: make page header fancier by adding a title
  return (
    <div className="container-grid-responsive labs-view">
      <PageHeader>
        {currentCourse?.title ? currentCourse.title : courseSlug} {t('lab')}
      </PageHeader>
      <Row>
        <Col sm={12}>
          <div className="loading">
            <SyncLoader
              loading={!labBlueprintsFetched || !labDefinitionFetched}
              size={10}
            />
          </div>
          {labBlueprintsFetched && labDefinitionFetched && (
            <Tabs id="course-tabs" className="courseTabs" animation={false}>
              {labBlueprints.length && !labDefinition.doc_id ? (
                <Tab
                  eventKey={3}
                  title={<TabTitle icon="flash" title={t('Online Lab')} />}
                >
                  <labControlsContext.Provider value={labControlsStore}>
                    <LabPolling
                      blueprints={labBlueprints}
                      courseSlug={courseSlug}
                    />
                  </labControlsContext.Provider>
                </Tab>
              ) : null}
              {labDefinition.doc_id ? (
                <Tab
                  eventKey={8}
                  title={<TabTitle icon="flash" title={t('Lab Environment')} />}
                >
                  <LabEnvironment
                    courseSlug={courseSlug}
                    labDefinition={labDefinition}
                  />
                </Tab>
              ) : null}
              {!labBlueprints.length && !labDefinition.doc_id && (
                <Alert className="lab-not-found-msg" bsStyle="danger">
                  {t(
                    'We are sorry, but we cannot locate the lab for this version of your course. Please contact support for assistance.',
                  )}
                  <Button
                    bsStyle="primary"
                    href={
                      configStore.config?.OLE_SUPPORT_URL || RHU_SUPPORT_URL
                    }
                    target="_blank"
                  >
                    {t('Contact support')}
                  </Button>
                </Alert>
              )}
            </Tabs>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(LabView),
  GenericViewError,
  handleError,
);
