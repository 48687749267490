import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const InfoTooltip = ({ text, children }) => (
  <OverlayTrigger
    placement="bottom"
    trigger={['hover']}
    overlay={<Tooltip>{text}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);

InfoTooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};

InfoTooltip.defaultProps = {
  text: '',
  children: null,
};

export default InfoTooltip;
