import {
  Modal,
  ModalVariant,
  Button,
  Text,
  TextVariants,
} from '@patternfly/react-core';
import { observer } from 'mobx-react';
import ComparisonTable from 'components/Subscriptions/ComparisonTable';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';

import './styles.scss';

function FreeTrialComparisonTable() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { configStore, routerStore, userStore } = useContext(storesContext);

  const { isFreeTrialRestartEnabled } = configStore;

  const handleModalToggle = () => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  const handleRestart = () => {
    routerStore.navigate('free-trial-registration');
  };

  const title = userStore.isPastSubscriptionFreeTrial
    ? t('Free Trial has expired')
    : t('Subscription has expired');
  let message = '';

  if (isFreeTrialRestartEnabled) {
    if (userStore.isPastSubscriptionFreeTrial) {
      message = t(
        'Your free trial has ended, but we have good news for you. You have the option to take another free trial. If you have found our services helpful and want to enhance the benefits, upgrade to one of the tiers below or contact us.',
      );
    } else {
      message = t(
        'Your subscription has ended, but we have good news for you. You have the option to take a free trial. If you have found our services helpful and want to enhance the benefits, upgrade to one of the tiers below or contact us.',
      );
    }
  } else if (userStore.isPastSubscriptionFreeTrial) {
    message = t(
      'Your free trial has expired. To continue using our services, please consider upgrading to one of our subscription tiers or contact us.',
    );
  } else {
    message = t(
      'Your subscription has expired. To continue using our services, please consider upgrading to one of our subscription tiers or contact us.',
    );
  }

  return (
    <Modal
      variant={ModalVariant.large}
      className="free-trial-comparison-table"
      title={title}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={
        isFreeTrialRestartEnabled
          ? [
              <Button
                key="confirm"
                variant="primary"
                onClick={handleRestart}
                data-analytics-id="restart-btn-free-trial-comparison-table-lp"
              >
                {t('Restart free trial')}
              </Button>,
              <Button
                key="cancel"
                variant="link"
                onClick={handleModalToggle}
                data-analytics-id="close-btn-free-trial-comparison-table-lp"
              >
                {t('Close')}
              </Button>,
            ]
          : [
              <Button
                key="cancel"
                variant="link"
                onClick={handleModalToggle}
                data-analytics-id="close-btn-free-trial-comparison-table-lp"
              >
                {t('Close')}
              </Button>,
            ]
      }
    >
      <div>
        <div>
          <Text component={TextVariants.p}>{message}</Text>
        </div>
        <ComparisonTable tableOnly />
      </div>
    </Modal>
  );
}

export default observer(FreeTrialComparisonTable);
