import { createContext } from 'react';
import { observable, computed, action, autorun, reaction } from 'mobx';
import { post } from 'axios';

class LabControlsStore {
  @observable api = '';

  @observable application = {};

  @observable userLab = {};

  // autostop time in minutes
  @observable autostop = {
    time: undefined,
    minTime: 55,
    maxTime: 240,
    default: 55,
  };

  @observable blueprints = [];

  @observable blueprint = {};

  @observable classroomVM = {};

  @observable courseSlug = '';

  @observable currentLoader = '';

  @observable euuid = null;

  @observable loading = false;

  @observable mode = 'rol';

  @observable vms = [];

  constructor() {
    autorun(() => {
      if (!this.created) {
        this.blueprint.title = null;
        return;
      }

      // Auto-set autostop.time when the this.application.expires_seconds changes
      this.autostop.time = this.application.expires_seconds;

      const { blueprintName } = this.application;

      // Auto-set current blueprint when the this.application changes
      this.blueprint = {
        id: this.application.baseBlueprintId,
        name: this.application.blueprintName,
        title: (
          this.blueprints.find((blueprint) => {
            const bpNamePrefixIdx = blueprintName.indexOf(':');
            const bpNameSuffixIdx = blueprintName.lastIndexOf('@');

            return (
              blueprint.name &&
              blueprintName.substring(
                bpNamePrefixIdx !== -1 ? bpNamePrefixIdx + 1 : 0,
                bpNameSuffixIdx !== -1 ? bpNameSuffixIdx : blueprintName.length,
              ) === blueprint.name
            );
          }) || {}
        ).title,
      };

      this.vms =
        this.application.deployment && this.application.deployment.vms
          ? this.application.deployment.vms
          : [];

      this.classroomVM = this.vms.find((vm) => vm.name === 'classroom') || {};
    });

    // send a command to update autostop timer
    // 15 seconds after lab was created
    reaction(
      () => this.currentLoader === 'app_create',
      () => {
        setTimeout(
          () =>
            this.sendCommand('set_autostop', null, this.autostop.default * 60),
          15000,
        );
      },
    );
  }

  post(data, bypassPoll) {
    return new Promise((resolve, reject) => {
      post(this.api, JSON.stringify(data))
        .then((response) => {
          // Do a poll to make sure data is up-to-date
          if (!bypassPoll) {
            this.poll(data);
          }

          resolve(response && response.data && response.data.result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @computed get created() {
    return this.application && Object.keys(this.application).length > 0;
  }

  @computed get started() {
    return this.application && this.application.state === 'STARTED';
  }

  @computed get stopped() {
    return this.application && this.application.state === 'STOPPED';
  }

  @computed get region() {
    return (
      this.application &&
      this.application.deployment &&
      this.application.deployment.regionName
    );
  }

  @action poll(data) {
    data = { ...data, ...{ command: 'poll' } };

    post(this.api, JSON.stringify(data))
      .then((response) => {
        if (this.mode !== 'vt') {
          this.application =
            response &&
            response.data &&
            response.data.result &&
            response.data.result.application;
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  @action sendCommand(command, vmId, autostopSeconds, blueprint) {
    return new Promise((resolve) => {
      // Use the VT command set and API if mode is 'vt'
      if (this.mode === 'vt') {
        return resolve(
          this.sendVTCommand(command, vmId, autostopSeconds, blueprint),
        );
      }

      if (command === 'set_autostop') {
        // FIXME: We should fix this on the API side. We're currently overloading autostopSeconds with vmId
        vmId = autostopSeconds;
      }

      const data = {
        command,
        application_id: this.application.id || null,
        course_slug: this.courseSlug,
        ...(vmId ? { vm_id: vmId } : {}),
        ...(blueprint && blueprint.blueprint_id
          ? { blueprint_id: blueprint.blueprint_id }
          : {}),
        ...(blueprint && blueprint.name
          ? { blueprint_name: blueprint.name }
          : {}),
      };

      // Set autostop to default time if 'app_start' command is sent
      if (command === 'app_start') {
        this.post({
          ...data,
          ...{
            command: 'set_autostop',
            vm_id: this.autostop.default * 60,
          },
        });
      }

      const bypassPoll = command === 'app_wake';

      // add autostop time to request body on lab creation
      if (command === 'app_create') {
        data.autostop = this.autostop.default * 60;
      }

      return resolve(this.post(data, bypassPoll));
    });
  }

  @action sendVTCommand(command, vmId, autostopSeconds, blueprint) {
    return new Promise((resolve) => {
      // Special case for VT lab API
      let commandType = 'app_command';

      const cmd = command === 'set_autostop' ? 'command' : commandType;
      commandType = vmId ? 'instance_command' : cmd;

      switch (command) {
        case 'set_autostop':
          command = 'set_expiration';
          break;
        case 'app_shutdown':
          command = 'app_stop';
          break;
        case 'vm_start':
          command = 'start';
          break;
        case 'vm_shutdown':
          command = 'shutdown';
          break;
        case 'vm_poweroff':
          command = 'poweroff';
          break;
        case 'vm_reset':
          command = 'reset';
          break;
        default:
          break;
      }

      const data = {
        enrollment_uuid: this.euuid,
        command_type: commandType,
        command,
        ...(vmId ? { instance_id: vmId } : {}),
        ...(command === 'set_expiration'
          ? { minutes: autostopSeconds / 60 }
          : {}),
        ...(blueprint && blueprint.blueprint_id
          ? { blueprint_id: blueprint.blueprint_id }
          : {}),
        ...(blueprint && blueprint.name
          ? { blueprint_name: blueprint.name }
          : {}),
      };

      // Set autostop to max time if 'app_start' command is sent
      if (command === 'app_start') {
        this.post({
          ...data,
          ...{
            command_type: 'command',
            command: 'set_expiration',
            minutes: this.autostop.maxTime,
          },
        });
      }

      const bypassPoll = command === 'app_wake';

      return resolve(this.post(data, bypassPoll));
    });
  }
}

export const labControlsContext = createContext(null);

export default LabControlsStore;
