import { observable, action } from 'mobx';

class DatePickerInputStore {
  @observable isCalendarOpen = false;

  @observable isDateValid = true;

  @action toggleDayPickerInput(visibility) {
    this.isCalendarOpen = visibility;
  }
}
export default DatePickerInputStore;
