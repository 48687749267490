import React from 'react';

import './styles.scss';

type Props = {
  hasMargins?: boolean;
};

const Separator = ({ hasMargins = true }: Props) => (
  <div className={`separator ${hasMargins ? 'separator--has-margins' : ''}`} />
);

export default Separator;
