import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import { when } from 'mobx';
import {
  Button,
  Modal,
  Row,
  Col,
  FormGroup,
  Form,
  Checkbox,
  FormControl,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import ManageUsersInPathStore from './store';
import { storesContext } from '../../../../stores';

import ViewLoader from '../../../Loading/View';

import './styles.scss';
import { USER_SUBSCRIPTION_CODE } from '../../../../config/constants';

const ManageUsersInPath = (props) => {
  const { t } = useTranslation();
  const { onAddUsersToPath, onCancelUsersToPath, title, subscription } = props;

  const { userStore, organizationStore } = useContext(storesContext);
  const store = useLocalStore(
    () => new ManageUsersInPathStore(organizationStore, userStore),
  );

  useEffect(() => {
    when(
      () => props.showModal && props.subscription !== '',

      async () => {
        await store.getUsersListBySubscription(
          props.subscription,
          props.usersToAdd,
        );

        store.userListFetched = true;
      },
    );

    when(
      () => store.userListFetched && props.showModal,
      () => {
        if (store.userListFetched) {
          store.onOrgSelection({ target: { checked: true } }, 'all-org');

          store.filterUsers();
          store.isAllUsersSelected =
            props.usersInPath.length >= store.filteredUsers.length;
        }
      },
    );

    store.selectedUsers.clear();

    props.usersInPath.forEach((username) => {
      store.selectedUsers.add(username);
    });

    store.showAddUserModal = props.showModal;
  }, [props.showModal]);

  const renderDataColumns = (columns) => {
    return columns?.map((c) => {
      if (c.includeCheckbox) {
        return (
          <div className={`${c.className || 'column-user'} check-column`}>
            <div className="flex-sm-row">
              <span className="row-sm-header">&nbsp;&nbsp;&nbsp;</span>
              <Checkbox
                name={c?.value}
                key={`chk-row-${uuidv4()}`}
                checked={c.checked}
                onChange={(e) => {
                  e.stopPropagation();
                  c.onChange(e);
                }}
              />
            </div>
          </div>
        );
      }

      return (
        <div className={c.className || 'column-user'}>
          <div className="flex-sm-row">
            <span className="row-sm-header">{c.headerText}&nbsp;&nbsp;</span>
            {c.value}
          </div>
        </div>
      );
    });
  };

  const renderAUserToAdd = (userIndex, user) => {
    return (
      <React.Fragment key={userIndex}>
        <div key={userIndex} className="row-user-to-add">
          {renderDataColumns([
            {
              includeCheckbox: true,
              value: user?.username,
              checked: store.selectedUserNames.includes(user.username),
              onChange: (e) => {
                store.onUserSelection(e, user.username);
              },
            },
            {
              headerText: t('Username'),
              value: user?.username,
            },
            {
              headerText: t('Email'),
              value: user?.email,
            },
            {
              headerText: t('Organization'),
              value: user?.organizationName,
            },
            {
              headerText: t('Manager'),
              value: user?.managerName,
            },
            {
              headerText: t('Days remaining'),
              value: user.subscriptionDaysRemaining || 0,
            },
          ])}
        </div>
      </React.Fragment>
    );
  };
  const renderColumns = (columns) => {
    return columns?.map((c, index) => {
      if (c.includeCheckbox) {
        return (
          <div className={`${c.className || 'column-user'} check-column`}>
            <Checkbox
              type="checkbox"
              name={`chkALL-${index}`}
              checked={c.checked}
              onChange={(e) => {
                e.stopPropagation();
                c.onChange(e);
              }}
            />
          </div>
        );
      }
      return (
        <div className={c.className || 'column-user'}>
          <span>{c?.text}</span>
        </div>
      );
    });
  };
  const renderAddUserHeader = () => {
    return (
      <div className="row-user-to-add-header row-user-to-add">
        {renderColumns([
          {
            includeCheckbox: true,
            checked: store.isAllUsersSelected,
            onChange: (e) => {
              store.onUserSelection(e, 'all-user');
            },
          },
          {
            text: 'Username',
          },
          {
            text: 'Email',
          },
          {
            text: 'Organization',
          },
          {
            text: 'Manager',
          },
          {
            text: 'Days remaining',
          },
        ])}
      </div>
    );
  };

  const onSearchUsers = (e) => {
    e.persist();
    const { value } = e.target;

    const query = value && value.toLowerCase();
    store.onSearchUsers(query);
  };
  const renderUsersList = () => {
    return (
      <React.Suspense fallback={<ViewLoader />}>
        <React.Fragment>
          <div className="users-to-add-list">
            {renderAddUserHeader()}

            {(store.paginatedUserEntries.length &&
              store.paginatedUserEntries?.map((item, i) =>
                renderAUserToAdd(i, item),
              )) || (
              <div className="no-progress-msg">
                <p>{t('No users found for the selected organisation')}</p>
              </div>
            )}
          </div>
        </React.Fragment>
      </React.Suspense>
    );
  };

  const renderAddUsersModal = () => {
    return (
      <React.Fragment>
        <Modal
          bsSize="large"
          aria-labelledby="contained-modal-title-lg"
          show={store.showAddUserModal}
          className="add-user-custom-skill-path-modal"
        >
          {!store.showConfirmModal && store.showAddUserModal && (
            <Modal.Header>
              <React.Fragment>
                <h4> {t('Organizations')}</h4>
                <Row>
                  <Col className="add-user-modal-header">
                    <React.Fragment>
                      <Form>
                        <FormGroup className="org-name-list">
                          {store.allOrganizationsByRole?.length > 1 && (
                            <React.Fragment>
                              <div key="all-org" className="org-name-group">
                                <span>{t('All')}</span>
                                <Checkbox
                                  inline
                                  name="all-org"
                                  checked={store.isAllOrgSelected}
                                  onChange={(e) => {
                                    store.onOrgSelection(e, 'all-org');
                                  }}
                                />
                              </div>
                            </React.Fragment>
                          )}
                          {store.allOrganizationsByRole?.map((org) => {
                            return (
                              <React.Fragment>
                                <div
                                  key={`${org.doc_id}-org`}
                                  className="org-name-group"
                                >
                                  <span>{org.name}</span>
                                  <Checkbox
                                    inline
                                    checked={store.selectedOrganizationIds.includes(
                                      org.doc_id,
                                    )}
                                    name={org.doc_id}
                                    onChange={(e) => {
                                      store.onOrgSelection(e, org.doc_id);
                                    }}
                                  />
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </FormGroup>
                      </Form>
                    </React.Fragment>
                  </Col>
                </Row>
              </React.Fragment>
            </Modal.Header>
          )}
          {store.showConfirmModal && (
            <Modal.Body>
              <Row>
                <Col className="add-user-modal-body-confirm-message">
                  {t(
                    `Are you sure you want to create  ${title} with ${
                      store.selectedUsers.size
                    }/${store.filteredUsers.length} ${
                      store.selectedUsers.size > 1 ? 'users' : 'user'
                    } assigned to this path? `,
                  )}
                </Col>
              </Row>
            </Modal.Body>
          )}
          {!store.showConfirmModal && store.showAddUserModal && (
            <Modal.Body>
              <div className="add-user-modal-body">
                <div className="users-to-add-filter">
                  <h4>
                    {t('Users with subscription:')}{' '}
                    {USER_SUBSCRIPTION_CODE[subscription]}
                  </h4>
                  <Form
                    className="users-to-add-filter-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <FormControl
                      type="text"
                      placeholder={t('Search by username, email, organization')}
                      onKeyUp={onSearchUsers}
                    />
                  </Form>
                </div>
                {(store.userListFetched && renderUsersList()) || (
                  <div className="users-to-add-list">
                    {t('Loading users for the selected organization(s)...')}
                  </div>
                )}
              </div>
            </Modal.Body>
          )}

          {store.showConfirmModal && (
            <Modal.Footer>
              <Button
                data-analytics-id="cancel-btn-usermod-cform-cskp-lp"
                key={uuidv4()}
                onClick={() => {
                  store.toggleConfirmModal();
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                data-analytics-id="apply-btn-usermod-cform-cskp-lp"
                key={uuidv4()}
                bsStyle="primary"
                onClick={() => {
                  const _usersToAddWithOrgDetails = [];
                  store.selectedUsers.forEach((username) => {
                    const _selectedUser = store.userListToAdd?.find(
                      (u) => u.username === username,
                    );
                    if (_selectedUser) {
                      _usersToAddWithOrgDetails.push(_selectedUser);
                    }
                  });

                  onAddUsersToPath(_usersToAddWithOrgDetails);
                  store.selectedOrgs.clear();
                  store.userListFetched = false;
                  store.userSearchQuery = '';
                  store.toggleConfirmModal();
                }}
              >
                {t('Confirm')}
              </Button>
            </Modal.Footer>
          )}
          {!store.showConfirmModal && store.showAddUserModal && (
            <Modal.Footer>
              <div className="add-user-modal-footer">
                <div className="users-selected">
                  {t('Selected Users:')} {store.selectedUsers.size} /
                  {store.filteredUsers.length || 0}
                </div>
                {/* --- Pagination : User list */}
                {store.userListFetched && store.paginatedUserEntries.length ? (
                  <div className="users-to-add-list-pagination">
                    <UltimatePagination
                      currentPage={store.currentPage}
                      totalPages={store.totalPages}
                      onChange={store.setCurrentPage}
                    />
                  </div>
                ) : null}
                <div className="action-buttons">
                  <Button
                    key={uuidv4()}
                    onClick={() => {
                      store.selectedUsers.clear();
                      store.selectedOrgs.clear();
                      store.userListFetched = false;
                      store.userSearchQuery = '';
                      onCancelUsersToPath();
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    key={uuidv4()}
                    bsStyle="primary"
                    onClick={() => {
                      store.toggleConfirmModal();
                    }}
                  >
                    {t('Apply')}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </React.Fragment>
    );
  };

  return <React.Fragment>{renderAddUsersModal()}</React.Fragment>;
};

ManageUsersInPath.propTypes = {
  usersInPath: PropTypes.array,
  onAddUsersToPath: PropTypes.func,
  onCancelUsersToPath: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  subscription: PropTypes.string,
  usersToAdd: PropTypes.array,
};
ManageUsersInPath.defaultProps = {
  usersInPath: [],
  title: '',
  showModal: false,
  onCancelUsersToPath: () => {},
  onAddUsersToPath: () => {},
  subscription: '',
  usersToAdd: [],
};

export default observer(ManageUsersInPath);
