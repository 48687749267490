import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Panel } from 'react-bootstrap';
import { observer, useLocalStore } from 'mobx-react';
import SVG from 'react-inlinesvg';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-mobx-router5';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import ProgressIcon from 'img/icons/progress_small.svg';
import ProgressDarkIcon from 'img/icons/progress_small_dark.svg';
import { storesContext } from 'stores';
import ClassCard from './Card';
import PremiumClassCard from '../PremiumClasses/Card';
import SessionCard from '../PremiumClasses/Card/Session';
import './styles.scss';
import Store from './store';

const ClassesWidget = (props) => {
  const { t } = useTranslation();
  const rootStore = useContext(storesContext);
  const store = useLocalStore(() => new Store(rootStore));
  const { classes, modality } = props;

  let sortedPremSessionsWithEnrollments = [];
  if (classes.length && modality === 'premvt') {
    sortedPremSessionsWithEnrollments =
      store.getSortedEnrollmentsFromPremiumClasses(classes);
  }

  const renderNoScheduledSessionsMessage = () => {
    const catalogLink = (
      <Link
        href
        key={uuidv4()}
        routeName="catalog"
        data-analytics-id="cat-page-link-my-vt-widget-lp"
      >
        {t('Catalog')}
      </Link>
    );

    const liveSessionsLink = (
      <Link
        href
        key={uuidv4()}
        routeName="premium:sessions:live"
        data-analytics-id="liveSess--page-link-my-vt-widget-lp"
      >
        {t('Live Sessions')}
      </Link>
    );

    return (
      <p className="text-center">
        {t(
          'No scheduled Virtual Training sessions to display. Select from the',
        )}{' '}
        {catalogLink} {t('or schedule on the')} {liveSessionsLink} {t('page.')}
      </p>
    );
  };

  return classes.length ? (
    <Panel
      className="my-classes-widget"
      expanded={store.opened}
      onToggle={store.toggle}
    >
      <Panel.Heading>
        <Panel.Title toggle>
          {t(
            // eslint-disable-next-line no-nested-ternary
            modality === 'ilt'
              ? 'MY INSTRUCTOR-LED TRAINING CLASSES'
              : modality === 'premvt'
              ? 'MY VIRTUAL TRAINING SESSIONS'
              : 'MY VIRTUAL TRAINING CLASSES',
          )}
          <div className="my-classes-widget-expand">
            <SVG src={store.opened ? CollapseIcon : ExpandIcon}>
              {store.opened ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
          {modality === 'premvt' && classes.length && (
            <div
              role="button"
              tabIndex="0"
              className="my-classes-widget-premvt-show"
              data-analytics-id="live-course-progress-view-my-vt-widget-lp"
              onClick={(e) => {
                store.opened = true;
                store.togglePremiumClassesInProgress();
                // Stopping propagation to avoid default expand action
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <SVG
                src={
                  store.openedPremiumClassesInProgress
                    ? ProgressDarkIcon
                    : ProgressIcon
                }
                title={t('Premium Classes in Progress')}
              />
            </div>
          )}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          {store.openedPremiumClassesInProgress && (
            <div className="my-classes-widget-list">
              {classes.map((premClass, index) => {
                return (
                  <PremiumClassCard
                    coloredBottom
                    enrollments={premClass.enrollments}
                    premiumClass={premClass}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  />
                );
              })}
            </div>
          )}
          <div className="my-classes-widget-list">
            {modality !== 'premvt'
              ? classes.slice(0, store.visibleItems).map((offering) => {
                  return (
                    <ClassCard
                      modality={modality}
                      offering={offering}
                      key={offering?.uuid}
                    />
                  );
                })
              : !store.openedPremiumClassesInProgress &&
                sortedPremSessionsWithEnrollments
                  .slice(0, store.visibleItems)
                  .map((premSession) => {
                    const sessionData =
                      store.prepareSessionDataForRenderingCard(premSession);
                    return (
                      <SessionCard
                        course={sessionData.course}
                        premvt_session_slug={sessionData.sessionSlug}
                        sessionContent={sessionData.sessionContent}
                        enrollment={premSession.enrollment}
                        scheduledSession={premSession.scheduledSession}
                        key={uuidv4()}
                      />
                    );
                  })}
            {modality === 'premvt' &&
              !sortedPremSessionsWithEnrollments.length &&
              renderNoScheduledSessionsMessage()}
          </div>
        </Panel.Body>
        {sortedPremSessionsWithEnrollments.length >= store.visibleItems ||
        classes.length >= store.visibleItems ? (
          <Panel.Footer onClick={() => store.showMoreItems()}>
            {t('Show more')}
          </Panel.Footer>
        ) : null}
      </Panel.Collapse>
    </Panel>
  ) : null;
};

ClassesWidget.propTypes = {
  classes: PropTypes.any,
  modality: PropTypes.string,
};

ClassesWidget.defaultProps = {
  classes: [],
  modality: 'ilt',
};

export default observer(ClassesWidget);
