import React, { Component } from 'react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { PageHeader } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';
import { getVTClassByUUID } from '../../../services/VTClassService';
import Store from './store';
import PremiumSessionsStore from '../../../stores/PremiumSessions';
import './styles.scss';

const ClassDashboard = React.lazy(() =>
  import(
    /* webpackChunkName: 'class-dashboard' */ '../../../components/Classes/Dashboard'
  ),
);

@inject('routerStore', 'userStore', 'premiumSessionsStore', 'catalogStore')
@withTranslation()
@observer
class ClassDashboardView extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    premiumSessionsStore: MobXPropTypes.observableObject.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore } = this.props;
    const { uuid, title } = routerStore?.route?.params;
    const sessionslug = routerStore?.route?.params.sessionslug || '';
    this.store = new Store();

    this.uuid = uuid;
    this.title = title;

    const splitSessionSlug = sessionslug.split('-');

    this.breadcrumb =
      splitSessionSlug?.length >= 2
        ? `${splitSessionSlug[0]} - ${splitSessionSlug[1]} - Session ${splitSessionSlug[2]}`
        : '';
    document.title = title || this.breadcrumb;
  }

  componentDidMount() {
    const { userStore, premiumSessionsStore } = this.props;
    const loadPremiumSessions = () => {
      return new Promise((resolve, reject) => {
        if (!premiumSessionsStore.loaded) {
          premiumSessionsStore
            .getPremiumSessions()
            .then(() => {
              return resolve();
            })
            .catch((error) => reject(error));
        } else {
          resolve();
        }
      });
    };

    const setDataForPremiumSessions = (sessionData = null) => {
      const { routerStore } = this.props;
      const premVTSession =
        sessionData ||
        premiumSessionsStore.getScheduledSessionBySessionId(
          routerStore?.route?.params?.sessionid,
        );

      this.store.data = premVTSession || {};
      this.store.data.course =
        PremiumSessionsStore.getCourseMetaFromSessionSlug(
          routerStore?.route?.params.sessionslug,
        );
      this.store.userSubscription = userStore.subscription;
    };

    if (this.title) {
      getVTClassByUUID(this.uuid).then((data) => {
        const offeringId = data.slug.replace(/lms_/, '');
        data = { ...data, offeringId };

        const title = `${this.title} - ${data?.name}`;
        this.title = title;
        document.title = title;

        this.store.data = data;
        this.store.userSubscription = userStore.subscription;
      });
    } else {
      // eslint-disable-next-line no-lonely-if
      if (userStore.isPremiumSubscriber) {
        loadPremiumSessions().then(() => {
          setDataForPremiumSessions();
        });
      } else {
        const { routerStore } = this.props;
        const premiumSessionId = routerStore?.route?.params?.sessionid || '';
        premiumSessionsStore
          .fetchPremiumSessionBySessionId(premiumSessionId)
          .then((session) => {
            setDataForPremiumSessions(session);
          })
          .catch(() => {
            setDataForPremiumSessions({});
          });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="session-dashb-responsive">
        {this.title ? (
          <PageHeader>{this.title}</PageHeader>
        ) : (
          <div className="session-dashboard">
            <div className="title-line">
              <h3>{this.breadcrumb.toUpperCase()}</h3>

              {this.store.isLessThanAnHour && (
                <h3
                  className={`${
                    this.store.countDown < 16 ? 'starts-soon ' : ''
                  } next-timer`}
                >
                  {`${t('Next session starts in')} ${this.store.countDown} ${t(
                    'minutes',
                  )}`}
                </h3>
              )}
            </div>
          </div>
        )}
        <React.Suspense fallback={<ViewLoader />}>
          {this.store.data && (
            <ClassDashboard
              data={toJS(this.store.data)}
              userSubscription={toJS(this.store.userSubscription)}
            />
          )}
        </React.Suspense>
      </div>
    );
  }
}

export default withErrorBoundary(
  ClassDashboardView,
  GenericViewError,
  handleError,
);
