import React, { useContext } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Button } from '@patternfly/react-core';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { observer, useLocalStore } from 'mobx-react';
import { Link } from 'react-mobx-router5';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';
import Store from './store';
import './styles.scss';

const htmlParser = new DOMParser();

const ShowSolution = (props) => {
  const { data, parser } = props;
  const { t } = useTranslation();
  const store = useLocalStore(() => new Store());
  const { routerStore } = useContext(storesContext);
  const { route } = routerStore;

  const node = htmlParser.parseFromString(data, 'text/html');
  const html = node.body.innerHTML;
  let index = 0;

  const getSolutions = (el) =>
    el.filter((child) => child.attribs.class?.match(/solution/));

  const getSolutionsForProcedure = (el) =>
    el.filter((child) => child.attribs.class?.match(/step/));

  const getNonSolutions = (el) =>
    el.filter((child) => !child.attribs.class?.match(/solution/));

  const parserOptions = {
    replace: (el) => {
      index += 1;
      const { attribs, children } = el;
      const className = 'show-solution';
      const id = `${className}-panel-${index}`;
      if (
        attribs &&
        attribs.class === 'procedure' &&
        parser !== 'nonsolution'
      ) {
        return (
          <React.Fragment>
            {getSolutionsForProcedure(children).length !== 0 && (
              <PanelGroup id={id}>
                <Panel
                  className={`${className}-panel`}
                  eventKey={id}
                  onToggle={(expanded) => {
                    store.toggleSolutions(id, expanded);
                  }}
                >
                  <Panel.Body collapsible>
                    <ol className="procedure" type="2">
                      {Parser(
                        renderToStaticMarkup(
                          domToReact(getNonSolutions(children)),
                        ),
                        parserOptions,
                      )}
                    </ol>
                  </Panel.Body>
                  <Panel.Heading>
                    <Panel.Title toggle>
                      <Button
                        bsStyle="success"
                        data-analytics-id="show-btn-lab-ole-lp"
                      >
                        {!store.solutionIsExpanded(id)
                          ? t('Show Solution')
                          : t('Hide Solution')}
                      </Button>
                    </Panel.Title>
                  </Panel.Heading>
                </Panel>
              </PanelGroup>
            )}
          </React.Fragment>
        );
      }
      if (attribs && attribs.class === 'step') {
        return (
          <li className="step">
            {Parser(
              renderToStaticMarkup(domToReact(getNonSolutions(children))),
              parserOptions,
            )}
            {getSolutions(children).length !== 0 && (
              <PanelGroup id={id}>
                <Panel
                  className={`${className}-panel`}
                  eventKey={id}
                  onToggle={(expanded) => {
                    store.toggleSolutions(id, expanded);
                  }}
                >
                  <Panel.Body collapsible>
                    {domToReact(getSolutions(children))}
                  </Panel.Body>
                  <Panel.Heading>
                    <Panel.Title toggle>
                      <Button variant="secondary">
                        {t(
                          !store.solutionIsExpanded(id)
                            ? 'Show Solution'
                            : 'Hide Solution',
                        )}
                      </Button>
                    </Panel.Title>
                  </Panel.Heading>
                </Panel>
              </PanelGroup>
            )}
          </li>
        );
      }
      if (el?.name === 'a' && el.attribs?.class === 'xref' && el.attribs.href) {
        return (
          <Link
            href
            routeName="courses:page"
            routeParams={{
              course: route.params.course,
              page: el.attribs.href.replace(/.html.*$/, ''),
            }}
          >
            {domToReact(el.children)}
          </Link>
        );
      }
      return null;
    },
  };

  return (
    <div className="show-solution-interaction">
      {Parser(html, parserOptions)}
    </div>
  );
};

ShowSolution.propTypes = {
  data: PropTypes.string,
  parser: PropTypes.string,
};

ShowSolution.defaultProps = {
  data: '',
  parser: 'nonsolution',
};

export default observer(ShowSolution);
