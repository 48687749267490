import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import arrowUp from '../../../img/icons/up.svg';
import arrowDown from '../../../img/icons/down.svg';

import './styles.scss';
import InfoTooltip from '../InfoTooltip';

const OrderingButtons = (props) => {
  const { onOrderChange } = props;

  const { t } = useTranslation();

  const textAboutUp = t('Move up');
  const textAboutDown = t('Move down');

  return (
    <div className="ordering-buttons-container">
      <InfoTooltip text={textAboutUp}>
        <span
          id="up"
          tabIndex="0"
          role="button"
          onClick={(event) => onOrderChange()(event)}
        >
          <img src={arrowUp} alt={textAboutUp} />
        </span>
      </InfoTooltip>
      <InfoTooltip text={textAboutDown}>
        <span
          id="down"
          tabIndex="-1"
          role="button"
          onClick={(event) => onOrderChange()(event)}
        >
          <img src={arrowDown} alt={textAboutDown} />
        </span>
      </InfoTooltip>
    </div>
  );
};

OrderingButtons.propTypes = {
  onOrderChange: PropTypes.func,
};

OrderingButtons.defaultProps = {
  onOrderChange: () => {},
};

export default OrderingButtons;
