/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';
import ComponentError from 'components/Error/ComponentError';
import { useFeature } from '@ole-ui/ole-ui-components';
import './styles.scss';

const SubscriptionWidget = () => {
  const { t } = useTranslation();
  const { userStore, classesStore, routerStore } = useContext(
    storesContext,
  ) as any;
  const { subscription, subscriptionDaysLeft } = userStore;
  const { standard } = classesStore;
  const { route } = routerStore;
  const {
    pdf_downloads: pdfDownloads,
    exams,
    claims_allowed: claimsAllowed,
    claims_consumed: claimsConsumed,
  } = subscription.quotas || {};

  const isBookshelfEnabled = useFeature({
    section: 'course',
    flagName: 'bookshelf_button_active',
    defaultFlagValue: false,
  });

  const getSubscriptionDays = () => {
    const currentOffering = standard.find(
      (offering) => offering.slug_tag === route.params.course,
    );

    if (!currentOffering) {
      return 0;
    }

    const daysleft = moment(currentOffering.end_time).diff(
      moment(),
      'days',
      true,
    );

    return daysleft;
  };

  const subscriptionDaysALC = getSubscriptionDays();

  return (
    <div className="subscription-widget">
      <ul className="subscription-info">
        <li>
          {userStore.isAlaCarte ? (
            <React.Fragment>
              {subscriptionDaysALC > 0 && subscriptionDaysALC <= 1 ? (
                <strong>{t('Last day')}</strong>
              ) : (
                <React.Fragment>
                  {t('Days remaining')}&nbsp;
                  <strong>{Math.trunc(subscriptionDaysALC)}</strong>
                </React.Fragment>
              )}
              <strong />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {t('Days remaining')}&nbsp;
              <strong>{subscriptionDaysLeft}</strong>
            </React.Fragment>
          )}
        </li>
        {userStore.subscription.quotas &&
          !userStore.isLimitedTrialSubscriber && (
            <React.Fragment>
              <span>|</span>
              <li>
                {t('Lab hours used')}
                &nbsp;
                <strong>
                  {userStore.totalUsedLabHours}/{userStore.totalAllowedLabHours}
                </strong>
              </li>
              {(isBookshelfEnabled || pdfDownloads?.allowed > 0) && (
                <>
                  <span>|</span>
                  <li>
                    {t('E-books claimed')}&nbsp;
                    <strong>
                      {isBookshelfEnabled
                        ? claimsAllowed > 0
                          ? `${claimsConsumed || 0}/${claimsAllowed}`
                          : `${claimsConsumed || 0}`
                        : userStore.isRHUSubscriber
                        ? pdfDownloads?.used || 0
                        : `${pdfDownloads?.used || 0}/${pdfDownloads?.allowed}`}
                    </strong>
                  </li>
                </>
              )}
              {exams?.allowed > 0 && (
                <React.Fragment>
                  <span>|</span>
                  <li>
                    {t('Exams')}&nbsp;
                    <strong>
                      {exams?.used || 0}/{exams?.allowed}
                    </strong>
                  </li>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </ul>
    </div>
  );
};

export default withErrorBoundary(
  observer(SubscriptionWidget),
  ComponentError,
  handleError,
);
