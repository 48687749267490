import { createContext } from 'react';
import { action, computed, observable } from 'mobx';

import { login } from '../../services/AuthService';
import { getCourseInfo } from '../../services/CourseService';
import { getVTClassesByOfferingIds } from '../../services/VTClassService';
import { getLMSClassByOfferingId } from '../../services/LMSClassService';

class LoginStore {
  @observable offeringId = null;

  @observable course = {};

  @observable username = '';

  @observable password = '';

  @observable loading = false;

  @observable loadingFull = false;

  @observable message = {};

  query = {};

  static generateErrorMsg(t) {
    return {
      type: 'danger',
      text: t('Red Hat login or password incorrect. Please try again.'),
    };
  }

  @computed get courseCode() {
    return this.course?.code;
  }

  @computed get modality() {
    return (this.offering?.modality || '').toLowerCase();
  }

  @computed get instructors() {
    return this.offering?.instructors || [];
  }

  isValidOffering() {
    const { modality } = this;

    return (
      this.offeringId &&
      (modality === 'vt' || modality === 'ilt' || modality === 'os')
    );
  }

  @action async getLMSClassByOfferingId() {
    const offering = await getLMSClassByOfferingId(this.offeringId);

    this.offering = offering || this.offering;
    return this.offering;
  }

  @action async getCourse() {
    this.loading = true;

    const regex = /^(.*)+\d/g;

    const courseCode = (this.offering?.contentObject?.identifier || '').match(
      regex,
    );

    if (!(courseCode && courseCode[0])) {
      return;
    }

    getCourseInfo(courseCode[0]).then((course) => {
      if (!course) {
        return;
      }

      this.course = {
        code: (course.code || '').toUpperCase(),
        title: course.title,
      };
    });
  }

  @action async getVTClassUUID() {
    const classes = await getVTClassesByOfferingIds([this.offeringId]);

    return (classes && classes[0] && classes[0].uuid) || null;
  }

  @action async handleLocalLogin(routerStore) {
    const { redirect } = routerStore?.route?.params;

    if (!this.isValidOffering()) {
      if (redirect) {
        return routerStore.navigate(redirect.name, redirect.params);
      }

      return routerStore.navigate('home');
    }

    const uuid = await this.getVTClassUUID();

    if (!uuid) {
      return routerStore.navigate('home');
    }

    return routerStore.navigate('classes:view', { uuid });
  }

  @action post(routerStore, userStore, t) {
    this.loading = true;
    const trimmedUsername = this.username.trim();
    login(trimmedUsername, this.password)
      .then(() => {
        userStore.init();

        this.handleLocalLogin(routerStore);
      })
      // Display "incorrect username/password" error if login is incorrect
      .catch(() => {
        this.message = LoginStore.generateErrorMsg(t);
        // TODO: Send error message to analytics service
      })
      .then(() => {
        this.loading = false;
      });
  }
}

export default createContext(new LoginStore());
