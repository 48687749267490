import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel } from 'react-bootstrap';
import { observer, useLocalStore } from 'mobx-react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-mobx-router5';

import lodashValues from 'lodash/values';
import find from 'lodash/find';

import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';

import { storesContext } from 'stores';
import { FeatureFlag } from '@ole-ui/ole-ui-components';
import ComponentError from '../Error/ComponentError';
import VersionCard from './VersionCard';
import CatalogCard from '../Catalog/Card';
import './styles.scss';
import Store from './store';

const MyProgressWidget = () => {
  const { t } = useTranslation();
  const { catalogStore, progressStore, userStore } = useContext(storesContext);
  const store = useLocalStore(() => new Store());

  const course = (progressRecord) => {
    return catalogStore.groupedCatalogEntries[progressRecord.course_code];
  };

  const extractVersionCardData = (entry) => {
    const target = {};
    ({ course_slug: target.courseSlug, course_code: target.courseCode } =
      entry);
    return target;
  };

  return (
    <FeatureFlag section="my_widgets" flagName="my_progress" defaultFlagValue>
      <Panel
        className="my-progress-widget"
        expanded={store.opened}
        onToggle={store.toggle}
        data-analytics-id="my-progress-widget-lp"
      >
        <Panel.Heading>
          <Panel.Title toggle>
            {t('MY PROGRESS')}
            <div className="my-progress-widget-expand">
              <SVG src={store.opened ? CollapseIcon : ExpandIcon}>
                {store.opened ? t('Collapse') : t('Expand')}
              </SVG>
            </div>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            {userStore.hasAlaCarteEnrollments || userStore.isLS101Subscriber
              ? catalogStore.groupedAlaCarteCatalogEntriesAsArray &&
                catalogStore.groupedAlaCarteCatalogEntriesAsArray.map(
                  (courses) => {
                    const coursesArray = lodashValues(courses);
                    const referenceVersion =
                      (Array.isArray(progressStore.progressByCourse) &&
                        find(coursesArray, (courseElement) => {
                          return progressStore.progressByCourse.some(
                            (progress) =>
                              progress.course_slug === courseElement.slug,
                          );
                        })) ||
                      coursesArray[0];

                    if (!referenceVersion) {
                      return null;
                    }

                    return (
                      <VersionCard
                        key={referenceVersion.slug_tag || referenceVersion.slug}
                        catalogEntries={courses}
                        courseSlug={
                          referenceVersion.slug_tag || referenceVersion.slug
                        }
                        courseCode={referenceVersion.code}
                      />
                    );
                  },
                )
              : !progressStore.progressByCourse.length &&
                !userStore.isExternalSubscriber && (
                  <div className="no-progress-msg">
                    <p>{t('There are no courses currently in progress.')}</p>
                    <Link href routeName="catalog">
                      {t('Explore the Catalog')}
                    </Link>
                  </div>
                )}
            {progressStore.progressByCourse.length &&
            !userStore.isAlaCarte &&
            !userStore.isExternalSubscriber &&
            !userStore.isLS101Subscriber
              ? progressStore.progressByCourse
                  .slice(0, store.visibleItems)
                  .map((progressRecord) => {
                    const courses = course(progressRecord);
                    const versionCardProps =
                      extractVersionCardData(progressRecord);

                    if (courses) {
                      return (
                        <VersionCard
                          key={progressRecord.course_slug}
                          catalogEntries={courses}
                          {...versionCardProps}
                        />
                      );
                    }
                    return null;
                  })
              : null}

            {userStore.isExternalSubscriber &&
            catalogStore.paginatedEntries.length ? (
              <React.Fragment>
                <div className="catalog__list">
                  {catalogStore.paginatedEntries.map((entry) => {
                    const { doc_id: docId } = entry;
                    const found = progressStore.progressByCourse.find(
                      (courseItem) => {
                        const { course_code: courseCode } = courseItem || {};
                        return courseCode === entry.code;
                      },
                    );

                    const { total_progress: totalProgress } = found || {};
                    const progress =
                      !totalProgress &&
                      entry.code === catalogStore.selectedByExternalSubscriber
                        ? 0
                        : Math.round(totalProgress * 100);

                    return progress >= 0 ? (
                      <CatalogCard
                        course={entry}
                        progress={progress}
                        key={docId}
                      />
                    ) : null;
                  })}
                </div>
              </React.Fragment>
            ) : null}
          </Panel.Body>
          {progressStore.progressByCourse.length >= store.visibleItems ? (
            <Panel.Footer
              onClick={() => store.showMoreItems()}
              data-analytics-id="show-more-my-progress-widget-lp"
            >
              {t('Show more')}
            </Panel.Footer>
          ) : null}
        </Panel.Collapse>
      </Panel>
    </FeatureFlag>
  );
};

export default withErrorBoundary(
  observer(MyProgressWidget),
  ComponentError,
  handleError,
);
