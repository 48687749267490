import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { Button } from 'react-bootstrap';

import CatalogCard from '../../../Catalog/Card';

const CustomSkillPathOfferingsList = (props) => {
  const { t } = useTranslation();
  const { offeringsInPath, offerings, onAddToPath, targetSubscription } = props;

  const getOfferingButtons = (offeringCode) => {
    return [
      <Button
        key={shortid.generate()}
        disabled={offeringsInPath.includes(offeringCode) || false}
        onClick={() => {
          onAddToPath(offeringCode);
        }}
      >
        {t('Add To Path')}
      </Button>,
    ];
  };

  return (
    <React.Fragment>
      {offerings.length ? (
        <React.Fragment>
          <div className="offering-list-for-csk">
            {offerings.map((offering) => {
              return (
                <CatalogCard
                  targetSubscription={targetSubscription}
                  key={shortid.generate()}
                  course={offering}
                  catlogCardButtons={getOfferingButtons(offering.code)}
                />
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <p>{t('No offerings found')}</p>
      )}
    </React.Fragment>
  );
};

CustomSkillPathOfferingsList.propTypes = {
  offeringsInPath: PropTypes.array,
  offerings: PropTypes.array,
  onAddToPath: PropTypes.func,
  targetSubscription: PropTypes.string,
};
CustomSkillPathOfferingsList.defaultProps = {
  offeringsInPath: [],
  offerings: [],
  onAddToPath: () => {},
  targetSubscription: null,
};
export default observer(CustomSkillPathOfferingsList);
