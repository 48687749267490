import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Table } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import LabUsageTableStore from './store';
import UserMetricsEmptyState from '../EmptyState';
import './styles.scss';

const LabUsageTable = () => {
  const { t } = useTranslation();
  const { organizationStore, catalogStore, uiStore } =
    useContext(storesContext);
  const store = useLocalStore(
    () => new LabUsageTableStore(organizationStore, catalogStore),
  );

  useEffect(() => {
    store.getReporteesLabUsage(organizationStore.managerReportees);
  }, [organizationStore.managerReportees]);

  useEffect(() => {
    store.courseSKUTranslation = t('Course SKU: ');
    store.versionTranslation = t(', Version: ');
    store.userLabTranslation = t('User/Lab ');
  }, [uiStore.currentLanguage]);

  const renderEmptyState = () => {
    return (
      <UserMetricsEmptyState
        selectedCount={organizationStore.selectedUsersByManagerList?.length}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="table-horiz-scroll mgmt-lab-table">
        <Table
          ariaLabel={t('Lab hours table')}
          columns={store.tableColumns}
          data={store.transformedData}
          variant="compact"
          renderEmptyState={renderEmptyState}
        />
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(LabUsageTable),
  GenericViewError,
  handleError,
);
