import React, { useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import OrgFormControl from 'components/Dashboard/OrgFormControl';
import MgmtOverviewNav from 'components/Dashboard/MgmtOverviewNav';
import { ManagementDashboardTab } from 'components/Dashboard/types';

const ManagementOverview = () => {
  const [activeTab, setActiveTab] = useState(ManagementDashboardTab.Summary);

  const showSubscriptionTypeFilter =
    activeTab === ManagementDashboardTab.Summary;

  return (
    <div>
      <OrgFormControl
        showSubscriptionTypeFilter={showSubscriptionTypeFilter}
        setActiveTab={setActiveTab}
      />
      <MgmtOverviewNav activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
};

export default withErrorBoundary(
  ManagementOverview,
  GenericViewError,
  handleError,
);
