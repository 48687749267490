import React from 'react';
import Flatpickr from 'react-flatpickr';
import { observer } from 'mobx-react';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_blue.css';

const DateTimePicker = (props) => {
  return <Flatpickr {...props} />;
};

export default observer(DateTimePicker);
