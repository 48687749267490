import { observable, action } from 'mobx';
import { getLabSSHkey } from '../../../../services/LabService';

class LabInstructionsStore {
  @observable isTourDisplayed = false;

  @observable isOrientationVideoDisplayed = false;

  @observable sshKey = '';

  @observable status = 'idle';

  @action toggleTour = () => {
    this.isTourDisplayed = !this.isTourDisplayed;
  };

  @action toggleOrientationVideoDisplayed = () => {
    this.isOrientationVideoDisplayed = !this.isOrientationVideoDisplayed;
  };

  @observable expandedPanels = new Set();

  @action togglePanels = (id, expanded) => {
    return expanded
      ? this.expandedPanels.add(id)
      : this.expandedPanels.delete(id);
  };

  @action getSSHkey = (userCredential, finalState) => {
    this.status = 'retrieving';
    return getLabSSHkey(userCredential)
      .then((data) => {
        this.sshKey = data.key;
        this.status = finalState;
      })
      .catch((error) => {
        this.status = 'error';
        throw error;
      });
  };
}

export default LabInstructionsStore;
