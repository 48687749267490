import { get } from 'axios';
import { VOCABULARY_API } from '../config/constants';

const getVocabularyList = async (language = 'en-US') => {
  let vocabulary = [];

  try {
    vocabulary = await get(`${VOCABULARY_API}?language=${language}`);
  } catch (error) {
    throw new Error(error);
  }

  return vocabulary?.data;
};

const getVocabularyByNamespace = async (slug, language = 'en-US') => {
  if (!slug) {
    throw new Error('Vocabulary slug required');
  }

  let vocabulary = [];

  try {
    vocabulary = await get(`${VOCABULARY_API}${slug}@@${language}`);
  } catch (error) {
    vocabulary = await get(`${VOCABULARY_API}${slug}@@en-US`);

    if (!vocabulary) {
      throw new Error(error);
    }
  }

  return vocabulary?.data?.vocabulary;
};

export { getVocabularyList, getVocabularyByNamespace };

export default getVocabularyList;
