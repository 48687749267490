import { constants } from 'router5';

import LandingMat from 'views/LandingMat';
import FreeTrialRegistration from 'views/FreeTrialRegistration';
import NotFound from '../views/Errors/NotFound';
import Home from '../views/Home';
import Catalog from '../views/Catalog';
import LearningPaths from '../views/LearningPaths';
import Login from '../views/Login';
import Faq from '../views/Faq';
import Admin from '../views/Admin';
import Profile from '../views/Account/Profile';
import UserReports from '../views/Reports/User';
import AdminReports from '../views/Reports/Admin';
import OrganizationAdmin from '../views/ManagementReporting';
import Dashboard from '../views/Dashboard';
import Course from '../views/Course';
import ClassList from '../views/Classes/List';
import ClassDashboard from '../views/Classes/Dashboard';
import EditClass from '../views/Classes/Edit';
import GenerationForm from '../views/Certificates/Attendance/GenerationForm';
import ExpertSeminar from '../views/ExpertSeminar';
import NotificationList from '../views/Notifications/List';
import AlertsList from '../views/Alert/List';
import AlertEdit from '../views/Alert/Edit';
import EditNotification from '../views/Notifications/Edit';
import { getServerConfig } from '../services/ConfigService';
import Search from '../views/Search';
import Exam from '../views/Exam';
import FreeTierVideoClassroom from '../views/FreeTierVideoClassroom';
import Lab from '../views/Lab';
import RedirectComponent from '../components/Redirect';
import StuckLabsListView from '../views/Admin/LabResolve/List';
import StuckLabsResolveView from '../views/Admin/LabResolve/Resolve';
import ScheduleSession from '../views/Premium/ScheduleSession';
import LiveSessions from '../views/Premium/LiveSessions';
import LiveSessionsCalendar from '../views/Premium/LiveSessionsCalendar';
import PremiumSessionsCOA from '../views/Premium/COA';
import ManagementOverview from '../views/ManagementOverview';
import CustomSkillPath from '../views/CustomSkillPath';
import TechOverview from '../views/TechOverview';
import { HOSTNAME } from './constants';

class Routes {
  constructor(stores) {
    this.stores = stores;
  }

  /**
   * Logout user and redirect to landing or login page
   */
  invalidateAndRedirect = async (shouldRedirect, toState) => {
    const { routerStore, userStore } = this.stores;

    userStore.invalidateUser();

    let scope = 'local';
    let freeTrialRegistrationEnabled = false;
    let loginMethodMatchPage = 'login';

    try {
      const {
        OLE_ENABLE_SSO_AUTH: ssoEnabled,
        FREE_TRIAL_REGISTRATION,
        LOGIN_METHOD,
      } = await getServerConfig(); // can I use mobx store instead?

      const loginMethodMatch = LOGIN_METHOD?.find?.(
        (o) => o.hostname === HOSTNAME && o.method === 'redirect',
      );
      loginMethodMatchPage = loginMethodMatch?.params?.page || 'login';

      freeTrialRegistrationEnabled = FREE_TRIAL_REGISTRATION;

      if (ssoEnabled) {
        scope = 'sso';
      }
    } finally {
      const params = { scope };
      if (shouldRedirect) {
        params.redirect = toState;
      }

      let navigationDestination = 'login';

      if (
        freeTrialRegistrationEnabled &&
        toState.name !== 'free-trial-registration'
      ) {
        navigationDestination = loginMethodMatchPage;
      }

      return Promise.reject(
        routerStore.navigate(navigationDestination, params, {
          replace: true,
        }),
      );
    }
  };

  /**
   * Check if user is logged in
   * @returns {boolean} true if user is logged in, redirect to login page otherwise
   */
  // eslint-disable-next-line no-unused-vars
  loginRequired = (router) => (toState, fromState) => {
    const { userStore } = this.stores;

    if (userStore.isLoggedIn) return true;

    // if the user is unauthorized, send them to the login page
    // and redirect back to where they tried to access
    return userStore
      .init()
      .then(() => true)
      .catch(() => this.invalidateAndRedirect(true, toState));
  };

  hasAdminAccess = () => () => {
    const { routerStore, userStore } = this.stores;
    if (userStore.isLoggedIn) {
      return userStore.isAdmin || Promise.reject(routerStore.navigate('home'));
    }
    // if the user is unauthorized, send them to the login page
    // and don't redirect them to admin page after
    return userStore.init().then(
      () => {
        return (
          userStore.isAdmin || Promise.reject(routerStore.navigate('home'))
        );
      },
      () => this.invalidateAndRedirect(false),
    );
  };

  hasAdminInstructorAccess = () => () => {
    const { routerStore, userStore } = this.stores;
    if (userStore.isLoggedIn) {
      return (
        userStore.isInstructorOrAdmin ||
        Promise.reject(routerStore.navigate('home'))
      );
    }
    // if the user is unauthorized, send them to the login page
    // and don't redirect them to admin page after
    return userStore.init().then(
      () => {
        return (
          userStore.isInstructorOrAdmin ||
          Promise.reject(routerStore.navigate('home'))
        );
      },
      () => this.invalidateAndRedirect(false),
    );
  };

  hasOrgAccess = () => () => {
    const { routerStore, userStore, organizationStore } = this.stores;
    if (userStore.isLoggedIn) {
      return (
        userStore.isOrganizationAdmin ||
        userStore.isOrganizationManager ||
        organizationStore.hasPendingOrganizations ||
        Promise.reject(routerStore.navigate('home'))
      );
    }
    return userStore.init().then(
      () => {
        return (
          userStore.isOrganizationManager ||
          organizationStore.hasPendingOrganizations ||
          Promise.reject(routerStore.navigate('home'))
        );
      },
      () => this.invalidateAndRedirect(false),
    );
  };

  hasOrganizationAdminAccess = () => () => {
    const { routerStore, userStore } = this.stores;
    if (userStore.isLoggedIn) {
      return (
        userStore.isOrganizationAdmin ||
        Promise.reject(routerStore.navigate('home'))
      );
    }
    return userStore.init().then(
      () => {
        return (
          userStore.isOrganizationAdmin ||
          Promise.reject(routerStore.navigate('home'))
        );
      },
      () => this.invalidateAndRedirect(false),
    );
  };

  routes = [
    {
      name: 'home',
      path: '/',
      defaultParams: {
        title: 'Home',
      },
      canActivate: this.loginRequired,
      component: Home,
    },
    {
      name: 'landing-mat',
      path: '/landing',
      component: LandingMat,
    },
    {
      name: 'free-trial-registration',
      path: '/free-trial-registration',
      component: FreeTrialRegistration,
      canActivate: this.loginRequired,
    },
    {
      name: 'redirect',
      path: '/redirect/:course_slug',
      defaultParams: {
        title: 'Redirect',
      },
      canActivate: this.loginRequired,
      component: RedirectComponent,
    },
    {
      name: 'catalog',
      path: '/catalog?:q',
      defaultParams: { title: 'Catalog' },
      canActivate: this.loginRequired,
      component: Catalog,
      children: [
        {
          name: 'early-access',
          path: '/early-access',
          defaultParams: {
            title: 'Catalog',
            filter: 'early',
          },
          canActivate: this.loginRequired,
          component: Catalog,
        },
      ],
    },
    {
      name: 'custom-skill-path',
      path: '/skills-paths/custom',
      defaultParams: { title: 'Skills Paths' },
      canActivate: this.loginRequired,
      component: CustomSkillPath,
    },
    {
      name: 'custom-skill-path-edit',
      path: '/skills-paths/custom/:code',
      defaultParams: { title: 'Skills Paths' },
      canActivate: this.loginRequired,
      component: CustomSkillPath,
    },
    {
      name: 'skills-paths',
      path: '/skills-paths?:q',
      defaultParams: { title: 'Skills Paths' },
      canActivate: this.loginRequired,
      component: LearningPaths,
    },
    {
      name: constants.UNKNOWN_ROUTE,
      path: '/not-found',
      defaultParams: { title: 'Not Found' },
      canActivate: this.loginRequired,
      component: NotFound,
    },
    {
      name: 'login',
      path: '/login',
      defaultParams: { scope: 'sso' },
      component: Login,
      children: [
        {
          name: 'sso',
          path: '/sso',
          defaultParams: { scope: 'sso' },
          component: Login,
        },
        {
          name: 'local',
          path: '/local',
          defaultParams: { scope: 'local' },
          component: Login,
        },
      ],
    },
    {
      name: 'faq',
      path: '/faq',
      canActivate: this.loginRequired,
      component: Faq,
      defaultParams: {
        title: 'Frequently Asked Questions',
      },
    },
    {
      name: 'account',
      path: '/account',
      defaultParams: {
        title: 'Account - Red Hat Learning',
      },
      canActivate: this.loginRequired,
      forwardTo: 'account.profile',
      component: Profile,
      children: [
        {
          name: 'profile',
          path: '/profile',
          defaultParams: {
            title: 'Profile - Red Hat Learning',
          },
          canActivate: this.loginRequired,
          component: Profile,
        },
      ],
    },
    {
      name: 'admin',
      path: '/admin',
      defaultParams: {
        title: 'OLE Administration',
      },
      canActivate: this.hasAdminAccess,
      component: Admin,
    },
    {
      name: 'admin:labs:list',
      path: '/admin/stuck-labs',
      defaultParams: {
        title: 'Stuck Labs Resolve',
      },
      canActivate: this.hasAdminAccess,
      component: StuckLabsListView,
    },
    {
      name: 'admin:labs:resolve',
      path: '/admin/stuck-labs/:id',
      canActivate: this.hasAdminAccess,
      component: StuckLabsResolveView,
    },
    {
      name: 'admin:alerts:list',
      path: '/admin/alerts',
      defaultParams: {
        title: 'Alerts',
      },
      canActivate: this.hasAdminAccess,
      component: AlertsList,
    },
    {
      name: 'admin:alerts:edit',
      path: '/admin/alerts/:uuid',
      defaultParams: {
        title: 'Edit alert',
      },
      canActivate: this.hasAdminAccess,
      component: AlertEdit,
    },
    {
      name: 'admin:alerts:new',
      path: '/admin/alerts/new',
      defaultParams: {
        title: 'New alert',
      },
      canActivate: this.hasAdminAccess,
      component: AlertEdit,
    },
    {
      name: 'admin:notifications:list',
      path: '/admin/notifications/list',
      defaultParams: {
        title: 'Notifications',
      },
      canActivate: this.hasAdminAccess,
      component: NotificationList,
    },
    {
      name: 'admin:notifications:new',
      path: '/admin/notifications/new',
      defaultParams: {
        title: 'Create New Notification',
      },
      canActivate: this.hasAdminAccess,
      component: EditNotification,
    },
    {
      name: 'admin:notifications:edit',
      path: '/admin/notifications/:uuid/edit',
      defaultParams: {
        title: 'Edit Notification',
      },
      canActivate: this.hasAdminAccess,
      component: EditNotification,
    },
    {
      name: 'admin:reports',
      path: '/admin/reports',
      defaultParams: {
        title: 'Admin Reporting',
      },
      canActivate: this.hasAdminAccess,
      component: AdminReports,
    },
    {
      name: 'admin:management-reporting',
      path: '/admin/management-reporting',
      defaultParams: {
        title: 'Management Level Reporting Administrators',
      },
      canActivate: this.hasAdminAccess,
      component: OrganizationAdmin,
    },
    {
      name: 'reports',
      path: '/reports',
      defaultParams: {
        title: 'Reports',
      },
      canActivate: this.loginRequired,
      component: UserReports,
    },
    {
      name: 'dashboard:user',
      path: '/dashboard/user',
      defaultParams: {
        title: 'Dashboard',
      },
      canActivate: this.loginRequired,
      component: Dashboard,
    },
    {
      name: 'dashboard:overview',
      path: '/dashboard/overview',
      defaultParams: {
        title: 'Management Overview',
      },
      canActivate: this.hasOrgAccess,
      component: ManagementOverview,
    },
    {
      name: 'courses:free-tier-vc',
      path: '/courses/:course/free',
      canActivate: this.loginRequired,
      component: FreeTierVideoClassroom,
    },
    {
      name: 'courses:view',
      path: '/courses/:course',
      canActivate: this.loginRequired,
      component: Course,
    },
    {
      name: 'courses:page',
      path: '/courses/:course/pages/:page',
      canActivate: this.loginRequired,
      component: Course,
    },
    {
      name: 'courses:lab',
      path: '/courses/:course/lab',
      canActivate: this.loginRequired,
      component: Lab,
    },
    {
      name: 'classes:list',
      path: '/classes',
      defaultParams: {
        title: 'Class List',
      },
      canActivate: this.hasAdminInstructorAccess,
      component: ClassList,
    },
    {
      name: 'classes:view',
      path: '/classes/:uuid',
      defaultParams: {
        title: 'Class Dashboard',
      },
      canActivate: this.loginRequired,
      component: ClassDashboard,
    },
    {
      name: 'sessions:view',
      // path: '/sessions/:uuid',
      path: '/sessions/:sessionid/:sessionslug',
      canActivate: this.loginRequired,
      component: ClassDashboard,
    },
    {
      name: 'sessions:view:survey',
      path: '/sessions/:sessionid/:sessionslug/survey',
      canActivate: this.loginRequired,
      component: ClassDashboard,
    },
    {
      name: 'classes:new',
      path: '/classes/new',
      defaultParams: {
        title: 'New Class',
      },
      canActivate: this.hasAdminAccess,
      component: EditClass,
    },
    {
      name: 'classes:edit',
      path: '/classes/:uuid/edit',
      defaultParams: {
        title: 'Edit Class',
      },
      canActivate: this.hasAdminAccess,
      component: EditClass,
    },
    {
      name: 'certificates:attendance:generate',
      path: '/certificates/attendance/generate',
      canActivate: this.hasAdminAccess,
      component: GenerationForm,
    },
    {
      name: 'expertSeminars:view',
      path: '/seminar/:slug',
      canActivate: this.loginRequired, // should be ls220 check
      component: ExpertSeminar,
    },
    {
      name: 'exams:view',
      path: '/exam/:slug',
      defaultParams: { title: 'Exam' },
      canActivate: this.loginRequired,
      component: Exam,
    },
    {
      name: 'search',
      path: '/search?:q',
      defaultParams: {
        q: '',
        searchWithin: 'all',
      },
      canActivate: this.loginRequired,
      component: Search,
    },
    {
      name: 'premium:sessions:live',
      path: '/premium/sessions/live/',
      canActivate: this.loginRequired,
      component: LiveSessions,
    },
    {
      name: 'premium:sessions:calendar',
      path: '/premium/sessions/calendar/',
      canActivate: this.loginRequired,
      component: LiveSessionsCalendar,
    },
    {
      name: 'premium:sessions:schedule',
      path: '/premium/session/schedule/:sessionslug',
      canActivate: this.loginRequired,
      component: ScheduleSession,
    },

    {
      name: 'premium:sessions:coa',
      path: '/premium/session/coa/:offeringslug',
      canActivate: this.loginRequired,
      component: PremiumSessionsCOA,
    },
    {
      name: 'premium:sessions:reschedule',
      path: '/premium/session/reschedule/:sessionslug/:scheduledsessionid',
      canActivate: this.loginRequired,
      component: ScheduleSession,
    },
    {
      name: 'tech-overview:view',
      path: '/technical-overview/:slug',
      canActivate: this.loginRequired,
      component: TechOverview,
    },
  ];
}

export default Routes;
