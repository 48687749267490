import { get } from 'axios';
import { PARTNER_API } from '../config/constants';

const getPartnerInfo = async (partnerId) => {
  try {
    const response = await get(`${PARTNER_API}${partnerId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export { getPartnerInfo };

export default getPartnerInfo;
