import { action, observable } from 'mobx';
import { getSessionExpiry, extendSession } from '../services/SessionService';

class SessionStore {
  @observable sessionMinsLeft = 10;

  @observable sessionIntervalID = null;

  @observable sessionExpireTime = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action getSessionExpiry = async () => {
    try {
      const response = await getSessionExpiry();
      this.sessionMinsLeft = response.session_expire_minutes;
      this.sessionExpireTime = response.session_expire_time;

      if (this.sessionMinsLeft === -1) {
        this.stopSessionExpiryPolling();
      }
    } catch (e) {
      if (e.response || e.request) {
        console.error(e);
      }
    }
  };

  @action startSessionExpiryPolling() {
    this.sessionIntervalID = setInterval(
      () => {
        this.getSessionExpiry();
      },
      1000 * 60, // poll every minute
    );
  }

  @action stopSessionExpiryPolling() {
    clearInterval(this.sessionIntervalID);
  }

  @action extendSession = async () => {
    try {
      await extendSession();
      this.getSessionExpiry();
    } catch (error) {
      console.error(error);
    }
  };
}

export default SessionStore;
