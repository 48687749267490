import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import LearningPathIcon from 'img/icons/skills_path_small.svg';
import CatalogCard from 'components/Catalog/Card';
import BaseCard from 'components/Card';
import { storesContext } from 'stores';
import DatePickerInput from '../CustomSkillForm/DatePickerInput';
import './styles.scss';

const LearningPathCard = (props) => {
  const { t } = useTranslation();
  const {
    learningPathStore,
    catalogStore,
    progressStore,
    userStore,
    routerStore,
  } = useContext(storesContext);

  const { learningPath, progress } = props;
  const { lp_elements: elements, title, code } = learningPath;

  const disableActionButtons = () => {
    return (
      userStore.isOrganizationLeader && !userStore.subscription.subscription
    );
  };

  const handleJoinUnjoinClick = (e) => {
    e.stopPropagation();

    if (learningPath.joined) {
      learningPathStore.unjoinLearningPath(code);
      return;
    }

    learningPathStore.joinLearningPath(code);
  };

  const handleCustomizeCSK = (e) => {
    e.stopPropagation();

    if (learningPath.path_type === 'custom' && code) {
      routerStore.navigate('custom-skill-path-edit', { code });
    }
  };

  const handleDeleteCSK = (e) => {
    e.stopPropagation();
    if (props.onDeleteCSK) {
      props.onDeleteCSK(learningPath);
    }
  };

  const renderElementCard = (entry, pathCode) => {
    const { code: entryCode } = entry;
    let offering;

    offering = catalogStore.uniqueCatalogEntries[entryCode];

    offering = offering || entry;

    const isExam = offering.code.startsWith('ex');

    const getCourseProgress = () => {
      if (isExam) {
        const foundExam = elements.find((element) => {
          const { code: courseCode } = element || {};
          return courseCode === offering.code;
        });
        foundExam.total_progress = foundExam.user_progress || 0;
        return foundExam;
      }
      return progressStore.progressByCourse.find((course) => {
        const { course_code: courseCode } = course || {};
        return courseCode === offering.code;
      });
    };

    const found = getCourseProgress();

    const { total_progress: totalProgress } = found || {};

    const offeringProgress = found ? Math.round(totalProgress * 100) : 0;

    const status = isExam && totalProgress === 1 ? 'completed' : 'unenrolled';

    const isCompleted =
      offeringProgress === 100
        ? { background: 'rgb(76, 175, 80)', color: '#fff' }
        : { background: 'rgb(249, 249, 249)', color: '#fff' };

    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={isCompleted}
        key={`${pathCode}-${entryCode}`}
      >
        <CatalogCard
          disableActionButtons={disableActionButtons()}
          status={status}
          course={offering}
          progress={offeringProgress}
          key={`${pathCode}-${entryCode}`}
          canAccess={
            offering.visibility !== 'hidden' &&
            offering.visibility !== 'upgrade'
          }
        />
      </VerticalTimelineElement>
    );
  };

  const renderBodyCard = () => {
    return (
      <React.Fragment>
        <article className="learning-path-info">
          <React.Fragment>
            {learningPath.description && (
              <section
                className="learning-path-collateral-description"
                dangerouslySetInnerHTML={{
                  __html: `<b>${t('Introduction')}:</b>&nbsp;${
                    learningPath?.description?.body
                  }`,
                }}
              />
            )}
            <section
              className="learning-path-collateral-prerequisites"
              dangerouslySetInnerHTML={{
                __html: `<b>${t('Prerequisites')}:</b>&nbsp;
                      ${learningPath?.prerequisites?.body || t('None')}`,
              }}
            />
            <br />
          </React.Fragment>
        </article>
        <div className="learning-path-elements">
          <VerticalTimeline layout="1-column">
            {elements.map((el) => {
              return renderElementCard(el, learningPath.code);
            })}
          </VerticalTimeline>
        </div>
      </React.Fragment>
    );
  };

  const getIcon = () => {
    return (
      <SVG
        src={LearningPathIcon}
        title={t('Skills Path')}
        width={40}
        height={40}
      />
    );
  };

  const getActionButtonsArray = () => {
    const _actionButtons = [
      <Button
        {...(disableActionButtons() && { disabled: true })}
        key={uuidv4()}
        onClick={handleJoinUnjoinClick}
        data-analytics-id={
          learningPath.joined
            ? 'unenroll-btn-card-cskp-lp'
            : 'enroll-btn-card-cskp-lp'
        }
      >
        {learningPath.joined ? t('Unenroll') : t('Enroll')}
      </Button>,
    ];

    if (
      learningPath?.path_type === 'custom' &&
      learningPath.author?.toLowerCase().trim() ===
        userStore.username?.toLowerCase().trim()
    ) {
      _actionButtons.push(
        <Button
          key={uuidv4()}
          onClick={handleCustomizeCSK}
          data-analytics-id="customize-btn-card-cskp-lp"
        >
          {t('Customize')}
        </Button>,
        <Button
          className="learning-path-delete-btn"
          key={uuidv4()}
          onClick={handleDeleteCSK}
          data-analytics-id="delete-btn-card-cskp-lp"
        >
          {t('Delete')}
        </Button>,
      );
    }
    return _actionButtons;
  };

  const getDueDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : t('Not Applicable');
  };

  const getIndicatorElementsArray = () => {
    return [
      learningPath.path_type === 'custom' && (
        <DatePickerInput
          key={uuidv4()}
          dateCaption={t('Due Date')}
          selectedDate={getDueDate(learningPath.end_date)}
          enableReadOnlyInput
          data-analytics-id="due-date-calendar-card-cskp-lp"
        />
      ),
    ];
  };

  return (
    <BaseCard
      customClassName="skills-path-card"
      title={
        learningPath?.path_type !== 'custom' ? title : `${t('Custom')} ${title}`
      }
      cardColor="#5F526D"
      svgIconElement={getIcon()}
      iconClassName="skills-path-icon"
      progress={progress}
      actionButtonsArray={getActionButtonsArray()}
      indicatorElementsArray={getIndicatorElementsArray()}
    >
      {renderBodyCard()}
    </BaseCard>
  );
};

LearningPathCard.propTypes = {
  learningPath: PropTypes.object,
  progress: PropTypes.number,
  onDeleteCSK: PropTypes.func,
};

LearningPathCard.defaultProps = {
  learningPath: {},
  progress: 0,
  onDeleteCSK: () => {},
};

export default observer(LearningPathCard);
