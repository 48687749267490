import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Menu from 'react-burger-menu/lib/menus/slide';
import { storesContext } from '../../stores';
import FeedbackPane from './FeedbackPane';
import AllPane from './AllPane';
import SupportPane from './SupportPane';
import './styles.scss';

const Drawer = () => {
  const { t } = useTranslation();
  const { eventStore } = useContext(storesContext);

  const renderPanes = () => {
    switch (eventStore.isDrawerActive) {
      case 'feedback':
        return (
          <React.Fragment>
            <h4>{t('Feedback form')}</h4>
            <FeedbackPane />
          </React.Fragment>
        );
      case 'support_form':
        return (
          <React.Fragment>
            <SupportPane />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <h4>{t('Notifications')}</h4>
            <AllPane />
          </React.Fragment>
        );
    }
  };

  return (
    <div className="drawer-component">
      <Menu
        right
        isOpen
        noOverlay
        customBurgerIcon={false}
        menuClassName={`${eventStore.isDrawerOpen ? 'opened' : 'closed'}`}
        onStateChange={(state) => {
          eventStore.isDrawerOpen = state.isOpen;
          if (state.isOpen) {
            eventStore.error = false;
          }
        }}
      >
        <div>{renderPanes()}</div>
      </Menu>
    </div>
  );
};

export default observer(Drawer);
