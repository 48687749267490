import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Row, Col, PageHeader } from 'react-bootstrap';
import { Alert } from '@patternfly/react-core';

import GenericViewError from 'components/Error/GenericViewError';
import AlertForm from 'components/Alert/Form';
import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';

import AlertFormStore, { alertFormContext } from './store';
import './styles.scss';

const AlertEdit = () => {
  const { routerStore, vocabularyStore, alertStore } =
    useContext(storesContext);
  const { t } = useTranslation();
  const { uuid } = routerStore.route.params;
  const store = useLocalStore(() => new AlertFormStore());

  useEffect(() => {
    if (!uuid) return;
    const existingAlert = alertStore.alerts.find(
      (item) => item.doc_id === uuid,
    );
    if (existingAlert) {
      store.formData = existingAlert;
    }
  }, []);

  const onSubmit = async () => {
    // FIXME: modify this method to be more reasonable

    const { start_time: startTime, end_time: endTime } = store.formData;

    if (!startTime) {
      store.formData.start_time = null;
    }

    if (!endTime) {
      store.formData.end_time = null;
    }

    // TODO: add error handling here
    if (uuid) {
      await store.updateAlert(uuid);
    } else {
      await store.createAlert();
    }
    routerStore.navigate('admin:alerts:list');
  };
  return (
    <div className="container-grid-responsive">
      <Row>
        <PageHeader>{t('Alert preview')}</PageHeader>
        <Alert
          isInline
          variant={store.formData.priority}
          title={store.formData.title}
          className="alert-preview"
        >
          <p>{store.formData.description}</p>
        </Alert>
      </Row>
      <Row>
        <PageHeader>{uuid ? t('Edit alert') : t('New alert')}</PageHeader>
      </Row>
      <Row>
        <Col xs={12}>
          <alertFormContext.Provider value={store}>
            <AlertForm
              languages={vocabularyStore.languages}
              uuid={uuid}
              onSubmit={onSubmit}
            />
          </alertFormContext.Provider>
        </Col>
      </Row>
    </div>
  );
};

export default withErrorBoundary(
  observer(AlertEdit),
  GenericViewError,
  handleError,
);
