import React, { Component, Suspense } from 'react';
import {
  observer,
  inject,
  Provider,
  PropTypes as MobXPropTypes,
} from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Col, Row, PageHeader } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import ExpertSeminarStore from './store';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import ViewLoader from '../../components/Loading/View';
import GenericAlert from '../../components/Banners/GenericAlert';

const ExpertSeminarComponent = React.lazy(() =>
  import(
    /* webpackChunkName: 'expert-seminar-player' */ '../../components/ExpertSeminar'
  ),
);

@inject('routerStore', 'alertStore')
@withTranslation()
@observer
class ExpertSeminar extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
    alertStore: MobXPropTypes.observableObject.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore } = this.props;
    this.seminarStore = new ExpertSeminarStore();
    const { slug } = routerStore?.route?.params;

    this.slug = slug;
  }

  componentDidMount() {
    this.seminarStore.getExpertSeminar(this.slug);
  }

  render() {
    const stores = {
      seminarStore: this.seminarStore,
    };

    if (this.seminarStore.seminar?.sku) {
      document.title = `${this.seminarStore.seminar.sku.toUpperCase()}`;
    }

    const alerts = this.props.alertStore.currentAlerts.filter((alert) =>
      alert.environment.includes(this.slug),
    );

    return (
      <Suspense fallback={<ViewLoader />}>
        <Provider {...stores}>
          <div className="container-grid-responsive">
            {this.seminarStore.seminar.title && (
              <PageHeader>{this.seminarStore.seminar.title}</PageHeader>
            )}
            <Row className="seminar-alerts">
              {alerts.map((alert) => (
                <GenericAlert {...alert} />
              ))}
            </Row>
            <Row>
              <Col md={12}>
                <ExpertSeminarComponent seminarStore={this.seminarStore} />
              </Col>
            </Row>
          </div>
        </Provider>
      </Suspense>
    );
  }
}

export default withErrorBoundary(ExpertSeminar, GenericViewError, handleError);
