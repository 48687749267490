import { action, observable } from 'mobx';

class ProgressMapStore {
  @observable chapterCount = 0;

  @observable progressStructure = [];

  @action incrementChapterCount() {
    this.chapterCount += 1;

    return this.chapterCount;
  }
}

export default ProgressMapStore;
