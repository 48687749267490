/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment';
import { Col, Row, FormGroup, Form, ControlLabel } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { SimpleSelect } from 'react-selectize';
import { withErrorBoundary } from 'react-error-boundary';
import completedIndicator from '../../../img/icons/check_indicator_small.svg';
import enrolledIndicator from '../../../img/icons/clock_indicator_small.svg';
import VTClassIcon from '../../../img/icons/virtual_training_small.svg';
import PremiumClassIcon from '../../../img/icons/live-session-indicator.svg';

import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';

import { storesContext } from '../../../stores';
import { formatDateTime } from '../../../services/UtilityService';
import CertificateOfAttendanceButton from '../../../components/CertificateOfAttendance/Button';
import './styles.scss';

import PremiumSessionsCOAStore from './store';

import { PREMIUM_VT_SESSION_STATES } from '../../../config/constants';

const PremiumSessionsCOA = () => {
  const { i18n, t } = useTranslation();
  const {
    catalogStore,
    routerStore,
    uiStore,
    userStore,
    premiumSessionsStore,
    classesStore,
  } = useContext(storesContext);

  const locale = i18n.language;

  const premiumSessionsCOAStore = useLocalStore(
    () =>
      new PremiumSessionsCOAStore(
        catalogStore,
        premiumSessionsStore,
        classesStore,
        i18n,
      ),
  );
  uiStore.title = routerStore?.route?.params?.title;

  const { title } = uiStore;
  document.title = t(title);

  useEffect(() => {
    // Populate catalog data on initial load - this happens on hard browser refresth
    when(
      () => !catalogStore.loaded && userStore.subscription.subscription,
      async () => {
        await catalogStore.getCatalogEntries();

        catalogStore.loaded = true;
      },
    );
  }, []);

  // Premium sessions - available only when catalog and scheduled premium sessions are loaded.
  useEffect(() => {
    when(
      () =>
        catalogStore.loaded &&
        premiumSessionsStore.scheduledSessionsloaded &&
        routerStore?.route?.name,

      async () => {
        premiumSessionsCOAStore.premSessionOfferingSlug =
          routerStore?.route?.params.offeringslug;
        premiumSessionsCOAStore.ready = true;
      },
    );
  }, []);

  const PremiumSessionCOARow = (p) => {
    const { entry } = p;

    return (
      <div className="flex-list">
        {Object.keys(entry.enrollment)?.length <= 0 && (
          <React.Fragment>
            <div className="flex-column-icon">
              <SVG src={VTClassIcon} title={t('Session')} />
            </div>
            <div className="flex-column">
              <div className="col-sm-2">
                <span className="sp-sm-header">
                  {t('Title')} &nbsp; &nbsp;{' '}
                </span>
              </div>
              <div className="col-sm-10 col-md-12">{entry.title}</div>
            </div>
          </React.Fragment>
        )}
        <div className="flex-column">
          {Object.keys(entry.enrollment)?.length > 0
            ? Object.keys(entry.enrollment).map((state) => {
                return (
                  <React.Fragment>
                    <div className="flex-row">
                      <div className="flex-column-icon">
                        {state === PREMIUM_VT_SESSION_STATES.completed && (
                          <SVG src={completedIndicator} title={t(state)} />
                        )}
                        {state === PREMIUM_VT_SESSION_STATES.enrolled && (
                          <SVG src={enrolledIndicator} title={t(state)} />
                        )}
                      </div>
                      <div className="flex-column">
                        <div className="col-sm-2">
                          <span className="sp-sm-header">
                            {t('Title')} &nbsp; &nbsp;{' '}
                          </span>
                        </div>
                        <div className="col-sm-10 col-md-12">{entry.title}</div>
                      </div>
                      <div className="flex-column">
                        <div className="col-sm-2">
                          <span className="sp-sm-header">
                            {t('Status')} &nbsp; &nbsp;{' '}
                          </span>
                        </div>
                        <div className="col-sm-10 col-md-12">
                          {state === PREMIUM_VT_SESSION_STATES.enrolled &&
                            t('Scheduled')}
                          {state === PREMIUM_VT_SESSION_STATES.completed &&
                            t('Attended')}
                          {Object.values(entry.enrollment[state]).length >
                            1 && (
                            <b>
                              &nbsp; (<span className="x-icon" />
                              {Object.values(entry.enrollment[state]).length})
                            </b>
                          )}
                        </div>
                      </div>
                      <div className="flex-column ">
                        <div className="col-sm-2">
                          <span className="sp-sm-header">
                            {t('Date')} &nbsp; &nbsp;{' '}
                          </span>
                        </div>
                        <div className="col-sm-10 col-md-12">
                          {Object.values(entry.enrollment[state])?.length > 0
                            ? formatDateTime(
                                Object.values(entry.enrollment[state])[0]
                                  .end_time,
                                locale,
                                'LLL',
                              )
                            : ''}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            : null}
        </div>
      </div>
    );
  };

  const renderColumns = () => {
    return (
      <React.Fragment>
        <div className="flex-column-icon">
          <SVG src={PremiumClassIcon} title={t('Session')} />
        </div>
        <div className="flex-column">
          <div className="col-sm-10 col-md-12">{t('Title')}</div>
        </div>

        <div className="flex-column">
          <div className="col-sm-10 col-md-12">{t('Status')}</div>
        </div>
        <div className="flex-column">
          <div className="col-sm-10 col-md-12">{t('Date')}</div>
        </div>
      </React.Fragment>
    );
  };

  const renderBanner = () => {
    return premiumSessionsCOAStore.hasAttendedAllSessions &&
      premiumSessionsCOAStore.totalAttendedEnrollments > 0 ? (
      <div
        className={`coa-banner ${
          premiumSessionsCOAStore.ready && 'red-banner'
        }`}
      >
        {premiumSessionsCOAStore.ready &&
          t(
            `Congratulations! You have successfully completed all Live Sessions in ${premiumSessionsCOAStore.premSessionOfferingSlug.toUpperCase()} - ${premiumSessionsCOAStore.courseTitle(
              premiumSessionsCOAStore.premSessionOfferingSlug,
            )}`,
          )}
      </div>
    ) : (
      (!premiumSessionsCOAStore.hasAttendedAllSessions &&
        premiumSessionsCOAStore.totalAttendedEnrollments > 0 && (
          <div className="coa-banner blue-banner">
            <h4>
              {premiumSessionsCOAStore.ready &&
                premiumSessionsCOAStore.totalPremiumSessions > 0 &&
                t(
                  `Congratulations! You have successfully completed ${
                    premiumSessionsCOAStore.totalAttendedEnrollments
                  } out of ${
                    premiumSessionsCOAStore.totalPremiumSessions
                  } Live Sessions in ${premiumSessionsCOAStore.premSessionOfferingSlug.toUpperCase()} - ${premiumSessionsCOAStore.courseTitle(
                    premiumSessionsCOAStore.premSessionOfferingSlug,
                  )}`,
                )}
            </h4>
          </div>
        )) ||
        null
    );
  };

  const renderCourseDropdown = () => {
    return (
      <Form className="course-dropdown-container">
        <FormGroup>
          <React.Fragment>
            <ControlLabel>{t('Select course')}</ControlLabel>

            {
              // eslint-disable-next-line no-nested-ternary
              catalogStore.loaded && premiumSessionsCOAStore.ready ? (
                premiumSessionsStore.premiumSessionsContent.length ? (
                  <SimpleSelect
                    value={
                      premiumSessionsCOAStore.selectedPremOfferingSlugWithTitle
                    }
                    options={
                      premiumSessionsCOAStore.scheduledCompletedUserPremiumOfferings
                    }
                    placeholder={t('Select Course')}
                    onValueChange={(e) => {
                      if (e.value) {
                        premiumSessionsCOAStore.premSessionOfferingSlug =
                          e.value;
                      }
                    }}
                  />
                ) : (
                  <p>{t('No courses found')}</p>
                )
              ) : (
                <div className="view-loader-wrapper">
                  {t('Loading Courses...')}
                </div>
              )
            }
          </React.Fragment>
        </FormGroup>
      </Form>
    );
  };
  return (
    <React.Fragment>
      <div className="premium-coa-wrapper">
        <div className="container">
          <h4 className="page-title">{t('Certificate of Completion')}</h4>
          {renderCourseDropdown()}
        </div>
        {renderBanner()}
        <div className="container-grid-responsive">
          <React.Suspense fallback={<ViewLoader />}>
            <Row className="premium-coa-container">
              <Col>
                <section className="coa-list-container">
                  <div className="flex-list coa-header">{renderColumns()}</div>

                  {premiumSessionsCOAStore.ready ? (
                    (premiumSessionsCOAStore.premiumSessionsByCourseSlug
                      ?.length > 0 && (
                      <React.Fragment>
                        {premiumSessionsCOAStore.premiumSessionsByCourseSlug?.map(
                          (entry) => {
                            const { doc_id: docId } = entry;

                            return (
                              <PremiumSessionCOARow entry={entry} key={docId} />
                            );
                          },
                        )}

                        <div className="coa-buttons">
                          {premiumSessionsCOAStore.totalAttendedEnrollments >
                            0 && (
                            <CertificateOfAttendanceButton
                              buttonText={
                                !premiumSessionsCOAStore.hasAttendedAllSessions
                                  ? 'Download Report'
                                  : 'Certificate of Attendance'
                              }
                              courseSlug={
                                premiumSessionsCOAStore.premSessionOfferingSlug
                              }
                              timestamp={moment().toISOString()}
                              forPremium
                            />
                          )}
                        </div>
                      </React.Fragment>
                    )) || <p>{t('No sessions were found')}</p>
                  ) : (
                    <ViewLoader />
                  )}
                </section>
              </Col>
            </Row>
          </React.Suspense>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(PremiumSessionsCOA),
  GenericViewError,
  handleError,
);
