import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'react-bootstrap';
import { storesContext } from '../../stores';
import { APP_BASE } from '../../config/constants';

const SessionModal = (props) => {
  const { t } = useTranslation();
  const { sessionStore, userStore } = useContext(storesContext);
  const { expirationTime } = props;
  const showModelMinutes = 10;

  const getCurrentTimeUTC = () => {
    const tmLoc = new Date();
    return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
  };

  const calculateTimeLeft = () => {
    const difference =
      +new Date(expirationTime).getTime() - +getCurrentTimeUTC();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor(difference / 1000 / 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const isShowModel =
    (timeLeft.minutes ? timeLeft.minutes : 0) < showModelMinutes;

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>,
    );
  });

  const extendLoginSession = () => {
    sessionStore.extendSession();
  };

  const redirectLogin = () => {
    userStore.invalidateUser();
    window.location.href = APP_BASE;
  };

  return isShowModel ? (
    <Modal tabIndex="-1" show>
      {/* show={visible}> */}
      <Modal.Header tabIndex="-1">
        <Modal.Title>{t('Your session is about to expire')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {timerComponents.length ? (
          <p>
            {t('Your session is going to time out in')} {timerComponents}
          </p>
        ) : (
          t('Session is expired. Please log in again.')
        )}
      </Modal.Body>
      <Modal.Footer tabIndex="-1">
        {timerComponents.length ? (
          <Button bsStyle="primary" onClick={extendLoginSession}>
            {t('Extend session')}
          </Button>
        ) : (
          <Button bsStyle="primary" onClick={redirectLogin}>
            {t('Log back in')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  ) : null;
};

SessionModal.propTypes = {
  expirationTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
};

export default SessionModal;
