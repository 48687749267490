/**
 * @file User Profile view
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import ViewLoader from 'components/Loading/View';
import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';
import ProfileForm from 'components/Profile/Form';
import GenericViewError from 'components/Error/GenericViewError';

const Profile = () => {
  const { t } = useTranslation();
  const { routerStore, uiStore } = useContext(storesContext);

  uiStore.title = routerStore?.route?.params?.title;

  const { title } = uiStore;
  document.title = t(title);

  return (
    <div className="container-grid-responsive profile">
      <React.Suspense fallback={<ViewLoader />}>
        <ProfileForm />
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(Profile),
  GenericViewError,
  handleError,
);
