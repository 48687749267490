import {
  MINIMUM_COURSE_COMPLETION_PERCENTAGE,
  PENDO_GUIDES,
} from 'config/constants';
import { useContext, useEffect, useRef } from 'react';

import { storesContext } from 'stores';
/**
 * Compares new progress with old progress and shows a Pendo notification if a course has been completed. Also sends a Pendo track event
 */
function useCourseCompletionNotification(
  isCredlyEnabled,
  newProgress = null,
  courseSlug,
) {
  const { pendoStore, configStore } = useContext(storesContext);
  const previousProgress = useRef(null);

  useEffect(() => {
    if (newProgress !== null && newProgress !== previousProgress.current) {
      if (
        isCredlyEnabled &&
        previousProgress.current !== null &&
        newProgress >= MINIMUM_COURSE_COMPLETION_PERCENTAGE &&
        previousProgress.current < MINIMUM_COURSE_COMPLETION_PERCENTAGE // TODO: Use constant
      ) {
        pendoStore.sendTrackEvent('course_completed', {
          courseSlug,
        });

        const fallbackGuideId = configStore.isChina
          ? PENDO_GUIDES.CHINA.courseCompletion
          : PENDO_GUIDES.STANDARD.courseCompletion;

        const guideId =
          configStore.pendoGuides?.courseCompletion || fallbackGuideId;

        pendoStore.showGuideById(guideId);
      }
      previousProgress.current = newProgress; // update previous progress
    }
  }, [newProgress]);
}

export default useCourseCompletionNotification;
