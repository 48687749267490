import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex } from '@patternfly/react-core';
import NotificationBadge, { Effect } from 'react-notification-badge';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';

function AccountMenu({ setMenuOpen, onLogout }) {
  const { t } = useTranslation();
  const { userNotificationsStore, userStore, routerStore, eventStore } =
    useContext(storesContext);
  const { user, isAdmin, isInstructor } = userStore;
  const { preferred_name: preferredName } = user;

  return (
    <Flex
      className="account-menu-container"
      justifyContent={{ default: 'justifyContentSpaceEvenly' }}
    >
      <Flex
        direction={{ default: 'column' }}
        alignItems={{ default: 'alignItemsFlexStart' }}
      >
        <h3>Welcome, {preferredName}</h3>
        <Button
          variant="link"
          className="button-link"
          onClick={() => {
            setMenuOpen(false);
            routerStore.navigate('account.profile');
          }}
          data-analytics-id="profile-page-link-header-lp"
        >
          {t('Profile')}
        </Button>
        <Button
          variant="link"
          className="button-link"
          onClick={() => {
            setMenuOpen(false);
            eventStore.toggleDrawer('all');
          }}
          data-analytics-id="notification-icon-header-lp"
        >
          <React.Fragment>
            <div className="notification-badge-wrapper">
              <NotificationBadge
                count={
                  eventStore.newNotifications.length +
                    userNotificationsStore?.newUserNotifications?.length || 0
                }
                effect={Effect.SCALE}
              />
            </div>
            {t('Notifications')}
          </React.Fragment>
        </Button>
        {(isAdmin || isInstructor) && (
          <Button
            variant="link"
            className="button-link"
            onClick={() => {
              setMenuOpen(false);
              routerStore.navigate('classes:list');
            }}
            data-analytics-id="instructor-dashboard-link-header-lp"
          >
            {t('Instructor Dashboard')}
          </Button>
        )}
        {isAdmin && (
          <Button
            variant="link"
            className="button-link"
            onClick={() => {
              setMenuOpen(false);
              routerStore.navigate('admin');
            }}
            data-analytics-id="admin-dashboard-link-header-lp"
          >
            {t('Admin Dashboard')}
          </Button>
        )}
      </Flex>
      <Flex
        direction={{ default: 'column' }}
        alignItems={{ default: 'alignItemsFlexStart' }}
        alignSelf={{ default: 'alignSelfCenter' }}
      >
        <p className="logout-text">
          {t(
            'For your security, if you are on a public computer and have finished using your Red Hat services, please log out.',
          )}
        </p>
        <Button
          variant="danger"
          onClick={() => {
            setMenuOpen(false);
            onLogout();
          }}
          data-analytics-id="logout-link-header-lp"
        >
          {t('Logout')}
        </Button>
      </Flex>
    </Flex>
  );
}

AccountMenu.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default AccountMenu;
