/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { KalturaWidget } from '@ole-ui/ole-ui-components';
import { KALTURA_CONFIGS } from 'config/constants';
import useLocalStorageState from 'hooks/useLocalStorageState';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import useKalturaSession from 'hooks/useKalturaSession';
import './styles.scss';

const VideoWidget = ({
  entryId,
  partnerId,
  playerId,
  isPlaylist,
  videoWidgetTitle,
}) => {
  const [IsVideoContainerExpanded, setVideoContainerExpanded] =
    useLocalStorageState('videoWidgetCollapseState', true);
  const [isVideoPlayerLaunched, setVideoPlayerLaunched] = useState(true);
  const [isPlayerPictureInPicture, setPlayerPictureInPicture] = useState(false);

  const [player, setPlayer] = useState({});

  const removeVideoEvents = () => {
    if (player && player.removeEventListener) {
      player.removeEventListener('enterpictureinpicture');

      player.removeEventListener('leavepictureinpicture');
    }
  };

  const { kalturaSession, skipKalturaSession } = useKalturaSession({
    entryId,
    onRemoval: () => {
      setPlayerPictureInPicture(false);
      removeVideoEvents();
    },
  });

  const attachVideoEvents = (videoPlayer) => {
    videoPlayer.addEventListener('enterpictureinpicture', () => {
      setPlayerPictureInPicture(true);
    });

    videoPlayer.addEventListener('leavepictureinpicture', () => {
      setPlayerPictureInPicture(false);
    });
  };

  const onVideoPlayerLaunch = (videoPlayer) => {
    attachVideoEvents(videoPlayer);
    setPlayer(videoPlayer);
    setVideoPlayerLaunched(true);
  };

  const { t } = useTranslation();

  const onVideoHandlerClick = () => {
    if (!isVideoPlayerLaunched) return;
    setVideoContainerExpanded(!IsVideoContainerExpanded);
    if (player && player.pause) {
      player.pause();
    }
  };

  const showTitle = () => {
    return IsVideoContainerExpanded ? videoWidgetTitle : '';
  };

  return videoWidgetTitle ? (
    <React.Fragment>
      <div className="video-container">
        <div
          className="video-handler"
          onClick={onVideoHandlerClick}
          role="button"
          tabIndex={0}
        >
          <div>{isVideoPlayerLaunched ? t(`VIDEO ${showTitle()}`) : ''}</div>
          <div className="icon-container">
            <SVG src={IsVideoContainerExpanded ? CollapseIcon : ExpandIcon}>
              {IsVideoContainerExpanded ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </div>
        <div
          className={
            IsVideoContainerExpanded
              ? (isPlayerPictureInPicture && 'picture-in-picture-video') ||
                'expand-video'
              : 'collapse-video'
          }
        >
          {(kalturaSession || skipKalturaSession) && (
            <KalturaWidget
              entryId={entryId}
              partnerId={partnerId}
              playerId={playerId}
              isPlaylist={isPlaylist}
              onPlayerLaunched={onVideoPlayerLaunch}
              session={kalturaSession}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  ) : (
    (kalturaSession || skipKalturaSession) && (
      <KalturaWidget
        entryId={entryId}
        partnerId={partnerId}
        playerId={playerId}
        isPlaylist={isPlaylist}
        onPlayerLaunched={onVideoPlayerLaunch}
        session={kalturaSession}
      />
    )
  );
};

VideoWidget.propTypes = {
  entryId: PropTypes.string.isRequired,
  playerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPlaylist: PropTypes.bool,
  videoWidgetTitle: PropTypes.string,
};

VideoWidget.defaultProps = {
  playerId: KALTURA_CONFIGS.player.uiConfId,
  partnerId: KALTURA_CONFIGS.partnerId,
  isPlaylist: false,
  videoWidgetTitle: '',
};

export default VideoWidget;
