import { get, patch } from 'axios';
import { ENROLLMENT_API, ENROLLMENT_INSTANCE } from '../config/constants';

/* get course enrollment info */
const getCourseEnrollment = (course, username) =>
  new Promise((resolve, reject) => {
    get(
      ENROLLMENT_API.replace('<course-slug>', course).replace(
        '<username>',
        username,
      ),
    )
      .then((result) => resolve(result.data.data.attributes))
      .catch((e) => reject(e));
  });

const getEnrollmentInstance = (uuid) =>
  new Promise((resolve, reject) => {
    get(ENROLLMENT_INSTANCE.replace('<uuid>', uuid))
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => reject(e));
  });

const saveEnrollmentUserData = (newUserData, uuid) =>
  new Promise((resolve, reject) => {
    patch(ENROLLMENT_INSTANCE.replace('<uuid>', uuid), {
      user_data: newUserData,
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => reject(e));
  });

export { getCourseEnrollment, getEnrollmentInstance, saveEnrollmentUserData };
export default getCourseEnrollment;
