import React, { useContext, useEffect } from 'react';
import { PostScribe } from 'react-postscribe';
import { when } from 'mobx';

import { storesContext } from '../../stores';

const PendoWidget = () => {
  const { userStore, pendoStore, configStore } = useContext(storesContext);
  const initPendo = () => {
    pendoStore.initPendo();
  };
  const [pendoAppId, setpendoAppId] = React.useState('');
  const [isPendoScriptLoaded, setPendoScriptLoaded] = React.useState(false);

  useEffect(() => {
    when(
      () =>
        userStore.isLoggedIn &&
        userStore.username &&
        configStore.pendoAppId !== '',
      () => {
        if (
          pendoStore.isUserAllowedToBeTracked(userStore.username) &&
          configStore.pendoAppId !== ''
        ) {
          setpendoAppId(configStore.pendoAppId);
        }
      },
    );
    when(
      () => isPendoScriptLoaded === true,
      () => {
        if (!window.pendo) return;

        if (!userStore.isLoggedIn) {
          return;
        }

        initPendo();
      },
    );
  }, [userStore, window, configStore, isPendoScriptLoaded]);

  return (
    (pendoAppId && userStore.isLoggedIn && (
      <PostScribe
        html={`<script >(function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('${pendoAppId}');</script>`}
        done={() => {
          setPendoScriptLoaded(true);
          // eslint-disable-next-line no-console
          console.log('pendo agent included');
        }}
      />
    )) ||
    null
  );
};

export default PendoWidget;
