import React, { Component } from 'react';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { PageHeader } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';

const EditClass = React.lazy(() =>
  import(
    /* webpackChunkName: 'class-edit' */ '../../../components/Classes/Edit'
  ),
);

@inject('routerStore')
@withTranslation()
@observer
class EditClassView extends Component {
  static propTypes = {
    routerStore: MobXPropTypes.observableObject.isRequired,
  };

  constructor(props) {
    super(props);

    const { routerStore } = this.props;
    const { uuid, title } = routerStore?.route?.params;

    this.uuid = uuid;
    this.title = title;
    document.title = title;
  }

  render() {
    return (
      <div className="container-grid-responsive">
        <PageHeader>{this.title}</PageHeader>
        <React.Suspense fallback={<ViewLoader />}>
          <EditClass {...(this.uuid && { uuid: this.uuid })} />
        </React.Suspense>
      </div>
    );
  }
}

export default withErrorBoundary(EditClassView, GenericViewError, handleError);
